import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Grid } from '@mui/material';
import { Email, Phone, Visibility } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { getAllLeads as getAllLeadsAction} from '../../redux/actions/leadActions'
import CustomDataGrid from '../../shared/CustomDataGrid';
import LeadDetailsModal from './LeadDetailsModal';
import { getUser } from '../../redux/actions/userActions';

const useStyles = makeStyles({
  header: {
    margin: ".5em 0 !important",
  },
});

const AllLeads = () => {
  const classes = useStyles();
  const leads = useSelector((state) => state.leads.allLeads);
  const loading = useSelector((state) => state.loading.ALL_LEADS);
  const error = useSelector((state) => state?.leads?.error);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const [leadsData, setLeadsData] = useState(getLeadsData(leads) || []);
  const [selectedLeadData, setSelectedLeadData] = React.useState(null);

  useEffect(() => {
    if(!user) {
      dispatch(getUser());
    } else {
      dispatch(getAllLeadsAction(user.customer.id));;
    }
  }, []);

  useEffect(() => {
    setLeadsData(getLeadsData(leads) || []);
  }, [leads]);

  const handleCallPhone = (phone) => {
    console.log(phone);
  }
  
  const handleSendEmail = (email) => {
    console.log(email);
  }
  
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: true,
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 150,
      editable: true,
    },
    {
      field: 'company',
      headerName: 'Company',
      width: 160,
      editable: true,
    },
    {
      field: 'leadStatus',
      headerName: 'Lead Status',
      sortable: true,
      width: 160,
    },
    {
      field: 'leadSource',
      headerName: 'Lead Source',
      sortable: true,
      width: 160,
    },
    {
      field: 'lastActivity',
      headerName: 'Last Activity',
      sortable: true,
      width: 160,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <div>
          <Email
            style={{ cursor: 'pointer', marginRight: '8px' }}
            onClick={() => handleSendEmail(params.row.email)}
          />
          <Phone
            style={{ cursor: 'pointer' }}
            onClick={() => handleCallPhone(params.row.phone)}
          />
          <Visibility
            style={{ cursor: 'pointer', marginRight: '8px' }}
            onClick={() => handleShowDetails(params.row)}
          />
        </div>
      ),
    },
  ];

  const handleShowDetails = (lead) => {
    setSelectedLeadData(lead);
  };

  const handleCloseDetailsModal = () => {
    setSelectedLeadData(null);
  };

  return (
    <Box sx={{ minHeight: 400, width: '100%' }}>
      <Grid item xs={12} md={12}>
        <Typography id="allleads" variant="h5" className={classes.header}>All Leads</Typography>
      </Grid>
      <CustomDataGrid 
        gridData={leadsData}
        columns={columns}
        loading={loading}
        error={error}
      />
      <LeadDetailsModal leadData={selectedLeadData} onClose={handleCloseDetailsModal} />
    </Box>
  );
}

export default AllLeads;

const getLeadsData = (leads) => {
  if (!Array.isArray(leads)) {
    return [];
  }

  const myLeads = leads.map(lead => ({
    ...lead,
    name: `${lead.firstName} ${lead.lastName}`,
  }));

  const filteredMyLeads = myLeads.filter(item => item.isAccountCreated !== true);

  const sortedLeads = filteredMyLeads.sort((a, b) => {
    const dateA = new Date(a.lastActivity);
    const dateB = new Date(b.lastActivity);
    return dateB - dateA;
  });

  return sortedLeads;
};

// import React, { useEffect, useState } from 'react';
// import { Container, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton, Collapse, Box, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TablePagination } from '@mui/material';
// import EditIcon from '@mui/icons-material/Edit';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import ArrowRightIcon from '@mui/icons-material/ArrowRight';
// import { saveTaskAction,getAllTaskAction,searchTaskAction } from '../../../redux/actions/taskActions';
// import { useDispatch, useSelector } from 'react-redux';

// const data = [
//   {
//     policyNumber: 'UAD3B1140',
//     date: '01-27-2023',
//     dueDate: '02-27-2023',
//     status: 'Opened',
//     assignedTo: 'MICHELLE MIRABAL',
//     subject: 'Compliance Task: Verify that policy...'
//   },
//   {
//     policyNumber: 'UAD3B1141',
//     date: '01-27-2023',
//     dueDate: '03-27-2023',
//     status: 'Closed',
//     assignedTo: 'MICHELLE MIRABAL',
//     subject: 'Compliance Task: Verify that policy...'
//   },
//   {
//     policyNumber: 'UAD3B1142',
//     date: '01-27-2023',
//     dueDate: '02-27-2023',
//     status: 'Opened',
//     assignedTo: 'MICHELLE MIRABAL',
//     subject: 'Compliance Task: Verify that policy...'
//   },
//   {
//     policyNumber: 'UAD3B1143',
//     date: '01-27-2023',
//     dueDate: '02-27-2023',
//     status: 'Closed',
//     assignedTo: 'MICHELLE MIRABAL',
//     subject: 'Compliance Task: Verify that policy...'
//   }
// ];
// const poppinsFont = {
//   fontFamily: '"Poppins", sans-serif',
// };

// const TaskComponent = () => {
//   const [expandedRows, setExpandedRows] = useState([]);
//   const [open, setOpen] = useState(false);
//   const dispatch = useDispatch();
//   const taskData=useSelector((state)=>state.task.allTask);
//   const searchtaskData=useSelector((state)=>state.task.searchTask);
//   const data=taskData.addTaskList || [];
//   console.log("taskData",taskData.addTaskList);
//   const [formValues, setFormValues] = useState({
//     policyId: '',
//     taskTo: '',
//     dueDate: '',
//     status: '',
//     taskDescription: ''
//   });

//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);

//   useEffect(() => {

//         dispatch(getAllTaskAction());

//   }, [dispatch]);

//   const handleExpandClick = (index) => {
//     setExpandedRows(prevExpandedRows =>
//       prevExpandedRows.includes(index)
//         ? prevExpandedRows.filter(row => row !== index)
//         : [...prevExpandedRows, index]
//     );
//   };

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormValues(prevValues => ({
//       ...prevValues,
//       [name]: value
//     }));
//   };

//   const handleSubmit = () => {
//     // Dispatch the saveTaskAction with formValues
//     dispatch(saveTaskAction(formValues, (response) => {
//       console.log('Task saved successfully:', response);
//       // Optionally handle the response here
//       setOpen(false); // Close the dialog after submission
//     }));
//   };

//   const paginatedRows = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };


//   return (
//     <Box
//     sx={{
//       display: 'flex',
//       flexWrap: 'wrap',
//       gap: 2,
//       padding: 2,
//       backgroundColor: '#f9f9f9',
//       borderRadius: '8px',
//       boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//     }}
//     >
//       <TextField
//         label="From"
//         type="date"
//         InputLabelProps={{ shrink: true }}
//         sx={{fontFamily: '"Poppins", sans-serif', }}
//         style={{ marginRight: '10px',}}
//       />
//       <TextField
//         label="To"
//         type="date"
//         InputLabelProps={{ shrink: true }}
//         sx={{fontFamily: '"Poppins", sans-serif', }}
//         style={{ marginRight: '10px',}}
//       />
//       {/* <TextField
//         label="Subject"
//         sx={{fontFamily: '"Poppins", sans-serif', }}
//         style={{ marginRight: '10px',}}
//       /> */}
//       <TextField
//         // label="Status"
//         placeholder='Status'
//         select
//         SelectProps={{ native: true }}
//         sx={{fontFamily: '"Poppins", sans-serif', }}
//         style={{ marginRight: '10px',}}
//       >
//         <option value="">Select Status</option>
//         <option value="Opened">Opened</option>
//         <option value="Closed">Closed</option>
//       </TextField>
//       {/* <TextField
//         label="Select Policy"
//         select
//         SelectProps={{ native: true }}
//         style={{ marginRight: '10px',  fontFamily: '"Poppins", sans-serif',}}
//       >
//         <option value="All">All</option>
//       </TextField> */}
//       <Button variant="contained" sx={{fontFamily: '"Poppins", sans-serif',}} style={{ marginRight: '10px' }}>Search</Button>
//       <Button variant="contained" sx={{fontFamily: '"Poppins", sans-serif',}} onClick={handleClickOpen}>Add Task</Button>

//       <Dialog open={open} onClose={handleClose}>
//         <DialogTitle sx={{fontFamily: '"Poppins", sans-serif',}}>Add Task</DialogTitle>
//         <DialogContent>
//           <TextField
//             // label="Select Policy"
//             placeholder='Select Policy'
//             name="policyId"
//             select
//             fullWidth
//             value={formValues.policyId}
//             onChange={handleChange}
//             margin="normal"
//             SelectProps={{ native: true }}
//           >
//             <option value="" disabled>
//     Select Policy
//   </option>
//             <option value="1">1</option>
//             <option value="2">2</option>
//             <option value="3">3</option>
//             <option value="4">4</option>
//           </TextField>
//           <TextField
//             // label="Assigned To"
//             placeholder='Assigned To'
//             name="taskTo"
//             fullWidth
//             value={formValues.taskTo}
//             onChange={handleChange}
//             margin="normal"
//             select
//             SelectProps={{ native: true }}
//           >
//             <option value="" disabled>
//     Select Assign To
//   </option>
//             <option value="MICHELLE MIRABAL">MICHELLE MIRABAL</option>
//           </TextField>
//           <TextField
//             label="Select Due Date"
//             name="dueDate"
//             type="date"
//             fullWidth
//             value={formValues.dueDate}
//             onChange={handleChange}
//             margin="normal"
//             InputLabelProps={{ shrink: true }}
//           />
//           <TextField
//             // label="Select Status"
//             placeholder='Select Status'
//             name="status"
//             fullWidth
//             value={formValues.status}
//             onChange={handleChange}
//             margin="normal"
//             select
//             SelectProps={{ native: true }}
//           >
//             <option value="" disabled>
//     Select Policy
//   </option>
//             <option value="Opened">Opened</option>
//             <option value="Closed">Closed</option>
//           </TextField>
//           <TextField
//             label="Task"
//             name="taskDescription"
//             fullWidth
//             value={formValues.taskDescription}
//             onChange={handleChange}
//             margin="normal"
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose}>Cancel</Button>
//           <Button onClick={handleSubmit}>Submit</Button>
//         </DialogActions>
//       </Dialog>

//       <TableContainer style={{ marginTop: '20px' }}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell sx={poppinsFont}>Id</TableCell>
//               <TableCell sx={poppinsFont}>Date</TableCell>
//               <TableCell sx={poppinsFont}>Due Date</TableCell>
//               <TableCell sx={poppinsFont}>Status</TableCell>
//               <TableCell sx={poppinsFont}>Assigned To</TableCell>
//               <TableCell sx={poppinsFont}>Subject</TableCell>
//               <TableCell sx={poppinsFont}>Action</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {paginatedRows.map((row, index) => (
//               <React.Fragment key={index}>
//                 <TableRow>
//                   <TableCell sx={poppinsFont}>{row.id}</TableCell>
//                   <TableCell sx={poppinsFont}>{row.date}</TableCell>
//                   <TableCell sx={poppinsFont}>{row.dueDate}</TableCell>
//                   <TableCell sx={poppinsFont}>{row.status}</TableCell>
//                   <TableCell sx={poppinsFont}>{row.taskTo}</TableCell>
//                   <TableCell sx={poppinsFont}>
//                     <IconButton onClick={() => handleExpandClick(index)}>
//                       {expandedRows.includes(index) ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
//                     </IconButton>
//                     {row.taskDescription}
//                   </TableCell>
//                   <TableCell sx={poppinsFont}>
//                     <IconButton>
//                       <EditIcon />
//                     </IconButton>
//                   </TableCell>
//                 </TableRow>
//                 <TableRow>
//                   <TableCell colSpan={7} style={{ paddingBottom: 0, paddingTop: 0 }}>
//                     <Collapse in={expandedRows.includes(index)} timeout="auto" unmountOnExit>
//                       <Box margin={1}>
//                         {/* Add your expanded content here */}
//                         <div>
//                           Detailed information about the subject: {row.subject}
//                         </div>
//                       </Box>
//                     </Collapse>
//                   </TableCell>
//                 </TableRow>
//               </React.Fragment>
//             ))}
//           </TableBody>
//         </Table>
//         <TablePagination
//           component="div"
//           count={data.length}
//           page={page}
//           onPageChange={handleChangePage}
//           rowsPerPage={rowsPerPage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//           rowsPerPageOptions={[5, 10, 15]}
//         />
//       </TableContainer>
//     </Box>
//   );
// };

// export default TaskComponent;



import React, { useEffect, useState } from 'react';
import { Container, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton, Collapse, Box, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TablePagination } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { saveTaskAction, getAllTaskAction, searchTaskAction } from '../../../redux/actions/taskActions';
import { useDispatch, useSelector } from 'react-redux';

const poppinsFont = {
  fontFamily: '"Poppins", sans-serif',
};

const TaskComponent = () => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const taskData = useSelector((state) => state.task.allTask);
  const searchtaskData = useSelector((state) => state.task.searchTask);
  console.log("search data",searchtaskData);
  // const data = searchtaskData || taskData.addTaskList || [];
  const data = searchtaskData && searchtaskData.length > 0 ? searchtaskData : taskData.addTaskList || [];
  console.log("data after searching",data);
  // const data=taskData.addTaskList || [];
  const [formValues, setFormValues] = useState({
    policyId: '',
    taskTo: '',
    dueDate: '',
    status: '',
    taskDescription: ''
  });

  const [searchValues, setSearchValues] = useState({
    fromDate: '',
    toDate: '',
    status: ''
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    dispatch(getAllTaskAction());
  }, [dispatch]);

  const handleExpandClick = (index) => {
    setExpandedRows(prevExpandedRows =>
      prevExpandedRows.includes(index)
        ? prevExpandedRows.filter(row => row !== index)
        : [...prevExpandedRows, index]
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues(prevValues => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchValues(prevValues => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleSearch = () => {
    dispatch(searchTaskAction(searchValues, (response) => {
      console.log('Search completed:', response);
    }));
  };

  const handleSubmit = () => {
    dispatch(saveTaskAction(formValues, (response) => {
      console.log('Task saved successfully:', response);
      setOpen(false);
    }));
  };

  const paginatedRows = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
        padding: 2,
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
    <Box sx={{ display: 'flex', width: '100%'}}>
      <Box sx={{ display: 'flex', flexFlow: 'column', width: '20%'}}>
        <label>From date</label>
        <TextField
          type="date"
          id="fromdate"
          name="fromDate"
          value={searchValues.fromDate}
          onChange={handleSearchChange}
          InputLabelProps={{ shrink: true }}
          sx={{fontFamily: '"Poppins", sans-serif', }}
          style={{ marginRight: '10px',}}
        />
      </Box>
      <Box sx={{ display: 'flex', flexFlow: 'column', width: '20%'}}>
        <label>To Date</label>
        <TextField
          type="date"
          id="todate"
          name="toDate"
          value={searchValues.toDate}
          onChange={handleSearchChange}
          InputLabelProps={{ shrink: true }}
          sx={{fontFamily: '"Poppins", sans-serif', }}
          style={{ marginRight: '10px',}}
        />
      </Box>
      
     <Box sx={{ display: 'flex', flexFlow: 'column', width: '20%'}}>
      <label>Status</label>
      <TextField
        placeholder="Status"
        name="status"
        id="status"
        value={searchValues.status}
        onChange={handleSearchChange}
        select
        SelectProps={{ native: true }}
        sx={{fontFamily: '"Poppins", sans-serif', }}
        style={{ marginRight: '10px',}}
      >
        <option value="">Select Status</option>
        <option value="Pending">Pending</option>
        <option value="Complete">Complete</option>
      </TextField>
     </Box>
        <Box sx={{display: 'flex', flexFlow: 'row', alignItems: 'center', width: '20%'}}>
          <Button variant="contained" sx={{fontFamily: '"Poppins", sans-serif',}} id="searchbutton" style={{ marginRight: '10px' }} onClick={handleSearch}>
            Search
          </Button>
          <Button variant="contained" sx={{fontFamily: '"Poppins", sans-serif',}} id="addtaskbutton" onClick={handleClickOpen}>Add Task</Button>
        </Box>
      </Box>

      {/* Add Task Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{fontFamily: '"Poppins", sans-serif',}} id="addtasktitle">Add Task</DialogTitle>
        <DialogContent>
        <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
            <Box sx={{ width: '50%', display: 'flex', flexFlow: 'column', marginBottom: '15px', 
              padding:'0 10px 0 0', boxSizing: 'border-box'}}>
              <label>Policy</label>
              <TextField
                placeholder="Select Policy"
                name="policyId"
                id="policyid"
                select
                fullWidth
                value={formValues.policyId}
                onChange={handleChange}
                SelectProps={{ native: true }}
              >
                <option value="" disabled>Select Policy</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </TextField>
            </Box>
            <Box sx={{ width: '50%', display: 'flex', flexFlow: 'column', marginBottom: '15px', 
              padding:'0 0 0 10px', boxSizing: 'border-box'}}>
              <label>Assigned To</label>
              <TextField
                placeholder="Assigned To"
                name="taskTo"
                id="assignedto"
                fullWidth
                value={formValues.taskTo}
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
              >
                <option value="" disabled>Select Assign To</option>
                <option value="MICHELLE MIRABAL">MICHELLE MIRABAL</option>
              </TextField>
            </Box>
            
            <Box sx={{ width: '50%', display: 'flex', flexFlow: 'column', marginBottom: '15px', 
              padding:'0 10px 0 0', boxSizing: 'border-box'}}>
              <label>Select Due Date</label>
              <TextField
                name="dueDate"
                id="duedate"
                type="date"
                fullWidth
                value={formValues.dueDate}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              />
            </Box>

            <Box sx={{ width: '50%', display: 'flex', flexFlow: 'column', marginBottom: '15px', 
              padding:'0 0 0 10px', boxSizing: 'border-box'}}>
              <label>Select Status</label>
              <TextField
                placeholder="Select Status"
                name="status"
                id="selectstatus"
                fullWidth
                value={formValues.status}
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
              >
                <option value="" disabled>Select Policy</option>
                <option value="Opened">Opened</option>
                <option value="Closed">Closed</option>
              </TextField>
            </Box>
            
            <Box sx={{ width: '100%', display: 'flex', flexFlow: 'column', boxSizing: 'border-box'}}>
              <label>Task Description</label>
              <TextField
                name="taskDescription"
                id="task"
                fullWidth
                value={formValues.taskDescription}
                onChange={handleChange}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>

      <TableContainer style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={poppinsFont}>Policy Number</TableCell>
              <TableCell sx={poppinsFont}>Date</TableCell>
              <TableCell sx={poppinsFont}>Due Date</TableCell>
              <TableCell sx={poppinsFont}>Status</TableCell>
              <TableCell sx={poppinsFont}>Assigned To</TableCell>
              <TableCell sx={poppinsFont}>Subject</TableCell>
              <TableCell sx={poppinsFont}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell sx={poppinsFont}>{row.policyNumber}</TableCell>
                  <TableCell sx={poppinsFont}>{row.date}</TableCell>
                  <TableCell sx={poppinsFont}>{row.dueDate}</TableCell>
                  <TableCell sx={poppinsFont}>{row.status}</TableCell>
                  <TableCell sx={poppinsFont}>{row.taskTo}</TableCell>
                  <TableCell sx={poppinsFont}>
                    <IconButton onClick={() => handleExpandClick(index)}>
                      {expandedRows.includes(index) ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                    </IconButton>
                    {row.taskDescription}
                  </TableCell>
                  <TableCell sx={poppinsFont}>
                    <IconButton onClick={handleClickOpen}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={expandedRows.includes(index)} timeout="auto" unmountOnExit>
                      <Box margin={1} sx={poppinsFont}>
                        <strong>Task Description:</strong> {row.taskDescription}
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={data.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 15]}
      />
    </Box>
  );
};

export default TaskComponent;

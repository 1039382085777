import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, MenuItem, Checkbox, FormControlLabel, Grid, FormControl, Select } from '@mui/material';
import { saveCarrierAction, updateInsuranceCarrierAction, getCarrierAction } from '../../../redux/actions/agencyActions';
import { useDispatch } from 'react-redux';
import { stateList } from '../../../common/StaticFieldList';
import { useLocation, useNavigate } from 'react-router-dom';

const AddInsuranceCarrier = () => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // Email format

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const editUserDetail = location.state?.selectedUser;

    const carrierStates = [
    { label: 'Florida', value: 'FL' },
    { label: 'Texas', value: 'TX' },
  ];

  const [formData, setFormData] = useState({
    insuranceCarrier: '',
    carrierState: '',
    carrierProgramDescription: '',
    agencyUserName: '',
    agencyPassword: '',
    confirmPassword: '',
    selectLogin: '',
    producerCode: '',
    subCode: '',
    ratingUserName: '',
    ratingPassword: '',
    agencyName: '',
    accountNumber: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    phone: '',
    fax: '',
    website: '',
    email: '',
    alternatePhone: '',
    enableTwoFactorAuthentication: false,
    singleSignOnURL: '',
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (editUserDetail) {
      setFormData({
        ...editUserDetail,
        agencyPassword: '',
        confirmPassword: '',
      });
    }
  }, [editUserDetail]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const formattedValue = name === 'phone' || name === 'alternatePhone' ? formatPhoneNumber(value) : value;

    // Conditional validation for agencyUserName to accept only a valid email format if it looks like an email
    if (name === 'agencyUserName') {
      const isEmailFormat = value.includes('@')  || value.includes('.')  || value.includes('.com')  || value.includes('.co') || value.includes('.in');
      setErrors((prevErrors) => ({
        ...prevErrors,
        agencyUserName: isEmailFormat && !emailRegex.test(value) ? 'Please enter a valid email address.' : ''
      }));
    }

    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : formattedValue,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/; // At least 1 uppercase, 1 special char, min 8 chars

    // Validate agencyUserName as an email if it appears in an email format
    const isUserNameEmailFormat = formData.agencyUserName.includes('@') || formData.agencyUserName.includes('.') && formData.agencyUserName.includes('.com')  || formData.agencyUserName.includes('.co')  || formData.agencyUserName.includes('.in');
    if (isUserNameEmailFormat && !emailRegex.test(formData.agencyUserName)) {
      newErrors.agencyUserName = 'Please enter a valid email address.';
    }

    // Email validation for email field
    if (formData.email && !emailRegex.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address.';
    }

    // Password validation
    if (!editUserDetail) {
      if (!formData.agencyPassword) {
        newErrors.agencyPassword = 'Password is required.';
      } else if (!passwordRegex.test(formData.agencyPassword)) {
        newErrors.agencyPassword = 'Password must be at least 8 characters, include one uppercase letter and one special character.';
      }

      if (formData.agencyPassword !== formData.confirmPassword) {
        newErrors.confirmPassword = 'Passwords do not match.';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    if (phoneNumber.length <= 3) {
      return `(${phoneNumber}`;
    } else if (phoneNumber.length <= 6) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    } else if (phoneNumber.length <= 10) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    } else {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setFormData({
        insuranceCarrier: '',
        selectLogin: '',
        carrierProgramDescription: '',
        agencyUserName: '',
        agencyPassword: '',
        confirmPassword: '',
        agencyName: '',
        accountNumber: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
        phone: '',
        fax: '',
        website: '',
        email: '',
        alternatePhone: '',
        enableTwoFactorAuthentication: false,
        singleSignOnURL: '',
      });
      setErrors({});

      if (editUserDetail) {
        const updatedFormValues = {
          ...formData,
          agencyId: editUserDetail.agencyId,
        };
        dispatch(updateInsuranceCarrierAction(updatedFormValues));
        navigate('/managecarrier');
        dispatch(getCarrierAction());
      } else {
        dispatch(saveCarrierAction(formData, (response) => {
          if (response) navigate('/managecarrier');
        }));
      }
    }
  };

  const cancelAddInsuranceCarrier = () => {
    navigate('/managecarrier');
  };

  const labelStyle = {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "19.6px",
    textAlign: "left",
    marginBottom: "8px",
  };

  return (
    <Box sx={{ padding: '40px', position: 'relative', zIndex: 10 }}>

      <Typography variant="h5" sx={{ marginBottom: '20px' }}>
        {editUserDetail ? 'Edit Insurance Carrier' : 'Add Insurance Carrier'}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
          <Typography
                variant="body1"
                sx={labelStyle}
              >
                Insurance Company Name
                </Typography>
            <TextField
              id="insuranceCarrier"
              name="insuranceCarrier"

              fullWidth
              value={formData.insuranceCarrier}
              onChange={(e) => {
                const alphanumericValue = e.target.value.replace(/[^a-z0-9\s]/gi, ''); 
                handleChange({ target: { name: "insuranceCarrier", value: alphanumericValue } });
              }}
              error={!!errors.insuranceCarrier}
              helperText={errors.insuranceCarrier}
              required
              inputProps={{ maxLength: 30 }}

            />
          </Grid>
          <Grid item xs={3}>
          <Typography
                variant="body1"
                sx={labelStyle}
              >
                Carrier State
                </Typography>
            <TextField
              id="carrierState"
              name="carrierState"
              Carrier State
              select
              fullWidth
              value={formData.carrierState}
              onChange={handleChange}
              required

            >
              {carrierStates.map((state) => (
                 <MenuItem key={state.value} value={state.value}>
                 {state.label}
               </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={3}>
          <Typography
                variant="body1"
                sx={labelStyle}
              >
                 Company Program Description
                </Typography>
            <TextField
              id="programDescription"
              name="carrierProgramDescription"

              fullWidth
              value={formData.carrierProgramDescription}
              onChange={(e) => {
                const alphanumericValue = e.target.value.replace(/[^a-z0-9\s]/gi, ''); 
                handleChange({ target: { name: "carrierProgramDescription", value: alphanumericValue } });
              }}
              error={!!errors.carrierProgramDescription}
              helperText={errors.carrierProgramDescription}
              required
              inputProps={{ maxLength: 30 }}

            />
          </Grid>
          <Grid item xs={3}>
          <Typography
                variant="body1"
                sx={labelStyle}
              >
                 User Name
                </Typography>
                <TextField
              id="userName"
              name="agencyUserName"
              fullWidth
              value={formData.agencyUserName}
              onChange={handleChange}
              error={!!errors.agencyUserName}
              helperText={errors.agencyUserName}
              required
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
          <Grid item xs={3}>
          <Typography
                variant="body1"
                sx={labelStyle}
              >
                 Password
                </Typography>
            <TextField
              id="password"
              name="agencyPassword"

              type="password"
              fullWidth
              value={formData.agencyPassword}
              onChange={handleChange}
              error={!!errors.agencyPassword}
              helperText={errors.agencyPassword}
              required={!editUserDetail}
              inputProps={{ maxLength: 30 }}
            />
          </Grid>
          <Grid item xs={3}>
          <Typography
                variant="body1"
                sx={labelStyle}
              >
                 Confirm Password
                </Typography>
            <TextField
              id="confirmPassword"
              name="confirmPassword"

              type="password"
              fullWidth
              value={formData.confirmPassword}
              onChange={handleChange}
              error={!!errors.confirmPassword}
        helperText={errors.confirmPassword}
        required={!editUserDetail}
              inputProps={{ maxLength: 30 }}

            />
          </Grid>
          <Grid item xs={3}>
          <Typography
                variant="body1"
                sx={labelStyle}
              >
                 Select Login
                </Typography>
            <TextField
              id="selectLogin"
              name="selectLogin"

              type="text"
              fullWidth
              value={formData.selectLogin}
              onChange={handleChange}
              error={!!errors.selectLogin}
        helperText={errors.selectLogin}
              // required
              inputProps={{ maxLength: 10 }}
            />
          </Grid>

          <Grid item xs={3}>
          <Typography
                variant="body1"
                sx={labelStyle}
              >
                 Producer Code
                </Typography>
            <TextField
              id="producerCode"
              name="producerCode"

              fullWidth
              value={formData.producerCode}
              onChange={(e) => {
                const numericValue = e.target.value.replace(/\D/g, ""); 
                handleChange({ target: { name: "producerCode", value: numericValue } });
              }}
              inputProps={{ maxLength: 10 }}
            />
          </Grid>
          <Grid item xs={3}>
          <Typography
                variant="body1"
                sx={labelStyle}
              >
                 Sub Code
                </Typography>
            <TextField
              id="subCode"
              name="subCode"

              fullWidth
              value={formData.subCode}
              onChange={(e) => {
                const numericValue = e.target.value.replace(/\D/g, ""); 
                handleChange({ target: { name: "subCode", value: numericValue } });
              }}
              inputProps={{ maxLength: 10 }}
            />
          </Grid>
          <Grid item xs={3}>
          <Typography
                variant="body1"
                sx={labelStyle}
              >
                 Rating User Name
                </Typography>
            <TextField
              id="ratingUserName"
              name="ratingUserName"

              fullWidth
              value={formData.ratingUserName}
              onChange={(e) => {
                const alphanumericValue = e.target.value.replace(/[^a-z0-9\s]/gi, ''); 
                handleChange({ target: { name: "ratingUserName", value: alphanumericValue } });
              }}
              inputProps={{ maxLength: 30 }}
            />
          </Grid>
          <Grid item xs={3}>
          <Typography
                variant="body1"
                sx={labelStyle}
              >
                 Rating Password
                </Typography>
            <TextField
              id="ratingPassword"
              name="ratingPassword"

              type="password"
              fullWidth
              value={formData.ratingPassword}
              onChange={handleChange}
              inputProps={{ maxLength: 30 }}
            />
          </Grid>
          <Grid item xs={3}>
          <Typography
                variant="body1"
                sx={labelStyle}
              >
                 Agency Name
                </Typography>
            <TextField
              id="agencyName"
              name="agencyName"

              fullWidth
              value={formData.agencyName}
              onChange={(e) => {
                const alphanumericValue = e.target.value.replace(/[^a-z0-9\s]/gi, ''); 
                handleChange({ target: { name: "agencyName", value: alphanumericValue } });
              }}
              error={!!errors.agencyName}
        helperText={errors.agencyName}
        inputProps={{ maxLength: 70 }}
        required
            />
          </Grid>
          <Grid item xs={3}>
          <Typography
                variant="body1"
                sx={labelStyle}
              >
                 Account Number
                </Typography>
            <TextField
              id="accountNumber"
              name="accountNumber"

              fullWidth
              value={formData.accountNumber}
              onChange={(e) => {
                const numericValue = e.target.value.replace(/\D/g, ""); 
                handleChange({ target: { name: "accountNumber", value: numericValue } });
              }}
              error={!!errors.accountNumber}
        helperText={errors.accountNumber}
        inputProps={{ maxLength: 20 }}
            />
          </Grid>
          <Grid item xs={3}>
          <Typography
                variant="body1"
                sx={labelStyle}
              >
                 Address Line 1
                </Typography>
            <TextField
              id="address1"
              name="address1"

              fullWidth
              value={formData.address1}
              onChange={(e) => {
                const alphanumericValue = e.target.value.replace(/[^a-z0-9\s]/gi, ''); 
                handleChange({ target: { name: "address1", value: alphanumericValue } });
              }}
              error={!!errors.address1}
        helperText={errors.address1}
        inputProps={{ maxLength: 50 }}
            />
          </Grid>
          <Grid item xs={3}>
          <Typography
                variant="body1"
                sx={labelStyle}
              >
                 Address Line 2
                </Typography>
            <TextField
              id="address2"
              name="address2"

              fullWidth
              value={formData.address2}
              onChange={(e) => {
                const alphanumericValue = e.target.value.replace(/[^a-z0-9\s]/gi, ''); 
                handleChange({ target: { name: "address2", value: alphanumericValue } });
              }}
              error={!!errors.address2}
        helperText={errors.address2}
        inputProps={{ maxLength: 50 }}
            />
          </Grid>
          <Grid item xs={3}>
          <Typography
                variant="body1"
                sx={labelStyle}
              >
                 City
                </Typography>
            <TextField
              id="city"
              name="city"

              fullWidth
              value={formData.city}
              onChange={(e) => {
                const alphanumericValue = e.target.value.replace(/[^a-z0-9\s]/gi, ''); 
                handleChange({ target: { name: "city", value: alphanumericValue } });
              }}
              error={!!errors.city}
        helperText={errors.city}
        inputProps={{ maxLength: 30 }}
            />
          </Grid>
          <Grid item xs={3}>
  <FormControl fullWidth required>
    <Typography
      style={{
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "19.6px",
        textAlign: "left",
        marginBottom: "8px",
      }}
    >
      State
    </Typography>
    <Select
      name="state"
      value={formData.state}
      onChange={handleChange}
      // label="State"
    >
      {stateList.map((state) => (
        <MenuItem key={state.value} value={state.value}>
          {state.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</Grid>
          <Grid item xs={3}>
          <Typography
                variant="body1"
                sx={labelStyle}
              >
                 Zip Code
                </Typography>
            <TextField
              id="zipCode"
              name="zipCode"

              fullWidth
              value={formData.zipCode}
              onChange={(e) => {
                const numericValue = e.target.value.replace(/\D/g, ""); 
                handleChange({ target: { name: "zipCode", value: numericValue } });
              }}
              error={!!errors.zipCode}
        helperText={errors.zipCode}
        inputProps={{ maxLength: 10 }}
            />
          </Grid>

          <Grid item xs={3}>
          <Typography
                variant="body1"
                sx={labelStyle}
              >
                 Phone
                </Typography>
            <TextField
              id="phone"
              name="phone"

              fullWidth
              value={formData.phone}
              onChange={(e) => {
                const numericValue = e.target.value.replace(/\D/g, ""); 
                handleChange({ target: { name: "phone", value: numericValue } });
              }}
              error={!!errors.phone}
        helperText={errors.phone}
        inputProps={{ maxLength: 10 }}

            />
          </Grid>


          <Grid item xs={3}>
          <Typography
                variant="body1"
                sx={labelStyle}
              >
                 Fax
          </Typography>

            <TextField
              id="fax"
              name="fax"
              fullWidth
              value={formData.fax}
              onChange={(e) => {
                const numericValue = e.target.value.replace(/\D/g, ""); 
                handleChange({ target: { name: "fax", value: numericValue } });
              }}
              error={!!errors.fax}
        helperText={errors.fax}
        inputProps={{ maxLength: 10 }}
            />
          </Grid>
          <Grid item xs={3}>
          <Typography
                variant="body1"
                sx={labelStyle}
              >
                 Website
                </Typography>
            <TextField
              id="website"
              name="website"

              fullWidth
              value={formData.website}
              onChange={handleChange}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xs={3}>
          <Typography
                variant="body1"
                sx={labelStyle}
              >
                 Email
                </Typography>
            <TextField
              id="email"
              name="email"
              fullWidth
              value={formData.email}
              onChange={(e) => {
                const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                const isValidEmail = emailPattern.test(e.target.value);
                handleChange(e); 
          
                if (!isValidEmail) {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    email: "Please enter a valid email address.",
                  }));
                } else {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    email: "",
                  }));
                }
              }}
              error={!!errors.email}
              helperText={errors.email}
              inputProps={{ maxLength: 50 }}
              required
            />
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1" sx={labelStyle}>
              Alternate Phone
            </Typography>
            <TextField
              id="alternatePhone"
              name="alternatePhone"
              fullWidth
              value={formData.alternatePhone}
              onChange={handleChange} // This now applies the phone formatting
              error={!!errors.alternatePhone}
              helperText={errors.alternatePhone}
              inputProps={{ maxLength: 14 }} // Adjust maxLength for formatted phone
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id="twoFactorAuth"
                  name="enableTwoFactorAuthentication"
                  checked={formData.enableTwoFactorAuthentication}
                  onChange={handleChange}
                />
              }
              label="Enable Two-Factor Authentication"
            />
          </Grid>
          <Grid item xs={3}>
          <Typography
                variant="body1"
                sx={labelStyle}
              >
                 Single Sign-On URL
                </Typography>
            <TextField
              id="singleSignOnUrl"
              name="singleSignOnURL"
              fullWidth
              value={formData.singleSignOnURL}
              onChange={handleChange}
              inputProps={{ maxLength: 30 }}
            />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="primary" type="submit" id="submitButton" sx={{ marginTop: '20px', marginRight: '15px' }}>
              {editUserDetail ? 'Update' : 'Save'}
            </Button>
            <Button variant="outlined" color="secondary" onClick={cancelAddInsuranceCarrier} id="cancelButton" sx={{ marginTop: '20px' }}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default AddInsuranceCarrier;

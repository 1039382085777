import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, RadioGroup, Grid, FormLabel, OutlinedInput, Select, MenuItem, Box, FormControlLabel, Radio, Autocomplete,
    TextField  } from '@mui/material';
import FormLabelText from '../../../common/FormLabelText';
import AddressSection from './AddressSection';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';

const LeadSection = () => {
    const leadsData = useSelector((state) => state?.newleads?.getLeadById);
    const [customer, setCustomer] = useState(leadsData.customerType);
   // const [country, setCountry] = useState('USA');
    const [status, setStatus] = useState(leadsData.status);

    const handleChange = (event) => {
        setCustomer(event.target.value);
    };

    // const handleChangeCountry = (event) => {
    //     setCountry(event.target.value);
    // }
    const ownerNameList = [
        { label: 'Bertha Boxer'},
        { label: 'John'},
        { label: 'Steve'},
        { label: 'Mark'},
    ];
    return(
        <>
            <Box sx={{ border: '#ccc solid 1px', borderRadius: '4px', padding: '20px', margin: '40px 0 0 0', position: 'relative', }}>
                <FormLabelText labelText="Lead" />
                <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={6} md={4} sx={{margin: '15px 0 0 0'}}>
                    <FormControl fullWidth>
                        <Box sx={{ padding: '0 0 10px 0' }}>
                            <label htmlFor="ownerName">Owner Name</label>
                        </Box>
                        <Autocomplete
                            disablePortal
                            id="comboboxdemo"
                            options={ownerNameList}
                            defaultValue={ownerNameList[0]}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </FormControl>        
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} sx={{ margin: '15px 0 0 0' }}>
  <FormControl fullWidth>
    <Box sx={{ padding: '0 0 10px 0' }}>
      <label id="customertypelabel">Customer Type</label>
    </Box>
    <Grid container spacing={2} sx={{ margin: '0' }}>
      <Grid item xs={11} md={11}>
        <Select
          sx={{ width: '100%' }}
          onChange={handleChange}
          labelId="customer-type-label"
          id="customertypeselect" 
          name="customerType"
          value={customer}
        >
          <MenuItem id="personallinesoption" value="Personal Lines">Personal Lines</MenuItem> 
          <MenuItem id="commercialoption" value="Commercial">Commercial</MenuItem> 
        </Select>
      </Grid>
                                <Grid xs={1} md={1} sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Link><EditIcon /></Link>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>    
                </Grid>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={6} md={4} sx={{margin: '15px 0 0 0'}}>
                        <FormControl fullWidth>
                            <Box sx={{
                                padding: '0 0 10px 0',
                            }}>
                                <label id="firstname">First Name</label>
                            </Box>
                            <Grid container spacing={2} sx={{margin:'0'}}>
                                <Grid xs={11} md={11}>
                                    <OutlinedInput sx={{ width: '100%'}} placeholder="First Name" value={leadsData.firstName} name="firstName" id="first_name" type="text" required />
                                </Grid>
                                <Grid xs={1} md={1} sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Link>
                                        <EditIcon />
                                    </Link>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} sx={{margin: '15px 0 0 0'}}>
                        <FormControl fullWidth>
                            <Box sx={{
                                    padding: '0 0 10px 0',
                                }}>
                                <label id="middlename">Middle Name </label>
                            </Box>

                            <Grid container spacing={2} sx={{margin:'0'}}>
                                <Grid xs={11} md={11}>
                                    <OutlinedInput sx={{ width: '100%'}} placeholder="Middle Name" id="middle_name" type="text" />
                                </Grid>
                                <Grid xs={1} md={1} sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Link><EditIcon /></Link>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} sx={{margin: '15px 0 0 0'}}>
                        <FormControl fullWidth>
                            <Box sx={{
                                padding: '0 0 10px 0',
                            }}>
                                <label id="lastname">Last Name</label>
                            </Box>

                            <Grid container spacing={2} sx={{margin:'0'}}>
                                <Grid xs={11} md={11}>
                                    <OutlinedInput sx={{ width: '100%'}} placeholder='Last Name' id="last_name" type="text" required />
                                </Grid>
                                <Grid xs={1} md={1} sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Link><EditIcon /></Link>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {/* <Grid item xs={12} sm={6} md={4} sx={{margin: '15px 0 0 0'}}>
                        <FormControl fullWidth>
                            <Box sx={{
                                    padding: '0 0 10px 0',
                                }}>
                                <label htmlFor="name">Suffix</label>
                            </Box>
                            <Grid container spacing={2} sx={{margin:'0'}}>
                                <Grid xs={11} md={11}>
                                    <OutlinedInput sx={{ width: '100%'}} placeholder='Enter the value' id="suffix" type="text" />
                                </Grid>
                                <Grid xs={1} md={1} sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Link><EditIcon /></Link>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>     */}
                    <Grid item xs={12} sm={6} md={4} sx={{margin: '15px 0 0 0'}}>
                        <FormControl fullWidth>
                            <Box sx={{
                                padding: '0 0 10px 0',
                            }}>
                                <label id="status">Status</label>
                            </Box>                            
                            <Grid container spacing={2} sx={{margin:'0'}}>
                                <Grid xs={11} md={11}>
                                    <Select
                                        sx={{ width: '100%'}}
                                        onChange={handleChange}
                                        labelId="demo-simple-select-label"
                                        id="demosimpleselect"
                                        value={status}
                                    >
                                        <MenuItem value="">Select Status</MenuItem>
                                        <MenuItem value="Active">Active</MenuItem>
                                        <MenuItem value="New">New</MenuItem>
                                        <MenuItem value="In progress">In progress</MenuItem>
                                        <MenuItem value="Rejected">Rejected</MenuItem>
                                        <MenuItem value="Completed">Completed</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid xs={1} md={1} sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Link><EditIcon /></Link>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={4} sx={{margin: '15px 0 0 0'}}>
                        <FormControl fullWidth>
                            <Box sx={{
                                padding: '0 0 10px 0',
                            }}>
                                <label>Country</label>
                            </Box>
                            <Grid container spacing={2} sx={{margin:'0'}}>
                                <Grid xs={11} md={11}>
                                    <Select
                                        sx={{ width: '100%'}}
                                        onChange={handleChangeCountry}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={country}
                                    >
                                        <MenuItem value="USA">USA</MenuItem>
                                        <MenuItem value="INDIA">INDIA</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid xs={1} md={1} sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Link><EditIcon /></Link>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid> */}
                </Grid>
                {/* <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={6} md={4} sx={{margin: '15px 0 0 0', color:'#242929'}}>
                        <FormControl sx={{ color:'#242929', fontWeight: '700',}}>
                            <FormLabel id="citizen">Is USA Citizen?</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="citizen"
                                defaultValue="yes"
                                name="radio-buttons-group"                                
                            >
                                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="no" control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>                 */}
            </Box>
            <AddressSection />
        </>
    )
}

export default LeadSection;
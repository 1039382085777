import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  TablePagination,
} from '@mui/material';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { getDriverAction, saveDriverAction } from '../../../redux/actions/driverInfoActions';
import { getDetailPolicyAction } from '../../../redux/actions/policyActions';

const PolicyDriver = ({ policyId }) => {
  const [open, setOpen] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const dispatch = useDispatch();
  const customerData = useSelector((state) => state.policy.allDetailPolicy);
  const driverData = customerData?.drivers || [];

  console.log(customerData, 'customer driverData driver id')
  
  console.log(policyId, 'driver policy id')
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [drivers, setDrivers] = useState(driverData);
  const [errors, setErrors] = useState({});
  const [newDriver, setNewDriver] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    gender: "",
    relationshipToPolicyholder: "",
    driversLicenseState: "",
    driversLicenseNumber: "",
    studentType: "",
    maritalStatus: "",
    dateLicensed: "",
    policyId: policyId || null,
  });


  useEffect(() => {
    if (policyId) {
      dispatch(getDetailPolicyAction(policyId));
    }
  }, [dispatch, policyId]);

  useEffect(() => {
    if (driverData !== drivers) {
      setDrivers(driverData);      
    }
    getDriverAction();
  }, [driverData]);

  const handleDateChange = (date, fieldName) => {
    const formattedDate = date ? dayjs(date).format("MM-DD-YYYY") : "";
    setNewDriver((prev) => ({ ...prev, [fieldName]: formattedDate }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewDriver((prev) => ({ ...prev, [name]: value }));
    if (value.trim()) setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validate = () => {
    const tempErrors = {};
    if (!newDriver.firstName.trim()) tempErrors.firstName = 'First name is required';
    if (!newDriver.lastName.trim()) tempErrors.lastName = 'Last name is required';
    if (!newDriver.dateOfBirth.trim()) tempErrors.dateOfBirth = 'Date of birth is required';
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleAddDriver = () => {
    if (validate()) {
      setDrivers([...drivers, newDriver]);
      dispatch(saveDriverAction(newDriver, () => {
        setNewDriver({
          firstName: "",
          lastName: "",
          dateOfBirth: "",
          gender: "",
          relationshipToPolicyholder: "",
          driversLicenseState: "",
          driversLicenseNumber: "",
          studentType: "",
          maritalStatus: "",
          dateLicensed: "",
          policyId: policyId || null,
        });
        setOpen(false);
      }));
    }
  };

  const handleEditClick = (driver) => {
    setSelectedDriver(driver);
    setNewDriver(driver);
    setOpen(true);
  };

  const handleCancel = () => {
    setNewDriver({
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      gender: "",
      relationshipToPolicyholder: "",
      driversLicenseState: "",
      driversLicenseNumber: "",
      studentType: "",
      maritalStatus: "",
      dateLicensed: "",
      policyId: policyId || null,
    });
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRows = drivers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


  return (
    <div>
      {paginatedRows.map((driver, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography id={`driver-${index + 1}`}>Driver: {index + 1}</Typography>
            <IconButton onClick={() => handleEditClick(driver)} size="small" sx={{ marginLeft: "auto" }}>
              <EditIcon />
            </IconButton>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell><strong>First Name:</strong></TableCell>
                    <TableCell>{driver.firstName}</TableCell>
                    <TableCell><strong>Relationship:</strong></TableCell>
                    <TableCell>{driver.relationshipToPolicyholder}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><strong>Last Name:</strong></TableCell>
                    <TableCell>{driver.lastName}</TableCell>
                    <TableCell><strong>Driver License State:</strong></TableCell>
                    <TableCell>{driver.driversLicenseState}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><strong>Date of Birth:</strong></TableCell>
                    <TableCell>{driver.dateOfBirth}</TableCell>
                    <TableCell><strong>Driver License:</strong></TableCell>
                    <TableCell>{driver.driversLicenseNumber}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><strong>Gender:</strong></TableCell>
                    <TableCell>{driver.gender}</TableCell>
                    <TableCell><strong>Licensed Date:</strong></TableCell>
                    <TableCell>{driver.dateLicensed}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><strong>Marital Status:</strong></TableCell>
                    <TableCell>{driver.maritalStatus}</TableCell>
                    <TableCell><strong>Student Type:</strong></TableCell>
                    <TableCell>{driver.studentType}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ))}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={drivers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Button variant="contained" color="primary" onClick={() => setOpen(true)} style={{ marginTop: '20px' }}>
        Add Driver
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{selectedDriver ? "Edit Driver" : "Add Driver"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <label>First Name</label>
              <TextField
                name="firstName"
                value={newDriver.firstName}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label>Last Name</label>
              <TextField
                name="lastName"
                value={newDriver.lastName}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label>Date of Birth</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={dayjs(newDriver.dateOfBirth)}
                  onChange={(date) => handleDateChange(date, 'dateOfBirth')}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <label>Gender</label>
              <FormControl fullWidth>
                <Select
                  name="gender"
                  value={newDriver.gender}
                  onChange={handleChange}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <label>Marital Status</label>
              <FormControl fullWidth>
                <Select
                  name="maritalStatus"
                  value={newDriver.maritalStatus}
                  onChange={handleChange}
                >
                  <MenuItem value="Single">Single</MenuItem>
                  <MenuItem value="Married">Married</MenuItem>
                  <MenuItem value="Divorced">Divorced</MenuItem>
                  <MenuItem value="Widowed">Widowed</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <label>Student Type</label>
              <FormControl fullWidth>
                <Select
                  name="studentType"
                  value={newDriver.studentType}
                  onChange={handleChange}
                >
                  <MenuItem value="Full-Time">Full-Time</MenuItem>
                  <MenuItem value="Part-Time">Part-Time</MenuItem>
                  <MenuItem value="Not a Student">Not a Student</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>

              <label>Licensed Date</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={dayjs(newDriver.dateLicensed)}
                  onChange={(date) => handleDateChange(date, 'dateLicensed')}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <label>Driver License Number</label>
              <TextField
                name="driversLicenseNumber"
                value={newDriver.driversLicenseNumber}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label>Driver License State</label>
              <TextField
                name="driversLicenseState"
                value={newDriver.driversLicenseState}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="secondary">Cancel</Button>
          <Button onClick={handleAddDriver} color="primary">{selectedDriver ? "Save" : "Add"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PolicyDriver;

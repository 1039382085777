



import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Box,
  Grid,
  FormControl,
  Typography,
  MenuItem,
  TextField,
  Select,
  Checkbox,
  FormControlLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useDispatch, useSelector } from 'react-redux';
import { saveNoteAction } from '../../redux/actions/noteActions';
import { getAllPolicyAction } from '../../redux/actions/policyActions';
import { updateNoteAction,getAllNoteAction } from '../../redux/actions/noteActions';

// const validationRules = [
//   {
//     fieldName: 'notesSource',
//     label: 'Select Policy',
//     type: 'dropdown',
//     options: ['USER', 'UAD381140']
//   }
// ];

const validationRules1 = [
  { fieldName: "message", label: "Notes", type: "text" , id:"message"},
];

function AddNotes({ setshowNote, setShowButton }) {
  const { control, handleSubmit, formState: { errors },reset } = useFormContext();
  const dispatch = useDispatch();
  const policyResponse = useSelector(
    (state) => state.policy.allPolicies
  );
 const policyvalue=policyResponse.policiesList;
   console.log("policies in notes are",policyvalue);
  let policyId = [];
  if (Array.isArray(policyvalue)) {
    policyId= policyvalue.map((policy) => ({
      value: policy.id,
      // label: carrier.carrierName,
    }));
  }
  console.log("policyId",policyId);

  const validationRules = [
    {
      fieldName: 'policyId',
      label: 'Select Policy',
      type: 'dropdown',
      options: policyId,
      id: "policyId"
    }
  ];
  // const getCreateUserSuccess = async () => {
  //   // navigate('/allaccount')
  //   dispatch(getAllNoteAction());
  // }
  const handleSuccess = () => {
    dispatch(getAllNoteAction());
    reset();
    setshowNote(false);

  };
  useEffect(() => {
    dispatch(getAllPolicyAction());
  }, []);

  const onSubmit = (data) => {
    const formData = {
      ...data,
      // policyId: 3, // Assuming 123 is the default PolicyId
      notesSource:"USER",
    };

    if (setShowButton) {
      // Create new policy
      dispatch(saveNoteAction(formData, handleSuccess));

    } else {
      // Update existing policy
      dispatch(updateNoteAction(data,handleSuccess));


    }

    // dispatch(saveNoteAction(formData, getCreateUserSuccess));

};


  return (
    <Dialog open={true} onClose={() => setshowNote(false)} fullWidth maxWidth="md">
      <DialogTitle id="addnotesdialogtitle">
        Add Notes
        <IconButton
        id="closenotesdialogbutton"
          aria-label="close"
          onClick={() => setshowNote(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Grid container spacing={3}>
              {validationRules.map(({ fieldName, label, type, options, rules, defaultValue }) => (
                <Grid key={fieldName} item xs={12} sm={6}>
                  <FormControl fullWidth error={!!errors[fieldName]}>
                    <Typography>{label}</Typography>
                    <Controller
                      name={fieldName}
                      control={control}
                      rules={rules}
                      defaultValue={defaultValue}
                      render={({ field }) => {
                        if (type === 'text' || type === 'number' || type === 'time') {
                          return (
                            <TextField
                              {...field}
                              type={type}
                              fullWidth
                              error={!!errors[fieldName]}
                            />
                          );
                        } else if (type === 'dropdown' || type === 'select') {
                          return (
                            <Select
                              {...field}
                              fullWidth
                              error={!!errors[fieldName]}
                            >
                              {options.map((option, index) => (
                                // <MenuItem key={index} value={option}>
                                //   {option}
                                // </MenuItem>
                                <MenuItem
                                  key={index}
                                  // placeholder={label}
                                  value={option.value}
                                >
                                  {option.value}
                                </MenuItem>
                              ))}
                            </Select>
                          );
                        } else if (type === 'date') {
                          return (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                {...field}
                                value={field.value ? dayjs(field.value) : null}
                                renderInput={(params) => (
                                  <TextField {...params} fullWidth error={!!errors[fieldName]} />
                                )}
                              />
                            </LocalizationProvider>
                          );
                        } else if (type === 'checkbox') {
                          return (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...field}
                                  checked={field.value}
                                  onChange={(e) => field.onChange(e.target.checked)}
                                />
                              }
                              label={label}
                            />
                          );
                        }
                        return null;
                      }}
                    />
                    <Typography variant="caption" color="error">
                      {errors[fieldName]?.message}
                    </Typography>
                  </FormControl>
                </Grid>
              ))}
            </Grid>
            <Grid container spacing={3}>
              {validationRules1.map(({ fieldName, label, type, rules, defaultValue }) => (
                <Grid key={fieldName} item xs={12}>
                  <FormControl fullWidth error={!!errors[fieldName]}>
                    <Typography>{label}</Typography>
                    <Controller
                      name={fieldName}
                      control={control}
                      rules={rules}
                      defaultValue={defaultValue}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type={type}
                          fullWidth
                          multiline
                          minRows={4}
                          error={!!errors[fieldName]}
                        />
                      )}
                    />
                    <Typography variant="caption" color="error">
                      {errors[fieldName]?.message}
                    </Typography>
                  </FormControl>
                </Grid>
              ))}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setshowNote(false)} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button type="submit" color="primary" variant="contained">
            Save
          </Button> */}
           <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {!setShowButton ? (
        <>
          <Button type="submit"   variant="contained" color="primary" id="updatebutton" sx={{ marginRight: 2 }}>
          Update
          </Button>
          <Button
          //  onClick={() =>  setshowNote(false)}
          onClick={() => {
            reset(); // Reset the form
            setshowNote(false); // Close the dialog
          }}
             variant="outlined"
             id="cancelbutton"
              color="primary">
            Cancel
          </Button>
        </>
      ) : (
        <>
        <Button type="submit"  variant="contained" id="savebutton" color="primary">
          Save
        </Button>
        <Button
        // onClick={() => setshowNote(false)}
        onClick={() => {
          reset(); // Reset the form
          setshowNote(false); // Close the dialog
        }}
          variant="outlined"
          id="cancelbutton"
          color="primary">
            Cancel
          </Button>
        </>

      )}
            </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AddNotes;

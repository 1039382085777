import * as formLayoutServices from '../../services/formLayoutServices';
import {
    START_LOADING,
    STOP_LOADING
} from "./actionTypes"
import { openSessionTimeoutModal } from './sessionTimeOutActions';

export const getFormLayout = (customerId, type) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'GET_FORM_LAYOUT' });
    const token = getState().auth.token || sessionStorage.getItem("token");;
    const response = await formLayoutServices.getFormLayout(customerId, type, token);
    dispatch({ type: STOP_LOADING, service: 'GET_FORM_LAYOUT' });
    dispatch({ type: 'GET_FORM_LAYOUT_SUCCESS', payload: response });
  } catch (error) {
    if(error?.includes("403")) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'GET_FORM_LAYOUT' });
      dispatch({ type: 'GET_FORM_LAYOUT_FAILURE', payload: error || 'Get form layout service failed. Please try again.' });
    }
  }
};

export const saveFormLayout = (formData, callbackfn) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'SAVE_FORM_LAYOUT' });
    const token = getState().auth.token || sessionStorage.getItem("token");;
    const response = await formLayoutServices.saveFormLayout(formData, token);
    dispatch({ type: STOP_LOADING, service: 'SAVE_FORM_LAYOUT' });
    if(response) {
      callbackfn();
    }
    dispatch({ type: 'SAVE_FORM_LAYOUT_SUCCESS', payload: response });
  } catch (error) {
    if(error?.includes("403")) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'SAVE_FORM_LAYOUT' });
      dispatch({ type: 'SAVE_FORM_LAYOUT_FAILUTE', payload: error || 'Save form layout service failed. Please try again.' });
    }
  }
};

export const updateFormLayout = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'UPDATE_FORM_LAYOUT' });
    const token = getState().auth.token || sessionStorage.getItem("token");;
    const response = await formLayoutServices.updateFormLayout(formData, token);
    dispatch({ type: STOP_LOADING, service: 'UPDATE_FORM_LAYOUT' });
    dispatch({ type: 'UPDATE_FORM_LAYOUT_SUCCESS', payload: response });
  } catch (error) {
    // console.log(error, 'error in action')
    if(error?.includes("403")) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'UPDATE_FORM_LAYOUT' });
      dispatch({ type: 'UPDATE_FORM_LAYOUT_FAILUTE', payload: error || 'Update form layout service failed. Please try again.' });
    }
  }
};
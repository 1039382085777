// Dropbox.js
import React from 'react';
import { DropzoneAreaBase } from 'react-mui-dropzone';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    dropzone: {
        backgroundColor: 'lightblue',
        width: '600px',
        minHeight: '300px', // Ensure minHeight for consistent height
        border: '2px dashed blue',
        borderRadius: '8px',
        cursor: 'pointer',
        marginLeft: '16px',
    },
    image: {
        maxWidth: '80px',  // Ensure images don't exceed their container width
        maxHeight: '80px', // Ensure images don't exceed their container height
    },
}));

const handlePreviewIcon = (fileObject, classes) => {
    const { type } = fileObject.file;
    const base64 = fileObject.data;
    if (type.startsWith('image/')) {
        return <img className={classes.image} alt={fileObject.file.name} src={base64} />;
    } else if (type === 'application/pdf') {
        return <img className={classes.image} alt={fileObject.file.name} src="path/to/pdf-icon.png" />;
    } else if (type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        return <img className={classes.image} alt={fileObject.file.name} src="path/to/docx-icon.png" />;
    }
    return null;
};

const Dropbox = ({ fileObjects, handleAdd, handleDelete }) => {
    const classes = useStyles();

    return (
        <Box id="dropboxContainer" name="dropboxContainer">
            <DropzoneAreaBase
                id="fileuploaddropzone"
                name="fileUploadDropzone"
                fileObjects={fileObjects}
                onAdd={handleAdd}
                onDelete={handleDelete}
                getPreviewIcon={(fileObject) => handlePreviewIcon(fileObject, classes)}
                acceptedFiles={['image/jpeg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
                dropzoneText="Click here to upload files"
                showPreviewsInDropzone={true}
                showFileNamesInPreview={false}
                showPreviews={false}  // Show previews separately
                filesLimit={1}
                dropzoneClass={classes.dropzone}
            />
            <Typography
                id="fileuploadinstructions"
                name="fileUploadInstructions"
                variant="body2"
                color="textSecondary"
                marginLeft={"16px"}
            >
                Only JPG, PNG, PDF, and DOCX files will be accepted. Only one file can be uploaded at a time.
            </Typography>
        </Box>
    );
};

export default Dropbox;





// import React from 'react';
// import { DropzoneAreaBase } from 'react-mui-dropzone';
// import { makeStyles } from '@mui/styles';
// import { Box, Typography } from '@mui/material';

// const useStyles = makeStyles((theme) => ({
//     dropzone: {
//         backgroundColor: 'lightblue',
//         width: '600px',
//         minHeight: '300px',
//         border: '2px dashed blue',
//         borderRadius: '8px',
//         cursor: 'pointer',
//         marginLeft: '16px',
//     },
//     image: {
//         maxWidth: '80px',
//         maxHeight: '80px',
//     },
// }));

// const handlePreviewIcon = (fileObject, classes) => {
//     const { type } = fileObject.file;
//     const base64 = fileObject.data;
//     if (type.startsWith('image/')) {
//         return <img className={classes.image} alt={fileObject.file.name} src={base64} />;
//     } else if (type === 'application/pdf') {
//         return <img className={classes.image} alt={fileObject.file.name} src="path/to/pdf-icon.png" />;
//     } else if (type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
//         return <img className={classes.image} alt={fileObject.file.name} src="path/to/docx-icon.png" />;
//     }
//     return null;
// };

// const Dropbox = ({ fileObjects, handleAdd, handleDelete }) => {
//     const classes = useStyles();

//     const onAdd = (newFiles) => {
//         // If there's already a file, replace it
//         if (fileObjects.length > 0) {
//             handleDelete(fileObjects[0]);
//         }
//         handleAdd(newFiles);
//     };

//     return (
//         <Box>
//             <DropzoneAreaBase
//                 fileObjects={fileObjects}
//                 onAdd={onAdd}
//                 onDelete={handleDelete}
//                 getPreviewIcon={(fileObject) => handlePreviewIcon(fileObject, classes)}
//                 acceptedFiles={['image/jpeg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
//                 dropzoneText={fileObjects.length === 0 ? "Click here to upload file" : "Replace current file"}
//                 showPreviewsInDropzone={true}
//                 showFileNamesInPreview={true}
//                 showPreviews={false}
//                 filesLimit={1}
//                 maxFileSize={5000000}
//                 dropzoneClass={classes.dropzone}
//             />
//             <Typography variant="body2" color="textSecondary" marginLeft={"16px"}>
//                 Only JPG, PNG, PDF, and DOCX files up to 5MB will be accepted. Only one file can be uploaded at a time.
//             </Typography>
//         </Box>
//     );
// };

// export default Dropbox;
import React from 'react'
import {
    Button,
    TextField,
    Box,
    Snackbar,
    Alert,
    Grid,
    Typography,
    FormControl,
    MenuItem,
    InputLabel,
    FormHelperText,
    Select,
    FormControlLabel,
    Checkbox,
  } from "@mui/material";

  const validationRules = [
    { fieldName: "selectTask", label: "Type of Task", type: "select" },
    { fieldName: "customerNumber", label: "Customer Number", type: "number" },
    { fieldName: "toValue", label: "To", type: "select" },
    { fieldName: "dueDate", label: "Due Date:", type: "date" },
    { fieldName: "priority", label: "Priority", type: "select" },
  ]

  const validationRules1 = [
    { fieldName: "reviewRequired", label: "Review Require", type: "checkbox" },
    { fieldName: "customerName", label: "Customer Nabe", type: "text" },
    { fieldName: "createdBy", label: "Created By", type: "text" },
    { fieldName: "taskTime", label: "Task Time:", type: "time" },
    { fieldName: "status", label: "Status", type: "select" },
  ]


function AddTask() {
    const handleFormSubmit=()=>{

    }
  return (
    <>
   <Box
        sx={{
          alignSelf: "stretch",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "36px",
          maxWidth: "100%",
        }}
      >

        <section
          sx={{
            alignSelf: "stretch",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "center",
            padding: "0px 23px 0px 20px",
            boxSizing: "border-box",
            maxWidth: "100%",
            textAlign: "left",
            fontSize: "14px",
            color: "#242929",
            fontFamily: "Poppins",
          }}
        >
          <Box
            component="form"
            onSubmit={handleFormSubmit}
            sx={{
              width: "853px",
              borderRadius: "4px",
              backgroundColor: "#fff",
              border: "1px solid #e3e3e3",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "flex-start",
              padding: "30px 0px 24px",
              gap: "23px",
              maxWidth: "100%",
              marginLeft: "170px",
            }}
          >
            <Box
              sx={{
                alignSelf: "stretch",
                height: "798px",
                position: "relative",
                borderRadius: "4px",
                backgroundColor: "#fff",
                border: "1px solid #e3e3e3",
                boxSizing: "border-box",
                display: "none",
              }}
            />
            <Typography
              variant="h1" // Adjust the variant as needed, e.g., h1, h2, h3, etc.
              component="div"
              sx={{
                border: "none",
                backgroundColor: "transparent",
                alignSelf: "stretch",
                height: "51px",
                fontFamily: "Poppins",
                fontWeight: "600",
                fontSize: "25px",
                color: "#242929",
                paddingLeft: "50px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Client Information
            </Typography>
            <hr style={{ borderTop: "1px solid #ccc", width: "100%" }} />
            <Box
              sx={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-end",
                padding: "0px 44px 0px 52px",
                boxSizing: "border-box",
                maxWidth: "100%",
              }}
            >
              <Box
                sx={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "16px",
                  maxWidth: "100%",
                }}
              >
                <Box
                  sx={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "4px",
                    minWidth: "240px",
                    maxWidth: "100%",
                  }}
                >
                  <Box
                    sx={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      padding: "0px 0px 8px",
                      gap: "4px",
                    }}
                  >
                    {validationRules.map(
                      ({ fieldName, label, type, option,groupLabel = ''}, index) => (
                        <React.Fragment key={index}>
                           {groupLabel && (
          <Grid item xs={12} style={{ marginBottom: 0 }}>
            <Typography
              variant="subtitle1"
              style={{
                // backgroundColor: "#f0f0f0",
                // padding: "10px 5px",
                // borderRadius: 5,
                // fontWeight: "bold",
                // width:"230px"
                position: "relative",
                lineHeight: "37px",
                fontWeight: "500",
                display: "inline-block",
                minWidth: "83px",
                zIndex: "1",
              }}
            >
              {groupLabel}
            </Typography>
          </Grid>
        )}
                          <Typography
                            key={fieldName} // Assign a unique key to each element
                            variant="body1"
                            sx={{
                              position: "relative",
                              lineHeight: "40px",
                              fontWeight: "500",
                              display: "inline-block",
                              minWidth: "83px",
                              zIndex: "1",
                            }}
                          >
                            {label}
                          </Typography>
                          <TextField
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                              alignSelf: "stretch",
                              height: "52px",
                              fontFamily: "Poppins",
                              fontWeight: "500",
                              fontSize: "14px",
                              // color: '#077BFD',
                              zIndex: "1",
                            }}
                            type={type}
                            variant="outlined"
                            select={type === "select"}
                            InputProps={{
                              style: { height: 40, fontSize: 16 },
                              inputProps: {
                                min: type === "number" ? "0" : "",
                                max: type === "date" ? "2008-04-04" : "",
                              },
                            }}


                            // label={label}

                            sx={{
                              "& fieldset": { borderColor: "#077BFD" },
                              "& .MuiInputBase-root": {
                                height: "52px",
                                backgroundColor: "#fff",
                                fontSize: "14px",
                              },
                              "& .MuiInputBase-input": { color: "#242929" },
                            }}
                          >
                            {/* {type === "select" &&
                              option.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  placeholder={label}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))} */}
                          </TextField>
                        </React.Fragment>
                      )
                    )}
                  </Box>
                </Box>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  lg={6}
                  sx={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "12px",
                    minWidth: "242px",
                    maxWidth: "100%",
                  }}
                >
                  <Box
                    sx={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      padding: "0px 0px 8px",
                      gap: "4px",
                    }}
                  >
                    {validationRules1.map(
                      ({ fieldName, label, type, option }, index) => (
                        <React.Fragment key={index}>
                          <Typography
                            key={fieldName} // Assign a unique key to each element
                            variant="body1"
                            sx={{
                              position: "relative",
                              lineHeight: "40px",
                              fontWeight: "500",
                              display: "inline-block",
                              minWidth: "83px",
                              zIndex: "1",
                            }}
                          >
                            {label}
                          </Typography>
                          {type === 'checkbox' ? (
                    <FormControlLabel
                      control={<Checkbox />}
                      label={label}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: 16,
                          fontWeight: "500",
                        },
                      }}
                    />
                  ):(
                          <TextField
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                              alignSelf: "stretch",
                              height: "52px",
                              fontFamily: "Poppins",
                              fontWeight: "500",
                              fontSize: "14px",
                              color: "#242929",
                              zIndex: "1",
                            }}
                            type={type}
                            variant="outlined"
                            select={type === "select"}
                            InputProps={{
                              style: { height: 40, fontSize: 16 },
                              inputProps: {
                                min: type === "number" ? "0" : "",
                                max: type === "date" ? "2008-04-04" : "",
                                // maxLength: fieldName === 'cellPhone' ? "10" : "",
                              },
                            }}

                            sx={{
                              "& fieldset": { borderColor: "#077BFD" },
                              "& .MuiInputBase-root": {
                                height: "52px",
                                backgroundColor: "#fff",
                                fontSize: "14px",
                              },
                              "& .MuiInputBase-input": { color: "#242929" },
                            }}
                          >
                            {/* {type === "select" &&
                              option.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))} */}
                          </TextField>)}
                        </React.Fragment>
                      )
                    )}
                  </Box>
                </Grid>
              </Box>
            </Box>



          </Box>
        </section>
      </Box>
    </>
  )
}

export default AddTask

import React from 'react';
import { Box, Typography, Grid, Button, IconButton, MenuItem, Select, FormControl, InputLabel, Paper } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';

const systemNotesData = [
  {
    "date": "Friday February 16, 2024 10:32:54 PM",
    "policy": "None",
    "by": "Task Administrator",
    "noteType": "Customer View",
    "note": "Customer View By Task Administrator on 05/31/24 06:51:29"
  },
  {
    "date": "Friday February 16, 2024 10:32:54 PM",
    "policy": "None",
    "by": "Task Administrator",
    "noteType": "Customer View",
    "note": "Customer View By Task Administrator on 05/30/24 23:04:47"
  }
  // Add more notes as needed
];

const ViewSystemNotes = () => {
  return (
    <Box p={2} id="viewsystemnotescontainer">
      <Grid container justifyContent="flex-end" mb={2}>
        <FormControl variant="outlined" size="small" id="filterformcontrol">
          <InputLabel id="filterlabel">Filter</InputLabel>
          <Select defaultValue="All" label="Filter" id="filterselect">
            <MenuItem value="All" id="filterall">All</MenuItem>
            {/* Add more filter options as needed */}
          </Select>
        </FormControl>
      </Grid>
      {systemNotesData.map((note, index) => (
        <Paper key={index} variant="outlined" sx={{ p: 2, mb: 2 }} id={`notepaper${index}`}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary" id={`noteinfo${index}`}>
                Date: {note.date} | Policy: {note.policy} | By: {note.by}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary" id={`notetype${index}`}>
                Note Type: {note.noteType}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" id={`notecontent${index}`}>
                {note.note}
              </Typography>
            </Grid>
            <Grid item container xs={12} justifyContent="space-between">
              <Button variant="outlined" id={`appendbutton${index}`}>
                Append
              </Button>
              <IconButton id={`printbutton${index}`}>
                <PrintIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </Box>
  );
};

export default ViewSystemNotes;

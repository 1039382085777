import apiService from "./apiServices";

export const tokenPost = async(formData,token) => {
    try {

        // console.log(formData, 'formData, for task');
        const response = await apiService('/token/extract', 'POST', formData, null, token);
        console.log("resoponse",response)
        return response;
        } catch (error) {
          throw (error?.message || 'Posting token is failed. Please try again.');
        }
  }

  // export const tokenEmailPost = async(formData,token) => {
  //   try {

  //       // console.log(formData, 'formData, for task');
  //       const response = await apiService('/userprofile/verify-email', 'POST', formData, null, token);
  //       console.log("resoponse",response)
  //       return response;
  //       } catch (error) {
  //         throw (error?.message || 'Posting email token is failed. Please try again.');
  //       }
  // }


  export const tokenEmailPost = async (formData) => {
    try {
      const response = await apiService('/userprofile/verify-email', 'POST', formData, null, null);
      console.log("response", response);
      return response;
    } catch (error) {
      // Check if the error has a response status and handle the 400 Bad Request error
      if (error.response?.data) {
        throw error.response.data;  // This contains the error structure we want
      } else {
        throw new Error(error?.message || 'Posting email token failed. Please try again.');
      }
    }
  };


import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, StepConnector, StepLabel, Button, Snackbar, Alert } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LeadSection from '../../../../components/SidebarLayout/CreateLead/LeadSection';
import ContactInformation from '../../../../components/SidebarLayout/CreateLead/ContactInformation';
import AdditionalInformation from '../../../../components/SidebarLayout/CreateLead/AdditionalInformation';
import Check from '@mui/icons-material/Check';
import { useForm, FormProvider } from 'react-hook-form';

import {
  createLead as createLeadAction,
  updateLead as updateLeadAction,
  updateLeadAddress as updateLeadAddressAction,
  getMyLeads,
  getMyLeads as getMyLeadsAction,
} from '../../../../redux/actions/newleadActions';

const steps = ['General', 'Contact Information', 'Additional Info'];

const components = {
  0: LeadSection,
  1: ContactInformation,
  2: AdditionalInformation,
};

const CreateLead = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completedSteps, setCompletedSteps] = React.useState(new Set());
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state
  const methods = useForm({ mode: 'onTouched' });
  const { reset } = methods;
  
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const userData = user.user;
  const userId = (userData)?userData.id:'';
  const firstName = (userData)?userData.firstName:"";
  const lastName = (userData)?userData.lastName:"";
  const loginUserName = firstName+' '+lastName;
  // Storing data in SessionStorage
   sessionStorage.setItem('userId',userId);
   sessionStorage.setItem('loginUserName',loginUserName);

   const leads = useSelector((state) => state?.newleads?.allLeads);
   const leadId = (leads)?leads.id:"";
  
   const handleNext = (data) => {
    const addressData = {address:data.address,zip:data.zip,city:data.city,state:data.state};
    //  console.log(data,addressData,data.sameAddress,data.sameAddress2,'qqq');
   // console.log(leadId,'lid');
    if(leadId){
      data.id=leadId;
      console.log(data,'leads data');
      dispatch(updateLeadAction(data));
      //const addressData = {address:data.address,zip:data.zip,city:data.city,state:data.state};
     // console.log(addressData,'qqq');
    //  dispatch(updateLeadAddressAction(data));

    }else{
     dispatch(createLeadAction(data));
    }

    if (activeStep < steps.length - 1) {
      setCompletedSteps((prev) => new Set(prev).add(activeStep));
      setActiveStep((prev) => prev + 1);      
    } else {
      setCompletedSteps((prev) => new Set(prev).add(activeStep));
      setSnackbarOpen(true); // Show Snackbar on save
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleSave = () => {
    const newCompletedSteps = new Set(completedSteps);
    newCompletedSteps.add(activeStep);
    setCompletedSteps(newCompletedSteps);
    setSnackbarOpen(true); // Show Snackbar on save
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompletedSteps(new Set());
    reset(); // Reset the form values to their default state
  };

  const handleSaveAndNew = () => {
    handleSave();
    handleReset();
  };

  const renderStepContent = (step) => {
    const Component = components[step];
    return Component ? <Component loginUserName={loginUserName} /> : null;
  };

  const primaryButton = {
    background: '#0173BC',
    color: '#FFFFFF',
    minWidth: '84px',
    '&:hover': {
      background: '#0173BC',
      color: '#FFFFFF',
    },
  };

  const CustomStepIcon = (props) => {
    const { active, completed, icon } = props;

    return (
      <div
        style={{
          backgroundColor: completed ? '#4caf50' : active ? '#0173BC' : '#eaeaf0',
          zIndex: 1,
          color: '#fff',
          width: 24,
          height: 24,
          display: 'flex',
          borderRadius: '50%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {completed ? <Check /> : icon}
      </div>
    );
  };

  const CustomStepLabel = (props) => {
    const { children, icon, active, completed } = props;

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <CustomStepIcon active={active} completed={completed} icon={icon} />
        <Box sx={{ ml: 2, fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
          {children}
          {icon - 1 < steps.length - 1 && (
            <ArrowForwardIosIcon style={{ fontSize: '16px', marginLeft: '5px', color: '#242929' }} />
          )}
        </Box>
      </Box>
    );
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <FormProvider {...methods}>
      <Box sx={{ margin: '80px 0 0 0', padding: '0 2rem 0 5rem' }}>
        <Stepper activeStep={activeStep} connector={<CustomStepConnector />}>
          {steps.map((label, index) => (
            <Step key={label} completed={completedSteps.has(index)}>
              <StepLabel StepIconComponent={() => null}>
                <CustomStepLabel icon={index + 1} active={activeStep === index} completed={completedSteps.has(index)}>
                  {label}
                </CustomStepLabel>
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box>
          <Box sx={{ margin: '40px 0 0 0' }}>
            {renderStepContent(activeStep)}
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'end' }}>
            {activeStep > 0 && (
              <Button
                color="inherit"
                id="backbutton"
                onClick={handleBack}
                sx={{ mr: 1, color: '#242929', minWidth: '84px', border: '#242929 solid 1px' }}
              >
                Back
              </Button>
            )}
            {activeStep >= steps.length - 1 ? (
              <>
                <Button type='button' onClick={methods.handleSubmit(handleNext)} id="savebutton" sx={primaryButton}>
                  Save
                </Button>
                <Button type='button' onClick={handleSaveAndNew} id="saveandnewbutton" sx={{ ...primaryButton, ml: 1 }}>
                  Save & New
                </Button>
              </>
            ) : (
              <Button
                type="button"
                id="nextbutton"
                onClick={methods.handleSubmit(handleNext)}
                sx={primaryButton}
              >
                Next
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <Snackbar 
        open={snackbarOpen} 
        autoHideDuration={10000} 
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Positioning Snackbar
      >
        <Alert onClose={handleSnackbarClose} id="savedalert" severity="success" sx={{ width: '100%' }}>
          Saved successfully!
        </Alert>
      </Snackbar>
    </FormProvider>
  );
};

const CustomStepConnector = () => {
  return (
    <StepConnector
      style={{
        marginLeft: -4,
        marginRight: -4,
        position: 'relative',
        borderWidth: '5px', // Adjust width as needed
        borderColor: 'green', // Adjust color as needed
        display: 'none'
      }}
    />
  );
};

export default CreateLead;

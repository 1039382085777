import React,{useState,useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DropDownMenuItem from '../../../common/DropDownMenuItem';
import Status from '../../../common/Status';
import DeleteModal from './DeleteModal'; 
import ChangeOwnerModal from './ChangeOwnerModal';
import FilterListIcon from '@mui/icons-material/FilterList';
import { getAllLeads as getAllLeadsAction} from '../../../redux/actions/newleadActions'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, phone, mobile, email, status, owner) {
  return { name, phone, mobile, email, status, owner };
}

// const rows = [
//   createData('Bertha Boxer',  '(850) 123 1234', '(850) 123 1234', 'abac@gmail.com', 'In progress', 'John'),
//   createData('John', '(850) 123 1234', '(850) 123 1234', 'johna@yopmail.com', 'completed', 'Bertha Boxer'),  
//   createData('Bertha Boxer', '(850) 123 1234', '(850) 123 1234', 'abac@gmail.com', 'rejected', 'John'),
// ];

// Define table headers dynamically
const tableHeaders = [
  { id: 'name', label: 'Name1' },
  { id: 'phone', label: 'Phone' },
  { id: 'mobile', label: 'Mobile' },
  { id: 'email', label: 'Email' },
  { id: 'status', label: 'Lead Status' },
  { id: 'owner', label: 'Owner', align: 'right' },
];

const AllLeadListing = () => {
  const leads = useSelector((state) => state?.newleads?.allLeads);
  const loading = useSelector((state) => state.loading.ALL_LEADS);
  const error = useSelector((state) => state?.newleads?.error);
  const dispatch = useDispatch();

  const [leadsData, setLeadsData] = useState(getLeadsData(leads) || []);
  const [selectedLeadData, setSelectedLeadData] = React.useState(null);

  useEffect(() => {
      dispatch(getAllLeadsAction());;
  }, []);
  useEffect(() => {
    setLeadsData(getLeadsData(leads) || []);
  }, [leads]);
 // console.log(leadsData);
  const [deleteLead, setDeleteLead] = React.useState(false);
  const [changeOwner, setChangeOwner] = React.useState(false);

  const handleChangeOwnerOpen = () => setChangeOwner(true);
  const handleChangeOwnerClose = () => setChangeOwner(false);

  const handleDeleteOpen = () => setDeleteLead(true);
  const handleDeleteClose = () => setDeleteLead(false);

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <StyledTableCell key={header.id} align={header.align || 'left'} sx={{fontWeight: 'bold'}}>
                  {header.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {leadsData.map((row) => (
              <StyledTableRow key={row.firstName}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                {/* <StyledTableCell>{row.title}</StyledTableCell>
                <StyledTableCell>{row.company}</StyledTableCell> */}
                <StyledTableCell>{row.cell}</StyledTableCell>
                 <StyledTableCell>{row.phoneNumber}</StyledTableCell>
                <StyledTableCell>{row.email}</StyledTableCell>
                <StyledTableCell><Status leadStatus={row.status} /></StyledTableCell>
                <StyledTableCell align='right'>
                  <DropDownMenuItem handleChangeOwnerOpen={handleChangeOwnerOpen} handleDeleteOpen={handleDeleteOpen} username={row.ownerName} leadId={row.id} />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <DeleteModal openDeleteModal={deleteLead} handleDeleteClose={handleDeleteClose} />
      <ChangeOwnerModal openChangeOwner={changeOwner}  handleOwnerClose={handleChangeOwnerClose} />
    </>
  );
}

export default AllLeadListing;


const getLeadsData = (leads) => {
  if (!Array.isArray(leads)) {
    return [];
  }

  const myLeads = leads.map(lead => ({
    ...lead,
    name: `${lead.firstName} ${lead.lastName}`,
  }));

  const filteredMyLeads = myLeads.filter(item => item.isAccountCreated !== true);

  const sortedLeads = filteredMyLeads.sort((a, b) => {
    const dateA = new Date(a.lastActivity);
    const dateB = new Date(b.lastActivity);
    return dateB - dateA;
  });

  return sortedLeads;
};

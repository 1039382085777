import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Grid, FormControl, Typography, Tooltip, MenuItem, TextField, Select, FormHelperText, Checkbox, FormControlLabel } from '@mui/material';
import FormLabelText from '../../../common/FormLabelText';

const addressFormFiledData = [
    {
        fieldName: 'address',
        label: 'Address *',
        type: 'text',
        rules: { required: 'Address field is required' },
        id: 'address'
    },
    {
        fieldName: 'zip',
        label: 'Zip',
        type: 'text',
        id: 'zip'
    },
    {
        fieldName: 'city',
        label: 'City',
        type: 'text',
        id: 'city'
    },
    {
        fieldName: 'state',
        label: 'State',
        type: 'dropdown',
        options: ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM'],
        id: 'state'
    },
    {
        fieldName: 'country',
        label: 'Country',
        type: 'dropdown',
        options: ['United States', 'United Kingdom', 'Canada', 'Mexico', 'Germany', 'China', 'Singapore', 'Hong Kong', 'Brazil'],
        id: 'country'
    },
    {
        fieldName: 'mailingAddress',
        label: 'Mailing Address',
        type: 'text',
        id: 'mailingaddress'
    },
    {
        fieldName: 'mailingZip',
        label: 'Mailing Zip',
        type: 'text',
        id: 'mailingzip'
    },
    {
        fieldName: 'mailingCity',
        label: 'Mailing City',
        type: 'text',
        id: 'mailingcity'
    },
    {
        fieldName: 'mailingState',
        label: 'Mailing State',
        type: 'dropdown',
        options: ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM'],
        id: 'mailingstate'
    },
    {
        fieldName: 'mailingCountry',
        label: 'Mailing Country',
        type: 'dropdown',
        options: ['United States', 'United Kingdom', 'Canada', 'Mexico', 'Germany', 'China', 'Singapore', 'Hong Kong', 'Brazil'],
        id: 'mailingcountry'
    },
   // {
    //     fieldName: 'hdnCity',
    //     label: 'hdn City',
    //     type: 'hidden',
    //     defaultValue:'Hello'
    // } 
];

const AddressSection = () => {
    const { control, formState: { errors } } = useFormContext();
    const [sameAddress, setSameAddress] = useState(false);

    const handleSameAddressChange = (event) => {
        setSameAddress(event.target.checked);
    };

    return (
        <>
           <Box sx={{ border: '#ccc solid 1px', borderRadius: '4px', padding: '20px', margin: '40px 0 0 0', position: 'relative' }}>
                <FormLabelText labelText="Address Section" />
                <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ margin: '0' }}>
                    {addressFormFiledData.slice(0, 5).map(({ fieldName, label, type, options, rules, id }) => (
                        <Grid item xs={12} sm={6} key={fieldName}>
                            <Controller
                                name={fieldName}
                                control={control}
                                rules={rules}
                                render={({ field }) => {
                                    if (type === 'text') {
                                        return (
                                            <TextField
                                                {...field}
                                                id={id}  // Using the id here
                                                label={label}
                                                error={!!errors[fieldName]}
                                                helperText={errors[fieldName] ? errors[fieldName].message : ''}
                                                fullWidth
                                            />
                                        );
                                    } else if (type === 'dropdown') {
                                        return (
                                            <FormControl fullWidth error={!!errors[fieldName]}>
                                                <Select
                                                    {...field}
                                                    id={id}  // Using the id here
                                                    displayEmpty
                                                >
                                                    {options.map((option, index) => (
                                                        <MenuItem key={index} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {errors[fieldName] && (
                                                    <FormHelperText>{errors[fieldName].message}</FormHelperText>
                                                )}
                                            </FormControl>
                                        );
                                    }
                                    return null;
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
                <FormControlLabel
                    control={<Checkbox checked={sameAddress} onChange={handleSameAddressChange} />}
                    label="Mailing address is the same as above"
                />
            </Box>
        </>
    );
};

export default AddressSection;

import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';
import AttachmentIcon from '@mui/icons-material/Attachment';

const paymentData = {
  headers: [
    "Receipt/Details",
    "Office",
    "Company",
    "Policy",
    "Status",
    "Amount Billed",
    "Amount Tendered",
    "Date",
    "E",
    "V",
    "P",
    "AT",
    "VA"
  ],
  data: [
    {
      "receipt": "3020244",
      "office": "Headquarters",
      "company": "PROGRESSIVE FL",
      "policy": "932854084",
      "status": "Active",
      "amountBilled": 198.33,
      "amountTendered": 198.33,
      "date": "12/30/2023 02:32:20 PM"
    },
    // Add more rows as needed
  ]
};

const PayHistory = () => (
  <TableContainer component={Paper} id="payhistorytablecontainer">
    <Table id="payhistorytable">
      <TableHead>
        <TableRow>
          {paymentData.headers.map((header, index) => (
            <TableCell key={index} id={`header-${index}`}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {paymentData.data.map((row, index) => (
          <TableRow key={index} id={`paymentrow${index}`}>
            <TableCell>{row.receipt}</TableCell>
            <TableCell>{row.office}</TableCell>
            <TableCell>{row.company}</TableCell>
            <TableCell>{row.policy}</TableCell>
            <TableCell>{row.status}</TableCell>
            <TableCell>{row.amountBilled.toFixed(2)}</TableCell>
            <TableCell>{row.amountTendered.toFixed(2)}</TableCell>
            <TableCell>{row.date}</TableCell>
            <TableCell>
              <IconButton size="small" id={`viewbutton${index}`}>
                <VisibilityIcon />
              </IconButton>
            </TableCell>
            <TableCell>
              <IconButton size="small" id={`editbutton${index}`}>
                <VisibilityIcon />
              </IconButton>
            </TableCell>
            <TableCell>
              <IconButton size="small" id={`printbutton${index}`}>
                <PrintIcon />
              </IconButton>
            </TableCell>
            <TableCell>
              <IconButton size="small" id={`attachmentbutton${index}`}>
                <AttachmentIcon />
              </IconButton>
            </TableCell>
            <TableCell>
              <IconButton size="small" id={`attachmentbutton2${index}`}>
                <AttachmentIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const ManagePolicy = () => {
  return (
    <Box sx={{ width: '100%' }} id="managepolicycontainer">
      <Accordion id="paymentsaccordion">
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1aheader">
          <Typography id="paymentssummary">Payments</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PayHistory />
        </AccordionDetails>
      </Accordion>
      <Accordion id="creditcardtransactionsaccordion">
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2aheader">
          <Typography id="creditcardtransactionssummary">Credit Card Transactions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {/* Content for Credit Card Transactions */}
            Credit Card Transactions content goes here.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion id="invoiceaccordion">
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3aheader">
          <Typography id="invoicesummary">Invoice</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {/* Content for Invoice */}
            Invoice content goes here.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ManagePolicy;

import React, { useState } from 'react';
import { Tab, Tabs, Paper, Container, Typography, Grid,Button } from '@mui/material';
import MainDropdownForm from './MainDropdownForm';
import DependentDropdownForm from './DependentDropdownForm';
import DependencyDropdownForm from './DependencyDropdownForm';

import { Box } from '@mui/system';

const MenuDropDown = () => {
  const [tabValue, setTabValue] = useState(0);
  console.log('hello');
  const handleTabChange = (event, newValue) => {
    console.log(newValue,'asid');
    setTabValue(newValue);
  };

  // const handleTabSubmit = (newValue) => {
  //   setTabValue(newValue);
  // };

  return (
    <Box sx={{marginLeft:'4rem',marginTop:'4rem'}}>
      <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          sx={{mb:'1rem'}}
        >
          <Tab label="Main Dropdown" />
          <Tab label="Dependent Dropdown" />
          <Tab label="Dependency Fields" />
        </Tabs>
          {/* <Button variant="contained" value={tabValue} onChange={handleTabChange} onClick={() => handleTabSubmit(0)} sx={{mb:'1rem'}}>Main Dropdown</Button>
          <Button variant="outlined" value={tabValue} onChange={handleTabChange} onClick={() => handleTabSubmit(1)} sx={{mb:'1rem'}}>Dependent Dropdown</Button>
          <Button variant="outlined" value={tabValue} onChange={handleTabChange} onClick={() => handleTabSubmit(2)} sx={{mb:'1rem'}}>Dependency Fields</Button> */}
      <Grid container fluid justifyContent="center">
        <Grid item xs={12}>
          {tabValue === 0 && (
            <div>
              <Typography id="managemaindropdownvalues"  variant="h5" gutterBottom>Manage Main Dropdown Values</Typography>
              <MainDropdownForm />
            </div>
          )}
          {tabValue === 1 && (
            <div>
              <Typography id="managedependentdropdownvalues" variant="h5" gutterBottom>Manage Dependent Dropdown Values</Typography>
              <DependentDropdownForm />
            </div>
          )}
          {tabValue === 2 && (
            <div>
              <Typography id="managedependencyfields" variant="h5" gutterBottom>Manage Dependency Fields</Typography>
              <DependencyDropdownForm />
            </div>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MenuDropDown;

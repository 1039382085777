import apiService from "./apiServices";

const generateRandomString = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }
  return result;
};

// export const getPostGeneralPartyInfoById = async (quoteId, token) => {
//   try {
//     const randomString = generateRandomString(20); // Generate a random 20-character string
//     const url = `/generalPartyInfo/${quoteId}?random=${randomString}`; // Append random string to URL

//     const headers = {
//       'Access-Control-Request-Headers': '*'
//     };

//     console.log("token", token);
//     console.log("url", url); // Log URL to verify

//     const response = await apiService(url, 'POST', null, headers, token);
//     return response;
//   } catch (error) {
//     throw new Error(error.message || 'Service failed. Please try again.');
//   }
// };

export const createGeneralPartyInfo = async (formData, token) => {
  try {
    const headers = {
      'Access-Control-Request-Headers': '*'
    };
    const response = await apiService('/generalPartyInfo/', 'POST', formData, headers, token);
    // console.log(response, 'response from service');
    return response;
  } catch (error) {
    throw new Error(error.message || 'Creating organization is failed. Please try again.');
  }
}

export const updateGeneralPartyInfo = async (formData, token) => {
  // console.log("token in update general",token);
  try {
    const headers = {
      'Access-Control-Request-Headers': '*'
    };
    const response = await apiService('/generalPartyInfo/', 'PUT', formData, headers, token);
    // console.log(response, 'response from update service');
    return response;
  } catch (error) {
    throw new Error(error.message || 'Updating organization is failed. Please try again.');
  }
}

export const getGeneralPartyInfoById = async (quoteId, token) => {
  try {
    const headers = {
      'Access-Control-Request-Headers': '*'
    };
    // console.log("token",token);
    const response = await apiService(`/generalPartyInfo/${quoteId}`, 'GET', null, headers, token);
    return response;
  } catch (error) {
    throw new Error(error.message || 'service is failed. Please try again.');
  }
}

export const getComparisionById = async (gpId, token) => {
  try {
    const headers = {
      'Access-Control-Request-Headers': '*'
    };
    // console.log("token",token);
    const response = await apiService(`/comparison/json/${gpId}`, 'GET', null, headers, token);
    return response;
  } catch (error) {
    throw new Error(error.message || 'service is failed. Please try again.');
  }
}

export const getPostGeneralPartyInfoById = async (id, token) => {
  try {
    const headers = {
      'Access-Control-Request-Headers': '*'
    };
    // console.log("token",token);
    const response = await apiService('/generalPartyInfo/fetch', 'POST', id, headers, token);
    return response;
  } catch (error) {
    throw new Error(error.message || 'service is failed. Please try again.');
  }
}

export const getAllGeneralPartyInfo = async (token) => {
  try {
    const headers = {
      'Access-Control-Request-Headers': '*'
    };
    const response = await apiService('/generalPartyInfo/', 'GET', null, headers, token);
    return response;
  } catch (error) {
    throw new Error(error.message || 'service is failed. Please try again.');
  }
}

export const getPriorInsuranceCarriers = async (token) => {
  try {
    // const headers = {
    //   'Access-Control-Request-Headers': '*',
    //   // 'Authorization': `Bearer ${token}` // Assuming the token is required in the headers
    // };
    const response = await apiService('/prior-carrier/', 'GET', null, null, token);
    // console.log("response",response)
    return response;
    // return response.data.map(carrier => ({
    //   value: carrier.carrierCode,
    //   label: carrier.carrierName,
    // }));
  } catch (error) {
    throw new Error(error.message || 'Failed to fetch prior insurance carriers. Please try again.');
  }
};




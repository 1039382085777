import React, { useState, useEffect } from "react";
import EditIcon from '@mui/icons-material/Edit';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Select,
  MenuItem,
  TablePagination,
  TextField,
  FormControl,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  fetchTaskDataAction,
  fetchTaskFilterDataAction,
} from "../../../redux/actions/dashboardAction";
import { useDispatch, useSelector } from "react-redux";
import DashboardTaskEditPopup from './DashboardTaskEditPopup';
import dayjs from "dayjs";
import Loader from "../../loader/loader";

const DashboardTaskList = () => {
  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [fromDate, setFromDate] = useState(dayjs().startOf('month').format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [filteredData, setFilteredData] = useState([]); 
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false); 
  const [detailTask, setDetailTask] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);

  useEffect(() => {
    if(!isFiltered){
      dispatch(fetchTaskDataAction(page, rowsPerPage));
    }    
  }, [dispatch,page, rowsPerPage]);

  const taskList = useSelector((state) => state.dashboardData.all_task?.addTaskList?.content) || [];
  const paginatoin = useSelector((state) => state.dashboardData.all_task?.addTaskList) || [];
  
  const loading = useSelector((state) => state.dashboardData.loading);    

  useEffect(() => {
    if (selectedFilter === "All" && !fromDate && !toDate) {
      setFilteredData(taskList); // Only set initial data load without overwriting
    }
    setFilteredData(taskList); 
  }, [taskList]);

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const fetchUpdatedData = () => {
    dispatch(fetchTaskDataAction(page, rowsPerPage));
  };

  const handleClose = () => {
    setOpen(false); // Close the dialog
  };

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    if (name === "fromDate") setFromDate(value);
    if (name === "toDate") setToDate(value);
  };

  const handleApply = () => {
    const formData = {
      fromDate: fromDate || "",
      toDate: toDate || "",
      status: selectedFilter,
    };

    if ((fromDate && toDate) || selectedFilter !== "All") {
      dispatch(fetchTaskFilterDataAction(formData, page, rowsPerPage));
      setIsFiltered(true);
    } else {
      setFilteredData(taskList, page, rowsPerPage);
      setIsFiltered(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (isFiltered) {
      const formData = {
        fromDate: fromDate || "",
        toDate: toDate || "",
        status: selectedFilter,
      };
      dispatch(fetchTaskFilterDataAction(formData, newPage, rowsPerPage));
    } else {
      dispatch(fetchTaskDataAction(newPage, rowsPerPage));
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    if (isFiltered) {
      const formData = {
        fromDate: fromDate || "",
        toDate: toDate || "",
        status: selectedFilter,
      };
      dispatch(fetchTaskFilterDataAction(formData, 0, newRowsPerPage));
    } else {
      dispatch(fetchTaskDataAction(0, newRowsPerPage));
    }
  };

  const handleEditTask = (id) => {
    const selectedRow = taskList.find((task) => task.id === id);
    setDetailTask(selectedRow);
    setOpen(true);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Box sx={{ display: "flex", width: "100%", marginBottom: "15px" }}>
        <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
          <Box sx={{ display: "flex", flexFlow: "column" }}>
            <label>From</label>
            <TextField
              type="date"
              name="fromDate"
              id="fromdate"
              value={fromDate}
              onChange={handleDateChange}
              InputLabelProps={{ shrink: true }}
              sx={{ mr: 2 }}
            />
          </Box>
          <Box sx={{ display: "flex", flexFlow: "column" }}>
            <label>To</label>
            <TextField
              type="date"
              name="toDate"
              id="todate"
              value={toDate}
              onChange={handleDateChange}
              InputLabelProps={{ shrink: true }}
              sx={{ mr: 2 }}
            />
          </Box>
          <FormControl sx={{ display: "flex", flexFlow: "column", minWidth: "200px" }}>
            <label>Status</label>
            <Select
              labelId="filter-select-label"
              value={selectedFilter}
              id="statusfilter"
              onChange={handleFilterChange}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Open">Open</MenuItem>
              <MenuItem value="Closed">Closed</MenuItem>
              <MenuItem value="DueTaskDate">Due Today</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            onClick={handleApply}
            id="applybutton"
            sx={{ ml: 2 }}
            disabled={!fromDate && selectedFilter === "All"}
          >
            Apply
          </Button>
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="task list table">
          <TableHead>
            <TableRow>
              <TableCell sx={{fontWeight: 'bold'}}>Policy Number</TableCell>
              <TableCell sx={{fontWeight: 'bold'}} align="left">Assigned To</TableCell>
              <TableCell sx={{fontWeight: 'bold'}} align="left">Due Date</TableCell>
              <TableCell sx={{fontWeight: 'bold'}} align="left">Status</TableCell>
              <TableCell sx={{fontWeight: 'bold'}} align="left">Closed Date</TableCell>
              <TableCell sx={{fontWeight: 'bold'}} align="left">Closed By</TableCell>
              <TableCell sx={{fontWeight: 'bold'}} align="left">Subject</TableCell>
              <TableCell  sx={{fontWeight: 'bold'}} align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={8}>
                  <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
                    <Typography variant="subtitle1" color="textSecondary">
                      <Loader />
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ) : filteredData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8}>
                  <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
                    <Typography variant="subtitle1" color="textSecondary">
                      No Data found.
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              filteredData.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">{row.policyNumber}</TableCell>
                  <TableCell align="left">{row.assignedTo}</TableCell>
                  <TableCell align="left">{row.dueDate}</TableCell>
                  <TableCell align="left">{row.status}</TableCell>
                  <TableCell align="left">{row.closedDate}</TableCell>
                  <TableCell align="left">{row.closedBy}</TableCell>
                  <TableCell align="left">{row.subject}</TableCell>
                  <TableCell align="left">
                    <EditIcon onClick={() => handleEditTask(row.id)} />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={paginatoin.totalPages === 0 ? [] : [5, 10, 15, 25, 50, 100]}
        component="div"
        count={paginatoin.totalElements}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" id="edit-task-dialog">
        <DialogContent>
          <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', padding: '0' }} id="dialogactions">
            <h3 id="edittasktitle">Edit Task</h3>
            <Button onClick={handleClose} id="closebutton">Close</Button>
          </DialogActions>
          <DashboardTaskEditPopup closePopup={handleClose} savePopup={fetchUpdatedData} taskFilterData={detailTask} id="dashboard-task-edit-popup" />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default DashboardTaskList;

import * as dropdownServices from '../../services/dropdownServices';
import {
  CLOSE_CREATE_ORGANIZATION_MODAL,
  OPEN_CREATE_ORGANIZATION_MODAL,
    OPEN_UPDATE_ORGANIZATION_MODAL,
    START_LOADING,
    STOP_LOADING
} from "./actionTypes"
import { openSessionTimeoutModal } from './sessionTimeOutActions';

export const createMaindropdown = (formData, callbackfn) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'CREATE_MAINDROPDOWN' });
    const token = getState().auth.token || sessionStorage.getItem("token");;
    const response = await dropdownServices.createMaindropdown(formData, token);
    dispatch({ type: STOP_LOADING, service: 'CREATE_MAINDROPDOWN' });
    if(response) {
      callbackfn();
    }
    dispatch({ type: 'CREATE_MAINDROPDOWN_SUCCESS', payload: response });
  } catch (error) {
    if(error?.includes("403")) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'CREATE_MAINDROPDOWN' });
      dispatch({ type: 'CREATE_MAINDROPDOWN_FAILURE', payload: error || 'Create Main dropdown service failed. Please try again.' });
    }
  }
};

export const getMaindropdownBylabel = (formLabel) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'FORM_LABEL' });
    const token = getState().auth.token || sessionStorage.getItem("token");;
    const customerId = getState().user.user.customer.id;
    const response = await dropdownServices.getMaindropdownBylabel(formLabel, token);
    dispatch({ type: STOP_LOADING, service: 'FORM_LABEL' });
    dispatch({ type: 'ALL_USERS_SUCCESS', payload: response });
  } catch (error) {
    if((typeof(error) !== "object" && error?.includes("403")) || (error && error.message?.includes("403"))) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'ALL_USERS' });
      dispatch({ type: 'ALL_USERS_FAILURE', payload: typeof(error)==="object" ? error.message : error || 'All Users service failed. Please try again.' });
    }
  }
};

export const getDependentdropdownBylabel = (formLabel) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'FORM_LABEL' });
    const token = getState().auth.token || sessionStorage.getItem("token");;
    const customerId = getState().user.user.customer.id;
    const response = await dropdownServices.getDependentdropdownBylabel(formLabel, token);
    dispatch({ type: STOP_LOADING, service: 'FORM_LABEL' });
    dispatch({ type: 'ALL_USERS_SUCCESS', payload: response });
  } catch (error) {
    if((typeof(error) !== "object" && error?.includes("403")) || (error && error.message?.includes("403"))) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'ALL_USERS' });
      dispatch({ type: 'ALL_USERS_FAILURE', payload: typeof(error)==="object" ? error.message : error || 'All Users service failed. Please try again.' });
    }
  }
};

export const dependencyMappingDropdown = (formData, callbackfn) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'CREATE_MAPPING' });
    const token = getState().auth.token || sessionStorage.getItem("token");;
    const response = await dropdownServices.createDependencyMappingDropdown(formData, token);
    dispatch({ type: STOP_LOADING, service: 'CREATE_MAPPING' });
    if(response) {
      callbackfn();
    }
    dispatch({ type: 'CREATE_MAPPING_DROPDOWN_SUCCESS', payload: response });
  } catch (error) {
    if((typeof(error) !== "object" && error?.includes("403")) || (error && error.message?.includes("403"))) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'CREATE_MAPPING' });
      dispatch({ type: 'CREATE_MAPPING_DROPDOWN_FAILURE', payload: typeof(error)==="object" ? error.message : error || 'Create mapping dropdown service failed. Please try again.' });
    }
  }
};

export const getDependentDropdownByLabel = (dependentByLabel) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'ALL_USERS' });
    const token = getState().auth.token || sessionStorage.getItem("token");;
   // const customerId = getState().user.user.customer.id;
    const response = await dropdownServices.getDependentDropdownByLabel(dependentByLabel, token);
    dispatch({ type: STOP_LOADING, service: 'ALL_USERS' });
    dispatch({ type: 'ALL_USERS_SUCCESS', payload: response });
  } catch (error) {
    if((typeof(error) !== "object" && error?.includes("403")) || (error && error.message?.includes("403"))) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'ALL_USERS' });
      dispatch({ type: 'ALL_USERS_FAILURE', payload: typeof(error)==="object" ? error.message : error || 'All Users service failed. Please try again.' });
    }
  }
};
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
// import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Typography, Box, IconButton } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { makeStyles } from "@mui/styles";
import { css } from '@emotion/react';
import {
  GridRowModes,
  DataGrid,
  DataGridCell,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { Container } from "@mui/material";
import DriverSave from "./driverSave1";
import { getAllDriverInfo } from "../../redux/actions/driverInfoActions";

const roles = ["Market", "Finance", "Development"];

const initialRows = [
  {
    id: 1,
    name: "Chetan Wagh",
    age: 33,
    driver_type: "Rated",
    gender: "Male",
    marital: "Married",
    violations: 0,
    priorInsurance: "Yes",
  },
  {
    id: 2,
    name: "Rajiv",
    age: 34,
    gender: "Male",
    marital: "Married",
    violations: 1,
    priorInsurance: "No",
  },
];
const useStyles = makeStyles({
  root: {
    '& .MuiDataGrid-columnHeaders': {
      borderBottom: 'none',
    },
    '& .MuiDataGrid-window': {
      borderTop: 'none',
    },
  },
});


const DrvierTab = ({handleNext,handleBack}) => {
  const dispatch = useDispatch();
  let { quoteId } = useParams();
  const classes = useStyles();
  const [showButton,setShowButton]=useState(false);
  const [showHideDriverForm, setShowHideDriverForm] = useState(false);

  const [rowModesModel, setRowModesModel] = useState({});

  const allDriverInfo = useSelector(
    (state) => state.driverInfo.allDriverInfo
  );


  // const [rows, setRows] = React.useState(allDriverInfo);
  const [rows, setRows] = useState(initialRows);
  const [driverData, setDriverData] = useState({})

  useEffect(() => {
    // if (!Object.keys(user).length) {
      // dispatch(getUserAction());
      if(quoteId){

        dispatch(getAllDriverInfo(quoteId));
      }
    // }
  }, []);

  useEffect(() => {
    allDriverInfo.filter((row)=> row.id = row.did)
    setRows(allDriverInfo);
  }, [allDriverInfo]);



  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setShowHideDriverForm(true)
    setShowButton(true)
    const editedRow = rows.find((row) => row.id === id);
    setDriverData(editedRow)

    // setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setShowHideDriverForm(true)
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: "id",
      headerName: "#",
      width: 30,
      id:"id"
    },
    // {
    //   field: "driverType",
    //   headerName: "Driver Type",
    //   width: 100,
    // },
    { field: "firstName", headerName: "Driver Name", width: 350, id:"firstname" },
    // {
    //   field: "age",
    //   headerName: "Age",
    //   width: 80,
    // },
    // {
    //   field: "gender",
    //   headerName: "Gender",
    //   width: 100,
    // },
    // {
    //   field: "marital",
    //   headerName: "Marital",
    //   width: 100,
    // },
    // { field: "violations", headerName: "#Violations", width: 100 },
    // {
    //   field: "priorInsurance",
    //   headerName: "Prior Insurancep",
    //   width: 150,
    // },
    {
      field: "actions",
      type: "actions",
      id:"actions",
      headerName: "actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              id="editicon"
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />
            ,
            <GridActionsCellItem
              icon={<CancelIcon />}
              id="cancelicon"
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          // <GridActionsCellItem
          //   icon={<EditIcon />}
          //   label="Edit"
          //   className="textPrimary"
          //   onClick={handleEditClick(id)}
          //   color="inherit"
          // />
          <Box sx={{ display: "flex", gap: "11.5px", alignItems: "center" }}>
            <IconButton 
            id="editicon"
            onClick={handleEditClick(id)}>
              <EditIcon />
            </IconButton>
            <IconButton 
            id="deleteicon"
            onClick={handleDeleteClick(id)}>
              <DeleteIcon />
            </IconButton>
          </Box>,
          // <GridActionsCellItem
          //   icon={<DeleteIcon />}
          //   label="Delete"
          //   onClick={handleDeleteClick(id)}
          //   color="inherit"
          // />,
        ];
      },
    },
  ];

  const EditToolbar = (props) => {
    // const { setRows, setRowModesModel } = props;

    function handleClick() {
      setShowHideDriverForm(true);
      setDriverData({})
      // const id = rows.length + 1;
      // setRows((oldRows) => [...oldRows, { id, name: '', age: '', isNew: true }]);
      // setRowModesModel((oldModel) => ({
      //     ...oldModel,
      //     [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
      // }));
    }

    // return (
    //   <GridToolbarContainer>
    //     <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
    //       Add Driver
    //     </Button>
    //   </GridToolbarContainer>
    // );
  };
  return (
    <>

      {showHideDriverForm ? (
        <>
         <Box sx={{marginLeft:"-200px"}}>
          <DriverSave setShowHideDriverForm={setShowHideDriverForm} driverData = {driverData} setshowbutton={showButton}  />
          </Box>
        </>
      ) : (
    //  <DriverSave />
        <>
          <Box
            sx={{
              height: 500,
              width: "100%",
              "& .actions": {
                color: "text.secondary",
              },
              "& .textPrimary": {
                color: "text.primary",
              },
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              hideFooterPagination={true}
              sx={{
                border: 'none',
                '& .MuiDataGrid-cell': {
                  border: 'none',
                },
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-row': {
                  borderBottom: 'none',
                },
              }}
              className={classes.root}

            />
          </Box>
        </>
    )}

    </>
  );
};

export default DrvierTab;

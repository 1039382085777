const initialState = {
    taskForm: JSON.parse(localStorage.getItem('taskForm')) || {},
    allTask: [],
    searchTask:[],
    error: null,
  };

  const taskReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_TASK_SUCCESS':
        // localStorage.setItem('customizedForm', JSON.stringify(action.payload));
        // return { customizedForm: action.payload, error: null };
        return { ...state, allTask: action.payload, error: null };
      case 'SAVE_TASK_SUCCESS':
        localStorage.setItem('taskForm', JSON.stringify(action.payload));
        return { ...state, taskForm: action.payload, error: null };
      case 'SEARCH_TASK_SUCCESS':
            return { ...state, searchTask: action.payload, error: null };
      case 'UPDATE_TASK_SUCCESS':
        localStorage.setItem('taskForm', JSON.stringify(action.payload));
        return { ...state, taskForm: action.payload, error: null };
      case 'DELETE_TASK_SUCCESS':
        const updatedTask = state.allTask.filter(policy => policy.id !== action.payload);
        return { ...state, allTask: updatedTask, error: null };
      case 'GET_TASK_FAILURE':
      case 'SAVE_TASK_FAILURE':
      case 'DELETE_TASK_FAILURE':
      case 'SEARCH_TASK_FAILURE':
      case 'UPDATE_TASK_FAILURE':
        return { ...state, error: action.payload };
      default:
        return state;
    }
  };

  export default taskReducer;

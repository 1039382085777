import { CLOSE_CREATE_CARRIER_SELECTIONS_INFO_MODAL, OPEN_CREATE_CARRIER_SELECTIONS_INFO_MODAL, OPEN_UPDATE_CARRIER_SELECTIONS_INFO_MODAL } from "../actions/actionTypes";

const initialState = {
    allCarrierSelectionsInfo: [],
    error: null,
    isCreateCarrierSelectionsInfoModalOpen: false,
    selectedCarrierSelectionsInfoId: null,
    selectedRateList: []
  };
  
  const carrierSelectionsInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ALL_CARRIER_SELECTIONS_INFOS_SUCCESS':
            return { ...state, allCarrierSelectionsInfo: action.payload, error: null };
        case 'CREATE_CARRIER_SELECTIONS_INFO_SUCCESS':
        case 'UPDATE_CARRIER_SELECTIONS_INFO_SUCCESS':
            return {
                ...state,
                allCarrierSelectionsInfo: [ ...state.allCarrierSelectionsInfo, ...action.payload ],
                error: null,
            };
        case OPEN_CREATE_CARRIER_SELECTIONS_INFO_MODAL:
            return {...state, isCreateCarrierSelectionsInfoModalOpen: true}
        case OPEN_UPDATE_CARRIER_SELECTIONS_INFO_MODAL:
            return {...state, isCreateCarrierSelectionsInfoModalOpen: true, selectedCarrierSelectionsInfoId: action.payload}
        case CLOSE_CREATE_CARRIER_SELECTIONS_INFO_MODAL:
            return {...state, isCreateCarrierSelectionsInfoModalOpen: false, selectedCarrierSelectionsInfoId: null}
        case 'ALL_CARRIER_SELECTIONS_INFOS_FAILURE':
        case 'CREATE_CARRIER_SELECTIONS_INFO_FAILURE':
        case 'UPDATE_CARRIER_SELECTIONS_INFO_FAILURE':
            return { ...state, error: action.payload };
        case 'GET_SELECTED_RATES_ACTION':
            return {...state, selectedRateList: action.payload}
        default:
            return state;
    }
  };
  
  export default carrierSelectionsInfoReducer;
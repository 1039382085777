import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AddNotes from './AddNotes';  // Import the AddNotes component
import AddAlert from './AddAlert';  // Import the AddAlert component
import NotesListing from './NotesListing';  // Import the NotesListing component
import AddComment from './AddComment';  // Import the AddComment component
import ViewSystemNotes from './ViewSystemNotes';  // Import the ViewSystemNotes component
import ViewDrip from './ViewDrip';  // Import the ViewDrip component

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`fullwidthtabpanel${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `fullwidthtab${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const Notes = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ bgcolor: 'background.paper', border: '#D7D7D7 solid 1px' }}>
      <AppBar position="static" sx={{ bgcolor: "#F8F8F8", boxShadow: "none" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab sx={{ textTransform: 'capitalize' }} label="View Notes" {...a11yProps(0)} />
          <Tab sx={{ textTransform: 'capitalize' }} label="Add Note" {...a11yProps(1)} />
          <Tab sx={{ textTransform: 'capitalize' }} label="Add Alert" {...a11yProps(2)} />
          <Tab sx={{ textTransform: 'capitalize' }} label="Add Comment" {...a11yProps(3)} />
          <Tab sx={{ textTransform: 'capitalize' }} label="View System Notes" {...a11yProps(4)} />
          <Tab sx={{ textTransform: 'capitalize' }} label="View Drip" {...a11yProps(5)} />
          <Tab sx={{ textTransform: 'capitalize' }} label="Print Notes" {...a11yProps(6)} />
          <Tab sx={{ textTransform: 'capitalize' }} label="Email" {...a11yProps(7)} />
          <Tab sx={{ textTransform: 'capitalize' }} label="Email Blast" {...a11yProps(8)} />
          <Tab sx={{ textTransform: 'capitalize' }} label="Text Blast" {...a11yProps(9)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} dir={theme.direction}>
        <NotesListing />
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <AddNotes />
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        <AddAlert />
      </TabPanel>
      <TabPanel value={value} index={3} dir={theme.direction}>
        <AddComment />
      </TabPanel>
      <TabPanel value={value} index={4} dir={theme.direction}>
        <ViewSystemNotes />
      </TabPanel>
      <TabPanel value={value} index={5} dir={theme.direction}>
        <ViewDrip />
      </TabPanel>
      {Array.from({ length: 4 }).map((_, index) => (
        <TabPanel key={index + 6} value={value} index={index + 6} dir={theme.direction}>
          {`Item ${index + 7}`}
        </TabPanel>
      ))}
    </Box>
  );
};

export default Notes;

import React from 'react';
import { TextField, Typography, Grid, Button, Box } from '@mui/material';

const AddAlert = () => {
  const [alert, setAlert] = React.useState('');

  const handleNoteChange = (event) => {
    setAlert(event.target.value);
  };

  return (
    <>
      <Box component="form" id="addalertform" noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Typography id="alert-label">Alert</Typography>
            <TextField
              id="alertinput" 
              value={alert}
              onChange={handleNoteChange}
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              margin="normal"
              required
            />
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button id="submitbutton" variant="contained" color="primary">
            Submit
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default AddAlert;


const initialState = {
    customizedForm: {},
    error: null,
  };
  
  const formLayoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_FORM_LAYOUT_SUCCESS':
            return { customizedForm: action.payload, error: null };
        case 'SAVE_FORM_LAYOUT_SUCCESS':
        case 'UPDATE_FORM_LAYOUT_SUCCESS':
            return { ...state, customizedForm: action.payload, error: null };
        case 'GET_FORM_LAYOUT_FAILURE':
        case 'SAVE_FORM_LAYOUT_FAILUTE':
        case 'UPDATE_FORM_LAYOUT_FAILUTE':
            return { ...state, error: action.payload };
        default:
            return state;
    }
  };
  
  export default formLayoutReducer;
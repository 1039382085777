import React, { useState, useEffect } from "react";
import {
  Paper,
  Box,
  TablePagination,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import {
  setDashboardData,
  setDashboardPolicyData,
} from "../../../redux/actions/dashboardAction";
import Loader from "../../loader/loader";
import PolicyDetails from "../PolicyDetails/PolicyDetails";


const PolicyListing = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);

  // Load initial data when the component mounts
  useEffect(() => {
    dispatch(setDashboardData());
    dispatch(setDashboardPolicyData());
  }, [dispatch]);

  // Load initial data into filteredData
  const policyListingData =
    useSelector((state) => state.dashboardData.policies?.policiesList) || [];
    const loading = useSelector((state) => state.dashboardData.loading);

  useEffect(() => {
    setFilteredData(policyListingData); // Load all data by default
  }, [policyListingData]);

  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRows = policyListingData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleRowClick = (id) => {
    const selectedRowData = policyListingData.filter((row) => row.id === id);
    setSelectedId(selectedRowData);
  };

    return (
        <>
            <TableContainer component={Paper} sx={{ mt: 3 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell id="nameheader">Name</TableCell>
                            <TableCell id="policynumberheader" align="left">Policy Number</TableCell>
                            <TableCell id="phonenumberheader" align="left">Phone Number</TableCell>
                            <TableCell id="policytypeheader" align="left">Policy Type</TableCell>
                            <TableCell id="companyheader" align="left">Company</TableCell>
                            <TableCell id="lineofbusinessheader" align="left">Line of Business</TableCell>
                            <TableCell id="effdateheader" align="left">Eff Date</TableCell>
                            <TableCell id="expdateheader" align="left">Exp Date</TableCell>
                            <TableCell id="statusheader" align="left">Status</TableCell>
                            <TableCell id="premiumheader" align="left">Premium</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={8}>
                                        <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
                                        <Typography variant="subtitle1" color="textSecondary">
                                            <Loader />
                                        </Typography>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ) : paginatedRows.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={10}>
                                    <Box
                                        sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        padding: 2,
                                        }}
                                    >
                                        <Typography variant="subtitle1" color="textSecondary">
                                            No Data found.
                                        </Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                            ) : (
                            paginatedRows.map((row) => (
                            <TableRow
                                onClick={() => handleRowClick(row.id)}
                                key={row.id}
                                id={`row-${row.id}`}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row?.name || "Jennifer Bravo"}
                                </TableCell>
                                <TableCell align="left">{row?.policyNumber}</TableCell>
                                <TableCell align="left">
                                    {row?.phoneNumber || "1242141122"}
                                </TableCell>
                                <TableCell align="left">{row?.policyType}</TableCell>
                                <TableCell align="left">{row?.company}</TableCell>
                                <TableCell align="left">{row?.lineOfBusiness}</TableCell>
                                <TableCell align="left">{row?.effectiveDate}</TableCell>
                                <TableCell align="left">{row?.expiryDate}</TableCell>
                                <TableCell align="left">{row?.status || "active"}</TableCell>
                                <TableCell align="left">{row?.premium}</TableCell>
                            </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={policyListingData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <PolicyDetails selectedPolicyDetail={selectedId} />
        </>
    )
}
export default PolicyListing;
import { SAVE_NOTE_FAILURE,SAVE_NOTE_SUCCESS,STOP_LOADING,START_LOADING,UPDATE_NOTE_FAILURE,UPDATE_NOTE_SUCCESS,DELETE_NOTE_FAILURE,DELETE_NOTE_SUCCESS,
    GET_NOTE_FAILURE,GET_NOTE_SUCCESS,SAVE_ALERT_SUCCESS,SAVE_ALERT_FAILURE,SAVE_COMMENT_SUCCESS,SAVE_COMMENT_FAILURE,SEARCH_NOTE_SUCCESS,SEARCH_NOTE_FAILURE} from "./actionTypes";
  import { saveNote,updateNote,getAllNote,deleteNoteById, saveAlert,saveComment, searchNote } from "../../services/noteServices";
  import { openSessionTimeoutModal } from './sessionTimeOutActions';


  export const saveNoteAction = (formData, callbackfn) => async (dispatch, getState) => {
      try {
        dispatch({ type: START_LOADING, service: 'SAVE_NOTE' });
        const token = getState().auth.token || sessionStorage.getItem("token");
        const response = await saveNote(formData, token);
        // console.log(response);
        dispatch({ type: STOP_LOADING, service: 'SAVE_NOTE' });
        if (response) {
          callbackfn(response);
        }
        dispatch({ type: SAVE_NOTE_SUCCESS, payload: response }); // Dispatch the success action with response data
      } catch (error) {
        if (error?.includes("403")) {
          dispatch(openSessionTimeoutModal());
        } else {
          dispatch({ type: STOP_LOADING, service: 'SAVE_NOTE' });
          dispatch({ type: SAVE_NOTE_FAILURE, payload: error || 'Save task service failed. Please try again.' });
        }
      }
    };

    export const updateNoteAction = (formData,callbackfn) => async (dispatch, getState) => {
      try {
        dispatch({ type: START_LOADING, service: 'UPDATE_NOTE' });
        const token = getState().auth.token || sessionStorage.getItem("token");;
        const response = await updateNote(formData, token);
        dispatch({ type: STOP_LOADING, service: 'UPDATE_NOTE' });
        if (response) {
          callbackfn(response);
        }
        dispatch({ type: UPDATE_NOTE_SUCCESS, payload: response });
      } catch (error) {
        // console.log(error, 'error in action')
        if(error?.includes("403")) {
          dispatch(openSessionTimeoutModal());
        } else {
          dispatch({ type: STOP_LOADING, service: 'UPDATE_NOTE' });
          dispatch({ type: UPDATE_NOTE_FAILURE, payload: error || 'Update task service failed. Please try again.' });
        }
      }
    };

    export const getAllNoteAction= () => async (dispatch, getState) => {
      try {
        dispatch({ type: START_LOADING, service: 'ALL_NOTE' });
        const token = getState().auth.token || sessionStorage.getItem("token");
        const response = await getAllNote(token);
        dispatch({ type: STOP_LOADING, service: 'ALL_NOTE' });
        dispatch({ type: GET_NOTE_SUCCESS, payload: response });
      } catch (error) {
        if(error?.includes("403")) {
          dispatch(openSessionTimeoutModal());
        } else {
          dispatch({ type: STOP_LOADING, service: 'ALL_NOTE' });
          dispatch({ type: GET_NOTE_FAILURE, payload: error || 'All task service failed. Please try again.' });
        }
      }
    };

    // export const getAllNoteAction= (page = 0, size = 1) => async (dispatch, getState) => {
    //   console.log("in note action");
    //   try {
    //     dispatch({ type: START_LOADING, service: 'ALL_NOTE' });
    //     const token = getState().auth.token || sessionStorage.getItem("token");
    //     const response = await getAllNote(token, page, size);
    //     console.log("response value of notes",response);
    //     dispatch({ type: STOP_LOADING, service: 'ALL_NOTE' });
    //     dispatch({ type: GET_NOTE_SUCCESS, payload: response });
    //   } catch (error) {
    //     if(error?.includes("403")) {
    //       dispatch(openSessionTimeoutModal());
    //     } else {
    //       dispatch({ type: STOP_LOADING, service: 'ALL_NOTE' });
    //       dispatch({ type: GET_NOTE_FAILURE, payload: error || 'All task service failed. Please try again.' });
    //     }
    //   }
    // };

    export const deleteNoteActionById = (quoteId) => async (dispatch, getState) => {
      try {
        dispatch({ type: START_LOADING, service: 'ALL_NOTE' });
        const token = getState().auth.token || sessionStorage.getItem("token");
        // console.log("quoteid",quoteId);
        const response= await deleteNoteById(quoteId,token);
        dispatch({ type: STOP_LOADING, service: 'ALL_NOTE' });
        // console.log("Token:", token);
        // console.log("quoteid",quoteId);

        // dispatch({ type: 'ALL_DRIVER_INFOS_SUCCESS', payload: response });
        dispatch({ type: DELETE_NOTE_SUCCESS, payload: quoteId });
        return response;
      } catch (error) {
        dispatch({ type: STOP_LOADING, service: 'ALL_NOTE' });
        dispatch({ type: DELETE_NOTE_FAILURE, payload: error.message || 'All task service failed. Please try again.' });
      }
    };

    export const searchNoteAction = (formData, callbackfn) => async (dispatch, getState) => {
      try {
        dispatch({ type: START_LOADING, service: 'SEARCH_NOTE' });
        const token = getState().auth.token || sessionStorage.getItem("token");
        const response = await searchNote(formData, token);
        // console.log(response);
        dispatch({ type: STOP_LOADING, service: 'SEARCH_NOTE' });
        if (response) {
          callbackfn(response);
        }
        dispatch({ type: SEARCH_NOTE_SUCCESS, payload: response }); // Dispatch the success action with response data
      } catch (error) {
        if (error?.includes("403")) {
          dispatch(openSessionTimeoutModal());
        } else {
          dispatch({ type: STOP_LOADING, service: 'SEARCH_NOTE' });
          dispatch({ type: SEARCH_NOTE_FAILURE, payload: error || 'Search note  failed. Please try again.' });
        }
      }
    };

    //Alert

    export const saveAlertAction = (formData, callbackfn) => async (dispatch, getState) => {
        try {
          dispatch({ type: START_LOADING, service: 'SAVE_ALERT' });
          const token = getState().auth.token || sessionStorage.getItem("token");
          const response = await saveAlert(formData, token);
          // console.log("alert",response);
          dispatch({ type: STOP_LOADING, service: 'SAVE_ALERT' });
          if (response) {
            callbackfn(response);
          }
          dispatch({ type: SAVE_ALERT_SUCCESS, payload: response }); // Dispatch the success action with response data
        } catch (error) {
          if (error?.includes("403")) {
            dispatch(openSessionTimeoutModal());
          } else {
            dispatch({ type: STOP_LOADING, service: 'SAVE_ALERT' });
            dispatch({ type: SAVE_ALERT_FAILURE, payload: error || 'Save alert service failed. Please try again.' });
          }
        }
      };

//comment
export const saveCommentAction = (formData, callbackfn) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'SAVE_COMMENT' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await saveComment(formData, token);
      // console.log("alert",response);
      dispatch({ type: STOP_LOADING, service: 'SAVE_COMMENT' });
      if (response) {
        callbackfn(response);
      }
      dispatch({ type: SAVE_COMMENT_SUCCESS, payload: response }); // Dispatch the success action with response data
    } catch (error) {
      if (error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: STOP_LOADING, service: 'SAVE_COMMENT' });
        dispatch({ type: SAVE_COMMENT_FAILURE, payload: error || 'Save comment service failed. Please try again.' });
      }
    }
  };


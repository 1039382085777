import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  TextField,
  Box,
  Container,
  Snackbar,
  Alert,
  Grid,
  Typography,
  Modal,
  Paper,
  Select,
  MenuItem
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { alphanumericValidation, alphanumericWithSpaceValidation, validateEmail, validateField, validateMobile } from '../../common/helpers/validation';
import {
  getUser as getUserAction,
  closeCreateUserModal as closeCreateUserModalAction,
  createUser as createUserAction,
  getAllUser as getAllUserAction,
  updateUser as updateUserAction
} from '../../redux/actions/userActions';
import { CircleOutlined } from '@mui/icons-material';
import { getAllRoles } from '../../redux/actions/roleActions';

const requiredFields = ['firstName', 'lastName', 'email', 'password'];

const validationRules = [
  { fieldName: 'firstName', label: 'First Name', type: 'text' },
  { fieldName: 'lastName', label: 'Last Name', type: 'text' },
  { fieldName: 'email', label: 'Email Id', type: 'text' },
  { fieldName: 'password', label: 'Password', type: 'password' },
  { fieldName: 'role', label: 'Select Role', type: 'select' },
];

const validationFunctions = {
  firstName: alphanumericWithSpaceValidation,
  lastName: alphanumericWithSpaceValidation,
  email: validateEmail,
  password: validateField,
};

const useStyles = makeStyles((theme) => ({
  formContainer: {
    marginBottom: theme.spacing(2),
  },
  select: {
    minWidth: 150,
  },
  centerContent: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '.5rem !important',
  },
  fullWidth: {
    width: '100%',
  },
}));


const CreateTeam = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const loading = useSelector((state) => state.loading.CREATE_USER);
  const isModalOpen = useSelector((state) => state?.user?.isCreateUserModalOpen);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(isModalOpen);
  const roles = useSelector((state) => state?.roles?.allRoles)

  const [formState, setFormState] = useState({
    formData: validationRules.reduce((fields, { fieldName }) => ({
      ...fields,
      [fieldName]: '',
    }), {}),
    touchedFields: validationRules.reduce((fields, { fieldName }) => ({
      ...fields,
      [fieldName]: false,
    }), {}),
    fieldErrors: validationRules.reduce((errors, { fieldName }) => ({
      ...errors,
      [fieldName]: '',
    }), {}),
  });

  const user = useSelector((state) => state.user);
  const selectedUserId = useSelector((state) => state.user.selectedUserId);
  const allUser = useSelector((state) => state?.user?.allUser);
  const customerDataForUpdate = allUser && allUser.length && allUser.filter((user) => user.id === selectedUserId);
  const customerForUpdate = customerDataForUpdate && customerDataForUpdate[0];

  React.useEffect(() => {
    if (!user) {
      dispatch(getUserAction());
    }
  }, [])

  React.useEffect(() => {
    if (user) {
      dispatch(getAllRoles(user?.customer?.id));
    }
  }, [user])

  const { formData, touchedFields, fieldErrors } = formState;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };
  
  const handleSelectChange = (event) => {
    setFormState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        role: event.target.value,
      },
    }));
  };

  const handleBlur = (field) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      touchedFields: {
        ...prevFormState.touchedFields,
        [field]: true,
      },
    }));

    if (!requiredFields?.includes(field)) {
      return;
    }

    const value = formData[field];
    const error = validateField(value, validationRules.find(rule => rule.fieldName === field).label);

    // Additional validations
    const additionalError = validationFunctions[field] ? validationFunctions[field](value) : '';

    setFormState((prevFormState) => ({
      ...prevFormState,
      fieldErrors: {
        ...prevFormState.fieldErrors,
        [field]: error || additionalError,
      },
    }));
  };

  const handleSnackbarClose = () => {
    setSnackbar((prevSnackbar) => ({
      ...prevSnackbar,
      open: false,
    }));
  };

  const handleSnackbarOpen = (message, severity) => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationErrors = validationRules.reduce((errors, { fieldName, label }) => {
      if (requiredFields?.includes(fieldName)) {
        const value = formData[fieldName];
        const error = validateField(value, label);

        // Additional validations
        const additionalError = validationFunctions[fieldName] ? validationFunctions[fieldName](value) : '';

        setFormState((prevFormState) => ({
          ...prevFormState,
          fieldErrors: {
            ...prevFormState.fieldErrors,
            [fieldName]: error || additionalError,
          },
        }));

        return {
          ...errors,
          [fieldName]: error || additionalError,
        };
      }

      return errors;
    }, {});

    const hasErrors = Object.values(validationErrors).some((error) => !!error);

    if (!hasErrors) {
      try {
        const roleName = roles?.filter(role => role.id === formData.role)?.[0].name;
        if (selectedUserId) {
          const newFormData = {
            ...formData,
            id: selectedUserId,
            updatedBy: user.user.id,
            customer: {
              id: user.user.customer.id
            },
            roles: {
              id: formData.role
            },
            role: roleName,
          };
          await dispatch(updateUserAction(newFormData, getCreateUserSuccess));
        } else {
          const newFormData = {
            ...formData,
            insertedBy: user.user.id,
            updatedBy: user.user.id,
            id: 0,
            customer: {
              id: user.user.customer.id
            },
            roles: {
              id: formData.role
            },
            role: roleName,
          };
          await dispatch(createUserAction(newFormData, getCreateUserSuccess));
        }

      } catch (error) {
        handleSnackbarOpen(error || 'User service failed. Please try again.', 'error');
      }
    } else {
      handleSnackbarOpen('Validation errors detected. Please correct them.', 'error');
    }
  };

  const getCreateUserSuccess = async () => {
    selectedUserId ? handleSnackbarOpen('User updated successfully.', 'success') : handleSnackbarOpen('User created successfully.', 'success');
    await dispatch(getAllUserAction());

    setFormState({
      formData: validationRules.reduce((fields, { fieldName }) => ({
        ...fields,
        [fieldName]: '',
      }), {}),
      touchedFields: validationRules.reduce((fields, { fieldName }) => ({
        ...fields,
        [fieldName]: false,
      }), {}),
      fieldErrors: validationRules.reduce((errors, { fieldName }) => ({
        ...errors,
        [fieldName]: '',
      }), {}),
    });

    setTimeout(() => {
      handleSnackbarClose();
      if (location.pathname?.includes("teams")) {
        dispatch(closeCreateUserModalAction());
      } else {
        dispatch(closeCreateUserModalAction());
        navigate("/teams")
      }
    }, 3000)
  }

  useEffect(() => {
    setIsCreateModalOpen(isModalOpen);
    setFormState((prevFormState) => ({
      ...prevFormState,
      formData: validationRules.reduce((fields, { fieldName }) => ({
        ...fields,
        [fieldName]: selectedUserId ? (customerForUpdate && customerForUpdate[fieldName]) : '',
      }), {}),
    }));
  }, [isModalOpen, dispatch]);

  const closeModal = () => {
    setFormState({
      formData: validationRules.reduce((fields, { fieldName }) => ({
        ...fields,
        [fieldName]: '',
      }), {}),
      touchedFields: validationRules.reduce((fields, { fieldName }) => ({
        ...fields,
        [fieldName]: false,
      }), {}),
      fieldErrors: validationRules.reduce((errors, { fieldName }) => ({
        ...errors,
        [fieldName]: '',
      }), {}),
    });

    dispatch(closeCreateUserModalAction());
  }

  return (
    <Modal open={isCreateModalOpen}>
      <Paper style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', maxWidth: '70vw', height: "80vh", overflowX: "auto" }}>
        <Container component="main" >
          <Grid item xs={12} md={12} sx={{ position: "sticky" }}>
            <Typography variant="h5">{selectedUserId ? 'Update User' : 'Create User'}</Typography>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <Grid container spacing={2}>
              {validationRules.map(({ fieldName, label, type }, index) => (
                  <Grid item xs={12} sm={6} key={`key${index+1}`}>
                    {type === 'select' ?
                      <>
                        <Select
                          id="organizationselectrequired"
                          value={formData[fieldName] || 0}
                          label={`${label} *`}
                          onChange={handleSelectChange}
                          className={`${classes.select} ${classes.fullWidth}`}
                        >
                          <MenuItem value={0}>
                            <em>{label} *</em>
                          </MenuItem>
                          {roles.map((row)=><MenuItem value={row.id}>{row.name}</MenuItem>)}
                        </Select>
                      </>
                      :
                      <TextField
                        margin="normal"
                        required={requiredFields?.includes(fieldName)}
                        fullWidth
                        id={fieldName}
                        label={label}
                        type={type}
                        name={fieldName}
                        autoComplete={fieldName}
                        value={formData[fieldName]}
                        onChange={handleInputChange}
                        onBlur={() => handleBlur(fieldName)}
                        error={touchedFields[fieldName] && !!fieldErrors[fieldName]}
                        helperText={touchedFields[fieldName] ? fieldErrors[fieldName] : ''}
                      />
                    }
                  </Grid>
                ))}
              </Grid>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="submit"
                  id="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2, marginRight: "1rem" }}
                >
                  {loading ? <CircleOutlined size="24" color="secondary" /> : selectedUserId ? 'Update User' : 'Create User'}
                </Button>
                <Button
                  variant="contained"
                  id="close"
                  sx={{ mt: 3, mb: 2 }}
                  color="secondary"
                  onClick={closeModal}
                >
                  Close
                </Button>
              </Box>
            </Box>
          </Box>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Container>
      </Paper>
    </Modal>
  );
};

export default CreateTeam;

import * as leadServices from '../../services/newleadServices';
import {
  CLOSE_CREATE_LEAD_MODAL,
  OPEN_CREATE_LEAD_MODAL,
    OPEN_UPDATE_LEAD_MODAL,
    START_LOADING,
    STOP_LOADING
} from "./actionTypes"
import { openSessionTimeoutModal } from './sessionTimeOutActions';

export const createLead = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'NEW_CREATE_LEAD' });
    const token = getState().auth.token || sessionStorage.getItem("token");;
    const response = await leadServices.createLead(formData, token);
    dispatch({ type: STOP_LOADING, service: 'NEW_CREATE_LEAD' });
    // if(response) {
    //   callbackfn();
    // }
    dispatch({ type: 'NEW_CREATE_LEAD_SUCCESS', payload: response });
  } catch (error) {
      dispatch({ type: STOP_LOADING, service: 'NEW_CREATE_LEAD' });
      dispatch({ type: 'NEW_CREATE_LEAD_FAILURE', payload: error || 'Create Lead service failed. Please try again.' });
    }
};

export const updateLead = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'NEW_UPDATE_LEAD' });
    const token = getState().auth.token || sessionStorage.getItem("token");;
    const response = await leadServices.updateLead(formData, token);
    dispatch({ type: STOP_LOADING, service: 'NEW_UPDATE_LEAD' });
    // if(response) {
    //   callbackfn();
    // }
    dispatch({ type: 'NEW_UPDATE_LEAD_SUCCESS', payload: response });
  } catch (error) {
      dispatch({ type: STOP_LOADING, service: 'NEW_UPDATE_LEAD' });
      dispatch({ type: 'NEW_UPDATE_LEAD_FAILURE', payload: error || 'Update Lead service failed. Please try again.' });
  }
};
export const updateLeadAddress = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'NEW_UPDATE_LEAD_ADDRESS' });
    const token = getState().auth.token || sessionStorage.getItem("token");;
    const response = await leadServices.updateLeadAddress(formData, token);
    dispatch({ type: STOP_LOADING, service: 'NEW_UPDATE_LEAD_ADDRESS' });
    // if(response) {
    //   callbackfn();
    // }
    dispatch({ type: 'NEW_UPDATE_LEAD_SUCCESS_ADDRESS', payload: response });
  } catch (error) {
      dispatch({ type: STOP_LOADING, service: 'NEW_UPDATE_LEAD_ADDRESS' });
      dispatch({ type: 'NEW_UPDATE_LEAD_FAILURE_ADDRESS', payload: error || 'Update Lead Address service failed. Please try again.' });
  }
};

export const getAllLeads = () => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'NEW_ALL_LEADS' });
    const token = getState().auth.token || sessionStorage.getItem("token");
    const response = await leadServices.getAllLeads(token);
    dispatch({ type: STOP_LOADING, service: 'NEW_ALL_LEADS' });
    dispatch({ type: 'NEW_ALL_LEADS_SUCCESS', payload: response });
  } catch (error) {
    if(error?.includes("403")) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'NEW_ALL_LEADS' });
      dispatch({ type: '_NEW_ALL_LEADS_FAILURE', payload: error || 'All Leads service failed. Please try again.' });
    }
  }
};

export const getMyLeads = (ownerId) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'NEW_MY_LEADS' });
    const token = getState().auth.token || sessionStorage.getItem("token");;
    const response = await leadServices.getMyLeads(ownerId, token);
    dispatch({ type: STOP_LOADING, service: 'NEW_MY_LEADS' });
    dispatch({ type: 'NEW_MY_LEADS_SUCCESS', payload: response });
  } catch (error) {
    if(error?.includes("403")) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'NEW_MY_LEADS' });
      dispatch({ type: 'NEW_MY_LEADS_FAILURE', payload: error || 'My Leads service failed. Please try again.' });
    }
  }
};

export const getLeadById = (leadId) => async (dispatch, getState) => {
  try { 
    dispatch({ type: START_LOADING, service: 'GET_LEAD' });
    const token = getState().auth.token || sessionStorage.getItem("token");
    const response = await leadServices.getLeadById(leadId, token);
    dispatch({ type: STOP_LOADING, service: 'GET_LEAD' });
    dispatch({ type: 'GET_LEAD_BY_ID_SUCCESS', payload: response });
  } catch (error) {
      dispatch({ type: STOP_LOADING, service: 'GET_LEAD' });
      dispatch({ type: 'GET_LEAD_BY_ID_FAILURE', payload: error || 'Get Lead by id service failed. Please try again.' });
  }
};

export const openCreateLeadModal = () => ({
  type: OPEN_CREATE_LEAD_MODAL,
});

export const closeCreateLeadModal = () => ({
  type: CLOSE_CREATE_LEAD_MODAL,
});

export const openUpdateLeadModal = (id) => async (dispatch) => {
  dispatch({ type:OPEN_UPDATE_LEAD_MODAL, payload: id });
};
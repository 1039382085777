

import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  Checkbox,
  FormControl,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Box,
  Grid,
  Typography,
  Paper,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  saveProfileAction,
  updateProfileAction,
} from "../../../redux/actions/agencyActions";
import { useDispatch, useSelector } from "react-redux";
import { getAllLocationAction } from "../../../redux/actions/locationActions";
import { getRolesList } from "../../../redux/actions/roleActions";

export default function UserProfileForm({ user, toggleForm }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const locationDetails = useSelector((state) => state.location.allLocation);
  const getAllRoles = useSelector((state) => state.location.getAllRolesList);
  const userError = useSelector((state) => state.agency.allError);
  // console.log("usererrro",userError)

  // State variables (unchanged)
  const [prefix, setPrefix] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [suffix, setSuffix] = useState("");
  // const [designation, setDesignation] = useState('');
  const [emailAddress, setEmail] = useState("");
  const [emailConfirm, setEmailConfirm] = useState("");
  const [userType, setUserType] = useState("");
  const [status, setStatus] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [homePhone, setHomePhone] = useState("");
  const [faxNumber, setFaxNumber] = useState("");
  const [workPhone, setWorkPhone] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [defaultLocation, setDefaultLocation] = useState("");
  const [role, setRole] = useState("");
  // const [companyGroup, setCompanyGroup] = useState('');
  const [permissions, setPermissions] = useState({
    personalAuto: false,
    idCard: false,
    binders: false,
    homeowner: false,
  });

  const [errors, setErrors] = useState({}); // State for form errors

  let locationList = [];
  if (Array.isArray(locationDetails)) {
    locationList = locationDetails.map((location) => ({
      value: location.locationDescription,
      label: location.locationDescription,
    }));
  }


  useEffect(() => {
    dispatch(getAllLocationAction());
    dispatch(getRolesList());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      setPrefix(user.prefix || "");
      setFirstName(user.firstName || "");
      setMiddleName(user.middleName || "");
      setLastName(user.lastName || "");
      setSuffix(user.suffix || "");
      // setDesignation(user.designation || '');
      setEmail(user.emailAddress || "");
      setEmailConfirm(user.emailAddress || ""); // Assuming emailConfirm is same as email
      setUserType(user.userType || "");
      setStatus(user.status || "");
      setUserName(user.userName || "");
      setPassword(""); // Do not prefill password for security reasons
      setConfirmPassword(""); // Do not prefill password confirmation
      setPhone(user.phone || "");
      setHomePhone(user.homePhone || "");
      setFaxNumber(user.faxNumber || "");
      setWorkPhone(user.workPhone || "");
      setCellPhone(user.cellPhone || "");
      setDefaultLocation(user.locationDescription || "");
      setRole(user.role || "");
      // setCompanyGroup(user.companyGroup || '');
      setPermissions(
        user.permissions || {
          personalAuto: false,
          idCard: false,
          binders: false,
          homeowner: false,
        }
      );
    }
  }, [user]);

  const handlePermissionChange = (event) => {
    setPermissions({
      ...permissions,
      [event.target.name]: event.target.checked,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (!firstName) newErrors.firstName = "First Name is required";
    if (!lastName) newErrors.lastName = "Last Name is required";
    if (!emailAddress) newErrors.emailAddress = "Email Address is required";
    if (emailAddress !== emailConfirm) newErrors.emailConfirm = "Emails do not match";
    if (!userName) newErrors.userName = "User Name is required";
  
    // Only validate password fields if not in edit mode (i.e., if no user is passed)
    if (!user) {
      if (!password) newErrors.password = "Password is required";
      if (password !== confirmPassword) newErrors.confirmPassword = "Passwords do not match";
    }
  
    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const formData = {
      prefix,
      firstName,
      middleName,
      lastName,
      suffix,
      emailAddress,
      emailConfirm,
      userType,
      status,
      userName,
      password,
      confirmPassword,
      phone,
      homePhone,
      faxNumber,
      workPhone,
      cellPhone,
      defaultLocation,
      role,
      permissions,
    };

    // const handleResponse = (response, error) => {
    //   if (response) {
    //     toggleForm(); // Close form after successful save/update
    //   } else if (error) {
    //     setErrors({ emailAddress: error.errorMessage }); // Set email error
    //   }
    // };
    const handleResponse = (response,error) => {
      if (response) {
        toggleForm(); // Close form after successful save/update
      } else if (error && error.errors && error.errors.length > 0) {
        // Initialize an empty errors object to store errors for email and username
        const newErrors = {};

        // Iterate through each error in the errors array
        error.errors.forEach(({ errorMessage, target }) => {
          // Check if the target relates to 'username' or 'email' and add it to the newErrors object
          console.log("target.toLowerCase().includes",target.toLowerCase().includes("username"))
          if (target.toLowerCase().includes("username")) {
            newErrors.userName = errorMessage;
          } else if (target.toLowerCase().includes("email")) {
            newErrors.emailAddress = errorMessage;
          } else {
            // For any other errors, use a general error message
            newErrors.general = errorMessage || "An error occurred. Please check your inputs.";
          }
        });

        // Update the state with the collected errors
        setErrors(newErrors);
      }
    };


    if (user) {
      const updatedFormData = {
        ...formData,
        id: user.id, // Assuming `user.id` contains the ID
      };
      dispatch(updateProfileAction(updatedFormData, handleResponse));
    } else {
      dispatch(saveProfileAction(formData, handleResponse));
    }
  };


  const formFieldStyle = () => ({
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "19.6px",
    textAlign: "left",
    marginBottom: "8px",
  });

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2),
      }}
    >
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          maxWidth: 1200,
          padding: theme.spacing(3),
          borderRadius: theme.shape.borderRadius,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            marginBottom: 4,
            textAlign: "center",
            color: theme.palette.primary.main,
          }}
        >
          {user ? "Edit User Profile" : "Add New User"}
        </Typography>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                prefix
              </Typography>
              <FormControl fullWidth>
                <Select
                  value={prefix}
                  onChange={(e) => setPrefix(e.target.value)}
                  id="prefix"
                  name="prefix"
                >
                  <MenuItem value="Mr.">Mr.</MenuItem>
                  <MenuItem value="Ms.">Ms.</MenuItem>
                  <MenuItem value="Mrs.">Mrs.</MenuItem>
                  <MenuItem value="Dr.">Dr.</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                First Name
              </Typography>
              <TextField
                required
                fullWidth
                value={firstName}
                onChange={(e) => {
                  if (
                    /^[A-Za-z\s]*$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    setFirstName(e.target.value);
                  }
                }}
                id="firstname"
                name="firstName"
                error={!!errors.firstName}
                helperText={errors.firstName}
                inputProps={{ maxLength: 30 }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Middle Name
              </Typography>
              <TextField
                fullWidth
                value={middleName}
                onChange={(e) => {
                  if (
                    /^[A-Za-z\s]*$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    setMiddleName(e.target.value);
                  }
                }}
                id="middlename"
                name="middleName"
                inputProps={{ maxLength: 30 }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Last Name
              </Typography>
              <TextField
                required
                fullWidth
                value={lastName}
                onChange={(e) => {
                  // Validate last name
                  if (
                    /^[A-Za-z\s]*$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    setLastName(e.target.value);
                  }
                }}
                id="lastname"
                name="lastName"
                error={!!errors.lastName}
                helperText={errors.lastName}
                inputProps={{ maxLength: 30 }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Suffix
              </Typography>
              <FormControl fullWidth>
                <Select
                  value={suffix}
                  onChange={(e) => setSuffix(e.target.value)}
                  id="suffix"
                  name="suffix"
                >
                  <MenuItem value="Jr.">Jr.</MenuItem>
                  <MenuItem value="Sr.">Sr.</MenuItem>
                  <MenuItem value="II">II</MenuItem>
                  <MenuItem value="III">III</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{ color: theme.palette.success.main, marginY: 2 }}
              >
                Account Status: Active
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                User Type
              </Typography>
              <FormControl fullWidth>
                <Select
                  value={userType}
                  onChange={(e) => setUserType(e.target.value)}
                  id="usertype"
                  name="userType"
                >
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="User">User</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Email Address
              </Typography>
              <TextField
                required
                fullWidth
                autoComplete="off"
                value={emailAddress}
                onChange={(e) => { const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; 
                  const email = e.target.value; setEmail(email);
                  if (!emailPattern.test(email)) 
                    { setErrors(prevErrors => ({ ...prevErrors, emailAddress: 'Please enter a valid email address.', }));
                 } 
                 else 
                  { setErrors(prevErrors => ({ ...prevErrors, emailAddress: '', }));
                 } 
                }}
                id="emailaddress"
                name="emailAddress"
                error={!!errors.emailAddress}
                helperText={errors.emailAddress}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Confirm Email
              </Typography>
              <TextField
                required
                fullWidth
                autoComplete="off"
                value={emailConfirm}
                onChange={(e) => { const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; 
                  const confirmEmail = e.target.value; 
                  setEmailConfirm(confirmEmail);  
                  if (!emailPattern.test(confirmEmail))
                     { setErrors(prevErrors => ({ ...prevErrors, emailConfirm: 'Please enter a valid email address.', })); 
                } else if (confirmEmail !== emailAddress) 
                  { setErrors(prevErrors => ({ ...prevErrors, emailConfirm: 'Email addresses do not match.', })); 
                } else { setErrors(prevErrors => ({ ...prevErrors, emailConfirm: '', })); 
              } 
            }}
                id="emailconfirm"
                name="emailConfirm"
                error={!!errors.emailConfirm}
                helperText={errors.emailConfirm}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                User Name
              </Typography>
              <TextField
                required
                autoComplete="off"
                fullWidth
                value={userName}
                id="username"
                error={!!errors.userName}
                helperText={errors.userName}
                onChange={(e) => {
                  // Validate last name
                  if (
                    /^[A-Za-z\s]*$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    setUserName(e.target.value);
                  }
                }}
                inputProps={{ maxLength: 30 }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Password
              </Typography>
              <TextField
                fullWidth
                autoComplete="off"
                type="password"
                value={password}
                onChange={(e) => { setPassword(e.target.value);  
                  if (confirmPassword !== e.target.value) 
                    { setErrors(prevErrors => ({ ...prevErrors, confirmPassword: 'Passwords do not match.', })); 
                } 
                else 
                { setErrors(prevErrors => ({ ...prevErrors, confirmPassword: '', })); 
              } 
            }}
                id="password"
                name="password"
                // error={!!errors.password}
                // helperText={errors.password}
                inputProps={{ maxLength: 30 }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Confirm Password
              </Typography>
              <TextField
                fullWidth
                autoComplete="off"
                type="password"
                value={confirmPassword}
                onChange={(e) => { setConfirmPassword(e.target.value); 
                   if (password !== e.target.value) 
                    { setErrors(prevErrors => ({ ...prevErrors, confirmPassword: 'Passwords do not match.', }));
                   }
                    else 
                   { setErrors(prevErrors => ({ ...prevErrors, confirmPassword: '', })); 
                  } 
                }}
                id="confirmpassword"
                name="confirmPassword"
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
                inputProps={{ maxLength: 30 }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Phone
              </Typography>
              <TextField
                fullWidth
                autoComplete="off"
                value={phone}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d{0,10}$/.test(value)) {
                    setPhone(value);
                  }
                }}
                id="phone"
                name="phone"
                inputProps={{
                  maxLength: 10,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Home Phone
              </Typography>
              <TextField
                fullWidth
                value={homePhone}
                autoComplete="off"
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d{0,10}$/.test(value)) {
                    setHomePhone(value);
                  }
                }}
                id="homephone"
                name="homePhone"
                inputProps={{
                  maxLength: 10,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "19.6px",
                textAlign: "left",
                marginBottom: "8px",
              }}
            >
              Fax Number
            </Typography>
            <TextField
              fullWidth
              autoComplete="off"
              type="tel"
              value={faxNumber}
              onChange={(e) => {
                const value = e.target.value;
                // Allow only numbers and limit length to 10 digits
                if (/^\d{0,10}$/.test(value)) {
                  setFaxNumber(value);
                }
              }}
              id="faxnumber"
              name="faxNumber"
              inputProps={{
                maxLength: 10, // Optional: Limits the input to 10 digits
              }}
            />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Work Phone
              </Typography>
              <TextField
                fullWidth
                value={workPhone}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d{0,10}$/.test(value)) {
                    setWorkPhone(value);
                  }
                }}
                id="workphone"
                name="workPhone"
                inputProps={{
                  maxLength: 10,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Location
              </Typography>
              <Select
                fullWidth
                value={defaultLocation}
                onChange={(e) => setDefaultLocation(e.target.value)}
                id="defaultlocation"
                name="defaultLocation"
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select a location
                </MenuItem>
                {locationList.map((location, index) => (
                  <MenuItem key={index} value={location.value}>
                    {location.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
  <Typography
    variant="body1"
    sx={{
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "19.6px",
      textAlign: "left",
      marginBottom: "8px",
    }}
  >
    Role
  </Typography>
  <Select
    fullWidth
    value={role}
    onChange={(e) => setRole(e.target.value)}
    id="role"
    name="role"
    displayEmpty
  >
    <MenuItem value="" disabled>
      Select a Role
    </MenuItem>
    {getAllRoles
      .filter((role) => !role.isSuperUser) // Only include roles where isSuperUser is false
      .map((role) => (
        <MenuItem key={role.id} value={role.internalName}>
          {role.name}
        </MenuItem>
      ))}
  </Select>
</Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "19.6px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Status
              </Typography>
              <FormControl fullWidth>
                <Select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  id="status"
                  name="status"
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Not Active">Not Active</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="Company Group"
                fullWidth
                value={companyGroup}
                onChange={(e) => setCompanyGroup(e.target.value)}
                id="companyGroup"
                name="companyGroup"
              />
            </Grid> */}

            <Grid item xs={12}>
              <Typography id="permissions" variant="h6" sx={{ marginY: 2 }}>
                Permissions
              </Typography>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permissions.personalAuto}
                      id="personalauto"
                      onChange={handlePermissionChange}
                      name="personalAuto"
                    />
                  }
                  label="Personal Auto"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permissions.idCard}
                      id="idcard"
                      onChange={handlePermissionChange}
                      name="idCard"
                    />
                  }
                  label="ID Card"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permissions.binders}
                      id="binders"
                      onChange={handlePermissionChange}
                      name="binders"
                    />
                  }
                  label="Binders"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permissions.homeowner}
                      id="homeowner"
                      onChange={handlePermissionChange}
                      name="homeowner"
                    />
                  }
                  label="Homeowner"
                />
              </FormGroup>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              marginTop: 4,
            }}
          >
            <Button id="cancelbutton" onClick={toggleForm} variant="outlined" color="secondary">
              Cancel
            </Button>
            {/* <Button type="submit" variant="contained" color="primary">
                Save
              </Button> */}
            <Button id="submitbutton" type="submit" variant="contained" color="primary">
              {user ? "Update" : "Save"}
            </Button>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
}

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { backgrounds, borderRadius } from 'polished';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column',
    justifyContent: 'center',
    boxShadow: 24,    
    p: 4,
    borderRadius: '4px',
  };
const primaryButtonStyle = {
    height: '42px',
    minWidth: '90px',
    color: '#FFFFFF',
    lineHeight: 'normal',
    '&:hover': {
        backgroundColor: '#0C87D7',
        color: '#FFFFFF',
    }
}


const DeleteModal = ({ openDeleteModal, handleDeleteClose}) => {
    return(
        <>
            <Modal
                open={openDeleteModal}
                onClose={handleDeleteClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modalmodaltitle" variant="h6" component="h2">
                        Delete
                    </Typography>
                    <Typography id="modalmodaldescription" sx={{ margin: '5px 0 15px 0' }}>
                        Are you sure, you want to delete this.
                    </Typography>
                    <Stack spacing={2} direction="row">
                        <Button onClick={handleDeleteClose} sx={{ textTransform: 'capitalize'}} variant="outlined">Cancel</Button>
                        <Button color="error" sx={{...primaryButtonStyle, textTransform: 'capitalize'}} 
                            variant="contained">Yes
                        </Button>
                    </Stack>
                </Box>                
            </Modal>
        </>
    )
}

export default DeleteModal;
import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Typography, Button, Grid, Box, TablePagination, IconButton } from '@mui/material';
import { getAllLocationAction, getDeleteLocationAction } from '../../redux/actions/locationActions';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import AddLocationForm from './AddLocationForm';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from "@mui/material/styles";

// Styled table cell with hex color
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: '#1565c0', // Using the specific hex color
  color: theme.palette.common.white,
  fontFamily: '"Poppins", sans-serif',
}));
 
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
  '& td, & th': {
    padding: theme.spacing(1.5),
    fontFamily: '"Poppins", sans-serif',
  },
}));

function LocationList({ locations, toggleForm }) {  
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedUser, setSelectedUser] = useState(null); 
  const [isEditing, setIsEditing] = useState(false); 
  const locationDetails = useSelector((state) => state.location.allLocation);
  const locationData = Array.isArray(locationDetails) ? locationDetails : [];
  

  useEffect(() => {
    dispatch(getAllLocationAction(page, rowsPerPage));
  }, [dispatch, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditClick = (user) => {
    setSelectedUser(user); // Set the selected user for editing
    setIsEditing(true);    // Show the edit form
  };

  const handleFormClose = () => {
    setSelectedUser(null);  // Reset selected user after form close
    setIsEditing(false);    // Switch back to user list
  };

  const onDeleteGroup = (id) => {
    dispatch(getDeleteLocationAction(id)).then(() => {
      dispatch(getAllLocationAction()); // Refetch after deletion completes
    });
  };

  const paginatedRows = locationData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  
  return (
    <Paper sx={{ padding: 3 }}>
      {isEditing ? (
        <AddLocationForm
          location={selectedUser}
          toggleForm={handleFormClose}
          id="editLocationForm"
          name="editLocationForm"
        />
      ) : (
        <>
          <Grid container justifyContent="space-between" alignItems="center" sx={{ marginBottom: 3 }}>
            <Grid item>
              <Typography id="alllocationlist" variant="h5" sx={{ fontFamily: '"Poppins", sans-serif' }}>              
                All Location List 
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                sx={{ backgroundColor: '#1565c0', fontFamily: '"Poppins", sans-serif' }}
                onClick={toggleForm}
                id="addnewlocationbutton"
                name="addNewLocationButton"
              >
                Add New Location
              </Button>
            </Grid>
          </Grid>

          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell id="descriptionheader" name="descriptionHeader">Description</StyledTableCell>
                <StyledTableCell id="agencynameheader" name="agencyNameHeader">Agency Name</StyledTableCell>
                <StyledTableCell id="addressheader" name="addressHeader">Address</StyledTableCell>
                <StyledTableCell id="cityheader" name="cityHeader">City</StyledTableCell>
                <StyledTableCell id="stateheader" name="stateHeader">State</StyledTableCell>
                <StyledTableCell id="phoneheader" name="phoneHeader">Phone</StyledTableCell>
                <StyledTableCell id="activeheader" name="activeHeader">Active</StyledTableCell>
                <StyledTableCell id="actionheader" name="actionHeader">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedRows.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
                      <Typography variant="subtitle1" color="textSecondary">
                        No locations available.
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                paginatedRows.map((location, index) => (
                  <StyledTableRow key={index}>
                    <TableCell id={`locationDescription${index}`} name={`locationDescription${index}`}>
                      {location.locationDescription}
                    </TableCell>
                    <TableCell id={`locationAgencyName${index}`} name={`locationAgencyName${index}`}>
                      {location.agencyName}
                    </TableCell>
                    <TableCell id={`locationAddress${index}`} name={`locationAddress${index}`}>
                      {`${location.address1} ${location.address2}`}
                    </TableCell>
                    <TableCell id={`locationCity${index}`} name={`locationCity${index}`}>
                      {location.city}
                    </TableCell>
                    <TableCell id={`locationState${index}`} name={`locationState${index}`}>
                      {location.state}
                    </TableCell>
                    <TableCell id={`locationPhone${index}`} name={`locationPhone${index}`}>
                      {location.phone}
                    </TableCell>
                    <TableCell id={`locationActive${index}`} name={`locationActive${index}`}>
                      {location.isActive ? 'Yes' : 'No'}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={() => handleEditClick(location)}
                        id={`editLocationButton${index}`}
                        name={`editLocationButton${index}`}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => onDeleteGroup(location.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={locationData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
            id="tablepagination"
            name="tablePagination"
          />
        </>
      )}
    </Paper>
  );
}

export default LocationList;



import { CLOSE_CREATE_CARRIER_QUESTIONS_INFO_MODAL, OPEN_CREATE_CARRIER_QUESTIONS_INFO_MODAL, OPEN_UPDATE_CARRIER_QUESTIONS_INFO_MODAL } from "../actions/actionTypes";

const initialState = {
    allCarrierQuestionsInfo: [],
    error: null,
    isCreateCarrierQuestionsInfoModalOpen: false,
    selectedCarrierQuestionsInfoId: null,
  };
  
  const carrierQuestionsInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ALL_CARRIER_QUESTIONS_INFOS_SUCCESS':
            return { ...state, allCarrierQuestionsInfo: action.payload, error: null };
        case 'CREATE_CARRIER_QUESTIONS_INFO_SUCCESS':
        case 'UPDATE_CARRIER_QUESTIONS_INFO_SUCCESS':
            return {
                ...state,
                allCarrierQuestionsInfo: [ ...state.allCarrierQuestionsInfo, ...action.payload ],
                error: null,
            };
        case OPEN_CREATE_CARRIER_QUESTIONS_INFO_MODAL:
            return {...state, isCreateCarrierQuestionsInfoModalOpen: true}
        case OPEN_UPDATE_CARRIER_QUESTIONS_INFO_MODAL:
            return {...state, isCreateCarrierQuestionsInfoModalOpen: true, selectedCarrierQuestionsInfoId: action.payload}
        case CLOSE_CREATE_CARRIER_QUESTIONS_INFO_MODAL:
            return {...state, isCreateCarrierQuestionsInfoModalOpen: false, selectedCarrierQuestionsInfoId: null}
        case 'ALL_CARRIER_QUESTIONS_INFOS_FAILURE':
        case 'CREATE_CARRIER_QUESTIONS_INFO_FAILURE':
        case 'UPDATE_CARRIER_QUESTIONS_INFO_FAILURE':
            return { ...state, error: action.payload };
        default:
            return state;
    }
  };
  
  export default carrierQuestionsInfoReducer;
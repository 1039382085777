import apiService from "./apiServices";

export const saveFormLayout = async (formData, token) => {
    try {

      // console.log(formData, 'formData, for layout');
      const response = await apiService('/customizableLayout/save-layout', 'POST', formData, null, token);
      return response;
      } catch (error) {
        throw (error?.message || 'Saving form-layout is failed. Please try again.');
      }
}

export const updateFormLayout = async (formData, token) => {
  try {

    const response = await apiService('/customizableLayout/update-layout', 'PUT', formData, null, token);
    return response;
    } catch (error) {
      throw (error?.message || 'Updating formLayout is failed. Please try again.');
    }
}

export const getFormLayout = async (customerId, type, token) => {
    try {
        const response = await apiService(`/customizableLayout/customerId/${customerId}/type/${type}`, 'GET', null, null, token);
        return response;
      } catch (error) {
        throw (error?.message || 'service is failed. Please try again.');
      }
  }
import React,{ useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, StepConnector } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import EditLeads from "../../../../components/SidebarLayout/EditLead/EditeLeads";
import ContactInformation from '../../../../components/SidebarLayout/EditLead/ContactInformation';
import AdditionalInformation from '../../../../components/SidebarLayout/EditLead/AdditionalInformation';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useParams } from 'react-router-dom';


import {
  getLeadById as getLeadByIdAction,
  updateLead as updateLeadAction,
  updateLeadAddress as updateLeadAddressAction,
} from '../../../../redux/actions/newleadActions';

const steps = ['General', 'Contact Information', 'Additional Info'];
const components = [EditLeads, ContactInformation, AdditionalInformation];

const EditLead = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const dispatch = useDispatch();

  const {leadId}  = useParams();
  const getLeadId = parseInt(leadId);

 useEffect(()=>{
    dispatch(getLeadByIdAction(getLeadId));
 },[getLeadId]);

 const leadById = useSelector((state) => state?.newleads?.getLeadById);
//  const [leadData,setLeadData] = useState(leadById);
 
//  useEffect(()=>{
//   setLeadData(leadById);
//  },[]);

 console.log(leadById.firstName,'aaa');

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {    
    const newActiveStep =
      isLastStep() && !allStepsCompleted()        ? 
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const ActiveComponent = components[activeStep];

  const primaryButton = {
    background: '#0173BC', 
    color: '#FFFFFF', 
    minWidth: '84px',
    '&:hover' : {
      background: '#0173BC', 
      color: '#FFFFFF', 
    }
  }

  return (
    <Box sx={{margin: '70px 0 0 0', padding: '0 2rem 0 5rem'}}>
      <Stepper nonLinear activeStep={activeStep} connector={<CustomStepConnector />}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
          <StepButton color="#0173BC" onClick={handleStep(index)}>
            <Box sx={{ fontWeight: 'bold'}}>
              {label} 
              {index < 2 && (
                <ArrowForwardIosIcon style={{ fontSize: '16px', position: 'relative', top: '4px', left: '5px', color: '#242929' }} />
              )}
            </Box>              
          </StepButton>
        </Step>
        ))}
      </Stepper>
      
      <div>
          <React.Fragment>            
            <Box sx={{
                margin: '20px 0 0 0',
            }}>
                <ActiveComponent data={leadById} />
            </Box>
            
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'end' }}>              
                  <Button
                    color="inherit"
                    id="backbutton"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1, color: '#242929', textTransform: 'capitalize', minWidth: '84px', border: '#242929 solid 1px'}}
                  >
                    Back
                </Button>
                <Button id="nextbutton" onClick={handleNext} sx={primaryButton}>
                    Next
                </Button>
            </Box>
          </React.Fragment>
      </div>
    </Box>
  );
}

const CustomStepConnector = () => {
  return (
    <StepConnector
      style={{
        marginLeft: -4,
        marginRight: -4,
        position: 'relative',
        borderWidth: '5px',
        borderColor: 'green', 
        display:'none'
      }}
    />
  );
};

export default EditLead;
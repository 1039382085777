import * as rolesServices from '../../services/rolesServices';
import {
    CLOSE_CREATE_ROLE_MODAL,
    OPEN_CREATE_ROLE_MODAL,
    OPEN_UPDATE_ROLE_MODAL,
    START_LOADING,
    STOP_LOADING
} from "./actionTypes"
import { openSessionTimeoutModal } from './sessionTimeOutActions';

export const getAllRoles = (customerId) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'ALL_ROLES' });
      const token = getState().auth.token || sessionStorage.getItem("token");
            const response = await rolesServices.getRoles(customerId, token);
      dispatch({ type: STOP_LOADING, service: 'ALL_ROLES' });
      dispatch({ type: 'ALL_ROLES_SUCCESS', payload: response });
    } catch (error) {
      if(error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: STOP_LOADING, service: 'ALL_ROLES' });
        dispatch({ type: 'ALL_ROLES_FAILURE', payload: error || 'All roles service failed. Please try again.' });
      }
    }
  };

export const createRoleAction = (formData, callbackfn) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'CREATE_ROLE' });
    const token = getState().auth.token || sessionStorage.getItem("token");;
    const response = await rolesServices.createRole(formData, token);
    dispatch({ type: STOP_LOADING, service: 'CREATE_ROLE' });
    if(response) {
      callbackfn();
    }
    dispatch({ type: 'CREATE_ROLE_SUCCESS', payload: response });
  } catch (error) {
    if((typeof(error) !== "object" && error?.includes("403")) || (error && error.message?.includes("403"))) {
        dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'CREATE_ROLE' });
      dispatch({ type: 'CREATE_ROLE_FAILURE', payload: typeof(error)==="object" ? error.message : error || 'Create Role service failed. Please try again.' });
    }
  }
};

export const updateRoleAction = (formData, callbackfn) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'UPDATE_ROLE' });
    const token = getState().auth.token || sessionStorage.getItem("token");;
    const response = await rolesServices.updateRole(formData, token);
    dispatch({ type: STOP_LOADING, service: 'UPDATE_ROLE' });
    if(response) {
      callbackfn();
    }
    dispatch({ type: 'UPDATE_ROLE_SUCCESS', payload: response });
  } catch (error) {
    if((typeof(error) !== "object" && error?.includes("403")) || (error && error.message?.includes("403"))) {
        dispatch(openSessionTimeoutModal());
      } else {
      dispatch({ type: STOP_LOADING, service: 'UPDATE_ROLE' });
      dispatch({ type: 'UPDATE_ROLE_FAILURE', payload: typeof(error)==="object" ? error.message : error || 'Update role service failed. Please try again.' });
    }
  }
};

export const openCreateRoleModal = () => ({
  type: OPEN_CREATE_ROLE_MODAL,
});

export const closeCreateRoleModal = () => ({
  type: CLOSE_CREATE_ROLE_MODAL,
});

export const openUpdateRoleModal = (id) => async (dispatch) => {
  dispatch({ type:OPEN_UPDATE_ROLE_MODAL, payload: id });
};

export const getRolesList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'START_LOADING' });
    const token = getState().auth.token || sessionStorage.getItem("token");
          const response = await rolesServices.getAllRoles(token);
    dispatch({ type: STOP_LOADING, service: 'ALL_ROLES' });
    dispatch({ type: 'GET_ALL_ROLES_LIST', payload: response });
  } catch (error) {
    if(error?.includes("403")) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'ALL_ROLES' });
      dispatch({ type: 'ALL_ROLES_FAILURE', payload: error || 'All roles service failed. Please try again.' });
    }
  }
};
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  TextField,
  Checkbox,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography
} from '@mui/material';
import { getCarrierAction, saveCarrierGroupAction, updateCarrierAction } from '../../redux/actions/agencyActions';
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: '#1565c0', // Set header background color to #1565c0
  color: theme.palette.common.white,
  fontFamily: '"Poppins", sans-serif',
}));
 
 
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover, // Odd rows have a subtle background color
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected, // Row background changes on hover
  },
  '& td, & th': {
    padding: theme.spacing(1.5),
    fontFamily: '"Poppins", sans-serif', // Poppins font applied to all cells
  },
}));

const CreateCarrier = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation(); // Get the state passed during navigation

  const [companyGroupName, setGroupName] = useState('');
  const [agencyName, setAgencyName] = useState([]);
  const [carrierId, setCarrierId] = useState([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState([]); // This will hold the selected carrier agencyIds
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isEdit, setIsEdit] = useState(false);
  const [groupId, setGroupId] = useState(null); // This will hold the group ID if editing
  const [error, setError] = useState('');

  const carrierDetails = useSelector((state) => state.agency.allCarrier); // Get carrier details from Redux
  const carriers = carrierDetails || []; // Make sure carriers exist
  const caarr = location.state?.group?.carriers;
  
  // Populate form fields if editing
  useEffect(() => {
    if (location.state) {
      setIsEdit(location.state.isEdit); // Set edit mode
      setGroupId(location.state?.group?.groupId); // Set the group ID
      setGroupName(location.state?.group?.companyGroupName); // Pre-fill the group name field
  
      if (caarr && caarr.length > 0) {
        // Extract and set agency IDs that should be selected
        const selectedCarrierIds = caarr.map((carrier) => carrier.agencyId);
        const getAgencyName = caarr.map((carrier) => carrier.agencyName);
        
        setAgencyName(getAgencyName);
        setCarrierId(selectedCarrierIds); // Set the carrierId array
        setSelectedCheckbox(selectedCarrierIds); // Set pre-selected checkboxes
      }
    }
  }, [location.state, caarr]);
  

  useEffect(() => {
    dispatch(getCarrierAction()); // Fetch all carriers when the component mounts
  }, [dispatch]);

  
  const handleCheckboxChange = (id) => {
    setSelectedCheckbox((prev) =>
      prev.includes(id) ? prev.filter((c) => c !== id) : [...prev, id]
    );
  };
  
  // Handle the Create or Update action
  const handleCancel = () => {
    navigate('/CarrierGroup');
  }

  const handleCreateOrUpdateGroupClick = () => {
    if (!companyGroupName) {
      setError('Carrier Group Name is required.');
      return;
    }
    if (selectedCheckbox.length === 0) {
      setError('Please select at least one carrier.');
      return;
    }
      const carrierIds = selectedCheckbox; // Carrier IDs that have been selected

      const groupData = {
        groupId: isEdit && groupId ? groupId : undefined, // Only send groupId if editing
        companyGroupName,
        carrierIds, // Carrier IDs to associate with the group
        status: 'Active',
      };

      if (isEdit) {
        dispatch(updateCarrierAction(groupData)).then(() => {
          dispatch(getCarrierAction()); // Refresh the carrier list
          navigate('/CarrierGroup');
        });
      } else {
        dispatch(saveCarrierGroupAction(groupData)).then(() => {
          dispatch(getCarrierAction()); // Refresh the carrier list
          navigate('/CarrierGroup');
        });
      }

       // Navigate back to the CarrierGroup component

      // Reset the form
      setSelectedCheckbox([]);
      setGroupName('');
      setIsEdit(false); // Reset edit flag
      setGroupId(null);
      setError(''); 
    
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRows = carriers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage); // Paginate carrier rows

  return (
    <Box sx={{ padding: 3 }} id="carriergroupscreen">
  <Typography
    id="Create Carrier Group"
    variant="h5"
    sx={{ 
      fontFamily: '"Poppins", sans-serif', 
      marginBottom: 2 // Add margin here to create spacing
    }}
  >
    Create Carrier Group
  </Typography>
              
      {/* Input for Carrier Group Name */}
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        
        <TextField
          label="Carrier Group Name"
          variant="outlined"
          size="small"
          value={companyGroupName} // Pre-filled value if editing
          onChange={(e) => {
            setGroupName(e.target.value);
            setError(''); // Clear error when user starts typing
          }}
          error={!!error && error.includes('Group Name')}
          helperText={error && error.includes('Group Name') ? error : ''}
           
          sx={{ marginRight: '10px',
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "19.6px",
            textAlign: "left",
            marginBottom: "8px",
           }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateOrUpdateGroupClick}
          
          // Handle the create or update action
        >
          {isEdit ? 'Update Group' : 'Create Group'} {/* Button label changes based on edit mode */}
        </Button>

        <Button
          variant="contained"
          color="secondary"
          sx={{ margin: '0 15px'}}
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </Box>

      {error && error.includes('carrier') && (
        <Typography color="error" variant="body2" sx={{ marginBottom: '10px' }}>
          {error}
        </Typography>
      )}

      {/* Carrier Selection Table */}
      <TableContainer component={Paper}>
        <Table id="carriergrouptable">
          <TableHead>
            <TableRow>
              <StyledTableCell>Active</StyledTableCell>
              <StyledTableCell>Company Name</StyledTableCell>
              <StyledTableCell>Program Name</StyledTableCell>
              <StyledTableCell>Producer Code</StyledTableCell>
              <StyledTableCell>Last Activity</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.map((group, index) => (
              <StyledTableRow key={index} id={`carriergrouprow${index}`}>
                <TableCell>
                <Checkbox
                  checked={selectedCheckbox.includes(group.agencyId)} // Check if the current agencyId is in the selectedCheckbox array
                  onChange={() => handleCheckboxChange(group.agencyId)} // Handle checkbox change
                  id={`active-${index}`}
                />
                </TableCell>
                <TableCell id={`companyname${index}`}>{group.insuranceCarrier}</TableCell>
                <TableCell id={`programname${index}`}>{group.programName}</TableCell>
                <TableCell id={`producercode${index}`}>{group.producerCode}</TableCell>
                <TableCell id={`lastactivity${index}`}>{group.updatedTimeStamp}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination for Carriers Table */}
      <TablePagination
        component="div"
        count={carriers.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={carriers.length === 0 ? [] : [5, 10, 15]}
        id="tablepagination"
        name="tablePagination"
      />
    </Box>
  );
};

export default CreateCarrier;

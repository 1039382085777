import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const StyledOutlinedButton = styled(Button)(({ theme }) => ({
    borderColor: '#0173BC',
    color: '#0173BC',
    '&:hover': {
      borderColor: '#0173BC',
      backgroundColor: 'rgba(1, 115, 188, 0.04)',
    },
  }));

const AddMenu = ({label}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    
    return (
      <div>
        <StyledOutlinedButton
          endIcon={<ArrowDropDownIcon />}
          id="basicbutton"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          {label}
        </StyledOutlinedButton>
        <Menu
          id="basicmenu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleClose} id="formsmenuitem">Forms</MenuItem>
<MenuItem onClick={handleClose} id="notemenuitem">Note</MenuItem>
<MenuItem onClick={handleClose} id="endorsementmenuitem">Endorsement</MenuItem>
<MenuItem onClick={handleClose} id="reinstatemenuitem">Reinstate</MenuItem>
        </Menu>
      </div>
    );
}
export default AddMenu;
import { SAVE_TASK_SUCCESS,SAVE_TASK_FAILURE,STOP_LOADING,START_LOADING,UPDATE_TASK_SUCCESS,
  UPDATE_TASK_FAILURE ,GET_TASK_SUCCESS,GET_TASK_FAILURE,DELETE_TASK_FAILURE,DELETE_TASK_SUCCESS} from "./actionTypes";
import { saveTask,updateTask,getAllTask,deleteTaskById, searchTask } from "../../services/taskServices";
import { openSessionTimeoutModal } from './sessionTimeOutActions';


export const saveTaskAction = (formData, callbackfn) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'SAVE_TASK' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await saveTask(formData, token);
      // console.log(response);
      dispatch({ type: STOP_LOADING, service: 'SAVE_TASK' });
      if (response) {
        callbackfn(response);
      }
      dispatch({ type: SAVE_TASK_SUCCESS, payload: response }); // Dispatch the success action with response data
    } catch (error) {
      if (error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: STOP_LOADING, service: 'SAVE_TASK' });
        dispatch({ type: SAVE_TASK_FAILURE, payload: error || 'Save task service failed. Please try again.' });
      }
    }
  };

  export const searchTaskAction = (formData, callbackfn) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'SEARCH_TASK' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await searchTask(formData, token);
      // console.log("alert",response);
      dispatch({ type: STOP_LOADING, service: 'SEARCH_TASK' });
      if (response) {
        callbackfn(response);
      }
      dispatch({ type: 'SEARCH_TASK_SUCCESS', payload: response }); // Dispatch the success action with response data
    } catch (error) {
      if (error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: STOP_LOADING, service: 'SEARCH_TASK' });
        dispatch({ type: 'SEARCH_TASK_FAILURE', payload: error || 'Search Quote service failed. Please try again.' });
      }
    }
  };

  export const updateTaskAction = (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'UPDATE_TASK' });
      const token = getState().auth.token || sessionStorage.getItem("token");;
      const response = await updateTask(formData, token);
      dispatch({ type: STOP_LOADING, service: 'UPDATE_TASK' });
      dispatch({ type: UPDATE_TASK_SUCCESS, payload: response });
    } catch (error) {
      // console.log(error, 'error in action')
      if(error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: STOP_LOADING, service: 'UPDATE_TASK' });
        dispatch({ type: UPDATE_TASK_FAILURE, payload: error || 'Update task service failed. Please try again.' });
      }
    }
  };

  export const getAllTaskAction= () => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'ALL_TASK' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await getAllTask(token);
      dispatch({ type: STOP_LOADING, service: 'ALL_TASK' });
      dispatch({ type: GET_TASK_SUCCESS, payload: response });
    } catch (error) {
      if(error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: STOP_LOADING, service: 'ALL_TASK' });
        dispatch({ type: GET_TASK_FAILURE, payload: error || 'All task service failed. Please try again.' });
      }
    }
  };


  export const deleteTaskActionById = (Id) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'ALL_TASK' });
      const token = getState().auth.token || sessionStorage.getItem("token");

      const response= await deleteTaskById(Id,token);
      dispatch({ type: STOP_LOADING, service: 'ALL_TASK' });
      // console.log("Token:", token);


      // dispatch({ type: 'ALL_DRIVER_INFOS_SUCCESS', payload: response });
      dispatch({ type: DELETE_TASK_SUCCESS, payload: Id });
      return response;
    } catch (error) {
      dispatch({ type: STOP_LOADING, service: 'ALL_TASK' });
      dispatch({ type: DELETE_TASK_FAILURE, payload: error.message || 'All task service failed. Please try again.' });
    }
  };


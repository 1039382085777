import apiService from "./apiServices";

  // export const getQuotelisting = async (token) => {
  //   try {
  //       const response = await apiService(`/quoteslist/all`, 'GET', null, null, token);
  //       return response;
  //     } catch (error) {
  //       throw (error?.message || 'service is failed. Please try again.');
  //     }
  // }

  export const getQuotelisting = async (token,page, size) => {
    try {
        const response = await apiService(`/quoteslist/all?page=${page}&size=${size}`, 'GET', null, null, token);
        return response;
      } catch (error) {
        throw (error?.message || 'service is failed. Please try again.');
      }
  }

  export const searchQuote = async(formData, token) => {
    try {
      // console.log("formdata",formData);
        const response = await apiService(`/search-quotes/search`, 'POST', formData, null, token);
        // console.log("searchquote",response);
        return response;
      } catch (error) {
        throw (error?.message || 'search quote service is failed. Please try again.');
      }
  }


import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  Typography,
  Box,
  Select,
  MenuItem,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TablePagination,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import {
  getProfileAction,
  searchActiveProfileAction,
} from "../../../redux/actions/agencyActions";
import UserProfileForm from "./UserProfileForm";
import { getAllLocationAction } from "../../../redux/actions/locationActions";
 
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: "#1565c0",  // Primary color for header cells
  color: theme.palette.common.white,
  fontFamily: '"Poppins", sans-serif',
}));
 
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:hover": {
    backgroundColor: theme.palette.action.selected,
  },
  "& td, & th": {
    padding: theme.spacing(1.5),
    fontFamily: '"Poppins", sans-serif',
  },
}));
 
export default function UserList({ toggleForm }) {
  const [status, setStatus] = useState("All");
  const [defaultLocation, setDefaultLocation] = useState("All");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
 
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.agency.allProfile.content);
  const userPagination = useSelector((state) => state.agency.allProfile);
  const searchData = useSelector((state) => state.agency.profilesSearch.content);
  const searchPagination = useSelector((state) => state.agency.profilesSearch);
  const locationDetails = useSelector((state) => state.location.allLocation);
 
  const userData = isSearching ? searchData || [] : userDetails || [];
  const paginationData = isSearching ? searchPagination || {} : userPagination || {};
 
  let locationList = [];
  if (Array.isArray(locationDetails)) {
    locationList = locationDetails.map((location) => ({
      value: location.locationDescription,
      label: location.locationDescription,
    }));
  }
 
  useEffect(() => {
    dispatch(getAllLocationAction());
    if (!isSearching) {
      dispatch(getProfileAction(page, rowsPerPage));
    }
  }, [dispatch, page, rowsPerPage, isSearching]);
 
  useEffect(() => {
    if (status !== "All" || defaultLocation !== "All") {
      setIsSearching(true);
      const payload = { status, location: defaultLocation };
      dispatch(searchActiveProfileAction(payload, page, rowsPerPage));
    } else {
      setIsSearching(false);
      dispatch(getProfileAction(page, rowsPerPage));
    }
  }, [dispatch, status, defaultLocation, page, rowsPerPage]);
 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
 
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
 
  const handleEditClick = (user) => {
    setSelectedUser(user);
    setIsEditing(true);
  };
 
  const handleFormClose = () => {
    setSelectedUser(null);
    setIsEditing(false);
  };
 
  return (
    <>
      {isEditing ? (
        <UserProfileForm user={selectedUser} toggleForm={handleFormClose} />
      ) : (
        <>
          <Box sx={{ marginTop: '50px', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography id="allusers" variant="h5" sx={{ p: 2, fontFamily: '"Poppins", sans-serif' }}>
              All Users ({userData.length})
            </Typography>
            <div style={{ display: "flex", justifyContent: "flex-end", padding: "0 16px", alignItems: 'center' }}>
              <Box sx={{ display: "flex", flexDirection: "column", mr: 2 }}>
                <Typography sx={{ p: 0, mr: 12, fontSize: '16px', fontFamily: '"Poppins", sans-serif' }}>Active Status</Typography>
                <Select
                  id="selectactivestatus"
                  name="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  sx={{ width: 200, fontFamily: '"Poppins", sans-serif' }}
                >
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Not Active">Not Active</MenuItem>
                </Select>
              </Box>
 
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontFamily: '"Poppins", sans-serif' }}>User in Default Location</Typography>
                <Select
                  id="selectdefaultlocation"
                  name="defaultLocation"
                  value={defaultLocation}
                  onChange={(e) => setDefaultLocation(e.target.value)}
                  sx={{ width: 200, fontFamily: '"Poppins", sans-serif' }}
                >
                  <MenuItem id="alllocation" value="All" sx={{ fontFamily: '"Poppins", sans-serif' }}>All Location</MenuItem>
                  {locationList.map((location, index) => (
                    <MenuItem sx={{ fontFamily: '"Poppins", sans-serif' }} key={index} value={location.value}>
                      {location.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Button
  variant="contained"
  color="primary"
  onClick={toggleForm}
  id="buttonadduser"
  name="addUser"
  sx={{
    marginLeft: '15px',
    padding: '8px 16px',
    backgroundColor: '#1565c0', // Primary button color
    fontFamily: '"Poppins", sans-serif',
    '&:hover': {
      backgroundColor: '#0d47a1', // Darker hover shade
    },
    borderRadius: '5px', // Added border radius for rounded corners
  }}
>
  Add New User
</Button>
 
            </div>
          </Box>
 
          <Paper sx={{ width: "100%", overflow: "hidden", marginTop: '15px', borderRadius: '10px' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>First Name</StyledTableCell>
                    <StyledTableCell>Last Name</StyledTableCell>
                    <StyledTableCell>User Name</StyledTableCell>
                    <StyledTableCell>Email Address</StyledTableCell>
                    <StyledTableCell>Role</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Location</StyledTableCell>
                    <StyledTableCell>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userData.map((user) => (
                    <StyledTableRow hover role="checkbox" tabIndex={-1} key={user.id}>
                      <TableCell>{user.firstName}</TableCell>
                      <TableCell>{user.lastName}</TableCell>
                      <TableCell>{user.userName}</TableCell>
                      <TableCell>{user.emailAddress}</TableCell>
                      <TableCell>{user.role}</TableCell>
                      <TableCell>{user.status}</TableCell>
                      <TableCell>{user.defaultLocation}</TableCell>
                      <TableCell>
                        <IconButton size="small" onClick={() => handleEditClick(user)} id={`editbutton${user.id}`}>
                          <EditIcon sx={{ fontSize: '18px' }} />
                        </IconButton>
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              sx={{ fontFamily: '"Poppins", sans-serif' }}
              rowsPerPageOptions={paginationData.totalPages === 0 ? [] : [5, 10, 15, 25, 50, 100]}
              component="div"
              count={paginationData.totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </>
      )}
    </>
  );
}
import apiService from "./apiServices";
import axios from 'axios';

export const createVehicleInfo = async (formData, token) => {
  // console.log("Raushaninfolast",formData)
  try {
    const headers = {
      'Access-Control-Request-Headers': '*'
    };
    const response = await apiService('/carinfo/', 'POST', formData, headers, token);
    // console.log(response, 'response from service');
    return response;
  } catch (error) {
    throw new Error(error.message || 'Creating organization is failed. Please try again.');
  }
}

export const updateVehicleInfo = async (formData, token) => {
  try {
    const headers = {
      'Access-Control-Request-Headers': '*'
    };
    const response = await apiService(`/carinfo/`, 'PUT', formData, headers, token);
    // console.log(response, 'response from service');
    return response;
  } catch (error) {
    throw new Error(error.message || 'Updating organization is failed. Please try again.');
  }
}

export const getVehicleInfoById = async (quoteId, token) => {
  try {
    const headers = {
      'Access-Control-Request-Headers': '*'
    };
    const response = await apiService(`/carinfo/${quoteId}`, 'GET', null, headers, token);
    return response;
  } catch (error) {
    throw new Error(error.message || 'service is failed. Please try again.');
  }
}

export const getAllVehicleInfo = async (generalPartyId,token) => {
  try {
    const headers = {
      'Access-Control-Request-Headers': '*'
    };
    // const token = ""
    const response = await apiService(`/carinfo/bygpid/${generalPartyId}`, 'GET', null, headers, token);
    return response;
  } catch (error) {
    throw new Error(error.message || 'service is failed. Please try again.');
  }
}


export const getVehicleInfoByVIN = async (vin, token) => {

  try {
    const options = {
      method: 'GET', url: `https://car-api2.p.rapidapi.com/api/vin/${vin}`,
      headers: {
              'Access-Control-Request-Headers': '*',
              'X-RapidAPI-Key': '9300bec98cmsh7208d810ea2e649p1a5a89jsn29930acb94ae',
              'X-RapidAPI-Host': 'car-api2.p.rapidapi.com'
      }
    };
    const response = await axios.request(options);
    return response;
  } catch (error) {
    throw new Error(error.message || 'service is failed. Please try again.');
  }
}

export const deleteVehicleInfoById = async (quoteId, token) => {
  try {
    // const headers = {
    //   'Access-Control-Request-Headers': '*'
    // };
    const response = await apiService(`/carinfo/${quoteId}`, 'DELETE', null, null, token);
    return response;
  } catch (error) {
    throw new Error(error.message || 'service is failed. Please try again.');
  }
}

//POLICY DETAILS
export const saveVehicle = async (formData, token) => {
  try {
    const response = await apiService('/vehicle/save', 'POST', formData, null, token);
    return response;
  } catch (error) {
    throw new Error(error.message || 'Saving vehicle is failed. Please try again.');
  }
}

export const getVehicle = async (token) => {
  try {
    // const headers = {
    //   'Access-Control-Request-Headers': '*'
    // };
    const response = await apiService('/vehicle/all-vehicle', 'GET', null, null, token);
    // console.log(response, 'response from service');
    return response;
  } catch (error) {
    throw new Error(error.message || 'fetching vehicle is failed. Please try again.');
  }
}

export const saveVehicleCoverage = async (formData, token) => {
  try {
    // const headers = {
    //   'Access-Control-Request-Headers': '*'
    // };
    // console.log(formData)
    const response = await apiService('/vehiclecoverage/save', 'POST', formData, null, token);
    // console.log(response, 'response from coverage service');
    return response;
  } catch (error) {
    throw new Error(error.message || 'Saving vehicle coverage is failed. Please try again.');
  }
}

export const getVehiclecoverage = async (token) => {
  try {
    // const headers = {
    //   'Access-Control-Request-Headers': '*'
    // };
    const response = await apiService('/vehiclecoverage/list', 'GET', null, null, token);
    // console.log(response, 'response from service');
    return response;
  } catch (error) {
    throw new Error(error.message || 'fetching vehicle coverage is failed. Please try again.');
  }
}

//YMS

export const getVehicleInfoByYmsYears = async ( token) => {
  try {
    const headers = {
      'Access-Control-Request-Headers': '*'
    };
    const response = await apiService(`/vin-lookup/years`, 'GET', null, headers, token);
    return response;
  } catch (error) {
    throw new Error(error.message || 'service is failed. Please try again.');
  }
}

export const getVehicleInfoByYmsMakes = async (year, token) => {
  try {
    const headers = {
      'Access-Control-Request-Headers': '*'
    };
    const response = await apiService(`/vin-lookup/makes?year=${year}`, 'GET', null, headers, token);
    return response;
  } catch (error) {
    throw new Error(error.message || 'service is failed. Please try again.');
  }
}

export const getVehicleInfoByYmsModels = async (year,makes, token) => {
  try {
    const headers = {
      'Access-Control-Request-Headers': '*'
    };
    const response = await apiService(`/vin-lookup/models?year=${year}&make=${makes}`, 'GET', null, headers, token);
    return response;
  } catch (error) {
    throw new Error(error.message || 'service is failed. Please try again.');
  }
}

export const getVehicleInfoByYmsStyles = async (year,makes,models ,token) => {
  try {
    const headers = {
      'Access-Control-Request-Headers': '*'
    };
    const response = await apiService(`/vin-lookup/styles?year=${year}&make=${makes}&model=${models}`, 'GET', null, headers, token);
    return response;
  } catch (error) {
    throw new Error(error.message || 'service is failed. Please try again.');
  }
}

export const getVehicleInfoByYmsVins = async (year,makes,models,styles ,token) => {
  try {
    const headers = {
      'Access-Control-Request-Headers': '*'
    };
    const response = await apiService(`/vin-lookup/vins?year=${year}&make=${makes}&model=${models}&style=${styles}`, 'GET', null, headers, token);
    return response;
  } catch (error) {
    throw new Error(error.message || 'service is failed. Please try again.');
  }
}
import { CLOSE_CREATE_LEAD_MODAL, OPEN_CREATE_LEAD_MODAL, OPEN_UPDATE_LEAD_MODAL } from "../actions/actionTypes";

const initialState = {
    allLeads: {},
    myLeads: {},
    error: null,
    isCreateLeadModalOpen: false,
    selectedLeadId: null,
  };
  
  const leadReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ALL_LEADS_SUCCESS':
            return { ...state, allLeads: action.payload, error: null };
        case 'MY_LEADS_SUCCESS':
            return { ...state, myLeads: action.payload, error: null };
        case 'CREATE_LEAD_SUCCESS':
        case 'UPDATE_LEAD_SUCCESS':
            return {
                ...state,
                myLeads: { ...state.myLeads, ...action.payload },
                allLeads: { ...state.allLeads, ...action.payload },
                error: null,
            };
        case OPEN_CREATE_LEAD_MODAL:
            return {...state, isCreateLeadModalOpen: true}
        case OPEN_UPDATE_LEAD_MODAL:
            return {...state, isCreateLeadModalOpen: true, selectedLeadId: action.payload}
        case CLOSE_CREATE_LEAD_MODAL:
            return {...state, isCreateLeadModalOpen: false, selectedLeadId: null}
        case 'ALL_LEADS_FAILURE':
        case 'MY_LEADS_FAILURE':
        case 'CREATE_LEAD_FAILURE':
        case 'UPDATE_LEAD_FAILURE':
            return { ...state, error: action.payload };
        default:
            return state;
    }
  };
  
  export default leadReducer;
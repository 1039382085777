import * as userServices from '../../services/userServices';
import {
  CLOSE_CREATE_USER_MODAL,
  OPEN_CREATE_USER_MODAL,
    OPEN_UPDATE_USER_MODAL,
    START_LOADING,
    STOP_LOADING
} from "./actionTypes"
import { openSessionTimeoutModal } from './sessionTimeOutActions';

export const getUser = () => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'USER_INFO' });
    const token = getState().auth.token || sessionStorage.getItem("token");;
    const response = await userServices.getUser(token);
    dispatch({ type: STOP_LOADING, service: 'USER_INFO' });
    dispatch({ type: 'USER_INFO_SUCCESS', payload: response });
  } catch (error) {
    if((typeof(error) !== "object" && error?.includes("403")) || (error && error.message?.includes("403"))) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'USER_INFO' });
      dispatch({ type: 'USER_INFO_FAILURE', payload: typeof(error)==="object" ? error.message : error || 'User Info service failed. Please try again.' });
    }

  }
};

export const getAllUser = () => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'ALL_USERS' });
    const token = getState().auth.token || sessionStorage.getItem("token");;
    const customerId = getState().user.user.customer.id;
    const response = await userServices.getAllUser(customerId, token);
    dispatch({ type: STOP_LOADING, service: 'ALL_USERS' });
    dispatch({ type: 'ALL_USERS_SUCCESS', payload: response });
  } catch (error) {
    if((typeof(error) !== "object" && error?.includes("403")) || (error && error.message?.includes("403"))) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'ALL_USERS' });
      dispatch({ type: 'ALL_USERS_FAILURE', payload: typeof(error)==="object" ? error.message : error || 'All Users service failed. Please try again.' });
    }
  }
};

export const createUser = (formData, callbackfn) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'CREATE_USER' });
    const token = getState().auth.token || sessionStorage.getItem("token");;
    const response = await userServices.createUser(formData, token);
    dispatch({ type: STOP_LOADING, service: 'CREATE_USER' });
    if(response) {
      callbackfn();
    }
    dispatch({ type: 'CREATE_USER_SUCCESS', payload: response });
  } catch (error) {
    if((typeof(error) !== "object" && error?.includes("403")) || (error && error.message?.includes("403"))) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'CREATE_USER' });
      dispatch({ type: 'CREATE_USER_FAILURE', payload: typeof(error)==="object" ? error.message : error || 'Create User service failed. Please try again.' });
    }
  }
};

export const updateUser = (formData, callbackfn) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'UPDATE_USER' });
    const token = getState().auth.token || sessionStorage.getItem("token");;
    const response = await userServices.updateUser(formData, token);
    dispatch({ type: STOP_LOADING, service: 'UPDATE_USER' });
    if(response) {
      callbackfn();
    }
    dispatch({ type: 'UPDATE_USER_SUCCESS', payload: response });
  } catch (error) {
    if((typeof(error) !== "object" && error?.includes("403")) || (error && error.message?.includes("403"))) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'UPDATE_USER' });
      dispatch({ type: 'UPDATE_USER_FAILURE', payload: typeof(error)==="object" ? error.message : error || 'Update User service failed. Please try again.' });
    }
  }
};
export const getRates = (data, quoteId) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'USER_INFO' });
    // const token = "" //getState().auth.token;
    const token = getState().auth.token || sessionStorage.getItem("token");
    const response = await userServices.postRatesPearl(data, quoteId,token);
    dispatch({ type: STOP_LOADING, service: 'USER_INFO' });
    dispatch({ type: 'GET_RATE_XML_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'USER_INFO' });
    dispatch({ type: 'GET_RATE_XML_FAILURE', payload: error.body.message || 'User Info service failed. Please try again.' });
  }
};

export const getRatesUnited = (data, quoteId) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'USER_INFO' });
    // const token = "" //getState().auth.token;
    const token = getState().auth.token || sessionStorage.getItem("token");
    const response = await userServices.postRatesUnited(data, quoteId,token);
    dispatch({ type: STOP_LOADING, service: 'USER_INFO' });
    dispatch({ type: 'GET_RATE_XML_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'USER_INFO' });
    dispatch({ type: 'GET_RATE_XML_FAILURE', payload: error.body.message || 'User Info service failed. Please try again.' });
  }
};

export const openCreateUserModal = () => ({
  type: OPEN_CREATE_USER_MODAL,
});

export const closeCreateUserModal = () => ({
  type: CLOSE_CREATE_USER_MODAL,
});

export const openUpdateUserModal = (id) => async (dispatch) => {
  dispatch({ type:OPEN_UPDATE_USER_MODAL, payload: id });
};
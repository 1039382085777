import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography, TextField, Button, Grid, styled, Table, TableBody, TableCell,
  tableCellClasses, TableContainer, TableHead, TableRow, Paper
} from '@mui/material';
import { format } from 'date-fns';
import { searchQuoteAction, getQuotelisting } from '../../redux/actions/quotelistingActions';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const tableHeaders = [
//   { id: 'quote_number', label: 'Quote Number' },
{ id: 'name', label: 'Name' },
  { id: 'policynumber', label: 'Policy Number' },

  { id: 'dateofbirth', label: 'Date Of Birth' },
  { id: 'phonenumber', label: 'Phone Number' },
//   { id: 'license_number', label: 'License Number' },
  { id: 'effdate', label: 'Effective Date' },
  { id: 'expdate', label: 'Expiry Date' },
];


function CustomerListing() {
    const quotes = useSelector((state) => state?.quotelisting?.allQuotes);
  const loading = useSelector((state) => state.loading.ALL_LEADS);
  const error = useSelector((state) => state?.quotelisting?.error);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    // dateOfBirth: '',
    email: '',
    licenseNumber: '',
    vin: '',
    phoneNumber: '',
    quoteNumber: '',
    // from: '',
    // to: ''
  });

  const [quoteData, setQuoteData] = useState(getQuoteData(quotes) || []);
  const [selectedLeadData, setSelectedLeadData] = React.useState(null);

//   useEffect(() => {
//     dispatch(getQuotelisting());
//   }, []);

//   useEffect(() => {
//     setQuoteData(getQuoteData(quotes) || []);
//   }, [quotes]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const formatDateString = (dateString) => {
    if (!dateString) return '';
    return format(new Date(dateString), 'MM-dd-yyyy');
  };

  const handleSearch = () => {
    const formattedData = {
      ...formData,
      createdBy:10,
      // dateOfBirth: formatDateString(formData.dateOfBirth),
      // from: formatDateString(formData.from),
      // to: formatDateString(formData.to),
    };

    dispatch(searchQuoteAction(formattedData, (response) => {
      setQuoteData(response || []);
    }));
  };
  return (
    <>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: 'bold' }}>Search Customer</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography id="policynumber">Policy Number</Typography>
            <TextField
              placeholder='Enter the policy number'
              fullWidth
              variant="outlined"
              name="policyNumber"
              value={formData.policyNumber}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography id="firstname">First Name</Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder='Enter the first name'
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography id="lastname">Last Name</Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder='Enter the last name'
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
            />
          </Grid>
          {/* <Grid item xs={3}>
            <Typography>Date of birth</Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder='dd-mm-yyyy'
              type='date'
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleInputChange}
            />
          </Grid> */}
          <Grid item xs={3}>
            <Typography id="email">Email</Typography>
            <TextField
              fullWidth
              type="email"
              placeholder='Enter the email'
              variant="outlined"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography id="licensenumber">License Number</Typography>
            <TextField
              placeholder='Enter the license number'
              fullWidth
              variant="outlined"
              name="licenseNumber"
              value={formData.licenseNumber}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography id="vin">Vin</Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder='Enter the vin number'
              name="vin"
              value={formData.vin}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={3}>
            <Typography id="phonenumber">Phone Number</Typography>
            <TextField
              fullWidth
              placeholder='Enter the phone number'
              variant="outlined"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
            />
          </Grid>

          {/* <Grid item xs={3}>
            <Typography>Quote Number</Typography>
            <TextField
              placeholder='Enter the quote number'
              fullWidth
              variant="outlined"
              name="quoteNumber"
              value={formData.quoteNumber}
              onChange={handleInputChange}
            />
          </Grid> */}
          {/* <Grid item xs={3}>
            <Typography>From</Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder='dd-mm-yyyy'
              type='date'
              name="from"
              value={formData.from}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography>To</Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder='dd-mm-yyyy'
              type='date'
              name="to"
              value={formData.to}
              onChange={handleInputChange}
            />
          </Grid> */}
          <Grid item xs={3}>
            <Typography id="companyname">Company Name</Typography>
            <TextField
              placeholder='Enter the company name'
              fullWidth
              variant="outlined"
              name="quoteNumber"
              value={formData.quoteNumber}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography>&nbsp;</Typography>
            <Button fullWidth  id="search" variant="contained" color="primary" onClick={handleSearch}>
              Search
            </Button>
          </Grid>
        </Grid>
      </form>
      <TableContainer component={Paper}>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 'bold', mt: 5, ml: 2 }}>Policies</Typography>
        </Grid>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <StyledTableCell key={header.id} align={header.align || 'left'} sx={{ fontWeight: 'bold' }}>
                  {header.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {quoteData.map((row) => (
              <StyledTableRow key={row.quoteNumber}>
                <StyledTableCell>{row.quoteNumber}</StyledTableCell>
                <StyledTableCell>{row.policyNumber}</StyledTableCell>
                <StyledTableCell>{row.name}</StyledTableCell>
                <StyledTableCell>{row.dateOfBirth}</StyledTableCell>
                <StyledTableCell>{row.phoneNumber}</StyledTableCell>
                <StyledTableCell>{row.licenseNumber}</StyledTableCell>
                <StyledTableCell>{row.effectiveDate}</StyledTableCell>
                <StyledTableCell>{row.expiryDate}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default CustomerListing;

const getQuoteData = (quotes) => {
    if (!Array.isArray(quotes)) {
      return [];
    } else {
      return quotes;
    }
  };


import React, { useState } from 'react';
import FormLabelText from '../../../common/FormLabelText';
import { FormControl, Link, FormLabel, RadioGroup, Grid, OutlinedInput, Select, MenuItem, Box, FormControlLabel, Radio } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const ContactInformation = () => {
    const [customer, setCustomer] = useState('Personal Lines');
    const [country, setCountry] = useState('');
    const [status, setStatus] = useState('New');

    const handleChange = (event) => {
        setCustomer(event.target.value);
    };

    const handleChangeCountry = (event) => {
        setCountry(event.target.value);
    }

    return(        
        <>
            <Box sx={{ border: '#ccc solid 1px', borderRadius: '4px', padding: '20px', margin: '40px 0 0 0', position: 'relative' }}>
                <FormLabelText labelText="Contact Information" />

                <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={2} md={12} sx={{margin: '15px 0 0 0'}}>                        
                        <label htmlFor="cell">Cell*</label>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} sx={{margin: '15px 0 0 0'}}>        
                        <Grid container spacing={2} sx={{margin:'0'}} >
                            <Grid xs={11} md={11}>
                                <OutlinedInput  placeholder="___/___/____" id="cell" type="number" sx={{ width: '100%'}}  />
                            </Grid>    
                            <Grid xs={1} md={1} sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Link><EditIcon /></Link>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} sx={{margin: '15px 0 0 0'}}>                    
                        <Grid container spacing={2} sx={{margin:'0'}}>
                            <Grid xs={11} md={11}>
                                <OutlinedInput placeholder="Enter the phone number" id="phone1" type="number" sx={{ width: '100%'}}  />
                            </Grid>    
                            <Grid xs={1} md={1} sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Link><EditIcon /></Link>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} sx={{margin: '15px 0 0 0'}}>                    
                        <Grid container spacing={2} sx={{margin:'0'}}>
                            <Grid xs={11} md={11}>
                                <OutlinedInput placeholder="Enter the phone number" id="phone2" type="number" sx={{ width: '100%'}}  />
                            </Grid>    
                            <Grid xs={1} md={1} sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Link><EditIcon /></Link>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} sx={{margin: '15px 0 0 0'}}>                    
                        <Grid container spacing={2} sx={{margin:'0'}}>
                            <Grid xs={11} md={11}>
                                <OutlinedInput placeholder="Enter the phone number" id="phone3" type="number" sx={{ width: '100%'}}  />
                            </Grid>    
                            <Grid xs={1} md={1} sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Link><EditIcon /></Link>
                            </Grid>
                        </Grid>
                    </Grid>    
                </Grid>                                

                <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={6} md={3} sx={{margin: '15px 0 0 0'}}>
                        <FormControl fullWidth>
                            <Box sx={{
                                    padding: '0 0 10px 0',
                                }}>
                                <label htmlFor="driving_license">Driving License</label>
                            </Box>
                            <Grid container spacing={2} sx={{margin:'0'}}>
                                <Grid xs={11} md={11}>
                                    <OutlinedInput placeholder="Driving License" id="drivinglicense" type="text" sx={{ width: '100%'}}  />
                                </Grid>    
                                <Grid xs={1} md={1} sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Link><EditIcon /></Link>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} sx={{margin: '15px 0 0 0'}}>
                        <FormControl fullWidth>
                            <Box sx={{
                                padding: '0 0 10px 0',
                            }}>
                                <label htmlFor="dl_state">DL State</label>
                            </Box>
                            <Grid container spacing={2} sx={{margin:'0'}}>
                                <Grid xs={11} md={11}>
                                    <Select
                                        sx={{ width: '100%'}} 
                                        onChange={handleChange}
                                        labelId="dl_state"
                                        id="dlstateselect"
                                        value={customer}
                                    >
                                        <MenuItem defaultValue value="Personal Lines">Personal Lines</MenuItem>
                                        <MenuItem value="Personal Lines 2">Personal Lines 2</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid xs={1} md={1} sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Link><EditIcon /></Link>
                                </Grid>    
                            </Grid>  
                        </FormControl>
                    </Grid>    
                    
                    <Grid item xs={12} sm={6} md={3} sx={{margin: '15px 0 0 0'}}>
                        <FormControl fullWidth>
                            <Box sx={{
                                padding: '0 0 10px 0',
                            }}>
                                <label htmlFor="date_licensed">Date Licensed</label>
                            </Box>
                            <Grid container spacing={2} sx={{margin:'0'}}>
                                <Grid xs={11} md={11}>
                                    <OutlinedInput placeholder="MM/DD/YYYY" id="datelicensed" type="date" sx={{width: '100%'}} />
                                </Grid>
                                <Grid xs={1} md={1} sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Link><EditIcon /></Link>
                                </Grid>
                            </Grid> 
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} sx={{margin: '15px 0 0 0'}}>
                        <FormControl fullWidth>
                            <Box sx={{
                                padding: '0 0 10px 0',
                            }}>
                                <label htmlFor="date_of_birth">Date of Birth</label>
                            </Box>
                            <Grid container spacing={2} sx={{margin:'0'}}>
                                <Grid xs={11} md={11}>
                                    <OutlinedInput placeholder="MM/DD/YYYY" id="dateofbirth" type="date" sx={{width: '100%'}} />
                                </Grid>
                                <Grid xs={1} md={1} sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Link><EditIcon /></Link>
                                </Grid>
                            </Grid> 
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={6} md={3} sx={{margin: '15px 0 0 0'}}>
                        <FormControl fullWidth>
                            <Box sx={{
                                    padding: '0 0 10px 0',
                                }}>
                                <label htmlFor="ssn">SSN/Tax ID</label>
                            </Box>
                            <Grid container spacing={2} sx={{margin:'0'}}>
                                <Grid xs={11} md={11}>
                                    <OutlinedInput placeholder="SSN/Tax ID" id="ssn" type="text" sx={{ width: '100%'}}  />
                                </Grid>    
                                <Grid xs={1} md={1} sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Link><EditIcon /></Link>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} sx={{margin: '15px 0 0 0'}}>
                        <FormControl fullWidth>
                            <Box sx={{
                                padding: '0 0 10px 0',
                            }}>
                                <label htmlFor="marital_status">Marital Status</label>
                            </Box>
                            <Grid container spacing={2} sx={{margin:'0'}}>
                                <Grid xs={11} md={11}>
                                    <Select
                                        sx={{ width: '100%'}} 
                                        onChange={handleChange}
                                        labelId="marital_status"
                                        id="maritalstatusselect"
                                        value={customer}
                                    >
                                        <MenuItem defaultValue value="Married">Married</MenuItem>
                                        <MenuItem value="Single">Single</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid xs={1} md={1} sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Link><EditIcon /></Link>
                                </Grid>    
                            </Grid>  
                        </FormControl>
                    </Grid>    
                </Grid>

                <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={6} md={3} sx={{margin: '15px 0 0 0'}}>
                        <FormLabel id="Propertyinsurance">Property Insurance</FormLabel>
                        <RadioGroup row defaultValue="no" aria-labelledby="property_insurance" name="property_insurance">
                            <FormControlLabel value="yes" control={<Radio id="property_insurance_yes" />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio id="property_insurance_no" />} label="No" />
                        </RadioGroup>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} sx={{margin: '15px 0 0 0'}}>
                        <FormLabel id="Autinsurance">Auto Insurance</FormLabel>
                        <RadioGroup row defaultValue="no" aria-labelledby="auto_insurance" name="auto_insurance">
                            <FormControlLabel value="yes" control={<Radio id="auto_insurance_yes" />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio id="auto_insurance_no" />} label="No" />
                        </RadioGroup>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default ContactInformation;

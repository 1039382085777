import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Checkbox, IconButton, Menu, MenuItem, Box } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import policiesData from './policies_data.json'; // Ensure this path is correct
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import { Link,useLocation  } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CreatePolicy from './CreatePolicy';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import dayjs from "dayjs";
import { getAllPolicyAction } from '../../../redux/actions/policyActions';
import { deletePolicyActionById } from '../../../redux/actions/policyActions';

const tableHeaders = [
    { id: 'f', label: 'F' },
    { id: 'dl', label: 'DL' },
    { id: 'action', label: 'Action' },
    { id: 'id', label: 'ID Card' },
    { id: 'company', label: 'Company' },
    { id: 'status', label: 'Status' },
    { id: 'type', label: 'Type' },
    { id: 'class', label: 'Class' },
    { id: 'policyNumber', label: 'Policy' },
    { id: 'paymentDueDay', label: 'Due' },
    { id: 'effectiveDate', label: 'Eff Date' },
    { id: 'expirationDate', label: 'Exp/Can Date' },
    { id: 'premium', label: 'Premium' },
    { id: 'edit', label: 'Edit' },
    { id: 'delete', label: 'Delete' },
    { id: 'details', label: 'Details' },
    { id: 'add', label: 'Add' },
    { id: 'rater', label: 'Rater' },
    { id: 'change_owner', label: 'Change Status' }
];

const DropdownButton = ({ label, options }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button variant="outlined"  endIcon={<ArrowDropDownIcon />} onClick={handleClick}>
                {label}
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {options.map((option, index) => (
                    <MenuItem key={index} onClick={handleClose}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

const AllAccountListing = () => {
    const [tableData, setTableData] = useState([]);
    const location = useLocation();
    const navigate=useNavigate();
    const [showButton,setShowButton]=useState(false);
    const [showHideDriverForm, setShowHideDriverForm] = useState(false);
    const methods = useForm();
    const response = location.state?.response || [];
    const policyData = useSelector((state) => state.policy.customizedForm);
    const allPolicies = useSelector((state) => state.policy.allPolicies);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllPolicyAction());
    }, [dispatch]);

    useEffect(() => {
        if (allPolicies.length > 0) {
            setTableData(allPolicies);
        }
    }, [allPolicies]);


    useEffect(() => {
        // Add new policy data to the existing table data
        if (policyData && Object.keys(policyData).length > 0) {
            setTableData((prevData) => [...prevData, policyData]);
        }
    }, [policyData]);

    // useEffect(() => {
    //   if (policyData) {
    //     setTableData([policyData]);
    //   }
    // }, [policyData]);

    // useEffect(() => {
    //     setTableData(policiesData);
    // }, []);
    // useEffect(() => {
    //     if (response) {
    //         setTableData([response]);
    //     }
    // }, [response]);
    // const handleEditClick = (policy) => {
    //     setShowHideDriverForm(true);
    //     setShowButton(true);

    //     const formattedData = { ...policy };

    //     // Format only the date fields
    //     if (formattedData.effectiveDate) {
    //       formattedData.effectiveDate = dayjs(formattedData.effectiveDate).format('MM-DD-YYYY');
    //     }
    //     if (formattedData.expirationDate) {
    //       formattedData.expirationDate = dayjs(formattedData.expirationDate).format('MM-DD-YYYY');
    //     }

    //     methods.reset({
    //       ...formattedData,
    //       effectiveDate: formattedData.effectiveDate || '', // Ensure effectiveDate is populated
    //       expirationDate: formattedData.expirationDate || '', // Ensure expirationDate is populated
    //     });
    //   };
    const handleEditClick = () => {
        // Navigate to CreatePolicy component with pre-filled data
        setShowHideDriverForm(true)
        setShowButton(true);
        // console.log("policy data in edit",policyData);

        methods.reset(policyData);

      };
    //   const handleDeleteClick = (id) => {
    //     dispatch(deletePolicyActionById(id));
    // };
    const handleDeleteClick = (id) => {
        dispatch(deletePolicyActionById(id, () => {
          dispatch(getAllPolicyAction());
        }));
      };

      function handleClick() {
        setShowHideDriverForm(true);
        setTableData({});
      }

    return (
        <>
          {showHideDriverForm ? (
        <>
         <FormProvider {...methods}>
        <CreatePolicy setShowHideDriverForm={setShowHideDriverForm} setShowButton={setShowButton} />
        </FormProvider>
        </>
      ):(
        <>
            <Box sx={{display:'flex', justifyContent: 'end'}}>
                <Button   variant="text" sx={{ color: '#0173BC', fontWeight: 'bold', margin: '0 0 10px 0'}}>
                <Link to="/addPolicy"> <AddCircleOutlineIcon sx={{padding: '0 0px',}} />Add New Policy</Link>
                {/* Add New Policy */}
                </Button>
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {tableHeaders.map(header => (
                                <TableCell sx={{color: '#0173BC', fontWeight: 'bold'}} key={header.id}>{header.label}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.map((row, index) => (
                            <TableRow key={index}>
                                {tableHeaders.map(header => (
                                    <TableCell key={header.id} sx={{padding: '12px'}}>
                                        {header.id === 'f' ? (
                                            <Checkbox />
                                        ): header.id === 'edit' ? (
                                            // Render Edit icon and handle click event
                                            <IconButton onClick={handleEditClick}>
                                              <EditIcon />
                                            </IconButton>
                                          ): header.id === 'delete' ? (
                                            // Render Edit icon and handle click event
                                            <IconButton onClick={() => handleDeleteClick(row.id)}>
                                              <DeleteIcon />
                                            </IconButton>
                                          ): header.id === 'dl' ? (
                                            <IconButton>
                                                <FileDownloadDoneIcon />
                                            </IconButton>
                                        ) : header.id === 'action' ? (
                                            <DropdownButton label="Action" options={['Option 1', 'Option 2', 'Option 3']} />
                                        ) : header.id === 'details' ? (
                                            <DropdownButton label="Details" options={['Detail 1', 'Detail 2', 'Detail 3']} />
                                        ) : header.id === 'add' ? (
                                            <DropdownButton label="Add" options={['Add 1', 'Add 2', 'Add 3']} />
                                        ) : header.id === 'rater' ? (
                                            <DropdownButton label="Rater" options={['Rater 1', 'Rater 2', 'Rater 3']} />
                                        ) : (
                                            row[header.id]
                                        )}
                                </TableCell>
                            ))}
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </>
      )}
        </>
    );
};

export default AllAccountListing;

// import React, { useState,useEffect } from 'react';
// import {
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   Typography,
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   TextField,
//   Grid,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableRow,
//   Paper,
//   FormControl,
//   MenuItem,
//   Select
// } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { useDispatch,useSelector } from 'react-redux';
// import { getVehicleCoverageAction,saveVehicleCoverageAction } from '../../../redux/actions/vehicleInfoActions';


// const PremiumDetails = () => {

//   const [errors, setErrors] = useState({});
//   const [open, setOpen] = useState(false);
//   const dispatch = useDispatch();
//   const vehicleCoverageData = useSelector((state) => state.policyDetail.allVehicleCoverage);
//   const [coverages, setCoverages] = useState([]);
//   console.log("vehiclecoverage",vehicleCoverageData);
//   const [newCoverage, setNewCoverage] = useState({
//     type: "",
//     limit1: "",
//     deductible: "",
//     premium: "",
//     vehicleId:1,
//   });

//   useEffect(() => {
//     console.log("vehicle coverage action")
//     dispatch(getVehicleCoverageAction());
//   }, [dispatch]);

//   useEffect(() => {
//     if (vehicleCoverageData && vehicleCoverageData.content) {
//       setCoverages(vehicleCoverageData.content);
//     }
//   }, [vehicleCoverageData]);

//   const handleChangeCoverage = (e) => {
//     const { name, value } = e.target;
//     if ((name === "type" )) {
//       const regex = /^[A-Za-z]+$/;

//       // Check if input contains only letters and is no longer than 30 characters
//       if ((!regex.test(value) && value !== "") || value.length > 30) {
//         return; // Prevent updates if input is invalid
//       }
//     }
//     if (name === "limit1" || name==="deductible" || name==="premium") {
//       const regex = /^[0-9]+$/;
//       if ((!regex.test(value) && value !== "") || value.length > 5) {
//         return; // Prevent updates if input is invalid
//       }
//     }

//     setNewCoverage({ ...newCoverage, [name]: value });
//     if (value.trim()) {
//       setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
//     }
//   };

//   const validate = () => {
//     let tempErrors = {};
//     if (!newCoverage.type.trim()) {
//       tempErrors.type = 'Type is required';
//     }
//     setErrors(tempErrors);
//     return Object.keys(tempErrors).length === 0;
//   };

//   const handleAddCoverage = () => {
//     if (validate()) {
//     setCoverages([...coverages, newCoverage]);


//     dispatch(saveVehicleCoverageAction(newCoverage, () => {
//       console.log('coverage saved successfully');

//       setNewCoverage({
//         type: "",
//         limit1: "",
//         deductible: "",
//         premium: "",
//         vehicleId:1,
//       });
//       setOpen(false);
//     }));
//   }
//   };

//   const handleCancel=()=>
//     {
//       setNewCoverage({
//         type: "",
//         limit1: "",
//         deductible: "",
//         premium: "",
//         vehicleId:"",
//       });
//       setOpen(false);
//     }
//   return (
//   <div>
//       {coverages.map((coverage, index) => (
//         <Accordion key={index}>
//           <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//             <Typography>Vehicle Coverage: {index + 1}</Typography>
//           </AccordionSummary>
//           <AccordionDetails>
//             <TableContainer component={Paper}>
//               <Table>
//                 <TableBody>
//                   <TableRow>
//                     <TableCell><strong>BI:</strong></TableCell>
//                     <TableCell>{coverage.BI}</TableCell>
//                     <TableCell><strong>PD:</strong></TableCell>
//                     <TableCell>{coverage.PD}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell><strong>COMP:</strong></TableCell>
//                     <TableCell>{coverage.Comp}</TableCell>
//                     <TableCell><strong>Coll:</strong></TableCell>
//                     <TableCell>{coverage.Coll}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell><strong>DED:</strong></TableCell>
//                     <TableCell>{coverage.deductible}</TableCell>
//                     <TableCell><strong>Limit:</strong></TableCell>
//                     <TableCell>{coverage.limit1}</TableCell>
//                   </TableRow>
//                   {/* <TableRow>
//                     <TableCell><strong>Territory:</strong></TableCell>
//                     <TableCell>{vehicle.territory}</TableCell>
//                   </TableRow> */}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           </AccordionDetails>
//         </Accordion>
//       ))}

//       <Button variant="contained" color="primary" onClick={() => setOpen(true)} style={{ marginTop: '20px' }}>
//         Add Coverage
//       </Button>

//       <Dialog open={open} onClose={() => setOpen(false)}>
//         <DialogTitle>Add Coverage</DialogTitle>
//         <DialogContent>
//           <Grid container spacing={2}>
//             {Object.keys(newCoverage).filter((key) => key !== 'vehicleId').map((key) => (
//                key === 'type' ? (
//                 <Grid item xs={12} sm={6} key={key}>
//                     <Typography>
//             <label>{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</label>
//           </Typography>
//                   <FormControl fullWidth>
//                     {/* <InputLabel id={`gender-label`}>Gender</InputLabel> */}
//                     <Select
//                       id={`type-select`}
//                       name="type"
//                       value={newCoverage.type}
//                       onChange={handleChangeCoverage}
//                     >
//                       <MenuItem value="comp">Comp</MenuItem>
//                       <MenuItem value="coll">Coll</MenuItem>
//                       {/* <MenuItem value="pdLimit">pdLimit</MenuItem>
//                       <MenuItem value="medLimit">medLimit</MenuItem> */}
//                     </Select>
//                   </FormControl>
//                 </Grid>
//               ):
//                key === 'limit1' ? (
//                 <Grid item xs={12} sm={6} key={key}>
//                     <Typography>
//             <label>{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</label>
//           </Typography>
//                   <FormControl fullWidth>
//                     {/* <InputLabel id={`marital-status-label`}>Marital Status</InputLabel> */}
//                     <Select
//                       id={`limit1-status-select`}
//                       name="limit1"
//                       value={newCoverage.limit1}
//                       onChange={handleChangeCoverage}
//                     >
//                       <MenuItem value="10">10000</MenuItem>
//                       <MenuItem value="15">15000</MenuItem>
//                       <MenuItem value="20">20000</MenuItem>
//                       <MenuItem value="25">25000</MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Grid>
//               ): key === 'deductible' ? (
//                 <Grid item xs={12} sm={6} key={key}>
//                     <Typography>
//             <label>{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</label>
//           </Typography>
//                   <FormControl fullWidth>
//                     {/* <InputLabel id={`relationship-label`}>Relationship</InputLabel> */}
//                     <Select
//                       id={`deductible-select`}
//                       name="deductible"
//                       value={newCoverage.deductible}
//                       onChange={handleChangeCoverage}
//                     >
//                       <MenuItem value="250">250</MenuItem>
//                       <MenuItem value="350">350</MenuItem>
//                       <MenuItem value="500">500</MenuItem>

//                     </Select>
//                   </FormControl>
//                 </Grid>
//               ):
//               (
//               <Grid item xs={12} sm={6} key={key}>
//                  <Typography>
//             <label>{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</label>
//           </Typography>
//                 <TextField
//                   fullWidth
//                   // label={key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
//                   name={key}
//                   value={newCoverage[key]}
//                   onChange={handleChangeCoverage}
//                   error={!!errors[key]} // Check if there is an error for this field
//                   helperText={errors[key]} // Show error message for this field
//                 />
//               </Grid>
//               )
//             ))}
//           </Grid>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpen(false)} color="secondary">Cancel</Button>
//           <Button onClick={handleAddCoverage} color="primary">Add</Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// };

// export default PremiumDetails;




import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  FormControl,
  MenuItem,
  Select,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { saveVehicleCoverageAction } from '../../../redux/actions/vehicleInfoActions';
import { coll, comp } from '../../../common/StaticFieldList';
import { getDetailPolicyAction } from '../../../redux/actions/policyActions';

const PremiumDetails = ({ policyId }) => {
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const customerData = useSelector((state) => state.policy.allDetailPolicy);
  const vehicleCoverageData = customerData?.drivers?.[0]?.vehicles?.[0]?.coverages || [];

  const [coverages, setCoverages] = useState(vehicleCoverageData);
  const [limit1Options, setLimit1Options] = useState([]);
  const [newCoverage, setNewCoverage] = useState({
    type: "",
    limit1: "",
    deductible: "",
    premium: "",
    vehicleId: policyId,
  });

  useEffect(() => {
    if (policyId) {
      dispatch(getDetailPolicyAction(policyId));
    }
  }, [dispatch, policyId]);

  useEffect(() => {
    if (vehicleCoverageData.length !== coverages.length) {
      setCoverages(vehicleCoverageData);
    }
  }, [vehicleCoverageData]);

  const handleChangeCoverage = (e) => {
    const { name, value } = e.target;

    if (name === "type") {
      setNewCoverage({ ...newCoverage, type: value });
      setLimit1Options(value === "comp" ? comp : value === "coll" ? coll : []);
    } else {
      if ((name === "deductible" || name === "premium") && (!/^[0-9]+$/.test(value) || value.length > 5)) {
        return; // Prevent updates if input is invalid
      }
      setNewCoverage({ ...newCoverage, [name]: value });
    }
    if (value.trim()) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const validate = () => {
    const tempErrors = {};
    if (!newCoverage.type.trim()) tempErrors.type = "Type is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleAddCoverage = () => {
    if (validate()) {
      setCoverages([...coverages, newCoverage]);
      dispatch(saveVehicleCoverageAction(newCoverage, () => {
        setNewCoverage({
          type: "",
          limit1: "",
          deductible: "",
          premium: "",
          vehicleId: policyId,
        });
        setOpen(false);
      }));
    }
  };

  const handleCancel = () => {
    setNewCoverage({
      type: "",
      limit1: "",
      deductible: "",
      premium: "",
      vehicleId: policyId,
    });
    setOpen(false);
  };

  return (
    <div>
      {coverages.map((coverage, index) => (
        <Accordion key={index} id={`accordioncoverage${index}`}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id={`accordionheader${index}`}>
            <Typography>Vehicle Coverage: {index + 1}</Typography>
          </AccordionSummary>
          <AccordionDetails id={`accordiondetails${index}`}>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell><strong>BI:</strong></TableCell>
                    <TableCell>{coverage.BI}</TableCell>
                    <TableCell><strong>PD:</strong></TableCell>
                    <TableCell>{coverage.PD}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><strong>COMP:</strong></TableCell>
                    <TableCell>{coverage.Comp}</TableCell>
                    <TableCell><strong>Coll:</strong></TableCell>
                    <TableCell>{coverage.Coll}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><strong>DED:</strong></TableCell>
                    <TableCell>{coverage.deductible}</TableCell>
                    <TableCell><strong>Limit:</strong></TableCell>
                    <TableCell>{coverage.limit1}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ))}

      <Button variant="contained" id="addcoverage" color="primary" onClick={() => setOpen(true)} style={{ marginTop: '20px' }}>
        Add Coverage
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle id="addcoveragetitle">Add Coverage</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {Object.keys(newCoverage).filter((key) => key !== 'vehicleId').map((key) => (
              key === 'type' ? (
                <Grid item xs={12} sm={6} key={key}>
                  <Typography>
                    <label>{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</label>
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      id="typeselect"
                      name="type"
                      value={newCoverage.type}
                      onChange={handleChangeCoverage}
                    >
                      <MenuItem value="comp">Comp</MenuItem>
                      <MenuItem value="coll">Coll</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              ) : key === 'limit1' ? (
                <Grid item xs={12} sm={6} key={key}>
                  <Typography>
                    <label>{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</label>
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      id="limit1select"
                      name="limit1"
                      value={newCoverage.limit1}
                      onChange={handleChangeCoverage}
                    >
                      {limit1Options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item xs={12} sm={6} key={key}>
                  <Typography>
                    <label>{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</label>
                  </Typography>
                  <TextField
                    fullWidth
                    name={key}
                    value={newCoverage[key]}
                    onChange={handleChangeCoverage}
                    error={!!errors[key]}
                    helperText={errors[key]}
                  />
                </Grid>
              )
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} id="cancelbutton" color="secondary">Cancel</Button>
          <Button onClick={handleAddCoverage} id="addbutton" color="primary">Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PremiumDetails;

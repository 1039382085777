import React from 'react';
import Button from '@mui/material/Button';

const Status = ({ leadStatus }) => {
  let statusColor = '';
  let buttonText = '';

  switch (leadStatus.toLowerCase()) {
    case 'active':
      statusColor = 'success'; // Customize this based on your color scheme
      buttonText = 'Active';
      break;
    case 'new':
      statusColor = 'info'; // Customize this based on your color scheme
      buttonText = 'New';
      break;
    case 'completed':
      statusColor = 'success'; // Customize this based on your color scheme
      buttonText = 'Completed';
      break;
    case 'in progress':
      statusColor = 'info'; // Customize this based on your color scheme
      buttonText = 'In Progress';
      break;
    case 'rejected':
      statusColor = 'error'; // Customize this based on your color scheme
      buttonText = 'Rejected';
      break;
    default:
      statusColor = 'default'; // Default color if status is not recognized
      buttonText = leadStatus;
      break;
  }

  return (
    <Button variant="outlined" sx={{textTransform: 'capitalize', minWidth: '120px', borderRadius: '20px', fontSize: '12px'}} 
        color={statusColor}
        backgroundColor={statusColor}
        >
        {buttonText}
    </Button>
  );
}

export default Status;

import React, { useState} from 'react';
import { Container, Collapse, Box, TextField, Accordion, AccordionSummary, AccordionDetails,TablePagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const phoneData = [
    {
      policyNumber: 'UAD3B1140',
      type: 'Marketing',
      date: 'June 6, 2024',
      subject: 'Verify that policy Application has been signed by...',
      delivered: 'Yes',
      response: 'Verify that policy...'
    },
    {
      policyNumber: 'UAD3B1141',
      type: 'Marketing Campaign',
      date: 'June 6, 2024',
      subject: 'Verify that policy Application has been signed by...',
      delivered: 'No',
      response: 'Verify that policy...'
    },
    {
      policyNumber: 'UAD3B1142',
      type: 'Promotional',
      date: 'June 6, 2024',
      subject: 'Verify that policy Application has been signed by...',
      delivered: 'Yes',
      response: 'Verify that policy...'
    },
    {
      policyNumber: 'UAD3B1143',
      type: 'Marketing Campaign',
      date: 'June 6, 2024',
      subject: 'Verify that policy Application has been signed by...',
      delivered: 'Yes',
      response: 'Verify that policy...'
    }
  ];

const Phone = ({phones,poppinsFont,handleChangePage,handleChangeRowsPerPage,rowsPerPage,page}) => {
    const [expandedRows, setExpandedRows] = useState([]);

    const handleExpandClick = (index) => {
      setExpandedRows(prevExpandedRows =>
        prevExpandedRows.includes(index)
          ? prevExpandedRows.filter(row => row !== index)
          : [...prevExpandedRows, index]
      );
    };
    return(
        <>
        <TableContainer component={Paper}>
            <Table  sx={poppinsFont}>
            <TableHead>
                <TableRow>
                  <TableCell sx={poppinsFont}>Policy Number</TableCell>

                  <TableCell sx={poppinsFont}>Date</TableCell>
                  <TableCell sx={poppinsFont}>Subject</TableCell>
                  <TableCell sx={poppinsFont}>Delivered</TableCell>
                  <TableCell sx={poppinsFont}>Response</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {phones.map((row, index) => (
                   <React.Fragment key={index}>
                   <TableRow id={`phone-row-${index}`}>
                     <TableCell sx={poppinsFont}>{row.policyNumber}</TableCell>
                     {/* <TableCell sx={poppinsFont}>{row.type}</TableCell> */}
                     <TableCell sx={poppinsFont}>{row.date}</TableCell>
                     <TableCell sx={poppinsFont}>
                       <IconButton onClick={() => handleExpandClick(index)}>
                         {expandedRows.includes(index) ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                       </IconButton>
                       {row.subject || "No Subject"}
                     </TableCell>
                     <TableCell sx={poppinsFont}>{row.delivered ? "Yes" : "No"}</TableCell>
                     <TableCell sx={poppinsFont}>{row.response}</TableCell>
                   </TableRow>
                   <TableRow>
                     <TableCell colSpan={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
                       <Collapse in={expandedRows.includes(index)} timeout="auto" unmountOnExit>
                         <Box margin={1} sx={poppinsFont}>
                           <div id={`details${index}`}>
                             Detailed information about the subject: {row.subject || "No Subject"}
                           </div>
                         </Box>
                       </Collapse>
                     </TableCell>
                   </TableRow>
                 </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={100}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
    )
}
export default Phone;
import { getcompanyService } from "../../services/companyServices";
import { FETCH_COMPANY_SELECTION_SUCCESS,FETCH_COMPANY_SELECTION_FAILURE, START_LOADING,
    STOP_LOADING } from "./actionTypes";
export const getcompanyselectionAction = () => async (dispatch, getState) => {
    try {
      // console.log("Action dispatched");
      dispatch({ type: START_LOADING, service: 'FETCH_COMPANY_SELECTION' });
      const token = getState().auth.token||sessionStorage.getItem("token"); // Adjust based on where you store the token
      const response = await getcompanyService(token);
      // console.log("Action response:", response); // Debug log
      dispatch({ type: STOP_LOADING, service: 'FETCH_COMPANY_SELECTION' });
      dispatch({ type: FETCH_COMPANY_SELECTION_SUCCESS, payload: response });
    } catch (error) {
      dispatch({ type: STOP_LOADING, service: 'FETCH_COMPANY_SELECTION' });
      dispatch({
        type: FETCH_COMPANY_SELECTION_FAILURE,
        payload: error.message || 'Failed to fetch company selection. Please try again.',
      });
    }
  };
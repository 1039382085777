import React from "react";
import { Menu, MenuItem, Button, Box } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const MainMenu = () => {
  const [quoteAnchorEl, setQuoteAnchorEl] = React.useState(null);
  const [policyAnchorEl, setPolicyAnchorEl] = React.useState(null);
  const [adminAnchorEl, setAdminAnchorEl] = React.useState(null);
  const [reportAnchorEl, setReportAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const handleQuoteMenuClick = (event) => {
    setQuoteAnchorEl(event.currentTarget);
  };

  const handlePolicyMenuClick = (event) => {
    setPolicyAnchorEl(event.currentTarget);
  };
  const handleReportMenuClick = (event) => {
    setReportAnchorEl(event.currentTarget);
  };

  const handleAdminMenuClick = (event) => {
    setAdminAnchorEl(event.currentTarget);
  };

  const handleNewQuoteClick = (event) => {
    event.preventDefault();
    navigate("/"); // Navigate to the new quote URL
    handleClose();
    window.location.reload(); // Refresh the page
  };

  const handleHomeClick = (event) => {
    event.preventDefault();
    navigate("/home"); // Navigate to the new quote URL
    window.location.reload(); // Refresh the page
  };
  const handleBotClick = (event) => {
    event.preventDefault();
    navigate("/signupbot"); // Navigate to the new quote URL
    window.location.reload(); // Refresh the page
  };
  const handlePolicyClick = (event) => {
    event.preventDefault();
    navigate("/managepolicylisting"); // Navigate to the new quote URL
    handleClose();
    window.location.reload(); // Refresh the page
  };

  const handleBotReportClick = (event) => {
    event.preventDefault();
    navigate("/botreport"); // Navigate to the new quote URL
    handleClose();
    window.location.reload(); // Refresh the page
  };
  const handleBotAllReportClick = (event) => {
    event.preventDefault();
    navigate("/botallreport"); // Navigate to the new quote URL
    handleClose();
    window.location.reload(); // Refresh the page
  };
  const handleLocationList = (event) => {
    event.preventDefault();
    navigate("/locationmanager"); // Navigate to the new quote URL
    handleClose();
    window.location.reload(); // Refresh the page
  };
  const handleLocationGroup = (event) => {
    event.preventDefault();
    navigate("/locationgrp"); // Navigate to the new quote URL
    handleClose();
    window.location.reload(); // Refresh the page
  };
  const handleManageCarrier = (event) => {
    event.preventDefault();
    navigate("/managecarrier"); // Navigate to the new quote URL
    handleClose();
    window.location.reload(); // Refresh the page
  };
  const handleCarrierGroup = (event) => {
    event.preventDefault();
    navigate("/carriergroup"); // Navigate to the new quote URL
    handleClose();
    window.location.reload(); // Refresh the page
  };
  const handleUserList = (event) => {
    event.preventDefault();
    navigate("/usermanager"); // Navigate to the new quote URL
    handleClose();
    window.location.reload(); // Refresh the page
  };

  const handleClose = () => {
    setQuoteAnchorEl(null);
    setPolicyAnchorEl(null);
    setAdminAnchorEl(null);
    setReportAnchorEl(null);
  };

  const handleSearchQuoteClick = (event) => {
    event.preventDefault();
    navigate("/searchsavedquote"); // Navigate to the new quote URL
    handleClose();
    window.location.reload(); // Refresh the page
  };
  const dynamicTheme = useSelector((state) => state?.theme?.theme[0]);

  return (
    <Box>
      <Button
        sx={{ margin: "0 40px", fontFamily: '"Poppins", sans-serif' }}
        id="botbutton"
        style={{ color: dynamicTheme?.secondaryColor }}
        onClick={handleBotClick}
      >
        Bot
      </Button>
      <Button
        aria-controls="quote-menu"
        aria-haspopup="true"
        onClick={handleQuoteMenuClick}
        endIcon={<ArrowDropDown />}
        sx={{ margin: "0 40px", fontFamily: '"Poppins", sans-serif' }}
        style={{ color: dynamicTheme?.secondaryColor }}
      >
        Quote
      </Button>
      <Menu
        id="quotemenu"
        anchorEl={quoteAnchorEl}
        open={Boolean(quoteAnchorEl)}
        onClose={handleClose}
        sx={{ fontFamily: '"Poppins", sans-serif' }}
        style={{ color: dynamicTheme?.primaryColor }}
      >
        {/* <MenuItem>
          <Link to="/" sx={{fontFamily: '"Poppins", sans-serif'}}
          style={{color: dynamicTheme?.secondaryColor}}
          >
            New Quote
          </Link>
        </MenuItem> */}
        <MenuItem
          onClick={handleNewQuoteClick}
          sx={{ fontFamily: '"Poppins", sans-serif' }}
          style={{ color: dynamicTheme?.secondaryColor }}
        >
          New Quote
        </MenuItem>
        <MenuItem
          onClick={handleSearchQuoteClick}
          sx={{ fontFamily: '"Poppins", sans-serif' }}
          style={{ color: dynamicTheme?.secondaryColor }}
        >
          Search Quote
        </MenuItem>
      </Menu>

      <Button
        aria-controls="policy-menu"
        aria-haspopup="true"
        onClick={handlePolicyMenuClick}
        id="policybutton"
        endIcon={<ArrowDropDown />}
        sx={{ margin: "0 40px", fontFamily: '"Poppins", sans-serif' }}
        style={{ color: dynamicTheme?.secondaryColor }}
      >
        Policy
      </Button>
      <Menu
        id="policymenu"
        anchorEl={policyAnchorEl}
        open={Boolean(policyAnchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={handleClose}
          sx={{ fontFamily: '"Poppins", sans-serif' }}
        >
          Create Policy
        </MenuItem>
        <MenuItem
          onClick={handlePolicyClick}
          sx={{ fontFamily: '"Poppins", sans-serif' }}
        >
          Manage Policy
        </MenuItem>
      </Menu>

      <Button
        aria-controls="report-menu"
        aria-haspopup="true"
        onClick={handleReportMenuClick}
        id="reportbutton"
        endIcon={<ArrowDropDown />}
        sx={{ margin: "0 40px", fontFamily: '"Poppins", sans-serif' }}
        style={{ color: dynamicTheme?.secondaryColor }}
      >
        Report
      </Button>
      <Menu
        id="reportmenu"
        anchorEl={reportAnchorEl}
        open={Boolean(reportAnchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={handleBotReportClick}
          sx={{ fontFamily: '"Poppins", sans-serif' }}
          style={{ color: dynamicTheme?.secondaryColor }}
        >
          Bot Activity log
        </MenuItem>
        <MenuItem
          onClick={handleBotAllReportClick}
          sx={{ fontFamily: '"Poppins", sans-serif' }}
        >
          History log
        </MenuItem>
      </Menu>
      <Button
        aria-controls="admin-menu"
        aria-haspopup="true"
        onClick={handleAdminMenuClick}
        endIcon={<ArrowDropDown />}
        sx={{ margin: "0 40px", fontFamily: '"Poppins", sans-serif' }}
        style={{ color: dynamicTheme?.secondaryColor }}
      >
        Admin
      </Button>
      <Menu
        id="adminmenu"
        anchorEl={adminAnchorEl}
        open={Boolean(adminAnchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={handleLocationList}
          sx={{ fontFamily: '"Poppins", sans-serif' }}
          style={{ color: dynamicTheme?.secondaryColor }}
        >
          Location List
        </MenuItem>

        <MenuItem
          onClick={handleLocationGroup}
          sx={{ fontFamily: '"Poppins", sans-serif' }}
          style={{ color: dynamicTheme?.secondaryColor }}
        >
          Location Group
        </MenuItem>

        <MenuItem
          onClick={handleManageCarrier}
          sx={{ fontFamily: '"Poppins", sans-serif' }}
          style={{ color: dynamicTheme?.secondaryColor }}
        >
          Manage Carrier
        </MenuItem>

        <MenuItem
          onClick={handleCarrierGroup}
          sx={{ fontFamily: '"Poppins", sans-serif' }}
        >
          Carrier Group
        </MenuItem>
        <MenuItem
          onClick={handleUserList}
          sx={{ fontFamily: '"Poppins", sans-serif' }}
          style={{ color: dynamicTheme?.secondaryColor }}
        >
          User List
        </MenuItem>
      </Menu>

      <Button
        sx={{ margin: "0 40px", fontFamily: '"Poppins", sans-serif' }}
        id="homebutton"
        style={{ color: dynamicTheme?.secondaryColor }}
        onClick={handleHomeClick}
      >
        Dashboard
      </Button>
    </Box>
  );
};

export default MainMenu;

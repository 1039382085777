import React, { useState } from 'react'
import AddInsuranceCarrier from './AddInsuranceCarrier';
import ManageCarrierListing from './ManageCarrierListing';
import { Box } from '@mui/material';

function CarrierManager() {
  const [carriers, setCarriers] = useState([]);
  const [showForm, setShowForm] = useState(false);

  const addCarriers = (carrier) => {
    // Validation: Ensure location is not empty before adding
    if (!carrier.name || carrier.name.trim() === '') {
      alert('carriers name is required.');
      return;
    }

    setCarriers((prevCarriers) => [...prevCarriers, carrier]);
    setShowForm(false);
  };

  const toggleForm = () => {
    setShowForm((prevShowForm) => !prevShowForm);
  };
  return (
    <Box sx={{ padding: 2 }}>
    {showForm ? (
      <AddInsuranceCarrier
      addCarriers={addCarriers}
        toggleForm={toggleForm}
        id="addCarrierform"
      />
    ) : (
      <ManageCarrierListing
      carriers={carriers}
        toggleForm={toggleForm}
        id="carrierlist"
      />
    )}

  </Box>
  )
}

export default CarrierManager

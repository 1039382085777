const initialState = {
  locationGroupsForm: JSON.parse(localStorage.getItem('locationGroupsForm')) || {},
  allLocationGroups: [],
  loading: false, // General loading state for all actions
  deleteLoading: false, // Loading state specifically for delete action
  error: null,
};

const locationGroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ALL_LOCATION_GROUPS':
      return { ...state, allLocationGroups: action.payload, loading: false, error: null };

    case 'DELETE_GROUP_LOCATIONS':
      return {
        ...state,
        allLocationGroups: state.allLocationGroups.filter(group => group.locationGroupId !== action.payload), // Remove deleted group
        deleteLoading: false, // Stop delete loading after deletion
      };

      case 'UPDATE_LOCATION_GROUPS':
      return {
        ...state,
        allLocationGroups: state.allLocationGroups.map((group) =>
          group.id === action.payload.id ? action.payload : group
        ),
      };
     
      
    case 'START_DELETE_LOADING':
      return { ...state, deleteLoading: true }; // Start delete loading

    case 'STOP_DELETE_LOADING':
      return { ...state, deleteLoading: false }; // Stop delete loading

    case 'GET_LOCATION_GROUPS_FAILURE':
      return { ...state, error: action.payload, loading: false, deleteLoading: false };

    default:
      return state;
  }
};

export default locationGroupsReducer;


import apibotService from "./apibotServices";

export const saveBotForm = async(formData, token) => {
    try {

        // console.log(formData, 'formData, for task');
        const response = await apibotService('/carrier-master/save', 'POST', formData, null, token);
        return response;
        } catch (error) {
          throw (error?.message || 'Saving bot is failed. Please try again.');
        }
  }

  export const getAllBot = async (token) => {
    try {

      const response = await apibotService('/carrier-master/get-all', 'GET', null, null, token);
    //   console.log("all response",response)
      return response;
    } catch (error) {
      throw (error?.message || 'service is failed. Please try again.');
    }
  }

  //Search
  export const searchBotAllReport = async(formData, token,page, size) => {
    try {
      // console.log("formdata",formData);
        const response = await apibotService(`/carrier-master-history/search?page=${page}&size=${size}`, 'POST', formData, null, token);
        // console.log("searchquote",response);
        return response;
      } catch (error) {
        throw (error?.message || 'search quote service is failed. Please try again.');
      }
  }

  export const searchBotLogsReport = async(formData, token,page, size,sort) => {
    try {
      // console.log("formdata",formData);
        const response = await apibotService(`/bot-process-details/search?page=${page}&size=${size}`, 'POST', formData, null, token);
        // console.log("searchquote",response);
        return response;
      } catch (error) {
        throw (error?.message || 'search quote service is failed. Please try again.');
      }
  }

  export const searchBotLogsDownloadReport = async(formData, token,page, size) => {
    try {
      // console.log("formdata",formData);
        const response = await apibotService(`/bot-process-details/search?page=${page}&size=${size}`, 'POST', formData, null, token);
        // console.log("searchquote",response);
        return response;
      } catch (error) {
        throw (error?.message || 'search quote service is failed. Please try again.');
      }
  }


  export const getBotReport = async (token,page, size,sort) => {
    try {

      const response = await apibotService(`/bot-process-details/get?page=${page}&size=${size}&sort=${sort}`, 'GET', null, null, token);
    //   console.log("all response",response)
      return response;
    } catch (error) {
      throw (error?.message || 'service is failed. Please try again.');
    }
  }

  export const getBotAllReport = async (token,page, size) => {
    try {

      const response = await apibotService(`/carrier-master-history/get?page=${page}&size=${size}`, 'GET', null, null, token);
    //   console.log("all response",response)
      return response;
    } catch (error) {
      throw (error?.message || 'service is failed. Please try again.');
    }
  }

  export const getBotUserId = async (id,token) => {
    try {

      const response = await apibotService(`/carrier-master/get/userid/${id}`, 'GET', null, null, token);
    //   console.log("response of pilicy",response);
      return response;

    } catch (error) {
      throw (error?.message || 'service is failed. Please try again.');
    }
  }

  export const updateBot = async (formData, token) => {
    try {

      const response = await apibotService('/carrier-master/update', 'PUT', formData, null, token);
      return response;
      } catch (error) {
        throw (error?.message || 'Updating bot is failed. Please try again.');
      }
  }




  //crm service

  export const saveBotSignupForm = async(formData, token) => {
    try {

        // console.log(formData, 'formData, for task');
        const response = await apibotService('/crm-master/save', 'POST', formData, null, token);
        return response;
        } catch (error) {
          throw (error?.message || 'Saving bot signup is failed. Please try again.');
        }
  }


  export const getBotLogin = async (token) => {
    try {

      const response = await apibotService('/crm-master/get-all', 'GET', null, null, token);
    //   console.log("all response",response)
      return response;
    } catch (error) {
      throw (error?.message || 'service is failed. Please try again.');
    }
  }

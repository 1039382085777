import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  modalContent: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  sectionTitle: {
    color: theme.palette.primary.main,
    backgroundColor: 'rgba(224, 224, 224, 1)',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
}));

const SessionTimeOutModal = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle className={classes.sectionTitle} sx={{mb:"1rem"}}>Session Timeout</DialogTitle>
      <DialogContent className={classes.modalContent}>
        <Typography variant="body1" gutterBottom>
          Your session has expired. Please log in again.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button id="loginbutton" onClick={onClose} color="primary" variant='contained'>
          Log In
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionTimeOutModal;

import { getAllBot, getBotAllReport, getBotLogin, getBotReport, getBotUserId, saveBotForm, saveBotSignupForm, searchBotAllReport, searchBotLogsDownloadReport, searchBotLogsReport, updateBot } from "../../services/botServices";
import { START_LOADING,STOP_LOADING } from "./actionTypes";
import { openSessionTimeoutModal } from './sessionTimeOutActions';


export const savebotAction = (formData, callbackfn) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'SAVE_BOT' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await saveBotForm(formData, token);
      // console.log(response);
      dispatch({ type: STOP_LOADING, service: 'SAVE_BOT' });
      if (response) {
        callbackfn(response);
      }
      dispatch({ type: 'SAVE_BOT_SUCCESS', payload: response }); // Dispatch the success action with response data
    } catch (error) {
      if (error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: STOP_LOADING, service: 'SAVE_BOT' });
        dispatch({ type: 'SAVE_BOT_FAILURE', payload: error || 'Save bot service failed. Please try again.' });
      }
    }
  };


  export const getAllBotAction= () => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'ALL_BOT' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await getAllBot(token);
      dispatch({ type: STOP_LOADING, service: 'ALL_BOT' });
      dispatch({ type: 'GET_BOT_SUCCESS', payload: response });
    } catch (error) {
      if(error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: STOP_LOADING, service: 'ALL_BOT' });
        dispatch({ type: 'GET_BOT_FAILURE', payload: error || 'All Bot service failed. Please try again.' });
      }
    }
  };

  export const getBotByUserIdAction= (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'ALL_BOT' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await getBotUserId(id,token);
      dispatch({ type: STOP_LOADING, service: 'ALL_BOT' });
      dispatch({ type: 'GET_BOT_SUCCESS', payload: response });
    } catch (error) {
      if(error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: STOP_LOADING, service: 'ALL_BOT' });
        dispatch({ type: 'GET_BOT_FAILURE', payload: error || 'All Bot service failed. Please try again.' });
      }
    }
  };

  //Search Bot
  export const searchBotAllReportAction = (formData,page = 1, size = 5, callbackfn) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'SEARCH_BOT' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await searchBotAllReport(formData, token,page, size);
      // console.log("alert",response);
      dispatch({ type: STOP_LOADING, service: 'SEARCH_BOT' });
      // if (response) {
      //   callbackfn(response);
      // }
      dispatch({ type: 'SEARCH_BOT_SUCCESS', payload: response }); // Dispatch the success action with response data
    } catch (error) {
      if (error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: STOP_LOADING, service: 'SEARCH_BOT' });
        dispatch({ type: 'SEARCH_BOT_FAILURE', payload: error || 'Search Bot service failed. Please try again.' });
      }
    }
  };

  export const searchBotDownloadAllReportAction = (formData,page = 1, size = 5) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'SEARCH_BOT' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await searchBotAllReport(formData, token,page, size);
      // console.log("alert",response);
      dispatch({ type: STOP_LOADING, service: 'SEARCH_BOT' });
      // if (response) {
      //   callbackfn(response);
      // }
      dispatch({ type: 'SEARCH_BOTDOWNLOAD_SUCCESS', payload: response }); // Dispatch the success action with response data
    } catch (error) {
      if (error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: STOP_LOADING, service: 'SEARCH_BOT' });
        dispatch({ type: 'SEARCH_BOTDOWNLOAD_FAILURE', payload: error || 'Search Bot service failed. Please try again.' });
      }
    }
  };

  export const searchBotLogsReportAction = (formData,page = 1, size = 5) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'SEARCH_BOT' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await searchBotLogsReport(formData, token,page, size);
      // console.log("alert",response);
      dispatch({ type: STOP_LOADING, service: 'SEARCH_BOT' });
      // if (response) {
      //   callbackfn(response);
      // }
      dispatch({ type: 'SEARCH_BOTLOGS_SUCCESS', payload: response }); // Dispatch the success action with response data
    } catch (error) {
      if (error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: STOP_LOADING, service: 'SEARCH_BOT' });
        dispatch({ type: 'SEARCH_BOTLOGS_FAILURE', payload: error || 'Search Bot service failed. Please try again.' });
      }
    }
  };

  export const searchBotDownloadLogsReportAction = (formData,page = 1, size = 5) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'SEARCH_BOT' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await searchBotLogsDownloadReport(formData, token,page, size);
      // console.log("alert",response);
      dispatch({ type: STOP_LOADING, service: 'SEARCH_BOT' });
      // if (response) {
      //   callbackfn(response);
      // }
      dispatch({ type: 'SEARCH_BOTDOWNLOADLOGS_SUCCESS', payload: response }); // Dispatch the success action with response data
    } catch (error) {
      if (error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: STOP_LOADING, service: 'SEARCH_BOT' });
        dispatch({ type: 'SEARCH_BOTDOWNLOADLOGS_FAILURE', payload: error || 'Search Bot service failed. Please try again.' });
      }
    }
  };


  export const getBotReportAction= (page = 1, size = 5,sort) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'ALL_BOT' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await getBotReport(token,page, size,sort);
      dispatch({ type: STOP_LOADING, service: 'ALL_BOT' });
      dispatch({ type: 'GET_BOT_REPORT_SUCCESS', payload: response });
    } catch (error) {
      if(error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: STOP_LOADING, service: 'ALL_BOT' });
        dispatch({ type: 'GET_BOT_REPORT_FAILURE', payload: error || 'All Bot service failed. Please try again.' });
      }
    }
  };

  export const getBotAllReportAction= (page = 1, size = 5) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'ALL_BOT' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await getBotAllReport(token,page, size);
      // console.log("report response",response);
      dispatch({ type: STOP_LOADING, service: 'ALL_BOT' });
      dispatch({ type: 'GET_BOT_ALL_REPORT_SUCCESS', payload: response });
    } catch (error) {
      if(error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: STOP_LOADING, service: 'ALL_BOT' });
        dispatch({ type: 'GET_BOT__ALL_REPORT_FAILURE', payload: error || 'All Bot service failed. Please try again.' });
      }
    }
  };

  export const updateBotAction = (formData,callbackfn) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'UPDATE_BOT' });
      const token = getState().auth.token || sessionStorage.getItem("token");;
      const response = await updateBot(formData, token);
      dispatch({ type: STOP_LOADING, service: 'UPDATE_BOT' });
    //   if (response) {
    //     callbackfn(response);
    //   }
      dispatch({ type: 'UPDATE_BOT_SUCCESS', payload: response });
    } catch (error) {
      // console.log(error, 'error in action')
      if(error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: STOP_LOADING, service: 'UPDATE_BOT' });
        dispatch({ type: 'UPDATE_BOT_FAILURE', payload: error || 'Update bot action failed. Please try again.' });
      }
    }
  };

  //bot signup

  export const savebotsignupAction = (formData, callbackfn) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'SAVE_BOT' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await saveBotSignupForm(formData, token);
      // console.log(response);
      dispatch({ type: STOP_LOADING, service: 'SAVE_BOT' });
    //   if (response) {
    //     callbackfn(response);
    //   }
      dispatch({ type: 'SIGNUP_BOT_SUCCESS', payload: response }); // Dispatch the success action with response data
    } catch (error) {
      if (error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: STOP_LOADING, service: 'SAVE_BOT' });
        dispatch({ type: 'SIGNUP_BOT_FAILURE', payload: error || 'Save bot service failed. Please try again.' });
      }
    }
  };


  export const getBotLoginAction= () => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'ALL_BOT' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await getBotLogin(token);
      dispatch({ type: STOP_LOADING, service: 'ALL_BOT' });
      dispatch({ type: 'GET_BOT_LOGIN_SUCCESS', payload: response });
    } catch (error) {
      if(error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: STOP_LOADING, service: 'ALL_BOT' });
        dispatch({ type: 'GET_BOT_LOGIN_FAILURE', payload: error || 'All Bot service failed. Please try again.' });
      }
    }
  };
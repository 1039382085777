
const initialState = {
    accounts: {},
    myAccounts: {},
    error: null,
  };
  
  const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ACCOUNT_SUCCESS':
            return { accounts: action.payload, error: null };
        case 'GET_MY_ACCOUNT_SUCCESS':
            return { myAccounts: action.payload, error: null };
        case 'SAVE_ACCOUNT_SUCCESS':
        case 'CREATE_ACCOUNT_SUCCESS':
            return { ...state, accounts: action.payload, error: null };
        case 'GET_ACCOUNT_FAILURE':
        case 'SAVE_ACCOUNT_FAILUTE':
        case 'CREATE_ACCOUNT_FAILUTE':
            return { ...state, error: action.payload };
        default:
            return state;
    }
  };
  
  export default accountReducer;
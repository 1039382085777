import { Box, Grid } from "@mui/material";
import Heading from './../../common/Heading';

const LeadInfoBar = (props) => {
  console.log(props.data.phoneNumber,"jk");
    const leadInfo = [
    {
        small: 'Cell',
        main_title: props.data.cell,
    },
    {
        small: 'Phone Number',
        main_title: props.data.phoneNumber,
    },
    {
        small: 'Email',
        main_title: props.data.email,
    },
]

const leadStyle = {
    position: 'relative',
    '&:after' : {
        borderRight: '#979797 solid 1px' ,
        content: `''`,
        position: 'absolute',
        top: '0',
        height: '45px',
        right: '25%'
    },
}
    return(
        <>
          <Box sx={{background: '#F4F4F4', border: '#D7D7D7 solid 1px', borderRadius: '4px', padding: '15px'}}>
            <Grid container spacing={4} sx={{margin:'0'}}>
            <Grid xs={12} md={3} sx={leadStyle}>
                <Box sx={{fontSize:'14px', color: '#242929'}}>Lead</Box>                    
                <Box sx={{fontSize:'22px', fontWeight: '600', color: '#0173BC'}}>{props.data.ownerName}</Box>
            </Grid>
                {leadInfo.map((data) => {
                    return(
                        <Grid xs={12} md={3}>
                            <Heading data={data} />   
                        </Grid>
                    )
                })}                
            </Grid>
          </Box>
        </>
    )
}

export default LeadInfoBar;
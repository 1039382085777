import React, { useState } from 'react';
import HeaderLayoutRoute from './HeaderLayoutRoute';
import Home from './pages/Home';
import RaterRoute from './RaterRoute';
import Crm from './Crm';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif' // Set your desired font family here
  },
});

function App() {
  const [useRoute, setUseRoute] = useState(true);
  // const [project, setProject] = useState(null);

  return (
    <>
        <ThemeProvider theme={theme}>
        <CssBaseline />
          {!useRoute ? (
            // <HeaderLayoutRoute />
            <Crm />
          ) : (
            <RaterRoute />
            // <Crm />
          )}
          {/* {!project && <Home setProject={setProject} />}
      {project === 'quote' && <RaterRoute />}
      {project === 'crm' && <Crm />} */}
          </ThemeProvider>

    </>
  );
}

export default App;

import { Box } from '@mui/material';

const FormLabelText = ({labelText}) => {
    return(
        <>
          <Box sx={{
                    position: 'absolute',
                    left: '15px',
                    top: '-15px',
                    backgroundColor: '#FFFFFF',
                    fontSize: '18px',
                    fontWeight: '600',
                    padding: '2px 15px'
                }}>
                    {labelText}
          </Box>
        </>
    )
}
export default FormLabelText;
import React, { useEffect } from 'react';
import { Box, Typography, Grid, Button, IconButton, MenuItem, Select, FormControl, InputLabel, Paper } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { useSelector, useDispatch } from 'react-redux';
import { getAllNoteAction } from '../../../redux/actions/noteActions';

const NotesListing = () => {
  const allNotes = useSelector((state) => state.note.allNote);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllNoteAction());
  }, [dispatch]);

  // Transforming notes data
  const notesData = allNotes.map((note, index) => ({
    id: index, 
    date: note.timestamp, // Assuming date field exists
    policy: note.policyId, // Adjust field names as per your data
    by: note.createdBy, // Assuming by field exists
    noteType: note.noteType, // Assuming noteType field exists
    note: note.message // Assuming note field exists
  }));

  return (
    <Box p={2} id="noteslistingcontainer">
      <Grid container justifyContent="flex-end" mb={2}>
        <FormControl variant="outlined" size="small" id="filterformcontrol">
          <InputLabel id="filterlabel">Filter</InputLabel>
          <Select defaultValue="All" label="Filter" id="filterselect">
            <MenuItem value="All" id="filteroptionall">All</MenuItem>
            {/* Add more filter options as needed */}
          </Select>
        </FormControl>
      </Grid>
      {notesData.map((note) => (
        <Paper key={note.id} variant="outlined" sx={{ p: 2, mb: 2 }} id={`note${note.id}`}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary" id={`notedate${note.id}`}>
                Date: {note.date} | Policy: {note.policy} | By: {note.by}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary" id={`notetype${note.id}`}>
                Note Type: {note.noteType}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" id={`notemessage${note.id}`}>
                {note.note}
              </Typography>
            </Grid>
            <Grid item container xs={12} justifyContent="space-between">
              <Button variant="outlined" id={`appendbutton${note.id}`}>Append</Button>
              <IconButton id={`printbutton${note.id}`}>
                <PrintIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </Box>
  );
};

export default NotesListing;

import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Grid} from '@mui/material';
import { Email, Phone } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { getAccounts } from '../../redux/actions/accountActions'
import CustomDataGrid from '../../shared/CustomDataGrid';
import { getUser } from '../../redux/actions/userActions';

const useStyles = makeStyles({
    header: {
      margin: ".5em 0 !important",
    },
    muiEven: {
      backgroundColor: "rgb(245, 245, 245)",
    },
    root: {
      '& .MuiDataGrid-columnHeaders': {
          backgroundColor: "rgb(220, 250, 250)",
      },
      '& .MuiDataGrid-row:hover': {
          backgroundColor: "rgb(240, 250, 250)",
      }
    }
  });

const handleCallPhone = (phone) => {
  console.log(phone);
}

const handleSendEmail = (email) => {
  console.log(email);
}

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    width: 150,
    editable: true,
  },
  {
    field: 'title',
    headerName: 'Title',
    width: 150,
    editable: true,
  },
  {
    field: 'company',
    headerName: 'Company',
    width: 160,
    editable: true,
  },
  {
    field: 'accountStatus',
    headerName: 'Account Status',
    sortable: true,
    width: 160,
  },
  {
    field: 'accountSource',
    headerName: 'Account Source',
    sortable: true,
    width: 160,
  },
  {
    field: 'lastActivity',
    headerName: 'Last Activity',
    sortable: true,
    width: 160,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 150,
    renderCell: (params) => (
      <div>
        <Email
          style={{ cursor: 'pointer', marginRight: '8px' }}
          onClick={() => handleSendEmail(params.row.email)}
        />
        <Phone
          style={{ cursor: 'pointer' }}
          onClick={() => handleCallPhone(params.row.phone)}
        />
      </div>
    ),
  },
];

const AllAccounts = () => {
  const classes = useStyles();
  const accounts = useSelector((state) => state.accounts.accounts);
  const loading = useSelector((state) => state.loading.GET_Accounts);
  const error = useSelector(state => state.accounts.error);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const [accountsData, setAccountsData] = useState(getAccountsData(accounts) || []);


  useEffect(() => {
    if(!user) {
      dispatch(getUser());
    } else {
      dispatch(getAccounts(user.customer.id));;
    }
  }, []);

  useEffect(() => {
    setAccountsData(getAccountsData(accounts) || []);
  }, [accounts]);

  return (
    <Box sx={{ minHeight: 400, width: '100%' }}>
      <Grid item xs={12} md={12}>
        <Typography id="AllAccounts" variant="h5" className={classes.header}>All Accounts</Typography>
      </Grid>
      <CustomDataGrid 
        gridData={accountsData}
        columns={columns}
        loading={loading}
        error={error}
      />
    </Box>
  );
}

export default AllAccounts;

const getAccountsData = (accounts) => {
  if (!Array.isArray(accounts)) {
    return [];
  }

  const myAccounts = accounts.map(account => ({
    ...account,
    name: `${account.firstName} ${account.lastName}`,
  }));

  return myAccounts;
};

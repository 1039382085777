const initialState = {
    all_activity: [],    
};

const activityReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ALL_ACTIVITY':
            return {
                ...state,
                all_activity: action.payload || [],
            };      
        default:
            return state;
    }
};

export default activityReducer;

import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  IconButton,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { Snackbar, Alert } from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import Botlogin from "./Botlogin";
import { useDispatch, useSelector } from "react-redux";
import {
  savebotAction,
  getAllBotAction,
  getBotByUserIdAction,
  getBotLoginAction,
  updateBotAction,
  savebotsignupAction,
} from "../../redux/actions/botAction";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const staticEntries = [
  {
    id: "static-1",
    carrierName: "Pearl Harobour",
    startDate: "",
    carrierAgencyCode: "",
    carrierAgencyUserId: "",
    carrierAgencyUserPassword: "",
    status: "Coming Soon",
    isReadOnly: true,
    isPrefilled: true,
    displayNumber: 3,
  },
  {
    id: "static-2",
    carrierName: "Geico",
    startDate: "",
    carrierAgencyCode: "",
    carrierAgencyUserId: "",
    carrierAgencyUserPassword: "",
    status: "Coming Soon",
    isReadOnly: true,
    isPrefilled: true,
    displayNumber: 4,
  },
];



const createEmptyEntry = (index) => {
  // Define preset carrier names for first two entries
  const presetCarriers = {
    0: "Progressive",
    1: "UAIC",
  };

  return {
    id: `dynamic-${index + 1}`,
    originalId: null,
    displayNumber: index + 1,
    checked: false,
    carrierName: presetCarriers[index] || "", // Use preset name if available
    startDate: "",
    carrierAgencyCode: "",
    carrierAgencyUserId: "",
    carrierAgencyUserPassword: "",
    status: "",
    isEditing: true,
    isPrefilled: false,
    isReadOnly: false, // Not read-only by default
    carrierNameReadOnly: index < 2,
  };
};


const formatDate = (date) => {
  if (!date) return "";
  const formattedDate = dayjs(date);
  return formattedDate.isValid() ? formattedDate.format("MM-DD-YYYY") : "";
};

export default function SignupBot() {
  const [entries, setEntries] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [flag, setFlag] = useState(false);
  const [refreshUser, setRefreshUser] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
const [snackbarMessage, setSnackbarMessage] = useState('');
  const userId = useSelector((state) => state.user?.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logindetails = useSelector((state) => state.bot.getLoginBot);
  const botResponse = useSelector((state) => state.bot.getBots);
  const loginid = userId?.id || [];
  const email = userId?.email || [];

  // const usersWithId9 = logindetails.filter((item) => item.userId === loginid);
  // console.log("userswith9", usersWithId9);

  useEffect(() => {
    dispatch(getBotLoginAction());
  }, [dispatch]);

  useEffect(() => {
    if (logindetails && logindetails.length > 0) {
      const userFound = logindetails.some((entry) => entry.userId === loginid);
      setFlag(userFound);
    }
    dispatch(getBotByUserIdAction(loginid));
  }, [logindetails, loginid]);

  useEffect(() => {
    let dynamicEntries = [];

    if (botResponse && botResponse.length > 0) {
      // Process existing entries (up to 2)
      const existingEntries = botResponse.slice(0, 2).map((entry, index) => ({
        ...entry,
        originalId: entry.id,
        id: entry.id,
        displayNumber: index + 1,
        // startDate: entry.startDate
        //   ? new Date(entry.startDate).toISOString().split("T")[0]
        //   : "",
        startDate:entry.startDate,
        checked: true,
        isEditing: false,
        isPrefilled: true,
        status: entry.status || "",
        isReadOnly: false, // Not read-only for all fields
        carrierNameReadOnly: true, // Only carrier name is read-only
        carrierName: index === 0 ? "Progressive" : "UAIC",
      }));

      // Fill remaining slots up to 2 with preset entries
      const remainingCount = 2 - existingEntries.length;
      const emptyEntries = Array.from({ length: remainingCount }, (_, i) =>
        createEmptyEntry(existingEntries.length + i)
      );

      dynamicEntries = [...existingEntries, ...emptyEntries];
    } else {
      // If no entries exist, create 2 preset ones
      dynamicEntries = Array.from({ length: 2 }, (_, index) =>
        createEmptyEntry(index)
      );
    }

    // Always combine with static entries to ensure 4 total entries
    setEntries([...dynamicEntries, ...staticEntries]);
  }, [botResponse]);

  // const handleBotLoginSubmit = async (formData) => {
  //   try {
  //     // First save the bot signup data
  //     await dispatch(savebotsignupAction(formData));

  //     // Then trigger the saveAll functionality
  //     const selectedEntries = entries
  //       .filter((entry) => entry.checked && !entry.isReadOnly)
  //       .map((entry) => ({
  //         carrierName: entry.carrierName,
  //         startDate: entry.startDate,
  //         carrierAgencyCode: entry.carrierAgencyCode,
  //         carrierAgencyUserId: entry.carrierAgencyUserId,
  //         carrierAgencyUserPassword: entry.carrierAgencyUserPassword,
  //         status: entry.status,
  //         purchasedByUserId: loginid,
  //         purchasedByUserEmail: email,
  //       }));

  //     if (selectedEntries.length > 0) {
  //       await dispatch(
  //         savebotAction(selectedEntries, (response) => {
  //           if (response) {
  //             // window.location.reload();
  //             dispatch(getBotByUserIdAction(loginid));
  //           }
  //         })
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error in form submission:", error);
  //   }
  // };

  const handleBotLoginSubmit = async (formData) => {
    try {
      // Validate formData before proceeding
      const hasEmptyFieldInFormData = Object.values(formData).some((value) => !value);

      if (hasEmptyFieldInFormData) {
        // Show error message if validation fails
        setRefreshUser(false);
        setSnackbarMessage('Please fill out all required fields before submitting.');
        setSnackbarOpen(true);
        return false; // Exit the function to prevent submission
      }



      // Then trigger the saveAll functionality
      const selectedEntries = entries
        .filter((entry) => entry.checked && !entry.isReadOnly)
        .map((entry) => ({
          carrierName: entry.carrierName,
          startDate: entry.startDate,
          carrierAgencyCode: entry.carrierAgencyCode,
          carrierAgencyUserId: entry.carrierAgencyUserId,
          carrierAgencyUserPassword: entry.carrierAgencyUserPassword,
          status: entry.status,
          purchasedByUserId: loginid,
          purchasedByUserEmail: email,
        }));

      // Validation: Check if any selectedEntries fields are empty
      const hasEmptyFieldInSelectedEntries = selectedEntries.some((entry) => {
        return (
          !entry.carrierName ||
          !entry.startDate ||
          // !entry.carrierAgencyCode ||
          !entry.carrierAgencyUserId ||
          !entry.carrierAgencyUserPassword
        );
      });


      if (hasEmptyFieldInSelectedEntries) {
        // Show error message if validation fails
        setRefreshUser(false);
        setSnackbarMessage('Please fill out all required fields in the entries before saving.');
        setSnackbarOpen(true);
        return; // Exit the function to prevent saving
      }
      setRefreshUser(true);
      await dispatch(savebotsignupAction(formData));


      // Proceed with saving if validation is successful
      if (selectedEntries.length > 0) {
        setRefreshUser(true);
        await dispatch(
          savebotAction(selectedEntries, (response) => {
            if (response) {
              dispatch(getBotByUserIdAction(loginid));

            }
          })
        );
      }
    } catch (error) {
      console.error("Error in form submission:", error);
    }
  };


  const handleSave = (id) => {
    const entryToUpdate = entries.find((entry) => entry.id === id);

    // Only process save for non-static entries
    if (
      entryToUpdate &&
      !entryToUpdate.isReadOnly &&
      entryToUpdate.originalId
    ) {
      const updatedEntry = {
        id: entryToUpdate.originalId,
        carrierName: entryToUpdate.carrierName,
        startDate: formatDate(entryToUpdate.startDate),
        carrierAgencyCode: entryToUpdate.carrierAgencyCode,
        carrierAgencyUserId: entryToUpdate.carrierAgencyUserId,
        carrierAgencyUserPassword: entryToUpdate.carrierAgencyUserPassword,
        status: entryToUpdate.status || "Active",
        purchasedByUserId: loginid,
        purchasedByUserEmail: email,
      };

      try {
        // dispatch(
        //   updateBotAction(updatedEntry, (response) => {
        //     // console.log("Bot data updated successfully:", response);
        //     dispatch(getBotByUserIdAction(loginid));
        //   })
        // );
        dispatch(updateBotAction(updatedEntry));
        dispatch(getBotByUserIdAction(loginid));
        // setSnackbarMessage('Data updated successfully!');
        // setSnackbarOpen(true);
        window.location.reload();
        setSnackbarMessage('Data updated successfully!');
        setSnackbarOpen(true);
      } catch (error) {
        console.error("Error in updateBotAction:", error);
      }
    }

    setEntries((prevEntries) =>
      prevEntries.map((entry) =>
        entry.id === id ? { ...entry, isEditing: false } : entry
      )
    );
  };

  const handleInputChange = (id, field, value) => {
    const entry = entries.find((e) => e.id === id);
    if (entry?.isReadOnly) return;

    // Don't allow carrier name changes for first two entries
    if (field === "carrierName" && entry?.carrierNameReadOnly) return;

    const lettersOnly = /^[A-Za-z]*$/;
    const numbersOnly = /^[0-9]*$/;
    const maxLength = 20;

    if (field === "startDate") {
      setEntries((prevEntries) =>
        prevEntries.map((entry) =>
          entry.id === id ? { ...entry, [field]: value } : entry
        )
      );
      return;
    }

    // Special handling for status changes in dynamic entries
    if (field === "status") {
      setEntries((prevEntries) =>
        prevEntries.map((entry) => {
          if (entry.id === id) {
            const isActivated = value === "Active";
            // For prefilled/dynamic entries, update both status and checked
            if (!entry.isReadOnly) {
              // If the entry is prefilled and has an originalId, update it in the backend
              if (entry.isPrefilled && entry.originalId) {
                const updatedEntry = {
                  id: entry.originalId,
                  carrierName: entry.carrierName,
                  startDate: formatDate(entry.startDate),
                  carrierAgencyCode: entry.carrierAgencyCode,
                  carrierAgencyUserId: entry.carrierAgencyUserId,
                  carrierAgencyUserPassword: entry.carrierAgencyUserPassword,
                  status: value,
                  purchasedByUserId: loginid,
                  purchasedByUserEmail: email,
                };

                dispatch(
                  updateBotAction(updatedEntry, (response) => {
                    dispatch(getBotByUserIdAction(loginid));
                  })
                );
              }
              return {
                ...entry,
                [field]: value,
                checked: isActivated, // Automatically check/uncheck based on Active status
              };
            }
            return { ...entry, [field]: value };
          }
          return entry;
        })
      );
      return;
    }

    if (field === "carrierName") {
      if (!lettersOnly.test(value) || value.length > maxLength) return;
    } else if (field === "carrierAgencyCode") {
      if (!numbersOnly.test(value) || value.length > maxLength) return;
    }

    setEntries((prevEntries) =>
      prevEntries.map((entry) =>
        entry.id === id ? { ...entry, [field]: value } : entry
      )
    );
  };

  // Also update the handleCheckboxChange function to sync status with checkbox
  const handleCheckboxChange = (id) => {
    setEntries((prevEntries) =>
      prevEntries.map((entry) => {
        if (entry.id === id && !entry.isReadOnly) {
          const newChecked = !entry.checked;
          // Update status based on checkbox state for prefilled entries
          if (entry.isPrefilled) {
            const newStatus = newChecked ? "Active" : "Inactive";
            // If the entry is prefilled and has an originalId, update it in the backend
            if (entry.originalId) {
              const updatedEntry = {
                id: entry.originalId,
                carrierName: entry.carrierName,
                startDate: formatDate(entry.startDate),
                carrierAgencyCode: entry.carrierAgencyCode,
                carrierAgencyUserId: entry.carrierAgencyUserId,
                carrierAgencyUserPassword: entry.carrierAgencyUserPassword,
                status: newStatus,
                purchasedByUserId: loginid,
                purchasedByUserEmail: email,
              };

              dispatch(
                updateBotAction(updatedEntry, (response) => {
                  dispatch(getBotByUserIdAction(loginid));
                })
              );
            }
            return {
              ...entry,
              checked: newChecked,
              status: newStatus,
            };
          }
          return { ...entry, checked: newChecked };
        }
        return entry;
      })
    );
  };

  const handleEdit = (id) => {
    const entry = entries.find((e) => e.id === id);
    if (entry?.isReadOnly) return;

    setEntries((prevEntries) =>
      prevEntries.map((entry) =>
        entry.id === id && entry.isPrefilled
          ? { ...entry, isEditing: true }
          : entry
      )
    );
  };

  const handleSaveAll = () => {
    const selectedEntries = entries
      .filter((entry) => entry.checked && !entry.isReadOnly)
      .map((entry) => ({
        carrierName: entry.carrierName,
        startDate: formatDate(entry.startDate),
        carrierAgencyCode: entry.carrierAgencyCode,
        carrierAgencyUserId: entry.carrierAgencyUserId,
        carrierAgencyUserPassword: entry.carrierAgencyUserPassword,
        status: entry.status || "Active",
        purchasedByUserId: loginid,
        purchasedByUserEmail: email,
        id: entry.originalId || null, // Include originalId if it exists
      }));

    // Validation: Check if any field is empty
    const hasEmptyField = selectedEntries.some((entry) => {
      return (
        !entry.carrierName ||
        !entry.startDate ||
        // !entry.carrierAgencyCode ||
        !entry.carrierAgencyUserId ||
        !entry.carrierAgencyUserPassword
      );
    });

    if (hasEmptyField) {
      // Show error message if validation fails
      setSnackbarMessage('Please fill out all required fields before saving.');
      setSnackbarOpen(true);
      return; // Exit the function to prevent saving/updating
    }

    const newEntries = selectedEntries.filter((entry) => !entry.id); // New entries (without originalId)
    const existingEntries = selectedEntries.filter((entry) => entry.id); // Prefilled entries (with originalId)

    // Save new entries if there are no validation errors
    if (newEntries.length > 0) {
      dispatch(
        savebotAction(newEntries, (response) => {
          if (response) {
            dispatch(getBotByUserIdAction(loginid));
            setSnackbarMessage('Data saved successfully!');
            setSnackbarOpen(true);
          }
        })
      );
    }

    // Update existing entries if there are no validation errors
    existingEntries.forEach((entry) => {
      dispatch(updateBotAction(entry));
      setSnackbarMessage('Data updated successfully!');
      setSnackbarOpen(true);
    });
  };


  // const handleSaveAll = () => {
  //   const selectedEntries = entries
  //     .filter((entry) => entry.checked && !entry.isReadOnly)
  //     .map((entry) => ({
  //       carrierName: entry.carrierName,
  //       startDate: formatDate(entry.startDate),
  //       carrierAgencyCode: entry.carrierAgencyCode,
  //       carrierAgencyUserId: entry.carrierAgencyUserId,
  //       carrierAgencyUserPassword: entry.carrierAgencyUserPassword,
  //       status: entry.status || "Active",
  //       purchasedByUserId: loginid,
  //       purchasedByUserEmail: email,
  //       id: entry.originalId || null, // Include originalId if it exists
  //     }));

  //   const newEntries = selectedEntries.filter((entry) => !entry.id); // New entries (without originalId)
  //   const existingEntries = selectedEntries.filter((entry) => entry.id); // Prefilled entries (with originalId)

  //   // Save new entries
  //   if (newEntries.length > 0) {
  //     dispatch(
  //       savebotAction(newEntries, (response) => {
  //         if (response) {
  //           dispatch(getBotByUserIdAction(loginid));
  //           setSnackbarMessage('Data saved successfully!');
  //           setSnackbarOpen(true);
  //         }
  //       })
  //     );
  //   }

  //   existingEntries.forEach((entry) => {
  //     dispatch(updateBotAction(entry));
  //     setSnackbarMessage('Data updated successfully!');
  //           setSnackbarOpen(true);
  //   });
  // };


  // const handleSaveAll = () => {
  //   // Only save non-static entries that are checked
  //   const selectedEntries = entries
  //     .filter((entry) => entry.checked && !entry.isReadOnly)
  //     .map((entry) => ({
  //       carrierName: entry.carrierName,
  //       startDate: entry.startDate,
  //       carrierAgencyCode: entry.carrierAgencyCode,
  //       carrierAgencyUserId: entry.carrierAgencyUserId,
  //       carrierAgencyUserPassword: entry.carrierAgencyUserPassword,
  //       status: entry.status,
  //       purchasedByUserId: loginid,
  //       purchasedByUserEmail: email,
  //     }));

  //   if (selectedEntries.length > 0) {
  //     dispatch(
  //       savebotAction(selectedEntries, (response) => {
  //         // console.log("Bot data saved successfully:", response);
  //         if (response) {
  //           // window.location.reload();
  //         }
  //       })
  //     );
  //     // window.location.reload();
  //   }
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("Saving entries:", entries);
  };

  const handleSignup = () => {
    setDialogOpen(true);
    setFormVisible(true);
  };

  const handleCancel = () => {
    navigate("/home");
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setFormVisible(false);
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <Box sx={{ maxWidth: "1024px", padding: "16px", ml: "32px" }}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs>
            <Typography
              variant="h5"
              component="h1"
              sx={{
                fontFamily: "Poppins",
                fontSize: "22px",
                fontWeight: 600,
                lineHeight: "19.6px",
                textAlign: "left",
              }}
            >
              Signup Bot
            </Typography>
          </Grid>
          {/* <Grid item xs={6} container justifyContent="center">
            <Button onClick={handleSignup}>View CRM</Button>
          </Grid> */}
          {flag && (
            <Grid item xs={6} container justifyContent="center">
              <Button onClick={handleSignup}>CRM</Button>
            </Grid>
          )}
        </Grid>
      </Box>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={0} sx={{ pl: "32px" , pr: "32px" }}>
          {entries.map((entry) => (
            <Grid item xs={12} key={entry.id} spacing={0}>
              <Card variant="outlined" spacing={0}>
                <CardContent sx={{ marginTop: "0px", paddingTop: "0px" }}>
                  <Grid container spacing={1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={entry.checked}
                          onChange={() => handleCheckboxChange(entry.id)}
                        />
                      }
                    />
                    <Grid item xs={12} sm={6} md={1.8} style={{backgroundColor:"#00000"}}>
                      <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                        Carrier Name
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        value={entry.carrierName}
                        variant="outlined"
                        // required
                        onChange={(e) =>
                          handleInputChange(
                            entry.id,
                            "carrierName",
                            e.target.value
                          )
                        }
                        InputProps={{
                          readOnly:
                            entry.carrierNameReadOnly ||
                            (entry.isPrefilled && !entry.isEditing) ||
                            entry.isReadOnly,
                        }}
                        // InputProps={{
                        //   readOnly: entry.isPrefilled && !entry.isEditing,
                        // }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={1.8}>
                      <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                        Start Date
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          format="MM-DD-YYYY"
                          // value={
                          //   entry.startDate
                          //     ? dayjs(entry.startDate, "YYYY-MM-DD")
                          //     : null
                          // }
                          value={
                            entry.startDate ? dayjs(entry.startDate) : null
                          }
                          maxDate={dayjs("2040-04-04")}
                          minDate={dayjs("1940-04-04")}
                          // onChange={(date) =>
                          //   handleInputChange(entry.id, "startDate", date)
                          // }
                          onChange={(date) =>
                            handleInputChange(
                              entry.id,
                              "startDate",
                              date ? formatDate(date) : ""
                            )
                          }
                          // onChange={(date) =>
                          //   handleDateChange(entry.id, "startDate",date)
                          // }
                          disabled={entry.isPrefilled && !entry.isEditing}
                          slotProps={{
                            textField: {
                              size: "small",
                              fullWidth: true,
                              sx: {
                                border: "none",
                                backgroundColor: "transparent",
                                alignSelf: "stretch",
                                fontFamily: '"Poppins", sans-serif',
                                fontWeight: "500",
                                fontSize: "16px",
                                zIndex: "1",
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "#242929 !important",
                                  },
                                },
                              },
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} sm={6} md={1.8}>
                      <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                        Carrier Agency Code
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        value={entry.carrierAgencyCode}
                        variant="outlined"
                        onChange={(e) =>
                          handleInputChange(
                            entry.id,
                            "carrierAgencyCode",
                            e.target.value
                          )
                        }
                        InputProps={{
                          readOnly: entry.isPrefilled && !entry.isEditing,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={1.8}>
                      <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                        Carrier Agency User Id
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        value={entry.carrierAgencyUserId}
                        variant="outlined"
                        onChange={(e) =>
                          handleInputChange(
                            entry.id,
                            "carrierAgencyUserId",
                            e.target.value
                          )
                        }
                        InputProps={{
                          readOnly: entry.isPrefilled && !entry.isEditing,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={1.8}>
                      <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                        Carrier Agency Password
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        type="password"
                        value={entry.carrierAgencyUserPassword}
                        variant="outlined"
                        onChange={(e) =>
                          handleInputChange(
                            entry.id,
                            "carrierAgencyUserPassword",
                            e.target.value
                          )
                        }
                        InputProps={{
                          readOnly: entry.isPrefilled && !entry.isEditing,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={1.8}>
                      <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                        Status
                      </Typography>
                      {entry.isReadOnly ? ( // Check if the entry is read-only
                        // <Typography fullWidth size="small" variant="outlined"  sx={{ marginBottom: "8px" }}>
                        //   {entry.status || "Coming Soon"} {/* Display the status as text */}
                        // </Typography>
                        <TextField
                          fullWidth
                          size="small"
                          variant="outlined"
                          value={
                            entry.isReadOnly
                              ? entry.status || "Coming Soon"
                              : entry.status
                          } // Use status or default if read-only
                          onChange={(e) => {
                            if (!entry.isReadOnly) {
                              handleInputChange(
                                entry.id,
                                "status",
                                e.target.value
                              );
                            }
                          }}
                          InputProps={{
                            readOnly: entry.isReadOnly, // Make it read-only if applicable
                          }}
                          sx={{ marginBottom: "8px" }} // Add margin for spacing
                        />
                      ) : (
                        <FormControl fullWidth size="small" variant="outlined">
                          <Select
                            value={entry.status || "coming soon"} // Default value if status is not set
                            onChange={(e) =>
                              handleInputChange(
                                entry.id,
                                "status",
                                e.target.value
                              )
                            }
                            disabled={entry.isPrefilled && !entry.isEditing} // Disable if not editing
                          >
                            <MenuItem value="Active">Active</MenuItem>
                            <MenuItem value="Inactive">Inactive</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6} md={0.5}>
                      <CardHeader
                        // title={`#${entry.displayNumber}`} // Use displayNumber here instead of id
                        action={
                          entry.isPrefilled && !entry.isReadOnly ? (
                            entry.isEditing ? (
                              <IconButton onClick={() => handleSave(entry.id)}>
                                <SaveIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() => {
                                  // Only allow editing if not a read-only entry
                                  if (!entry.isReadOnly) {
                                    handleEdit(entry.id);
                                  }
                                }}
                                disabled={entry.isReadOnly} // Disable button for read-only entries
                              >
                                <EditIcon />
                              </IconButton>
                            )
                          ) : null
                        }
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "19.6px",
            textAlign: "center",
            marginTop: "30px"
          }}
        >
          Minimum Cost $100 per month, Cancel Anytime *2 cent for each
          information
        </Typography>

        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
            marginTop: 4,
            mr: "32px"
          }}
        >
          <Button id="submitbutton" color="primary" onClick={handleCancel}>
            Cancel
          </Button>

          <Button
            id="actionButton"
            type="submit"
            onClick={flag ? handleSaveAll : handleSignup}
            variant="outlined"
            color="secondary"
          >
            {flag ? "Save" : "Signup"}
          </Button>
        </Grid>
      </form>

      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Signup Confirmation</DialogTitle>
        <DialogContent>
          <Botlogin
            toggleform={handleCloseDialog}
            onSubmitSuccess={handleBotLoginSubmit}
            logindetails={logindetails}
            loginid={loginid}
            refreshUser={refreshUser}
          />
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormLabelText from '../../../common/FormLabelText';
import { FormControl, Button, FormLabel, Checkbox, Grid, OutlinedInput, Select, MenuItem, Box, FormControlLabel, FormHelperText } from '@mui/material';

const additionalInformationFields = [
    // Add your JSON configuration here...
    {
        fieldName: 'doNotEmail',
        label: 'Do Not Email',
        type: 'checkbox',
        defaultChecked: false,
        //disabled: true,
        id:"donotemail"
    },
    {
        fieldName: 'doNotTest',
        label: 'Do Not Text',
        type: 'checkbox',
        defaultChecked: false,
       // disabled: true,
       id:"donottest"
    },
    {
        fieldName: 'doNotCall',
        label: 'Do Not Call',
        type: 'checkbox',
        defaultChecked: false,
       // disabled: true,
       id:"donotcall"
    },
    {
        fieldName: 'doNotMail',
        label: 'Do Not Mail',
        type: 'checkbox',
        defaultChecked: false,
       // disabled: true,
       id:"donotmail"
    },
    {
        fieldName: 'doNotMarket',
        label: 'Do Not Market',
        type: 'checkbox',
        defaultChecked: false,
       // disabled: true,
       id:"donotmarket"
    },
    {
        fieldName: 'doNotCaptureEmail',
        label: 'Do Not Capture Email',
        type: 'checkbox',
        defaultChecked: false,
        id:"donotcaptureemail"
    },
    {
        fieldName: 'unbearableMail',
        label: 'Unbearable Mail',
        type: 'checkbox',
        defaultChecked: false,
        id:"unbearableemail"
    },
    {
        fieldName: 'badCell',
        label: 'Bad Cell',
        type: 'checkbox',
        defaultChecked: false,
        id:"badcell"
    },
    {
        fieldName: 'bad1',
        label: 'Bad',
        type: 'checkbox',
        defaultChecked: false,
        id:"bad1"
    },
    {
        fieldName: 'bad2',
        label: 'Bad',
        type: 'checkbox',
        defaultChecked: false,
        id:"bad2"
    },
    {
        fieldName: 'bad3',
        label: 'Bad',
        type: 'checkbox',
        defaultChecked: false,
        id:"bad3"
    },
    {
        fieldName: 'unbearableEmail1',
        label: 'Unbearable Email 1',
        type: 'checkbox',
        defaultChecked: false,
        id:"unbearbleemail1"
    },
    {
        fieldName: 'unbearableEmail2',
        label: 'Unbearable Email 2',
        type: 'checkbox',
        defaultChecked: false,
        id:"unbearbleemail2"
    },
    {
        fieldName: 'type',
        label: 'Type',
        type: 'dropdown',
        options: ['Customer', 'Prospect'],
        rules: {
            required: 'Please select type',
        },
        id:"type"
    },
    {
        fieldName: 'subStatus',
        label: 'Sub Status',
        type: 'dropdown',
        options: ['Status', 'Status 1'],
        rules: {
            required: 'Please select Sub Status',
          },
          id:"substatus"
    },
    {
        fieldName: 'customerAgentOfRecord',
        label: 'Customer Agent of Record *',
        id:"customerAgentofrecord",
        type: 'dropdown',
        options: ['ADISLAY FERRAZ', 'ADRIANA MEDEROS', 'ADRIANA PINTO', 'AGUSTIN CABELLO', 'ALAN LEYVA'],
        rules: {
            required: 'Customer Agent is required',
        },
    },
    {
        fieldName: 'customerCsr',
        label: 'Customer CSR',
        type: 'dropdown',
        options: ['Task Administrator', 'ALAN LEYVA', 'AMALIE OLIVA'],
        rules: {
            required: 'Customer CSR is required',
        },
        id:"customercsr"
    },
    // {
    //     fieldName: 'language',
    //     label: 'Language',
    //     type: 'dropdown',
    //     options: ['English', 'Spanish', 'French'],
    // },
    {
        fieldName: 'source',
        label: 'Source *',
        type: 'dropdown',
        options: ['DOWNLOAD', 'GR Acquisition', 'JAV Acquisition', 'MARINA BOAT CAMPAIGN '],
        rules: {
            required: 'Source is required',
        },
        id:"source"
    },
    {
        fieldName: 'subSource',
        label: 'Sub Source',
        type: 'dropdown',
        options: ['SubSource1', 'SubSource2', 'SubSource3'],
        id:"subsource"
    },
    // {
    //     fieldName: 'add_members',
    //     label: 'Add as HH Members',
    //     type: 'checkbox',
    //     defaultChecked: false,
    // },
    {
        fieldName: 'website_access',
        label: 'Website Access',
        type: 'checkbox',
        defaultChecked: false,
        id:"websiteaccess"
    },
    {
        fieldName: 'username',
        label: 'Username *',
        type: 'text',
        placeholder: 'Enter the value',
        rules: {
            required: 'Please enter the username',
          },
          id:"username"
    },
    {
        fieldName: 'password',
        label: 'Password *',
        type: 'password',
        id:"password",
        placeholder: 'Enter the the password',
        rules: {
            required: 'Please enter the password',
            min: { value: 8, message: 'Password atleast 8 charater' },
        },
    },
    // {
    //     fieldName: 'generate_credentials',
    //     label: 'Generate Credentials',
    //     type: 'button',
    //     variant: 'outlined',
    //     disabled: true,
    // },
    {
        fieldName: 'sendLoginInfoByEmail',
        label: 'Send login info by Email',
        type: 'checkbox',
        defaultChecked: true,
        id:"sendlogininfo"
    },
];

const AdditionalInformation = () => {
    const { control, watch, formState: { errors } } = useFormContext();
    const username = watch('username');
    const password = watch('password');

    return (
        <>
            <Box sx={{ border: '#ccc solid 1px', borderRadius: '4px', padding: '20px', margin: '20px 0 0 0', position: 'relative' }}>
                <FormLabelText labelText="Additional Information" />
                <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{margin: '0'}}>
                    {additionalInformationFields.slice(0, 13).map(({ fieldName, label, rules, type, options, defaultChecked, placeholder, variant, disabled }) => (
                        <Grid key={fieldName} item xs={12} sm={6} md={2} sx={{ margin: '0 !important', padding: '0 25px !important'}}>
                            <FormControl fullWidth margin="normal" error={!!errors[fieldName]}>
                                {type === 'checkbox' ? (
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name={fieldName}
                                                control={control}
                                                rules={rules}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        {...field}
                                                        defaultChecked={defaultChecked}
                                                        disabled={disabled}
                                                    />
                                                )}
                                            />
                                        }
                                        label={label}
                                    />
                                ) : type === 'dropdown' ? (
                                    <>
                                        <FormLabel>{label}</FormLabel>
                                        <Controller
                                            name={fieldName}
                                            control={control}
                                            rules={rules}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    error={!!errors[fieldName]}
                                                >
                                                    <MenuItem value="">Select {label}</MenuItem>
                                                    {options.map((option, index) => (
                                                        <MenuItem key={index} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                    </>
                                ) : type === 'text' || type === 'password' ? (
                                    <>
                                        <FormLabel>{label}</FormLabel>
                                        <Controller
                                            name={fieldName}
                                            control={control}
                                            rules={rules}
                                            render={({ field }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    placeholder={placeholder}
                                                    type={type}
                                                />
                                            )}
                                        />
                                    </>
                                ) : type === 'button' ? (
                                    <Button variant={variant} disabled={disabled || !username || !password}>
                                        {label}
                                    </Button>
                                ) : null}
                                <FormHelperText>{errors[fieldName]?.message}</FormHelperText>
                            </FormControl>
                        </Grid>
                    ))}
                </Grid>
                <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{margin: '0'}}>    
                    {additionalInformationFields.slice(13, 20).map(({ fieldName, label, rules, type, options, defaultChecked, placeholder, variant, disabled }) => (
                        <Grid key={fieldName} item xs={12} sm={6} md={3} sx={{ margin: '0 !important', padding: '0 25px !important'}}>
                            <FormControl fullWidth margin="normal" error={!!errors[fieldName]}>
                                {type === 'checkbox' ? (
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name={fieldName}
                                                control={control}
                                                rules={rules}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        {...field}
                                                        defaultChecked={defaultChecked}
                                                        disabled={disabled}
                                                    />
                                                )}
                                            />
                                        }
                                        label={label}
                                    />
                                ) : type === 'dropdown' ? (
                                    <>
                                        <FormLabel>{label}</FormLabel>
                                        <Controller
                                            name={fieldName}
                                            control={control}
                                            rules={rules}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    error={!!errors[fieldName]}
                                                >
                                                    <MenuItem value="">Select {label}</MenuItem>
                                                    {options.map((option, index) => (
                                                        <MenuItem key={index} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                    </>
                                ) : type === 'text' || type === 'password' ? (
                                    <>
                                        <FormLabel>{label}</FormLabel>
                                        <Controller
                                            name={fieldName}
                                            control={control}
                                            rules={rules}
                                            render={({ field }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    placeholder={placeholder}
                                                    type={type}
                                                />
                                            )}
                                        />
                                    </>
                                ) : type === 'button' ? (
                                    <Button variant={variant} disabled={disabled || !username || !password}>
                                        {label}
                                    </Button>
                                ) : null}
                                <FormHelperText>{errors[fieldName]?.message}</FormHelperText>
                            </FormControl>
                        </Grid>
                    ))}
                </Grid>

                <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{margin: '0'}}>    
                    {additionalInformationFields.slice(20, 22).map(({ fieldName, label, rules, type, options, defaultChecked, placeholder, variant, disabled }) => (
                        <Grid key={fieldName} item xs={12} sm={6} md={3} sx={{ margin: '0 !important', padding: '0 25px !important'}}>
                            <FormControl fullWidth margin="normal" error={!!errors[fieldName]}>
                                {type === 'checkbox' ? (
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name={fieldName}
                                                control={control}
                                                rules={rules}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        {...field}
                                                        defaultChecked={defaultChecked}
                                                        disabled={disabled}
                                                    />
                                                )}
                                            />
                                        }
                                        label={label}
                                    />
                                ) : type === 'dropdown' ? (
                                    <>
                                        <FormLabel>{label}</FormLabel>
                                        <Controller
                                            name={fieldName}
                                            control={control}
                                            rules={rules}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    error={!!errors[fieldName]}
                                                >
                                                    <MenuItem value="">Select {label}</MenuItem>
                                                    {options.map((option, index) => (
                                                        <MenuItem key={index} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                    </>
                                ) : type === 'text' || type === 'password' ? (
                                    <>
                                        <FormLabel>{label}</FormLabel>
                                        <Controller
                                            name={fieldName}
                                            control={control}
                                            rules={rules}
                                            render={({ field }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    placeholder={placeholder}
                                                    type={type}
                                                />
                                            )}
                                        />
                                    </>
                                ) : type === 'button' ? (
                                    <Button variant={variant} disabled={disabled || !username || !password}>
                                        {label}
                                    </Button>
                                ) : null}
                                <FormHelperText>{errors[fieldName]?.message}</FormHelperText>
                            </FormControl>
                        </Grid>
                    ))}
                </Grid>

                <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{margin: '0'}}>    
                    {additionalInformationFields.slice(22, 24).map(({ fieldName, label, rules, type, defaultChecked, placeholder, variant, disabled }) => (
                        <Grid key={fieldName} item xs={12} sm={6} md={3} sx={{ margin: '0 !important', padding: '0 25px !important'}}>
                            <FormControl fullWidth margin="normal" error={!!errors[fieldName]}>
                                {type === 'checkbox' ? (
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name={fieldName}
                                                control={control}
                                                rules={rules}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        {...field}
                                                        defaultChecked={defaultChecked}
                                                        disabled={disabled}
                                                    />
                                                )}
                                            />
                                        }
                                        label={label}
                                    />
                                ) : type === 'text' || type === 'password' ? (
                                    <>
                                        <FormLabel>{label}</FormLabel>
                                        <Controller
                                            name={fieldName}
                                            control={control}
                                            rules={rules}
                                            render={({ field }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    placeholder={placeholder}
                                                    type={type}
                                                />
                                            )}
                                        />
                                    </>
                                )  : null}
                                <FormHelperText>{errors[fieldName]?.message}</FormHelperText>
                            </FormControl>
                        </Grid>
                    ))}
                </Grid>

                <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{margin: '0'}}>    
                    {additionalInformationFields.slice(24, 25).map(({ fieldName, label, rules, type, options, defaultChecked, placeholder, variant, disabled }) => (
                        <Grid key={fieldName} item xs={12} sm={6} md={3} sx={{ margin: '0 !important', padding: '0 25px !important'}}>
                            <FormControl fullWidth margin="normal" error={!!errors[fieldName]}>
                                {type === 'button' ? (
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name={fieldName}
                                                control={control}
                                                rules={rules}
                                                render={({ field }) => (
                                                    <Button variant={variant} disabled={disabled || !username || !password}>
                                                        {label}
                                                    </Button>
                                                )}
                                            />
                                        }
                                    />                                
                                ) : null}
                                <FormHelperText>{errors[fieldName]?.message}</FormHelperText>
                            </FormControl>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
};

export default AdditionalInformation;

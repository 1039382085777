import React from 'react';
import { Typography, CircularProgress, Alert, lighten } from '@mui/material';
import {
  DataGrid, GridToolbar,
  gridFilteredSortedRowIdsSelector,
  selectedGridRowsSelector,
} from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((customTheme) => ({
    header: {
      margin: ".5em 0 !important",
    },
    muiEven: {
      backgroundColor: lighten(customTheme?.palette?.secondary?.main, 0.95),
    },
    root: {
      '& .MuiDataGrid-columnHeaders': {
          backgroundColor: lighten(customTheme?.palette?.primary?.main, 0.85),
      },
      '& .MuiDataGrid-row:hover': {
          backgroundColor: lighten(customTheme?.palette?.secondary?.main, 0.75),
      },
      color: `${customTheme?.palette?.secondary?.main} !important`,
    }
  }));

const CustomDataGrid = ({
    gridData,
    columns,
    error,
    loading,
}) => {
  const classes = useStyles();

  return (
    <>
    {loading ? <CircularProgress size={48} color="primary"/> : <DataGrid
          rows={gridData || []}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          checkboxSelection
          disableRowSelectionOnClick
          className={classes.root}
          slots={{ 
                toolbar: GridToolbar,
                noRowsOverlay: () => (
                    <div style={{ textAlign: 'center', fontSize: '16px', paddingTop: '50px' }}>
                    {error && (
                        <Alert severity='error'>
                          <Typography color='error'>{error}</Typography>
                        </Alert>
                    )}
                    </div>
                ),
            }}
          slotProps={{
            toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } },
          }}
          getRowClassName={(params) => {
            return params.indexRelativeToCurrentPage % 2 === 0 ? classes.muiEven : ''
            }
          }
        />}
    </>
  );
}

export default CustomDataGrid;

function getSelectedRowsToExport({ apiRef }) {
    const selectedRowIds = selectedGridRowsSelector(apiRef);
    if (selectedRowIds.size > 0) {
      return Array.from(selectedRowIds.keys());
    }
  
    return gridFilteredSortedRowIdsSelector(apiRef);
  };

import CreatePolicy from "../../../../components/SidebarLayout/AllAccounts/CreatePolicy";
import { useForm, FormProvider } from 'react-hook-form';


const AddPolicy = () => {
    const methods = useForm();

    return(
        <>
            <FormProvider {...methods}>
                <CreatePolicy />
            </FormProvider>          
        </>
    )
}
export default AddPolicy;
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
  Typography,
  Grid,
  CircularProgress,
} from '@mui/material';
import Dropbox from './Dropbox';
import { saveUploadAttachmentAction } from '../../redux/actions/uploadAttachmentActions';

const UploadForm = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState(''); // Automatically set name from file
  const [type, setType] = useState('');
  const [policy, setPolicy] = useState('');
  const [fileObjects, setFileObjects] = useState([]);
  const [fileType, setFileType] = useState('');
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state?.user?.user?.id);

  const handleAdd = (newFileObjs) => {
    const file = newFileObjs[0].file;
    const fileType = file.type.split('/')[1];

    // Set file name and type automatically
    setName(file.name);
    setFileType(fileType === 'pdf' || fileType === 'jpeg' || fileType === 'png' ? fileType : 'docx');
    setFileObjects((prev) => [...prev, ...newFileObjs]);
  };

  const handleDelete = (deleteFileObj) => {
    setFileObjects(fileObjects.filter((fileObject) => fileObject.file !== deleteFileObj.file));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (fileObjects.length === 0) {
      alert("Please upload a file");
      return;
    }

    setLoading(true);

    const cd = new Date();
    const dd = cd.toISOString().split('T')[0];

    const attachmentInfo = {
      name, // File name set automatically
      fileType,
      type,
      policy,
      date: dd,
      userId: user,
    };

    const formData = new FormData();
    formData.append('uploadFile', fileObjects[0].file);
    formData.append('attachmentInfo', JSON.stringify(attachmentInfo));

    dispatch(saveUploadAttachmentAction(formData, () => {
      window.location.reload(); // Reload the page after successful upload
      setLoading(false);
    }));

    setType('');
    setPolicy('');
    setFileObjects([]);
  };

  return (
    <Box sx={{ p: 3, backgroundColor: '#f5f5f5', borderRadius: 2, boxShadow: 3, marginLeft: 6 }}>
      <Typography variant="h6" gutterBottom>
        Upload Document
      </Typography>
      <Dropbox
        fileObjects={fileObjects}
        handleAdd={handleAdd}
        handleDelete={handleDelete}
        sx={{ width: '100%', height: 200, mb: 3 }}
      />
      <form onSubmit={handleSubmit} id="uploadform" name="uploadForm">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="typelabel">Type</InputLabel>
              <Select
                id="typeselect"
                name="type"
                value={type}
                onChange={(e) => setType(e.target.value)}
                required
                label="Type"
              >
                <MenuItem value="Claim">Claim</MenuItem>
                <MenuItem value="ABC">Driver's License</MenuItem>
                <MenuItem value="XYZ">Cancellation</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="policylabel">Policy</InputLabel>
              <Select
                id="policyselect"
                name="policy"
                value={policy}
                onChange={(e) => setPolicy(e.target.value)}
                required
                label="Policy"
              >
                <MenuItem value="Policy1">Policy1</MenuItem>
                <MenuItem value="Policy2">Policy2</MenuItem>
                <MenuItem value="Policy3">Policy3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Button
              id="uploadbutton"
              name="upload-button"
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} />}
              sx={{ paddingX: 4 }}
            >
              {loading ? 'Uploading...' : 'Upload'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default UploadForm;




// import React, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import {
//   Box,
//   TextField,
//   MenuItem,
//   Select,
//   InputLabel,
//   FormControl,
//   Button,
//   Typography,
//   Grid,
//   CircularProgress,
// } from '@mui/material';
// import Dropbox from './Dropbox';
// import { saveUploadAttachmentAction } from '../../redux/actions/uploadAttachmentActions';

// const UploadForm = () => {
//   const dispatch = useDispatch();
//   const [name, setName] = useState('');
//   const [type, setType] = useState('');
//   const [policy, setPolicy] = useState('');
//   const [fileObjects, setFileObjects] = useState([]);
//   const [fileType, setFileType] = useState('');
//   const [loading, setLoading] = useState(false);

//   const user = useSelector((state) => state?.user?.user?.id);

//   const handleAdd = (newFileObjs) => {
//     const file = newFileObjs[0].file;
//     const fileType = file.type.split('/')[1];

//     setFileType(fileType === 'pdf' || fileType === 'jpeg' || fileType === 'png' ? fileType : 'docx');
//     setFileObjects((prev) => [...prev, ...newFileObjs]);
//   };

//   const handleDelete = (deleteFileObj) => {
//     setFileObjects(fileObjects.filter((fileObject) => fileObject.file !== deleteFileObj.file));
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();

//     if (fileObjects.length === 0) {
//       alert("Please upload a file");
//       return;
//     }

//     setLoading(true);

//     const cd = new Date();
//     const dd = cd.toISOString().split('T')[0];

//     const attachmentInfo = {
//       name,
//       fileType,
//       type,
//       policy,
//       date: dd,
//       userId: user,
//     };

//     const formData = new FormData();
//     formData.append('uploadFile', fileObjects[0].file);
//     formData.append('attachmentInfo', JSON.stringify(attachmentInfo));

//     dispatch(saveUploadAttachmentAction(formData, () => {
//       window.location.reload(); // Reload the page after successful upload
//       setLoading(false);
//     }));

//     setName('');
//     setType('');
//     setPolicy('');
//     setFileObjects([]);
//   };

//   return (
//     <Box sx={{ p: 3, backgroundColor: '#f5f5f5', borderRadius: 2, boxShadow: 3 }}>
//       <Typography variant="h6" gutterBottom>
//         Upload Document
//       </Typography>
//       <Dropbox
//         fileObjects={fileObjects}
//         handleAdd={handleAdd}
//         handleDelete={handleDelete}
//         sx={{ width: '100%', height: 200, mb: 3 }}
//       />
//       <form onSubmit={handleSubmit}>
//         <Grid container spacing={3}>
//           <Grid item xs={12} sm={6}>
//             <FormControl fullWidth>
//               <TextField
//                 label="Document Name"
//                 variant="outlined"
//                 value={name}
//                 onChange={(e) => setName(e.target.value)}
//                 required
//               />
//             </FormControl>
//           </Grid>
//           <Grid item xs={12} sm={3}>
//             <FormControl fullWidth>
//               <InputLabel>Type</InputLabel>
//               <Select
//                 value={type}
//                 onChange={(e) => setType(e.target.value)}
//                 required
//                 label="Type"
//               >
//                 <MenuItem value="Claim">Claim</MenuItem>
//                 <MenuItem value="ABC">Driver's License</MenuItem>
//                 <MenuItem value="XYZ">Cancellation</MenuItem>
//               </Select>
//             </FormControl>
//           </Grid>
//           <Grid item xs={12} sm={3}>
//             <FormControl fullWidth>
//               <InputLabel>Policy</InputLabel>
//               <Select
//                 value={policy}
//                 onChange={(e) => setPolicy(e.target.value)}
//                 required
//                 label="Policy"
//               >
//                 <MenuItem value="Policy1">Policy1</MenuItem>
//                 <MenuItem value="Policy2">Policy2</MenuItem>
//                 <MenuItem value="Policy3">Policy3</MenuItem>
//               </Select>
//             </FormControl>
//           </Grid>
//           <Grid item xs={12} sx={{ textAlign: 'center' }}>
//             <Button
//               type="submit"
//               variant="contained"
//               color="primary"
//               disabled={loading}
//               startIcon={loading && <CircularProgress size={20} />}
//               sx={{ paddingX: 4 }}
//             >
//               {loading ? 'Uploading...' : 'Upload'}
//             </Button>
//           </Grid>
//         </Grid>
//       </form>
//     </Box>
//   );
// };

// export default UploadForm;

import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Dialog,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import { getAllCustomer } from '../../../redux/actions/customerActions';
import { getDetailPolicyAction, updatePolicyAction } from '../../../redux/actions/policyActions';
import EditPolicyUserDetail from './EditPolicyUserDetail';

const poppinsFont = {
  fontFamily: '"Poppins", sans-serif',
};

const PolicyUserDetails = ({ policy_Id, selectedRowId }) => {
  const dispatch = useDispatch();
  const customerData = useSelector((state) => state.policy.allDetailPolicy) || {};
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState([]);
  
  const id = 2;

  useEffect(() => {
    if (policy_Id) {
      dispatch(getDetailPolicyAction(policy_Id));
    } else if (selectedRowId) {
      dispatch(getDetailPolicyAction(selectedRowId));
    } else {
      dispatch(getDetailPolicyAction(id));
    }
  }, [dispatch, policy_Id, selectedRowId]);

  useEffect(() => {    
    setFormData({
      firstName: customerData.firstName || '',
      lastName: customerData.lastName || '',
      dateOfBirth: customerData.dateOfBirth || '',
      gender: customerData.gender || '',
      email: customerData.email || '',
      address: customerData.address || '',
      zip: customerData.zip || '',
      city: customerData.city || '',
      state: customerData.state || '',
      yearsAtResidence: customerData.yearsAtResidence || '',
      tax: customerData.taxId || '',
      ownershipStatus: customerData.ownershipStatus || '',
      id: policy_Id
    });
  }, [customerData]);

  const handleEditClick = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => { 
    event.preventDefault();
    alert('hi')
    const updatedPolicyDetail = {...formData };
    dispatch(
      updatePolicyAction(updatedPolicyDetail)
    );

    console.log(formData, 'formData save policy form')
  }
  return (
    <TableContainer sx={{ verticalAlign: 'Top' }} component={Paper}>
      <Table sx={{ verticalAlign: 'Top' }}>
        <TableBody>
          <TableRow>
            <TableCell align="right" colSpan={3}>
              <EditIcon onClick={handleEditClick} style={{ cursor: 'pointer' }} />
            </TableCell>
          </TableRow>
          <TableRow sx={{ verticalAlign: 'Top' }}>
            <TableCell>
              <Table sx={{ verticalAlign: 'Top' }}>
                <TableRow sx={{ verticalAlign: 'Top' }}>
                  <TableCell id="firstname" sx={poppinsFont}><strong>First Name:</strong></TableCell>
                  <TableCell sx={poppinsFont}>{customerData.firstName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="lastname" sx={poppinsFont}><strong>Last Name:</strong></TableCell>
                  <TableCell sx={poppinsFont}>{customerData.lastName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="dateofBirth" sx={poppinsFont}><strong>Date of Birth:</strong></TableCell>
                  <TableCell sx={poppinsFont}>{customerData.dateOfBirth}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="gender" sx={poppinsFont}><strong>Gender:</strong></TableCell>
                  <TableCell sx={poppinsFont}>{customerData.gender}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="email" sx={poppinsFont}><strong>Email:</strong></TableCell>
                  <TableCell sx={poppinsFont}>{customerData.email}</TableCell>
                </TableRow>
              </Table>
            </TableCell>
            <TableCell>
              <Table>
                <TableRow>
                  <TableCell id="address" sx={poppinsFont}><strong>Address:</strong></TableCell>
                  <TableCell sx={poppinsFont}>{customerData.address}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="zip" sx={poppinsFont}><strong>Zip:</strong></TableCell>
                  <TableCell sx={poppinsFont}>{customerData.zip}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="city" sx={poppinsFont}><strong>City:</strong></TableCell>
                  <TableCell sx={poppinsFont}>{customerData.city}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="state" sx={poppinsFont}><strong>State:</strong></TableCell>
                  <TableCell sx={poppinsFont}>{customerData.state}</TableCell>
                </TableRow>
              </Table>
            </TableCell>
            <TableCell>
              <Table>
                <TableRow>
                  <TableCell id="yearsmonthatresidence" sx={poppinsFont}><strong>Years/Month at Residence:</strong></TableCell>
                  <TableCell sx={poppinsFont}>{customerData.yearsAtResidence}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="taxidssn" sx={poppinsFont}><strong>Tax Id/SSN:</strong></TableCell>
                  <TableCell sx={poppinsFont}>{customerData.taxId}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="ownedrented" sx={poppinsFont}><strong>Owned/Rented:</strong></TableCell>
                  <TableCell sx={poppinsFont}>{customerData.ownershipStatus}</TableCell>
                </TableRow>
              </Table>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {/* Edit Popup Dialog */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
          <EditPolicyUserDetail 
              handleChange={handleChange} 
              formData={formData} 
              handleDialogClose={handleDialogClose}
              handleSubmit={handleSubmit}
            />        
      </Dialog>
    </TableContainer>
  );
};

export default PolicyUserDetails;

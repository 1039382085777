import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { Box, Typography,Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { getAllTaskAction } from '../../redux/actions/taskActions';
import { useDispatch, useSelector } from 'react-redux';
import AddTask1 from '../SidebarLayout/Task/AddTask1';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: 'none',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#F8F8F8',
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.common.white,
  },
  '& td, & th, & tr': {
    border: 'none',
    padding: '0 10px',
  },
}));

const StyledTableContainer = styled(TableContainer)({
  boxShadow: 'none',
  '& tr': {
    border: 'none',
  },
});

const TaskList = () => {

    const location = useLocation();
    const navigate=useNavigate();
    const [showButton,setShowButton]=useState(false);
    const [showHideTaskForm, setShowHideTaskForm] = useState(false);
    const methods = useForm();
    const taskvalue = useSelector((state) => state.task.taskForm);
    const allTask = useSelector((state) => state.task.allTask);
    const dispatch = useDispatch();
    console.log("taskvalue",taskvalue)
    const taskData = {
      "headers": ["E", "Status", "Type", "Priority", "Description", "Assigned", "Due", "Creator", "Date", "Closed By", "Closed", "Attachments"],
      "data": allTask,
    };
    useEffect(() => {
        dispatch(getAllTaskAction());
    }, [dispatch]);
    console.log("allTask",allTask);

    // useEffect(() => {
    //     if (allTask.length > 0) {

    //     }
    // }, [allTask]);

    // useEffect(() => {
    //         setTableData(taskData);
    //     }, []);


    const handleEditClick = (task) => {
        // Navigate to CreatePolicy component with pre-filled data
        setShowHideTaskForm(true)
        setShowButton(false);
        // console.log("task data in edit",taskData);

        // methods.reset(taskData);
        methods.reset(task);

      };
    // const handleDeleteClick = (id) => {
    //     dispatch(deleteTaskActionById(id, () => {
    //       dispatch(getAllTaskAction());
    //     }));
    //   };

      function handleClick() {
        setShowHideTaskForm(true);
        setShowButton(true);
        // setTableData({});
      }
  return (
    <>
     {showHideTaskForm ? (
        <>
         <FormProvider {...methods}>
        <AddTask1 setShowHideTaskForm={setShowHideTaskForm} setShowButton={showButton} />
        </FormProvider>
        </>
     ):(
      <>
    <Box p={2}>
    <Button onClick={handleClick} id="addtaskbutton"   variant="text" sx={{ color: '#0173BC', fontWeight: 'bold', margin: '0 0 10px 0'}}>
                Add New Task
                </Button>
      <Typography id="tasks" variant="h6" gutterBottom>
        Tasks
      </Typography>
      <StyledTableContainer component={Paper}>
        <Table sx={{ border: '0', borderColor: 'transparent', minWidth: 650 }} aria-label="tasks table">
          <TableHead>
            <TableRow>
              {taskData.headers.map((header, index) => (
                <StyledTableCell key={index}>{header}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {taskData.data.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell padding="checkbox">
                  <Checkbox />
                </StyledTableCell>
                <StyledTableCell>{row.status}</StyledTableCell>
                <StyledTableCell>{row.taskType}</StyledTableCell>
                <StyledTableCell>{row.priority}</StyledTableCell>
                <StyledTableCell>{row.taskDescription}</StyledTableCell>
                <StyledTableCell>{row.taskTo}</StyledTableCell>
                <StyledTableCell>{row.dueDate}</StyledTableCell>
                <StyledTableCell>{row.createdBy}</StyledTableCell>
                <StyledTableCell>{row.date}</StyledTableCell>
                <StyledTableCell>{row.closedBy}</StyledTableCell>
                <StyledTableCell>{row.closed}</StyledTableCell>
                <StyledTableCell>{row.attachments}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Box>
    </>
     )}
     </>
  );
};

export default TaskList;

import AllLeadListing  from "../../../../components/SidebarLayout/AllLeads/AllLeadListing";
import Box from '@mui/material/Box';

const LeadListing = () => {
    return (
        <>
            <Box sx={{margin: '5rem 0 0 0', padding: '0 2rem 0 5rem'}}>
                <AllLeadListing />
            </Box>
        </>
    )
}

export default LeadListing;
const initialState = {
    token: null,
    error: null,
  };
  
  const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SIGN_UP_SUCCESS':
      case 'SIGN_IN_SUCCESS':
        return { ...state, token: action.payload, error: null };
      case 'SIGN_UP_FAILURE':
      case 'SIGN_IN_FAILURE':
        return { ...state, token: null, error: action.payload };
      case 'SIGN_OUT_SUCCESS':
        sessionStorage.removeItem('token');
        return { ...state, token: action.payload, error: null };
      default:
        return state;
    }
  };
  
  export default authReducer;
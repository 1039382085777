import { searchNote } from "../../services/noteServices";

const initialState = {
  notes: JSON.parse(localStorage.getItem("notes")) || {},
  alert: JSON.parse(localStorage.getItem("alert")) || {},
  comment: JSON.parse(localStorage.getItem("comment")) || {},
  allNote: [],
  searchNote:[],
  error: null,
};

const noteReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_NOTE_SUCCESS":
      // localStorage.setItem('customizedForm', JSON.stringify(action.payload));
      // return { customizedForm: action.payload, error: null };
      return { ...state, allNote: action.payload, error: null };
    case "SEARCH_NOTE_SUCCESS":
      return { ...state, searchNote: action.payload, error: null };
    case "SAVE_NOTE_SUCCESS":
      localStorage.setItem("notes", JSON.stringify(action.payload));
      return { ...state, notes: action.payload, error: null };
    case "SAVE_ALERT_SUCCESS":
      localStorage.setItem("alert", JSON.stringify(action.payload));
      return { ...state, alert: action.payload, error: null };
    case "SAVE_COMMENT_SUCCESS":
      localStorage.setItem("comment", JSON.stringify(action.payload));
      return { ...state, comment: action.payload, error: null };
    case "UPDATE_NOTE_SUCCESS":
      localStorage.setItem("notes", JSON.stringify(action.payload));
      return { ...state, notes: action.payload, error: null };
    case "DELETE_NOTE_SUCCESS":
      const updatedNote = state.allNote.filter(
        (policy) => policy.id !== action.payload
      );
      return { ...state, allNote: updatedNote, error: null };
    case "GET_NOTE_FAILURE":
    case "SAVE_NOTE_FAILURE":
    case "SAVE_ALERT_FAILURE":
    case "SEARCH_NOTE_FAILURE":
    case "SAVE_COMMENT_FAILURE":
    case "DELETE_NOTE_FAILURE":
    case "UPDATE_NOTE_FAILURE":
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default noteReducer;

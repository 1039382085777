import * as quoteServices from '../../services/quotelistingServices';
import {
  CLOSE_CREATE_LEAD_MODAL,
  OPEN_CREATE_LEAD_MODAL,
    OPEN_UPDATE_LEAD_MODAL,
    START_LOADING,
    STOP_LOADING,
    SEARCH_QUOTE_FAILURE,
    SEARCH_QUOTE_SUCCESS,
    QUOTE_LISTING_SUCCESS,
    QUOTE_LISTING_FAILURE
} from "./actionTypes"
import { openSessionTimeoutModal } from './sessionTimeOutActions';

// export const getQuotelisting = () => async (dispatch, getState) => {
//   try {
//     dispatch({ type: START_LOADING, service: 'QUOTE_LISTING' });
//     const token = getState().auth.token || sessionStorage.getItem("token");
//     const response = await quoteServices.getQuotelisting(token);
//     dispatch({ type: STOP_LOADING, service: 'QUOTE_LISTING' });
//     dispatch({ type: QUOTE_LISTING_SUCCESS, payload: response });
//   } catch (error) {
//     if(error?.includes("403")) {
//       dispatch(openSessionTimeoutModal());
//     } else {
//       dispatch({ type: STOP_LOADING, service: 'QUOTE_LISTING' });
//       dispatch({ type: QUOTE_LISTING_FAILURE, payload: error || 'All quote service failed. Please try again.' });
//     }
//   }
// };

export const getQuotelisting = (page = 1, size = 5) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'QUOTE_LISTING' });
    const token = getState().auth.token || sessionStorage.getItem("token");
    const response = await quoteServices.getQuotelisting(token, page, size);
    dispatch({ type: STOP_LOADING, service: 'QUOTE_LISTING' });
    dispatch({ type: QUOTE_LISTING_SUCCESS, payload: response });
  } catch (error) {
    if(error?.includes("403")) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'QUOTE_LISTING' });
      dispatch({ type: QUOTE_LISTING_FAILURE, payload: error || 'All quote service failed. Please try again.' });
    }
  }
};


export const searchQuoteAction = (formData, callbackfn) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'SEARCH_QUOTE' });
    const token = getState().auth.token || sessionStorage.getItem("token");
    const response = await quoteServices.searchQuote(formData, token);
    // console.log("alert",response);
    dispatch({ type: STOP_LOADING, service: 'SEARCH_QUOTE' });
    if (response) {
      callbackfn(response);
    }
    dispatch({ type: SEARCH_QUOTE_SUCCESS, payload: response }); // Dispatch the success action with response data
  } catch (error) {
    if (error?.includes("403")) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'SEARCH_QUOTE' });
      dispatch({ type: SEARCH_QUOTE_FAILURE, payload: error || 'Search Quote service failed. Please try again.' });
    }
  }
};

export const openCreateLeadModal = () => ({
  type: OPEN_CREATE_LEAD_MODAL,
});

export const closeCreateLeadModal = () => ({
  type: CLOSE_CREATE_LEAD_MODAL,
});

export const openUpdateLeadModal = (id) => async (dispatch) => {
  dispatch({ type:OPEN_UPDATE_LEAD_MODAL, payload: id });
};
import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TablePagination,
  Grid,
  TextField,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getBotReportAction,
  searchBotDownloadLogsReportAction,
  searchBotLogsReportAction,
} from "../../redux/actions/botAction";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 20,
    padding: 16,
    backgroundColor: "#f5f5f5",
    borderRadius: 8,
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
  },
  table: {
    minWidth: 650,
  },
  tableCell: {
    fontWeight: "bold",
    backgroundColor: "#b0b0b0",
    color: "white",
    textAlign: "center",
  },
  tableCellData: {
    textAlign: "center", // Center align all data cells
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#f1f1f1",
    },
  },
  iconButton: {
    marginRight: 8,
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
  },
  downloadButton: {
    backgroundColor: "#4CAF50",
    color: "white",
    marginLeft: 16,
    "&:hover": {
      backgroundColor: "#45a049",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  tableContainer: {
    borderRadius: 8,
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
  },
}));

function BotReport() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const botDetails = useSelector((state) => state.bot.getBotReport);
  const searchBot = useSelector((state) => state.bot.searchBotlogs);
  const searchBotDownload = useSelector((state) => state.bot.searchBotDownloadLogs);
  const reports = botDetails.content || [];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [botData, setBotData] = useState([]);
  const searchreport = searchBot.content || [];
  const searchreportdownload = searchBotDownload.content || [];
  // console.log("searchBotDownload",searchBotDownload);
  const count = hasSearched ? searchBot.totalElements : 0;

  // useEffect(() => {
  //   dispatch(getBotReportAction(page, rowsPerPage,`${sortField},${sortOrder}`));
  // }, [dispatch,page, rowsPerPage, sortField, sortOrder]);

  const fetchAllSearchResults = async () => {
    try {
      const response = await dispatch(searchBotDownloadLogsReportAction({ search }, 0, count));
      console.log("response of all data",response);
      return response.content || [];
    } catch (error) {
      console.error('Error fetching all search results:', error);
      return [];
    }
  };

   useEffect(() => {
    if(count>0)
    {
      dispatch(searchBotDownloadLogsReportAction({fromDate: fromDate ? dayjs(fromDate).format("MM-DD-YYYY") : null,
        toDate: toDate ? dayjs(toDate).format("MM-DD-YYYY") : null, search }, 0, count));
    }
    }, [dispatch,page, rowsPerPage,count]);

  useEffect(() => {
    if (hasSearched) {
      dispatch(
        searchBotLogsReportAction(
          {fromDate: fromDate ? dayjs(fromDate).format("MM-DD-YYYY") : null,
            toDate: toDate ? dayjs(toDate).format("MM-DD-YYYY") : null, search },
          page,
          rowsPerPage,
          // `${sortField},${sortOrder}`
        )
      );
    }
  }, [dispatch, page, rowsPerPage, hasSearched, search]);

  useEffect(() => {
    if (hasSearched) {
      setBotData(getBotData(searchreport));
    }
  }, [searchBot, hasSearched]);

  const handleSearch = () => {
    // if (search.trim()) {
      dispatch(searchBotLogsReportAction({fromDate: fromDate ? dayjs(fromDate).format("MM-DD-YYYY") : null,
        toDate: toDate ? dayjs(toDate).format("MM-DD-YYYY") : null, search }));
      setHasSearched(true);

  };

  const resetSearch = () => {
    setHasSearched(false);
    setSearch("");
    setBotData([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (field) => {
    const isAsc = sortField === field && sortOrder === "asc";
    setSortField(field);
    setSortOrder(isAsc ? "desc" : "asc");
  };
  const createTableHeader = () => `
    <tr style="background-color: #b0b0b0; color: white;">
      <th style="padding: 8px; text-align: center;">Carrier Name</th>
      <th style="padding: 8px; text-align: center;">Process Master Name</th>
      <th style="padding: 8px; text-align: center;">Track Date</th>
      <th style="padding: 8px; text-align: center;">Policy Number</th>
      <th style="padding: 8px; text-align: center;">Text</th>
      <th style="padding: 8px; text-align: center;">Task</th>
      <th style="padding: 8px; text-align: center;">Text Successful</th>
      <th style="padding: 8px; text-align: center;">Result Successful</th>
    </tr>
  `;

  const createTableRow = (row) => `
    <tr style="border-bottom: 1px solid #ddd;">
      <td style="padding: 8px; text-align: center;">${
        row.carrierName || ""
      }</td>
      <td style="padding: 8px; text-align: center;">${
        row.botProcessMasterName || ""
      }</td>
      <td style="padding: 8px; text-align: center;">${row.trackDate || ""}</td>
      <td style="padding: 8px; text-align: center;">${
        row.policyNumber || ""
      }</td>
      <td style="padding: 8px; text-align: center;">${row.text || ""}</td>
      <td style="padding: 8px; text-align: center;">${row.task || ""}</td>
      <td style="padding: 8px; text-align: center;">${row.resultText || ""}</td>
      <td style="padding: 8px; text-align: center;">${row.resultTask || ""}</td>
    </tr>
  `;

  const downloadPDF = async () => {
    try {
      setIsDownloading(true);
      // await fetchAllSearchResults();
      const allData=searchreportdownload;

      const pdf = new jsPDF('l', 'mm', 'a4');
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const margins = 15;

      // Add title and search term
      pdf.setFontSize(16);
      pdf.text('Bot Report', margins, margins);
      pdf.setFontSize(12);
      pdf.text(`Search Term: "${search}"`, margins, margins + 10);

      // Calculate rows per page
      const rowHeight = 12;
      const headerHeight = 25;
      const footerHeight = 10;
      const availableHeight = pageHeight - margins * 2 - headerHeight - footerHeight;
      const rowsPerPdfPage = Math.floor(availableHeight / rowHeight);

      // Split data into pages
      for (let i = 0; i < allData.length; i += rowsPerPdfPage) {
        if (i > 0) {
          pdf.addPage();
        }

        const pageData = allData.slice(i, i + rowsPerPdfPage);
        const tableHtml = `
          <table style="width: 100%; border-collapse: collapse; font-size: 12px;">
            ${createTableHeader()}
            ${pageData.map(row => createTableRow(row)).join('')}
          </table>
        `;

        const div = document.createElement('div');
        div.innerHTML = tableHtml;
        document.body.appendChild(div);

        const canvas = await html2canvas(div, {
          scale: 2,
          useCORS: true,
          logging: false,
          backgroundColor: '#ffffff'
        });

        document.body.removeChild(div);

        const imgData = canvas.toDataURL('image/png');
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pageWidth - (margins * 2);
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(imgData, 'PNG', margins, i === 0 ? margins + 15 : margins, pdfWidth, pdfHeight);

        // Add page number
        const pageNumber = `Page ${pdf.internal.getNumberOfPages()}`;
        pdf.setFontSize(10);
        pdf.text(pageNumber, pageWidth - margins - 20, pageHeight - margins);
      }

      // Add generation details on the last page
      const date = new Date().toLocaleDateString();
      pdf.text(`Generated on: ${date}`, margins, pageHeight - margins);
      pdf.text(`Total Records: ${allData.length}`, margins, pageHeight - margins - 5);

      pdf.save(`bot-report-${date}.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setIsDownloading(false);
    }
  };




  return (
    <Container className={classes.container}>
       <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: "bold" }}>Search</Typography>
        </Grid>
        <Grid item xs={3}>
        <DatePicker
          label="From Date"
          value={fromDate}
          onChange={(newDate) => setFromDate(newDate)}
          format="MM-DD-YYYY"
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      </Grid>
      <Grid item xs={3}>
        <DatePicker
          label="To Date"
          value={toDate}
          onChange={(newDate) => setToDate(newDate)}
          format="MM-DD-YYYY"
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Enter search term"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            fullWidth
            id="search"
            variant="contained"
            color="primary"
            onClick={handleSearch}
            // disabled={!search.trim()}
            disabled={ !fromDate || !toDate}
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            fullWidth
            id="reset"
            variant="contained"
            color="secondary"
            onClick={resetSearch}
            disabled={!hasSearched}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      </LocalizationProvider>
      {hasSearched && (
        <>
      <Box className={classes.header}>
        <Typography variant="h4" gutterBottom>
          Bot Logs
        </Typography>

        <Button
          variant="contained"
          className={classes.downloadButton}
          onClick={downloadPDF}
          disabled={isDownloading || count === 0}
          startIcon={<DownloadIcon />}
        >
          {isDownloading ? 'Downloading...' : 'Download All'}
        </Button>

      </Box>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table}>
          {/* <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>Carrier Name</TableCell>
              <TableCell className={classes.tableCell}>Process Master Name</TableCell>
              <TableCell className={classes.tableCell}>Track Date</TableCell>
              <TableCell className={classes.tableCell}>Policy Number</TableCell>
              <TableCell className={classes.tableCell}>Sub Process Name</TableCell>

              <TableCell className={classes.tableCell}>Text Successful</TableCell>
              <TableCell className={classes.tableCell}>Result Successful</TableCell>
              <TableCell className={classes.tableCell}>Created At</TableCell>
            </TableRow>
          </TableHead> */}
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.tableCell}
                onClick={() => handleSort("carrierName")}
              >
                Carrier Name{" "}
                {sortField === "carrierName"
                  ? sortOrder === "asc"
                    ? "▲"
                    : "▼"
                  : ""}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                onClick={() => handleSort("botProcessMasterName")}
              >
                Process Master Name{" "}
                {sortField === "botProcessMasterName"
                  ? sortOrder === "asc"
                    ? "▲"
                    : "▼"
                  : ""}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                onClick={() => handleSort("trackDate")}
              >
                Track Date{" "}
                {sortField === "trackDate"
                  ? sortOrder === "asc"
                    ? "▲"
                    : "▼"
                  : ""}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                onClick={() => handleSort("policyNumber")}
              >
                Policy Number{" "}
                {sortField === "policyNumber"
                  ? sortOrder === "asc"
                    ? "▲"
                    : "▼"
                  : ""}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                onClick={() => handleSort("botSubProcessName")}
              >
                Sub Process Name{" "}
                {sortField === "botSubProcessName"
                  ? sortOrder === "asc"
                    ? "▲"
                    : "▼"
                  : ""}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                onClick={() => handleSort("resultText")}
              >
                Text Successful{" "}
                {sortField === "resultText"
                  ? sortOrder === "asc"
                    ? "▲"
                    : "▼"
                  : ""}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                onClick={() => handleSort("resultTask")}
              >
                Result Successful{" "}
                {sortField === "resultTask"
                  ? sortOrder === "asc"
                    ? "▲"
                    : "▼"
                  : ""}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                onClick={() => handleSort("createdAt")}
              >
                Date Created{" "}
                {sortField === "createdAt"
                  ? sortOrder === "asc"
                    ? "▲"
                    : "▼"
                  : ""}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {botData.length > 0 ? (
            botData.map((row) => (
              <TableRow key={row.id} className={classes.tableRow}>
                <TableCell className={classes.tableCellData}>
                  {row.carrierName}
                </TableCell>
                <TableCell className={classes.tableCellData}>
                  {row.botProcessMasterName}
                </TableCell>
                <TableCell className={classes.tableCellData}>
                  {row.trackDate}
                </TableCell>
                <TableCell className={classes.tableCellData}>
                  {row.policyNumber}
                </TableCell>
                <TableCell className={classes.tableCellData}>
                  {row.botSubProcessName}
                </TableCell>

                <TableCell className={classes.tableCellData}>
                  {row.resultText}
                </TableCell>
                <TableCell className={classes.tableCellData}>
                  {row.resultTask}
                </TableCell>
                {/* <TableCell className={classes.tableCellData}>{row.createdAt}</TableCell> */}
                <TableCell>
                  {dayjs(row.createdAt).format("MM-DD-YYYY, hh:mm:ss A")}
                </TableCell>
              </TableRow>
            ))):(
              <TableRow>
                  <TableCell colSpan={7} align="center">
                    <Typography variant="body2">No data found</Typography>
                  </TableCell>
                </TableRow>
              )
          }
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={count === 0 ? [] : [5, 10, 15, 25, 50, 100]}
        />
      </TableContainer>
      </>
      )}
    </Container>
  );
}

export default BotReport;

const getBotData = (bots) => {
  if (!Array.isArray(bots)) {
    return [];
  }
  return bots;
};

// import React, { useEffect, useState } from "react";
// import {
//   Container,
//   Typography,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   IconButton,
//   Button,
//   Box,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   TablePagination,
// } from "@mui/material";
// import { makeStyles } from "@mui/styles";
// import { useDispatch, useSelector } from "react-redux";
// import { getBotReportAction } from "../../redux/actions/botAction";
// const useStyles = makeStyles((theme) => ({
//     container: {
//       marginTop: 20,
//       padding: 16,
//       backgroundColor: "#f5f5f5",
//       borderRadius: 8,
//       boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
//     },
//     table: {
//       minWidth: 650,
//     },
//     tableCell: {
//       fontWeight: "bold",
//       backgroundColor: "#b0b0b0",
//       color: "white",
//       textAlign: "center",
//     },
//     tableRow: {
//       '&:hover': {
//         backgroundColor: "#f1f1f1",
//       },
//     },
//     iconButton: {
//       marginRight: 8,
//       '&:hover': {
//         backgroundColor: "#e0e0e0",
//       },
//     },
//     addButton: {
//       backgroundColor: "#1976d2",
//       color: "white",
//       borderRadius: 8,
//       textTransform: "none",
//       padding: "8px 16px",
//       "&:hover": {
//         backgroundColor: "#1565c0",
//       },
//     },
//     header: {
//       display: "flex",
//       justifyContent: "space-between",
//       alignItems: "center",
//       marginBottom: 20,
//     },
//     tableContainer: {
//       borderRadius: 8,
//       boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
//     },
//     dialogContent: {
//       backgroundColor: "#f8f8f8",
//     },
//     deleteButton: {
//       backgroundColor: "#d32f2f",
//       color: "white",
//       "&:hover": {
//         backgroundColor: "#c62828",
//       },
//     },
//     cancelButton: {
//       backgroundColor: "#757575",
//       color: "white",
//       "&:hover": {
//         backgroundColor: "#616161",
//       },
//     },
//   }));
// function BotReport() {
//     const classes = useStyles();
//     const dispatch = useDispatch();
//   const botDetails = useSelector((state) => state.bot.getBotReport);
//   const reports = botDetails || [];
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);
//   console.log("bot reporst",reports);
//   useEffect(() => {
//     dispatch(getBotReportAction());
//   }, [dispatch]);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const paginatedRows = reports.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

//   return (
//     <Container className={classes.container}>
//          <Box className={classes.header}>
//             <Typography variant="h4" gutterBottom>
//             Bot Logs
//             </Typography>
//             </Box>
//           <TableContainer component={Paper} className={classes.tableContainer}>
//             <Table className={classes.table}>
//               <TableHead>
//                 <TableRow>
//                 <TableCell className={classes.tableCell}>Carrier Name</TableCell>
//                 <TableCell className={classes.tableCell}>Process Master Name</TableCell>
//               <TableCell className={classes.tableCell}>Track Date</TableCell>
//               <TableCell className={classes.tableCell}>Policy Number</TableCell>
//               <TableCell className={classes.tableCell}>Text</TableCell>
//               <TableCell className={classes.tableCell}>Task</TableCell>
//               <TableCell className={classes.tableCell}>Text Successfull</TableCell>
//               <TableCell className={classes.tableCell}>Result Successfull</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {paginatedRows.map((row) => (
//                   <TableRow key={row.id} className={classes.tableRow}>
//                     <TableCell>{row.carrierName}</TableCell>
//                     <TableCell>{row.botProcessMasterName}</TableCell>
//                 <TableCell>{row.trackDate}</TableCell>
//                 <TableCell>{row.policyNumber}</TableCell>
//                 <TableCell>{row.text}</TableCell>
//                 <TableCell>{row.task}</TableCell>
//                 <TableCell>{row.resultText}</TableCell>
//                 <TableCell>{row.resultTask}</TableCell>

//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>
//           <TablePagination
//         component="div"
//         count={reports.length}
//         page={page}
//         onPageChange={handleChangePage}
//         rowsPerPage={rowsPerPage}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//         rowsPerPageOptions={reports.length === 0 ? [] : [5, 10, 15]}
//       />
//     </Container>
//   )
// }

// export default BotReport

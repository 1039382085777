// store.js
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk'
import authReducer from './reducers/authReducer';
import loadingReducer from './reducers/loadingReducer';
import userReducer from './reducers/userReducer';
import leadReducer from './reducers/leadReducer';
import organizationReducer from './reducers/organizationReducer';
import customerReducer from './reducers/customerReducer';
import formLayoutReducer from './reducers/formLayoutReducer';
import accountReducer from './reducers/accountReducer';
import sessionTimeoutReducer from './reducers/sessionTimeOutReducer';
import rolesReducer from './reducers/rolesReducer';
import permissionReducer from './reducers/permissionReducer';
import themeReducer from './reducers/themeReducer';
import dropDownReducer from './reducers/dynamicDropDownReducer';
import policyReducer from './reducers/policyReducer';
import taskReducer from './reducers/taskReducer';
import noteReducer from './reducers/noteReducer';
import generalPartyInfoReducer from './reducers/generalPartyInfoReducer';
import driverInfoReducer from './reducers/driverInfoReducer';
import vehicleInfoReducer from './reducers/vehicleInfoReducer';
import exclusionsInfoReducer from './reducers/exclusionsInfoReducer';
import carrierSelectionsInfoReducer from './reducers/carrierSelectionsInfoReducer';
import carrierQuestionsInfoReducer from './reducers/carrierQuestionsInfoReducer';
import priorInsuranceCarriersReducer from './reducers/priorInsuranceCarriersReducer';
import companyselectionReducer from './reducers/companyReducer';
import dashboardReducer from './reducers/dashboardReducer';
import locationGroupsReducer from './reducers/locationGroupsReducer';

///New Design
import newleadReducer from './reducers/newleadReducer';
import quotelistingReducer from './reducers/quotelistingReducer';
import communicationReducer from './reducers/communicationReducer';
import uploadAttachmentReducer from './reducers/uploadAttachmentReducer';
import policyDetailReducer from './reducers/policyDetailReducer';
import agencyReducer from './reducers/agencyReducer';
import locationReducer from './reducers/locationReducer';
import gailReducer from './reducers/gailReducer';
import botReducer from './reducers/botReducer';
import activityReducer from './reducers/activityReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  loading: loadingReducer,
  user: userReducer,
  leads: leadReducer,
  organizations: organizationReducer,
  customers: customerReducer,
  formLayout: formLayoutReducer,
  accounts: accountReducer,
  sessionTimeoutModal: sessionTimeoutReducer,
  roles: rolesReducer,
  permissions: permissionReducer,
  theme: themeReducer,
  dropDowns: dropDownReducer,
  newleads: newleadReducer,
  policy:policyReducer,
  task:taskReducer,
  note:noteReducer,
  generalPartyInfo: generalPartyInfoReducer,
  driverInfo: driverInfoReducer,
  vehicleInfo: vehicleInfoReducer,
  exclusionsInfo: exclusionsInfoReducer,
  carrierSelectionsInfo: carrierSelectionsInfoReducer,
  carrierQuestionsInfo: carrierQuestionsInfoReducer,
  priorInsuranceCarriers:priorInsuranceCarriersReducer,
  companies:companyselectionReducer,
  quotelisting:quotelistingReducer,
  communication:communicationReducer,
  uploadAttachment:uploadAttachmentReducer,
  policyDetail:policyDetailReducer,
  agency:agencyReducer,
  location:locationReducer,
  dashboardData: dashboardReducer,
  gail:gailReducer,
  bot:botReducer,
  allActivity: activityReducer,
  locationGroup:locationGroupsReducer,
});

const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
);

export default store;

import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Menu, IconButton, MenuItem } from '@mui/material';
import { AccountCircleRounded, ExpandMoreOutlined } from '@mui/icons-material';
import { makeStyles, useTheme } from '@mui/styles';
import { Home } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { signOut as signOutAction } from '../redux/actions/authActions';
import { getUser as getUserAction, openCreateUserModal as openCreateUserModalAction } from '../redux/actions/userActions';
import { openCreateOrganizationModal as openCreateOrganizationModalAction } from '../redux/actions/organizationActions';
import { openCreateCustomerModal as openCreateCustomerModalAction } from '../redux/actions/customerActions';
import { accessRules } from './helpers/utils';
import { getThemeAction } from '../redux/actions/themeActions';

const useStyles = makeStyles((customTheme) => {
    return {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row !important',
    },
    nav: {
        padding: '1em 1em 0',
        margin: '0',
    },
    logo: {
        maxWidth: '120px',
        marginTop: '.25em',
        fontWeight: 'bold',
    },
    link: {
        textDecoration: "none !important",
        padding: "2em .5em 0",
        color: customTheme?.palette?.primary?.main,
        "&:hover": {
            color: 'black',
        }
    },
    icon: {
        marginBottom: '-.25em',
    },
    menuLink: {
        color: customTheme?.palette?.primary?.main,
        textDecoration: "none !important",
        padding: ".25em",
    },
    iconColor: {
        color: customTheme?.palette?.primary?.main,
        background: customTheme?.palette?.primary?.main,
    }
  }});

const Header = () => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen ]= React.useState(null);
    const dispatch = useDispatch();
    const user = useSelector((state) => state?.user?.user);
    const classes = useStyles();
    const theme = useTheme();

    const dynamicTheme = useSelector((state) => state?.theme?.theme[0]);

    useEffect(() => {
        if(user?.customer?.id) {
            dispatch(getThemeAction(user?.customer?.id))
        }
        
    }, [user])
    
    useEffect(() => {
        if(!user) {
            dispatch(getUserAction());
        }
    }, []);

    const handleMenu = (event, target) => {
        setOpen(target);
        setAnchorEl(event.currentTarget);
      };
    
    const handleClose = () => {
        setOpen(undefined);
        setAnchorEl(null);
    };

    const openAllLeads = () => {
        setOpen(undefined);
        setAnchorEl(null);
        navigate("/allLeads");
    };

    const openAllAccounts = () => {
        setOpen(undefined);
        setAnchorEl(null);
        navigate("/allAccounts");
    };

    const openMyLeads = () => {
        setOpen(undefined);
        setAnchorEl(null);
        navigate("/myLeads");
    };

    const openMyAccounts = () => {
        setOpen(undefined);
        setAnchorEl(null);
        navigate("/myAccounts");
    };

    const handleSettings = () => {
        setOpen(undefined);
        setAnchorEl(null);
        navigate("/settings");
    };

    const openCreateLead = () => {
        setOpen(undefined);
        setAnchorEl(null);
        navigate("/createLead");
    };

    const openCreateOrganization = () => {
        setOpen(undefined);
        setAnchorEl(null);
        dispatch(openCreateOrganizationModalAction());
    };

    const openCreateCustomer = () => {
        setOpen(undefined);
        setAnchorEl(null);
        dispatch(openCreateCustomerModalAction());
    };

    const openCreateUser = () => {
        setOpen(undefined);
        setAnchorEl(null);
        dispatch(openCreateUserModalAction());
    };
    
    const handleNavigate = (route) => {
        setOpen(undefined);
        setAnchorEl(null);
        navigate(route);
    };

    const logout = async() => {
        setOpen(undefined);
        setAnchorEl(null);
        await dispatch(signOutAction());
    }

    const handleCustomTheme = () => {
        setOpen(undefined);
        setAnchorEl(null);
        navigate('/customizeThemeSettings');
    }

    const handleRolesClick = () => {
        setOpen(undefined);
        setAnchorEl(null);
        navigate('/roles');
    }

    const handlePermissionsClick = () => {
        setOpen(undefined);
        setAnchorEl(null);
        navigate('/permissions');
    }

    const handleMasterDropDown = () => {
        setOpen(undefined);
        setAnchorEl(null);
        navigate('/createDropdown')
    }

    const permissions = user?.roleInfo?.permissions || [];

    
    const rules = accessRules(permissions);

    return (
        <AppBar position="static">
        <Toolbar className={classes.header}>
            <Typography variant="h4">{dynamicTheme?.fileData !== "default" ? <img src={dynamicTheme?.fileData} width="100"/> : <strong>BizAiTek</strong>}</Typography>
            <div className={classes.nav}>
                <Link className={classes.link} to="/home" ><Home className={classes.icon}/></Link>
                {rules.leads &&  <Link className={classes.link} onClick={(e) => handleMenu(e, 'leads-menu')}>Leads <ExpandMoreOutlined className={classes.icon}/></Link>}
                {rules.organizations && <Link className={classes.link} onClick={(e) => handleMenu(e, 'organization-menu')}>Organizations <ExpandMoreOutlined className={classes.icon}/></Link>}
                {rules.customer && <Link className={classes.link} onClick={(e) => handleMenu(e, 'customer-menu')}>Customers <ExpandMoreOutlined className={classes.icon}/></Link>}
                {rules.teams && <Link className={classes.link} onClick={(e) => handleMenu(e, 'team-menu')}>Teams <ExpandMoreOutlined className={classes.icon}/></Link>}
                {rules.accounts && <Link className={classes.link} onClick={(e) => handleMenu(e, 'account-menu')} >Accounts <ExpandMoreOutlined className={classes.icon}/></Link>}
                {rules.contacts && <Link className={classes.link} onClick={(e) => handleMenu(e, 'contacts-menu')} >Contacts <ExpandMoreOutlined className={classes.icon}/></Link>}
                {rules.calendar && <Link className={classes.link} onClick={(e) => handleMenu(e, 'calendar-menu')} >Calendar <ExpandMoreOutlined className={classes.icon}/></Link>}
                {rules.dashboards && <Link className={classes.link} onClick={(e) => handleMenu(e, 'dashboards-menu')} >Dashboards <ExpandMoreOutlined className={classes.icon}/></Link>}
                {rules.reports && <Link className={classes.link} onClick={(e) => handleMenu(e, 'reports-menu')} >Reports <ExpandMoreOutlined className={classes.icon}/></Link>}
                <IconButton
                    size="large"
                    onClick={(e) => handleMenu(e, 'user-menu')}
                    color={theme?.palette?.primary?.main}
                >
                    <AccountCircleRounded />
                </IconButton>
                <Menu
                    id="usermenu"
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                    }}
                    keepMounted
                    open={open === 'user-menu'}
                    onClose={handleClose}
                    anchorEl={anchorEl}
                >
                    <MenuItem onClick={handleClose}><Link className={classes.menuLink}>Edit Profile</Link></MenuItem>
                    {rules.settings && <MenuItem onClick={handleSettings}><Link className={classes.menuLink}>Settings</Link></MenuItem>}
                    {rules.theme && <MenuItem onClick={handleCustomTheme}><Link className={classes.menuLink}>Custom Theme</Link></MenuItem>}
                    {rules.roles && <MenuItem onClick={handleRolesClick}><Link className={classes.menuLink}>Roles</Link></MenuItem>}
                    {rules.permission && <MenuItem onClick={handlePermissionsClick}><Link className={classes.menuLink}>Permissions</Link></MenuItem>}
                    {rules.permission && <MenuItem onClick={handleMasterDropDown}><Link className={classes.menuLink}>Create Dropdown</Link></MenuItem>}
                    <MenuItem onClick={logout}><Link className={classes.menuLink}>Logout</Link></MenuItem>
                </Menu>
                <Menu
                    id="leadsmenu"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    }}
                    keepMounted
                    open={open === 'leads-menu'}
                    onClose={handleClose}
                >
                    <MenuItem onClick={openCreateLead}><Link className={classes.menuLink}>Create Lead</Link></MenuItem>
                    <MenuItem onClick={openAllLeads}><Link className={classes.menuLink}>All Leads</Link></MenuItem>
                    <MenuItem onClick={openMyLeads}><Link className={classes.menuLink}>My Leads</Link></MenuItem>
                </Menu>
                <Menu
                    id="organizationmenu"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    }}
                    keepMounted
                    open={open === 'organization-menu'}
                    onClose={handleClose}
                >
                    <MenuItem onClick={openCreateOrganization}><Link className={classes.menuLink}>Create Organization</Link></MenuItem>
                    <MenuItem onClick={() => handleNavigate("organizations")}><Link className={classes.menuLink}>All Organizations</Link></MenuItem>
                </Menu>
                <Menu
                    id="customermenu"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    }}
                    keepMounted
                    open={open === 'customer-menu'}
                    onClose={handleClose}
                >
                    <MenuItem onClick={openCreateCustomer}><Link className={classes.menuLink}>Create Customer</Link></MenuItem>
                    <MenuItem onClick={() => handleNavigate("customers")}><Link className={classes.menuLink}>All Customers</Link></MenuItem>
                </Menu>
                <Menu
                    id="teammenu"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    }}
                    keepMounted
                    open={open === 'team-menu'}
                    onClose={handleClose}
                >
                    <MenuItem onClick={openCreateUser}><Link className={classes.menuLink}>Create Team</Link></MenuItem>
                    <MenuItem onClick={() => handleNavigate("teams")}><Link className={classes.menuLink}>All Team</Link></MenuItem>
                </Menu>
                <Menu
                    id="accountmenu"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    }}
                    keepMounted
                    open={open === 'account-menu'}
                    onClose={handleClose}
                >
                    <MenuItem onClick={openAllAccounts}><Link className={classes.menuLink}>All Accounts</Link></MenuItem>
                    <MenuItem onClick={openMyAccounts}><Link className={classes.menuLink}>My Accounts</Link></MenuItem>
                </Menu>
                <Menu
                    id="calendarmenu"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    }}
                    keepMounted
                    open={open === 'calendar-menu'}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClose}><Link className={classes.menuLink}>Calendar</Link></MenuItem>
                    <MenuItem onClick={handleClose}><Link className={classes.menuLink}>Calendar 2</Link></MenuItem>
                </Menu>
                <Menu
                    id="contactsmenu"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    }}
                    keepMounted
                    open={open === 'contacts-menu'}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClose}><Link className={classes.menuLink}>Contact 1</Link></MenuItem>
                    <MenuItem onClick={handleClose}><Link className={classes.menuLink}>Contact 2</Link></MenuItem>
                </Menu>
                <Menu
                    id="dashboardsmenu"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    }}
                    keepMounted
                    open={open === 'dashboards-menu'}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClose}><Link className={classes.menuLink}>Dashboard 1</Link></MenuItem>
                    <MenuItem onClick={handleClose}><Link className={classes.menuLink}>Dashboard 2</Link></MenuItem>
                </Menu>
                <Menu
                    id="reportsmenu"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    }}
                    keepMounted
                    open={open === 'reports-menu'}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClose}><Link className={classes.menuLink}>Report 1</Link></MenuItem>
                    <MenuItem onClick={handleClose}><Link className={classes.menuLink}>Report 2</Link></MenuItem>
                </Menu>
            </div>
        </Toolbar>
        </AppBar>
    );
};

export default Header;

import { CLOSE_CREATE_LEAD_MODAL, OPEN_CREATE_LEAD_MODAL, OPEN_UPDATE_LEAD_MODAL } from "../actions/actionTypes";

const initialState = {
    allLeads: {},
    myLeads: {},
    getLeadById:{},
    error: null,
    isCreateLeadModalOpen: false,
    selectedLeadId: null,
  };

  const newleadReducer = (state = initialState, action) => {
    // console.log(action.type);
    switch (action.type) {
        case 'NEW_ALL_LEADS_SUCCESS':
            return { ...state, allLeads: action.payload, error: null };
        case 'NEW_MY_LEADS_SUCCESS':
            return { ...state, myLeads: action.payload, error: null };
        case 'NEW_CREATE_LEAD_SUCCESS':
        case 'NEW_UPDATE_LEAD_SUCCESS':
        case 'NEW_UPDATE_LEAD_SUCCESS_ADDRESS':
            return {
                ...state,
                myLeads: { ...state.myLeads, ...action.payload },
                allLeads: { ...state.allLeads, ...action.payload },
                error: null,
            };
        case 'GET_LEAD_BY_ID_SUCCESS':
            return {
                ...state,
                myLeads: { ...state.myLeads, ...action.payload },
                allLeads: { ...state.allLeads, ...action.payload },
                getLeadById: { ...state.getLeadById, ...action.payload },
                error: null,
            };
        case OPEN_CREATE_LEAD_MODAL:
            return {...state, isCreateLeadModalOpen: true}
        case OPEN_UPDATE_LEAD_MODAL:
            return {...state, isCreateLeadModalOpen: true, selectedLeadId: action.payload}
        case CLOSE_CREATE_LEAD_MODAL:
            return {...state, isCreateLeadModalOpen: false, selectedLeadId: null}
        case 'NEW_ALL_LEADS_FAILURE':
        case 'NEW_MY_LEADS_FAILURE':
        case 'NEW_CREATE_LEAD_FAILURE':
        case 'NEW_UPDATE_LEAD_FAILURE':
        case 'NEW_UPDATE_LEAD_FAILURE_ADDRESS':
        case 'GET_LEAD_BY_ID_FAILURE':
            return { ...state, error: action.payload };
        default:
            return state;
    }
  };

  export default newleadReducer;
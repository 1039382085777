import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Grid, Button } from '@mui/material';
import { Edit, Email, Phone, Visibility } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { 
  getMyLeads as getMyLeadsAction, 
  openCreateLeadModal as openCreateLeadModalAction, 
  openUpdateLeadModal as openUpdateLeadModalAction  
} from '../../redux/actions/leadActions';
import { getUser as getUserAction } from '../../redux/actions/userActions';
import { useNavigate } from 'react-router-dom';
import CustomDataGrid from '../../shared/CustomDataGrid';
import LeadDetailsModal from './LeadDetailsModal';

const useStyles = makeStyles({
  header: {
    margin: ".5em 0 !important",
  },
});

const MyLeads = () => {
    const classes = useStyles();
    const myLeads = useSelector((state) => state?.leads?.myLeads);
    const loading = useSelector((state) => state.loading.MY_LEADS);
    const user = useSelector((state) => state.user.user);
    const error = useSelector((state) => state?.leads?.error);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const columns = [
      {
        field: 'name',
        headerName: 'Name',
        width: 150,
        editable: true,
        id: 'namecolumn', 
      },
      {
        field: 'email',
        headerName: 'Email',
        width: 150,
        editable: true,
        id: 'emailcolumn', 
      },
      {
        field: 'mobileNumber',
        headerName: 'Phone',
        width: 160,
        editable: true,
        id: 'phonecolumn', 
      },
      {
        field: 'company',
        headerName: 'Company',
        sortable: true,
        width: 160,
        id: 'companycolumn', 
      },
      {
        field: 'leadStatus',
        headerName: 'Lead Status',
        sortable: true,
        width: 160,
        id: 'leadstatuscolumn', 
      },
      {
        field: 'leadSource',
        headerName: 'Lead Source',
        sortable: true,
        width: 160,
        id: 'leadsourcecolumn', 
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 150,
        renderCell: (params) => (
          <div>
            <Email
              style={{ cursor: 'pointer', marginRight: '8px' }}
              onClick={() => handleSendEmail(params.row.email)}
              id={`emailaction${params.row.id}`} 
            />
            <Phone
              style={{ cursor: 'pointer' }}
              onClick={() => handleCallPhone(params.row.phone)}
              id={`phoneaction${params.row.id}`} 
            />
            <Edit
              style={{ cursor: 'pointer' }}
              onClick={() => updateLead(params.row.id)}
              id={`editaction${params.row.id}`} 
            />
            <Visibility
              style={{ cursor: 'pointer', marginRight: '8px' }}
              onClick={() => handleShowDetails(params.row)}
              id={`viewaction${params.row.id}`} 
            />
          </div>
        ),
      },
    ];

    const [myLeadsData, setMyLeadsData] = React.useState(getLeadsData(myLeads) || []);
    const [selectedLeadData, setSelectedLeadData] = React.useState(null);

    React.useEffect(() => {
      if (!user) {
        dispatch(getUserAction());
      } else {
        dispatch(getMyLeadsAction(user?.id));
      }
    }, [dispatch, user]);

    React.useEffect(() => {
      setMyLeadsData(getLeadsData(myLeads));
    }, [myLeads, dispatch]);

    const openCreateLead = () => {
      navigate('/createLead');
    }
    
    const handleCallPhone = (phone) => {
      console.log(phone);
    }
    
    const handleSendEmail = (email) => {
      console.log(email);
    }

    const updateLead = (id) => {
      navigate(`/updateLead/${id}`);
    }

    const handleShowDetails = (lead) => {
      setSelectedLeadData(lead);
    };
  
    const handleCloseDetailsModal = () => {
      setSelectedLeadData(null);
    };

  return (
    <Box sx={{ minHeight: 400, width: '100%' }} id="myleadscontainer"> {/* Added ID */}
      <Grid item xs={12} md={12} sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5" className={classes.header} id="myleadsheader">My Leads</Typography> {/* Added ID */}
        <Button variant="contained" color="primary" sx={{ marginBottom: "1rem", padding: ".5rem 1rem" }} onClick={openCreateLead} id="createleadbutton">Create Lead</Button> {/* Added ID */}
      </Grid>
      <CustomDataGrid
        gridData={myLeadsData}
        columns={columns}
        loading={loading}
        error={error}
        id="myleadsgrid" 
      />
      <LeadDetailsModal leadData={selectedLeadData} onClose={handleCloseDetailsModal} />
    </Box>
  );
}

export default MyLeads;

const getLeadsData = (leads) => {
  if (!Array.isArray(leads)) {
    return [];
  }

  const myLeads = leads.map(lead => ({
    ...lead,
    name: `${lead.firstName} ${lead.lastName}`,
  }));

  const filteredMyLeads = myLeads.filter(item => item.isAccountCreated !== true);

  const sortedLeads = filteredMyLeads.sort((a, b) => {
    const dateA = new Date(a.lastActivity);
    const dateB = new Date(b.lastActivity);
    return dateB - dateA;
  });

  return sortedLeads;
};

import React, { useEffect, useState } from 'react';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Box,
  Typography,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CreateCarrier from './CreateCarrier';
import { getGroupCarrierAction, deleteCarrierByIdAction } from '../../redux/actions/agencyActions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: '#1565c0', // Set header background color to #1565c0
  color: theme.palette.common.white,
  fontFamily: '"Poppins", sans-serif',
}));
 
 
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover, // Odd rows have a subtle background color
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected, // Row background changes on hover
  },
  '& td, & th': {
    padding: theme.spacing(1.5),
    fontFamily: '"Poppins", sans-serif', // Poppins font applied to all cells
  },
}));

const CarrierGroup = () => {
  const [showCarrierGroupScreen, setShowCarrierGroupScreen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false); // To control dialog
  const [selectedGroupId, setSelectedGroupId] = useState(null); // To store the groupId of the group to be deleted

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const carrierDetials = useSelector((state) => state.agency.allGroupCarrier);

  const carriergroupdata = carrierDetials.content || [];

  useEffect(() => {
    dispatch(getGroupCarrierAction(page, rowsPerPage));
  }, [dispatch, page, rowsPerPage]);

  const handleAddNewClick = () => {
    navigate('/CreateCarrier');
  };

  const handleclose = () => {
    setShowCarrierGroupScreen(false);
  };

  const handleOpenDialog = (groupId) => {
    setSelectedGroupId(groupId);
    setOpenDialog(true); // Open confirmation dialog
  };

  const handleCloseDialog = () => {
    setOpenDialog(false); // Close confirmation dialog
  };

  const handleConfirmDelete = () => {
    dispatch(deleteCarrierByIdAction(selectedGroupId)).then(() => {
      dispatch(getGroupCarrierAction(page, rowsPerPage));
    });
    handleCloseDialog(); // Close the dialog after deletion
  };

  const handleEditClick = (group) => {
    navigate('/CreateCarrier', {
      state: {
        group: group,
        isEdit: true,
      },
    });
};


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ padding: '20px' }}>
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="30px" marginTop="30px">
            <Typography variant="h5" sx={{ fontFamily: '"Poppins", sans-serif' }}>Carrier Group</Typography>
            <Button id="addnewbutton" sx={{backgroundColor: '#1565c0', fontFamily: '"Poppins", sans-serif'}} variant="contained" color="primary" onClick={handleAddNewClick}>
              Add New
            </Button>
          </Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Carrier Group Program</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell align='right'>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {carriergroupdata.map((group, index) => (
                  <StyledTableRow key={index}>
                    <TableCell>{group.companyGroupName}</TableCell>
                    <TableCell>{group.status}</TableCell>
                    <TableCell align='right'>
                      <IconButton onClick={() => handleOpenDialog(group.groupId)}>
                        <DeleteIcon />
                      </IconButton>
                      <IconButton onClick={() => handleEditClick(group)}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={carrierDetials.totalElements}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={carrierDetials.totalElements === 0 ? [] : [5, 10, 15, 25, 50, 100]}
            id="tablepagination"
            name="tablePagination"
          />
        </>

      {/* Confirmation Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this carrier group?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CarrierGroup;

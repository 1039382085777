// authActions.js
import * as authService from '../../services/loginServices';
import {
    START_LOADING,
    STOP_LOADING
} from "./actionTypes";
import { closeSessionTimeoutModal } from './sessionTimeOutActions';

export const signUp = (formData) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING, service: 'SIGN_IN' });
    const token = await authService.signUp(formData);
    sessionStorage.setItem('token', token);
    dispatch({ type: STOP_LOADING, service: 'SIGN_IN' });
    dispatch({ type: 'SIGN_UP_SUCCESS', payload: sessionStorage.getItem("token") });
    dispatch(closeSessionTimeoutModal());
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'SIGN_IN' });
    dispatch({ type: 'SIGN_UP_FAILURE', payload: error.errors || 'User name or email id already exist.' });
  }
};

export const signOut = () => async (dispatch) => {
    await dispatch({ type: 'SIGN_OUT_SUCCESS', payload: null });
};

export const signIn = (formData) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING, service: 'SIGN_IN' });
    const token = await authService.signIn(formData);
    sessionStorage.setItem('token', token);
    dispatch({ type: STOP_LOADING, service: 'SIGN_IN' });
    dispatch({ type: 'SIGN_IN_SUCCESS', payload: sessionStorage.getItem('token') });
    dispatch(closeSessionTimeoutModal());
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'SIGN_IN' });

    // Ensure the error has the structure you need
    const errorMessage = error?.errors?.[0]?.errorMessage || 'You have enter wrong User Id & Password';
    dispatch({ type: 'SIGN_IN_FAILURE', payload: errorMessage });

    // Throw the error so that the component can catch it
    throw errorMessage;
  }
};


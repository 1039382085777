import React, { useState } from 'react';
import LocationList from './LocationList';
import AddLocationForm from './AddLocationForm';
import { Box, Button } from '@mui/material';

function LocationManager() {
  const [locations, setLocations] = useState([]);
  const [showForm, setShowForm] = useState(false);

  const addLocation = (location) => {
    // Validation: Ensure location is not empty before adding
    if (!location.name || location.name.trim() === '') {
      alert('Location name is required.');
      return;
    }

    setLocations((prevLocations) => [...prevLocations, location]);
    setShowForm(false);
  };

  const toggleForm = () => {
    setShowForm((prevShowForm) => !prevShowForm);
  };

  return (
    <Box sx={{ padding: 2 }}>
      {showForm ? (
        <AddLocationForm
          addLocation={addLocation}
          toggleForm={toggleForm}
          id="addlocationform"
          name="addLocationForm"
        />
      ) : (
        <LocationList
          locations={locations}
          toggleForm={toggleForm}
          id="locationlist"
          name="locationList"
        />
      )}

    </Box>
  );
}

export default LocationManager;





import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    TextField,
    Box,
    Button,
    Select,
    MenuItem
} from '@mui/material';
import {
    updatedPriorityTaskData
  } from "../../../redux/actions/dashboardAction";

const DashboardPriorityTaskEditPopup = ({ closePopup, savePopup, taskFilterData }) => {
    const dispatch = useDispatch(); 
    const [formData, setFormData] = useState({
        id: 0,
        taskType: '',
        reviewRequired: true,
        customerNumber: 0,
        customerName: '',
        taskTo: '',
        createdBy: '',
        dueDate: '',
        closedDate: '', 
        closedBy: '',
        time: '',
        priority: '',
        status: '',
        typeOfTaskOpen: '',
        taskDescription: '',
        notes: '',
        policyId: 0,
        pubId: null,
        policyNumber: '',
        createdDate: '',
        assignedTo: '',        
        assignedBy: '',
        subject: '', 
    });

    useEffect(() => {
        if (taskFilterData) {
            setFormData({               
                id:taskFilterData.id,
                taskType: taskFilterData.taskType,
                reviewRequired: taskFilterData.reviewRequired,
                customerNumber: taskFilterData.customerNumber,
                customerName: taskFilterData.customerName,
                taskTo: taskFilterData.taskTo,
                createdBy: taskFilterData.createdBy,
                dueDate: taskFilterData.dueDate,
                closedDate: taskFilterData.closedDate || null, 
                closedBy: taskFilterData.closedBy || null,
                time:taskFilterData.time,
                priority: taskFilterData.priority,
                status: taskFilterData.status,
                typeOfTaskOpen: taskFilterData.typeOfTaskOpen,
                taskDescription: taskFilterData.taskDescription,
                notes: taskFilterData.notes,
                policyId: taskFilterData.policyId,
                pubId: taskFilterData.pubId,
                policyNumber: taskFilterData.policyNumber,
                createdDate: taskFilterData.createdDate,
                assignedTo: taskFilterData.assignedTo,
                assignedBy: taskFilterData.assignedBy,            
                subject: taskFilterData.subject,
            });
        }
    }, [taskFilterData]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    
        if (taskFilterData) {
            const updatedTasTablekData = {...formData };
            dispatch(
                updatedPriorityTaskData(updatedTasTablekData, (success) => {
                if (success) {
                  closePopup(true)
                  savePopup()
                }
              })
            );
          }
      }
      const handleCancel = () => {
         closePopup(true)
      }

    return (
        <div className="popup">
            <div className="popup-content">
                <form onSubmit={handleSubmit}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Box sx={{ display: 'flex', flexFlow: 'column', marginBottom: '20px', width: '33%' }}>
                        <label htmlFor="policyNumberInput">Policy Number:</label>
                        <TextField
                            id="policyNumberInput"
                            type="text"
                            name="policynumber"
                            value={formData.policyNumber}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            sx={{ mr: 2 }}
                        />
                    </Box>

                    <Box sx={{ display: 'flex', flexFlow: 'column', marginBottom: '20px', width: '33%' }}>
                        <label htmlFor="dueDateInput">Due Date:</label>
                        <TextField
                            id="dueDateInput"
                            type="date"
                            name="dueDate"
                            value={formData.dueDate}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            sx={{ mr: 2 }}
                        />
                    </Box>
                    
                    <Box sx={{ display: 'flex', flexFlow: 'column', marginBottom: '20px', width: '33%' }}>
                        <label htmlFor="prioritySelect">Priority:</label>
                        <Select
                            id="prioritySelect"
                            name="priority"
                            value={formData.priority}
                            onChange={handleChange}
                            sx={{ mr: 2 }}
                        >
                            <MenuItem value="High">High</MenuItem>
                            <MenuItem value="Urgent">Urgent</MenuItem>
                            <MenuItem value="Medium">Medium</MenuItem>
                        </Select>
                    </Box>

                    <Box sx={{ display: 'flex', flexFlow: 'column', marginBottom: '20px', width: '100%' }}>
                        <label htmlFor="subjectInput">Subject:</label>
                        <TextField
                            id="subjectInput"
                            multiline
                            rows={4}  // Adjust the number of rows as needed
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            sx={{ mr: 2 }}
                        />
                    </Box>
                </Box>
                
                <Box sx={{ display: 'flex', flexFlow: 'row', justifyContent: 'end' }}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleCancel}
                        sx={{ margin: '0 10px' }}
                    >
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        type='submit'
                    >
                        Save
                    </Button>
                </Box>
                </form>
            </div>
        </div>
    );
};

export default DashboardPriorityTaskEditPopup;

import * as React from 'react';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Checkbox, IconButton, Menu, MenuItem, Box } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import { Link,useLocation  } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import AddMenu from './AddMenu';
import RaterMenu from './RaterMenu';
import ActionMenu from './ActionMenu';
import CreatePolicy from '../SidebarLayout/AllAccounts/CreatePolicy';
import { getAllPolicyAction } from '../../redux/actions/policyActions';


const tableDataHeader = {
  "headers": ["F", "Action", "ID Card", "Company", "Status", "Type", "Class", "Policy", "Due", "Eff Date", "Exp/Can Date", "Premium", "Edit", "Details", "Add", "Rater", "Change Status"],
  // "data": [
  //   {
  //     "id": 1,
  //     "company": "PROGRESSIVE FL",
  //     "status": "RENEWED",
  //     "type": "Renewal",
  //     "class": "Personal Auto",
  //     "policy": "UAD381139",
  //     "due": "0",
  //     "effDate": "01-03-2024",
  //     "expCanDate": "07-03-2024",
  //     "premium": "$10.00",
  //   },
  //   {
  //     "id": 2,
  //     "company": "PROGRESSIVE FL",
  //     "status": "RENEWED",
  //     "type": "Renewal",
  //     "class": "Personal Auto",
  //     "policy": "UAD381139",
  //     "due": "0",
  //     "effDate": "01-03-2024",
  //     "expCanDate": "01-03-2024",
  //     "premium": "$3,083.00",
  //   },
  //   {
  //     "id": 3,
  //     "company": "MVR",
  //     "status": "CANCELLED",
  //     "type": "New Business",
  //     "class": "Personal Auto",
  //     "policy": "UAD381139",
  //     "due": "0",
  //     "effDate": "07-03-2023",
  //     "expCanDate": "07-03-2023",
  //     "premium": "$1,025.00",
  //   },
  //   {
  //     "id": 4,
  //     "company": "PROGRESSIVE FL",
  //     "status": "ACTIVE",
  //     "type": "New Business",
  //     "class": "Personal Auto",
  //     "policy": "UAD381139",
  //     "due": "0",
  //     "effDate": "01-03-2023",
  //     "expCanDate": "01-03-2023",
  //     "premium": "$1,025.00",
  //   },
  // ]
};

const tableHeaders = [
  { id: 'f', label: 'F' },
  { id: 'dl', label: 'DL' },
  { id: 'action', label: 'Action' },
  { id: 'id', label: 'ID Card' },
  { id: 'company', label: 'Company' },
  { id: 'status', label: 'Status' },
  { id: 'type', label: 'Type' },
  { id: 'class', label: 'Class' },
  { id: 'policynumber', label: 'Policy' },
  { id: 'paymentdueday', label: 'Due' },
  { id: 'effectivedate', label: 'Eff Date' },
  { id: 'expirationdate', label: 'Exp/Can Date' },
  { id: 'premium', label: 'Premium' },
  { id: 'edit', label: 'Edit' },
  { id: 'delete', label: 'Delete' },
  { id: 'details', label: 'Details' },
  { id: 'add', label: 'Add' },
  { id: 'rater', label: 'Rater' },
  { id: 'changeowner', label: 'Change Status' }
];
// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: theme.palette.common.white,
//     color: theme.palette.common.black,
//     fontWeight: 'bold',
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//     border: 'none',
//   },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   '&:nth-of-type(odd)': {
//     backgroundColor: '#F8F8F8',
//   },
//   '&:nth-of-type(even)': {
//     backgroundColor: theme.palette.common.white,
//   },
//   '& td, & th, & tr': {
//     border: 'none',
//     padding: '0 10px',
//   },
// }));

// const StyledTableContainer = styled(TableContainer)({
//   boxShadow: 'none',
//   '& tr': {
//     border: 'none',
//   },
// });

// const StyledOutlinedButton = styled(Button)(({ theme }) => ({
//   borderColor: '#0173BC',
//   color: '#0173BC',
//   '&:hover': {
//     borderColor: '#0173BC',
//     backgroundColor: 'rgba(1, 115, 188, 0.04)',
//   },
// }));

const DropdownButton = ({ label, options }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
  };

  return (
      <div>
          <Button variant="outlined"  endIcon={<ArrowDropDownIcon />} onClick={handleClick}>
              {label}
          </Button>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
              {options.map((option, index) => (
                  <MenuItem key={index} onClick={handleClose}>
                      {option}
                  </MenuItem>
              ))}
          </Menu>
      </div>
  );
};

const PoliciesList = () => {
  const [showButton,setShowButton]=useState(false);
  const [showHidePolicy, setShowHidePolicy] = useState(false);
  const [tableData, setTableData] = useState([]);
  const methods = useForm();
  const dispatch = useDispatch();
  const policyData = useSelector((state) => state.policy.customizedForm);
    const allPolicies = useSelector((state) => state.policy.allPolicies);

    useEffect(() => {
      dispatch(getAllPolicyAction());
  }, [dispatch]);

  useEffect(() => {
      if (allPolicies.length > 0) {
          setTableData(allPolicies);
      }
  }, [allPolicies]);


  useEffect(() => {
      // Add new policy data to the existing table data
      if (policyData && Object.keys(policyData).length > 0) {
          setTableData((prevData) => [...prevData, policyData]);
      }
  }, [policyData]);


  const handleEditClick = (task) => {
    // Navigate to CreatePolicy component with pre-filled data
    setShowHidePolicy(true)
    setShowButton(false);


    // methods.reset(taskData);
    methods.reset(task);

  };

// const handleDeleteClick = (id) => {
//     dispatch(deleteTaskActionById(id, () => {
//       dispatch(getAllTaskAction());
//     }));
//   };

  function handleClick() {
    setShowHidePolicy(true);
    setShowButton(true);
    console.log("hello")
    // setTableData({});
  }

  return (
    <>
     {showHidePolicy ? (
        <>
         <FormProvider {...methods}>
        <CreatePolicy setShowHidePolicy={setShowHidePolicy} setShowButton={showButton} />
        </FormProvider>
        </>
      ):(
    <>

    <Box sx={{display:'flex', justifyContent: 'end'}}>
    <Button sx={{ margin: 2 }} onClick={handleClick} variant="contained" color="primary">Add New Policy</Button>
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {tableHeaders.map(header => (
                                <TableCell sx={{color: '#0173BC', fontWeight: 'bold'}} key={header.id}>{header.label}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.map((row, index) => (
                            <TableRow key={index}>
                                {tableHeaders.map(header => (
                                    <TableCell key={header.id} sx={{padding: '12px'}}>
                                        {header.id === 'f' ? (
                                            <Checkbox />
                                        ): header.id === 'edit' ? (
                                            // Render Edit icon and handle click event
                                            <IconButton onClick={handleEditClick}>
                                              <EditIcon />
                                            </IconButton>
                                          ): header.id === 'delete' ? (
                                            // Render Edit icon and handle click event
                                            <IconButton >
                                              {/* onClick={() => handleDeleteClick(row.id)} */}
                                              <DeleteIcon />
                                            </IconButton>
                                          ): header.id === 'dl' ? (
                                            <IconButton>
                                                <FileDownloadDoneIcon />
                                            </IconButton>
                                        ) : header.id === 'action' ? (
                                            <DropdownButton label="Action" options={['Option 1', 'Option 2', 'Option 3']} />
                                        ) : header.id === 'details' ? (
                                            <DropdownButton label="Details" options={['Detail 1', 'Detail 2', 'Detail 3']} />
                                        ) : header.id === 'add' ? (
                                            <DropdownButton label="Add" options={['Add 1', 'Add 2', 'Add 3']} />
                                        ) : header.id === 'rater' ? (
                                            <DropdownButton label="Rater" options={['Rater 1', 'Rater 2', 'Rater 3']} />
                                        ) : (
                                            row[header.id]
                                        )}
                                </TableCell>
                            ))}
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
    </>
      )}
    </>
  );
}

export default PoliciesList;

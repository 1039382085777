import apiService from "./apiServices";

export const signUp = async (formData) => {
    try {
      const response = await apiService('/auth/signup', 'POST', formData, null);

      if (!response.ok) {
        const errorData = await response.token;
        throw new Error(errorData.errors?.[0]?.errorMessage || 'An error occurred');
      }
  
      return await response.token;
      } catch (error) {
        throw (error?.message || 'User name or email id already exist.');
      }
}

export const signIn = async (formData) => {
    try {
        
        const response = await apiService('/auth/signin', 'POST', formData, null);
        return response.token;
      } catch (error) {
        throw (error?.message || 'Login failed. Please try again.');
      }
  }

  export const resetPassword = async (formData) => {
    try {
        
        const response = await apiService('/auth/reset_password', 'POST', formData, null);
        return response.token;
      } catch (error) {
        throw (error?.message || 'Reset password failed. Please try again.');
      }
  }

  export const forgotPassword = async (formData) => {
    try {
        
        const response = await apiService('/auth/forgot_password', 'POST', formData, null);
        return response.token;
      } catch (error) {
        throw (error?.message || 'Forgot password service failed. Please try again.');
      }
  }




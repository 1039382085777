const initialState = {
    allDriver: [],
    allVehicle:[],
    allVehicleCoverage:[],
    error: null,
    // isCreateDriverInfoModalOpen: false,
    // selectedDriverInfoId: null,
    driverDetails:[],
    vehicleDetails:[],
    vehicleCoverageDetails:[],
  };

  const policyDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_DRIVER_SUCCESS':
            return { ...state, allDriver: action.payload, error: null };
        case 'GET_VEHICLE_SUCCESS':
            // console.log("all vehcile",action.payload)
            return { ...state, allVehicle: action.payload, error: null };
        case 'GET_VEHICLE_COVERAGE_SUCCESS':
            // console.log("all vehcile",action.payload)
            return { ...state, allVehicleCoverage: action.payload, error: null };
        // case 'CREATE_DRIVER_INFO_SUCCESS':
        // case 'UPDATE_DRIVER_INFO_SUCCESS':
        //     return {
        //         ...state,
        //         allDriverInfo: [ ...state.allDriverInfo, ...action.payload ],
        //         error: null,
        //     };
        // case OPEN_CREATE_DRIVER_INFO_MODAL:
        //     return {...state, isCreateDriverInfoModalOpen: true}
        // case OPEN_UPDATE_DRIVER_INFO_MODAL:
        //     return {...state, isCreateDriverInfoModalOpen: true, selectedDriverInfoId: action.payload}
        // case CLOSE_CREATE_DRIVER_INFO_MODAL:
        //     return {...state, isCreateDriverInfoModalOpen: false, selectedDriverInfoId: null}
        case 'SAVE_DRIVER_INFO_SUCCESS':
            return {
                ...state,
                driverDetails: [ ...state.driverDetails, ...action.payload ],
                error: null,
            };
         case 'SAVE_VEHICLE_INFO_SUCCESS':
            return {
                ...state,
                vehicleDetails: [ ...state.vehicleDetails, ...action.payload ],
                error: null,
            };
        case 'SAVE_VEHICLE_COVERAGE_INFO_SUCCESS':
            return {
                ...state,
                vehicleCoverageDetails: [ ...state.vehicleCoverageDetails, ...action.payload ],
                error: null,
            };
        // case 'CREATE_DRIVER_INFO_FAILURE':
        case 'SAVE_DRIVER_INFO_FAILURE':
        case 'SAVE_VEHICLE_INFO_FAILURE':
        case 'SAVE_VEHICLE_COVERAGE_INFO_FAILURE':
        case 'GET_VEHICLE_FAILURE':
         case 'GET_VEHICLE_COVERAGE_FAILURE':
        case 'GET_DRIVER_FAILURE':
            return { ...state, error: action.payload };
        default:
            return state;
    }
  };

  export default policyDetailReducer;
import React from 'react'
import LoadingImg from '../../assets/loading.gif';

const Loader = () => {
    return(
        <>
            <img src={LoadingImg} alt="" />
        </>
    )
}

export default Loader;
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormLabelText from '../../../common/FormLabelText';
import { FormControl, TextField, FormHelperText, RadioGroup, Grid, Select, MenuItem, Box, FormControlLabel, Radio } from '@mui/material';

const contactInformationFields = [
    {
        fieldName: 'cell',
        label: 'Cell*',
        id:"cell",
        type: 'number',
        placeholder: '___/___/____',
        rules: {
          required: 'Phone Number is required',
          pattern: { value: /^[0-9]{10}$/, message: 'Phone Number must be exactly 10 digits' }
        },
        defaultValue: ''
      },
      {
        fieldName: 'phoneNumber',
        label: 'Phone *',
        id:"phoneNumber",
        type: 'number',
        placeholder: 'Enter the phone number',
        rules: {
          required: 'Phone Number is required',
          pattern: { value: /^[0-9]{10}$/, message: 'Phone Number must be exactly 10 digits' }
        },
        defaultValue: ''
      },
    // {
    //     fieldName: 'phone_2',
    //     label: 'Phone 2',
    //     type: 'number',
    //     placeholder: 'Enter the phone number',
    // },
    // {
    //     fieldName: 'phone_3',
    //     label: 'Phone 3',
    //     type: 'number',
    //     placeholder: 'Enter the phone number',
    // },
    {
        fieldName: 'drivingLicense',
        label: 'Drivers License *',
        id:"drivinglicense",
        type: 'text',
        placeholder: 'Drivers License',
        rules : {
            required: 'Driving License is required',
            maxLength: { value: 7, message: 'Drivers License shuold be 7 number' }
        }
        
    },
    {
        fieldName: 'dlState',
        label: 'DL State *',
        id:"dlstate",
        type: 'dropdown',
        options: ['INTL', 'AL', 'AK', 'AR', 'AZ', 'CA', 'CO'],
        rules : {
            required: 'DL State is required',
        }
    },
    {
        fieldName: 'dateOfBirth',
        label: 'Date of Birth *',
        type: 'date',
        id:"dateofbirth",
        placeholder: 'MM/DD/YYYY',
        rules: {
            required: 'Date of birth is required',
        },
    },
    {   
        fieldName: 'maritalStatus',
        label: 'Marital Status *',
        id:"maritalstatus",
        type: 'dropdown',
        options: ['Single', 'Married', 'Domestic Partnership', 'Separated', 'Divorced', 'Widowed'],
        rules: {
            required: 'Marital Status is required',
        },
    },
    // {
    //     fieldName: 'date_licensed',
    //     label: 'Date Licensed *',
    //     type: 'date',
    //     placeholder: 'MM/DD/YYYY',
    //     rules: {
    //         required: 'Date Licensed is required',
    //     },
    // },
    {
        fieldName: 'ssnTaxId',
        label: 'SSN/Tax ID *',
        type: 'number',
        id:"ssntaxId",
        placeholder: 'xxx-xx-xxxx',
        rules: {
            required: 'SSN/Tax ID is required',
            maxLength: { value: 9, message: 'SSN/Tax should be 9 characters or number' }
        },
    },
    {
        fieldName: 'residencyType',
        label: 'Residency Type',
        type: 'dropdown',
        id:"residencytype",
        placeholder: 'Please selecte value',
        options: ['USA Citizen', 'Other'],
    },
    {
        fieldName: 'gender',
        label: 'Gender',
        id:"gender",
        type: 'radio',
        options: ['Male', 'Female'],
    },
    // {
    //     fieldName: 'homeowner',
    //     label: 'Homeowner?',
    //     type: 'text',
    //     placeholder: 'Enter the value',
    // },
    // {
    //     fieldName: 'boat_owner',
    //     label: 'Boat owner?',
    //     type: 'text',
    //     placeholder: 'Enter the value',
    // },
    // {
    //     fieldName: 'motorcycle_owner',
    //     label: 'Motorcycle Owner?',
    //     type: 'text',
    //     placeholder: 'Enter the value',
    // },
    // {
    //     fieldName: 'business_owner',
    //     label: 'Business Owner?',
    //     type: 'text',
    //     placeholder: 'Enter the value',
    // },
    // {
    //     fieldName: 'website',
    //     label: 'Website',
    //     type: 'text',
    //     placeholder: 'Enter the value',
    // },
    // {
    //     fieldName: 'preferred_contact',
    //     label: 'Preferred Contact',
    //     type: 'dropdown',
    //     placeholder: 'Please selecte value',
    //     options: ['None', 'Text', 'Email'],
    // },
    // {
    //     fieldName: 'use_tobacco',
    //     label: 'Use Tobacco',
    //     type: 'radio',
    //     options: ['Yes', 'No'],
    // },
];

const ContactInformation = () => {
    const { control, formState: { errors } } = useFormContext();
    const [customer, setCustomer] = useState('Personal Lines');
    const [country, setCountry] = useState('');

    const handleChange = (event) => {
        setCustomer(event.target.value);
    };

    const handleChangeCountry = (event) => {
        setCountry(event.target.value);
    };

    return (
        <>
            <Box sx={{ border: '#ccc solid 1px', borderRadius: '4px', padding: '20px', margin: '40px 0 0 0', position: 'relative' }}>
                <FormLabelText labelText="Contact Information" />
                <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{margin: '0'}}>
                    {contactInformationFields.map(({ fieldName, label, rules, type, placeholder, options }) => (
                        <Grid key={fieldName} item xs={12} sm={6} md={3} sx={{ margin: '0', padding: '0 25px !important'}}>
                            <FormControl fullWidth margin="normal" error={!!errors[fieldName]}>
                                <Box sx={{ padding: '0 0 10px 0' }}>
                                    <label htmlFor={fieldName}>{label}</label>
                                </Box>
                                <Controller
                                    name={fieldName}
                                    control={control}
                                    rules={rules}
                                    render={({ field }) => {
                                        if (type === 'text' || type === 'number' || type === 'date') {
                                            return (
                                                <TextField
                                                    {...field}
                                                    inputProps={type === 'number' ? { inputMode: 'numeric', pattern: '[0-9]*', maxLength: 10 } : {}}
                                                    placeholder={placeholder}
                                                    id={fieldName}
                                                    type={type}
                                                    sx={{ width: '100%' }}
                                                    error={!!errors[fieldName]}
                                                />
                                            );
                                        } else if (type === 'dropdown') {
                                            return (
                                                <Select
                                                    {...field}
                                                    sx={{ width: '100%' }}
                                                    error={!!errors[fieldName]}
                                                >
                                                    {options.map((option, index) => (
                                                        <MenuItem key={index} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            );
                                        } else if (type === 'radio') {
                                            return (
                                                <RadioGroup
                                                    {...field}
                                                    row
                                                    aria-labelledby={fieldName}
                                                    defaultValue={options[0]}
                                                    name={fieldName}
                                                >
                                                    {options.map((option, index) => (
                                                        <FormControlLabel key={index} value={option} control={<Radio />} label={option} />
                                                    ))}
                                                </RadioGroup>
                                            );
                                        }
                                        return null;
                                    }}
                                />
                                <FormHelperText>{errors[fieldName]?.message}</FormHelperText>
                            </FormControl>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
}

export default ContactInformation;

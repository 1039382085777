import React, { useState } from 'react';
import { FormGroup, Tooltip, tooltipClasses, Checkbox, FormControl, FormControlLabel, Grid, OutlinedInput, Select, Link, MenuItem, Box } from '@mui/material';
import FormLabelText from '../../../common/FormLabelText';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';

const AddressSection = () => {
    const [customer, setCustomer] = useState('');

    const handleChange = (event) => {
        setCustomer(event.target.value);
    };

    const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 100,
            textAlign: 'center',
        },
    });

    return (
        <>
            <Box sx={{ border: '#ccc solid 1px', borderRadius: '4px', padding: '20px', margin: '40px 0 0 0', position: 'relative' }}>
                <FormLabelText labelText="Address Section" />
                <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={4} md={3} sx={{ margin: '15px 0 0 0' }}>
                        <FormControl fullWidth>
                            <Box sx={{ padding: '0 0 10px 0' }}>
                                <label htmlFor="address">Address</label>
                            </Box>
                            <Grid container spacing={2} sx={{ margin: '0' }}>
                                <Grid xs={11} md={11}>
                                    <OutlinedInput sx={{ width: '100%' }} placeholder='Enter the address' id="address" type="text" />
                                </Grid>
                                <Grid xs={1} md={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Link><EditIcon id="edit-address" /></Link>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} sx={{ margin: '15px 0 0 0' }}>
                        <FormControl fullWidth>
                            <Box sx={{ padding: '0 0 10px 0' }}>
                                <label htmlFor="zip">Zip</label>
                            </Box>
                            <Grid container spacing={2} sx={{ margin: '0' }}>
                                <Grid xs={11} md={11}>
                                    <OutlinedInput sx={{ width: '100%' }} placeholder='Enter the zip code' id="zip" type="text" />
                                </Grid>
                                <Grid xs={1} md={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Link><EditIcon id="edit-zip" /></Link>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} sx={{ margin: '15px 0 0 0' }}>
                        <FormControl fullWidth>
                            <Box sx={{ padding: '0 0 10px 0' }}>
                                <label id="citylabel">City</label>
                            </Box>
                            <Grid container spacing={2} sx={{ margin: '0' }}>
                                <Grid xs={11} md={11}>
                                    <OutlinedInput sx={{ width: '100%' }} placeholder='Enter the city' id="city" type="text" required />
                                </Grid>
                                <Grid xs={1} md={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Link><EditIcon id="editcity" /></Link>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} sx={{ margin: '15px 0 0 0' }}>
                        <FormControl fullWidth>
                            <Box sx={{ padding: '0 0 10px 0' }}>
                                <label id="statelabel">State</label>
                            </Box>
                            <Grid container spacing={2} sx={{ margin: '0' }}>
                                <Grid xs={11} md={11}>
                                    <Select
                                        sx={{ width: '100%' }}
                                        onChange={handleChange}
                                        labelId="state-select-label"
                                        id="stateselect"
                                        value={customer}
                                    >
                                        <MenuItem value="LA">LA</MenuItem>
                                        <MenuItem value="MIAMI">MIAMI</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid xs={1} md={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Link><EditIcon id="editstate" /></Link>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={4} md={3} sx={{ margin: '15px 0 0 0', display: 'flex', alignItems: 'center' }}>
                        <Link href="#" sx={{
                            display: 'flex',
                            color: '#242929',
                        }}>Verify Address
                            <CustomWidthTooltip title="Click here to verify new email address">
                                <InfoOutlinedIcon sx={{ margin: '0 5px', color: '#242929' }} />
                            </CustomWidthTooltip>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} sx={{ margin: '15px 0 0 0' }}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox defaultChecked id="sameaddress" />} label="Same Address" />
                        </FormGroup>
                    </Grid>
                </Grid>

                <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={4} md={3} sx={{ margin: '15px 0 0 0' }}>
                        <FormControl fullWidth>
                            <Box sx={{ padding: '0 0 10px 0' }}>
                                <label htmlFor="mailing-country">Mailing Country</label>
                            </Box>
                            <Grid container spacing={2} sx={{ margin: '0' }}>
                                <Grid xs={11} md={11}>
                                    <Select
                                        onChange={handleChange}
                                        labelId="mailing-country-select-label"
                                        id="mailingcountryselect"
                                        value={customer}
                                        sx={{ width: '100%' }}
                                    >
                                        <MenuItem value="LA">LA</MenuItem>
                                        <MenuItem value="MIAMI">MIAMI</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid xs={1} md={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Link><EditIcon id="editmailingcountry" /></Link>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} sx={{ margin: '15px 0 0 0' }}>
                        <FormControl fullWidth>
                            <Box sx={{ padding: '0 0 10px 0' }}>
                                <label htmlFor="mailing-address">Mailing Address</label>
                            </Box>
                            <Grid container spacing={2} sx={{ margin: '0' }}>
                                <Grid xs={11} md={11}>
                                    <OutlinedInput placeholder='Mailing Address' id="mailingaddress" type="text" sx={{ width: '100%' }} />
                                </Grid>
                                <Grid xs={1} md={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Link><EditIcon id="editmailingaddress" /></Link>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} sx={{ margin: '15px 0 0 0' }}>
                        <FormControl fullWidth>
                            <Box sx={{ padding: '0 0 10px 0' }}>
                                <label htmlFor="mailing-zip">Mailing Zip</label>
                            </Box>
                            <Grid container spacing={2} sx={{ margin: '0' }}>
                                <Grid xs={11} md={11}>
                                    <OutlinedInput placeholder='Mailing Zip' id="mailing-zip" type="text" required sx={{ width: '100%' }} />
                                </Grid>
                                <Grid xs={1} md={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Link><EditIcon id="editmailingzip" /></Link>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} sx={{ margin: '15px 0 0 0' }}>
                        <FormControl fullWidth>
                            <Box sx={{ padding: '0 0 10px 0' }}>
                                <label id="mailingcitylabel">Mailing City</label>
                            </Box>
                            <Grid container spacing={2} sx={{ margin: '0' }}>
                                <Grid xs={11} md={11}>
                                    <OutlinedInput placeholder='Mailing City' id="mailingcity" type="text" required sx={{ width: '100%' }} />
                                </Grid>
                                <Grid xs={1} md={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Link><EditIcon id="editmailingcity" /></Link>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} sx={{ margin: '15px 0 0 0' }}>
                        <FormControl fullWidth>
                            <Box sx={{ padding: '0 0 10px 0' }}>
                                <label id="mailingstatelabel">Mailing State</label>
                            </Box>
                            <Grid container spacing={2} sx={{ margin: '0' }}>
                                <Grid xs={11} md={11}>
                                    <Select
                                        onChange={handleChange}
                                        labelId="mailing-state-select-label"
                                        id="mailingstateselect"
                                        value={customer}
                                        sx={{ width: '100%' }}
                                    >
                                        <MenuItem value="LA">LA</MenuItem>
                                        <MenuItem value="MIAMI">MIAMI</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid xs={1} md={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Link><EditIcon id="editmailingstate" /></Link>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default AddressSection;

import React, { useEffect, useState } from 'react';
import { Button, Typography, Grid, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getThemeAction, saveThemeAction, updateThemeAction } from '../../redux/actions/themeActions';
import { getUser } from '../../redux/actions/userActions';

const CustomThemeSettings = () => {
  const [customTheme, setCustomTheme] = useState({
    primaryColor: "",
    secondaryColor: "",
    themeFont: "",
    fileName: "",
    fileType: "",
    fileSize: 0,
    fileData: "",
  });
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user?.user);
  const theme = useSelector((state) => state?.theme?.theme[0]);
  const loading = useSelector((state) => state.loading?.GET_THEME || state.loading?.SAVE_THEME || state.loading?.UPDATE_THEME );
  const error = useSelector((state) => state.theme?.error)


  useEffect(() => {
    if(user?.customer?.id) {
      dispatch(getThemeAction(user?.customer?.id));
    }
  }, [user]);

  useEffect(() => {
    setCustomTheme(prevTheme => ({
      ...prevTheme,
      primaryColor: theme?.primaryColor,
      secondaryColor: theme?.secondaryColor,
      fileName: theme?.fileName,
      fileType: theme?.fileType,
      fileSize: theme?.fileSize,
      fileData: theme?.fileData,
    }));
  }, [theme])

  const handleColorChange = (color, type) => {
    setCustomTheme(prevTheme => ({
      ...prevTheme,
      [type]: color,
    }));
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      setCustomTheme(prevTheme => ({
        ...prevTheme,
        fileName: file.name,
        fileType: file.type,
        fileSize: file.size,
      }));
    };
    reader?.readAsDataURL(file);
  };

  const handleSubmit = async() => {
    if(theme?.id) {
      const themeUpdated = {...customTheme, customerId: user?.customer?.id, userId: user?.id, id: theme.id}
      await dispatch(updateThemeAction(themeUpdated));
    } else {
      const themeUpdated = {...customTheme, customerId: user?.customer?.id, userId: user?.id}
      await dispatch(saveThemeAction(themeUpdated));
    }
    await dispatch(getThemeAction(user?.customer?.id))
  };

  const resetTheme = async () => {
    if(theme?.id) {
      const themeUpdated = {...customTheme, 
        customerId: user?.customer?.id, 
        userId: user?.id, 
        id: theme?.id,
        primaryColor: "#077BFD",
        secondaryColor: "#000",
        themeFont: "",
        fileName: "default.png",
        fileType: "image/png",
        fileSize: 0,
        fileData: "default",
      }
      await dispatch(updateThemeAction(themeUpdated));
    } else {
      const themeUpdated = {...customTheme,
        customerId: user?.customer?.id, 
        userId: user?.id,
        primaryColor: "#077BFD",
        secondaryColor: "#000",
        themeFont: "",
        fileName: "default.png",
        fileType: "image/png",
        fileSize: 0,
        fileData: "default",
      }
      await dispatch(saveThemeAction(themeUpdated));
    }
    await dispatch(getThemeAction(user?.customer?.id))
  }

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" style={{ marginBottom: '20px' }}>Theme Customization</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography id="primarycolorlabel">Primary Color:</Typography>
          <input
            type="color"
            id="primarycolorinput"
            value={customTheme.primaryColor}
            onChange={(e) => handleColorChange(e.target.value, 'primaryColor')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography id="secondarycolorlabel">Secondary Color:</Typography>
          <input
            type="color"
            id="secondarycolorinput"
            value={customTheme.secondaryColor}
            onChange={(e) => handleColorChange(e.target.value, 'secondaryColor')}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography id="logolabel">Logo:</Typography>
          <img src={customTheme.fileData} width="25%" id="logo-preview" alt="Logo Preview" />
          <input
            type="file"
            id="logoupload"
            accept="image/*"
            onChange={handleLogoChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ mr: '1rem' }}
            id="savethemebutton"
          >
            {loading ? <CircularProgress size={24} color="secondary" /> : "Save Theme"}
          </Button>
          <Button
            variant="contained"
            onClick={resetTheme}
            id="resetthemebutton"
          >
            Reset To Default
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomThemeSettings;

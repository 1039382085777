export const validateEmail = (email) => {
    if (!email) {
        return 'Please enter email address';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
        return 'Invalid email address';
    }
    return '';
};

export const validateField = (value, fieldName) => {
    if (!value) {
        return `Please enter ${fieldName}`;
    }

    return '';
};

// export const validateMobile = (value) => {
//     const isValid = /^\d{10}$/.test(value);

//     if (!isValid) {
//       return 'Please enter a valid 10-digit mobile number.';
//     }

//     return ''; // No error
//   };

export const validateMobile = (value) => {
  const isValid = /^\d{10}$/.test(value);

  if (!isValid) {
    return 'Please enter a valid 10-digit mobile number.';
  }

  return ''; // No error
};


  export const alphanumericValidation = (value) => {
    const isValid = /^[a-zA-Z0-9]+$/.test(value);

    if (!isValid) {
      return 'Please enter only letters and numbers.';
    }

    return ''; // No error
  };

  // export const alphanumericWithSpaceValidation = (value) => {
  //   const isValid = /^[a-zA-Z0-9 ]+$/.test(value);

  //   if (!isValid) {
  //     return 'Please enter only letters and numbers.';
  //   }

  //   return ''; // No error
  // };

  // export const alphanumericWithSpaceValidation = (value) => {
  //   const isValid = /^[a-zA-Z0-9 ]+$/.test(value);
  //   const isValidLength = value.length <= 15;

  //   if (!isValid) {
  //     return 'Please enter only letters and numbers.';
  //   }
  //   if (!isValidLength) {
  //     return 'Text should not exceed 15 characters.';
  //   }

  //   return ''; // No error
  // };

  // export const alphanumericWithSpaceValidation = (value) => {
  //   const isValid = /^[a-zA-Z0-9 ]+$/.test(value);
  //   const isValidLength = value.length <= 50;
  //   const isNotOnlyNumbers = !/^[0-9]+$/.test(value); // Check if the value is not just numbers

  //   if (!isValid) {
  //     return 'Please enter only letters, numbers, and spaces.';
  //   }
  //   if (!isNotOnlyNumbers) {
  //     return 'Please include  letter also.';
  //   }
  //   if (!isValidLength) {
  //     return 'Text should not exceed 50 characters.';
  //   }

  //   return ''; // No error
  // };
  export const alphanumericWithSpaceValidation = (value) => {
    // Trim leading and trailing spaces to check if the value only contains spaces
    const trimmedValue = value.trim();

    const isValid = /^[a-zA-Z0-9 ]+$/.test(value);
    const isValidLength = value.length <= 50;
    const isNotOnlyNumbers = !/^[0-9]+$/.test(value); // Check if the value is not just numbers
    const isNotOnlySpaces = trimmedValue.length > 0; // Check if the value is not only spaces

    if (!isValid) {
      return 'Please enter only letters, numbers, and spaces.';
    }
    if (!isNotOnlyNumbers) {
      return 'Please include a letter also.';
    }
    if (!isNotOnlySpaces) {
      return 'The input cannot consist of only spaces.';
    }
    if (!isValidLength) {
      return 'Text should not exceed 50 characters.';
    }

    return ''; // No error
  };


  // export const zipCodeValidation = (value) => {
  //   const isValidZipCode = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
  //   return !isValidZipCode ? "Please enter valid zipcode" : "";
  // }

  export const zipCodeValidation = (value) => {
    // const isValidZipCode = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
    const isValidZipCode = /^\d{5}$/.test(value);
    return !isValidZipCode ? "Please enter a valid 5-digit zipcode" : "";

  }

  export const apartmentNumberValidation = (value) => {
    // const isValidZipCode = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
    const isValidZipCode = /^\d{5,9}$/.test(value);
    return !isValidZipCode ? "Please enter a valid number between 5 and 9 digits" : "";

  }
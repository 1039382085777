import apiService from "./apiServices";

export const createMaindropdown = async (formData, token) => {
  try {
    
    const response = await apiService('/infoDropdown/', 'POST', formData, null, token);
    return response;
  } catch (error) {
    throw (error?.message || 'Creating main dropdown is failed. Please try again.');
  }
}

export const getMaindropdownBylabel = async (parentLabel, token) => {
  try {
    
    const response = await apiService(`/parentDropdown/byAllFieldLabel/${parentLabel}`, 'GET', null, null, token);
    return response;
  } catch (error) {
    throw (error?.message || 'service is failed. Please try again.');
  }
}

export const getDependentdropdownBylabel = async (parentLabel, token) => {
  try {
    
    const response = await apiService(`/dependentDropdown/byAllFieldLabel/${parentLabel}`, 'GET', null, null, token);
    return response;
  } catch (error) {
    throw (error?.message || 'service is failed. Please try again.');
  }
}

export const createDependencyMappingDropdown = async (formData, token) => {
  try {
    
    const response = await apiService('/parent/', 'POST', formData, null, token);
    return response;
  } catch (error) {
    throw (error?.message || 'Create Mapping dependency dropdown is failed. Please try again.');
  }
}

export const getDependentDropdownByLabel = async (parentLabel, token) => {
  try {
    const response = await apiService(`/dependentDropdown/byAllFieldLabel/${parentLabel}`, 'GET', null, null, token);
    return response;
  } catch (error) {
    throw (error?.message || 'service is failed. Please try again.');
  }
}
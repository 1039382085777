import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Header from './common/Header';
import Footer from './common/Footer';
import About from './pages/About';
import Home from './pages/Home';
import StartProjectForm from './pages/StartProjectForm';
import SignIn from './pages/Login';
import theme from './common/theme';
import AllLeads from './pages/Leads/AllLeads';
import MyLeads from './pages/Leads/MyLeads';
import SignUp from './pages/Signup';
import { useSelector, useDispatch } from 'react-redux';
import CreateLead from './pages/Leads/CreateLead';
import Organizations from './pages/Organizations/Organization';
import CreateOrganization from './pages/Organizations/CreateOrganization';
import Settings from './pages/user/Settings';
import Customers from './pages/Customer/Customers';
import CreateCustomer from './pages/Customer/CreateCustomer';
import Team from './pages/Teams/Team';
import CreateTeam from './pages/Teams/CreateTeam';
import AllAccounts from './pages/Accounts/AllAccount';
import MyAccounts from './pages/Accounts/MyAccount';
import SessionTimeoutModal from './common/SessionTimeOutModal';
import { closeSessionTimeoutModal } from './redux/actions/sessionTimeOutActions';
import { signOut } from './redux/actions/authActions';
import CustomThemeSettings from './pages/user/CustomThemeSettings';
import Roles from './pages/Roles/Roles';
import Permissions from './pages/Permissions/Permissions';
import CreatePermission from './pages/Permissions/createUpdatePermissionModal';
import CreateUpdateRole from './pages/Roles/CreateUpdateRolesModal';
import { accessRules } from './common/helpers/utils';
import { getThemeAction } from './redux/actions/themeActions';
import { getUser } from './redux/actions/userActions';
import CreateUpdateMasterDropDown from './pages/user/CreateUpdateDropDownValues';
import ResetPasswordForm from './pages/ResetPassword';
import ForgotPasswordForm from './pages/ForgotPassword';

    const useStyles = makeStyles({
    mainContainer: {
        minHeight: '90vh',
        padding: '1em',
    }
    });
    const HeaderLayoutRoute = () => {
    const classes = useStyles();
    const token = useSelector(state => state.auth?.token || sessionStorage.getItem('token'));
    const user = useSelector((state) => state?.user?.user);
    const isOpen = useSelector(state => state.sessionTimeoutModal?.isOpen);
    const dispatch = useDispatch();
    const permissions = user?.roleInfo?.permissions || [];
    const rules = accessRules(permissions);

    const dynamicTheme = useSelector((state) => state?.theme?.theme[0]);

        useEffect(() => {
            if(user?.customer?.id) {
                dispatch(getThemeAction(user?.customer?.id))
            }
            
        }, [user])

        const customTheme = createTheme({
        ...theme,  
        palette: {
            primary: {
                main: dynamicTheme?.primaryColor || '#077BFD',
            },
            secondary: {
                main: dynamicTheme?.secondaryColor || '#000',
            },
            iconColors: {
                customIconColor1: dynamicTheme?.primaryColor || '#077BFD',
            }
            },
        });

        useEffect(() => {
            if(!user && token) {
                dispatch(getUser());
            } else if(token === null){
            console.log(token, 'token')
            dispatch(signOut());
            }
        }, [token]);

    const handleCloseSessionTimeoutModal = async () => {
        await dispatch(closeSessionTimeoutModal());
        await dispatch(signOut());
    }
    return(
        <>
            <ThemeProvider theme={customTheme}>
                {token && <Header />}
                <Container maxWidth="x1" className={classes.mainContainer}>
                    <Routes>
                    {!token && <Route path="/*" element={<Navigate to="/login" />} />}
                    {token && <Route path="/" element={<Navigate to="/home" />} />}
                    {token && <Route path="/login" element={<Navigate to="/home" />} />}
                    {token && <Route path="/signup" element={<Navigate to="/home" />} />}
                    {!token && <Route path="/login" element={<SignIn />} />}
                    {!token && <Route path="/reset_password" element={<ResetPasswordForm />} />}
                    {!token && <Route path="/forgotPassword" element={<ForgotPasswordForm />} />}
                    {!token && <Route path="/signup" element={<SignUp />} />}
                    <Route path="/about" element={<About />} />
                    {token && <Route path="/home" element={<Home />} />}
                    <Route path="/contact" element={<About />} />
                    <Route path="/projectForm" element={<StartProjectForm />} />
                    {token && rules.leads && <Route path="/allLeads" element={<AllLeads />} />}
                    {token && rules.leads && <Route path="/myLeads" element={<MyLeads />} />}
                    {token && rules.organizations && <Route path="/organizations" element={<Organizations />} />}
                    {token && rules.customer && <Route path="/customers" element={<Customers />} />}
                    {token && rules.teams && <Route path="/teams" element={<Team />} />}
                    {token && rules.settings && <Route path="/settings" element={<Settings />} />}
                    {token && rules.leads && <Route path="/createLead" element={<CreateLead />} />}
                    {token && rules.leads && <Route path="/updateLead/:leadId" element={<CreateLead />} />}
                    {token && rules.accounts && <Route path="/allAccounts" element={<AllAccounts />} />}
                    {token && rules.theme && <Route path="/customizeThemeSettings" element={<CustomThemeSettings />} />}
                    {token && rules.roles && <Route path="/roles" element={<Roles />} />}
                    {token && rules.permission && <Route path="/permissions" element={<Permissions />} />}
                    {token && rules.accounts && <Route path="/myAccounts" element={<MyAccounts />} />}
                    {token && rules.permission && <Route path="/createDropdown" element={<CreateUpdateMasterDropDown />} />}
                    </Routes>
                    {token && rules.updateOrganization && <CreateOrganization />}
                    {token && rules.updateCustomer &&  <CreateCustomer />}
                    {token && rules.updateTeam && <CreateTeam />}
                    {token && <SessionTimeoutModal open={isOpen} onClose={handleCloseSessionTimeoutModal}/>}
                    {token && rules.permission && <CreatePermission />}
                    {token && rules.roles && <CreateUpdateRole />}
                </Container>
                {token && <Footer />}
            </ThemeProvider>
        </>
    )
}

export default HeaderLayoutRoute;
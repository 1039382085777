import { CLOSE_CREATE_CUSTOMER_MODAL, OPEN_CREATE_CUSTOMER_MODAL, OPEN_UPDATE_CUSTOMER_MODAL } from "../actions/actionTypes";

const initialState = {
    allCustomer: {},
    error: null,
    isCreateCustomerModalOpen: false,
    selectedCustomerId: null,
  };
  
  const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ALL_CUSTOMERS_SUCCESS':
            return { ...state, allCustomer: action.payload, error: null };
        case 'CREATE_CUSTOMER_SUCCESS':
        case 'UPDATE_CUSTOMER_SUCCESS':
            return {
                ...state,
                allCustomer: { ...state.allCustomer, ...action.payload },
                error: null,
            };
        case OPEN_CREATE_CUSTOMER_MODAL:
            return {...state, isCreateCustomerModalOpen: true}
        case OPEN_UPDATE_CUSTOMER_MODAL:
            return {...state, isCreateCustomerModalOpen: true, selectedCustomerId: action.payload}
        case CLOSE_CREATE_CUSTOMER_MODAL:
            return {...state, isCreateCustomerModalOpen: false, selectedCustomerId: null}
        case 'ALL_CUSTOMERS_FAILURE':
        case 'CREATE_CUSTOMER_FAILURE':
        case 'UPDATE_CUSTOMER_FAILURE':
            return { ...state, error: action.payload };
        default:
            return state;
    }
  };
  
  export default customerReducer;
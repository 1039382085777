
import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TablePagination,
  Button,
  Grid,
  TextField,
} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { getBotAllReportAction, searchBotAllReportAction ,searchBotDownloadAllReportAction} from "../../redux/actions/botAction";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';



const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 20,
    padding: 16,
    backgroundColor: "#f5f5f5",
    borderRadius: 8,
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
  },
  table: {
    minWidth: 650,
  },
  tableCell: {
    fontWeight: "bold",
    backgroundColor: "#b0b0b0",
    color: "white",
    textAlign: "center",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#f1f1f1",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  tableContainer: {
    borderRadius: 8,
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
  },
  downloadButton: {
    backgroundColor: "#4CAF50",
    color: "white",
    "&:hover": {
      backgroundColor: "#45a049",
    },
  },
}));

function BotAllReport() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchBot = useSelector((state) => state.bot.searchBot);
  const searchBotDownload = useSelector((state) => state.bot.searchBotDownload);
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [botData, setBotData] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const tableRef = useRef(null);
  const searchreport = searchBot.content || [];
  const searchreportdownload = searchBotDownload.content || [];
  const count = hasSearched ? searchBot.totalElements : 0;


  // Fetch all search results for download
  const fetchAllSearchResults = async () => {
    try {
      const response = await dispatch(searchBotDownloadAllReportAction({ search }, 0, count));
      console.log("response of all data",response);
      return response.content || [];
    } catch (error) {
      console.error('Error fetching all search results:', error);
      return [];
    }
  };

  useEffect(() => {
    if(count>0)
    {
      dispatch(searchBotDownloadAllReportAction({fromDate: fromDate ? dayjs(fromDate).format("MM-DD-YYYY") : null,
        toDate: toDate ? dayjs(toDate).format("MM-DD-YYYY") : null, search }, 0, count));
    }
    }, [dispatch,page, rowsPerPage,count]);

  useEffect(() => {
    if (hasSearched) {
      dispatch(searchBotAllReportAction({fromDate: fromDate ? dayjs(fromDate).format("MM-DD-YYYY") : null,
        toDate: toDate ? dayjs(toDate).format("MM-DD-YYYY") : null, search }, page, rowsPerPage));
    }
  }, [dispatch, page, rowsPerPage, hasSearched, search]);

  useEffect(() => {
    if (hasSearched) {
      setBotData(getBotData(searchreport));
    }
  }, [searchBot, hasSearched]);

  const handleSearch = () => {
    // if (search.trim()) {
      dispatch(searchBotAllReportAction({ fromDate: fromDate ? dayjs(fromDate).format("MM-DD-YYYY") : null,
        toDate: toDate ? dayjs(toDate).format("MM-DD-YYYY") : null, search }));
      setHasSearched(true);
    // }
  };

  const resetSearch = () => {
    setHasSearched(false);
    setSearch("");
    setBotData([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createTableHeader = () => `
    <tr style="background-color: #b0b0b0; color: white;">
      <th style="padding: 8px; text-align: center;">Carrier Name</th>
      <th style="padding: 8px; text-align: center;">Start Date</th>
      <th style="padding: 8px; text-align: center;">Status</th>
      <th style="padding: 8px; text-align: center;">Purchased By</th>
      <th style="padding: 8px; text-align: center;">Agency Code</th>
      <th style="padding: 8px; text-align: center;">Recent Updates</th>
      <th style="padding: 8px; text-align: center;">Create Time</th>
    </tr>
  `;

  const createTableRow = (row) => `
    <tr style="border-bottom: 1px solid #ddd;">
      <td style="padding: 8px;">${row.carrierName}</td>
      <td style="padding: 8px;">${row.startDate}</td>
      <td style="padding: 8px;">${row.status}</td>
      <td style="padding: 8px;">${row.purchasedByUserEmail}</td>
      <td style="padding: 8px;">${row.carrierAgencyCode}</td>
      <td style="padding: 8px;">${row.changes}</td>
      <td style="padding: 8px;">${dayjs(row.insertedOn).format("MM-DD-YYYY, hh:mm:ss A")}</td>
    </tr>
  `;

  const downloadPDF = async () => {
    try {
      setIsDownloading(true);
      // await fetchAllSearchResults();
      const allData=searchreportdownload;

      const pdf = new jsPDF('l', 'mm', 'a4');
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const margins = 15;

      // Add title and search term
      pdf.setFontSize(16);
      pdf.text('Bot Report', margins, margins);
      pdf.setFontSize(12);
      pdf.text(`Search Term: "${search}"`, margins, margins + 10);

      // Calculate rows per page
      const rowHeight = 12;
      const headerHeight = 25;
      const footerHeight = 10;
      const availableHeight = pageHeight - margins * 2 - headerHeight - footerHeight;
      const rowsPerPdfPage = Math.floor(availableHeight / rowHeight);

      // Split data into pages
      for (let i = 0; i < allData.length; i += rowsPerPdfPage) {
        if (i > 0) {
          pdf.addPage();
        }

        const pageData = allData.slice(i, i + rowsPerPdfPage);
        const tableHtml = `
          <table style="width: 100%; border-collapse: collapse; font-size: 12px;">
            ${createTableHeader()}
            ${pageData.map(row => createTableRow(row)).join('')}
          </table>
        `;

        const div = document.createElement('div');
        div.innerHTML = tableHtml;
        document.body.appendChild(div);

        const canvas = await html2canvas(div, {
          scale: 2,
          useCORS: true,
          logging: false,
          backgroundColor: '#ffffff'
        });

        document.body.removeChild(div);

        const imgData = canvas.toDataURL('image/png');
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pageWidth - (margins * 2);
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(imgData, 'PNG', margins, i === 0 ? margins + 15 : margins, pdfWidth, pdfHeight);

        // Add page number
        const pageNumber = `Page ${pdf.internal.getNumberOfPages()}`;
        pdf.setFontSize(10);
        pdf.text(pageNumber, pageWidth - margins - 20, pageHeight - margins);
      }

      // Add generation details on the last page
      const date = new Date().toLocaleDateString();
      pdf.text(`Generated on: ${date}`, margins, pageHeight - margins);
      pdf.text(`Total Records: ${allData.length}`, margins, pageHeight - margins - 5);

      pdf.save(`bot-report-${date}.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setIsDownloading(false);
    }
  };


  return (
    <Container className={classes.container}>
       <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: "bold" }}>Search</Typography>
        </Grid>
        <Grid item xs={3}>
        <DatePicker
          label="From Date"
          value={fromDate}
          onChange={(newDate) => setFromDate(newDate)}
          format="MM-DD-YYYY"
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      </Grid>
      <Grid item xs={3}>
        <DatePicker
          label="To Date"
          value={toDate}
          onChange={(newDate) => setToDate(newDate)}
          format="MM-DD-YYYY"
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Enter search term"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            fullWidth
            id="search"
            variant="contained"
            color="primary"
            onClick={handleSearch}
            // disabled={!search.trim()}
            disabled={ !fromDate || !toDate}
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            fullWidth
            id="reset"
            variant="contained"
            color="secondary"
            onClick={resetSearch}
            disabled={!hasSearched}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      </LocalizationProvider>

      {hasSearched && (
        <>
          <Box className={classes.header}>
            <Typography variant="h4" gutterBottom>
              Bot Report
            </Typography>
            <Button
              variant="contained"
              className={classes.downloadButton}
              onClick={downloadPDF}
              disabled={isDownloading || count === 0}
              startIcon={<DownloadIcon />}
            >
              {isDownloading ? 'Downloading...' : 'Download All'}
            </Button>
          </Box>

          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table} ref={tableRef}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>Carrier Name</TableCell>
                  <TableCell className={classes.tableCell}>Start Date</TableCell>
                  <TableCell className={classes.tableCell}>Status</TableCell>
                  <TableCell className={classes.tableCell}>Purchased By</TableCell>
                  <TableCell className={classes.tableCell}>Agency Code</TableCell>
                  <TableCell className={classes.tableCell}>Recent Updates</TableCell>
                  <TableCell className={classes.tableCell}>Create Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {botData.length > 0 ? (
                botData.map((row) => (
                  <TableRow key={row.id} className={classes.tableRow}>
                    <TableCell>{row.carrierName}</TableCell>
                    <TableCell>{row.startDate}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>{row.purchasedByUserEmail}</TableCell>
                    <TableCell>{row.carrierAgencyCode}</TableCell>
                    <TableCell>{row.changes}</TableCell>
                    <TableCell>
                      {dayjs(row.insertedOn).format("MM-DD-YYYY, hh:mm:ss A")}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <Typography variant="body2">No data found</Typography>
                  </TableCell>
                </TableRow>
              )
              }
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={count === 0 ? [] : [5, 10, 15, 25, 50, 100]}
            />
          </TableContainer>
        </>
      )}
    </Container>
  );
}

export default BotAllReport;

const getBotData = (bots) => {
  if (!Array.isArray(bots)) {
    return [];
  }
  return bots;
};









// import React, { useEffect, useState, useRef } from "react";
// import {
//   Container,
//   Typography,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Box,
//   TablePagination,
//   Button,
//   Grid,
//   TextField,
// } from "@mui/material";
// import DownloadIcon from '@mui/icons-material/Download';
// import dayjs from "dayjs";
// import { makeStyles } from "@mui/styles";
// import { useDispatch, useSelector } from "react-redux";
// import { getBotAllReportAction ,searchBotAllReportAction} from "../../redux/actions/botAction";
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';

// const useStyles = makeStyles((theme) => ({
//   container: {
//     marginTop: 20,
//     padding: 16,
//     backgroundColor: "#f5f5f5",
//     borderRadius: 8,
//     boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
//   },
//   table: {
//     minWidth: 650,
//   },
//   tableCell: {
//     fontWeight: "bold",
//     backgroundColor: "#b0b0b0",
//     color: "white",
//     textAlign: "center",
//   },
//   tableRow: {
//     "&:hover": {
//       backgroundColor: "#f1f1f1",
//     },
//   },
//   header: {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//     marginBottom: 20,
//   },
//   tableContainer: {
//     borderRadius: 8,
//     boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
//   },
//   downloadButton: {
//     backgroundColor: "#4CAF50",
//     color: "white",
//     "&:hover": {
//       backgroundColor: "#45a049",
//     },
//   },
// }));

// function BotAllReport() {
//   const classes = useStyles();
//   const dispatch = useDispatch();
//   const botDetails = useSelector((state) => state.bot.getBotAllReport);
//   const searchBot = useSelector((state) => state.bot.searchBot);
//   const [search, setSearch] = useState("");
//   const [botData, setBotData] = useState([]);
//   const [hasSearched, setHasSearched] = useState(false);
//   console.log("botdetailsearc",searchBot.content)
//   const reports = botDetails.content || [];
//   const searchreport = searchBot.content || [];
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);
//   const tableRef = useRef(null);
//   const count = hasSearched ? searchBot.totalElements : botDetails.totalElements;

//   const fetchAllData = async () => {
//     try {
//       if (hasSearched) {
//         const response = await dispatch(searchBotAllReportAction({ search }, 0, count)).unwrap();
//         return response.content || [];
//       }
//       // const response = await dispatch(getBotAllReportAction(0, count)).unwrap();
//       // return response.content || [];
//     } catch (error) {
//       console.error('Error fetching all data:', error);
//       return [];
//     }
//   };

//   useEffect(() => {
//     if (hasSearched) {
//       dispatch(
//         searchBotAllReportAction({ search },page,rowsPerPage));
//     }
//     // else {
//     //   dispatch(getBotAllReportAction(page, rowsPerPage));
//     // }
//   }, [dispatch, page, rowsPerPage, hasSearched, search]);

//   useEffect(() => {
//     if (hasSearched) {
//       setBotData(getBotData(searchreport));
//     }
//     // } else {
//     //   setBotData(getBotData(reports));
//     // }
//   }, [botDetails,searchBot , hasSearched]);

//   // const handleSearch = () => {
//   //   dispatch(searchBotAllReportAction({ search }, () => {
//   //     setHasSearched(true);
//   //   }));
//   // };

//   const handleSearch = () => {
//     dispatch(searchBotAllReportAction({ search }))
//     setHasSearched(true);
//   };
//   const resetSearch = () => {
//     setHasSearched(false);
//     setSearch("");
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const createTableHeader = () => `
//     <tr style="background-color: #b0b0b0; color: white;">
//       <th style="padding: 8px; text-align: center;">Carrier Name</th>
//       <th style="padding: 8px; text-align: center;">Start Date</th>
//       <th style="padding: 8px; text-align: center;">Status</th>
//       <th style="padding: 8px; text-align: center;">Purchased By</th>
//       <th style="padding: 8px; text-align: center;">Agency Code</th>
//       <th style="padding: 8px; text-align: center;">Recent Updates</th>
//     </tr>
//   `;

//   const createTableRow = (row) => `
//     <tr style="border-bottom: 1px solid #ddd;">
//       <td style="padding: 8px;">${row.carrierName}</td>
//       <td style="padding: 8px;">${row.startDate}</td>
//       <td style="padding: 8px;">${row.status}</td>
//       <td style="padding: 8px;">${row.purchasedByUserEmail}</td>
//       <td style="padding: 8px;">${row.carrierAgencyCode}</td>
//       <td style="padding: 8px;">${row.changes}</td>
//     </tr>
//   `;

//   const downloadPDF = async () => {
//     try {
//       const pdf = new jsPDF('l', 'mm', 'a4'); // Landscape orientation
//       const pageWidth = pdf.internal.pageSize.getWidth();
//       const pageHeight = pdf.internal.pageSize.getHeight();
//       const margins = 15;

//       // Add title to first page
//       pdf.setFontSize(16);
//       pdf.text('Bot Report', margins, margins);

//       // Calculate rows per page (approximately)
//       const rowHeight = 12; // Approximate height of each row in mm
//       const headerHeight = 20; // Height for header section
//       const footerHeight = 10; // Height for footer section
//       const availableHeight = pageHeight - margins * 2 - headerHeight - footerHeight;
//       const rowsPerPdfPage = Math.floor(availableHeight / rowHeight);

//       // Split data into pages
//       for (let i = 0; i < reports.length; i += rowsPerPdfPage) {
//         if (i > 0) {
//           pdf.addPage();
//         }

//         // Create table for current page
//         const pageData = reports.slice(i, i + rowsPerPdfPage);
//         let tableHtml = `
//           <table style="width: 100%; border-collapse: collapse; font-size: 12px;">
//             ${createTableHeader()}
//             ${pageData.map(row => createTableRow(row)).join('')}
//           </table>
//         `;

//         // Create temporary div for the current page's table
//         const div = document.createElement('div');
//         div.innerHTML = tableHtml;
//         document.body.appendChild(div);

//         // Convert to canvas and add to PDF
//         const canvas = await html2canvas(div, {
//           scale: 2,
//           useCORS: true,
//           logging: false,
//           backgroundColor: '#ffffff'
//         });

//         document.body.removeChild(div);

//         // Calculate dimensions to fit page width
//         const imgData = canvas.toDataURL('image/png');
//         const imgProps = pdf.getImageProperties(imgData);
//         const pdfWidth = pageWidth - (margins * 2);
//         const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

//         // Add image to PDF
//         pdf.addImage(imgData, 'PNG', margins, i === 0 ? margins + 10 : margins, pdfWidth, pdfHeight);

//         // Add page number
//         const pageNumber = `Page ${pdf.internal.getNumberOfPages()}`;
//         pdf.setFontSize(10);
//         pdf.text(pageNumber, pageWidth - margins - 20, pageHeight - margins);
//       }

//       // Add generation date on the last page
//       const date = new Date().toLocaleDateString();
//       pdf.text(`Generated on: ${date}`, margins, pageHeight - margins);

//       // Save the PDF
//       pdf.save('bot-report.pdf');
//     } catch (error) {
//       console.error('Error generating PDF:', error);
//     }
//   };

//   // const paginatedRows = reports.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

//   return (
//     <Container className={classes.container}>
//        <Grid container spacing={2} sx={{ mb: 3 }}>
//         <Grid item xs={12}>
//           <Typography sx={{ fontWeight: "bold" }}>Search</Typography>
//         </Grid>
//         <Grid item xs={6}>
//           <TextField
//             fullWidth
//             variant="outlined"
//             placeholder="Enter search term"
//             value={search}
//             onChange={(e) => setSearch(e.target.value)}
//           />
//         </Grid>
//         <Grid item xs={3}>
//           <Button fullWidth id="search" variant="contained" color="primary" onClick={handleSearch} >
//             Search
//           </Button>
//         </Grid>
//         <Grid item xs={3}>
//           <Button fullWidth id="reset" variant="contained" color="secondary"  onClick={resetSearch}>
//             Reset
//           </Button>
//         </Grid>
//       </Grid>
//       <Box className={classes.header}>
//         <Typography variant="h4" gutterBottom>
//           Bot Report
//         </Typography>
//         {hasSearched && (
//         <Button
//           variant="contained"
//           className={classes.downloadButton}
//           onClick={downloadPDF}
//           startIcon={<DownloadIcon />}
//         >
//           Download
//         </Button>
//         )}
//       </Box>
//       {hasSearched && (
//       <TableContainer component={Paper} className={classes.tableContainer}>
//         <Table className={classes.table} ref={tableRef}>
//           <TableHead>
//             <TableRow>
//               <TableCell className={classes.tableCell}>Carrier Name</TableCell>
//               <TableCell className={classes.tableCell}>Start Date</TableCell>
//               <TableCell className={classes.tableCell}>Status</TableCell>
//               <TableCell className={classes.tableCell}>Purchased By</TableCell>
//               <TableCell className={classes.tableCell}>Agency Code</TableCell>
//               <TableCell className={classes.tableCell}>Recent Updates</TableCell>
//               <TableCell className={classes.tableCell}>Create Time</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {botData.map((row) => (
//               <TableRow key={row.id} className={classes.tableRow}>
//                 <TableCell>{row.carrierName}</TableCell>
//                 <TableCell>{row.startDate}</TableCell>
//                 <TableCell>{row.status}</TableCell>
//                 <TableCell>{row.purchasedByUserEmail}</TableCell>
//                 <TableCell>{row.carrierAgencyCode}</TableCell>
//                 <TableCell>{row.changes}</TableCell>
//                 {/* <TableCell>{row.insertedOn}</TableCell> */}
//                 <TableCell>
//   {dayjs(row.insertedOn).format("MM-DD-YYYY, hh:mm:ss A")}
// </TableCell>

//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//         <TablePagination
//         component="div"
//         // count={botDetails.totalElements}
//         count={count}
//         page={page}
//         onPageChange={handleChangePage}
//         rowsPerPage={rowsPerPage}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//         rowsPerPageOptions={count === 0 ? [] : [5, 10, 15,25, 50, 100]}
//       />
//       </TableContainer>
//    )}
//     </Container>
//   );
// }

// export default BotAllReport;

// const getBotData = (bots) => {
//   if (!Array.isArray(bots)) {
//     return [];
//   } else {
//     return bots;
//   }
// };
import apiService from "./apiServices";

export const getUser = async (token) => {
  try {
    const response = await apiService('/users/login-user', 'POST', null, null, token);
    return response;
  } catch (error) {
    throw error?.message || 'Get user services failed. Please try again.';
  }
}
export const createUser = async (formData, token) => {
  try {

    const response = await apiService('/users/add-user', 'POST', formData, null, token);
    return response;
  } catch (error) {
    throw error?.message || 'Creating user is failed. Please try again.';
  }
}

export const updateUser = async (formData, token) => {
  try {

    const response = await apiService('/users/update-user', 'PUT', formData, null, token);
    return response;
  } catch (error) {
    throw error?.message || 'Updating user is failed. Please try again.';
  }
}

export const getUserById = async (userId, token) => {
  try {

    const response = await apiService(`/users/userId/${userId}`, 'GET', null, null, token);
    return response;
  } catch (error) {
    throw error?.message || 'service is failed. Please try again.';
  }
}

export const getAllUser = async (customerId, token) => {
  try {

    const response = await apiService(`/users/all-users/customer/${customerId}`, 'GET', null, null, token);
    return response;
  } catch (error) {
    throw error?.message || 'service is failed. Please try again.';
  }
}

export const getRates = async (data, quoteId, token = "") => {
  try {
    // const headers = {
    //   'Access-Control-Request-Headers': '*',
    // };
    const response = await apiService(`/soapConsume/${quoteId}`, 'GET', null, null, token);
    return response;
  } catch (error) {
    throw new Error(error.message || 'Get user services failed. Please try again.');
  }
}

export const postRatesPearl = async (data, quoteId, token) => {
  try {
    // const headers = {
    //   'Access-Control-Request-Headers': '*',
    // };
    const response = await apiService(`/soapConsume/pearl`, 'POST', data, null, token);
    return response;
  } catch (error) {
    throw new Error(error.message || 'Get user services failed. Please try again.');
  }
}

export const postRatesUnited = async (data, quoteId, token) => {
  try {
    // const headers = {
    //   'Access-Control-Request-Headers': '*',
    // };
    const response = await apiService(`/soapConsume/united`, 'POST', data, null, token);
    return response;
  } catch (error) {
    throw new Error(error.message || 'Get user services failed. Please try again.');
  }
}

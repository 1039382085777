import * as customerServices from '../../services/customerServices';
import {
  CLOSE_CREATE_CUSTOMER_MODAL,
  OPEN_CREATE_CUSTOMER_MODAL,
  OPEN_UPDATE_CUSTOMER_MODAL,
  START_LOADING,
  STOP_LOADING
} from "./actionTypes"
import { openSessionTimeoutModal } from './sessionTimeOutActions';

export const getAllCustomer = () => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'ALL_CUSTOMERS' });
    const token = getState().auth.token || sessionStorage.getItem("token");
    const response = await customerServices.getAllCustomer(token);
    dispatch({ type: STOP_LOADING, service: 'ALL_CUSTOMERS' });
    dispatch({ type: 'ALL_CUSTOMERS_SUCCESS', payload: response });
  } catch (error) {
    if(error?.includes("403")) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'ALL_CUSTOMERS' });
      dispatch({ type: 'ALL_CUSTOMERS_FAILURE', payload: error || 'All Customers service failed. Please try again.' });
    }
  }
};

export const createCustomer = (formData, callbackfn) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'CREATE_CUSTOMER' });
    const token = getState().auth.token || sessionStorage.getItem("token");;
    const response = await customerServices.createCustomer(formData, token);
    dispatch({ type: STOP_LOADING, service: 'CREATE_CUSTOMER' });
    if (response) {
      callbackfn();
    }
    dispatch({ type: 'CREATE_CUSTOMER_SUCCESS', payload: response });
  } catch (error) {
    if(error?.includes("403")) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'CREATE_CUSTOMER' });
      dispatch({ type: 'CREATE_CUSTOMER_FAILURE', payload: error || 'Create Customer service failed. Please try again.' });
    }
  }
};

export const updateCustomer = (formData, callbackfn) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'UPDATE_CUSTOMER' });
    const token = getState().auth.token || sessionStorage.getItem("token");;
    const response = await customerServices.updateCustomer(formData, token);
    dispatch({ type: STOP_LOADING, service: 'UPDATE_CUSTOMER' });
    if (response) {
      callbackfn();
    }
    dispatch({ type: 'UPDATE_CUSTOMER_SUCCESS', payload: response });
  } catch (error) {
    if(error?.includes("403")) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'UPDATE_CUSTOMER' });
      dispatch({ type: 'UPDATE_CUSTOMER_FAILURE', payload: error || 'Update Customer service failed. Please try again.' });
    }
  }
};

export const openCreateCustomerModal = () => ({
  type: OPEN_CREATE_CUSTOMER_MODAL,
});

export const closeCreateCustomerModal = () => ({
  type: CLOSE_CREATE_CUSTOMER_MODAL,
});

export const openUpdateCustomerModal = (id) => async (dispatch) => {
  dispatch({ type: OPEN_UPDATE_CUSTOMER_MODAL, payload: id });
};
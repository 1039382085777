

import axios from 'axios';

const apiService = async (endpoint, method = 'GET', data = null, headers = {}, token, configOverrides = {}) => {
  // Base URL for API
  const BASE_URL = 'https://crmapi.autoairater.com/api/v1';
  const url = `${BASE_URL}${endpoint}`;

  // Add token to headers if provided
  if (token) {
    headers = {
      ...headers,
      'Authorization': `Bearer ${token}`,
    };
  }

  // Default config
  const config = {
    method,
    headers: {
      ...headers,
      "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
      // "Access-Control-Allow-Origin": "http://localhost:3001",
      "Access-Control-Allow-Origin": "*"
    },
    ...configOverrides
  };

  // Handle request data
  if (data) {
    if (data instanceof FormData) {
      config.data = data; // FormData should be sent directly without stringifying
      delete config.headers['Content-Type']; // Let the browser set the Content-Type header
    } else {
      config.data = JSON.stringify(data);
      config.headers['Content-Type'] = 'application/json';
    }
  }

  try {
    // Perform the request
    const response = await axios(url, config);

    // Check if response is a Blob
    const contentType = response.headers['content-type'];
    if (contentType && contentType.startsWith('application/')) {
      return response.data; // This is already a Blob
    }

    // Handle other content types
    return response.data;

  } catch (error) {
    console.error('API service error:', error);
    throw error;
  }
};

export default apiService;

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Grid, Button, } from '@mui/material';
import { Edit, Email, Phone } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { 
  getMyAccounts as getMyAccountsAction, 
    } from '../../redux/actions/accountActions';
import { getUser as getUserAction } from '../../redux/actions/userActions';
import { useNavigate } from 'react-router-dom';
import CustomDataGrid from '../../shared/CustomDataGrid';

const useStyles = makeStyles({
  header: {
    margin: ".5em 0 !important",
  },
  muiEven: {
    backgroundColor: "rgb(245, 245, 245)",
  },
  root: {
    '& .MuiDataGrid-columnHeaders': {
        backgroundColor: "rgb(220, 250, 250)",
    },
    '& .MuiDataGrid-row:hover': {
        backgroundColor: "rgb(240, 250, 250)",
    }
  }
});

const MyAccounts = () => {
    const classes = useStyles();
    const myAccounts = useSelector((state) => state?.accounts?.myAccounts);
    const error = useSelector(state => state?.accounts?.error);
    const loading = useSelector((state) => state.loading.MY_LEADS);
    const user = useSelector((state) => state.user.user)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const columns = [
      {
        field: 'name',
        id:'name',
        headerName: 'Name',
        width: 150,
        editable: true,
      },
      {
        field: 'email',
        id:'email',
        headerName: 'Email',
        width: 150,
        editable: true,
      },
      {
        field: 'mobileNumber',
        id:'phone',
        headerName: 'Phone',
        width: 160,
        editable: true,
      },
      {
        field: 'company',
        id:'company',
        headerName: 'Company',
        sortable: true,
        width: 160,
      },
      {
        field: 'accountStatus',
        id:'accountstatus',
        headerName: 'Account Status',
        sortable: true,
        width: 160,
      },
      {
        field: 'accountSource',
        id:'accountsource',
        headerName: 'Account Source',
        sortable: true,
        width: 160,
      },
      {
        field: 'actions',
        id:'actions',
        headerName: 'Actions',
        width: 150,
        renderCell: (params) => (
          <div>
            <Email
              style={{ cursor: 'pointer', marginRight: '8px' }}
              onClick={() => handleSendEmail(params.row.email)}
            />
            <Phone
              style={{ cursor: 'pointer' }}
              onClick={() => handleCallPhone(params.row.phone)}
            />
          </div>
        ),
      },
    ];

    const [myAccountsData, setMyAccountsData] = React.useState(getAccountsData(myAccounts) || []);

    React.useEffect(() => {
      if(!user) {
        dispatch(getUserAction());
      } else {
        dispatch(getMyAccountsAction(user?.id));
      }
    }, [])

    React.useEffect(() => {
      setMyAccountsData(getAccountsData(myAccounts));
    }, [myAccounts, dispatch, user]);

    const openCreateAccount = () => {
      navigate('/createAccount');
    }
    
    const handleCallPhone = (phone) => {
      console.log(phone);
    }
    
    const handleSendEmail = (email) => {
      console.log(email);
    }

  return (
    <Box sx={{ minHeight: 400, width: '100%' }}>
      <Grid item xs={12} md={12} sx={{display: "flex", justifyContent:"space-between"}}>
        <Typography variant="h5" id="MyAccounts" className={classes.header}>My Accounts</Typography>
        <Button variant="contained" color="primary" sx={{marginBottom: "1rem", padding: ".5rem 1rem"}} onClick={openCreateAccount}>Create Account</Button>
      </Grid>
      <CustomDataGrid 
        gridData={myAccountsData}
        columns={columns}
        loading={loading}
        error={error}
      />
    </Box>
  );
}

export default MyAccounts;

const getAccountsData = (accounts) => {
  if (!Array.isArray(accounts)) {
    return [];
  }

  const myAccounts = accounts.map(account => ({
    ...account,
    name: `${account.firstName} ${account.lastName}`,
  }));

  return myAccounts;
};
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  TextField,
  Button,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  CircularProgress
} from "@mui/material";
import { format } from "date-fns";
import { Link, useNavigate } from 'react-router-dom';
import {
  searchQuoteAction,
  getQuotelisting,
} from "../../redux/actions/quotelistingActions";
import { getGeneralPartyInfoByIdDashboard } from "../../redux/actions/generalPartyInfoActions";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const tableHeaders = [
  { id: "quotenumber", label: "Quote Number" },
  { id: "policynumber", label: "Policy Number" },
  { id: "name", label: "Name" },
  { id: "dateofbirth", label: "Date Of Birth" },
  { id: "phonenumber", label: "Phone Number" },
  { id: "licensenumber", label: "License Number" },
  { id: "effdate", label: "Effective Date" },
  { id: "expdate", label: "Expiry Date" },
];


const QuoteListing = () => {
  const quotes = useSelector((state) => state.quotelisting.allQuotes);
  const searchQuotes = useSelector((state) => state.quotelisting.searchQuotes);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // console.log("quotes",quotes.quotesList.length);
  // console.log("quotes",quotes.totalCount);
  const TotalCount=quotes.totalCount;

  const [search, setSearch] = useState("");
  const [quoteData, setQuoteData] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [quoteToFetch, setQuoteToFetch] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const generalData = useSelector(
    (state) => state.generalPartyInfo.allGeneralPartyInfoDash
  );


  useEffect(() => {
    if (quoteToFetch) {
      dispatch(getGeneralPartyInfoByIdDashboard(quoteToFetch));
    }
  }, [quoteToFetch, dispatch]);

  useEffect(() => {
    if (generalData && Object.keys(generalData).length > 0) {
      navigate("/mainview", { state: { generalData } });
    }
  }, [generalData, navigate]);


  const handleRowClickQuotes = (quoteNumber) => {
    console.log("quoteNumber",quoteNumber)
    if (!quoteNumber) {
      console.error("quoteNumber is undefined or missing.");
      return;
    }
    const numericPart = quoteNumber.replace(/^Q0*/, ""); // Remove leading 'Q' and zeros

    setQuoteToFetch(numericPart);
  };

  useEffect(() => {
    dispatch(getQuotelisting(page, rowsPerPage));
  }, [dispatch, page, rowsPerPage]);

  useEffect(() => {
    if (hasSearched) {
      setQuoteData(getQuoteData(searchQuotes));
    } else {
      setQuoteData(getQuoteData(quotes.quotesList));
    }
  }, [quotes, searchQuotes, hasSearched]);

  const handleSearch = () => {
    dispatch(searchQuoteAction({ search }, () => {
      setHasSearched(true);
    }));
  };

  const resetSearch = () => {
    setHasSearched(false);
    setSearch("");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  const handleChangeRowsPerPage = (event) => {

    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const formatDate = (date) => {
    if (!date) return "";
    return format(new Date(date), 'MM-dd-yyyy');
  };


  return (
    <>
      <Grid container spacing={2} sx={{ fontWeight: "bold", mt: 4, ml: 0.5 }}>
      <Grid item xs={12}>
        <div className="font-bold">Search Quotes</div>
      </Grid>

      <Grid item xs={2}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Enter search term"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="h-10" // Match button height
          sx={{
            '& .MuiOutlinedInput-root': {
              height: '40px', // Standard button height
            }
          }}
        />
      </Grid>

      <Grid item>
        <Button
          id="search"
          variant="contained"
          color="primary"
          onClick={handleSearch}
          size="small"
          sx={{ height: '36px', minWidth: '120px' }}
        >
          Search
        </Button>
      </Grid>

      <Grid item>
        <Button
          id="reset"
          variant="contained"
          color="secondary"
          onClick={resetSearch}
          size="small"
          sx={{ height: '36px', minWidth: '120px' }}
        >
          Reset
        </Button>
      </Grid>
</Grid>

      <TableContainer component={Paper}>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: "bold", mt: 5, ml: 2 }}>Quotes List</Typography>
        </Grid>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <StyledTableCell key={header.id} align={header.align || "left"} sx={{ fontWeight: "bold" }}>
                  {header.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {quoteData.map((row) => (
              <StyledTableRow key={row.quoteNumber}  onClick={() => handleRowClickQuotes(row.quoteNumber)}>
                {/* <StyledTableCell>{row.quoteNumber}</StyledTableCell> */}
                <StyledTableCell>
    {/* <Link to={`/quote/7`} style={{ textDecoration: 'none', color: 'inherit' }}> */}
      {row.quoteNumber}
    {/* </Link> */}
  </StyledTableCell>
                <StyledTableCell>{row.policyNumber}</StyledTableCell>
                <StyledTableCell>{row.name}</StyledTableCell>
                <StyledTableCell>{formatDate(row.dateOfBirth)}</StyledTableCell>
                <StyledTableCell>{row.phoneNumber}</StyledTableCell>
                <StyledTableCell>{row.licenseNumber}</StyledTableCell>
                <StyledTableCell>{formatDate(row.effDate)}</StyledTableCell>
                <StyledTableCell>{formatDate(row.expDate)}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={TotalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          // rowsPerPageOptions={[3, 6, 9]}
          // rowsPerPageOptions={[5, 10, 25]}
          rowsPerPageOptions={TotalCount === 0 ? [] : [5, 10, 15,25]}
        />
      </TableContainer>
    </>
  );
};

export default QuoteListing;

const getQuoteData = (quotes) => {
  if (!Array.isArray(quotes)) {
    return [];
  } else {
    return quotes;
  }
};






// const QuoteListing = () => {
//   const quotes = useSelector((state) => state.quotelisting.allQuotes);
//   const searchQuotes = useSelector((state) => state.quotelisting.searchQuotes);
//   const dispatch = useDispatch();

//   const [search, setSearch] = useState("");
//   const [quoteData, setQuoteData] = useState([]);
//   const [hasSearched, setHasSearched] = useState(false);

//   useEffect(() => {
//     dispatch(getQuotelisting());
//   }, [dispatch]);

//   useEffect(() => {
//     if (hasSearched) {
//       setQuoteData(getQuoteData(searchQuotes));
//     } else {
//       setQuoteData(getQuoteData(quotes));
//     }
//   }, [quotes, searchQuotes, hasSearched]);

//   const handleSearch = () => {
//     dispatch(searchQuoteAction({ search }, () => {
//       setHasSearched(true);
//     }));
//   };

//   const resetSearch = () => {
//     setHasSearched(false);
//     setSearch("");
//   };

//   return (
//     <>
//       <Grid container spacing={2} sx={{ mb: 3 }}>
//         <Grid item xs={12}>
//           <Typography sx={{ fontWeight: "bold" }}>Search Quotes</Typography>
//         </Grid>
//         <Grid item xs={6}>
//           <TextField
//             fullWidth
//             variant="outlined"
//             placeholder="Enter search term"
//             value={search}
//             onChange={(e) => setSearch(e.target.value)}
//           />
//         </Grid>
//         <Grid item xs={3}>
//           <Button fullWidth variant="contained" color="primary" onClick={handleSearch}>
//             Search
//           </Button>
//         </Grid>
//         <Grid item xs={3}>
//           <Button fullWidth variant="contained" color="secondary" onClick={resetSearch}>
//             Reset
//           </Button>
//         </Grid>
//       </Grid>
//       <TableContainer component={Paper}>
//         <Grid item xs={12}>
//           <Typography sx={{ fontWeight: "bold", mt: 5, ml: 2 }}>Quotes List</Typography>
//         </Grid>
//         <Table sx={{ minWidth: 700 }} aria-label="customized table">
//           <TableHead>
//             <TableRow>
//               {tableHeaders.map((header) => (
//                 <StyledTableCell key={header.id} align={header.align || "left"} sx={{ fontWeight: "bold" }}>
//                   {header.label}
//                 </StyledTableCell>
//               ))}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {quoteData.map((row) => (
//               <StyledTableRow key={row.quoteNumber}>
//                 <StyledTableCell>{row.quoteNumber}</StyledTableCell>
//                 <StyledTableCell>{row.policyNumber}</StyledTableCell>
//                 <StyledTableCell>{row.name}</StyledTableCell>
//                 <StyledTableCell>{row.dateOfBirth}</StyledTableCell>
//                 <StyledTableCell>{row.phoneNumber}</StyledTableCell>
//                 <StyledTableCell>{row.licenseNumber}</StyledTableCell>
//                 <StyledTableCell>{row.effDate}</StyledTableCell>
//                 <StyledTableCell>{row.expDate}</StyledTableCell>
//               </StyledTableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </>
//   );
// };

// export default QuoteListing;

// const getQuoteData = (quotes) => {
//   if (!Array.isArray(quotes)) {
//     return [];
//   } else {
//     return quotes;
//   }
// };

// const QuoteListing = () => {
//   const quotes = useSelector((state) => state.quotelisting.allQuotes);
//   const searchQuotes = useSelector((state) => state.quotelisting.searchQuotes);
//   const dispatch = useDispatch();

//   const [search, setSearch] = useState("");
//   const [quoteData, setQuoteData] = useState([]);
//   const [hasSearched, setHasSearched] = useState(false);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(2);

//   useEffect(() => {
//     dispatch(getQuotelisting(page, rowsPerPage));
//   }, [dispatch, page, rowsPerPage]);

//   useEffect(() => {
//     if (hasSearched) {
//       setQuoteData(getQuoteData(searchQuotes));
//     } else {
//       setQuoteData(getQuoteData(quotes));
//     }
//   }, [quotes, searchQuotes, hasSearched]);

//   const handleSearch = () => {
//     dispatch(searchQuoteAction({ search }, () => {
//       setHasSearched(true);
//     }));
//   };

//   const resetSearch = () => {
//     setHasSearched(false);
//     setSearch("");
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(+event.target.value);
//     setPage(0);
//   };

//   return (
//     <>
//       <Grid container spacing={2} sx={{ mb: 3 }}>
//         <Grid item xs={12}>
//           <Typography sx={{ fontWeight: "bold" }}>Search Quotes</Typography>
//         </Grid>
//         <Grid item xs={6}>
//           <TextField
//             fullWidth
//             variant="outlined"
//             placeholder="Enter search term"
//             value={search}
//             onChange={(e) => setSearch(e.target.value)}
//           />
//         </Grid>
//         <Grid item xs={3}>
//           <Button fullWidth variant="contained" color="primary" onClick={handleSearch}>
//             Search
//           </Button>
//         </Grid>
//         <Grid item xs={3}>
//           <Button fullWidth variant="contained" color="secondary" onClick={resetSearch}>
//             Reset
//           </Button>
//         </Grid>
//       </Grid>
//       <TableContainer component={Paper}>
//         <Grid item xs={12}>
//           <Typography sx={{ fontWeight: "bold", mt: 5, ml: 2 }}>Quotes List</Typography>
//         </Grid>
//         <Table sx={{ minWidth: 700 }} aria-label="customized table">
//           <TableHead>
//             <TableRow>
//               {tableHeaders.map((header) => (
//                 <StyledTableCell key={header.id} align={header.align || "left"} sx={{ fontWeight: "bold" }}>
//                   {header.label}
//                 </StyledTableCell>
//               ))}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {quoteData.map((row) => (
//               <StyledTableRow key={row.quoteNumber}>
//                 <StyledTableCell>{row.quoteNumber}</StyledTableCell>
//                 <StyledTableCell>{row.policyNumber}</StyledTableCell>
//                 <StyledTableCell>{row.name}</StyledTableCell>
//                 <StyledTableCell>{row.dateOfBirth}</StyledTableCell>
//                 <StyledTableCell>{row.phoneNumber}</StyledTableCell>
//                 <StyledTableCell>{row.licenseNumber}</StyledTableCell>
//                 <StyledTableCell>{row.effDate}</StyledTableCell>
//                 <StyledTableCell>{row.expDate}</StyledTableCell>
//               </StyledTableRow>
//             ))}
//           </TableBody>
//         </Table>
//         <TablePagination
//           component="div"
//           count={quoteData.length}
//           page={page}
//           onPageChange={handleChangePage}
//           rowsPerPage={rowsPerPage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//         />
//       </TableContainer>
//     </>
//   );
// };

// export default QuoteListing;

// const getQuoteData = (quotes) => {
//   if (!Array.isArray(quotes)) {
//     return [];
//   } else {
//     return quotes;
//   }
// };
import React, { useState } from 'react';
import {
  TextField,
  Button,
  MenuItem,
  Box,
  Typography,
  Grid
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { saveNoteAction } from '../../../redux/actions/noteActions';

const AddNotes = () => {
  const [policyId, setPolicy] = useState('');
  const [noteType, setNoteType] = useState('');
  const [message, setNote] = useState('');
  const dispatch = useDispatch();

  const handlePolicyChange = (event) => {
    setPolicy(event.target.value);
  };

  const handleNoteTypeChange = (event) => {
    setNoteType(event.target.value);
  };

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = {
      policyId,
      noteType,
      message
    };

    dispatch(saveNoteAction(formData, (response) => {
      console.log('Note saved successfully:', response);
      // Add any additional actions after the note is saved successfully
    }));
  };

  const policyOptions = [1, 2, 3];
  const noteTypeOptions = ['New Business', 'Rewrite', 'Re-Issue', 'Renewal', 'Rollover', 'TakeOut'];

  return (
    <Box component="form" noValidate autoComplete="off" id="add-notes-form">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography id="policylabel">Policy</Typography>
          <TextField
            select
            value={policyId}
            onChange={handlePolicyChange}
            variant="outlined"
            fullWidth
            margin="normal"
            id="policyselect"
          >
            <MenuItem value="">
              <em>-Select Policy-</em>
            </MenuItem>
            {policyOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography id="notetypelabel">Note Type</Typography>
          <TextField
            select
            value={noteType}
            onChange={handleNoteTypeChange}
            variant="outlined"
            fullWidth
            margin="normal"
            id="notetypeselect"
          >
            <MenuItem value="">
              <em>-Select Note Type-</em>
            </MenuItem>
            {noteTypeOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <Typography id="notelabel">Note</Typography>
          <TextField
            value={message}
            onChange={handleNoteChange}
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            margin="normal"
            required
            id="notetextfield"
          />
        </Grid>
        <Grid item xs={12}>
          <Button 
            type='submit' 
            variant="contained" 
            color="primary" 
            onClick={handleSubmit} 
            fullWidth 
            id="submitbutton"
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddNotes;

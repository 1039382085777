import apiService from "./apiServices";

export const savePolicy = async(formData, token) => {
    try {

        const response = await apiService('/policy/save', 'POST', formData, null, token);
        return response;
        } catch (error) {
          throw (error?.message || 'Saving policy is failed. Please try again.');
        }
  }

  export const updatePolicy = async (formData, token) => {
    try {

      const response = await apiService('/policy/update', 'PUT', formData, null, token);
      return response;
      } catch (error) {
        throw (error?.message || 'Updating Save policy is failed. Please try again.');
      }
  }

  export const getAllPolicy = async (token) => {
    try {

      const response = await apiService('/policy/all', 'GET', null, null, token);
      return response;

    } catch (error) {
      throw (error?.message || 'service is failed. Please try again.');
    }
  }

  export const deletePolicyById = async (id, token) => {
    try {
      // const headers = {
      //   'Access-Control-Request-Headers': '*'
      // };
      // console.log("id",id);
      const response = await apiService(`/policy/delete/${id}`, 'DELETE', null, null, token);
      // console.log("response",response)
      return response;
    } catch (error) {
      throw new Error(error.message || 'service is failed. Please try again.');
    }
  }



  //policydetails

  export const getDetailPolicy = async (token,id) => {
    try {

      const response = await apiService(`/policy/detailed-view/${id}`, 'GET', null, null, token);
      // console.log("response of pilicy",response);
      return response;

    } catch (error) {
      throw (error?.message || 'service is failed. Please try again.');
    }
  }

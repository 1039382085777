import * as generalPartyInfoServices from '../../services/generalPartyInfoServices';
import {
  CLOSE_CREATE_GENERAL_PARTY_INFO_MODAL,
  OPEN_CREATE_GENERAL_PARTY_INFO_MODAL,
    OPEN_UPDATE_GENERAL_PARTY_INFO_MODAL,
    START_LOADING,
    STOP_LOADING
} from "./actionTypes";
import { storeGpIdWithRandomString } from '../../common/helpers/utils';


// import { fetchPriorInsuranceCarriersRequest, fetchPriorInsuranceCarriersSuccess, fetchPriorInsuranceCarriersFailure } from './actionTypes';

// export const fetchPriorInsuranceCarriers = () => async (dispatch, getState) => {
//   dispatch(fetchPriorInsuranceCarriersRequest());

//   try {
//     const token = getState().auth.token; // Adjust based on where you store the token
//     const response = await generalPartyInfoServices.getPriorInsuranceCarriers(token);
//     const formattedData = response.map(carrier => ({
//       value: carrier.carrierCode,
//       label: carrier.carrierName,
//     }));
//     dispatch(fetchPriorInsuranceCarriersSuccess(formattedData));
//   } catch (error) {
//     dispatch(fetchPriorInsuranceCarriersFailure(error.message));
//   }
// };

import { FETCH_PRIOR_INSURANCE_CARRIERS_SUCCESS, FETCH_PRIOR_INSURANCE_CARRIERS_FAILURE,FETCH_PRIOR_INSURANCE_CARRIERS_REQUEST } from './actionTypes';
// import { getPriorInsuranceCarriers } from '../../services/generalPartyInfoServices';

export const fetchPriorInsuranceCarriers = () => async (dispatch, getState) => {
  try {
    // console.log("Action dispatched");
    dispatch({ type: START_LOADING, service: 'FETCH_PRIOR_INSURANCE_CARRIERS' });
    const token = getState().auth.token ||sessionStorage.getItem("token"); // Adjust based on where you store the token
    const response = await generalPartyInfoServices.getPriorInsuranceCarriers(token);
    // console.log("Action response:", response); // Debug log
    dispatch({ type: STOP_LOADING, service: 'FETCH_PRIOR_INSURANCE_CARRIERS' });
    dispatch({ type: FETCH_PRIOR_INSURANCE_CARRIERS_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'FETCH_PRIOR_INSURANCE_CARRIERS' });
    dispatch({
      type: FETCH_PRIOR_INSURANCE_CARRIERS_FAILURE,
      payload: error.message || 'Failed to fetch prior insurance carriers. Please try again.',
    });
  }
};


export const getAllGeneralPartyInfo = () => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'ALL_GENERAL_PARTY_INFOS' });
    const token = getState().auth.token ||sessionStorage.getItem("token");
    const response = await generalPartyInfoServices.getAllGeneralPartyInfo(token);
    // console.log("response2",response)
    dispatch({ type: STOP_LOADING, service: 'ALL_GENERAL_PARTY_INFOS' });
    dispatch({ type: 'ALL_GENERAL_PARTY_INFOS_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'ALL_GENERAL_PARTY_INFOS' });
    dispatch({ type: 'ALL_GENERAL_PARTY_INFOS_FAILURE', payload: error.message || 'All GeneralPartyInfos service failed. Please try again.' });
  }
};



export const getGeneralPartyInfoById = (quoteId) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'ALL_GENERAL_PARTY_INFOS' });
    const token = getState().auth.token||sessionStorage.getItem("token");
    // console.log("token in general",token);
    const response = await generalPartyInfoServices.getGeneralPartyInfoById(quoteId,token);
    dispatch({ type: STOP_LOADING, service: 'ALL_GENERAL_PARTY_INFOS' });
    dispatch({ type: 'ALL_GENERAL_PARTY_INFOS_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'ALL_GENERAL_PARTY_INFOS' });
    dispatch({ type: 'ALL_GENERAL_PARTY_INFOS_FAILURE', payload: error.message || 'All GeneralPartyInfos service failed. Please try again.' });
  }
};

export const getGeneralPartyInfoByIdDashboard = (quoteId) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'ALL_GENERAL_PARTY_INFOS' });
    const token = getState().auth.token||sessionStorage.getItem("token");
    // console.log("token in general",token);
    const response = await generalPartyInfoServices.getGeneralPartyInfoById(quoteId,token);
    dispatch({ type: STOP_LOADING, service: 'ALL_GENERAL_PARTY_INFOS' });
    dispatch({ type: 'ALL_GENERAL_PARTY_INFOS_DASH_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'ALL_GENERAL_PARTY_INFOS' });
    dispatch({ type: 'ALL_GENERAL_PARTY_INFOS_DASH_FAILURE', payload: error.message || 'All GeneralPartyInfosdash service failed. Please try again.' });
  }
};

export const comparisionResponseById = (gpId) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'ALL_GENERAL_PARTY_INFOS' });
    const token = getState().auth.token||sessionStorage.getItem("token");
    // console.log("token in general",token);
    const response = await generalPartyInfoServices.getComparisionById(gpId,token);
    dispatch({ type: STOP_LOADING, service: 'ALL_GENERAL_PARTY_INFOS' });
    dispatch({ type: 'ALL_COMPARISION_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'ALL_GENERAL_PARTY_INFOS' });
    dispatch({ type: 'ALL_COMPARISION_FAILURE', payload: error.message || 'All Comparision service failed. Please try again.' });
  }
};

export const getPostGeneralPartyInfoByIdAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'ALL_GENERAL_PARTY_INFOS' });
    const token = getState().auth.token||sessionStorage.getItem("token");
    // console.log("token in general",token);
    const response = await generalPartyInfoServices.getPostGeneralPartyInfoById(id,token);
    // console.log("response of dashbaord general",response);
    dispatch({ type: STOP_LOADING, service: 'ALL_GENERAL_PARTY_INFOS' });
    dispatch({ type: 'ALL_GENERAL_PARTY_POST_INFOS_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'ALL_GENERAL_PARTY_INFOS' });
    dispatch({ type: 'ALL_GENERAL_PARTY_INFOS_POST_FAILURE', payload: error.message || 'All GeneralPartyInfos service failed. Please try again.' });
  }
};



// export const createGeneralPartyInfo = (formData, callbackfn) => async (dispatch, getState) => {
//   try {
//     dispatch({ type: START_LOADING, service: 'CREATE_GENERAL_PARTY_INFO' });
//     const token = getState().auth.token || sessionStorage.getItem("token");
//     const response = await generalPartyInfoServices.createGeneralPartyInfo(formData, token);
//     dispatch({ type: STOP_LOADING, service: 'CREATE_GENERAL_PARTY_INFO' });

//     if (response) {
//       // const randomString = storeGpIdWithRandomString(response.gpId);
//       callbackfn(response); // Pass the random string instead of gpId
//     }

//     dispatch({ type: 'CREATE_GENERAL_PARTY_INFO_SUCCESS', payload: response });
//   } catch (error) {
//     dispatch({ type: STOP_LOADING, service: 'CREATE_GENERAL_PARTY_INFO' });
//     dispatch({ type: 'CREATE_GENERAL_PARTY_INFO_FAILURE', payload: error?.body?.message || 'Create GeneralPartyInfo service failed. Please try again.' });
//   }
// };

export const createGeneralPartyInfo = (formData, callbackfn) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'CREATE_GENERAL_PARTY_INFO' });
    const token = getState().auth.token ||sessionStorage.getItem("token");
    const response = await generalPartyInfoServices.createGeneralPartyInfo(formData, token);
    dispatch({ type: STOP_LOADING, service: 'CREATE_GENERAL_PARTY_INFO' });
    if(response) {
      callbackfn(response.gpId);
    }
    dispatch({ type: 'CREATE_GENERAL_PARTY_INFO_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'CREATE_GENERAL_PARTY_INFO' });
    dispatch({ type: 'CREATE_GENERAL_PARTY_INFO_FAILURE', payload: error?.body?.message || 'Create GeneralPartyInfo service failed. Please try again.' });
  }
};

export const updateGeneralPartyInfo = (formData, callbackfn) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'UPDATE_GENERAL_PARTY_INFO' });
    const token = getState().auth.token ||sessionStorage.getItem("token");
    // console.log("token in update",token);
    // console.log("response in updategeneral")
    const response = await generalPartyInfoServices.updateGeneralPartyInfo(formData, token);
    dispatch({ type: STOP_LOADING, service: 'UPDATE_GENERAL_PARTY_INFO' });
    if(response) {
      callbackfn(response.gpId);
    }
    dispatch({ type: 'UPDATE_GENERAL_PARTY_INFO_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'UPDATE_GENERAL_PARTY_INFO' });
    dispatch({ type: 'UPDATE_GENERAL_PARTY_INFO_FAILURE', payload: error?.body?.message || 'Update GeneralPartyInfo service failed. Please try again.' });
  }
};

export const openCreateGeneralPartyInfoModal = () => ({
  type: OPEN_CREATE_GENERAL_PARTY_INFO_MODAL,
});

export const closeCreateGeneralPartyInfoModal = () => ({
  type: CLOSE_CREATE_GENERAL_PARTY_INFO_MODAL,
});

export const openUpdateGeneralPartyInfoModal = (id) => async (dispatch) => {
  dispatch({ type:OPEN_UPDATE_GENERAL_PARTY_INFO_MODAL, payload: id });
};

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Grid, Button } from '@mui/material';
import { Edit, Email, Phone } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import {
  getAllOrganization as getAllOrganizationAction,
  openCreateOrganizationModal as openCreateOrganizationModalAction,
  openUpdateOrganizationModal as openUpdateOrganizationModalAction
} from '../../redux/actions/organizationActions';
import { getUser as getUserAction } from '../../redux/actions/userActions';
import CustomDataGrid from '../../shared/CustomDataGrid';

const useStyles = makeStyles({
  header: {
    margin: ".5em 0 !important",
  },
});

const Organizations = () => {
  const classes = useStyles();
  const organization = useSelector((state) => state?.organizations?.allOrganization);
  const error = useSelector((state) => state?.organization?.error);
  const loading = useSelector((state) => state.loading.ALL_ORGANIZATIONS);
  const user = useSelector((state) => state.user.user)
  const dispatch = useDispatch();

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: true,
      id: 'name',
    },
    {
      field: 'emailId',
      headerName: 'Email',
      width: 150,
      editable: true,
      id: 'emailid',
    },
    {
      field: 'primaryContact',
      headerName: 'Primary Contact',
      width: 160,
      editable: true,
      id: 'primarycontact',
    },
    {
      field: 'secondaryContact',
      headerName: 'Secondary Contact',
      width: 160,
      editable: true,
      id: 'secondarycontact',
    },
    {
      field: 'address',
      headerName: 'Address',
      sortable: true,
      width: 160,
      id: 'address',
    },
    {
      field: 'country',
      headerName: 'Country',
      sortable: true,
      width: 160,
      id: 'country',
    },
    {
      field: 'ipAddress',
      headerName: 'IP Address',
      sortable: true,
      width: 160,
      id: 'ipaddress',
    },
    {
      field: 'houseHold',
      headerName: 'House Hold',
      sortable: true,
      width: 160,
      id: 'household',
    },
    {
      field: 'creditScore',
      headerName: 'Credit Score',
      sortable: true,
      width: 160,
      id: 'creditscore',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <div>
          <Email
            style={{ cursor: 'pointer', marginRight: '8px' }}
            onClick={() => handleSendEmail(params.row.emailId)}
            id={`emailaction${params.row.id}`}
          />
          <Phone
            style={{ cursor: 'pointer' }}
            onClick={() => handleCallPhone(params.row.primaryContact || params.row.secondaryContact)}
            id={`phoneaction${params.row.id}`}
          />
          <Edit
            style={{ cursor: 'pointer' }}
            onClick={() => updateOrganization(params.row.id)}
            id={`editaction${params.row.id}`}
          />
        </div>
      ),
    },
  ];

  const [organizationData, setMyOrganizationsData] = React.useState(organization || []);

  React.useEffect(() => {
    if (!user) {
      dispatch(getUserAction());
    } else {
      dispatch(getAllOrganizationAction(user?.id));
    }
  }, [user])

  React.useEffect(() => {
    setMyOrganizationsData(organization || []);
  }, [organization, dispatch, user]);

  const openCreateOrganization = () => {
    dispatch(openCreateOrganizationModalAction());
  }

  const handleCallPhone = (phone) => {
    console.log(phone);
  }

  const handleSendEmail = (email) => {
    console.log(email);
  }

  const updateOrganization = (id) => {
    dispatch(openUpdateOrganizationModalAction(id));
  }

  return (
    <Box sx={{ minHeight: 400, width: '100%' }}>
      <Grid item xs={12} md={12} sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography id="organization" variant="h5" className={classes.header}>Organizations</Typography>
        <Button id="createorganization" variant="contained" color="primary" sx={{ marginBottom: "1rem", padding: ".5rem 1rem" }} onClick={openCreateOrganization}>Create Organization</Button>
      </Grid>
      <CustomDataGrid
        gridData={organizationData}
        columns={columns}
        loading={loading}
        error={error}
      />
    </Box>
  );
}

export default Organizations;

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Grid,List,Card,CardHeader,ListItemButton,ListItemText,ListItemIcon,Checkbox,Button,Divider} from '@mui/material';
import {Box,FormGroup,FormControlLabel,MenuItem,FormControl,Select,InputLabel} from '@mui/material';
import {
  dependencyMappingDropdown as dependencyMappingDropdownAction,getDependentDropdownByLabel } from '../../../../redux/actions/dropdownActions';

  import {
    getAllUser as getAllUserAction
  } from '../../../../redux/actions/userActions';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function DependencyDropdownForm() {

  const allUser = useSelector((state) => state?.user?.allUser);
  const loading = useSelector((state) => state.loading.ALL_USERS);
  const user = useSelector((state) => state.user.user)
  const error = useSelector((state) => state?.user?.error);

  const dispatch = useDispatch();
  const [checked, setChecked] = React.useState([]);
  // const [left, setLeft] = React.useState([0, 1, 2,3,4,5]);
  const city =['Jacksonville','Hollywood','Columbus','Savannah','Macon','Columbia','Charleston','North Charleston','Mount Pleasant']
  const [left, setLeft] = React.useState(city);

  const [right, setRight] = React.useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

/// Get data
const [allUserData, setMyAllUsersData] = React.useState(allUser || []);
React.useEffect(() => {
      dispatch(getDependentDropdownByLabel('CITIES'));
}, [])

// React.useEffect(() => {
//   setMyAllUsersData(allUser || []);
// }, [allUser, dispatch, user]);
// console.log(allUserData);
//// end

  const [state, setState] = React.useState('Florida');

  const handleChange = (event) => {
    setState(event.target.value);
  }
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };
  const customList = (title, items) => (
    <Card>
      <Divider />
      <List
        sx={{
          width: 200,
          height: 230,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;
          const isSelected = checked.indexOf(value) !== -1;
          return (
            <ListItemButton
              key={value}
              role="listitem"
              id="listitembutton"
              onClick={handleToggle(value)}
              sx={{ bgcolor: isSelected ? '#E7E7E7' : 'inherit', color: isSelected ? '#0173BC' : 'inherit' }}
            >
              <ListItemText id={labelId} primary={`${value}`} />
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );

 const handleClick=(e)=>{
  const childRequestList = right.map(city => ({ name: city }));
  let mappingData ={name:state,childRequestList:childRequestList};

       //await dispatch(dependencyMappingDropdownAction(newFormData, getCreateRoleSuccess));
       dispatch(dependencyMappingDropdownAction(mappingData, ''));
 }

  return (
    <>
    <Box sx={{ m: 1, minWidth: 520 }} item xs={12}>
    <FormControl required sx={{ m: 1, minWidth: 520 }}>
        <InputLabel id="demosimple-select-label">Select Form</InputLabel>
        <Select labelId="demo-simple-select-label"
          id="demosimpleselect"
          value=""
          label="city">
          <MenuItem value="Account">Account</MenuItem>
          <MenuItem value="User">User</MenuItem>
          <MenuItem value="Admin">Admin</MenuItem>
        </Select>
      </FormControl>
    <br/>
       <FormControl required sx={{ m: 1, minWidth: 520 }}>
        <InputLabel id="demo-simple-select-label">Controlling Field</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demosimpleselect"
          value=""
          label="City"
          >
          <MenuItem value="AK">Alaska</MenuItem>
          <MenuItem value="AL">Alabama</MenuItem>
          <MenuItem value="AR">Arkansas</MenuItem>
          <MenuItem value="AZ">Arizona</MenuItem>
        </Select>
      </FormControl>
      <FormControl required sx={{ m: 1, minWidth: 520 }}>
        <InputLabel id="demo-simple-select-label">Dependent Field</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demosimpleselect"
          value=""
          label="city"
        >
          <MenuItem value="AK">Alaska</MenuItem>
          <MenuItem value="AL">Alabama</MenuItem>
          <MenuItem value="AR">Arkansas</MenuItem>
          <MenuItem value="AZ">Arizona</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 520 }}>
        <InputLabel id="demo-simple-select-label">State</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demosimpleselect"
          value={state}
          label="State"
          onChange={handleChange}
        >
          <MenuItem value="Florida" selected>Florida</MenuItem>
          <MenuItem value="Georgia">Georgia</MenuItem>
          <MenuItem value="South Carolina">South Carolina</MenuItem>
          <MenuItem value="Texas">Texas</MenuItem>
        </Select>
      </FormControl>
   </Box>
    <Grid container spacing={2}>
      <Grid item>{customList('Choices', left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList('Chosen', right)}</Grid>
    </Grid><br></br>
    <Grid>
        <Button id="cancelbutton" variant="outlined">Cancel</Button> &nbsp;&nbsp;
        <Button id="savebutton" variant="contained" onClick={handleClick}>Save</Button>
    </Grid>
  </>
  );
}
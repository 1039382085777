const initialState = {
    locationForm: JSON.parse(localStorage.getItem('locationForm')) || {},
    allLocation: [],
    // searchTask:[],
    getAllRolesList: [],
    error: null,
  };

  const locationReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_LOCATION_SUCCESS':
        // localStorage.setItem('customizedForm', JSON.stringify(action.payload));
        // return { customizedForm: action.payload, error: null };
        return { ...state, allLocation: action.payload, error: null };
        case 'GET_ALL_ROLES_LIST':
          return { ...state, getAllRolesList: action.payload, error: null };   
        case 'DELETE_LOCATION':
          return { ...state, allLocation: action.payload, error: null };

      case 'SAVE_LOCATION_SUCCESS':
        localStorage.setItem('locationForm', JSON.stringify(action.payload));
        return { ...state, locationForm: action.payload, error: null };
    //   case 'SEARCH_TASK_SUCCESS':
    //         return { ...state, searchTask: action.payload, error: null };
      case 'UPDATE_LOCATION_SUCCESS':
        localStorage.setItem('locationForm', JSON.stringify(action.payload));
        return { ...state, locationForm: action.payload, error: null };
    //   case 'DELETE_TASK_SUCCESS':
    //     const updatedTask = state.allTask.filter(policy => policy.id !== action.payload);
    //     return { ...state, allTask: updatedTask, error: null };
      case 'GET_LOCATION_FAILURE':
      case 'SAVE_LOCATION_FAILURE':
    //   case 'DELETE_LOCATION_FAILURE':
    //   case 'SEARCH_LOCATION_FAILURE':
      case 'UPDATE_LOCATION_FAILURE':
        return { ...state, error: action.payload };
      default:
        return state;
    }
  };

  export default locationReducer;

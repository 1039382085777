import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setDashboardData,
    setDashboardPolicyData,
    fetchAllPolicyTypeAction,
    fetchTaskTypeAction,
    fetchTaskDataAction,
    fetchPriorityTaskAction
} from '../../../redux/actions/dashboardAction';
import {
    Box,
    Paper,
    Typography,
    Grid,
    Radio,
    RadioGroup,
    FormControlLabel,
} from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import PolicyInfo from './PolicyInfo';
import QuoteTableData from './TableQuote';
import DashboardPolicyData from './DashboardPolicyData';
import DashboardTaskList from './DashboardTaskList';
import DashboardTaskPriority from './DashboardTaskPriority';
import DashboardPolicyType from './DashboardPolicyType';
import DashboardTypeOfTaskOpen from './DashboardTypeOfTaskOpen';
import PolicyDetails from './PolicyDetails';

const Dashboard = () => {
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedQuoteFilter, setSelectedQuoteFilter] = useState('');
    const [selectedChartType, setSelectedChartType] = useState('count'); // State for chart type
    const [chartType, setChartType] = useState('count'); // Default to count
    const [policyChartType, setPolicyChartType] = useState('typecount'); // Default to count

    const policyDataRef = useRef(null);
    const policyTypeRef = useRef(null);
    const quoteDataRef = useRef(null);
    const taskDataRef = useRef(null);
    const priorityTaskRef = useRef(null);
    const openTaskRef = useRef(null);

    useEffect(() => {
        dispatch(setDashboardData());
        dispatch(setDashboardPolicyData());
        dispatch(fetchAllPolicyTypeAction());
        dispatch(fetchTaskTypeAction());
        dispatch(fetchPriorityTaskAction());        
        dispatch(fetchTaskDataAction());        
    }, [dispatch]);

    const transformKeys = (obj) => {
        const newObj = {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const newKey = key.replace(/\s+/g, '').toLowerCase();
                newObj[newKey] = obj[key];
            }
        }
        return newObj;
    };

    const policystatusCounts = useSelector(
        (state) => state.dashboardData.policies?.lineOfBusinessCounts
    ) || [];
    const policyDataByPremium = useSelector(
        (state) => state.dashboardData.policies?.lineOfBusinessPremium
    ) || [];

    const policyTypeCounts = useSelector(
        (state) => state.dashboardData.all_policy_type?.policyTypeCounts
    ) || [];
    const policyTypeByPercentage = useSelector(
        (state) => state.dashboardData.all_policy_type?.policyTypePercentages
    ) || [];
    const policyTypeByPremium = useSelector(
        (state) => state.dashboardData.all_policy_type?.policyTypePremiums
    ) || [];
    const policy_type_total_count = useSelector(
        (state) => state.dashboardData.all_policy_type?.totalCount
    ) || [];

    const quoteStatusCount = useSelector(
        (state) => state.dashboardData.quotes?.statusCounts
    ) || [];
    const quoteStatusCountPercentage = useSelector(
        (state) => state.dashboardData.quotes?.statusPercentages
    ) || [];
    const quote_total_count = useSelector(
        (state) => state.dashboardData.quotes?.totalCount
    ) || [];


    const taskTotalCount = useSelector(
        (state) => state.dashboardData.all_task?.totalCount
    ) || [];
    const taskCount = useSelector(
        (state) => state.dashboardData.all_task?.statusCounts
    ) || [];
    const taskCountByPercentage = useSelector(
        (state) => state.dashboardData.all_task?.statusPercentages
    ) || [];

    const priority_task_total_count = useSelector(
        (state) => state.dashboardData.all_priority_task?.totalCount
    ) || [];
    const priority_task_count = useSelector(
        (state) => state.dashboardData.all_priority_task?.taskTypeCounts
    ) || [];
    const priority_task_percentage = useSelector(
        (state) => state.dashboardData.all_priority_task?.taskTypePercentages
    ) || [];

    const task_type_count = useSelector(
        (state) => state.dashboardData.all_task_type?.totalCount
    ) || [];
    const open_task_count = useSelector(
        (state) => state.dashboardData.all_task_type?.taskTypeCounts
    ) || [];
    const open_task_percentage = useSelector(
        (state) => state.dashboardData.all_task_type?.taskTypePercentages
    ) || [];


    const policydata = transformKeys(policystatusCounts);
    const policy_by_premium = transformKeys(policyDataByPremium);

    const policyTypeData = transformKeys(policyTypeCounts);
    const policyTypeByPercentageCount = transformKeys(policyTypeByPercentage);
    const policyTypePremium = transformKeys(policyTypeByPremium);

    const opentaskcount = transformKeys(open_task_count);
    const opentaskpercentage = transformKeys(open_task_percentage);

    const task_count = transformKeys(taskCount);
    const task_count_percentage = transformKeys(taskCountByPercentage);

    const priortyTaskCount = transformKeys(priority_task_count);
    const priortyTaskCountPercentage = transformKeys(priority_task_percentage);    

    const quoteCount = transformKeys(quoteStatusCount);
    const quotePercentageCount = transformKeys(quoteStatusCountPercentage);
    

    const handleChartClick = (index) => {
        setSelectedTab(index); 
        switch (index) {
            case 1:
                policyDataRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 2:
                policyTypeRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 3:
                quoteDataRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 4:
                taskDataRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 5:
                priorityTaskRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 6:
                openTaskRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            default:
                break;
        }
    };

    const handleRowClick = (params) => {
        setSelectedRow(params.row);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedRow(null);
    };

    const pieChartsData = {
        policiesData: chartType === 'count'
        ? [
            {id: 'personalauto', name: 'Personal Auto', value: policydata.personalauto, color: '#344BFD' },
            {id: 'commercialauto', name: 'Commercial Auto', value: policydata.commercialauto, color: '#FFBB28' },
            {id: 'homeowners', name: 'Homeowners', value: policydata.homeowners, color: '#FF8042' },
            {id: 'health', name: 'Health', value: policydata.health,  color: '#92CA67' },
            {id: 'generalliability', name: 'General Liability', value: policydata.generalliability, color: '#72413A' },
        ]
        : [
            {id: 'personalauto', name: 'Personal Auto', value: policy_by_premium.personalauto, color: '#344BFD' },
            {id: 'Commercialauto', name: 'Commercial Auto', value: policy_by_premium.commercialauto, color: '#FFBB28' },
            {id: 'homeowners', name: 'Homeowners', value: policy_by_premium.homeowners, color: '#FF8042' },
            {id: 'health', name: 'Health', value: policy_by_premium.health, color: '#92CA67' },
            {id: 'generalliability', name: 'General Liability', value: policy_by_premium.generalliability, color: '#72413A' },
        ],
        policyTypeData: policyChartType === 'typecount' ? [
            {id: 'newbusinesstype', name: 'New Business', value: policyTypeData.newbusiness, percentage: policyTypeByPercentageCount.newbusiness, color: '#F68D2B' },
            {id: 'renewaltype', name: 'Renewal', value: policyTypeData.renewal, percentage: policyTypeByPercentageCount.renewal, color: '#F4A79D' },
            {id: 'rewritetype', name: 'Rewrite', value: policyTypeData.rewrite, percentage: policyTypeByPercentageCount.rewrite, color: '#344BFD' },
        ]: [
            {id: 'newbusiness', name: 'New Business', value: policyTypePremium.newbusiness, color: '#F68D2B' },
            {id: 'renewal', name: 'Renewal', value: policyTypePremium.renewal, color: '#F4A79D' },
            {id: 'rewrite', name: 'Rewrite', value: policyTypePremium.rewrite, color: '#344BFD' },
        ],
        quoteData: [
            {id: 'sold', name: 'Sold', value: quoteCount?.sold, percentage: quotePercentageCount?.sold , color: '#344BFD' },
            {id: 'notsold', name: 'Not Sold', value: quoteCount?.notsold, percentage: quotePercentageCount.notsold, color: '#FF8042' },
        ],
        taskData: [
            {id: 'close', name: 'Close', value: task_count.closed, percentage: task_count_percentage.closed, color: '#344BFD' },
            {id: 'open', name: 'Open', value: task_count.open, percentage: task_count_percentage.open, color: '#FFBB28' },
            {id: 'duetaskdate', name: 'Due Task Date', value: task_count.duetaskdate, percentage: task_count_percentage.duetaskdate, color: '#FF8042' },
        ],
        priorityTaskData: [
            {id: 'urgentdata', name: 'Urgent', value: priortyTaskCount.urgent, percentage: priortyTaskCountPercentage.urgent, color: '#344BFD' },
            {id: 'highPrioritydata', name: 'High Priority', value: priortyTaskCount.high, percentage: priortyTaskCountPercentage.high, color: '#FFBB28' },
            {id: 'mediumprioritydata', name: 'Medium Priority', value: priortyTaskCount.medium, percentage: priortyTaskCountPercentage.medium, color: '#FF8042' },
        ],
        typeofTaskOpenData: [
            {id: 'renewal', name: 'Renewal', value: opentaskcount.renewal, percentage: opentaskpercentage.renewal, color: '#344BFD' },
            {id: 'cancellation', name: 'Cancellation', value: opentaskcount.cancellation, percentage: opentaskpercentage.cancellation, color: '#F4A79D' },
            {id: 'pendingcancellation', name: 'Pending Cancellation', value: opentaskcount.pendingcancellation, percentage: opentaskpercentage.pendingcancellation, color: '#92CA67' },
            {id: 'UW', name: 'UW', value: opentaskcount.uw, percentage: opentaskpercentage.uw, color: '#F68D2B' },
        ],
        countData: [
            {id: 'urgentcount', name: 'Urgent', value: 70, color: '#344BFD' },
            {id: 'highprioritycount', name: 'High Priority', value: 10, color: '#FFBB28' },
            {id: 'mediumpropertycount', name: 'Medium Priority', value: 20, color: '#FF8042' },
        ],
        percentageData: [
            {id: 'urgentpercentage', name: 'Urgent', value: 70, color: '#344BFD' },
            {id: 'highprioritypercentage', name: 'High Priority', value: 10, color: '#FFBB28' },
            {id: 'mediumprioritypercentage', name: 'Medium Priority', value: 20, color: '#FF8042' },
        ],
    };

    const handleChartTypeChange = (event) => {
        setSelectedChartType(event.target.value);
    };

    // Function to render pie chart with count and percentage
    const renderPieChartPolicy = (label, chartData, index) => {
        const totalValue = chartData.reduce((sum, item) => sum + (item.value || 0), 0); // Total for percentage calculation

        return (
            <Box sx={{ width: '33%', padding: '20px', boxSizing: 'border-box' }}>
                <Paper sx={{ padding: '20px',
                    cursor: 'pointer',
                    border: selectedTab === index ? '1px solid #E5E5EF' : '1px solid #E5E5EF',
                    boxShadow: selectedTab === index ? '0px 0px 10px rgba(0, 123, 255, 0.5)' : 'none', }} onClick={() => handleChartClick(index)}>
                    <Box sx={{ width:'100%', display: 'flex', justifyContent: 'space-between', fontFamily: '"Poppins", sans-serif', borderBottom: '#E5E5EF solid 1px', paddingBottom: '10px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'end', flexFlow: 'column', alignItems:' start' }}>
                            <span id="statistics">Statistics</span>
                            <strong id="policies">Policies</strong>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'end', flexFlow: 'column', alignItems:' end' }}>
                            <span id="Total">Total {chartType === 'count' ? 'Count' : 'Premium'}</span>
                            <strong>{totalValue}</strong>
                        </Box>
                    </Box>
                    <Box>
                    <RadioGroup
    row
    aria-label="chart-type"
    name="chart-type"
    value={chartType}
    onChange={(e) => setChartType(e.target.value)}
    id="charttyperadiogroup"  
>
    <FormControlLabel 
        value="count" 
        control={<Radio id="charttypecount" />} 
        label="By Count" 
    />
    <FormControlLabel 
        value="premium" 
        control={<Radio id="charttypepremium" />}  
        label="By Premium" 
    />
</RadioGroup>

</Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '200px' }}>

                        {/* Pie chart on the left */}
                        <Box sx={{ width: '40%', display: 'flex', justifyContent: 'center' }}>
                            <PieChart
                                series={[
                                    {
                                        data: chartData.map((item, idx) => ({
                                            id: idx,
                                            value: item.value,
                                            color: item.color,
                                        })),
                                    },
                                ]}
                                width={500}
                                height={450}
                                sx={{ transform: 'scale(1.0)', transformOrigin: 'center' }}
                            />
                        </Box>

                        {/* Content on the right */}
                        <Box sx={{ width: '60%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            {chartData.map((item, idx) => {
                                const percentage = chartType === 'count'
                                    ? ((item.value / totalValue) * 100).toFixed(2)
                                    : null; // Only calculate percentage for counts

                                return (
                                    <Typography key={idx} variant="caption" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '16px' }}>
                                        <span>
                                            <span
                                                style={{
                                                    display: 'inline-block',
                                                    width: 10,
                                                    height: 10,
                                                    backgroundColor: item.color,
                                                    marginRight: 8,
                                                    borderRadius: '50%',
                                                }}
                                            ></span>
                                            {item.name}:
                                        </span>
                                        <span>
                                            {item.value} {chartType === 'count' && `(${percentage}%)`}
                                        </span>
                                    </Typography>
                                );
                            })}
                        </Box>
                    </Box>
                </Paper>
            </Box>
        );
    };

    const renderPieChartPolicyType = (label, chartData, index) => {
        const totalValue = chartData.reduce((sum, item) => sum + (item.value || 0), 0); // Total for percentage calculation
    
        return (
            <Box sx={{ width: '33%', padding: '20px', boxSizing: 'border-box' }}>
                <Paper sx={{ padding: '20px',
                    cursor: 'pointer',
                    border: selectedTab === index ? '1px solid #E5E5EF' : '1px solid #E5E5EF',
                    boxShadow: selectedTab === index ? '0px 0px 10px rgba(0, 123, 255, 0.5)' : 'none', }} onClick={() => handleChartClick(index)}>
    
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', fontFamily: '"Poppins", sans-serif', borderBottom: '#E5E5EF solid 1px', paddingBottom: '10px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'end', flexFlow: 'column', alignItems: 'start' }}>
                            <span id="statistics">Statistics</span>
                            <strong id="policytype">Policy Type</strong>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'end', flexFlow: 'column', alignItems: 'end' }}>
                            <span>Total {policyChartType === 'typecount' ? 'Count' : 'Premium'}</span>
                            <strong>{policy_type_total_count}</strong>
                        </Box>
                    </Box>
                    <RadioGroup
                        row
                        aria-label="chart-type"
                        name="chart-type"
                        value={policyChartType}
                        onChange={(e) => setPolicyChartType(e.target.value)}
                    >
                        <FormControlLabel value="typecount" control={<Radio />} label="By Count" />
                        <FormControlLabel value="premium" control={<Radio />} label="By Premium" />
                    </RadioGroup>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            height: '200px',
                        }}
                    >
                        {/* Pie chart on the left */}
                        <Box
                            sx={{
                                width: '40%',  // Set the width for the pie chart area
                                display: 'flex',
                                justifyContent: 'center',  // Center the pie chart horizontally
                            }}
                        >
                            <PieChart
                                series={[{
                                    data: chartData.map((item, idx) => ({
                                        id: idx,
                                        value: item.value,
                                        color: item.color,
                                    })),
                                }]}
                                width={500}  // Adjust this value to control the size of the graph
                                height={450} // Adjust this value to control the size of the graph
                                sx={{ transform: 'scale(1.0)', transformOrigin: 'center' }}
                            />
                        </Box>
    
                        {/* Content on the right */}
                        <Box
                            sx={{
                                width: '60%',  // Set the width for the content area
                                display: 'flex',
                                flexDirection: 'column',  // Stack labels vertically
                                justifyContent: 'space-between',
                            }}
                        >
                            {chartData.map((item, idx) => {
                                const percentage = policyChartType === 'typecount'
                                    ? ((item.value / totalValue) * 100).toFixed(2) // Only calculate percentage for "Count"
                                    : null;
                                return (
                                    <Typography key={idx} variant="caption" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '16px' }}>
                                        <span>
                                            <span
                                                style={{
                                                    display: 'inline-block',
                                                    width: 10,
                                                    height: 10,
                                                    backgroundColor: item.color,
                                                    marginRight: 8,
                                                    borderRadius: '50%',
                                                }}
                                            ></span>
                                            {item.name}:
                                        </span>
                                        <span>
                                            {item.value}
                                            {policyChartType === 'typecount' && ` (${percentage}%)`} {/* Conditionally show percentage */}
                                        </span>
                                    </Typography>
                                );
                            })}
                        </Box>
                    </Box>
                </Paper>
            </Box>
        );
    };
    

    // Quotes Data
    const renderPieChartQuote = (label, chartData, index) => {
        return (
            <Box sx={{ width: '33%', padding: '20px', boxSizing: 'border-box' }}>
                <Paper sx={{ padding: '20px',
                    cursor: 'pointer',
                    border: selectedTab === index ? '1px solid #E5E5EF' : '1px solid #E5E5EF',
                    boxShadow: selectedTab === index ? '0px 0px 10px rgba(0, 123, 255, 0.5)' : 'none', }} onClick={() => handleChartClick(index)}>

                    <Box sx={{ width:'100%', display: 'flex', justifyContent: 'space-between', fontFamily: '"Poppins", sans-serif', borderBottom: '#E5E5EF solid 1px', paddingBottom: '10px', }}>
                        <Box sx={{display: 'flex', justifyContent: 'end', flexFlow: 'column', alignItems:' start'}}>
                            <span id="statistics">Statistics</span>
                            <strong id="quote">Quote</strong>
                        </Box>
                        <Box sx={{display: 'flex', justifyContent: 'end', flexFlow: 'column', alignItems:' end'}}>
                            <span id="totalcount">Total Count</span>
                            <strong>{quote_total_count}</strong>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            height: '240px',
                        }}
                    >
                        {/* Pie chart on the left */}
                        <Box
                            sx={{
                                width: '40%',  // Set the width for the pie chart area
                                display: 'flex',
                                justifyContent: 'center',  // Center the pie chart horizontally
                            }}
                        >
                            <PieChart
                                series={[
                                    {
                                        data: chartData.map((item, idx) => ({
                                            id: idx,
                                            value: item.value,
                                            color: item.color,
                                        })),
                                    },
                                ]}
                                width={500}  // Adjust this value to control the size of the graph
                                height={450} // Adjust this value to control the size of the graph
                                sx={{ transform: 'scale(1.0)', transformOrigin: 'center' }}
                            />
                        </Box>


                        {/* Content on the right */}
                        <Box
                            sx={{
                                width: '60%',  // Set the width for the content area
                                display: 'flex',
                                flexDirection: 'column',  // Stack labels vertically
                                justifyContent: 'space-between',
                            }}
                        >
                            {chartData.map((item, idx) => {
                                return (
                                    <Typography key={idx} variant="caption" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '16px' }}>
                                        <span><span
                                            style={{
                                                display: 'inline-block',
                                                width: 10,
                                                height: 10,
                                                backgroundColor: item.color,
                                                marginRight: 8,
                                                borderRadius: '50%',
                                            }}
                                        ></span>{item.name}:</span>
                                        <span> {item.value} ({item.percentage?.toFixed(2)}%)</span>
                                    </Typography>
                                );
                            })}
                        </Box>
                    </Box>
                </Paper>
            </Box>
        );
    };
    //Task Data Section
    const renderPieChartTask = (label, chartData, index) => (
        <Box sx={{ width: '33%', padding: '20px', boxSizing: 'border-box' }}>
            <Paper sx={{ padding: '20px',
                    cursor: 'pointer',
                    border: selectedTab === index ? '1px solid #E5E5EF' : '1px solid #E5E5EF',
                    boxShadow: selectedTab === index ? '0px 0px 10px rgba(0, 123, 255, 0.5)' : 'none', }} onClick={() => handleChartClick(index)}>

                <Box sx={{ width:'100%', display: 'flex', justifyContent: 'space-between', fontFamily: '"Poppins", sans-serif', borderBottom: '#E5E5EF solid 1px', paddingBottom: '10px' }}>
                    <Box sx={{display: 'flex', justifyContent: 'end', flexFlow: 'column', alignItems:' start'}}>
                        <span id="statistics">Statistics</span>
                        <strong id="task">Task</strong>
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'end', flexFlow: 'column', alignItems:' end'}}>
                        <span id="totalcount">Total Count</span>
                        <strong>{taskTotalCount}</strong>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',  // Use flex layout to separate the chart and content
                        justifyContent: 'space-between',  // Ensure proper spacing
                        alignItems: 'center',  // Align items vertically center
                        height: '200px',  // Set a fixed height for the container
                    }}
                >
                    {/* Pie chart on the left */}
                    <Box
                        sx={{
                            width: '40%',  // Set the width for the pie chart area
                            display: 'flex',
                            justifyContent: 'center',  // Center the pie chart horizontally
                        }}
                    >
                        <PieChart
                            series={[
                                {
                                    data: chartData.map((item, idx) => ({
                                        id: idx,
                                        value: item.value,
                                        color: item.color,
                                    })),
                                },
                            ]}
                            width={500}  // Adjust this value to control the size of the graph
                            height={450} // Adjust this value to control the size of the graph
                            sx={{ transform: 'scale(1.0)', transformOrigin: 'center' }}
                        />
                    </Box>

                    {/* Content on the right */}
                    <Box
                        sx={{
                            width: '60%',  // Set the width for the content area
                            display: 'flex',
                            flexDirection: 'column',  // Stack labels vertically
                            justifyContent: 'space-between',
                        }}
                    >
                        {chartData.map((item, idx) => (
                            <Typography key={idx} variant="caption" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '16px' }}>
                                <span><span
                                    style={{
                                        display: 'inline-block',
                                        width: 10,
                                        height: 10,
                                        backgroundColor: item.color,
                                        marginRight: 8,
                                        borderRadius: '50%',
                                    }}
                                ></span>{item.name}:</span>
                                <span> {item.value} ({item.percentage?.toFixed(2)}%)</span>
                            </Typography>
                        ))}
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
    const renderPieChartPriorityTask = (label, chartData, index) => (
        <Box sx={{ width: '33%', padding: '20px', boxSizing: 'border-box' }}>
            <Paper sx={{ padding: '20px',
                    cursor: 'pointer',
                    border: selectedTab === index ? '1px solid #E5E5EF' : '1px solid #E5E5EF',
                    boxShadow: selectedTab === index ? '0px 0px 10px rgba(0, 123, 255, 0.5)' : 'none', }} onClick={() => handleChartClick(index)}>

                <Box sx={{ width:'100%', display: 'flex', justifyContent: 'space-between', fontFamily: '"Poppins", sans-serif', borderBottom: '#E5E5EF solid 1px', paddingBottom: '10px' }}>
                    <Box sx={{display: 'flex', justifyContent: 'end', flexFlow: 'column', alignItems:' start'}}>
                        <span id="statistics">Statistics</span>
                        <strong id="prioritytask">Priority Task</strong>
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'end', flexFlow: 'column', alignItems:' end'}}>
                        <span id="totalcount">Total Count</span>
                        <strong>{priority_task_total_count}</strong>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',  // Use flex layout to separate the chart and content
                        justifyContent: 'space-between',  // Ensure proper spacing
                        alignItems: 'center',  // Align items vertically center
                        height: '200px',  // Set a fixed height for the container
                    }}
                >
                    {/* Pie chart on the left */}
                    <Box
                        sx={{
                            width: '40%',  // Set the width for the pie chart area
                            display: 'flex',
                            justifyContent: 'center',  // Center the pie chart horizontally
                        }}
                    >
                        <PieChart
                            series={[
                                {
                                    data: chartData.map((item, idx) => ({
                                        id: idx,
                                        value: item.value,
                                        color: item.color,
                                    })),
                                },
                            ]}
                            width={500}  // Adjust this value to control the size of the graph
                            height={450} // Adjust this value to control the size of the graph
                            sx={{ transform: 'scale(1.0)', transformOrigin: 'center' }}
                        />
                    </Box>

                    {/* Content on the right */}
                    <Box
                        sx={{
                            width: '60%',  // Set the width for the content area
                            display: 'flex',
                            flexDirection: 'column',  // Stack labels vertically
                            justifyContent: 'space-between',
                        }}
                    >
                        {chartData.map((item, idx) => (
                            <Typography key={idx} variant="caption" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '16px' }}>
                                <span><span
                                    style={{
                                        display: 'inline-block',
                                        width: 10,
                                        height: 10,
                                        backgroundColor: item.color,
                                        marginRight: 8,
                                        borderRadius: '50%',
                                    }}
                                ></span>{item.name}:</span>
                                <span> {item.value} ({item.percentage?.toFixed(2)}%)</span>
                            </Typography>
                        ))}
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
    const renderPieChartOpenTask = (label, chartData, index) => (
        <Box sx={{ width: '33%', padding: '20px', boxSizing: 'border-box' }}>
            <Paper sx={{ padding: '20px',
                    cursor: 'pointer',
                    border: selectedTab === index ? '1px solid #E5E5EF' : '1px solid #E5E5EF',
                    boxShadow: selectedTab === index ? '0px 0px 10px rgba(0, 123, 255, 0.5)' : 'none', }} onClick={() => handleChartClick(index)}>

                <Box sx={{ width:'100%', display: 'flex', justifyContent: 'space-between', fontFamily: '"Poppins", sans-serif', borderBottom: '#E5E5EF solid 1px', paddingBottom: '10px' }}>
                    <Box sx={{display: 'flex', justifyContent: 'end', flexFlow: 'column', alignItems:' start'}}>
                        <span id="statistics">Statistics</span>
                        <strong id="typeoftaskopens">Type of Task Open</strong>
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'end', flexFlow: 'column', alignItems:' end'}}>
                        <span id="totalcount">Total Count</span>
                        <strong>{task_type_count}</strong>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',  // Use flex layout to separate the chart and content
                        justifyContent: 'space-between',  // Ensure proper spacing
                        alignItems: 'center',  // Align items vertically center
                        height: '200px',  // Set a fixed height for the container
                    }}
                >
                    {/* Pie chart on the left */}
                    <Box
                        sx={{
                            width: '40%',  // Set the width for the pie chart area
                            display: 'flex',
                            justifyContent: 'center',  // Center the pie chart horizontally
                        }}
                    >
                        <PieChart
                            series={[
                                {
                                    data: chartData.map((item, idx) => ({
                                        id: idx,
                                        value: item.value,
                                        color: item.color,
                                    })),
                                },
                            ]}
                            width={500}  // Adjust this value to control the size of the graph
                            height={450} // Adjust this value to control the size of the graph
                            sx={{ transform: 'scale(1.0)', transformOrigin: 'center' }}
                        />
                    </Box>

                    {/* Content on the right */}
                    <Box
                        sx={{
                            width: '60%',  // Set the width for the content area
                            display: 'flex',
                            flexDirection: 'column',  // Stack labels vertically
                            justifyContent: 'space-between',
                        }}
                    >
                        {chartData.map((item, idx) => (
                            <Typography key={idx} variant="caption" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '16px' }}>
                                <span><span
                                    style={{
                                        display: 'inline-block',
                                        width: 10,
                                        height: 10,
                                        backgroundColor: item.color,
                                        marginRight: 8,
                                        borderRadius: '50%',
                                    }}
                                ></span>{item.name}:</span>
                                <span> {item.value} ({item.percentage?.toFixed(2)}%)</span>
                            </Typography>
                        ))}
                    </Box>
                </Box>
            </Paper>
        </Box>
    );

    return (
        <Box sx={{ maxWidth: '90%', margin: '2rem auto 0 auto' }}>
            <Typography
                variant="h4"
                id="dashboard"
                sx={{ fontFamily: '"Poppins", sans-serif' }}
                gutterBottom
            >
                Dashboard
            </Typography>
            <Grid sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                {renderPieChartPolicy('Policies', pieChartsData.policiesData, 1)}
                {renderPieChartPolicyType('Policy Type', pieChartsData.policyTypeData, 2)}
                {renderPieChartQuote('Quotes', pieChartsData.quoteData, 3)}
                {renderPieChartTask('Tasks', pieChartsData.taskData, 4)}
                {renderPieChartPriorityTask('Priority Task', pieChartsData.priorityTaskData, 5)}
                {renderPieChartOpenTask('Type of Task Open', pieChartsData.typeofTaskOpenData, 6)}

            </Grid>
            <Box>
                <Grid container spacing={4} marginTop={3}>
                    <Grid item xs={12}>
                        <div ref={policyDataRef}>
                            {selectedTab === 1 && <DashboardPolicyData handleRowClick={handleRowClick} />}
                        </div>
                        <div ref={policyTypeRef}>
                            {selectedTab === 2 && <DashboardPolicyType />}
                        </div>
                        <div ref={quoteDataRef}>
                            {selectedTab === 3 && <QuoteTableData />}
                        </div>
                        <div ref={taskDataRef}>
                            {selectedTab === 4 && <DashboardTaskList />}
                        </div>
                        <div ref={priorityTaskRef}>
                            {selectedTab === 5 && <DashboardTaskPriority />}
                        </div>
                        <div ref={openTaskRef}>
                            {selectedTab === 6 && <DashboardTypeOfTaskOpen />}
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default Dashboard;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPolicyTypeFilteredDataAction,
  fetchAllPolicyTypeAction,
} from "../../../redux/actions/dashboardAction";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TextField,
  Button,
  TablePagination,
  Typography,
} from "@mui/material";
import { FormControl, Select, MenuItem } from "@mui/material";
import Loader from "../../loader/loader";
import dayjs from "dayjs";

const DashboardPolicyType = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [fromDate, setStartDate] = useState(dayjs().startOf("month").format("YYYY-MM-DD"));
  const [toDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [isFiltered, setIsFiltered] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (!isFiltered) {
      dispatch(fetchAllPolicyTypeAction(page, rowsPerPage));
    }
  }, [dispatch, page, rowsPerPage]);

  const allPolicy = useSelector(
    (state) => state.dashboardData.all_policy_type.policiesPage.content || []
  );

  const loading = useSelector((state) => state.dashboardData.loading);

  const paginatedData = useSelector((state) => state.dashboardData.all_policy_type?.policiesPage) || [];


  useEffect(() => {
    setFilteredData(allPolicy);
  }, [allPolicy]);
  
  console.log(allPolicy, 'allPolicy')

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    if (name === "fromDate") setStartDate(value);
    if (name === "toDate") setEndDate(value);
  };

  const handleApply = () => {
    const formData = {
      fromDate: fromDate || "",
      toDate: toDate || "",
      policyType: selectedFilter,
    };
    if ((fromDate && toDate) || selectedFilter !== "All") {
      dispatch(fetchPolicyTypeFilteredDataAction(formData, page, rowsPerPage));
      setIsFiltered(true);
    } else {
      setFilteredData(allPolicy);
      setIsFiltered(false);
    }
    setFilteredData(allPolicy);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (isFiltered) {
      const formData = {
        fromDate: fromDate || "",
        toDate: toDate || "",
        policyType: selectedFilter,
      };
      dispatch(fetchPolicyTypeFilteredDataAction(formData, newPage, rowsPerPage));
    } else {
      dispatch(fetchAllPolicyTypeAction(newPage, rowsPerPage));
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    if (isFiltered) {
      const formData = {
        fromDate: fromDate || "",
        toDate: toDate || "",
        policyType: selectedFilter,
      };
      dispatch(fetchPolicyTypeFilteredDataAction(formData, 0, newRowsPerPage));
    } else {
      dispatch(fetchAllPolicyTypeAction(0, newRowsPerPage));
    }
  };

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const isApplyButtonDisabled = !(fromDate && toDate) && selectedFilter === "All";

  return (
    <>
      <Box sx={{ display: "flex", width: "100%", marginBottom: "15px" }}>
        <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
          <Box sx={{ display: "flex", flexFlow: "column" }}>
            <label>Start Date</label>
            <TextField
              id="fromdate"
              type="date"
              name="fromDate"
              value={fromDate}
              onChange={handleDateChange}
              InputLabelProps={{ shrink: true }}
              sx={{ mr: 2 }}
            />
          </Box>
          <Box sx={{ display: "flex", flexFlow: "column" }}>
            <label>End Date</label>
            <TextField
              id="todate"
              type="date"
              name="toDate"
              value={toDate}
              onChange={handleDateChange}
              InputLabelProps={{ shrink: true }}
              sx={{ mr: 2 }}
            />
          </Box>
          <FormControl
            sx={{ display: "flex", flexFlow: "column", minWidth: "200px" }}
          >
            <label>Policy Type</label>
            <Select
              labelId="filter-select-label"
              value={selectedFilter}
              onChange={handleFilterChange}
              id="policytype"
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="New Business">New Business</MenuItem>
              <MenuItem value="Renewal">Renewal</MenuItem>
              <MenuItem value="Rewrite">Rewrite</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            onClick={handleApply}
            sx={{ ml: 2 }}
            disabled={isApplyButtonDisabled}
            id="applybutton"
          >
            Apply
          </Button>
        </Box>
      </Box>
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{fontWeight: 'bold'}}>Policy Number</TableCell>
                <TableCell sx={{fontWeight: 'bold'}} align="left">Phone Number</TableCell>
                <TableCell sx={{fontWeight: 'bold'}} align="left">Policy Type</TableCell>
                <TableCell sx={{fontWeight: 'bold'}} align="left">Company</TableCell>
                <TableCell sx={{fontWeight: 'bold'}} align="left">Line of Business</TableCell>
                <TableCell sx={{fontWeight: 'bold'}} align="left">Eff Date</TableCell>
                <TableCell sx={{fontWeight: 'bold'}} align="left">Exp Date</TableCell>
                <TableCell sx={{fontWeight: 'bold'}} align="left">Status</TableCell>
                <TableCell sx={{fontWeight: 'bold'}} align="right">Premium</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={9}>
                    <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
                      <Typography variant="subtitle1" color="textSecondary">
                        <Loader />
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ) : filteredData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={9}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        padding: 2,
                      }}
                    >
                      <Typography variant="subtitle1" color="textSecondary">
                        No Data found.
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                filteredData.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{row.policyNumber}</TableCell>
                    <TableCell align="left">1234567891</TableCell>
                    <TableCell align="left">{row.policyType}</TableCell>
                    <TableCell align="left">{row.company}</TableCell>
                    <TableCell align="left">{row.lineOfBusiness}</TableCell>
                    <TableCell align="left">{row.effectiveDate}</TableCell>
                    <TableCell align="left">{row.expiryDate}</TableCell>
                    <TableCell align="left">{row.status}</TableCell>
                    <TableCell align="right">{row.premium}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={paginatedData.totalPages === 0 ? [] : [5, 10, 15, 25, 50, 100]}
          component="div"
          count={paginatedData.totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          id="pagination"
        />
      </Box>
    </>
  );
};

export default DashboardPolicyType;

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Grid, Button } from '@mui/material';
import { Edit, Email } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import {
  getUser as getUserAction,
  getAllUser as getAllUserAction,
  openCreateUserModal as openCreateUserModalAction,
  openUpdateUserModal as openUpdateUserModalAction
} from '../../redux/actions/userActions';
import CustomDataGrid from '../../shared/CustomDataGrid';

const useStyles = makeStyles({
  header: {
    margin: ".5em 0 !important",
  },
});

const Team = () => {
  const classes = useStyles();
  const allUser = useSelector((state) => state?.user?.allUser);
  const loading = useSelector((state) => state.loading.ALL_USERS);
  const user = useSelector((state) => state.user.user)
  const error = useSelector((state) => state?.user?.error);
  const dispatch = useDispatch();

  const columns = [
    {
      field: 'firstName',
      headerName: 'First Name',
      id:'firstname',
      width: 150,
      editable: true,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      id:'lastname',
      width: 150,
      editable: true,
    },
    {
      field: 'email',
      id:'email',
      headerName: 'Email Id',
      width: 160,
      editable: true,
    },
    {
      field: 'role',
      id:'role',
      headerName: 'Role',
      sortable: true,
      width: 160,
    },
    {
      field: 'customer',
      id:'customer',
      headerName: 'Customer Name',
      sortable: true,
      width: 160,
      renderCell: (params) => {
        return <div className="rowitem">{params.row.customer.name}</div>;
      },
    },
    {
      field: 'organization',
      id:'organization',
      headerName: 'Organization Name',
      sortable: true,
      width: 160,
      renderCell: (params) => {
        return <div className="rowitem">{params.row.customer.organization.name}</div>;
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <div>
          <Email
            style={{ cursor: 'pointer', marginRight: '8px' }}
            onClick={() => handleSendEmail(params.row.email)}
            id={`email-action-${params.row.id}`}
          />
          <Edit
            style={{ cursor: 'pointer' }}
            onClick={() => updateUser(params.row.id)}
            id={`edit-action-${params.row.id}`}
          />
        </div>
      ),
    },
  ];

  const [allUserData, setMyAllUsersData] = React.useState(allUser || []);

  React.useEffect(() => {
    if (!user) {
      dispatch(getUserAction());
    } else {
      if(Object.keys(user).length) {
        dispatch(getAllUserAction(user?.id));
      }
    }
  }, [])

  React.useEffect(() => {
    setMyAllUsersData(allUser || []);
  }, [allUser, dispatch, user]);

  const openCreateUser = () => {
    dispatch(openCreateUserModalAction());
  }

  const handleSendEmail = (email) => {
    console.log(email);
  }

  const updateUser = (id) => {
    dispatch(openUpdateUserModalAction(id));
  }

  console.log(error);

  return (
    <Box sx={{ minHeight: 400, width: '100%' }}>
      <Grid item xs={12} md={12} sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography id="users" variant="h5" className={classes.header}>Users</Typography>
        <Button id="createuserbutton" variant="contained" color="primary" sx={{ marginBottom: "1rem", padding: ".5rem 1rem" }} onClick={openCreateUser}>Create User</Button>
      </Grid>
      <CustomDataGrid 
        gridData={allUserData}
        columns={columns}
        loading={loading}
        error={error && error}
      />
    </Box>
  );
}

export default Team;

import * as organizationServices from '../../services/organizationServices';
import {
  CLOSE_CREATE_ORGANIZATION_MODAL,
  OPEN_CREATE_ORGANIZATION_MODAL,
    OPEN_UPDATE_ORGANIZATION_MODAL,
    START_LOADING,
    STOP_LOADING
} from "./actionTypes"
import { openSessionTimeoutModal } from './sessionTimeOutActions';

export const getAllOrganization = () => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'ALL_ORGANIZATIONS' });
    const token = getState().auth.token || sessionStorage.getItem("token");;
    const response = await organizationServices.getAllOrganization(token);
    dispatch({ type: STOP_LOADING, service: 'ALL_ORGANIZATIONS' });
    dispatch({ type: 'ALL_ORGANIZATIONS_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'ALL_ORGANIZATIONS' });
    dispatch({ type: 'ALL_ORGANIZATIONS_FAILURE', payload: error || 'All Organizations service failed. Please try again.' });
  }
};

export const createOrganization = (formData, callbackfn) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'CREATE_ORGANIZATION' });
    const token = getState().auth.token || sessionStorage.getItem("token");;
    const response = await organizationServices.createOrganization(formData, token);
    dispatch({ type: STOP_LOADING, service: 'CREATE_ORGANIZATION' });
    if(response) {
      callbackfn();
    }
    dispatch({ type: 'CREATE_ORGANIZATION_SUCCESS', payload: response });
  } catch (error) {
    if(error?.includes("403")) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'CREATE_ORGANIZATION' });
      dispatch({ type: 'CREATE_ORGANIZATION_FAILURE', payload: error || 'Create Organization service failed. Please try again.' });
    }
  }
};

export const updateOrganization = (formData, callbackfn) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'UPDATE_ORGANIZATION' });
    const token = getState().auth.token || sessionStorage.getItem("token");;
    const response = await organizationServices.updateOrganization(formData, token);
    dispatch({ type: STOP_LOADING, service: 'UPDATE_ORGANIZATION' });
    if(response) {
      callbackfn();
    }
    dispatch({ type: 'UPDATE_ORGANIZATION_SUCCESS', payload: response });
  } catch (error) {
    if(error?.includes("403")) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'UPDATE_ORGANIZATION' });
      dispatch({ type: 'UPDATE_ORGANIZATION_FAILURE', payload: error || 'Update Organization service failed. Please try again.' });
    }
  }
};

export const openCreateOrganizationModal = () => ({
  type: OPEN_CREATE_ORGANIZATION_MODAL,
});

export const closeCreateOrganizationModal = () => ({
  type: CLOSE_CREATE_ORGANIZATION_MODAL,
});

export const openUpdateOrganizationModal = (id) => async (dispatch) => {
  dispatch({ type:OPEN_UPDATE_ORGANIZATION_MODAL, payload: id });
};
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
} from "@mui/material";
import { stateList } from "../../common/StaticFieldList";
import { useDispatch } from "react-redux";
import { saveLocationAction, updateLocationAction } from "../../redux/actions/locationActions";
import { useNavigate } from "react-router-dom";

function AddLocationForm({ location, toggleForm }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [formValues, setFormValues] = useState({
    locationDescription: "",
    agencyName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    phone: "",
    secondaryPhone: "",
    faxNumber: "",
    companyGroups: "",
    firstName: "",
    lastName: "",
    cellPhone: "",
    email: "",
    password: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    if (location) {
      setFormValues({
        id: location.id,
        locationDescription: location.locationDescription || "",
        agencyName: location.agencyName || "",
        address1: location.address1 || "",
        address2: location.address2 || "",
        city: location.city || "",
        state: location.state || "",
        zipCode: location.zipCode || "",
        phone: location.phone || "",
        secondaryPhone: location.secondaryPhone || "",
        faxNumber: location.faxNumber || "",
        companyGroups: location.companyGroups || "",
        firstName: location.firstName || "",
        lastName: location.lastName || "",
        cellPhone: location.cellPhone || "",
        email: location.email || "",
        password: location.password || "",
      });
      setIsActive(location.isActive || false);
    }
  }, [location]);

  // Validation functions
  const validateLetterInput = (value) => /^[a-zA-Z\s]*$/.test(value);
  const validateAddressInput = (value) => /^[a-zA-Z0-9\s,]*$/.test(value);
  const validateNumericInput = (value) => /^\d{0,10}$/.test(value);
  const validatePassword = (value) => value.length >= 6 && /\d/.test(value);
  const validateEmail = (value) => /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/.test(value);

  // Handle input change with inline validation
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let errorMessage = "";

    if (["locationDescription", "agencyName", "city", "firstName", "lastName"].includes(name) && !validateLetterInput(value)) {
      errorMessage = `${name.charAt(0).toUpperCase() + name.slice(1)} contains invalid characters. Only letters and spaces are allowed.`;
    } else if (["address1", "address2"].includes(name) && !validateAddressInput(value)) {
      errorMessage = `${name === "address1" ? "Primary Address" : "Secondary Address"} contains invalid characters.`;
    } else if (["zipCode", "phone", "secondaryPhone", "cellPhone", "faxNumber"].includes(name) && !validateNumericInput(value)) {
      errorMessage = "Only numeric characters are allowed.";
    } else if (name === "email" && value && !validateEmail(value)) {
      errorMessage = "Invalid email format.";
    } else if (name === "password" && value && !validatePassword(value)) {
      errorMessage = "Password must be at least 6 characters and include a number.";
    }

    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: errorMessage });
  };

  const handleSave = () => {
    const requiredFields = [
      "locationDescription",
      "agencyName",
      "address1",
      "city",
      "state",
      "zipCode",
      "phone",
      "email",
    ];
    
    const newErrors = {};
    requiredFields.forEach((field) => {
      if (!formValues[field].trim()) {
        newErrors[field] = "This field is required.";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setFormErrors(newErrors);
      return;
    }

    const FormValues = { ...formValues, isActive };
    
    if (location && location.id) {
      // Update location
      FormValues.id = location.id;
      dispatch(updateLocationAction(FormValues, () => {
        setSnackbarMessage("Location updated successfully.");
        setOpenSnackbar(true);        
        toggleForm(true)
      }));
    } else {
      // Add new location
      dispatch(saveLocationAction(FormValues, () => {
        setSnackbarMessage("Location added successfully.");
        setOpenSnackbar(true);        
        toggleForm(true)
      }));
    }
    toggleForm(true)
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box sx={{ width: "100%", minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#f4f6f8", fontFamily: '"Poppins", sans-serif' }}>
      <Box sx={{ width: "100%", maxWidth: "900px", padding: "32px", backgroundColor: "#fff" }}>
        <Typography variant="h5" sx={{ marginBottom: "32px" }}>
          {location ? "Edit Location" : "Add Location"}
        </Typography>

        <Box sx={{ display: "grid", gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" }, gap: "24px", marginBottom: "24px" }}>
          {[{ name: "locationDescription", label: "Location Description" },
            { name: "agencyName", label: "Agency Name" },
            { name: "address1", label: "Address Line 1" },
            { name: "address2", label: "Address Line 2" },
            { name: "city", label: "City" },
            {
              name: "state", label: "State", renderInput: (
                <Select
                  value={formValues.state}
                  onChange={(e) => handleInputChange({ target: { name: "state", value: e.target.value } })}
                  sx={{ width: "100%" }}
                >
                  {stateList.map((state) => (
                    <MenuItem key={state.value} value={state.value}>{state.label}</MenuItem>
                  ))}
                </Select>
              ),
            },
            { name: "zipCode", label: "Zip Code" },
            { name: "phone", label: "Phone" },
            { name: "secondaryPhone", label: "Alternate Phone" },
            { name: "faxNumber", label: "Fax Number" },
            { name: "firstName", label: "First Name" },
            { name: "lastName", label: "Last Name" },
            { name: "cellPhone", label: "Cell Phone" },
            { name: "email", label: "Email" },
            { name: "password", label: "Password" },
          ].map(({ name, label, renderInput }) => (
            <Box key={name}>
              <Typography variant="body2" component="label" htmlFor={name} sx={{ display: "block", marginBottom: "4px" }}>
                {label}
              </Typography>
              {name === "state" && renderInput ? (
                renderInput
              ) : (
                <TextField
                  id={name}
                  name={name}
                  value={formValues[name]}
                  onChange={handleInputChange}
                  error={!!formErrors[name]}
                  helperText={formErrors[name] || ""}
                  fullWidth
                  type={name === "password" ? "password" : "text"}
                />
              )}
            </Box>
          ))}
        </Box>

        <FormControlLabel
          control={<Checkbox checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />}
          label="Is Active"
        />

        <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "32px" }}>
          <Button variant="contained" color="primary" onClick={handleSave} sx={{ marginRight: "16px" }}>
            {location ? "Update" : "Save"}
          </Button>
          <Button variant="outlined" color="secondary" onClick={() => toggleForm(false)}>
            Cancel
          </Button>
        </Box>

        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="success">
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
}

export default AddLocationForm;

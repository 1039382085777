import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  TextField,
  SnackbarContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  Alert,
  Grid,
  Typography,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import { useNavigate, useLocation, useParams } from "react-router-dom";
import ImageLoader from "./imageLoader";
import { getUser as getUserAction } from "../../redux/actions/userActions";
// import {
//   InsuranceTech as jsonData,
//   QuickQuote as errorJson,
//   InsuranceTech,
//   // StarHealth,
//   // TATAHealth,
// } from "../../compare";
import {
  CommonYesNo,
  liabbi,
  liabpd,
  Rental,
  Rentals,
  Roadside,
  TermTenture,
  umbi,
  medPay,
  coll,
  comp,
  payPlanOptionIns,
  payPlanOptions,
  pipDed,
  pipDedOption,
} from "../../common/StaticFieldList";
import { CircleOutlined } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import { GridRowModes, DataGrid } from "@mui/x-data-grid";
import { getRatesAction } from "../../redux/actions/carrierSelectionsInfoActions";
import {
  findValueByKey,
  formattedCurrency,
  getValueByKey,
  populateValue,
} from "../../common/helpers/utils";
import { tabActions } from "../../redux/actions/tabActions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTheme } from "@emotion/react";
import { getRates as getJSONAction } from "../../redux/actions/userActions";
import { getRatesUnited } from "../../redux/actions/userActions";
import { getcompanyselectionAction } from "../../redux/actions/companySelectionAction";
import { getDriverNameInfoById,getAllDriverInfo } from "../../redux/actions/driverInfoActions";
import { getAllVehicleInfo } from "../../redux/actions/vehicleInfoActions";
import { comparisionResponseById } from "../../redux/actions/generalPartyInfoActions";


const useStyles = makeStyles((theme) => ({
  formContainer: {
    // marginBottom: theme.spacing(2),
  },
  select: {
    minWidth: 150,
  },
  centerContent: {
    display: "flex",
    alignItems: "center",
    // marginTop: '.5rem !important',
  },
  fullWidth: {
    width: "100%",
  },
  root: {
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none",
    },
    "& .MuiDataGrid-window": {
      borderTop: "none",
    },
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    fontFamily: '"Poppins", sans-serif',
    id:"table"
  },
  thead: {
    backgroundColor: "#f5f5f5",
    textAlign: "left",
    fontWeight: 600,
    fontSize: "16px",
    id:"thead"
  },
  th: {
    padding: "12px 16px",
    textAlign: "left",
    fontWeight: "500",
    borderBottom: "2px solid #ddd",
    id:"th"
  },
  tbody: {
    fontSize: "14px",
    color: "#333",
    id:"tbody"
  },
  td: {
    padding: "12px 16px",
    borderBottom: "1px solid #ddd",
    fontWeight: "500",
    id:"td"
  },
  actionCell: {
    display: "flex",
    gap: "12px",
    alignItems: "center",
    id:"actionCell"
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme?.palette?.primary?.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  // "&:last-child td, &:last-child th": {
  //   border: 1,
  // },
}));

const CompareQuotesTab = ({ setShowHideDriverForm, id }) => {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let { quoteId } = useParams();
  const driverDetails = useSelector((state) => state.driverInfo.allDriverInfo);
  const [isReRated, setIsReRated] = useState(false);
  const clientName = useSelector(
    (state) => state.generalPartyInfo.allGeneralPartyInfo
  );

  const jsonvalue = useSelector(
    (state) => state.generalPartyInfo.allComparisionInfo
  );

  const getJson=jsonvalue.jsonResponse;
  // const parsedData = JSON.parse(JSON.stringify(jsonfixvalue));
  const [selectedDriver, setSelectedDriver] = useState("");

  const [rowModesModel, setRowModesModel] = React.useState({});
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const [msgStatus, setMsgStatus] = useState("");
  const [mergeXMLjson, setMergeXMLjson] = useState([
    // JSON.stringify(StarHealth),
    // JSON.stringify(TATAHealth),
  ]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const loading = useSelector((state) => state.loading.CREATE_USER);
  const isModalOpen = useSelector(
    (state) => state?.user?.isCreateUserModalOpen
  );
  const [checkedItemss, setCheckedItemss] = useState([]);
  const [loadings, setLoading] = useState(false);
  // const companies = useSelector(state => state.companies.companies);
  const companies = useSelector((state) => state.companies?.companies || []);
  // console.log("companies", companies);
  useEffect(() => {
    dispatch(getcompanyselectionAction());
  }, [dispatch]);

  useEffect(() => {
    if (quoteId) {
      // console.log("quoteId", quoteId)
      dispatch(getAllDriverInfo(quoteId));
      dispatch(comparisionResponseById(quoteId));
    }
    else
    {
      // console.log("id in driver useeffect",id);
      dispatch(getAllDriverInfo(id));
      dispatch(comparisionResponseById(id));
    }
    // }
  }, [quoteId,id]);
  let initialRows = [];
  if (Array.isArray(companies)) {
    initialRows = companies.map((carrier) => ({
      company: carrier.companyName,
      id: carrier.id,
      code: carrier.companyCode,
    }));
  }
  // console.log("initialrows", initialRows);
  const [rows, setRows] = React.useState(initialRows);
  const handleCheckChanges = (event) => {
    const value = event.target.value;
    setCheckedItemss((prev) => {
      const newChecked = prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value];

      // Refresh the page if a checkbox is unchecked
      if (!newChecked.includes(value)) {
        window.location.reload();
      }

      return newChecked;
    });
  };

  const [opens, setOpens] = useState(false);
  const [openInvoice, setOpenInvoice] = useState(false);

  const handleClickOpen = (event) => {
    event.preventDefault();
    setOpens(true);
  };

  const handleClickOpenInvoice = (event) => {
    event.preventDefault();
    setOpenInvoice(true);
  };

  const handleClose = () => {
    setOpens(false);
  };

  const handleCloseInvoice = () => {
    setOpenInvoice(false);
  };

  React.useEffect(() => {
    if (!Object.keys(user).length) {
      dispatch(getUserAction());
    }
  }, []);
  // const getJson = useSelector((state) => state.user.xmlJson);
  // console.log("getJson", getJson);

  React.useEffect(() => {
    let MsgStatus = "";

    if (getJson) {
      try {
        // Assuming getJson is a JSON object; if it's a string, you should parse it first
        const parsedJson = typeof getJson === "string" ? JSON.parse(getJson) : getJson;

        // Retrieve MsgStatus from parsed JSON
        MsgStatus = findValueByKey(parsedJson, "MsgStatus");

        setMsgStatus(MsgStatus);

        // Check the status and update the mergeXMLjson state
        if (MsgStatus && !["Error", "REJECTED"].includes(MsgStatus.MsgStatusCd)) {
          // setMergeXMLjson(prev => [...prev, JSON.stringify(getJson)]);
          setMergeXMLjson([...mergeXMLjson, JSON.stringify(parsedJson)]);
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  }, [jsonvalue.jsonResponse]);

  // React.useEffect(() => {
  //   let MsgStatus = "";
  //   if (getJson) {
  //     MsgStatus = findValueByKey(getJson, "MsgStatus");
  //     // mergeXMLjson = [JSON.stringify(getJson)];
  //     // console.log("======MsgStatus=====", MsgStatus);
  //     setMsgStatus(MsgStatus);
  //     if (MsgStatus && !["Error", "REJECTED"].includes(MsgStatus.MsgStatusCd)) {
  //       // console.log("===========");
  //       setMergeXMLjson([...mergeXMLjson, JSON.stringify(getJson)]);
  //     }
  //   }
  // }, [getJson]);
  const generalPartyInfo = useSelector(
    (state) => state.generalPartyInfo.allGeneralPartyInfo
  );
  const allVehicleInfo = useSelector(
    (state) => state.vehicleInfo.allVehicleInfo
  );

  useEffect(() => {
    // if (!Object.keys(user).length) {
      // dispatch(getUserAction());
      if(quoteId){
        // console.log("quoteId", quoteId)
        dispatch(getAllVehicleInfo(quoteId));
      }
      else
      {
        dispatch(getAllVehicleInfo(id));
      }
    // }
  }, []);
  const user = useSelector((state) => state.user);
  const selectedUserId = useSelector((state) => state.user.selectedUserId);
  const allUser = useSelector((state) => state?.user?.allUser);
  const customerDataForUpdate =
    allUser &&
    allUser.length &&
    allUser.filter((user) => user.id === selectedUserId);
  const customerForUpdate = customerDataForUpdate && customerDataForUpdate[0];

  const getSelectedRates = useSelector(
    (state) => state.carrierSelectionsInfo.selectedRateList
  );
  const [checkedItems, setCheckedItems] = useState(getSelectedRates || []);
  // const fullName = `${clientName.firstName} ${clientName.lastName}`;

  const simplifiedDriverDetails = driverDetails.map((driver) => ({
    fullName: `${driver.firstName} ${driver.lastName}`,
  }));

  const firstDriverFullName =
    simplifiedDriverDetails.length > 0
      ? simplifiedDriverDetails[0].fullName
      : "";
  // console.log("firstDriverFullname",firstDriverFullName);
  const [formState, setFormState] = useState({
    payPlanOptions: "INS",
    term: "12",
    vehicle: [],
    // gpId: parseInt(id),
    gpId: id ? parseInt(id) : parseInt(quoteId),
    changeNameInsuredTo: "",
    liabbi: "",
    liabpd: "",
    pipDed: "",
    pipDedOption: "",
    umbi: "",
    umStacked: "",
    medPay: "",
  });

  // const simplifiedDriverDetails = driverDetails.map(driver => ({
  //   fullName: `${driver.firstName} ${driver.lastName}`,
  // }));

  useEffect(() => {
    if (clientName) {
      const fullName = `${clientName.firstName} ${clientName.lastName}`;
      // console.log("fullname", fullName);
      setSelectedDriver(fullName);
      setFormState((prevState) => ({
        ...prevState,
        changeNameInsuredTo: firstDriverFullName, // Set initial value from clientName
        liabbi: liabbi[2]?.value || "", // Use optional chaining to avoid errors
        liabpd: liabpd[0]?.value || "",
        pipDed: pipDed[3]?.label || "",
        pipDedOption: pipDedOption[0]?.label || "",
        umbi: umbi[1]?.value || "",
        umStacked: CommonYesNo[1]?.value || "",
        medPay: umbi[1]?.value || "",
      }));
    }
  }, [clientName,firstDriverFullName]);

  const [vehicleState, setVehicleState] = useState([]);

  React.useEffect(() => {
    let newVehicleObj = [];
    allVehicleInfo.map((row) => {
      let obj = {
        id: row.id,
        //cid
        comp: row.comp || comp[5]?.value,
        coll: row.coll || coll[11]?.value,
        roadside: Roadside[1]?.value || "",
        rental: Rentals[1]?.value || "",
        safety: CommonYesNo[1]?.value || "",
        manufacturer: row.manufacturer,
        model: row.model,
      };

      newVehicleObj.push(obj);
      // newVehicleObj[row.cid] = obj;
    });

    setVehicleState(newVehicleObj);
    setFormState((prevState) => ({
      ...prevState,
      vehicle: newVehicleObj,
    }));
  }, [allVehicleInfo]);
  const handleSnackbarClose = () => {
    setSnackbar((prevSnackbar) => ({
      ...prevSnackbar,
      open: false,
    }));
  };

  const handleSnackbarOpen = (message, severity) => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const handleCheckChange = (event) => {
    const value = event.target.value;

    // If checkbox is checked, add it to the array
    // If checkbox is unchecked, remove it from the array
    if (!checkedItems.includes(value)) {
      setCheckedItems([...checkedItems, value]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item != value));
    }
  };
  const handleInputChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setSelectedDriver(value);
  };

  const handleInputChangeVehicle = (e, index) => {
    e.preventDefault();
    const { name, value } = e.target;

    // Create a new array instead of mutating the existing one
    const vehicleStateNew = [...vehicleState];
    vehicleStateNew[index] = {
      ...vehicleStateNew[index],
      // [name]: value.toString(),
      [name]: value,
    };

    // Update the state with the new array
    setVehicleState(vehicleStateNew);
    setFormState((prevState) => ({
      ...prevState,
      vehicle: vehicleStateNew,
    }));
  };

  // console.log("mergeXMLjson", mergeXMLjson);
  // Example usage:

  // console.log("form", formState);
  // console.log("checkedItems", checkedItems)

  const getRates = async (payload, company) => {
    // console.log("Payload:", payload);

    if (company === "Pearl Holding") {
      return await dispatch(getJSONAction(payload, quoteId)); // Dispatch action for Pearl
    } else if (company === "United") {
      return await dispatch(getRatesUnited(payload, quoteId)); // Dispatch action for United
    }
  };

  const handleGetRates = async () => {
    try {
      setLoad(true);

      // Filter out any undefined or null values from checkedItemss
      const validCheckedItems = checkedItemss.filter(item => item != null);

      // Collect promises for all checked companies
      const promises = validCheckedItems.map((item) => {
        const company = initialRows.find((row) => row.id === parseInt(item));
        if (company) {
          const payload = {
            ...formState,
            companyCode: company.code, // Add the company code to the payload
          };

          return getRates(payload, company.company)
            .then(result => ({ company: company.company, result, success: true }))
            .catch(error => ({ company: company.company, error, success: false }));
        }
        return null;
      }).filter(promise => promise != null); // Filter out any null promises

      // Wait for all the requests to resolve
      const results = await Promise.all(promises);

      // console.log("All rates fetched:", results); // Check the fetched rates

      // Process the results
      const successfulResults = results.filter(({ success }) => success);

      results.forEach(({ company, result, error, success }) => {
        if (!success) {
          console.error(`Error fetching rates for ${company}:`, error);
        } else {
          // console.log(`Rates for ${company}:`, result);
          // Here you can update your state or perform any other action with the fetched rates
        }
      });

      // After all requests have completed successfully
      setLoad(false);

      // Only set isReRated to true if we have successful results for all checked companies
      if (successfulResults.length === validCheckedItems.length) {
        setIsReRated(true);
      } else {
        console.warn("Not all rates were successfully fetched. isReRated not set to true.");
      }
    } catch (error) {
      console.error("Error getting rates:", error);
      setLoad(false);
    }
  };

  return (
    <Box
      maxWidth="xl"
      sx={{
        backgroundColor: "#fff",
        margin: "0 auto",
        border: "#E7E7E7 solid 1px",
        padding: "20px",
        borderRadius: "4px",
      }}
    >
      {/* {msgStatus && ["Error", "REJECTED"].includes(msgStatus.MsgStatusCd) && (
        <SnackbarContent
          message={`${msgStatus.MsgStatusCd}:   ${msgStatus.MsgStatusDesc}`}
        />
      )} */}
      {load && (
        <div style={{ textAlign: "center" }}>
          <ImageLoader
            text1="Communicating with Insurance companies"
            text2="Result will be shown momentarily "
          />
        </div>
      )}
      <Paper
        elevation={0}
        style={{
          borderRadius: 4,
          backgroundColor: "#fff",
          borderBottom: "1px solid #e3e3e3",
          padding: "30px 0 20px",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Typography
            id="comparisonsection"
              variant="h5"
              fontWeight="600"
              sx={{ fontFamily: '"Poppins", sans-serif' }}
            >
              Comparison Section
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box sx={{ mt: 2, flexGrow: 1 }}>
          <Grid
            container
            spacing={1}
            sx={{ fontFamily: '"Poppins", sans-serif' }}
          >
            <Grid container item xs={12} sm={12} spacing={1} >
              <Grid item xs={6} sm={2} lg={3}>
                <Typography
                id="changenameinsuredto"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  Change Name Insured To
                  <TextField
                    select={"select"}
                    sx={{ fontFamily: '"Poppins", sans-serif' }}
                    fullWidth
                    placeholder="Change Name Insured To"
                    // label={"Change Name Insured To"}
                    name={"changeNameInsuredTo"}
                    // value={formState["changeNameInsuredTo"] }
                    value={formState["changeNameInsuredTo"]}
                    // value={"Smith"}
                    // sx={{marginTop:"5px"}}
                    size="small"
                    onChange={(e) => handleInputChange(e)}
                    // onBlur={() => handleBlur(fieldName)}
                  >
                    {simplifiedDriverDetails.map((driver, i) => (
                      <MenuItem
                        key={i}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                        value={driver.fullName}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {" "}
                          {driver.fullName}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>
              <Grid item xs={6} sm={2} lg={3}>
                <Typography
                id="LiabBI"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  Liab BI
                  <TextField
                    select={"select"}
                    fullWidth
                    placeholder="Liab BI"
                    // label={"Liab BI"}
                    name={"liabbi"}
                    value={formState["liabbi"]}
                    // || Liab[2].value
                    // value={}
                    size="small"
                    onChange={(e) => handleInputChange(e)}
                    sx={{ fontFamily: '"Poppins", sans-serif' }}
                    // onBlur={() => handleBlur(fieldName)}
                  >
                    {liabbi.map((option) => (
                      <MenuItem
                        key={option.value}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                        value={option.value}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {" "}
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography
                id="LiabPD"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  Liab PD
                  <TextField
                    select={"select"}
                    sx={{ fontFamily: '"Poppins", sans-serif' }}
                    fullWidth
                    placeholder="Liab PD"
                    // label={"Liab PD"}
                    name={"liabpd"}
                    // value={Liab1[0].value}
                    value={formState["liabpd"]}
                    size="small"
                    //  || Liab1[0].value
                    // value={formData[fieldName]}
                    onChange={(e) => handleInputChange(e)}
                    // onBlur={() => handleBlur(fieldName)}
                  >
                    {liabpd.map((option) => (
                      <MenuItem
                        key={option.value}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                        value={option.value}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {" "}
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography
                id="PIPDED"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  PIP DED
                  <TextField
                    select={"select"}
                    sx={{ fontFamily: '"Poppins", sans-serif' }}
                    fullWidth
                    placeholder="PIP DED"
                    // label={"PIP DED"}
                    name={"pipDed"}
                    // value={formData[fieldName]}
                    value={formState["pipDed"]}
                    size="small"
                    onChange={(e) => handleInputChange(e)}
                    // onBlur={() => handleBlur(fieldName)}
                  >
                    {pipDed.map((option) => (
                      <MenuItem
                        key={option.value}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                        value={option.value}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} spacing={1}>
              {/* <Grid item xs={12} sm={2}>
                <TextField
                  select={"select"}
                  sx={{fontFamily: '"Poppins", sans-serif',}}
                  InputProps={{
                    style: { height: 30, fontSize: 10, width: 150 },
                  }}
                  InputLabelProps={{
                    style: { fontSize: 12 },
                    shrink: true,
                  }}
                  margin="normal"
                  size="small"
                  label={"PIP DED"}
                  name={"pipDed"}
                  // value={formData[fieldName]}
                  value={formState["pipDed"]}
                  onChange={(e) => handleInputChange(e)}
                  // onBlur={() => handleBlur(fieldName)}
                >
                  {pipDed.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{fontFamily: '"Poppins", sans-serif',}}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid> */}
              <Grid item xs={6} sm={2} lg={3}>
                <Typography
                id="PIPDEDoption"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  PIP DED Option
                  <TextField
                    select={"select"}
                    sx={{
                      fontFamily: '"Poppins", sans-serif',
                      marginTop: "5px",
                    }}
                    fullWidth
                    placeholder="PIP DED Option"
                    // label={"PIP DED Option"}
                    name={"pipDedOption"}
                    size="small"
                    value={formState["pipDedOption"]}
                    // value={formData[fieldName]}
                    onChange={(e) => handleInputChange(e)}
                    // onBlur={() => handleBlur(fieldName)}
                  >
                    {pipDedOption.map((option) => (
                      <MenuItem
                        key={option.value}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                        value={option.value}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>
              <Grid item xs={6} sm={2} lg={3}>
                <Typography
                id="UMBI"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  UMBI
                  <TextField
                    select={"select"}
                    sx={{
                      fontFamily: '"Poppins", sans-serif',
                      marginTop: "5px",
                    }}
                    fullWidth
                    placeholder="UMBI"
                    // label={"UMBI"}
                    name={"umbi"}
                    size="small"
                    // disabled={formState.Liabbi ? false : true}
                    // value={formData[fieldName]}
                    value={formState["umbi"]}
                    onChange={(e) => handleInputChange(e)} // onBlur={() => handleBlur(fieldName)}
                  >
                    {umbi.map((option) => (
                      <MenuItem
                        key={option.value}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                        value={option.value}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {" "}
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography
                id="UMstacked"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  UM Stacked
                  <TextField
                    select={"select"}
                    sx={{
                      fontFamily: '"Poppins", sans-serif',
                      marginTop: "5px",
                    }}
                    fullWidth
                    placeholder="UM Stacked"
                    // label={"UM Stacked"}
                    name={"umStacked"}
                    size="small"
                    // value={formData[fieldName]}
                    value={formState["umStacked"]}
                    onChange={(e) => handleInputChange(e)}
                    // onBlur={() => handleBlur(fieldName)}
                  >
                    {CommonYesNo.map((option) => (
                      <MenuItem
                        key={option.value}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                        value={option.value}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {" "}
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography
                id="medpay"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  Med Pay
                  <TextField
                    // select={"select"}
                    select
                    sx={{
                      fontFamily: '"Poppins", sans-serif',
                      marginTop: "5px",
                    }}
                    fullWidth
                    placeholder="Med Pay"
                    // label={"Med Pay"}
                    name={"medPay"}
                    size="small"
                    // value={formState["medPay"]}
                    // name="medPay"
                    value={formState.medPay}
                    // value={formData[fieldName]}
                    // onChange={(e) => handleInputChange(e)}
                    onChange={handleInputChange}
                    // onBlur={() => handleBlur(fieldName)}
                  >
                    {medPay.map((option) => (
                      <MenuItem
                        key={option.value}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                        value={option.value}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>
            </Grid>
            <hr
              style={{
                borderTop: "1px solid #ccc",
                width: "100%",
                marginTop: "30px",
              }}
            />
            {/* Toyota */}
            <Grid item xs={12} sm={12}>
              {vehicleState.length > 0 &&
                vehicleState.map((vehicle, index) => (
                  <Grid container item xs={12} sm={12} spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: '"Poppins", sans-serif',
                          color: "#242929",
                          fontWeight: "bold",
                        }}
                      >
                        {`#${index + 1} ${vehicle.manufacturer} ${
                          vehicle.model
                        }`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Typography
                      id="comp"
                        variant="body1"
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                      >
                        Comp
                        <TextField
                          // select={"select"}
                           size="small"
                          select
                          sx={{ fontFamily: '"Poppins", sans-serif' }}
                          fullWidth
                          placeholder="Comp"
                          name={"comp"}
                          value={vehicle.comp}
                          onChange={(e) => handleInputChangeVehicle(e, index)}
                        >
                          {comp.map((option) => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              sx={{ fontFamily: '"Poppins", sans-serif' }}
                            >
                              <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                                {option.label}
                              </Box>
                            </MenuItem>
                          ))}
                        </TextField>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Typography
                      id="coll"
                        variant="body1"
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                      >
                        Coll
                        <TextField
                          // select={"select"}
                          select
                          sx={{ fontFamily: '"Poppins", sans-serif' }}
                          // InputProps={{
                          //   style: { height: 30, fontSize: 10, width: 150 },
                          // }}
                          // InputLabelProps={{
                          //   style: { fontSize: 12 },
                          //   shrink: true,
                          // }}
                          // margin="normal"
                          size="small"
                          // label={"Coll"}
                          fullWidth
                          placeholder="Coll"
                          name={"coll"}
                          value={vehicle.coll}
                          onChange={(e) => handleInputChangeVehicle(e, index)}
                        >
                          {coll.map((option) => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              sx={{ fontFamily: '"Poppins", sans-serif' }}
                            >
                              <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                                {" "}
                                {option.label}
                              </Box>
                            </MenuItem>
                          ))}
                        </TextField>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Typography
                      id="roadside"
                        variant="body1"
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                      >
                        Roadside
                        <TextField
                          select={"select"}
                          sx={{ fontFamily: '"Poppins", sans-serif' }}
                          // InputProps={{
                          //   style: { height: 30, fontSize: 10, width: 150 },
                          // }}
                          // InputLabelProps={{
                          //   style: { fontSize: 12 },
                          //   shrink: true,
                          // }}
                          // margin="normal"
                          size="small"
                          // label={"Roadside"}
                          fullWidth
                          placeholder="Roadside"
                          name={"roadside"}
                          value={vehicle.roadside}
                          onChange={(e) => handleInputChangeVehicle(e, index)}
                        >
                          {Roadside.map((option) => (
                            <MenuItem
                              sx={{ fontFamily: '"Poppins", sans-serif' }}
                              key={option.value}
                              value={option.value}
                            >
                              <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                                {" "}
                                {option.label}
                              </Box>
                            </MenuItem>
                          ))}
                        </TextField>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Typography
                      id="rental"
                        variant="body1"
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                      >
                        Rental
                        <TextField
                          select={"select"}
                          sx={{ fontFamily: '"Poppins", sans-serif' }}
                          // InputProps={{
                          //   style: { height: 30, fontSize: 10, width: 150 },
                          // }}
                          // InputLabelProps={{
                          //   style: { fontSize: 12 },
                          //   shrink: true,
                          // }}
                          // margin="normal"
                          size="small"
                          // label={"Rental"}
                          fullWidth
                          placeholder="Rental"
                          name={"rental"}
                          value={vehicle.rental}
                          onChange={(e) => handleInputChangeVehicle(e, index)}
                        >
                          {Rentals.map((option) => (
                            <MenuItem
                              key={option.value}
                              sx={{ fontFamily: '"Poppins", sans-serif' }}
                              value={option.value}
                            >
                              <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                                {" "}
                                {option.label}
                              </Box>
                            </MenuItem>
                          ))}
                        </TextField>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Typography
                      id="safety"
                        variant="body1"
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                      >
                        Safety
                        <TextField
                          select={"select"}
                          sx={{ fontFamily: '"Poppins", sans-serif' }}
                          // InputProps={{
                          //   style: { height: 30, fontSize: 10, width: 150 },
                          // }}
                          // InputLabelProps={{
                          //   style: { fontSize: 12 },
                          //   shrink: true,
                          // }}
                          // margin="normal"
                          size="small"
                          // label={"Safety"}
                          fullWidth
                          placeholder="safety"
                          name={"safety"}
                          value={vehicle.safety}
                          onChange={(e) => handleInputChangeVehicle(e, index)}
                        >
                          {CommonYesNo.map((option) => (
                            <MenuItem
                              key={option.value}
                              sx={{ fontFamily: '"Poppins", sans-serif' }}
                              value={option.value}
                            >
                              <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                                {" "}
                                {option.label}
                              </Box>
                            </MenuItem>
                          ))}
                        </TextField>
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
            </Grid>

            <Grid
              container
              item
              xs={12}
              sm={12}
              spacing={1}
              sx={{ marginTop: "10px" }}
            >
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  // backgroundColor: theme?.palette?.primary?.main,
                  color: "black",
                  padding: 5,
                  marginLeft: 5,
                  fontFamily: '"Poppins", sans-serif',
                }}
                sx={{ position: "sticky" }}
              >
                <Typography
                id="companyselection"
                  variant="h5"
                  fontWeight="600"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  Company Selection
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  padding: 5,
                  marginLeft: 5,
                  width: "100%",
                  overflowX: "hidden",
                  fontFamily: '"Poppins", sans-serif',
                }}
              >

                <table className={classes.table}>
                  <thead className={classes.thead}>
                    <tr>
                      <th className={classes.th}>#</th>
                      <th className={classes.th}>Company</th>
                      <th className={classes.th}>Rates</th>
                    </tr>
                  </thead>
                  <tbody className={classes.tbody}>
                    {initialRows.length === 0 ? (
                      <tr>
                        <td
                        id="nocompanyfound"
                          className={classes.td}
                          colSpan="3"
                          style={{
                            textAlign: "center",
                            padding: "20px",
                            fontSize: "16px",
                            fontWeight: "bold",
                            color: "#888",
                          }}
                        >
                          No Company found
                        </td>
                      </tr>
                    ) : (
                      initialRows.map((row, index) => (
                        <tr key={row.id}>
                          <td className={classes.td}>{index + 1}</td>
                          <td className={classes.td}>{row.company}</td>
                          <td className={classes.td}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={checkedItemss.includes(
                                    row.id.toString()
                                  )}
                                  onChange={handleCheckChanges}
                                  name={`rates-${row.id}`}
                                  value={row.id}
                                  color="primary"
                                />
                              }
                            />
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                <style>{`
        .Mui-even {
          background-color: #f4f5f9;
        }

        .Mui-odd {
          background-color: transparent;
        }
      `}</style>
              </Grid>
              <Grid item xs={12} md={12} sx={{ position: "sticky" }}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
          onClick={handleGetRates}
          variant="contained"
          sx={{ mt: 3, mb: 2, fontFamily: '"Poppins", sans-serif' }}
          disabled={loading} // Optionally disable the button while loading
        >
          {loading ? (
            <CircleOutlined size="24" color="secondary" />
          ) : isReRated ? (
            'Re-rate'
          ) : (
            'Get Rates'
          )}
        </Button>
                </Box>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} spacing={1}>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography
                id="term"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  Term
                  <TextField
                    select={"select"}
                    sx={{
                      fontFamily: '"Poppins", sans-serif',
                      marginTop: "5px",
                    }}
                    fullWidth
                    placeholder="Term"
                    // label={"Term"}
                    name={"term"}
                    value={formState["term"]}
                    onChange={(e) => handleInputChange(e)}
                    // onBlur={() => handleBlur(fieldName)}
                  >
                    {TermTenture.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>

              <Grid item xs={12} sm={2} lg={3}>
                <Typography
                id="payplanoptions"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  Pay Plan Options
                  <TextField
                    select={"select"}
                    sx={{
                      fontFamily: '"Poppins", sans-serif',
                      marginTop: "5px",
                    }}
                    fullWidth
                    placeholder="Pay Plan Options"
                    // label={"Pay Plan Options"}
                    name={"payPlanOptions"}
                    value={formState["payPlanOptions"]}
                    onChange={(e) => handleInputChange(e)}
                    // onBlur={() => handleBlur(fieldName)}
                  >
                    {payPlanOptionIns.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>
            </Grid>
            {/* table */}
            <Grid item xs={12} sm={12}>
              <Box
                sx={{
                  border: "#E7E7E7 solid 1px",
                  marginTop: "1rem",
                  borderRadius: "4px",
                }}
              >
                {mergeXMLjson .length > 0 ? (
                  <Table
                    aria-label="collapsible table"
                    size="small"
                    style={{
                      borderCollapse: "separate",
                      borderSpacing: "0 0.5rem",
                      border: "none",
                    }}
                  >
                    <TableHead>
                      <TableRow
                        sx={{
                          "& th": {
                            backgroundColor: "transparent",
                            color: "#242929",
                            fontSize: "1rem",
                            fontWeight: "bold",
                            fontFamily: '"Poppins", sans-serif',
                            border: "none",
                          },
                        }}
                      >
                        <TableCell
                        id="quote"
                          sx={{
                            fontFamily: '"Poppins", sans-serif',
                            color: "#242929",
                          }}
                          align="left"
                        >
                          Quote
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: '"Poppins", sans-serif',
                            color: "#242929",
                          }}
                          align="left"
                        ></TableCell>
                        <TableCell
                        id="companyname"
                          sx={{
                            fontFamily: '"Poppins", sans-serif',
                            color: "#242929",
                          }}
                        >
                          Company Name
                        </TableCell>
                        <TableCell
                        id="totalpremium"
                          sx={{
                            fontFamily: '"Poppins", sans-serif',
                            color: "#242929",
                          }}
                          align="left"
                        >
                          Total Premium
                        </TableCell>
                        <TableCell
                        id="downpayment"
                          sx={{
                            fontFamily: '"Poppins", sans-serif',
                            color: "#242929",
                          }}
                          align="left"
                        >
                          Down Payment
                        </TableCell>
                        <TableCell
                        id="schedules"
                          sx={{
                            fontFamily: '"Poppins", sans-serif',
                            color: "#242929",
                          }}
                          align="left"
                        >
                          Schedules
                        </TableCell>
                        <TableCell
                        id="paymentamount"
                          sx={{
                            fontFamily: '"Poppins", sans-serif',
                            color: "#242929",
                          }}
                          align="left"
                        >
                          Payment Amount
                        </TableCell>
                        <TableCell
                        id="term"
                          sx={{
                            fontFamily: '"Poppins", sans-serif',
                            color: "#242929",
                          }}
                          align="left"
                        >
                          Term
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {mergeXMLjson .map((row, i) => {
                        const AdditionalQuotedScenario = getValueByKey(
                          JSON.parse(row),
                          "ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.AdditionalQuotedScenario"
                        );
                        // const PaymentOption = getValueByKey(JSON.parse(row), "ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.PaymentOption");
                        let payplan = formState.payPlanOptions;
                        let term = formState.term;
                        if (formState.payPlanOptions === "INS") {
                          payplan = "";
                        } else if (formState.payPlanOptions === "PIF") {
                          term = 1;
                        }

                        let quotedCoverage =
                          AdditionalQuotedScenario.find(
                            (quote) =>
                              quote.id ===
                              `com.itc_${formState.term}Mth${payplan}`
                          ) || "";

                        if (
                          !quotedCoverage &&
                          formState.payPlanOptions === "INS"
                        ) {
                          quotedCoverage = AdditionalQuotedScenario.find(
                            (quote) =>
                              quote.id === `com.itc_${formState.term}MthEFT`
                          );
                        }

                        let paymentOption = null;
                        if (formState.term === "12") {
                          paymentOption =
                            quotedCoverage?.["com.itc_PaymentOption"];
                        } else if (formState.term === "6") {
                          paymentOption = quotedCoverage?.["PaymentOption"];
                        }
                        const vehicleCoverages =
                          quotedCoverage?.QuotedCoverage?.reduce(
                            (acc, coverage) => {
                              const coverageCd = coverage.Coverage?.CoverageCd;
                              const validCoverages = [
                                "BI",
                                "COLL",
                                "COMP",
                                "PD",
                                "UM",
                              ];
                              if (validCoverages.includes(coverageCd)) {
                                const idRef = coverage.IdRef;
                                if (!acc[idRef]) {
                                  acc[idRef] = [];
                                }
                                acc[idRef].push(coverage);
                              }
                              return acc;
                            },
                            {}
                          ) || {};

                        // const policyCoverages = quotedCoverage?.QuotedCoverage?.filter(coverage => {
                        //   const coverageCd = coverage.Coverage?.CoverageCd;
                        //   return ['BI', 'COLL', 'COMP', 'PD', 'UM'].includes(coverageCd);
                        // }) || [];

                        // const policyValue = policyCoverages.reduce((acc, coverage) => {
                        //   return acc + (coverage.Coverage?.CurrentTermAmt?.Amt || 0);
                        // }, 0);

                        const installmentInfo =
                          quotedCoverage?.["com.itc_PaymentOption"]
                            ?.InstallmentInfo?.InstallmentAmt || [];
                        // let quotedCoverage =
                        //   AdditionalQuotedScenario.find(
                        //     (quote) => quote.id === `com.itc_${formState.term}Mth${payplan}`
                        //   ) || "";
                        // if (quotedCoverage == "" && formState.payPlanOptions === "INS") {
                        //   quotedCoverage = AdditionalQuotedScenario.find(
                        //     (quote) => quote.id === `com.itc_${formState.term}MthEFT`
                        //   );
                        // }
                        // // let paymentOption = PaymentOption;

                        // let paymentOption = null;
                        //     if (formState.term === "12") {
                        //       paymentOption = quotedCoverage["com.itc_PaymentOption"];
                        //     } else if (formState.term === "6") {
                        //       paymentOption = quotedCoverage["PaymentOption"];
                        //     }

                        const biCoverage = quotedCoverage.QuotedCoverage.find(
                          (coverage) => coverage.Coverage.CoverageCd === "BI"
                        );
                        const pdCoverage = quotedCoverage.QuotedCoverage.find(
                          (coverage) => coverage.Coverage.CoverageCd === "PD"
                        );
                        const umCoverage = quotedCoverage.QuotedCoverage.find(
                          (coverage) => coverage.Coverage.CoverageCd === "UM"
                        );
                        const compCoverage = quotedCoverage.QuotedCoverage.find(
                          (coverage) => coverage.Coverage.CoverageCd === "COMP"
                        );
                        const collCoverage = quotedCoverage.QuotedCoverage.find(
                          (coverage) => coverage.Coverage.CoverageCd === "COLL"
                        );
                        const policyFee = quotedCoverage.QuotedCoverage.find(
                          (coverage) => coverage.Coverage.CoverageCd === "POLFE"
                        );
                        const underwritingFee =
                          quotedCoverage.QuotedCoverage.find(
                            (coverage) =>
                              coverage.Coverage.CoverageCd ===
                              "com.qqonline_MGAFEE"
                          );

                        const biValue = biCoverage
                          ? biCoverage.Coverage.CurrentTermAmt.Amt
                          : 0;
                        const pdValue = pdCoverage
                          ? pdCoverage.Coverage.CurrentTermAmt.Amt
                          : 0;
                        const umValue = umCoverage
                          ? umCoverage.Coverage.CurrentTermAmt.Amt
                          : 0;
                        const compValue = compCoverage
                          ? compCoverage.Coverage.CurrentTermAmt.Amt
                          : 0;
                        const collValue = collCoverage
                          ? collCoverage.Coverage.CurrentTermAmt.Amt
                          : 0;

                        const policyValue = policyFee
                          ? policyFee.Coverage.CurrentTermAmt.Amt
                          : 0;
                        const underwritingValue = underwritingFee
                          ? underwritingFee.Coverage.CurrentTermAmt.Amt
                          : 0;
                        // const idRef = biCoverage ? biCoverage.IdRef : pdCoverage ? pdCoverage.IdRef : 'Vehicle';
                        // const installmentInfo = quotedCoverage["com.itc_PaymentOption"]?.InstallmentInfo.InstallmentAmt || [];

                        return (
                          <>
                            <TableRow
                              sx={{
                                backgroundColor: "#F8F8F8",
                                "& td": {
                                  borderBottom: "#E7E7E7 solid 1px",
                                  fontSize: "14px",
                                  fontFamily: '"Poppins", sans-serif',
                                },
                              }}
                            >
                              <TableCell align="left">
                                <a
                            id={`bridgeLink_${row.id}`}
                                  href={getValueByKey(
                                    JSON.parse(row),
                                    "ACORD.InsuranceSvcRs.PersAutoPolicyQuoteInqRs.FileAttachmentInfo.WebsiteURL"
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  sx={{
                                    fontFamily: '"Poppins", sans-serif',
                                    color: "#1976d2",
                                    textDecoration: "none",
                                  }}
                                >
                                  Bridge
                                </a>

                                <Button
                                  onClick={handleClickOpen}
                                  id="details"
                                  sx={{
                                    fontFamily: '"Poppins", sans-serif',
                                    textTransform: "none",
                                  }}
                                >
                                  Details
                                </Button>
                              </TableCell>
                              <TableCell align="left">
                                <Dialog
                                  open={opens}
                                  onClose={handleClose}
                                  maxWidth="lg"
                                  fullWidth
                                >
                                  <DialogTitle
                                    sx={{ borderBottom: "#E7E7E7 solid 1px" }}
                                  >
                                    <Typography
                                      fontWeight="600"
                                      id="breakdowndetails"
                                      sx={{
                                        fontFamily: '"Poppins", sans-serif',
                                        fontSize: "18px",
                                      }}
                                    >
                                      BreakDown Details
                                    </Typography>
                                    <IconButton
                                      aria-label="close"
                                      onClick={handleClose}
                                      sx={{
                                        position: "absolute",
                                        right: 8,
                                        top: 8,
                                        color: (theme) =>
                                          theme.palette.grey[500],
                                      }}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </DialogTitle>

                                  <DialogContent>
                                    <Box
                                      sx={{ display: "flex", flexWrap: "wrap" }}
                                    >
                                      {Object.keys(vehicleCoverages).map(
                                        (vehicleId, index) => (
                                          <Box
                                            sx={{
                                              width: "25%",
                                              boxSizing: "border-box",
                                              padding: "20px 10px",
                                            }}
                                            key={index}
                                          >
                                            <Grid container spacing={2}>
                                              <Grid item xs={12}>
                                                <Typography
                                                id="vehicle"
                                                  sx={{
                                                    fontFamily:
                                                      '"Poppins", sans-serif',
                                                    fontSize: "1rem",
                                                    fontWeight: "bold",
                                                  }}
                                                  gutterBottom
                                                  component="div"
                                                >
                                                  Vehicle {index + 1}
                                                </Typography>
                                              </Grid>
                                              <Grid item xs={12}>
                                                <TableContainer>
                                                  <Table
                                                    size="small"
                                                    aria-label="nested table"
                                                    sx={{
                                                      fontFamily:
                                                        '"Poppins", sans-serif',
                                                      border:
                                                        "#E7E7E7 solid 1px",
                                                    }}
                                                  >
                                                    <TableBody>
                                                      {vehicleCoverages[
                                                        vehicleId
                                                      ]?.map(
                                                        (coverage, index) => (
                                                          <TableRow
                                                            sx={{
                                                              backgroundColor:
                                                                index % 2 === 0
                                                                  ? "white"
                                                                  : "#F8F8F8",
                                                            }}
                                                            key={index}
                                                          >
                                                            <TableCell>
                                                              <Box
                                                                sx={{
                                                                  fontFamily:
                                                                    '"Poppins", sans-serif',
                                                                }}
                                                              >
                                                                {coverage
                                                                  .Coverage
                                                                  ?.CoverageCd ||
                                                                  "N/A"}
                                                              </Box>
                                                            </TableCell>
                                                            <TableCell>
                                                              <Box
                                                                sx={{
                                                                  fontFamily:
                                                                    '"Poppins", sans-serif',
                                                                }}
                                                              >
                                                                {formattedCurrency(
                                                                  coverage
                                                                    .Coverage
                                                                    ?.CurrentTermAmt
                                                                    ?.Amt || 0
                                                                )}
                                                              </Box>
                                                            </TableCell>
                                                          </TableRow>
                                                        )
                                                      )}
                                                    </TableBody>
                                                  </Table>
                                                </TableContainer>
                                              </Grid>
                                            </Grid>
                                          </Box>
                                        )
                                      )}
                                      {/* <Box sx={{ width: '25%', boxSizing: 'border-box', padding: '20px 10px' }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography sx={{ fontFamily: '"Poppins", sans-serif', fontSize: '1rem', fontWeight: 'bold' }} gutterBottom component="div">
                              Policy
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TableContainer>
                              <Table size="small" aria-label="nested table" sx={{ fontFamily: '"Poppins", sans-serif', border: '#E7E7E7 solid 1px' }}>
                                <TableBody>
                                  {policyCoverages.map((coverage, index) => (
                                    <TableRow sx={{ backgroundColor: index % 2 === 0 ? 'white' : '#F8F8F8' }} key={index}>
                                      <TableCell>
                                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                                          {coverage.Coverage?.CoverageDesc || coverage.Coverage?.CoverageCd || 'N/A'}
                                        </Box>
                                      </TableCell>
                                      <TableCell>
                                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                                          {formattedCurrency(coverage.Coverage?.CurrentTermAmt?.Amt || 0)}
                                        </Box>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography sx={{ fontFamily: '"Poppins", sans-serif', fontSize: '14px', display: 'flex' }} gutterBottom component="div">
                              <Box sx={{ fontFamily: '"Poppins", sans-serif', fontWeight: 'bold', marginRight: '10px' }}>
                                Total Premium:
                              </Box>
                              {formattedCurrency(policyValue)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box> */}
                                      <Grid item xs={12}>
                                        <Typography
                                        id="policyfee"
                                          sx={{
                                            fontFamily: '"Poppins", sans-serif',
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                          }}
                                          gutterBottom
                                          component="div"
                                        >
                                          Policy Fee:{" "}
                                          <span
                                            style={{ fontWeight: "normal" }}
                                          >
                                            {formattedCurrency(policyValue)}
                                          </span>
                                        </Typography>
                                        <Typography
                                        id="underwritingfee"
                                          sx={{
                                            fontFamily: '"Poppins", sans-serif',
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                          }}
                                          gutterBottom
                                          component="div"
                                        >
                                          Underwriting Fee:{" "}
                                          <span
                                            style={{ fontWeight: "normal" }}
                                          >
                                            {formattedCurrency(
                                              underwritingValue
                                            )}
                                          </span>
                                        </Typography>
                                        <Typography
                                        id="totalpremium"
                                          sx={{
                                            fontFamily: '"Poppins", sans-serif',
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                          }}
                                          gutterBottom
                                          component="div"
                                        >
                                          Total Premium:{" "}
                                          <span
                                            style={{ fontWeight: "normal" }}
                                          >
                                            {formattedCurrency(
                                              quotedCoverage.FullTermAmt.Amt
                                            )}
                                          </span>
                                        </Typography>
                                      </Grid>
                                    </Box>
                                  </DialogContent>
                                </Dialog>
                              </TableCell>
                              {/* <TableCell sx={{fontFamily: '"Poppins", sans-serif'}}>{getValueByKey(JSON.parse(row), "ACORD.SignonRs.ClientApp.Org")}</TableCell>
                <TableCell sx={{fontFamily: '"Poppins", sans-serif'}} align="left">{formattedCurrency(quotedCoverage.FullTermAmt.Amt)}</TableCell>
                <TableCell sx={{fontFamily: '"Poppins", sans-serif'}} align="left">
                  {formattedCurrency(quotedCoverage["com.itc_PaymentOption"]?.DepositAmt.Amt)}
                </TableCell>
                <TableCell align="left" sx={{fontFamily: '"Poppins", sans-serif'}}>
                  {term !== 1 ? term - 1 : quotedCoverage["com.itc_PaymentOption"]?.NumPayments}
                </TableCell>
                <TableCell align="left" sx={{fontFamily: '"Poppins", sans-serif'}}>
                  {formattedCurrency(quotedCoverage["com.itc_PaymentOption"]?.InstallmentInfo.InstallmentAmt[0].Amt)}

                </TableCell>
                <TableCell align="left" sx={{fontFamily: '"Poppins", sans-serif'}}>{term}</TableCell> */}
                              <TableCell
                                sx={{ fontFamily: '"Poppins", sans-serif' }}
                              >
                                {getValueByKey(
                                  JSON.parse(row),
                                  "ACORD.SignonRs.ClientApp.Org"
                                )}
                              </TableCell>
                              <TableCell
                                sx={{ fontFamily: '"Poppins", sans-serif' }}
                                align="left"
                              >
                                {formattedCurrency(
                                  quotedCoverage.FullTermAmt.Amt
                                )}
                              </TableCell>
                              <TableCell
                                sx={{ fontFamily: '"Poppins", sans-serif' }}
                                align="left"
                              >
                                {formattedCurrency(
                                  paymentOption?.DepositAmt?.Amt
                                )}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ fontFamily: '"Poppins", sans-serif' }}
                              >
                                {/* {paymentOption?.NumPayments || ''} */}
                                <Button
                                id="detailsbutton"
                                  onClick={handleClickOpenInvoice}
                                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                                >
                                  Details
                                </Button>
                                <Dialog
                                  open={openInvoice}
                                  onClose={handleCloseInvoice}
                                  maxWidth="lg"
                                  fullWidth
                                >
                                  <DialogTitle
                                    sx={{
                                      borderBottom: "#E7E7E7 solid 1px",
                                      marginBottom: "1rem",
                                    }}
                                  >
                                    <Typography
                                    id="scheduledetails"
                                      sx={{
                                        fontFamily: '"Poppins", sans-serif',
                                        fontWeight: "bold",
                                        fontSize: "18px",
                                      }}
                                      component="div"
                                    >
                                      Schedule Details
                                    </Typography>
                                    <IconButton
                                      aria-label="close"
                                      onClick={handleCloseInvoice}
                                      sx={{
                                        position: "absolute",
                                        right: 8,
                                        top: 8,
                                        color: (theme) =>
                                          theme.palette.grey[500],
                                      }}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </DialogTitle>

                                  <DialogContent>
                                    <Box sx={{ margin: 1 }}>
                                      <Typography
                                        sx={{
                                          fontFamily: '"Poppins", sans-serif',
                                          display: "flex",
                                          fontSize: "14px",
                                        }}
                                        gutterBottom
                                        component="div"
                                      >
                                        <Box
                                        id="duedate"
                                          sx={{
                                            fontFamily: '"Poppins", sans-serif',
                                            fontWeight: "bold",
                                            marginRight: "10px",
                                          }}
                                        >
                                          Due Date:
                                        </Box>
                                        {
                                          quotedCoverage[
                                            "com.itc_PaymentOption"
                                          ]?.FirstPaymentDueDt
                                        }
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontFamily: '"Poppins", sans-serif',
                                          display: "flex",
                                          fontSize: "14px",
                                        }}
                                        gutterBottom
                                        component="div"
                                      >
                                        <Box
                                        id="downpaymentpercentage"
                                          sx={{
                                            fontFamily: '"Poppins", sans-serif',
                                            fontWeight: "bold",
                                            marginRight: "10px",
                                          }}
                                        >
                                          Down Payment Percentage:
                                        </Box>
                                        {
                                          quotedCoverage[
                                            "com.itc_PaymentOption"
                                          ]?.DownPaymentPct
                                        }
                                        %
                                      </Typography>
                                      <Table
                                        size="small"
                                        aria-label="installment details"
                                        sx={{ border: "#E7E7E7 solid 1px" }}
                                      >
                                        <TableHead>
                                          <TableRow>
                                            <TableCell
                                            id="installmentnumber"
                                              sx={{
                                                fontFamily:
                                                  '"Poppins", sans-serif',
                                              }}
                                            >
                                              Installment Number
                                            </TableCell>
                                            <TableCell
                                            id="amount"
                                              sx={{
                                                fontFamily:
                                                  '"Poppins", sans-serif',
                                              }}
                                            >
                                              Amount
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {installmentInfo.map(
                                            (installment, index) => (
                                              <TableRow
                                                key={index}
                                                sx={{
                                                  backgroundColor:
                                                    index % 2 === 0
                                                      ? "white"
                                                      : "#F8F8F8",
                                                }}
                                              >
                                                <TableCell
                                                  sx={{
                                                    fontFamily:
                                                      '"Poppins", sans-serif',
                                                  }}
                                                >
                                                  {
                                                    installment.InstallmentNumber
                                                  }
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    fontFamily:
                                                      '"Poppins", sans-serif',
                                                  }}
                                                >
                                                  {formattedCurrency(
                                                    installment.Amt
                                                  )}
                                                </TableCell>
                                              </TableRow>
                                            )
                                          )}
                                        </TableBody>
                                      </Table>
                                    </Box>
                                  </DialogContent>
                                </Dialog>
                              </TableCell>

                              <TableCell
                                align="left"
                                sx={{ fontFamily: '"Poppins", sans-serif' }}
                              >
                                {installmentInfo.length > 0
                                  ? formattedCurrency(installmentInfo[0].Amt)
                                  : ""}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ fontFamily: '"Poppins", sans-serif' }}
                              >
                                {term}
                              </TableCell>
                              {/* <TableCell sx={{fontFamily: '"Poppins", sans-serif'}}>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </TableCell> */}
                            </TableRow>
                            {/* <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse in={open} timeout="auto" unmountOnExit>

                    </Collapse>
                  </TableCell>

                </TableRow> */}
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>
                ) : (
                  msgStatus &&
                  ["Error", "REJECTED"].includes(msgStatus.MsgStatusCd) && (
                    <Table
                      aria-label="error table"
                      size="small"
                      style={{
                        borderCollapse: "separate",
                        borderSpacing: "0 0.5rem",
                        border: "none",
                      }}
                    >
                      <TableHead>
                        <TableRow
                          sx={{
                            "& th": {
                              backgroundColor: "transparent",
                              color: "black",
                              fontSize: "1rem",
                              fontWeight: "bold",
                              border: "none",
                            },
                          }}
                        >
                          <TableCell
                          id="quote"
                            sx={{ fontFamily: '"Poppins", sans-serif' }}
                            align="left"
                          >
                            Quote
                          </TableCell>
                          <TableCell
                            sx={{ fontFamily: '"Poppins", sans-serif' }}
                            align="left"
                          ></TableCell>
                          <TableCell
                          id="companyname"
                            sx={{ fontFamily: '"Poppins", sans-serif' }}
                            align="left"
                          >
                            Company Name
                          </TableCell>
                          <TableCell
                          id="totalpremium"
                            sx={{ fontFamily: '"Poppins", sans-serif' }}
                            align="left"
                          >
                            Total Premium
                          </TableCell>
                          <TableCell
                          id="downpayment"
                            sx={{ fontFamily: '"Poppins", sans-serif' }}
                            align="left"
                          >
                            Down Payment
                          </TableCell>
                          <TableCell
                          id="payments"
                            sx={{ fontFamily: '"Poppins", sans-serif' }}
                            align="left"
                          >
                            #Payments
                          </TableCell>
                          <TableCell
                          id="paymentamount"
                            sx={{ fontFamily: '"Poppins", sans-serif' }}
                            align="left"
                          >
                            Payment Amount
                          </TableCell>
                          <TableCell
                          id="term"
                            sx={{ fontFamily: '"Poppins", sans-serif' }}
                            align="left"
                          >
                            Term
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={8}
                            align="center"
                            sx={{
                              border: "none",
                              backgroundColor: "#f8d7da",
                              color: "#721c24",
                            }}
                          >
                            {/* {`${msgStatus.MsgStatusDesc}`} */}
                            <h3
                            id="noratesavailable"
                            sx={{ fontFamily: '"Poppins", sans-serif' }}>
                              No Rates available
                            </h3>
                            {/* <a href="#" onClick={(e) => { e.preventDefault(); handleClickOpen(); }}>
                Details
              </a> */}
                            <Button
                              onClick={handleClickOpen}
                              id="details"
                              sx={{
                                fontFamily: '"Poppins", sans-serif',
                                textTransform: "none",
                              }}
                            >
                              Details
                            </Button>
                            <Dialog open={opens} onClose={handleClose}>
                              <DialogTitle>Warnings</DialogTitle>
                              <DialogContent>
                                {msgStatus &&
                                  ["Error", "REJECTED"].includes(
                                    msgStatus.MsgStatusCd
                                  ) && (
                                    // <SnackbarContent
                                    //   message={` ${msgStatus.MsgStatusDesc}`}
                                    // />
                                    <div>
                                      <Box
                                        sx={{
                                          backgroundColor: "#45a7a7",
                                          color: "#fff",
                                          padding: "16px",
                                          borderRadius: "4px",
                                          margin: "16px 0",
                                          boxShadow:
                                            "0 3px 5px rgba(0, 0, 0, 0.3)",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          fontFamily: '"Poppins", sans-serif',
                                        }}
                                      >
                                        {msgStatus.MsgStatusDesc}
                                      </Box>
                                    </div>
                                  )}
                              </DialogContent>
                              <Button
                              id="closebutton"
                                onClick={handleClose}
                                sx={{ fontFamily: '"Poppins", sans-serif' }}
                                color="primary"
                              >
                                Close
                              </Button>
                            </Dialog>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ fontFamily: '"Poppins", sans-serif' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CompareQuotesTab;

import React, { useEffect, useState } from 'react';
import { 
  Box, 
  Button, 
  IconButton, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Typography, 
  TablePagination 
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../loader/loader';
import { getAllLocationGroupsAction, deleteGrouplocationAction } from "../../redux/actions/locationGroupActions";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: '#1565c0', // Set header background color to #1565c0
  color: theme.palette.common.white,
  fontFamily: '"Poppins", sans-serif',
}));
 
 
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover, // Odd rows have a subtle background color
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected, // Row background changes on hover
  },
  '& td, & th': {
    padding: theme.spacing(1.5),
    fontFamily: '"Poppins", sans-serif', // Poppins font applied to all cells
  },
}));

const LocationGroup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationGroups = useSelector((state) => state.locationGroup.allLocationGroups || []);
  const deleteLoading = useSelector((state) => state.locationGroup.deleteLoading); // Specific delete loading state
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  useEffect(() => {
    dispatch(getAllLocationGroupsAction(page, rowsPerPage));
  }, [dispatch, page, rowsPerPage]);

  const handleEditClick = (group) => {
    navigate('/locationgroup', {
      state: {
        groupName: group.locationGroupName, 
        selectedLocations: group.locations || [],
        status: group.status,
        isEdit: true,
        groupId: group.locationGroupId
      },
    });
  };

  const onDeleteGroup = (id) => {
    dispatch(deleteGrouplocationAction(id)); 
  };

  const handleAddNewGroup = () => {
    navigate('/locationgroup');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRows = locationGroups.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box sx={{ padding: '20px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>        
        <Typography variant="h5" sx={{ fontFamily: '"Poppins", sans-serif' }}>Locations Group</Typography>
        <Button variant="contained" color="primary" sx={{ background: '#1565c0', fontFamily: '"Poppins", sans-serif' }} onClick={handleAddNewGroup}>Add New Group</Button>
      </Box>

      {deleteLoading ? (
        <Loader /> // Show Loader only when deleting
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Group Name</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell align='right'>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedRows.map((group) => (
                  <StyledTableRow key={group.locationGroupId}>
                    <TableCell>{group.locationGroupName}</TableCell>
                    <TableCell>{group.status}</TableCell>
                    <TableCell align='right'>
                      <IconButton onClick={() => handleEditClick(group)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => onDeleteGroup(group.locationGroupId)}>
                        <DeleteIcon />
                      </IconButton> 
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={locationGroups.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </>
      )}
    </Box>
  );
};

export default LocationGroup;

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Grid, Button } from '@mui/material';
import { Edit, Email, Phone } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import {
  getAllCustomer as getAllCustomerAction,
  openCreateCustomerModal as openCreateCustomerModalAction,
  openUpdateCustomerModal as openUpdateCustomerModalAction
} from '../../redux/actions/customerActions';
import { getUser as getUserAction } from '../../redux/actions/userActions';
import CustomDataGrid from '../../shared/CustomDataGrid';

const useStyles = makeStyles({
  header: {
    margin: ".5em 0 !important",
  },
});

const Customers = () => {
  const classes = useStyles();
  const customer = useSelector((state) => state?.customers?.allCustomer);
  const loading = useSelector((state) => state.loading.ALL_CUSTOMERS);
  const user = useSelector((state) => state.user.user);
  const error= useSelector((state) => state?.customers?.error)
  const dispatch = useDispatch();

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: true,
      id:'name',
    },
    {
      field: 'emailId',
      id:'emailid',
      headerName: 'Email',
      width: 150,
      editable: true,
    },
    {
      field: 'primaryContact',
      id:'primarycontact',
      headerName: 'Primary Contact',
      width: 160,
      editable: true,
    },
    {
      field: 'secondaryContact',
      id:'secondarycontact',
      headerName: 'Secondary Contact',
      width: 160,
      editable: true,
    },
    {
      field: 'address',
      id:'address',
      headerName: 'Address',
      sortable: true,
      width: 160,
    },
    {
      field: 'country',
      id:'country',
      headerName: 'Country',
      sortable: true,
      width: 160,
    },
    {
      field: 'organization',
      id:'organization',
      headerName: 'Organizations',
      sortable: true,
      width: 160,
      renderCell: (params) => {
        return <div className="rowitem">{params.row.organization.name}</div>;
      },
    },
    {
      field: 'actions',
      id:'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <div>
          <Email
            style={{ cursor: 'pointer', marginRight: '8px' }}
            onClick={() => handleSendEmail(params.row.emailId)}
          />
          <Phone
            style={{ cursor: 'pointer' }}
            onClick={() => handleCallPhone(params.row.primaryContact || params.row.secondaryContact)}
          />
          <Edit
            style={{ cursor: 'pointer' }}
            onClick={() => updateCustomer(params.row.id)}
          />
        </div>
      ),
    },
  ];

  const [customerData, setMyCustomersData] = React.useState(customer || []);

  React.useEffect(() => {
    if (!user) {
      dispatch(getUserAction());
    } else {
      dispatch(getAllCustomerAction(user?.id));
    }
  }, [])

  React.useEffect(() => {
    setMyCustomersData(customer || []);
  }, [customer, dispatch, user]);

  const openCreateCustomer = () => {
    dispatch(openCreateCustomerModalAction());
  }

  const handleCallPhone = (phone) => {
    console.log(phone);
  }

  const handleSendEmail = (email) => {
    console.log(email);
  }

  const updateCustomer = (id) => {
    dispatch(openUpdateCustomerModalAction(id));
  }

  return (
    <Box sx={{ minHeight: 400, width: '100%' }}>
      <Grid item xs={12} md={12} sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography id="customers" variant="h5" className={classes.header}>Customers</Typography>
        <Button id="createcustomer" variant="contained" color="primary" sx={{ marginBottom: "1rem", padding: ".5rem 1rem" }} onClick={openCreateCustomer}>Create Customer</Button>
      </Grid>
      <CustomDataGrid
        gridData={customerData}
        columns={columns}
        loading={loading}
        error={error}
      />
    </Box>
  );
}

export default Customers;
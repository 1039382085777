import StepperComponent from "../../../common/StepperComponent";
import LeadInfoBar from "../LeadInfoBar";
import LeadSection from "./LeadSection";
import { Box, Button, Grid  } from '@mui/material';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';

const primaryButtonStyle = {
    height: '42px',
    minWidth: '90px',
    backgroundColor: '#0173BC',
    color: '#FFFFFF',
    lineHeight: 'normal',
    '&:hover': {
        backgroundColor: '#0C87D7',
        color: '#FFFFFF',
    }
}
const EditLead = (props) => {
    return(        
        <Box sx={{margin: '1rem 0 0 0'}}>
            <LeadInfoBar data={{ownerName:props.data.ownerName,cell:props.data.cell,phoneNumber:props.data.phoneNumber,email:props.data.email}} />
            <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={6} md={10}>
                    <StepperComponent />
                </Grid>
                <Grid item xs={12} sm={6} md={2} sx={{    
                    display:' flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                    margin: '15px 0 0 0',
                }}>
                    <Button sx={{...primaryButtonStyle, textTransform: 'capitalize'}} variant="contained">
                        <DoneOutlinedIcon sx={{margin: '0 10px 0 0'}} />
                        Marks Status as Complete
                    </Button>                
                </Grid>
            </Grid>
            <LeadSection />
        </Box>
    )
}

export default EditLead;
import { CLOSE_CREATE_LEAD_MODAL, OPEN_CREATE_LEAD_MODAL, OPEN_UPDATE_LEAD_MODAL } from "../actions/actionTypes";

const initialState = {
    allQuotes: {},
    searchQuotes:{},
    error: null,
    isCreateLeadModalOpen: false,
    selectedLeadId: null,
  };

  const quotelistingReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'QUOTE_LISTING_SUCCESS':
            return { ...state, allQuotes: action.payload, error: null };
        case 'SEARCH_QUOTE_SUCCESS':
            return { ...state, searchQuotes: action.payload, error: null };
        case OPEN_CREATE_LEAD_MODAL:
            return {...state, isCreateLeadModalOpen: true}
        case OPEN_UPDATE_LEAD_MODAL:
            return {...state, isCreateLeadModalOpen: true, selectedLeadId: action.payload}
        case CLOSE_CREATE_LEAD_MODAL:
            return {...state, isCreateLeadModalOpen: false, selectedLeadId: null}
        case 'QUOTE_LISTING_FAILURE':
        case 'SEARCH_QUOTE_FAILURE':
        case 'GET_LEAD_BY_ID_FAILURE':
            return { ...state, error: action.payload };
        default:
            return state;
    }
  };

  export default quotelistingReducer;
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Grid,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Typography,
  TextField,
  IconButton,
  Snackbar,
  CircularProgress,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import MuiAlert from '@mui/material/Alert';
import { getFormLayout, saveFormLayout, updateFormLayout } from '../../redux/actions/formLayoutActions';
import { getUser } from '../../redux/actions/userActions';

const defaultForm = {
  id: 0,
  customizableComponents: [],
  customizableLayoutType: 'default',
  insertedBy: '',
};

const Settings = () => {
  const formLayoutData = useSelector((state) => state?.formLayout);
  const user = useSelector((state) => state?.user);
  const dispatch = useDispatch();

  // State for loading, success, and error
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!user || !user?.loading) {
      dispatch(getUser());
    }
  }, []);

  const [formData, setFormData] = useState(
    formLayoutData?.customizedForm ? { ...formLayoutData.customizedForm } : { ...defaultForm }
  );
  

  useEffect(() => {
    if (formLayoutData.customizedForm && Object.keys(formLayoutData.customizedForm).length > 0) {
      setFormData(formLayoutData.customizedForm);
    }
  }, [formLayoutData.customizedForm.customizableLayoutType]);

  const handleAddField = () => {
    setFormData((prevData) => ({
      ...prevData,
      customizableComponents: [
        ...(prevData?.customizableComponents || []),
        {
          lable: '',
          componentType: 'text',
          componentActualValue: '',
          componentDefaultValue: '',
          required: false,
          minLength: 0,
          maxLength: 0,
        },
      ],
    }));
  };

  const handleRemoveField = (index) => {
    setFormData((prevData) => {
      const updatedComponents = [...prevData.customizableComponents];
      updatedComponents.splice(index, 1);
      return { ...prevData, customizableComponents: updatedComponents };
    });
  };

  const handleChange = (index, field, value) => {
    setFormData((prevData) => {
      const updatedComponents = [...prevData.customizableComponents];
      updatedComponents[index][field] = value;

      if (field === 'componentType') {
        updatedComponents[index].componentDefaultValue = '';
        updatedComponents[index].minLength = 0;
        updatedComponents[index].maxLength = 0;
      }

      return { ...prevData, customizableComponents: updatedComponents };
    });
  };

  const renderAdditionalFields = (field, index) => {
    switch (field.componentType) {
      case 'checkbox':
      case 'dropdown':
      case 'radio':
        return (
          <>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="Default Value / Options (comma-separated)"
                value={field.componentDefaultValue}
                onChange={(e) => handleChange(index, 'componentDefaultValue', e.target.value)}
                fullWidth
              />
            </Grid>
          </>
        );
      default:
        return (
          <>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                label="Min Length"
                value={field.minLength}
                onChange={(e) => handleChange(index, 'minLength', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                label="Max Length"
                value={field.maxLength}
                onChange={(e) => handleChange(index, 'maxLength', e.target.value)}
                fullWidth
              />
            </Grid>
          </>
        );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const finalFormData = { ...formData, customer: { id: user?.user?.customer?.id } };
      formData.id
        ? await dispatch(updateFormLayout({ ...finalFormData, updatedBy: `${user?.user?.firstName} ${user?.user?.lastName}` }))
        : await dispatch(saveFormLayout({ ...finalFormData, insertedBy: `${user?.user?.firstName} ${user?.user?.lastName}` }));
      setSuccessMessage('Form layout saved successfully');
    } catch (error) {
      setErrorMessage(error || 'Failed to save form layout. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const selectFromType = (e) => {
    if (e.target.value !== 'default' && e.target.value && e.target.value !== '') {
      const selectedType = e.target.value;
      setFormData({ ...formData, customizableLayoutType: selectedType });

      const customerId = user?.user?.customer?.id;
      dispatch(getFormLayout(customerId, selectedType));
    } else {
      setFormData({ ...defaultForm, customizableLayoutType: e.target.value });
    }
  };

  const handleCloseSnackbar = () => {
    setSuccessMessage('');
    setErrorMessage('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {loading && <CircularProgress />}

        <Snackbar open={Boolean(successMessage)} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <MuiAlert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
            {successMessage}
          </MuiAlert>
        </Snackbar>

        <Snackbar open={Boolean(errorMessage)} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <MuiAlert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </MuiAlert>
        </Snackbar>

        <Grid item xs={12}>
          <Typography id="customizedform" variant="h4">Customized Form Layout</Typography>
          <Select value={formData.customizableLayoutType} onChange={selectFromType} sx={{ minWidth: '120px' }}>
            <MenuItem id="selectform" selected value="default">
              Select Form Type
            </MenuItem>
            <MenuItem value="lead">Lead</MenuItem>
            <MenuItem value="agency">Agency</MenuItem>
          </Select>
        </Grid>

        {formData?.customizableComponents?.map((field, index) => (
          <Grid item container spacing={2} key={`key${index + 1}`} alignItems="center">
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                label="Label / Field Name"
                value={field.lable}
                onChange={(e) => handleChange(index, 'lable', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Select
                labelId="type-label"
                id="type"
                value={field.componentType}
                onChange={(e) => handleChange(index, 'componentType', e.target.value)}
                fullWidth
              >
                <MenuItem value="text">Text</MenuItem>
                <MenuItem value="checkbox">Checkbox</MenuItem>
                <MenuItem value="dropdown">Dropdown</MenuItem>
                <MenuItem value="radio">Radio</MenuItem>
                {/* Add other options as needed */}
              </Select>
            </Grid>
            {renderAdditionalFields(field, index)}
            <Grid item xs={12} sm={6} md={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.required}
                    onChange={(e) => handleChange(index, 'required', e.target.checked)}
                  />
                }
                label="Required"
              />
              <IconButton id="delete" onClick={() => handleRemoveField(index)}>
                <Delete />
              </IconButton>
            </Grid>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Button id="addfieldbutton" disabled={formData.customizableLayoutType === 'default'} onClick={handleAddField}>
            Add Field <Add />
          </Button>
          <Button id="submitbutton" disabled={formData.customizableLayoutType === 'default'} variant="contained" color="primary" type="submit">
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Settings;

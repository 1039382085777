const initialState = {
    allBots: {},
    updateBots:[],
    botsignup:{},
    getBots:[],
    getBotReport:[],
    getBotAllReport:[],
    getLoginBot:[],
    searchBot:{},
    searchBotDownload:{},
    searchBotlogs:{},
    searchBotDownloadLogs:{},
    error: null,

  };


  const botReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SAVE_BOT_SUCCESS':
            return { ...state, allBots: action.payload, error: null };
        case 'UPDATE_BOT_SUCCESS':
            return { ...state, updateBots: action.payload, error: null };
         case 'SIGNUP_BOT_SUCCESS':
            return { ...state, botsignup: action.payload, error: null };
        case 'GET_BOT_SUCCESS':
            return { ...state, getBots: action.payload, error: null };
        case 'GET_BOT_LOGIN_SUCCESS':
            return { ...state, getLoginBot: action.payload, error: null };
        case 'GET_BOT_REPORT_SUCCESS':
            return { ...state, getBotReport: action.payload, error: null };
         case 'SEARCH_BOT_SUCCESS':
            return { ...state, searchBot: action.payload, error: null };
         case 'SEARCH_BOTDOWNLOAD_SUCCESS':
            return { ...state, searchBotDownload: action.payload, error: null };
         case 'SEARCH_BOTLOGS_SUCCESS':
            return { ...state, searchBotlogs: action.payload, error: null };
         case 'SEARCH_BOTDOWNLOADLOGS_SUCCESS':
            return { ...state, searchBotDownloadLogs: action.payload, error: null };
        case 'GET_BOT_ALL_REPORT_SUCCESS':
            return { ...state, getBotAllReport: action.payload, error: null };
        case 'SIGNUP_BOT_FAILURE':
        case 'UPDATE_BOT_FAILURE':
        case 'GET_BOT_FAILURE':
        case 'SEARCH_BOT_FAILURE':
        case 'SEARCH_BOTDOWNLOAD_FAILURE':
        case 'GET_BOT_LOGIN_FAILURE':
        case 'GET_BOT_REPORT_FAILURE':
        case 'GET_BOT_ALL_REPORT_FAILURE':
        case 'SAVE_BOT_FAILURE':
            return { ...state, error: action.payload };
        default:
            return state;
    }
  };

  export default botReducer;
import {
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Grid,
    Box,
    MenuItem
  } from '@mui/material';

const EditPolicyUserDetail = ({formData, handleChange, handleDialogClose, handleSubmit}) => {
    
    return(
        <>
            <form onSubmit={handleSubmit}>
                <DialogTitle>Edit User Details</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Box sx={{ mb: 2 }}>
                                    <label>First Name</label>
                                    <TextField
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    fullWidth
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box sx={{ mb: 2 }}>
                                    <label>Last Name</label>
                                    <TextField
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    fullWidth
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box sx={{ mb: 2 }}>
                                    <label>Date of Birth</label>
                                    <TextField
                                        type='date'
                                        name="dateOfBirth"
                                        value={formData.dateOfBirth}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box sx={{ mb: 2 }}>
                                    <label>Gender</label>
                                    <TextField
                                        select
                                        name="gender"
                                        value={formData.gender}
                                        onChange={handleChange}
                                        fullWidth
                                    >
                                    <MenuItem value="Male">Male</MenuItem>
                                    <MenuItem value="Female">Female</MenuItem>
                                    <MenuItem value="Other">Other</MenuItem>
                                    </TextField>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box sx={{ mb: 2 }}>
                                    <label>Email</label>
                                    <TextField
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box sx={{ mb: 2 }}>
                                    <label>Address</label>
                                    <TextField
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    fullWidth
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box sx={{ mb: 2 }}>
                                    <label>Zip</label>
                                    <TextField
                                    name="zip"
                                    value={formData.zip}
                                    onChange={handleChange}
                                    fullWidth
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box sx={{ mb: 2 }}>
                                    <label>City</label>
                                    <TextField
                                    name="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                    fullWidth
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box sx={{ mb: 2 }}>
                                    <label>State</label>
                                    <TextField
                                    name="state"
                                    value={formData.state}
                                    onChange={handleChange}
                                    fullWidth
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box sx={{ mb: 2 }}>
                                    <label>Years/Months at Residence</label>
                                    <TextField
                                    name="yearsAtResidence"
                                    value={formData.yearsAtResidence}
                                    onChange={handleChange}
                                    fullWidth
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="secondary">Cancel</Button>
                        <Button color="primary" type='submit'>Save</Button>
                    </DialogActions>
                </form>
        </>
    )
}
export default EditPolicyUserDetail;
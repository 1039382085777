import { CLOSE_CREATE_USER_MODAL, OPEN_CREATE_USER_MODAL, OPEN_UPDATE_USER_MODAL } from "../actions/actionTypes";

const initialState = {
    allUser: [],
    user: null,
    error: null,
    isCreateUserModalOpen: false,
    selectedUserId: null,
      xmlJson: ''
  };

  const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'USER_INFO_SUCCESS':
        return { ...state, user: action.payload, error: null };
      case 'USER_INFO_FAILURE':
        return { ...state, user: null, error: action.payload };
      case 'ALL_USERS_SUCCESS':
          return { ...state, allUser: action.payload, error: null };
      case 'CREATE_USER_SUCCESS':
      case 'UPDATE_USER_SUCCESS':
          return {
              ...state,
              allUser: [ ...state.allUser, ...action.payload ],
              error: null,
          };
      case OPEN_CREATE_USER_MODAL:
          return {...state, isCreateUserModalOpen: true}
      case OPEN_UPDATE_USER_MODAL:
          return {...state, isCreateUserModalOpen: true, selectedUserId: action.payload}
      case CLOSE_CREATE_USER_MODAL:
          return {...state, isCreateUserModalOpen: false, selectedUserId: null}
      case 'GET_RATE_XML_SUCCESS':
          return { ...state, xmlJson: action.payload, error: null };
      case 'ALL_USERS_FAILURE':
      case 'CREATE_USER_FAILURE':
      case 'GET_RATE_XML_FAILURE':
      case 'UPDATE_USER_FAILURE':
          return { ...state, error: action.payload };
      default:
        return state;
    }
  };

  export default userReducer;
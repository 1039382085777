import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PolicyUserDetails from './PolicyUserDetails';
import PolicyDriver from './PolicyDriver';
import PolicyVehicle from './PolicyVehicle';
import PremiumDetails from './PremiumDetails';
import TransactionHistory from './TransactionHistory';

const poppinsFont = {
  fontFamily: '"Poppins", sans-serif',
};

const PolicyInfo = ({policyId, selectedRowId}) => {

  return (
    <div>
      <Accordion defaultExpanded sx={{ margin: '0 0 10px 0', background:'#F4F5F9', boxShadow: 'none', border: '#D9D9D9 solid 1px', ...poppinsFont }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1header"
          sx={poppinsFont}
        >
          Details
        </AccordionSummary>
        <AccordionDetails sx={poppinsFont}>
          <PolicyUserDetails selectedRowId={selectedRowId} policy_Id={policyId}/>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ margin: '0 0 10px 0', background:'#F4F5F9', boxShadow: 'none', border: '#D9D9D9 solid 1px', ...poppinsFont }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2header"
          sx={poppinsFont}
        >
          Drivers
        </AccordionSummary>
        <AccordionDetails sx={poppinsFont}>
          <PolicyDriver policyId={policyId}/>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ margin: '0 0 10px 0', background:'#F4F5F9', boxShadow: 'none', border: '#D9D9D9 solid 1px', ...poppinsFont }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3header"
          sx={poppinsFont}
        >
          Vehicle
        </AccordionSummary>
        <AccordionDetails sx={poppinsFont}>
          <PolicyVehicle policyId={policyId}/>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ margin: '0 0 10px 0', background:'#F4F5F9', boxShadow: 'none', border: '#D9D9D9 solid 1px', ...poppinsFont }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4-content"
          id="panel4header"
          sx={poppinsFont}
        >
          Premium
        </AccordionSummary>
        <AccordionDetails sx={poppinsFont}>
          <PremiumDetails policyId={policyId}/>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ margin: '0 0 10px 0', background:'#F4F5F9', boxShadow: 'none', border: '#D9D9D9 solid 1px', ...poppinsFont }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5-content"
          id="panel5header"
          sx={poppinsFont}
        >
          Transaction History
        </AccordionSummary>
        <AccordionDetails sx={poppinsFont}>
          <TransactionHistory />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default PolicyInfo;

export const blankOptions = [{ value: "", label: "" }];
export const quoteStateList = [
  {
    value: "AR",
    label: "Arkansas",
  },
  {
    value: "AZ",
    label: "Arizona",
  },
  {
    value: "FL",
    label: "Florida",
  },
  {
    value: "GA",
    label: "Georgia",
  },
  {
    value: "MS",
    label: "Mississippi",
  },
  {
    value: "NV",
    label: "Nevada",
  },
  {
    value: "OK",
    label: "Oklahoma",
  },
  {
    value: "SC",
    label: "South Carolina",
  },
  {
    value: "TX",
    label: "Texas",
  },
  {
    value: "UT",
    label: "Utah",
  },
];
export const paymentMethods = [
  {
    value: "CASH",
    label: "Cash is PIF",
  },
  { value: "EFT", label: "EFT" },
  { value: "OT", label: "OT is Installments or DB" },
];
export const stateList = [
  { value: "AK", label: "Alaska" },
  { value: "AL", label: "Alabama" },
  { value: "AR", label: "Arkansas" },
  { value: "AZ", label: "Arizona" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "IA", label: "Iowa" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "MA", label: "Massachusetts" },
  { value: "MD", label: "Maryland" },
  { value: "ME", label: "Maine" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MO", label: "Missouri" },
  { value: "MS", label: "Mississippi" },
  { value: "MT", label: "Montana" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "NE", label: "Nebraska" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NV", label: "Nevada" },
  { value: "NY", label: "New York" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VA", label: "Virginia" },
  { value: "VT", label: "Vermont" },
  { value: "WA", label: "Washington" },
  { value: "WI", label: "Wisconsin" },
  { value: "WV", label: "West Virginia" },
  { value: "WY", label: "Wyoming" },
];
export const driverLicenseState = [
  { value: "AK", label: "Alaska" },
  { value: "AL", label: "Alabama" },
  { value: "AR", label: "Arkansas" },
  { value: "AZ", label: "Arizona" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "IA", label: "Iowa" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "MA", label: "Massachusetts" },
  { value: "MD", label: "Maryland" },
  { value: "ME", label: "Maine" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MO", label: "Missouri" },
  { value: "MS", label: "Mississippi" },
  { value: "MT", label: "Montana" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "NE", label: "Nebraska" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NV", label: "Nevada" },
  { value: "NY", label: "New York" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VA", label: "Virginia" },
  { value: "VT", label: "Vermont" },
  { value: "WA", label: "Washington" },
  { value: "WI", label: "Wisconsin" },
  { value: "WV", label: "West Virginia" },
  { value: "WY", label: "Wyoming" },
  { value: "XX", label: "Foreign/International" },
];
export const priorinsurancecarrierflorida=[
  { value: "23833", label: "21st Century Insurance" },
  { value: "11711", label: "Access Insurance Company" },
  { value: "38806", label: "Affirmative Select" },
  { value: "35173", label: "Agency Insurance Company" },
  { value: "42579", label: "Allied Insurance" },
  { value: "17957", label: "American Independent" },
  { value: "12615", label: "Apollo Casualty Company" },
  { value: "11558", label: "AssuranceAmerica" },
  { value: "19658", label: "Bristol West" },
  { value: "21164", label: "Dairyland" },
  { value: "18139", label: "DairylandAuto" },
  { value: "37907", label: "Deerbrook" },
  { value: "28746", label: "Equity Insurance Company" },
  { value: "40029", label: "Explorer Universal" },
  { value: "27980", label: "Federated National" },
  { value: "99008", label: "FJUA" },
  { value: "40150", label: "Gainsco Auto Insurance" },
  { value: "22772", label: "GMAC Choice" },
  { value: "34037", label: "Hallmark 612" },
  { value: "10061", label: "Infinity Low Cost" },
  { value: "39497", label: "Infinity Premier" },
  { value: "11738", label: "Infinity Value Added" },
  { value: "42781", label: "Links Premier" },
  { value: "33650", label: "Mendota" },
  { value: "11202", label: "Mercury Insurance" },
  { value: "13023", label: "Northern Capital Select" },
  { value: "23248", label: "Occidental Fire & Casualty" },
  { value: "12360", label: "Ocean Harbor"},
  { value: "34940", label: "Omni Indemnity" },
  { value: "25755", label: "Peachtree Casualty" },
  { value: "37648", label: "Permanent General" },
  { value: "24252", label: "Progressive" },
  { value: "37303", label: "Redland Insurance Company" },
  { value: "24740", label: "Safeco" },
  { value: "33545", label: "Seminole Casualty" },
  { value: "32387", label: "Star Casualty" },
  { value: "13131", label: "The Responsive Auto" },
  { value: "13242", label: "Titan General Credit" },
  { value: "10105", label: "Titan General Non-Credit" },
  { value: "27998", label: "Travelers" },
  { value: "35319", label: "United Auto" },
  { value: "38156", label: "Unitrin Prism" },
  { value: "21300", label: "US Security" },
  { value: "13250", label: "Workmen's Auto" },
  { value: "99998", label: "Other" },
]
export const residenceType = [
  { value: "MH", label: "Mobile Home" },
  { value: "RH", label: "Row House" },
  { value: "APT", label: "Apartment" },
  { value: "OTH", label: "Other" },
];
export const residenceship = [
  { value: "OWNED", label: "Owned" },
  { value: "RENTD", label: "Rented" },
];
export const PriorPolicyBILimitPerPerson = [
  { value: "10000", label: "10000" },
  { value: "15000", label: "15000" },
  { value: "25000", label: "25000" },
  { value: "30000", label: "30000" },
  { value: "50000", label: "50000" },
  { value: "100000", label: "100000" },
  { value: "250000", label: "250000" },
];
export const PriorPolicyBILimitPerAccident = [
  { value: "20000", label: "20000" },
  { value: "30000", label: "30000" },
  { value: "50000", label: "50000" },
  { value: "60000", label: "60000" },
  { value: "100000", label: "100000" },
  { value: "300000", label: "300000" },
  { value: "500000", label: "500000" },
];
export const PriorPolicyCSLLimit = [
  { value: "100", label: "100 CSL" },
  { value: "300", label: "300 CSL" },
  { value: "500", label: "500 CSL" },
];
export const DefaultCreditScoreValue = [
  { value: "REQ", label: "Signals UAIG to pull credit" },
  { value: "DCS1", label: "Default Credit Score 1" },
  { value: "DCS2", label: "Default Credit Score 2" },
  { value: "DCS3", label: "Default Credit Score 3" },
  { value: "DCS4", label: "Default Credit Score 4" },
  { value: "DCS5", label: "Default Credit Score 5" },
];
export const genderList = [
  { value: "M", label: "Male" },
  { value: "F", label: "Female" },
  // { value: "O", label: "Other" },
];
export const martialStatus = [
  { value: "M", label: "Married" },
  { value: "S", label: "Single" },
  // { value: "D", label: "Divorced" },
];
export const RelationshipToInsured = [
  { value: "IN", label: "Insured" },
  { value: "BS", label: "Brother/Sister" },
  { value: "CH", label: "Child" },
  { value: "PA", label: "Parent" },
  { value: "SP", label: "Spouse" },
  { value: "RE", label: "Other Relative" },
  { value: "OT", label: "Non -Relative" },
];
export const SeniorCitizen = [
  { value: "0", label: "No" },
  { value: "1", label: "Yes" },
];
export const GoodStudent = [
  { value: "N", label: "No" },
  { value: "Y", label: "Yes" },
  { value: "G", label: "College Graduate" },
];
export const DriverTraining = [
  { value: "0", label: "No" },
  { value: "1", label: "Yes" },
];
export const DefensiveDriver = [
  { value: "N", label: "No" },
  { value: "Y", label: "Yes" },
  { value: "A", label: "Advanced Defensive Driver" },
];
export const CustodyofAMinorChild = [
  { value: "0", label: "No" },
  { value: "1", label: "Yes" },
];
export const DriverStatus = [
  { value: "E", label: "Excluded" },
  { value: "U", label: "Unlicensed" },
];
export const DriverLicenseStatus = [
  { value: "Active", label: "Valid" },
  {
    value: "Pending",
    label:
      "If driver age is more than 16, then it is `Temp Permit, otherwise it is `Learners Permit",
  },
  { value: "Temporary", label: "Temp Permit" },
  { value: "SI", label: "State Issued Id" },
  { value: "DP", label: "Driver Privilege Card" },
];
export const InternationalLicense = [
  { value: "0", label: "No" },
  { value: "1", label: "Yes" },
];
export const StateFiling = [
  { value: "N", label: "No" },
  { value: "Y", label: "Yes" },
];
export const AccidentClaimCoverage = [
  { value: "BI", label: "Bodily Injury" },
  { value: "PD", label: "Property Damage" },
  { value: "MEDPM", label: "Medical Payments" },
  { value: "UM", label: "Uninsured Motorist" },
  { value: "COLL", label: "Collision" },
];
export const priorPipClaimsList = [
  { value: "0-12", label: "Prior PIP Claims 0-12 Mo" },
  { value: "13-36", label: "Prior PIP Claims 13-36 Mo" },
  { value: "37-60", label: "Prior PIP Claims 36-60 Mo" },
];
export const CommonNumbers = [
 
  { value: "6", label: "6" },
  { value: "12", label: "12" },
];
export const CommonYesNo = [
  { value: "N", label: "No" },
  { value: "Y", label: "Yes" },
  // { label: "Yes", value: true },
  // { label: "No", value: false },
];
export const Relationship = [
  { value: "Insured", label: "Insured" },
  { value: "Other Related", label: "Other Related" },
];
export const VehicleType = [{ value: "Rated", label: "Rated" }];
export const ModelYear = [
  { value: "2024", label: "2024" },
  { value: "2023", label: "2023" },
  { value: "2022", label: "2022" },
  { value: "2021", label: "2021" },
  { value: "2020", label: "2020" },
  { value: "2019", label: "2019" },
  { value: "2018", label: "2018" },
  { value: "2017", label: "2017" },
  { value: "2016", label: "2016" },
  { value: "2015", label: "2015" },
  { value: "2014", label: "2014" },
  { value: "2013", label: "2013" },
  { value: "2012", label: "2012" },
  { value: "2011", label: "2011" },
  { value: "2010", label: "2010" },
];
export const NewUsed = [
  { value: "New", label: "New" },
  { value: "Used", label: "Used" },
];
export const CityList = [
  { value: "Miami", label: "Miami" },
  { value: "Miami Gardens", label: "Miami Gardens" },
];
export const ContactMethod = [
  { value: "Email", label: "Email" },
  { value: "Phone", label: "Phone" },
];

export const leadSource = [{ value: "Agent", label: "Agent" }];
export const LanguageList = [{ value: "English", label: "English" }];
export const pipDedOption = [
  { value: "NI", label: "NI" },
  { value: "NIRR", label: "NIRR" },
  { value: "NIWLE", label: "NI WLE" },
  { value: "NIRRWLE", label: "NIRR WLE" },
];
export const foreignLicensed = [
  { value: "No", label: "No" },
  { value: "Yes", label: "Yes" },
];
export const Make = [
  { value: "ACURA", label: "ACURA" },
  { value: "ALFA", label: "ALFA" },
  { value: "AUDI", label: "AUDI" },
  { value: "AMC", label: "AMC" },
  { value: "BMW", label: "BMW" },
  { value: "BUICK", label: "BUICK" },
  { value: "CADILLAC", label: "CADILLAC" },
  { value: "CHEVROLET", label: "CHEVROLET" },
  { value: "CHRYSLER", label: "CHRYSLER" },
  { value: "DAEWOO", label: "DAEWOO" },
  { value: "DAIHATSU", label: "DAIHATSU" },
  { value: "DODGE", label: "DODGE" },
  { value: "EAGLE", label: "EAGLE" },
  { value: "FIAT", label: "FIAT" },
  { value: " FORD", label: "FORD" },
  { value: "GMC", label: "GMC" },
  { value: "HONDA", label: "HONDA" },
  { value: "HUMMER", label: "HUMMER" },
  { value: "HYUNDAI", label: "HYUNDAI" },
  { value: "INFINITI", label: "INFINITI" },
  { value: "ISUZU", label: "ISUZU" },
  { value: "JAGUAR", label: "JAGUAR" },
  { value: "JEEP", label: "JEEP" },
  { value: "KIA", label: "KIA" },
  { value: "LAND ROVER", label: "LAND ROVER" },
  { value: "LEXUS", label: "LEXUS" },
  { value: "LINCOLN", label: "LINCOLN" },
  { value: "LOTUS", label: "LOTUS" },
  { value: "MAZDA", label: "MAZDA" },
  { value: "MERCEDES BENZ", label: "MERCEDES BENZ" },
  { value: "MERCURY", label: "MERCURY" },
  { value: "MG ", label: "MG" },
  { value: "MITSUBISHI", label: "MITSUBISHI" },
  { value: "NISSAN", label: "NISSAN" },
  { value: "OLDSMOBILE", label: "OLDSMOBILE" },
  { value: "PEUGEOT", label: "PEUGEOT" },
  { value: "PLYMOUT", label: "PLYMOUT" },
  { value: "PONTIAC", label: "PONTIAC" },
  { value: "PORSCHE", label: "PORSCHE" },
  { value: "RENAULT", label: "RENAULT" },
  { value: "SAAB", label: "SAAB" },
  { value: "SATURN", label: "SATURN" },
  { value: "SCION", label: "SCION" },
  { value: "SMART", label: "SMART" },
  { value: "STERLING", label: "STERLING" },
  { value: "SUBARU ", label: "SUBARU" },
  { value: "SUZUKI ", label: "SUZUKI" },
  { value: "TOYOTA", label: "TOYOTA" },
  { value: "TRIUMPH", label: "TRIUMPH" },
  { value: "VOLVO", label: "VOLVO" },
  { value: "VOLKSWAGEN", label: "VOLKSWAGEN" },
  { value: "YUGO", label: "YUGO" },
];
export const Usage = [
  { value: "BU", label: "Business" },
  { value: "CU", label: "Commute" },
  { value: "SR", label: "Artisan" },
  { value: "PL", label: "Pleasure" },
];
export const LeasedVehicle = [
  { value: "Veh Leased", label: "Veh Leased" },
  { value: "Veh Owned or Financed", label: "Veh Owned or Financed" },
];
export const AntiTheft = [
  { value: "A", label: "Alarm System" },
  { value: "D", label: "Active Disabling" },
  {
    value: "E",
    label: "Electronic Retrieval (LoJack)",
  },
  { value: "I", label: "Ignition Cut-Off" },
  { value: "P", label: "Passive Disabling " },
  { value: "N", label: "No Anti-Theft Device" },
];
export const sr22ReasonFiling = [
  { value: "DUI/DWI Conviction", label: "DUI/DWI Conviction" },
  {
    value: "Reckless Driving Conviction",
    label: "Reckless Driving Conviction",
  },
  { value: "Driving Without Insurance", label: "Driving Without Insurance" },
  {
    value: "Multiple Traffic Offence or Violations",
    label: "Multiple Traffic Offence or Violations",
  },
  {
    value: "At-Fault Accident Without Insurance",
    label: "At-Fault Accident Without Insurance ",
  },
  {
    value: "Other Offenses or Violations Leading to License Suspention",
    label: "Other Offenses or Violations Leading to License Suspention",
  },
];
export const MonthList = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
];
export const biLimitPerPerson = [
  { value: "10", label: "10000" },
  { value: "15", label: "15000" },
  { value: "25", label: "25000" },
  { value: "50", label: "50000" },
  { value: "100", label: "100000" },
  { value: "250", label: "250000" },

];
export const biLimitPerAccident = [

  { value: "20", label: "20000" },
  { value: "30", label: "30000" },
  { value: "50", label: "50000" },
  { value: "100", label: "100000" },
  { value: "300", label: "300000" },
  { value: "500", label: "500000" },
];

export const pdLimit = [
  { value: "10", label: "10000" },
  { value: "15", label: "15000" },
  { value: "20", label: "20000" },
  { value: "25", label: "25000" },
  { value: "50", label: "50000" },
  { value: "100", label: "100000" },
];
export const medLimit = [
  { value: "500", label: "500" },
  { value: "1000", label: "1000" },
  { value: "2000", label: "2000" },
  { value: "3000", label: "3000" },
  { value: "5000", label: "5000" },
];
export const umLimitPerPerson = [
  { value: "10", label: "10000" },
  { value: "15", label: "15000" },
  { value: "25", label: "25000" },
  { value: "50", label: "50000" },
  { value: "100", label: "100000" },
  { value: "250", label: "250000" },
];
export const umLimitPerAccident = [
  { value: "20", label: "20000" },
  { value: "30", label: "30000" },
  { value: "50", label: "50000" },
  { value: "100", label: "100000" },
  { value: "300", label: "300000" },
  { value: "500", label: "500000" },
];
export const pipDed = [
  { value: "250", label: "250" },
  { value: "350", label: "350" },
  { value: "500", label: "500" },
  { value: "1000", label: "1000" },
];
export const umOptions = [
  { value: "ST", label: "Stacking applies" },
  { value: "NS", label: "Non Stacked-Only" },
]
export const pipOption = [
  { value: "W", label: "Excludes work loss" },
  { value: "I", label: "Deductible applies to named Insured Only" },
  {
    value: "R",
    label:
      "Deductible applies to named Insured and Dependent Resident Relative",
  },
  { value: "WI", label: "Work loss exlusion applies to named insured Only" },
  {
    value: "WR",
    label:
      "Work loss exlusion applies to named insured and Dependent Resident Relatives",
  },
];
export const wageLossExclusion = [
  { value: "In", label: "Included(Coverage for wage loss included)" },
  { value: "Ex", label: "Excluded(Wage loss coverage excluded)" },
];
export const uninsuredBi = [
  { value: "In", label: "Included" },
  { value: "Ex", label: "Excluded" },
];
export const umStacked = [
  { value: "staked", label: "Staked" },
  { value: "nonStaked", label: "Non-Staked" },
];
export const medicalPayments = [
  { value: "In", label: "Included" },
  { value: "Ex", label: "Excluded" },
];
export const umCslLimit = [
  { value: "100000", label: "100000" },
  { value: "300000", label: "300000" },
  { value: "500000", label: "500000" },
];
export const accidentalDeath = [
  { value: "In", label: "Included" },
  { value: "Ex", label: "Excluded" },
];
export const priorInsurance = [
  { value: "CI", label: "Currently Insured" },
  { value: "PIE", label: "Previously Insured,but Policy Expired" },
  { value: "PIC", label: "Previously Insured,No Gaps in Coverage" },
  { value: "PIGC", label: "Previously Insured,with Gaps in Coverage " },
  { value: "NI", label: "Never Insured" },
];
export const timewithPriorInsurance = [
  { value: "lessThan6Months ", label: "Less than 6 months" },
  { value: "6MonthsTo1Year", label: "6 months to 1 year" },
  { value: "1YearTo3Months", label: "1 year to 3 months" },
  { value: "over3Years", label: "Over 3 years " },
  { value: "neverInsured", label: "Never Insured" },
];
export const priorTransferLevel = [
  {
    value: "maintainSameCoverageLevel ",
    label: "Maintain Same Coverage Level",
  },
  { value: "increaseCoverageLevel", label: "Increase Coverage Level" },
  { value: "decreaseCoverageLevel", label: "Decrease Coverage Level" },
];
export const sr22 = [
  { value: " required ", label: "Required" },
  { value: " notRequired", label: "Not Required" },
];
export const Industry = [
  { value: "healthcare", label: "Healthcare" },
  { value: "education", label: "Education" },
  { value: "technology", label: "Technology" },
  { value: "finance", label: "Finance" },
  { value: "construction", label: "Construction" },
  { value: "retail", label: "Retail" },
  { value: "transportaion", label: "Transportation" },
  { value: "text", label: "Other" },
];
export const car_type = [
  { value: "SE", label: "Sedan" },
  { value: "SUV", label: "SUV(Sport Utility Vehical)" },
  { value: "Coupe", label: "Coupe" },
  { value: "CON", label: "Convertible" },
  { value: "HB", label: "Hatchback" },
  { value: "WG", label: "Wagon" },
  { value: "MINI", label: "Miniwan" },
  { value: "PT", label: "Pickup Truck" },
  { value: "VAN", label: "Van" },
  { value: "CS", label: "Crossover" },
  { value: "EV", label: "Electric Vehical(EV)" },
  { value: "HY", label: "Hybrid" },
  { value: "LV", label: "Luxury Vehical" },
  { value: "SC", label: "Sports Car" },
  { value: "CC", label: "Compact Car" },
  { value: "FsC", label: "Full-size Car" },
  { value: "MsC", label: "Mid-size Car" },
  { value: "mC", label: "Microcar" },
  { value: "MuC", label: "Muscle Car" },
  { value: "OFF", label: "Off-road Vehical/4x4" },
  { value: "SW", label: "Station Wagon" },
  { value: "RS", label: "Roadstar" },
  { value: "CSUV", label: "Compact SUV" },
  { value: "LSUV", label: "Luxury SUV" },
  { value: "Truck", label: "Truck" },
];
export const comp = [
  { value: "NA", label: "No Covg" },
  { value: "0", label: "0" },
  { value: "100", label: "100" },
  { value: "200", label: "200" },
  { value: "250", label: "250" },
  { value: "500", label: "500" },
  { value: "750", label: "750" },
  { value: "1000", label: "1000" },
  { value: "2000", label: "2000" },
];
export const towing = [
  { value: "25", label: "25" },
  { value: "50", label: "50" },
  { value: "100", label: "100" },
];
export const Rental = [
  { value: "15", label: "15 per day, 450 per incident" },
  { value: "25", label: "25 per day, 750 per incident" },
  { value: "50", label: "50 per day, 1500 per incident " },
];
export const primaryoperator = [
  { value: "Myself", label: "Myself(Policyholder)" },
  { value: "Spouse/Partner", label: "Spouse/Partner" },
  { value: "Parent", label: "Parent" },
  { value: "Child", label: "Child" },
  { value: "Other Relatives", label: "Other Relatives" },
  { value: "Friend", label: "Friend" },
  { value: "Employee", label: "Employee" },
  { value: "Other", label: "Other" },
];
export const requestType = [
  { value: "PI", label: "Policy Inquiry" },
  { value: "CC", label: "Coverage Change" },
  { value: "CS", label: "Claim Submission" },
  { value: "BI", label: "Billing Inquiry" },
  { value: "PR", label: "Policy Renewal" },
  { value: "A/R D", label: "Adding/Removing Drivers" },
  { value: "A/R V", label: "Adding/Removing Vehicals" },
  { value: "ADCH", label: "Address Change" },
  { value: "NMCH", label: "Name Change" },
  { value: "CVR", label: "Coverage Review" },
  { value: "DIS", label: "Discount " },
  { value: "POI", label: "Proof of Insurance" },
  { value: "CN", label: "Cancellation" },
  { value: "OTHER", label: "Other" },
];
export const payPlanOptions = [
  { value: "AP", label: "Annual Payment" },
  { value: "SAP", label: "Semi-Annaul Payment (Every 6 Months)" },
  { value: "QP", label: "Quarterly Payment (Every 3 Months)" },
  { value: "MP", label: "Monthly Payment" },
  { value: "BWP", label: "Bi-Weekly Payment" },
  { value: "PD", label: "Payroll Deduction (If available through employer)" },
  { value: "EFT", label: "Electronics Funds Transfer(EFT)" },
  { value: "CCA", label: "Credit/Debit Card Autopay" },
  { value: "PC", label: "Paper Check/Money Order by Mail " },
  { value: "OBP", label: "Online Bill Payment " },
  { value: "O", label: "Other" },
];
export const DriverType=[
  {value:"E", label:"Excluded"},
  {value:"U", label:"Unlicensed"},
  {value:"Insured", label:"Insured"}
];
export const Fileintype=[
  {value:"S", label:"SR22"},
  {value:"F", label:"FR44"},
  {value:"N", label:"SR22N"},
  {value:"No", label:"No"}
];
export const BooleanYesNo = [
  { value: "0", label: "No" },
  { value: "1", label: "Yes" },
];
export const VoilationType = [
  { value: "ACCI", label: "Accident" },
  { value: "VIOL", label: "Voilation" },
  { value: "SUSP", label: "Suspension" },
];
export const vehBodyType = [
  { value: "CONVT", label: "Convertible" },
  { value: "PP", label: "Private Passenger Automobile" },
  { value: "PU", label: "Pickup Truck,and Utility" },
  { value: "STWAG", label: "Station Wagon" },
  { value: "VAN", label: "Van" },
  { value: "VANMV", label: "Mini Van" },
];
export const TermTenture = [
  { value: "12", label: "Annual" },
  { value: "6", label: "Semi-Annual" },
  // { value: "3", label: "Quarterly" },
  // { value: "2", label: "Two-Month" },
  // { value: "1", label: "Monthly" },
];
export const payPlanOptionIns = [
  { value: "INS", label: "Installments" },
  { value: "EFT", label: "Installments EFT" },
  { value: "PIF", label: "Paid in full" },
]
export const liabbi = [
  { value: "0", label: "State Minimum" },
  { value: "0", label: "No Covg" },
  { value: "10000/20000", label: "10/20" },
  { value: "15000/30000", label: "15/30" },
  { value: "25000/50000", label: "25/50" },
  { value: "50000/100000", label: "50/100" },
  { value: "100000/300000", label: "100/300" },
  { value: "250000/500000", label: "250/500" },
  { value: "300000/500000", label: "300/500" },
  { value: "400000/500000", label: "400/500" },
  { value: "50000", label: "500/1 Mil" },
  // { value: "1Mil/1Mil", label: "1 Mil/1 Mil" },
  // { value: "5Mil/5Mil", label: "5 Mil/5 Mil" },
  // { value: "100CSL", label: "100 CSL" },
  // { value: "300CSL", label: "300 CSL" },
  // { value: "500CSL", label: "500 CSL" },
]
export const liabpd = [
  { value: "10000", label: "10" },
  { value: "25000", label: "25" },
  { value: "50000", label: "50" },
  { value: "100000", label: "100" },
  { value: "250000", label: "250" },
  { value: "300000", label: "300" },
  { value: "500", label: "500" },
  { value: "1Mil", label: "1 Mil" },
]
export const umbi = [
  { value: "0", label: "No Covg" },
  { value: "10000/20000", label: "10/20" },
  { value: "15000/30000", label: "15/30" },
  { value: "25000/50000", label: "25/50" },
  { value: "50000/100000", label: "50/100" },
  { value: "100000/300000", label: "100/300" },
  { value: "250000/500000", label: "250/500" },
  { value: "300000/500000", label: "300/500" },
  { value: "500000/500000", label: "500/500" },
  { value: "500/1Mil", label: "500/1 Mil" },
  { value: "1Mil/1Mil", label: "1 Mil/1 Mil" },
  { value: "5Mil/5Mil", label: "5 Mil/5 Mil" },
  { value: "100CSL", label: "100 CSL" },
  { value: "300CSL", label: "300 CSL" },
  { value: "500CSL", label: "500 CSL" },
]
export const medPay = [
  { value: "0", label: "No Covg" },
  { value: "10000/20000", label: "10/20" },
  { value: "15000/30000", label: "15/30" },
  { value: "25000/50000", label: "25/50" },
  { value: "50000/100000", label: "50/100" },
  { value: "100000/300000", label: "100/300" },
  { value: "250000/500000", label: "250/500" },
  { value: "300000/500000", label: "300/500" },
  { value: "500000/500000", label: "500/500" },
  { value: "500/1Mil", label: "500/1 Mil" },
  { value: "1Mil/1Mil", label: "1 Mil/1 Mil" },
  { value: "5Mil/5Mil", label: "5 Mil/5 Mil" },
  { value: "100CSL", label: "100 CSL" },
  { value: "300CSL", label: "300 CSL" },
  { value: "500CSL", label: "500 CSL" },
]

export const coll = [
  { value: "NA", label: "No Covg" },
  { value: "0", label: "0" },
  { value: "50", label: "50" },
  { value: "100", label: "100" },
  { value: "150", label: "150" },
  { value: "200", label: "200" },
  { value: "250", label: "250" },
  { value: "300", label: "300" },
  { value: "400", label: "400" },
  { value: "450", label: "450" },
  { value: "475", label: "475" },
  { value: "500", label: "500" },
  { value: "550", label: "550" },
  { value: "600", label: "600" },
  { value: "700", label: "700" },
  { value: "750", label: "750" },
  { value: "800", label: "800" },
  { value: "975", label: "975" },
  { value: "1000", label: "1000" },
  { value: "1500", label: "1500" },
  { value: "2000", label: "2000" },
  { value: "2500", label: "2500" },
  { value: "3000", label: "3000" },
  { value: "5000", label: "5000" },
];
export const Roadside = [
  { value: "0", label: "No Covg" },
  { value: "20", label: "20" },
  { value: "30", label: "30" },
  { value: "35", label: "35" },
  { value: "40", label: "40" },
  { value: "50", label: "50" },
  { value: "70", label: "70" },
  { value: "75", label: "75" },
  { value: "100", label: "100" },
];
export const Rentals = [
  { value: "0", label: "No Covg" },
  { value: "20", label: "20" },
  { value: "25", label: "25" },
  { value: "30", label: "30" },
  { value: "35", label: "35" },
  { value: "40", label: "40" },
  { value: "50", label: "50" },
];

export const YearList = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
];
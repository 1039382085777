const initialState = {
    companies: [],
    error: null,
  };

  const companyselectionReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_COMPANY_SELECTION_SUCCESS':
          // console.log("Reducer success payload:", action.payload); // Debug log
          return {
          ...state,
          companies: action.payload,
          error: null,
        };
      case 'FETCH_COMPANY_SELECTION_FAILURE':
          // console.log("Reducer error payload:", action.payload); // Debug log
          return {
          ...state,
          error: action.payload,
        };
        case 'DELETE_CARRIER_GROUP':
          return { ...state, companies: action.payload, error: null };  
      default:
        return state;
    }
  };

  export default companyselectionReducer;
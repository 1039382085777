// import { Controller,useForm,FormProvider , useFormContext } from 'react-hook-form';
// import { Box, Grid, FormControl, Typography, MenuItem, TextField, Select, RadioGroup, FormControlLabel, Radio, FormHelperText, Button } from '@mui/material';
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import dayjs from "dayjs";
// import Autocomplete from '@mui/material/Autocomplete';
// import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// // import noteListing from './noteListing';
// import PoliciesList from './PoliciesList';

// const notesearchData = [
//     {
//       fieldName: 'from',
//       label: 'From:',
//       type: 'date',
//     },
//     {
//       fieldName: 'to',
//       label: 'To:',
//       type: 'date',
//     },
//     {
//       fieldName: 'userId',
//       label: 'User Id *',
//       type: 'text',

//     },
//     {
//         fieldName: 'notes',
//         label: 'Notes',
//         type: 'text',

//       },
//       {
//         fieldName: 'searchPolicy',
//         label: 'Search Policy',
//         type: 'dropdown',
//         options: ['All',"first"],

//       },
//     ]



// const NoteSearch = () => {
//     const methods = useForm();
//     const { control, formState: { errors } } = useFormContext();

//   return (
//     <>
//     <Box sx={{ padding: '0 2rem 0 5rem', margin: '4rem 0 0 0'}}>
//             <Grid container rowSpacing={1}  sx={{ margin: '0' }}>
//             {notesearchData.map(({ fieldName, label, type, options, rules, defaultValue }) => (
//                 <Grid key={fieldName} item xs={12} sm={6} md={4} lg={4} sx={{ margin: '0', padding: '0 25px !important' }}>
//                 <FormControl fullWidth margin="normal" error={!!errors[fieldName]}>
//                     <Typography>{label}</Typography>
//                     <Controller
//                     name={fieldName}
//                     control={control}
//                     rules={rules}
//                     defaultValue={defaultValue}
//                     render={({ field }) => {
//                         if (type === 'text' || type === 'number' ) {
//                             return (
//                                 <TextField
//                                     {...field}
//                                     inputProps={type === 'number' ? { inputMode: 'numeric', pattern: '[0-9]*', maxLength: 10 } : {}}
//                                     id={fieldName}
//                                     type={type}
//                                     sx={{ width: '100%' }}
//                                     error={!!errors[fieldName]}
//                                 />
//                             );
//                         } else if (type === 'dropdown' || type==="select") {
//                         return (
//                             <Select
//                             {...field}
//                             error={!!errors[fieldName]}
//                             >
//                             {options.map((option, index) => (
//                                 <MenuItem key={index} value={option}>
//                                 {option}
//                                 </MenuItem>
//                             ))}
//                             </Select>
//                         );
//                         } else if (type === 'autocomplete') {
//                         return (
//                             <Autocomplete
//                             {...field}
//                             options={options}
//                             getOptionLabel={(option) => option.label}
//                             onChange={(_, data) => field.onChange(data)}
//                             defaultValue={defaultValue}
//                             renderInput={(params) => (
//                                 <TextField
//                                 {...params}
//                                 error={!!errors[fieldName]}
//                                 />
//                             )}
//                             />
//                         );
//                         }
//                         else if(type === 'date'){
//                           return (
//                             <LocalizationProvider dateAdapter={AdapterDayjs}>
//                               <DatePicker
//                                 format="MM-DD-YYYY"
//                                 {...field}
//                                 value={field.value ? dayjs(field.value, 'YYYY-MM-DD') : null}
//                                 // onChange={(date) => field.onChange(date ? date.format('MM-DD-YYYY') : null)}
//                                 // value={formData[fieldName]}
//                                 // onChange={(date) => handleDateChange(date, fieldName)}
//                                 slotProps={{
//                                   textField: {
//                                     size: "medium",
//                                     sx: {
//                                       width: 357,
//                                       "& fieldset": { borderColor: "#077BFD" },
//                                     },
//                                   },
//                                 }}
//                                 renderInput={(params) => <TextField {...params} />}
//                               />
//                             </LocalizationProvider>
//                           )
//                         }
//                         return null;
//                     }}
//                     />
//                     <FormHelperText>{errors[fieldName]?.message}</FormHelperText>
//                 </FormControl>
//                 </Grid>
//             ))}
//             </Grid>


//         </Box>
//        <PoliciesList />

//     </>

//   );
// };

// export default NoteSearch;

import { Controller, useForm, FormProvider, useFormContext } from 'react-hook-form';
import { Box, Grid, FormControl, Typography, MenuItem, TextField, Select, FormHelperText, Button } from '@mui/material';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import React, { useState } from 'react';
import PoliciesList from './PoliciesList';

const notesearchData = [
  {
    fieldName: 'from',
    label: 'From:',
    type: 'date',
    id: 'fromdate', 
  },
  {
    fieldName: 'to',
    label: 'To:',
    type: 'date',
    id: 'todate', 
  },
  {
    fieldName: 'userId',
    label: 'User Id *',
    type: 'text',
    id: 'userid', 
  },
  {
    fieldName: 'notes',
    label: 'Notes',
    type: 'text',
    id: 'notesinput', 
  },
  {
    fieldName: 'searchPolicy',
    label: 'Search Policy',
    type: 'dropdown',
    options: ['All', 'first'],
    id: 'searchpolicydropdown', 
  },
];


const NoteSearch = () => {
  const methods = useForm();
  const { control, formState: { errors } } = methods;
  const [searchParams, setSearchParams] = useState({});

  const onSubmit = data => {
    setSearchParams(data);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box sx={{ padding: '0 2rem 0 5rem', margin: '4rem 0 0 0' }}>
          <Grid container rowSpacing={1} sx={{ margin: '0' }}>
            {notesearchData.map(({ fieldName, label, type, options, rules, defaultValue }) => (
              fieldName === 'searchPolicy' ? (
                <Grid container key={fieldName} item xs={12} sm={12} md={8} lg={8} sx={{ margin: '0', padding: '0 25px !important', alignItems: 'center' }}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormControl fullWidth margin="normal" error={!!errors[fieldName]}>
                      <Typography>{label}</Typography>
                      <Controller
                        name={fieldName}
                        control={control}
                        rules={rules}
                        defaultValue={defaultValue}
                        render={({ field }) => (
                          <Select
                            {...field}
                            error={!!errors[fieldName]}
                          >
                            {options.map((option, index) => (
                              <MenuItem key={index} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors[fieldName] && (
                        <FormHelperText>{errors[fieldName].message}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} sx={{ marginTop: '24px',padding:"20px" }}>
                    <Button id="search" variant="contained" color="primary" type="submit" >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid key={fieldName} item xs={12} sm={6} md={4} lg={4} sx={{ margin: '0', padding: '0 25px !important' }}>
                  <FormControl fullWidth margin="normal" error={!!errors[fieldName]}>
                    <Typography>{label}</Typography>
                    <Controller
                      name={fieldName}
                      control={control}
                      rules={rules}
                      defaultValue={defaultValue}
                      render={({ field }) => {
                        if (type === 'text' || type === 'number') {
                          return (
                            <TextField
                              {...field}
                              inputProps={type === 'number' ? { inputMode: 'numeric', pattern: '[0-9]*', maxLength: 10 } : {}}
                              id={fieldName}
                              type={type}
                              sx={{ width: '100%' }}
                              error={!!errors[fieldName]}
                            />
                          );
                        } else if (type === 'dropdown' || type === "select") {
                          return (
                            <Select
                              {...field}
                              error={!!errors[fieldName]}
                            >
                              {options.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          );
                        } else if (type === 'date') {
                          return (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                format="MM-DD-YYYY"
                                {...field}
                                value={field.value ? dayjs(field.value, 'YYYY-MM-DD') : null}
                                onChange={(date) => field.onChange(dayjs(date).format('YYYY-MM-DD'))}
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    error: !!errors[fieldName],
                                  },
                                }}
                              />
                            </LocalizationProvider>
                          );
                        }
                      }}
                    />
                    {errors[fieldName] && (
                      <FormHelperText>{errors[fieldName].message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              )
            ))}
          </Grid>
        </Box>
      </form>
      <PoliciesList searchParams={searchParams} />
    </FormProvider>
  );
};

export default NoteSearch;

import apiService from "./apiServices";

export const saveEmail = async(formData, token) => {
    try {

        // console.log(formData, 'formData, for task');
        const response = await apiService('/communication-email/save', 'POST', formData, null, token);
        return response;
        } catch (error) {
          throw (error?.message || 'Saving email is failed. Please try again.');
        }
  }

  export const updateEmail = async (formData, token) => {
    try {

      const response = await apiService('/communication-email/update', 'PUT', formData, null, token);
      return response;
      } catch (error) {
        throw (error?.message || 'Updating email is failed. Please try again.');
      }
  }

  // export const getAllEmail = async (token) => {
  //   try {

  //     const response = await apiService('/communication-email/all', 'GET', null, null, token);
  //     console.log("all response",response)
  //     return response;
  //   } catch (error) {
  //     throw (error?.message || 'service is failed. Please try again.');
  //   }
  // }

  export const getAllEmail = async (token, page, size) => {
    try {

      const response = await apiService(`/communication-email/all?page=${page}&size=${size}`, 'GET', null, null, token);
      // console.log("all response",response)
      return response;
    } catch (error) {
      throw (error?.message || 'service is failed. Please try again.');
    }
  }

  export const deleteEmailById = async (id, token) => {
    try {
      // const headers = {
      //   'Access-Control-Request-Headers': '*'
      // };
    //   console.log("id",id);
      const response = await apiService(`/communication-email/delete/${id}`, 'DELETE', null, null, token);
    //   console.log("response",response)
      return response;
    } catch (error) {
      throw new Error(error.message || 'service is failed. Please try again.');
    }
  }

  // export const searchEmail = async(formData, token) => {
  //   try {

  //       // console.log(formData, 'formData, for task');
  //       const response = await apiService('/communication-email/search', 'POST', formData, null, token);
  //       console.log("search note",response);
  //       return response;
  //       } catch (error) {
  //         throw (error?.message || 'Searching email is failed. Please try again.');
  //       }
  // }

  export const searchCommunication = async(formData, token) => {
    try {

        // console.log(formData, 'formData, for task');
        const response = await apiService('/communications/search', 'POST', formData, null, token);
        // console.log("search note",response);
        return response;
        } catch (error) {
          throw (error?.message || 'Searching email is failed. Please try again.');
        }
  }


  //SMS

  export const saveSMS = async(formData, token) => {
    try {

        // console.log(formData, 'formData, for alert');
        const response = await apiService('/communication-sms/save', 'POST', formData, null, token);
        return response;
        } catch (error) {
          throw (error?.message || 'Saving sms is failed. Please try again.');
        }
  }

  export const updateSMS = async (formData, token) => {
    try {

      const response = await apiService('/communication-sms/update', 'PUT', formData, null, token);
      return response;
      } catch (error) {
        throw (error?.message || 'Updating sms is failed. Please try again.');
      }
  }

  // export const getAllSMS = async (token) => {
  //   try {

  //     const response = await apiService('/communication-sms/all', 'GET', null, null, token);
  //     console.log("all response",response)
  //     return response;
  //   } catch (error) {
  //     throw (error?.message || 'service is failed. Please try again.');
  //   }
  // }

  export const getAllSMS = async (token, page, size) => {
    try {

      const response = await apiService(`/communication-sms/sms/all?page=${page}&size=${size}`, 'GET', null, null, token);
      // console.log("all response",response)
      return response;
    } catch (error) {
      throw (error?.message || 'service is failed. Please try again.');
    }
  }

  export const deleteSmsById = async (id, token) => {
    try {
      // const headers = {
      //   'Access-Control-Request-Headers': '*'
      // };
    //   console.log("id",id);
      const response = await apiService(`/communication-sms/delete/${id}`, 'DELETE', null, null, token);
    //   console.log("response",response)
      return response;
    } catch (error) {
      throw new Error(error.message || 'service is failed. Please try again.');
    }
  }

  export const searchSMS = async(formData, token) => {
    try {

        // console.log(formData, 'formData, for task');
        const response = await apiService('/communication-sms/search', 'POST', formData, null, token);
        // console.log("search note",response);
        return response;
        } catch (error) {
          throw (error?.message || 'Searching sms is failed. Please try again.');
        }
  }





  //Phone
  export const savePhone = async(formData, token) => {
    try {

        // console.log(formData, 'formData, for alert');
        const response = await apiService('/communication-phone/save', 'POST', formData, null, token);
        return response;
        } catch (error) {
          throw (error?.message || 'Saving phone is failed. Please try again.');
        }
  }

  export const getAllPhones = async (token, page, size) => {
    try {

      const response = await apiService(`/communication-phone/phones/all?page=${page}&size=${size}`, 'GET', null, null, token);
      // console.log("allphones response",response)
      return response;
    } catch (error) {
      throw (error?.message || 'service is failed. Please try again.');
    }
  }

  export const searchPhone = async(formData, token) => {
    try {

        // console.log(formData, 'formData, for task');
        const response = await apiService('/communication-phone/search', 'POST', formData, null, token);
        // console.log("search phone",response);
        return response;
        } catch (error) {
          throw (error?.message || 'Searching sms is failed. Please try again.');
        }
  }

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  TablePagination,
} from '@mui/material';
import { saveLocationGroupsAction, updateLocationGroupsAction } from '../../redux/actions/locationGroupActions';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllLocationAction } from '../../redux/actions/locationActions';
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: '#1565c0', // Set header background color to #1565c0
  color: theme.palette.common.white,
  fontFamily: '"Poppins", sans-serif',
}));
 
 
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover, // Odd rows have a subtle background color
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected, // Row background changes on hover
  },
  '& td, & th': {
    padding: theme.spacing(1.5),
    fontFamily: '"Poppins", sans-serif', // Poppins font applied to all cells
  },
}));

const LocationList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [locationGroups, setLocationGroups] = useState([]);
  const [editLocation, setEditLocation] = useState(null);
  const [selectedLocationIds, setSelectedLocationIds] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isEdit, setIsEdit] = useState(false);
  const [groupId, setGroupId] = useState(null);
  const [error, setError] = useState('');

  const locationDetails = useSelector((state) => state.location.allLocation || []);


  useEffect(() => {
    if (location.state) {
      setGroupName(location.state.groupName || ''); // Pre-fill group name

      const locationIds = location.state.selectedLocations.map((loc) => loc.id || loc.locationId);
      setSelectedLocationIds(locationIds);

      setIsEdit(location.state.isEdit); 
      setGroupId(location.state.groupId || null); 
    }
    dispatch(getAllLocationAction());
  }, [location.state]);

  const handleCheckboxChange = (id) => {
    setSelectedLocationIds((prev) =>
      prev.includes(id) ? prev.filter((locationId) => locationId !== id) : [...prev, id]
    );
  };

  const handleCreateOrUpdateGroupClick = () => {
    if (!groupName) {
      setError('Please provide a group name.');
      return;
    }

    if (selectedLocationIds.length === 0) {
      setError('Please select at least one location.');
      return;
    }

    // Clear any previous error messages if validation passes
    setError('');

    const groupData = {
      locationGroupName: groupName,
      locationIds: selectedLocationIds,
      status: 'Active',
    };

    const updatedGroupLocation = {
      locationGroupId: groupId,
      locationGroupName: groupName,
      locationIds: selectedLocationIds,
      status: 'Active',        
    };

    if (isEdit && groupId) {
      dispatch(updateLocationGroupsAction(updatedGroupLocation)); 
    } else {
      dispatch(saveLocationGroupsAction(groupData)); 
    }

    navigate('/locationgrp');
    
    // Reset form fields
    setSelectedLocationIds([]);
    setGroupName('');
    setIsEdit(false);
    setGroupId(null);
    setError(''); 
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRows = locationDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
        All Location List
      </Typography>

      {/* Group Name Input */}
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <TextField
          label="Location Group Name"
          variant="outlined"
          size="small"
          value={groupName}
          onChange={(e) => {
            setGroupName(e.target.value);
            setError(''); // Clear error when editing the group name
          }}
          error={!!error} // Set error if there's any error
          helperText={error} // Display error message
          sx={{ marginRight: '10px' }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateOrUpdateGroupClick}
        >
          {isEdit ? 'Update Group' : 'Create Group'}
        </Button>
      </Box>

      {/* Location List */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell padding="checkbox"></StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell>Address</StyledTableCell>
              <StyledTableCell>City</StyledTableCell>
              <StyledTableCell>State</StyledTableCell>
              <StyledTableCell>Zip Code</StyledTableCell>
              <StyledTableCell>Phone</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.map((location, index) => (
              <StyledTableRow key={location.id}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedLocationIds.includes(location.id)}
                    onChange={() => handleCheckboxChange(location.id)}
                    id={`active-${index}`}
                  />
                </TableCell>
                <TableCell>{location.locationDescription}</TableCell>
                <TableCell>{`${location.address1} ${location.address2}`}</TableCell>
                <TableCell>{location.city}</TableCell>
                <TableCell>{location.state}</TableCell>
                <TableCell>{location.zipCode}</TableCell>
                <TableCell>{location.phone}</TableCell>
                <TableCell>{location.isActive ? 'Yes' : 'No'}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          count={locationDetails.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </TableContainer>
    </Box>
  );
};

export default LocationList;

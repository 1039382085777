import { Box } from "@mui/material"

const Heading = ({data}) => {
    return(
        <>
            <Box sx={{fontSize:'14px', color: '#242929', padding: '0 0 8px 0'}}>{data.small}</Box>
            <Box sx={{fontSize:'16px', fontWeight: '600', color: '#242929'}}>{data.main_title}</Box>
        </>
    )
}

export default Heading;
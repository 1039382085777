// actions/uploadAttachmentActions.js

import {
  SAVE_UPLOAD_ATTACHMENT_SUCCESS,
  SAVE_UPLOAD_ATTACHMENT_FAILURE,
  UPDATE_UPLOAD_ATTACHMENT_SUCCESS,
  UPDATE_UPLOAD_ATTACHMENT_FAILURE,
  GET_UPLOAD_ATTACHMENTS_SUCCESS,
  GET_UPLOAD_ATTACHMENTS_FAILURE,
  DELETE_UPLOAD_ATTACHMENT_SUCCESS,
  DELETE_UPLOAD_ATTACHMENT_FAILURE,
  PREVIEW_FILE_SUCCESS,
  PREVIEW_FILE_FAILURE,
  START_LOADING,
  STOP_LOADING
} from "./actionTypes";
import {
  saveUploadAttachment,
  updateUploadAttachment,
  getAllUploadAttachments,
  deleteUploadAttachmentById,
  previewFileByUserIdAndFileName
} from "../../services/uploadAttachmentServices";

export const saveUploadAttachmentAction = (formData, callbackfn) => async (dispatch, getState) => {
  try {
      dispatch({ type: START_LOADING, service: 'SAVE_UPLOAD_ATTACHMENT' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await saveUploadAttachment(formData, token);
      dispatch({ type: STOP_LOADING, service: 'SAVE_UPLOAD_ATTACHMENT' });
      if (response) {
          callbackfn(response);
      }
      dispatch({ type: SAVE_UPLOAD_ATTACHMENT_SUCCESS, payload: response });
  } catch (error) {
      dispatch({ type: STOP_LOADING, service: 'SAVE_UPLOAD_ATTACHMENT' });
      dispatch({ type: SAVE_UPLOAD_ATTACHMENT_FAILURE, payload: error || 'Save upload attachment service failed. Please try again.' });
  }
};

export const updateUploadAttachmentAction = (formData) => async (dispatch, getState) => {
  try {
      dispatch({ type: START_LOADING, service: 'UPDATE_UPLOAD_ATTACHMENT' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await updateUploadAttachment(formData, token);
      dispatch({ type: STOP_LOADING, service: 'UPDATE_UPLOAD_ATTACHMENT' });
      dispatch({ type: UPDATE_UPLOAD_ATTACHMENT_SUCCESS, payload: response });
  } catch (error) {
      dispatch({ type: STOP_LOADING, service: 'UPDATE_UPLOAD_ATTACHMENT' });
      dispatch({ type: UPDATE_UPLOAD_ATTACHMENT_FAILURE, payload: error || 'Update upload attachment service failed. Please try again.' });
  }
};

export const getAllUploadAttachmentsAction = (userId) => async (dispatch, getState) => {
  try {
      dispatch({ type: START_LOADING, service: 'ALL_UPLOAD_ATTACHMENTS' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await getAllUploadAttachments(userId, token);
      dispatch({ type: STOP_LOADING, service: 'ALL_UPLOAD_ATTACHMENTS' });
      dispatch({ type: GET_UPLOAD_ATTACHMENTS_SUCCESS, payload: response });
  } catch (error) {
      dispatch({ type: STOP_LOADING, service: 'ALL_UPLOAD_ATTACHMENTS' });
      dispatch({ type: GET_UPLOAD_ATTACHMENTS_FAILURE, payload: error || 'Get all upload attachments service failed. Please try again.' });
  }
};

export const deleteUploadAttachmentActionById = (attachmentId) => async (dispatch, getState) => {
  try {
      dispatch({ type: START_LOADING, service: 'DELETE_UPLOAD_ATTACHMENT' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await deleteUploadAttachmentById(attachmentId, token);
      dispatch({ type: STOP_LOADING, service: 'DELETE_UPLOAD_ATTACHMENT' });
      dispatch({ type: DELETE_UPLOAD_ATTACHMENT_SUCCESS, payload: attachmentId });
      return response;
  } catch (error) {
      dispatch({ type: STOP_LOADING, service: 'DELETE_UPLOAD_ATTACHMENT' });
      dispatch({ type: DELETE_UPLOAD_ATTACHMENT_FAILURE, payload: error.message || 'Delete upload attachment service failed. Please try again.' });
  }
};

export const previewFileAction = (userId, filename) => async (dispatch, getState) => {
  try {
      dispatch({ type: START_LOADING, service: 'PREVIEW_FILE' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await previewFileByUserIdAndFileName(userId, filename, token);
      dispatch({ type: STOP_LOADING, service: 'PREVIEW_FILE' });

      // Assuming response is a Blob
      const blobUrl = URL.createObjectURL(response);

      // Dispatching both Blob and Blob URL
      dispatch({
          type: PREVIEW_FILE_SUCCESS,
          payload: { blob: response, blobUrl }
      });

      // Return both Blob and Blob URL for further handling
      return { blob: response, blobUrl };
  } catch (error) {
      dispatch({ type: STOP_LOADING, service: 'PREVIEW_FILE' });
      dispatch({ type: PREVIEW_FILE_FAILURE, payload: error || 'Preview file service failed. Please try again.' });
      throw error; // Re-throw error for handling in component
  }
};

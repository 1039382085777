import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Grid, Button } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import {
  getAllPermissionsAction,
  openCreatePermissionModal as openCreatePermissionModalAction,
  openUpdatePermissionModal as openUpdatePermissionModalAction
} from '../../redux/actions/permissionActions';
import { getUser as getUserAction } from '../../redux/actions/userActions';
import CustomDataGrid from '../../shared/CustomDataGrid';

const useStyles = makeStyles({
  header: {
    margin: ".5em 0 !important",
  },
});

const Permissions = () => {
  const classes = useStyles();
  const permissions = useSelector((state) => state?.permissions?.allPermissions);
  const error = useSelector((state) => state?.permissions?.error);
  const loading = useSelector((state) => state.loading.ALL_ROLES);
  const user = useSelector((state) => state.user.user)
  const dispatch = useDispatch();

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: true,
      id: 'name',
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 150,
      editable: true,
      id: 'description',
    },
    {
      field: 'insertedBy',
      headerName: 'Inserted By',
      width: 160,
      editable: true,
      id: 'insertedby',
    },
    {
      field: 'updatedBy',
      headerName: 'Updated By',
      width: 160,
      editable: true,
      id: 'updatedby',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <div>
          <Edit
            style={{ cursor: 'pointer' }}
            onClick={() => updatePermission(params.row.id)}
            id={`edit-action-${params.row.id}`}
          />
        </div>
      ),
    },
  ];

  const [permissionsData, setMyPermissionsData] = React.useState(permissions || []);

  React.useEffect(() => {
    if (!user) {
      dispatch(getUserAction());
    } else {
      dispatch(getAllPermissionsAction(user?.id));
    }
  }, [])

  React.useEffect(() => {
    setMyPermissionsData(permissions || []);
  }, [permissions, dispatch, user]);

  const openCreatePermission = () => {
    dispatch(openCreatePermissionModalAction());
  }

  const updatePermission = (id) => {
    dispatch(openUpdatePermissionModalAction(id));
  }

  return (
    <Box sx={{ minHeight: 400, width: '100%' }}>
      <Grid item xs={12} md={12} sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5" className={classes.header}>Permissions</Typography>
        <Button variant="contained" color="primary" sx={{ marginBottom: "1rem", padding: ".5rem 1rem" }} onClick={openCreatePermission}>Create Permission</Button>
      </Grid>
      <CustomDataGrid
        gridData={permissionsData}
        columns={columns}
        loading={loading}
        error={error}
      />
    </Box>
  );
}

export default Permissions;

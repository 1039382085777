import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { makeStyles, useTheme } from '@mui/styles';
import { Box, Typography,Menu, IconButton, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { signOut as signOutAction } from '../redux/actions/authActions';
import { getThemeAction } from '../redux/actions/themeActions';
import { getUser as getUserAction, openCreateUserModal as openCreateUserModalAction } from '../redux/actions/userActions';
import Logo from "./quotetek.svg";
import MainMenu from './MainMenu';

const useStyles = makeStyles((customTheme) => {
    return {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row !important',
    },
    nav: {
        padding: '1em 1em 0',
        margin: '0',
    },
    logo: {
        maxWidth: '120px',
        marginTop: '.25em',
        fontWeight: 'bold',
    },
    link: {
        textDecoration: "none !important",
        padding: "2em .5em 0",
        color: customTheme?.palette?.primary?.main,
        "&:hover": {
            color: 'black',
        }
    },
    icon: {
        marginBottom: '-.25em',
        color: customTheme?.palette?.primary?.main,
    },
    menuLink: {
        color: '#077BFD',
        textDecoration: "none !important",
        padding: ".25em",
    },
    iconColor: {
        color: customTheme?.palette?.primary?.main,
        background: customTheme?.palette?.primary?.main,
    }
  }});


const RaterHeader = () => {
  const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen ]= React.useState(null);
    const dispatch = useDispatch();
    const user = useSelector((state) => state?.user?.user);
    const classes = useStyles();
    const theme = useTheme();

    const dynamicTheme = useSelector((state) => state?.theme?.theme[0]);


    useEffect(() => {
        if(user?.customer?.id) {
            dispatch(getThemeAction(user?.customer?.id))
        }

    }, [user])

    useEffect(() => {
        if(user) {
            dispatch(getUserAction());
        }
    }, []);

    const handleMenu = (event, target) => {
        setOpen(target);
        setAnchorEl(event.currentTarget);
      };

    const handleClose = () => {
        setOpen(undefined);
        setAnchorEl(null);
    };

    const handleSettings = () => {
        setOpen(undefined);
        setAnchorEl(null);
        navigate("/settings");
    };

    const handleCustomTheme = () => {
        setOpen(undefined);
        setAnchorEl(null);
        navigate('/customthemesettings');
    }

    const handleProfile = () => {
      setOpen(undefined);
      setAnchorEl(null);
      navigate('/edituserprofile');
  }

    const logout = async() => {
        setOpen(undefined);
        setAnchorEl(null);

        await dispatch(signOutAction());
    }
    return (
        <header
          style={{
            alignSelf: "stretch",
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "space-between",
            padding: "27px 50px",
            boxSizing: "border-box",
            top: "0",
            zIndex: "99",
            position: "sticky",
            maxWidth: "100%",
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
          }}
      >
      <div
        style={{
          height: "90px",
          position: "relative",
          backgroundColor: "#fff",
          display: "none",
          maxWidth: "100%",
        }}
      />
      <Typography variant="h4">
        {/* {dynamicTheme?.fileData !== "default" ? <img src={dynamicTheme?.fileData}  /> : */}
        <strong style={{color:"#077BFD"}}>Quotetek</strong>
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "30px",
        }}
      >
        <MainMenu />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: "0px 0px 0px",
          }}
        >
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: "0px 0px -6px",
          }}
        >
        <IconButton
  // size="large"
            onClick={(e) => handleMenu(e, 'user-menu')}
            // color="inherit"
            // sx={theme?.palette?.primary?.main}
        >
        <AccountCircleIcon
           sx={{
            width: 25,
               height: 22.8,
              position: 'relative',
              overflow: 'hidden',
              flexShrink: 0,
              zIndex: 1,
              color: '#077BFD',
            //   paddingTop: 20,
           }}
           loading="lazy"
            alt=""
        />
        </IconButton>
        <Menu
                    id="usermenu"
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                    }}
                    keepMounted
                    open={open === 'user-menu'}
                    onClose={handleClose}
                    anchorEl={anchorEl}
                >
                    <MenuItem onClick={handleProfile}><Link className={classes.menuLink}>Edit Profile</Link></MenuItem>
                    <MenuItem onClick={handleSettings}><Link className={classes.menuLink}>Settings</Link></MenuItem>
                    <MenuItem onClick={handleCustomTheme}><Link className={classes.menuLink}>Custom Theme</Link></MenuItem>
                    <MenuItem onClick={logout}><Link className={classes.menuLink}>Logout</Link></MenuItem>
                </Menu>
        </div>

      </div>
    </header>
    )
}

export default RaterHeader;

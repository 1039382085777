// import { FETCH_PRIOR_INSURANCE_CARRIERS_SUCCESS, FETCH_PRIOR_INSURANCE_CARRIERS_FAILURE } from '../actions/actionTypes';

const initialState = {
  priorInsuranceCarriers: [],
  error: null,
};

const priorInsuranceCarriersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_PRIOR_INSURANCE_CARRIERS_SUCCESS':
        // console.log("Reducer success payload:", action.payload); // Debug log
        return {
        ...state,
        priorInsuranceCarriers: action.payload,
        error: null,
      };
    case 'FETCH_PRIOR_INSURANCE_CARRIERS_FAILURE':
        // console.log("Reducer error payload:", action.payload); // Debug log
        return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default priorInsuranceCarriersReducer;



// import {
//     FETCH_PRIOR_INSURANCE_CARRIERS_REQUEST,
//     FETCH_PRIOR_INSURANCE_CARRIERS_SUCCESS,
//     FETCH_PRIOR_INSURANCE_CARRIERS_FAILURE,
//   } from '../actions/actionTypes';

//   const initialState = {
//     priorInsuranceCarriers: [],
//     isLoading: false,
//     error: null,
//   };

//   const priorInsuranceCarriersReducer = (state = initialState, action) => {
//     switch (action.type) {
//       case FETCH_PRIOR_INSURANCE_CARRIERS_REQUEST:
//         return {
//           ...state,
//           isLoading: true,
//           error: null,
//         };
//       case FETCH_PRIOR_INSURANCE_CARRIERS_SUCCESS:
//         return {
//           ...state,
//           priorInsuranceCarriers: action.payload,
//           isLoading: false,
//           error: null,
//         };
//       case FETCH_PRIOR_INSURANCE_CARRIERS_FAILURE:
//         return {
//           ...state,
//           isLoading: false,
//           error: action.payload,
//         };
//       default:
//         return state;
//     }
//   };



//   export default priorInsuranceCarriersReducer;
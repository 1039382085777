import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Checkbox, IconButton, Menu, MenuItem, Box } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import policiesData from '../AllAccounts/policies_data.json'; // Ensure this path is correct
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import { Link,useLocation  } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddTask1 from './AddTask1';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import dayjs from "dayjs";
import { getAllTaskAction,deleteTaskActionById } from '../../../redux/actions/taskActions';

const tableHeaders = [
    { id: 'reviewRequired', label: 'E' },
    { id: 'status', label: 'Status' },
    { id: 'taskType', label: 'Type' },
    { id: 'priority', label: 'priority' },
    { id: 'taskDescription', label: 'Description' },
    { id: 'taskTo', label: 'Assigned' },
    { id: 'dueDate', label: 'Due' },
    { id: 'createdBy', label: 'Creator' },
    { id: 'edit', label: 'Edit' },
    { id: 'delete', label: 'Delete' },
];

const DropdownButton = ({ label, options }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button variant="outlined"  endIcon={<ArrowDropDownIcon />} onClick={handleClick}>
                {label}
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {options.map((option, index) => (
                    <MenuItem key={index} onClick={handleClose}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};


function AllTask() {
    const [tableData, setTableData] = useState([]);
    const location = useLocation();
    const navigate=useNavigate();
    const [showButton,setShowButton]=useState(false);
    const [showHideTaskForm, setShowHideTaskForm] = useState(false);
    const methods = useForm();
    const response = location.state?.response || [];
    const taskData = useSelector((state) => state.task.taskForm);
    const allTask = useSelector((state) => state.task.allTask);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllTaskAction());
    }, [dispatch]);
    console.log("allTask",allTask);

    useEffect(() => {
        if (allTask.length > 0) {
            setTableData(allTask);
        }
    }, [allTask]);
    useEffect(() => {
      if (taskData) {
        setTableData([taskData]);
      }
    }, [taskData]);
    // useEffect(() => {
    //         setTableData(taskData);
    //     }, []);


    const handleEditClick = (task) => {
        // Navigate to CreatePolicy component with pre-filled data
        setShowHideTaskForm(true)
        setShowButton(false);
        console.log("task data in edit",taskData);

        // methods.reset(taskData);
        methods.reset(task);

      };
    const handleDeleteClick = (id) => {
        dispatch(deleteTaskActionById(id, () => {
          dispatch(getAllTaskAction());
        }));
      };

      function handleClick() {
        setShowHideTaskForm(true);
        setShowButton(true);
        // setTableData({});
      }
  return (
    <>
          {showHideTaskForm ? (
        <>
         <FormProvider {...methods}>
        <AddTask1 setShowHideTaskForm={setShowHideTaskForm} setShowButton={showButton} />
        </FormProvider>
        </>
      ):(
        <>
            <Box sx={{display:'flex', justifyContent: 'end'}}>
                <Button onClick={handleClick}   variant="text" sx={{ color: '#0173BC', fontWeight: 'bold', margin: '0 0 10px 0'}}>
                Add New Task
                </Button>
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {tableHeaders.map(header => (
                                <TableCell sx={{color: '#0173BC', fontWeight: 'bold'}} key={header.id}>{header.label}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.map((row, index) => (
                            <TableRow key={index}>
                                {tableHeaders.map(header => (
                                    <TableCell key={header.id} sx={{padding: '12px'}}>
                                        {header.id === 'f' ? (
                                            <Checkbox />
                                        ): header.id === 'edit' ? (
                                            // Render Edit icon and handle click event
                                            <IconButton  onClick={() => handleEditClick(row)}>

                                              <EditIcon />
                                            </IconButton>
                                          ): header.id === 'delete' ? (
                                            // Render Edit icon and handle click event
                                            <IconButton onClick={() => handleDeleteClick(row.id)}>
                                              <DeleteIcon />
                                            </IconButton>
                                          ): header.id === 'dl' ? (
                                            <IconButton>
                                                <FileDownloadDoneIcon />
                                            </IconButton>
                                        ) : header.id === 'action' ? (
                                            <DropdownButton label="Action" options={['Option 1', 'Option 2', 'Option 3']} />
                                        ) : header.id === 'details' ? (
                                            <DropdownButton label="Details" options={['Detail 1', 'Detail 2', 'Detail 3']} />
                                        ) : header.id === 'add' ? (
                                            <DropdownButton label="Add" options={['Add 1', 'Add 2', 'Add 3']} />
                                        ) : header.id === 'rater' ? (
                                            <DropdownButton label="Rater" options={['Rater 1', 'Rater 2', 'Rater 3']} />
                                        ) : (
                                            row[header.id]
                                        )}
                                </TableCell>
                            ))}
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </>
      )}
        </>
  )
}

export default AllTask

import { fetchAllActivity } from '../../services/policyActivityServices';
import { START_LOADING, STOP_LOADING } from './actionTypes';

export const fetchPolicyAllActivity = (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'START_LOADING' });
      const token = getState().auth.token ||sessionStorage.getItem("token");
      const response = await fetchAllActivity(formData, token);      
      dispatch({ type: 'SET_ALL_ACTIVITY', payload: response });
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    } catch (error) {
      dispatch({ type: 'SET_ALL_ACTIVITY', payload: error.message || 'All DriverInfos service failed. Please try again.' });
    }
  };
const initialState = {
    policies: [],
    all_policy_type: [],
    quotes: [],
    all_task: [],
    all_filter_task: [],
    all_priority_task:[],
    all_task_type: [],
    all_policy_filter_type:[],
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_DASHBOARD_DATA':
            return {
                ...state,
                quotes: action.payload || [],
            };
        case 'SET_DASHBOARD_POLICY_DATA':
            return {
                ...state,
                policies: action.payload || [],  // Adjust according to your API structure
            };
        case 'FETCH_FILTERED_DATA_SUCCESS':
            return {
                ...state,
                loading: true,
                policies: action.payload  || [],
                error: null
            };
        case 'FETCH_QUOTE_FILTERED_DATA_SUCCESS':
            return {
                ...state,
                loading: true,
                quotes: action.payload  || [],
                error: null
            };
        case 'FETCH_TASK_DATA_SUCCESS':
            return {
                ...state,
                loading: true,
                all_task: action.payload  || [],
                error: null  
            };
        case 'FETCH_TASK_FILTER_DATA_SUCCESS':
            return {
                ...state,
                loading: true,
                all_task: action.payload  || [],
                error: null
            };    
        case 'FETCH_ALL_POLICY_TYPE_SUCCESS':
            return {
                ...state,
                loading: true,
                all_policy_type: action.payload  || [],
                error: null
            };
        case 'FETCH_POLICYTYPE_FILTERED_DATA_SUCCESS':
            return {
                ...state,
                loading: true,
                all_policy_type: action.payload  || [],
                error: null
            };
        case 'FETCH_TASK_TYPE_DATA_SUCCESS':
            return {
                ...state,
                loading: true,
                all_task_type: action.payload  || [],
                error: null
            };
        case 'FETCH_FILTER_TASK_TYPE_DATA_SUCCESS':
            return {
                ...state,
                loading: true,
                all_task_type: action.payload  || [],
                error: null  
        }; 
        case 'FETCH_PRIORITY_TASK_DATA_SUCCESS':
            return {
                ...state,
                loading: true,
                all_priority_task: action.payload  || [],
                error: null
            };
        case 'FETCH_FILTER_PRIORITY_TASK_DATA_SUCCESS':
            return {
                ...state,
                loading: true,
                all_priority_task: action.payload  || [],
                error: null
            };
        case 'UPDATE_TASK_TABLE_DATA':
            return {
                ...state,
                loading: true,
                all_task: action.payload  || [],
                error: null
            }; 
        case 'SAVE_PRIORITY_TASK_UPDATED_DATA':
            return {
                ...state,
                loading: true,
                all_priority_task: action.payload  || [],
                error: null
            };
        case 'SAVE_OPEN_TASK_UPDATED_DATA':
            return {
                ...state,
                loading: true,
                all_task_type: action.payload  || [],
                error: null
            };                                    
            case 'START_LOADING':
                return {
                    ...state,
                    loading: true,
                };
            case 'STOP_LOADING':
                return {
                    ...state,
                    loading: false,
                };            
        default:
            return state;
    }
};

export default dashboardReducer;

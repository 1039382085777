import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import {
  fetchTaskTypeAction,
  fetchFilterTaskTypeAction,
} from "../../../redux/actions/dashboardAction";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Select,
  TablePagination,
  FormControl,
  MenuItem,
  TextField,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import dayjs from "dayjs";
import DashboardOpenTaskEditPopup from "./DashboardOpenTaskEditPopup";
import Loader from "../../loader/loader";

const DashboardTypeOfTaskOpen = () => {
  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState("All"); // Default to 'All'
  const [fromDate, setFromDate] = useState(dayjs().startOf('month').format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [open, setOpen] = useState(false); // To control the dialog state
  const [openTask, setopenTask] = useState([])
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterData, setFilterData] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);

  useEffect(() => {
    if(!isFiltered){
      dispatch(fetchTaskTypeAction(page, rowsPerPage))
    };
  }, [dispatch,page, rowsPerPage]);

  const task_type_data =
    useSelector((state) => state.dashboardData.all_task_type.addTaskList.content) || [];
    const pagination =
    useSelector((state) => state.dashboardData.all_task_type.addTaskList) || [];
    const loading = useSelector((state) => state.dashboardData.loading);

  useEffect(() => {
    setFilterData(task_type_data)
  }, [task_type_data])  

  
  const handleDateChange = (event) => {
    const { name, value } = event.target;
    if (name === "fromDate") setFromDate(value);
    if (name === "toDate") setToDate(value);
  };

  const handleApply = () => {
    if (fromDate && toDate) {
      const formattedFromDate = dayjs(fromDate).toISOString(); // ISO format
      const formattedToDate = dayjs(toDate).toISOString(); // ISO format
      const formData = {
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        typeOfTaskOpen: selectedFilter !== " " ? selectedFilter : "", // send an empty string if 'All' is selected
      };
      
      if ((fromDate && toDate) || selectedFilter !== "All") {
        dispatch(fetchFilterTaskTypeAction(formData, page, rowsPerPage));
        setIsFiltered(true);
      } else {
        setFilterData(task_type_data, page, rowsPerPage);
        setIsFiltered(false);
      }
    }

  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (isFiltered) {
      const formData = {
        fromDate: fromDate || "",
        toDate: toDate || "",
        typeOfTaskOpen: selectedFilter,
      };
      dispatch(fetchFilterTaskTypeAction(formData, newPage, rowsPerPage));
    } else {
      dispatch(fetchTaskTypeAction(newPage, rowsPerPage));
    }
  };

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    if (isFiltered) {
      const formData = {
        fromDate: fromDate || "",
        toDate: toDate || "",
        typeOfTaskOpen: selectedFilter,
      };
      dispatch(fetchFilterTaskTypeAction(formData, 0, newRowsPerPage));
    } else {
      dispatch(fetchTaskTypeAction(0, newRowsPerPage));
    }
  };

  const fetchUpdatedData = () => {
    dispatch(fetchTaskTypeAction());
  };

  const handleEditTask = (id) => {
    const selectedRow = task_type_data.find((task) => task.id === id);  
    setopenTask(selectedRow)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false); // Close the dialog
  };

console.log(filterData, 'filterDatafilterData')
  return (
    <>
    <Box sx={{ padding: 2 }}>
      <Box sx={{ display: "flex", width: "100%", marginBottom: "15px" }}>
        <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
          <Box sx={{ display: "flex", flexFlow: "column" }}>
            <label>From</label>
            <TextField
              type="date"
              id="from-date"
              name="fromDate"
              value={fromDate}
              onChange={handleDateChange}
              InputLabelProps={{ shrink: true }}
              sx={{ mr: 2 }}
            />
          </Box>
          <Box sx={{ display: "flex", flexFlow: "column" }}>
            <label>To</label>
            <TextField
              type="date"
              name="toDate"
              id="to-date"
              value={toDate}
              onChange={handleDateChange}
              InputLabelProps={{ shrink: true }}
              sx={{ mr: 2 }}
            />
          </Box>
          <FormControl sx={{ display: "flex", flexFlow: "column", id:"type-of-task" }}>
            <label>Type Of Task Open</label>
            <Select
              labelId="filter-select-label"
              id="filter-select"
              value={selectedFilter}
              onChange={handleFilterChange}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Renewal">Renewal</MenuItem>
              <MenuItem value="Cancellation">Cancellation</MenuItem>
              <MenuItem value="Pending Cancellation">
                Pending Cancellation
              </MenuItem>
              <MenuItem value="UW">UW</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            onClick={handleApply}
            id="apply-button"
            sx={{ ml: 2 }}
            disabled={!fromDate || !toDate}
          >
            Apply
          </Button>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="task list table">
          <TableHead>
            <TableRow>
              <TableCell>Policy Number</TableCell>
              <TableCell align="left">Due Date</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Type Of Task Open</TableCell>
              <TableCell align="left">Assigned To</TableCell>
              <TableCell align="left">Subject</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={8}>
                  <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
                    <Typography variant="subtitle1" color="textSecondary">
                      <Loader />
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ) : filterData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={9}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      padding: 2,
                    }}
                  >
                    <Typography variant="subtitle1" color="textSecondary">
                      No Data found.
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              filterData.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.policyNumber}
                  </TableCell>
                  <TableCell align="left">{row.dueDate}</TableCell>
                  <TableCell align="left">{row.status}</TableCell>
                  <TableCell align="left">{row.typeOfTaskOpen}</TableCell>
                  <TableCell align="left">{row.assignedTo}</TableCell>
                  <TableCell align="left">{row.subject}</TableCell>
                  <TableCell align="right">
                    <EditIcon onClick={()=> handleEditTask(row.id)} />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={pagination.totalPages === 0 ? [] : [5, 10, 15, 25, 50, 100]}
        component="div"
        count={pagination.totalElements}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>

    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" id="edit-task-open-dialog">
      <DialogContent>
        <DialogActions
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0'
            }}
            id="dialog-actions"
          >
          <h3 id="edit-task-open-title">Edit Type of Task Open</h3>
          <Button onClick={handleClose} id="close-button">Close</Button>
        </DialogActions>
        <DashboardOpenTaskEditPopup 
          closePopup={handleClose} 
          taskFilterData={openTask} 
          id="dashboard-open-task-edit-popup"
          savePopup={fetchUpdatedData}
        />                
      </DialogContent>            
    </Dialog>

    </>
  );
};

export default DashboardTypeOfTaskOpen;

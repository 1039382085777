import { CLOSE_CREATE_ROLE_MODAL, OPEN_CREATE_ROLE_MODAL, OPEN_UPDATE_ROLE_MODAL } from "../actions/actionTypes";

const initialState = {
    allRoles: [],
    error: null,
    isCreateRoleModalOpen: false,
    selectedRoleId: null,
  };
  
  const rolesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ALL_ROLES_SUCCESS':
            return { ...state, allRoles: action.payload, error: null };

   
        case 'CREATE_ROLE_SUCCESS':
        case 'UPDATE_ROLE_SUCCESS':
            return {
                ...state,
                allRoles: [ ...state.allRoles, ...action.payload ],
                error: null,
            };
        case OPEN_CREATE_ROLE_MODAL:
            return {...state, isCreateRoleModalOpen: true}
        case OPEN_UPDATE_ROLE_MODAL:
            return {...state, isCreateRoleModalOpen: true, selectedRoleId: action.payload}
        case CLOSE_CREATE_ROLE_MODAL:
            return {...state, isCreateRoleModalOpen: false, selectedRoleId: null}
        case 'ALL_ROLES_FAILURE':
        case 'CREATE_ROLE_FAILURE':
        case 'UPDATE_ROLE_FAILURE':
            return { ...state, error: action.payload };
        default:
            return state;
    }
  };
  
  export default rolesReducer;
import { START_LOADING, STOP_LOADING } from "../actions/actionTypes";
const initialState = {};

const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, [action.service]: true };
    case STOP_LOADING:
      return { ...state, [action.service]: false };
    default:
      return state;
  }
};

export default loadingReducer;
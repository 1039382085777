import { CLOSE_CREATE_ORGANIZATION_MODAL, OPEN_CREATE_ORGANIZATION_MODAL, OPEN_UPDATE_ORGANIZATION_MODAL } from "../actions/actionTypes";

const initialState = {
    allOrganization: [],
    error: null,
    isCreateOrganizationModalOpen: false,
    selectedOrganizationId: null,
  };
  
  const organizationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ALL_ORGANIZATIONS_SUCCESS':
            return { ...state, allOrganization: action.payload, error: null };
        case 'CREATE_ORGANIZATION_SUCCESS':
        case 'UPDATE_ORGANIZATION_SUCCESS':
            return {
                ...state,
                allOrganization: [ ...state.allOrganization, ...action.payload ],
                error: null,
            };
        case OPEN_CREATE_ORGANIZATION_MODAL:
            return {...state, isCreateOrganizationModalOpen: true}
        case OPEN_UPDATE_ORGANIZATION_MODAL:
            return {...state, isCreateOrganizationModalOpen: true, selectedOrganizationId: action.payload}
        case CLOSE_CREATE_ORGANIZATION_MODAL:
            return {...state, isCreateOrganizationModalOpen: false, selectedOrganizationId: null}
        case 'ALL_ORGANIZATIONS_FAILURE':
        case 'CREATE_ORGANIZATION_FAILURE':
        case 'UPDATE_ORGANIZATION_FAILURE':
            return { ...state, error: action.payload };
        default:
            return state;
    }
  };
  
  export default organizationReducer;
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    TextField,
    Box,
    Button,
    Select,
    MenuItem
} from '@mui/material';
import {
    updatedTaskData
  } from "../../../redux/actions/dashboardAction";

const DashboardTaskEditPopup = ({closePopup, savePopup, taskFilterData}) => {
  const dispatch = useDispatch();   

  const [formData, setFormData] = useState({
        id: 0,
        taskType: '',
        reviewRequired: true,
        customerNumber: 0,
        customerName: '',
        taskTo: '',
        createdBy: '',
        dueDate: '',
        closedDate: '', 
        closedBy: '',
        time: '',
        priority: '',
        status: '',
        typeOfTaskOpen: '',
        taskDescription: '',
        notes: '',
        policyId: 0,
        pubId: null,
        policyNumber: '',
        createdDate: '',
        assignedTo: '',        
        assignedBy: '',
        subject: '',        
    });

  useEffect(() => {
    if(taskFilterData){
        setFormData({
            id:taskFilterData.id,
            taskType: taskFilterData.taskType,
            reviewRequired: taskFilterData.reviewRequired,
            customerNumber: taskFilterData.customerNumber,
            customerName: taskFilterData.customerName,
            taskTo: taskFilterData.taskTo,
            createdBy: taskFilterData.createdBy,
            dueDate: taskFilterData.dueDate,
            closedDate: taskFilterData.closedDate || null, 
            closedBy: taskFilterData.closedBy || null,
            time:taskFilterData.time,
            priority: taskFilterData.priority,
            status: taskFilterData.status,
            typeOfTaskOpen: taskFilterData.typeOfTaskOpen,
            taskDescription: taskFilterData.taskDescription,
            notes: taskFilterData.notes,
            policyId: taskFilterData.policyId,
            pubId: taskFilterData.pubId,
            policyNumber: taskFilterData.policyNumber,
            createdDate: taskFilterData.createdDate,
            assignedTo: taskFilterData.assignedTo,
            assignedBy: taskFilterData.assignedBy,            
            subject: taskFilterData.subject,
        })
    }    
}, [taskFilterData])

 
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (taskFilterData) {
        const updatedTasTablekData = {...formData };
        dispatch(
            updatedTaskData(updatedTasTablekData, (success) => {
            if (success) {
              closePopup(true)
              savePopup()
            }
          })
        );
      }
  }
  const handleCancel = () => {
     closePopup(true)
  }

  return (
    <div className="popup">
      <div className="popup-content">
        <form onSubmit={handleSubmit}>
            <Box sx={{display: 'flex', flexWrap: 'wrap'}}>            
            <Box sx={{display: 'flex', flexFlow: 'column', marginBottom: '20px', width: '33%'}}>
                <label>
                    Policy Number:
                </label>
                <TextField
                    type="text"
                    name="id"
                    value={formData.policyNumber}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    sx={{ mr: 2 }} />
            </Box>
            <Box sx={{display: 'flex', flexFlow: 'column', marginBottom: '20px', width: '33%'}}>
                <label>
                    Due Date:
                </label>
                <TextField
                    type="date" 
                    name="duedate"
                    id="due-date"
                    value={formData.dueDate}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    sx={{ mr: 2 }} />
            </Box>
            <Box sx={{display: 'flex', flexFlow: 'column', marginBottom: '20px', width: '33%'}}>
                <label>
                    Status:
                </label>
                <Select
                    name="status"
                    id="status"
                    value={formData.status}
                    onChange={handleChange}
                    sx={{ mr: 2 }}
                >
                    <MenuItem value="Open">Open</MenuItem>
                    <MenuItem value="Closed">Closed</MenuItem>
                    <MenuItem value="DueTaskDate">DueTaskDate</MenuItem>
                </Select>
            </Box>

            <Box sx={{display: 'flex', flexFlow: 'column', marginBottom: '20px', width: '33%'}}>
                <label>
                    Assigned To:	
                </label>
                <Select
                    name="assignedTo"
                    value={formData.assignedTo}
                    onChange={handleChange}
                    sx={{ mr: 2 }}
                >
                    <MenuItem value="Michael Taylor">Michael Taylor</MenuItem>
                    <MenuItem value="Sara Wilson">Sara Wilson</MenuItem>
                    <MenuItem value="Tom Clark">Tom Clark</MenuItem>
                    <MenuItem value="Henry Harris">Henry Harris</MenuItem>                    
                </Select>  
            </Box>

            <Box sx={{display: 'flex', flexFlow: 'column', marginBottom: '20px', width: '100%'}}>
                <label>
                    Subject:	
                </label>
                <TextField
                    multiline
                    rows={4}  // Adjust the number of rows as needed
                    name="subject"
                    id="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    sx={{ mr: 2 }}
                />
            </Box>
        </Box>
        <Box sx={{display: 'flex', flexFlow: 'row', justifyContent: 'end'}}>
            <Button
                variant="outlined"
                color="secondary"
                id="cancel-button"
                onClick={handleCancel}
                sx={{ margin: '0 10px'}}
                >
                Cancel
            </Button>

            <Button
                variant="contained"
                color="primary"
                type="submit">
                    Save
            </Button>
        </Box>
        </form>
      </div>
    </div>
  );
};

export default DashboardTaskEditPopup;

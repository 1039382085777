import React from 'react';
import { Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Home } from '@mui/icons-material';

const useStyles = makeStyles({
  link: {
      textDecoration: 'none !important',
      padding: '2em 1.5em 0',
      fontSize: '.85em',
      '&:hover': {
          color: '#ccc',
      }
  },
  homeIcon: {
    marginBottom: '-.25em',
  }
});

const Footer = () => {
  const classes = useStyles();
  return (
    <footer style={{ backgroundColor: '#000', color: 'white', textAlign: 'center', padding: '1rem' }}>
      <Link className={classes.link} href="/home" color="inherit"><Home className={classes.homeIcon}/></Link>
      <Link className={classes.link} href="/about" color="inherit">About</Link>
      <Link className={classes.link} href="/customers" color="inherit">Customers</Link>
      <Link className={classes.link} href="/contact" color="inherit">Contact</Link>
    </footer>
  );
};

export default Footer;

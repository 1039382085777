import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const DriverManagement = ({ handleBack, handleClick }) => {
  const navigate = useNavigate();
  return (
    <section
      id="drivermanagementsection"
      style={{
        alignSelf: "stretch",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "center",
        padding: "0px 23px 20px 20px",
        boxSizing: "border-box",
        maxWidth: "100%",
        textAlign: "left",
        fontSize: "22px",
        color: "#242929",
        fontFamily: '"Poppins", sans-serif',
        boxShadow: 'none',
        border: 0,
      }}
    >
      <div
        id="drivermanagementcontainer"
        style={{
          width: "853px",
          borderRadius: "4px",
          backgroundColor: "#fff",
          border: "1px solid #e3e3e3",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "space-between",
          padding: "17px 20px 17px 47px",
          gap: "20px",
          maxWidth: "100%",
        }}
      >
        <div
          id="hiddendriverinfo"
          style={{
            height: "80px",
            width: "853px",
            position: "relative",
            borderRadius: "4px",
            backgroundColor: "#fff",
            border: "1px solid #e3e3e3",
            boxSizing: "border-box",
            display: "none",
            maxWidth: "100%",
            fontFamily: '"Poppins", sans-serif',
          }}
        />
        <div
          id="driverinfoheader"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: "11px 0px 0px",
            fontFamily: '"Poppins", sans-serif',
          }}
        >
          <h3
            id="driverinfotitle"
            style={{
              margin: "0",
              position: "relative",
              fontSize: "inherit",
              lineHeight: "20px",
              fontWeight: "600",
              fontFamily: "inherit",
              zIndex: "1",
              fontFamily: '"Poppins", sans-serif',
            }}
          >
            Driver Information List
          </h3>
        </div>
        <Button
          id="adddriverbutton"
          style={{
            height: "42px",
            width: "150px",
            position: "relative",
            zIndex: "1",
          }}
          disableElevation={true}
          variant="contained"
          sx={{
            textTransform: "none",
            color: "#fff",
            fontSize: "16px",
            fontFamily: '"Poppins", sans-serif',
            background: "#077BFD",
            borderRadius: "4px",
            "&:hover": { background: "#077BFD" },
            width: 150,
            height: 42,
          }}
          onClick={handleClick}
        >
          Add Driver
        </Button>
      </div>
    </section>
  );
};

export default DriverManagement;


import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextField, Typography, Grid, Paper, MenuItem, FormControl, Select, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { savebotsignupAction } from '../../redux/actions/botAction';

export default function Botlogin({ toggleform, onSubmitSuccess, logindetails, loginid,refreshUser }) {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user?.user);
  const id = userId?.id || [];

  // Find the user data for the given loginid
  const usersWithId = logindetails.find((item) => item.userId === loginid);
  // console.log("usersWithId", usersWithId);

  // State for form data and password visibility
  const [formData, setFormData] = useState({
    crmName: usersWithId ? usersWithId.crmName : 'agencymatrix',
    crmUser: usersWithId ? usersWithId.crmUser : '',
    crmPassword: usersWithId ? usersWithId.crmPassword : '',
  });
  const [showPassword, setShowPassword] = useState(false);

  // Update formData if usersWithId changes
  useEffect(() => {
    if (usersWithId) {
      setFormData({
        crmName: usersWithId.crmName,
        crmUser: usersWithId.crmUser,
        crmPassword: usersWithId.crmPassword,
      });
    }
  }, [usersWithId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
      userId: id,
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await onSubmitSuccess(formData);
      if(refreshUser)
      {
      toggleform();
      window.location.reload();
      }

    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };


  // Toggle password visibility
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <Paper elevation={3} sx={{ maxWidth: 500, margin: 'auto', padding: 4 }}>
      <Typography
        variant="h5"
        component="h2"
        sx={{
          fontFamily: 'Poppins',
          fontSize: '20px',
          fontWeight: 600,
          lineHeight: '19.6px',
          textAlign: 'center',
          marginBottom: 3,
        }}
      >
        Enter the CRM Details
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Poppins',
                fontSize: '15px',
                fontWeight: 500,
                marginBottom: '8px',
              }}
            >
              CRM Name
            </Typography>
            <FormControl fullWidth variant="outlined">
              <Select
                id="crmName"
                name="crmName"
                value={formData.crmName}
                onChange={handleChange}
              >
                <MenuItem value="agencymatrix">Agency Matrix</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="body1"
              sx={{ fontFamily: 'Poppins', fontSize: '15px', fontWeight: 500, marginBottom: '8px' }}
            >
              CRM User
            </Typography>
            <TextField
              fullWidth
              id="crmUser"
              name="crmUser"
              placeholder="Enter the CRM User"
              value={formData.crmUser}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="body1"
              sx={{ fontFamily: 'Poppins', fontSize: '15px', fontWeight: 500, marginBottom: '8px' }}
            >
              CRM Password
            </Typography>
            <TextField
              fullWidth
              id="crmPassword"
              name="crmPassword"
              type={showPassword ? 'text' : 'password'}
              placeholder="Please enter the password"
              value={formData.crmPassword}
              onChange={handleChange}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} display="flex" justifyContent="flex-end" gap={2}>
            <Button variant="outlined" type="button" onClick={toggleform}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}






// import React, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Button, TextField, Typography, Grid, Paper, MenuItem, FormControl, Select } from '@mui/material';
// import { savebotsignupAction } from '../../redux/actions/botAction';

// export default function Botlogin({ toggleform ,onSubmitSuccess,logindetails,loginid }) {
//   const [formData, setFormData] = useState({
//     crmName: 'agencymatrix',
//     crmUser: '',
//     crmPassword: '',
//   });
//   const userId = useSelector((state) => state.user?.user);
//   const id = userId?.id || [];
//   const dispatch = useDispatch();
//   const usersWithId = logindetails.filter((item) => item.userId === loginid);
//   console.log("userswith9", usersWithId);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevState) => ({
//       ...prevState,
//       [name]: value,
//       userId:id,
//     }));
//   };

// //   const handleSubmit = (e) => {
// //     e.preventDefault();
// //     dispatch(savebotsignupAction(formData));
// //     console.log('Form submitted:', formData);
// //   };

// // const handleSubmit = (e) => {
// //     e.preventDefault();
// //     dispatch(savebotsignupAction(formData))
// //       .then((response) => {
// //         // console.log('Form submitted:', formData);
// //         // Call toggleform after the response is received
// //         toggleform();
// //         window.location.reload();
// //       })
// //       .catch((error) => {
// //         console.error('Error submitting form:', error);
// //       });
// //   };

// const handleSubmit = async (e) => {
//   e.preventDefault();
//   try {
//     // Call the callback from parent with form data
//     await onSubmitSuccess(formData);

//     // Close the dialog
//     toggleform();
//   } catch (error) {
//     console.error('Error submitting form:', error);
//   }
// };

//   return (
//     <Paper elevation={3} sx={{ maxWidth: 500, margin: 'auto', padding: 4 }}>
//       <Typography
//         variant="h5"
//         component="h2"
//         sx={{
//           fontFamily: 'Poppins',
//           fontSize: '20px',
//           fontWeight: 600,
//           lineHeight: '19.6px',
//           textAlign: 'center',
//           marginBottom: 3,
//         }}
//       >
//         Enter the CRM Details
//       </Typography>

//       <form onSubmit={handleSubmit}>
//         <Grid container spacing={3}>
//           <Grid item xs={12}>
//             <Typography
//               variant="body1"
//               sx={{
//                 fontFamily: 'Poppins',
//                 fontSize: '15px',
//                 fontWeight: 500,
//                 marginBottom: '8px',
//               }}
//             >
//               CRM Name
//             </Typography>
//             <FormControl fullWidth variant="outlined">
//               <Select
//                 id="crmName"
//                 name="crmName"
//                 value={formData.crmName}
//                 onChange={handleChange}
//               >
//                 {/* <MenuItem value="Bizaitek">Bizaitek</MenuItem> */}
//                 <MenuItem value="agencymatrix">Agency Matrix</MenuItem>
//               </Select>
//             </FormControl>
//           </Grid>

//           <Grid item xs={12}>
//             <Typography
//               variant="body1"
//               sx={{ fontFamily: 'Poppins', fontSize: '15px', fontWeight: 500, marginBottom: '8px' }}
//             >
//               CRM User
//             </Typography>
//             <TextField
//               fullWidth
//               id="crmUser"
//               name="crmUser"
//               placeholder="Enter the CRM User"
//               value={formData.crmUser}
//               onChange={handleChange}
//               variant="outlined"
//             />
//           </Grid>

//           <Grid item xs={12}>
//             <Typography
//               variant="body1"
//               sx={{ fontFamily: 'Poppins', fontSize: '15px', fontWeight: 500, marginBottom: '8px' }}
//             >
//               CRM Password
//             </Typography>
//             <TextField
//               fullWidth
//               id="crmPassword"
//               name="crmPassword"
//               type="password"
//               placeholder="Please enter the password"
//               value={formData.crmPassword}
//               onChange={handleChange}
//               variant="outlined"
//             />
//           </Grid>

//           <Grid item xs={12} display="flex" justifyContent="flex-end" gap={2}>
//             <Button variant="outlined" type="button" onClick={toggleform}>
//               Cancel
//             </Button>
//             <Button variant="contained" color="primary" type="submit" >
//               Submit
//             </Button>
//           </Grid>
//         </Grid>
//       </form>
//     </Paper>
//   );
// }

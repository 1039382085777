
import {
    SAVE_UPLOAD_ATTACHMENT_SUCCESS,
    SAVE_UPLOAD_ATTACHMENT_FAILURE,
    UPDATE_UPLOAD_ATTACHMENT_SUCCESS,
    UPDATE_UPLOAD_ATTACHMENT_FAILURE,
    GET_UPLOAD_ATTACHMENTS_SUCCESS,
    GET_UPLOAD_ATTACHMENTS_FAILURE,
    DELETE_UPLOAD_ATTACHMENT_SUCCESS,
    DELETE_UPLOAD_ATTACHMENT_FAILURE,
    PREVIEW_FILE_SUCCESS,
    PREVIEW_FILE_FAILURE,
    START_LOADING,
    STOP_LOADING
  } from "../actions/actionTypes";

  const initialState = {
    attachmentForm: JSON.parse(localStorage.getItem('attachmentForm')) || {},
    allAttachments: [],
    previewFile: null,
    error: null,
    isLoading: false
  };

  const uploadAttachmentReducer = (state = initialState, action) => {
      switch (action.type) {
        case GET_UPLOAD_ATTACHMENTS_SUCCESS:
          return { ...state, allAttachments: action.payload, error: null };
        case SAVE_UPLOAD_ATTACHMENT_SUCCESS:
        case UPDATE_UPLOAD_ATTACHMENT_SUCCESS:
          localStorage.setItem('attachmentForm', JSON.stringify(action.payload));
          return { ...state, attachmentForm: action.payload, error: null };
        case DELETE_UPLOAD_ATTACHMENT_SUCCESS:
          const updatedAttachments = state.allAttachments.filter(attachment => attachment.id !== action.payload);
          return { ...state, allAttachments: updatedAttachments, error: null };
        case PREVIEW_FILE_SUCCESS:
          return { ...state, previewFile: action.payload, error: null };
        case GET_UPLOAD_ATTACHMENTS_FAILURE:
        case SAVE_UPLOAD_ATTACHMENT_FAILURE:
        case UPDATE_UPLOAD_ATTACHMENT_FAILURE:
        case DELETE_UPLOAD_ATTACHMENT_FAILURE:
        case PREVIEW_FILE_FAILURE:
          return { ...state, error: action.payload };
        case START_LOADING:
          return { ...state, isLoading: true };
        case STOP_LOADING:
          return { ...state, isLoading: false };
        default:
          return state;
      }
    };


  export default uploadAttachmentReducer;

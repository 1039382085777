import React, { useState } from 'react';
import { TextField, Button, Typography, Grid, Container, Box, CssBaseline, Alert, Snackbar, Tooltip, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { resetPassword } from '../services/loginServices';
import { useLocation, useNavigate } from 'react-router';
import { validateField } from '../common/helpers/validation'; // Import your validation function
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles({
    logo: {
        maxWidth: "120px",
        color: "#077BFD",
    },
    loginBox: {
        padding: "1.5em",
        boxShadow: "0em .35em .5em #ccc",
        border: "1px solid #ddd",
    },
    link: {
        fontSize: ".85em",
    }
});

const ResetPasswordForm = () => {
    const classes = useStyles();
    const [formData, setFormData] = useState({
        newPassword: '',
        confirmPassword: '',
    });
    const [passwordMismatch, setPasswordMismatch] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    
    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.newPassword !== formData.confirmPassword) {
            setPasswordMismatch(true);
            return;
        }
        setPasswordMismatch(false);

        // Password policy validation
        const passwordPolicyRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+}{"':;?/>.<,])(?=.{8,})/;
        if (!passwordPolicyRegex.test(formData.newPassword)) {
            handleSnackbarOpen('Password must be at least 8 characters long and contain at least one uppercase letter, one numeric character, and one special character.', 'error');
            return;
        }

        const newPasswordError = validateField(formData.newPassword, 'New Password');
        const confirmPasswordError = validateField(formData.confirmPassword, 'Confirm Password');

        if (newPasswordError || confirmPasswordError) {
            handleSnackbarOpen('Validation errors detected. Please correct them.', 'error');
            return;
        }

        const data = {
            token,
            password: formData.newPassword,
        }
        try {
            const response = await resetPassword(data);
            if(response) {
                handleSnackbarOpen('Password reset completed successfully', 'success');
                navigate('/login');
            }
        } catch (error) {
            handleSnackbarOpen(error, 'error');
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box>
                    <h1 className={classes.logo}>BizAiTek</h1>
                </Box>
                <Box p={3} sx={{ mt: 1 }} className={classes.loginBox}>
                    <Typography id="resetpassword" variant="h6" align="center" gutterBottom>
                        Reset Password 
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    name="newPassword"
                                    label="New Password"
                                    id="newpassword"
                                    type="password"
                                    fullWidth
                                    value={formData.newPassword}
                                    onChange={handleInputChange}
                                    error={!/^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+}{"':;?/>.<,])(?=.{8,})/.test(formData.newPassword)}
                                    helperText={!/^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+}{"':;?/>.<,])(?=.{8,})/.test(formData.newPassword) ? 'Password must be at least 8 characters long and contain at least one uppercase letter, one numeric character, and one special character.' : ''}
                                    InputProps={{
                                        style: { color: 'red' }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    id="confirmpassword"
                                    type="password"
                                    fullWidth
                                    value={formData.confirmPassword}
                                    onChange={handleInputChange}
                                    error={passwordMismatch || (!!formData.confirmPassword && validateField(formData.confirmPassword, 'Confirm Password'))}
                                    helperText={passwordMismatch ? 'Passwords do not match' : validateField(formData.confirmPassword, 'Confirm Password')}
                                    InputProps={{
                                        style: { color: 'red' }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button id="restpasswordbutton" type="submit" variant="contained" color="primary" fullWidth>
                                    Reset Password
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                    >
                        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Box>
            </Box>
        </Container>
    );
};

export default ResetPasswordForm;

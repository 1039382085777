import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Paper,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    '&:nth-child(even)': {
      backgroundColor: 'rgba(224, 224, 224, 0.5)',
    },
  },
  boxContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
  sectionTitle: {
    color: theme.palette.primary.main,
    backgroundColor: 'rgb(230, 250, 250)',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
  infoTitle: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  infoValue: {
    color: theme.palette.text.primary,
    fontSize: '1rem',
  },
}));

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const LeadDetailsModal = ({ leadData, onClose }) => {
  const classes = useStyles();

  const displayValue = (value) => {
    return value ? capitalizeFirstLetter(value) : 'N/A';
  };

  return (
    <Dialog open={Boolean(leadData)} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle className={classes.sectionTitle} id="lead-details-title">
        {`Lead Details: ${displayValue(leadData?.firstName)} ${displayValue(leadData?.lastName)}`}
      </DialogTitle>
      <DialogContent id="leaddetailscontent">
        <Box className={classes.boxContainer}>
          <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12} md={6}>
              <Paper className={classes.paper} id="contactinfopaper">
                <Typography variant="h6" className={classes.infoTitle} id="contactinfotitle">
                  Contact Information
                </Typography>
                <Typography className={classes.infoValue} id="contactemail">
                  <strong>Email:</strong> {displayValue(leadData?.email)}
                </Typography>
                <Typography className={classes.infoValue} id="contactphone">
                  <strong>Phone:</strong> {displayValue(leadData?.phoneNumber)}
                </Typography>
                <Typography className={classes.infoValue} id="contactmobile">
                  <strong>Mobile:</strong> {displayValue(leadData?.mobileNumber)}
                </Typography>
                <Typography className={classes.infoValue} id="contactcreatedby">
                  <strong>Created By:</strong> {displayValue(leadData?.createdBy)}
                </Typography>
                <Typography className={classes.infoValue} id="contactupdate-by">
                  <strong>Updated By:</strong> {displayValue(leadData?.updatedBy)}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper className={classes.paper} id="companyinfopaper">
                <Typography variant="h6" className={classes.infoTitle} id="company-info-title">
                  Company Information
                </Typography>
                <Typography className={classes.infoValue} id="companyname">
                  <strong>Company:</strong> {displayValue(leadData?.company)}
                </Typography>
                <Typography className={classes.infoValue} id="companyindustry">
                  <strong>Industry:</strong> {displayValue(leadData?.industry)}
                </Typography>
                <Typography className={classes.infoValue} id="leadsource">
                  <strong>Lead Source:</strong> {displayValue(leadData?.leadSource)}
                </Typography>
                <Typography className={classes.infoValue} id="leadstatus">
                  <strong>Lead Status:</strong> {displayValue(leadData?.leadStatus)}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper} id="additionalfieldspaper">
                <Typography variant="h6" className={classes.infoTitle} id="additionalfieldstitle">
                  Additional fields
                </Typography>
                {leadData?.leadMetaInformations.map((metaInfo, index) => (
                  <Typography key={index} className={classes.infoValue} id={`metainfo${index}`}>
                    <strong>{metaInfo.label}:</strong> {displayValue(metaInfo.componentActualValue)}
                  </Typography>
                ))}
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" id="closebutton">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LeadDetailsModal;

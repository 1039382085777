import apiService from "./apiServices";


export const fetchAllActivity = async (token) => {
    try {
      const response = await apiService('/activity/all', 'GET', null, null, token);
      return response;
    } catch (error) {
      throw (error?.message || 'Dashboard data is failed. Please try again.');
    }
  }

import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TablePagination
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getVehicleAction, saveVehicleAction, getVehicleInfoByVIN } from '../../../redux/actions/vehicleInfoActions';
import { useDispatch, useSelector } from 'react-redux';
import { getDetailPolicyAction } from '../../../redux/actions/policyActions';

const PolicyVehicle = ({ policyId }) => {

  console.log(policyId, 'vehicle id')
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  
  const customerData = useSelector((state) => state.policy.allDetailPolicy);
  const vehicleData = customerData?.drivers?.[0]?.vehicles || [];
  
  const [vehicles, setVehicles] = useState(vehicleData);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [errors, setErrors] = useState({});
  const [newVehicle, setNewVehicle] = useState({
    vin: "",
    model: "",
    make: "",
    year: "",
    bodyType: "",
    engineType: "",
    territory: "",
    driverId: policyId,
  });

  useEffect(() => {
    if (policyId) {
      dispatch(getDetailPolicyAction(policyId));
    }
  }, [dispatch, policyId]);

  useEffect(() => {
    if (vehicleData.length > 0) {
      setVehicles(vehicleData);
    }
  }, [vehicleData]);

  const handleVinPopulate = async (value) => {
    const response = await dispatch(getVehicleInfoByVIN(value, ""));
    if (response && response.data) {
      setNewVehicle((prevState) => ({
        ...prevState,
        model: response.data.model,
        make: response.data.make,
        year: response.data.year,
        bodyType: response.data.bodyType || "",
        engineType: response.data.engineType || "",
        territory: response.data.territory || "",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, vin: "Invalid VIN" }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Validate input
    const fieldValidation = {
      model: /^[A-Za-z]+$/,
      make: /^[A-Za-z]+$/,
      bodyType: /^[A-Za-z]+$/,
      engineType: /^[A-Za-z]+$/,
      territory: /^[A-Za-z]+$/,
      vin: /^[A-Za-z0-9]+$/,
      year: /^[0-9]+$/
    };
    
    if (fieldValidation[name] && (!fieldValidation[name].test(value) || value.length > (name === "year" ? 4 : 30))) {
      return; // Invalid input
    }
    
    setNewVehicle({ ...newVehicle, [name]: value });
    
    if (value.trim()) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
    
    if (name === "vin") {
      handleVinPopulate(value);
    }
  };

  const validate = () => {
    const tempErrors = {};
    if (!newVehicle.vin.trim()) tempErrors.vin = "Vin is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleAddDriver = () => {
    if (validate()) {
      dispatch(saveVehicleAction(newVehicle, () => {
        console.log("Vehicle saved successfully");
        setVehicles([...vehicles, newVehicle]);
        setNewVehicle({
          vin: "",
          model: "",
          make: "",
          year: "",
          bodyType: "",
          engineType: "",
          territory: "",
          driverId : policyId,
        });
        setOpen(false);
      }));
    }
  };

  const handleCancel = () => {
    setNewVehicle({
      vin: "",
      model: "",
      make: "",
      year: "",
      bodyType: "",
      engineType: "",
      territory: "",
      driverId: policyId,
    });
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRows = vehicles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div>
      {paginatedRows.map((vehicle, index) => (
        <Accordion key={index} id={`accordionvehicle${index}`}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id={`accordionheader${index}`}>
            <Typography>Vehicle: {index + 1}</Typography>
          </AccordionSummary>
          <AccordionDetails id={`accordiondetails${index}`}>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell><strong>VIN:</strong></TableCell>
                    <TableCell>{vehicle.vin}</TableCell>
                    <TableCell><strong>Make:</strong></TableCell>
                    <TableCell>{vehicle.make}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><strong>Year:</strong></TableCell>
                    <TableCell>{vehicle.year}</TableCell>
                    <TableCell><strong>Model:</strong></TableCell>
                    <TableCell>{vehicle.model}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><strong>Body Type:</strong></TableCell>
                    <TableCell>{vehicle.bodyType}</TableCell>
                    <TableCell><strong>Engine Type:</strong></TableCell>
                    <TableCell>{vehicle.engineType}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><strong>Territory:</strong></TableCell>
                    <TableCell>{vehicle.territory}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ))}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={vehicles.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Button variant="contained" id="Addvehiclebutton" color="primary" onClick={() => setOpen(true)} style={{ marginTop: '20px' }}>
        Add Vehicle
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle id="addvehicletitle">Add Vehicle</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {Object.keys(newVehicle).filter((key) => key !== 'driverId').map((key) => (
              <Grid item xs={12} sm={6} key={key}>
                <Typography>
                  <label>{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</label>
                </Typography>
                <TextField
                  fullWidth
                  name={key}
                  value={newVehicle[key]}
                  onChange={handleChange}
                  error={!!errors[key]}
                  helperText={errors[key]}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} id="cancelbutton" color="secondary">Cancel</Button>
          <Button onClick={handleAddDriver} id="addbutton" color="primary">Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PolicyVehicle;


// import React, { useState, useEffect } from 'react';
// import {
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   Typography,
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   TextField,
//   Grid,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableRow,
//   Paper,
//   TablePagination
// } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { getVehicleAction, saveVehicleAction, getVehicleInfoByVIN } from '../../../redux/actions/vehicleInfoActions';
// import { useDispatch, useSelector } from 'react-redux';
// import { getDetailPolicyAction } from '../../../redux/actions/policyActions';

// const PolicyVehicle = ({ policyId }) => {
//   const [open, setOpen] = useState(false);
//   const dispatch = useDispatch();
//   const customerData = useSelector((state) => state.policy.allDetailPolicy);

//   // Extract vehicles data from the customerData
//   const vehicleData = customerData?.drivers?.[0]?.vehicles || []; // Safe navigation to avoid errors if data is missing
//   console.log("Customer data driver vehicles:", vehicleData);

//   const [vehicles, setVehicles] = useState([]);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);
//   const [errors, setErrors] = useState({});
//   const [newVehicle, setNewVehicle] = useState({
//     vin: "",
//     model: "",
//     make: "",
//     year: "",
//     bodyType: "",
//     engineType: "",
//     territory: "",
//     driverId: 1,
//   });

//   useEffect(() => {
//     dispatch(getVehicleAction());
//   }, [dispatch]);

//   useEffect(() => {
//     dispatch(getDetailPolicyAction(policyId));
//   }, [dispatch, policyId]);

//   useEffect(() => {
//     if (vehicleData) {
//       setVehicles(vehicleData);
//     }
//   }, [vehicleData]);

//   const handleVinPopulate = async (value) => {
//     const response = await dispatch(getVehicleInfoByVIN(value, ""));
//     if (response && response.data) {
//       setNewVehicle((prevState) => ({
//         ...prevState,
//         model: response.data.model,
//         make: response.data.make,
//         year: response.data.year,
//         bodyType: response.data.bodyType || "",
//         engineType: response.data.engineType || "",
//         territory: response.data.territory || "",
//       }));
//     } else {
//       setErrors((prevErrors) => ({ ...prevErrors, vin: "Invalid VIN" }));
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setNewVehicle({ ...newVehicle, [name]: value });

//     if (value.trim()) {
//       setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
//     }
//   };

//     const validate = () => {
//     let tempErrors = {};
//     if (!newVehicle.vin.trim()) {
//       tempErrors.vin = 'Vin is required';
//     }
//     setErrors(tempErrors);
//     return Object.keys(tempErrors).length === 0;
//   };
//   const handleAddDriver = () => {
//     if (validate()) {
//       setVehicles([...vehicles, newVehicle]);
//       dispatch(saveVehicleAction(newVehicle, () => {
//         console.log('Vehicle saved successfully');
//         setNewVehicle({
//           vin: "",
//           model: "",
//           make: "",
//           year: "",
//           bodyType: "",
//           engineType: "",
//           territory: "",
//           driverId: 1,
//         });
//         setOpen(false);
//       }));
//     }
//   };

//   const handleCancel = () => {
//     setNewVehicle({
//       vin: "",
//       model: "",
//       make: "",
//       year: "",
//       bodyType: "",
//       engineType: "",
//       territory: "",
//       driverId: "",
//     });
//     setOpen(false);
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const paginatedRows = vehicles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

//   return (
//     <div>
//       {paginatedRows.map((vehicle, index) => (
//         <Accordion key={index}>
//           <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//             <Typography>Vehicle: {index + 1}</Typography>
//           </AccordionSummary>
//           <AccordionDetails>
//             <TableContainer component={Paper}>
//               <Table>
//                 <TableBody>
//                   <TableRow>
//                     <TableCell><strong>VIN:</strong></TableCell>
//                     <TableCell>{vehicle.vin}</TableCell>
//                     <TableCell><strong>Make:</strong></TableCell>
//                     <TableCell>{vehicle.make}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell><strong>Year:</strong></TableCell>
//                     <TableCell>{vehicle.year}</TableCell>
//                     <TableCell><strong>Model:</strong></TableCell>
//                     <TableCell>{vehicle.model}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell><strong>Body Type:</strong></TableCell>
//                     <TableCell>{vehicle.bodyType}</TableCell>
//                     <TableCell><strong>Engine Type:</strong></TableCell>
//                     <TableCell>{vehicle.engineType}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell><strong>Territory:</strong></TableCell>
//                     <TableCell>{vehicle.territory}</TableCell>
//                   </TableRow>
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           </AccordionDetails>
//         </Accordion>
//       ))}

//       <TablePagination
//         rowsPerPageOptions={[5, 10, 25]}
//         component="div"
//         count={vehicles.length}
//         rowsPerPage={rowsPerPage}
//         page={page}
//         onPageChange={handleChangePage}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//       />

//       <Button variant="contained" color="primary" onClick={() => setOpen(true)} style={{ marginTop: '20px' }}>
//         Add Vehicle
//       </Button>

//       <Dialog open={open} onClose={() => setOpen(false)}>
//         <DialogTitle>Add Vehicle</DialogTitle>
//         <DialogContent>
//           <Grid container spacing={2} sx={{ mt: 1 }}>
//             {Object.keys(newVehicle).filter((key) => key !== 'driverId').map((key) => (
//               <Grid item xs={12} sm={6} key={key}>
//                 <Typography>{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</Typography>
//                 <TextField
//                   fullWidth
//                   name={key}
//                   value={newVehicle[key]}
//                   onChange={handleChange}
//                   error={!!errors[key]}
//                   helperText={errors[key]}
//                 />
//               </Grid>
//             ))}
//           </Grid>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCancel} color="secondary">Cancel</Button>
//           <Button onClick={handleAddDriver} color="primary">Add</Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// };

// export default PolicyVehicle;

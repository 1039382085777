import apiService from "./apiServices";

export const createLead = async (formData, token) => {
    try {
      
      const response = await apiService('/lead/save', 'POST', formData, null, token);
      return response;
      } catch (error) {
        throw (error?.message || 'Creating lead is failed. Please try again.');
      }
}

export const updateLead = async (formData, token) => {
  try {
    
    const response = await apiService('/lead/update', 'PUT', formData, null, token);
    return response;
    } catch (error) {
      throw (error?.message || 'Updating lead is failed. Please try again.');
    }
}

export const updateLeadAddress = async (formData, token) => {
  try {
    
    const response = await apiService('/address/save', 'POST', formData, null, token);
    return response;
    } catch (error) {
      throw (error?.message || 'Updating lead address is failed. Please try again.');
    }
}

export const getMyLeads = async (ownerId, token) => {
    try {
        const response = await apiService(`/leads/leads/owner/${ownerId}`, 'GET', null, null, token);
        return response;
      } catch (error) {
        throw (error?.message || 'service is failed. Please try again.');
      }
  }

  export const getAllLeads = async (token) => {
    try {
        const response = await apiService(`/lead/all`, 'GET', null, null, token);
        return response;
      } catch (error) {
        throw (error?.message || 'service is failed. Please try again.');
      }
  }

  export const getLeadById = async (leadId, token) => {
    try {
        const response = await apiService(`/lead/${leadId}`, 'GET', null, null, token);
        return response;
      } catch (error) {
        throw (error?.message || 'service is failed. Please try again.');
      }
  }


import { format } from 'date-fns';

export const convertToCamelCase = (input) => {
    return input?.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  };

export const accessRules = (permissions) => {
  return {
      leads: permissions?.includes('ALL_PERMISSIONS') || permissions?.includes('LEAD_VIEW_ONLY') || permissions?.includes('UPDATE_LEAD'),
      accounts: permissions?.includes('ALL_PERMISSIONS') || permissions?.includes('ACCOUNT_VIEW_ONLY') || permissions?.includes('UPDATE_ACCOUNT'),
      organizations: permissions?.includes('ALL_PERMISSIONS') || permissions?.includes('ORGANIZATIONS_VIEW_ONLY') || permissions?.includes('UPDATE_ORGANIZATIONS'),
      teams: permissions?.includes('ALL_PERMISSIONS') || permissions?.includes('TEAMS_VIEW_ONLY') || permissions?.includes('UPDATE_TEAM'),
      contacts: permissions?.includes('ALL_PERMISSIONS') || permissions?.includes('CONTACTS_VIEW_ONLY') || permissions?.includes('UPDATE_CONTACT'),
      calendar: permissions?.includes('ALL_PERMISSIONS') || permissions?.includes('CALENDAR_VIEW_ONLY') || permissions?.includes('UPDATE_CALENDAR'),
      customer: permissions?.includes('ALL_PERMISSIONS') || permissions?.includes('CUSTOMER_VIEW_ONLY') || permissions?.includes('UPDATE_CUSTOMER'),
      dashboards: permissions?.includes('ALL_PERMISSIONS') || permissions?.includes('DASHBOARDS_VIEW_ONLY') || permissions?.includes('UPDATE_DASHBOARD'),
      reports: permissions?.includes('ALL_PERMISSIONS') || permissions?.includes('REPORTS_VIEW_ONLY') || permissions?.includes('UPDATE_REPORT'),
      settings: permissions?.includes('ALL_PERMISSIONS'),
      theme: permissions?.includes('ALL_PERMISSIONS'),
      roles: permissions?.includes('ALL_PERMISSIONS'),
      permission: permissions?.includes('ALL_PERMISSIONS'),
      updateLead: permissions?.includes('ALL_PERMISSIONS') || permissions?.includes('UPDATE_LEAD'),
      updateAccounts: permissions?.includes('ALL_PERMISSIONS') || permissions?.includes('UPDATE_ACCOUNT'),
      updateOrganization: permissions?.includes('ALL_PERMISSIONS') || permissions?.includes('UPDATE_ORGANIZATIONS'),
      updateTeam: permissions?.includes('ALL_PERMISSIONS') || permissions?.includes('UPDATE_TEAM'),
      updateContact: permissions?.includes('ALL_PERMISSIONS') || permissions?.includes('UPDATE_CONTACT'),
      updateCalendar: permissions?.includes('ALL_PERMISSIONS') || permissions?.includes('UPDATE_CALENDAR'),
      updateCustomer: permissions?.includes('ALL_PERMISSIONS') || permissions?.includes('UPDATE_CUSTOMER'),
      updateDashboard: permissions?.includes('ALL_PERMISSIONS') || permissions?.includes('UPDATE_DASHBOARD'),
      updateReport: permissions?.includes('ALL_PERMISSIONS') || permissions?.includes('UPDATE_REPORT'),
  }
}

export const hexToRgb = (hex) => {
  hex = hex?.replace('#', '');

  const r = parseInt(hex?.substring(0, 2), 16);
  const g = parseInt(hex?.substring(2, 4), 16);
  const b = parseInt(hex?.substring(4, 6), 16);

  return { r, g, b };
};

//from Rater
export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};
// export const formatDate = (date) => {
//   var d = new Date(date),
//     month = "" + (d.getMonth() + 1),
//     day = "" + d.getDate(),
//     year = d.getFullYear();

//   if (month.length < 2) month = "0" + month;
//   if (day.length < 2) day = "0" + day;

//   return [month, day, year].join("-");
// };

// export const populateValue = (value, fieldName, fields) => {
//   const field = fields.find((rule) => rule.fieldName === fieldName);
//   if (field.type === "date") {
//     return formatDate(value);
//   } else {
//     return value;
//   }
// };

export const populateValue = (value, fieldName, fields) => {
  const field = fields.find((rule) => rule.fieldName === fieldName);

  // Handle sr22 field
  if (fieldName === "sr22") {
    return value || "No";
  }

  // Handle date fields
  if (field.type === "date") {
    return formatDate(value);
  }

  // Handle all other fields
  return value;
};


export const getValueByKey = (obj, key) => {
  // Split the key by '.' to handle nested keys
  const keys = key.split('.');

  // Initialize result to the input object
  let result = obj;

  // Traverse through the keys to access the nested properties
  for (const k of keys) {
    // If the current result is null or undefined, return undefined
    if (result === null || result === undefined) {
      return undefined;
    }

    // Access the next level of the nested property
    result = result[k];
  }

  // Return the final result
  return result;
}

export function findValueByKey(jsonData, key) {
  // Check if the JSON data is an object
  if (typeof jsonData !== 'object' || jsonData === null) {
      return;
  }

  // Iterate through the keys of the object
  for (const prop in jsonData) {
      // If the current property is the key we are looking for, return its value
      if (prop === key) {
          return jsonData[prop];
      }
      // If the current property is an object, recursively search within it
      if (typeof jsonData[prop] === 'object') {
          const result = findValueByKey(jsonData[prop], key);
          // If the key is found in the nested object, return its value
          if (result !== undefined) {
              return result;
          }
      }
  }

  // If the key is not found, return undefined
  return undefined;
}

export const formattedCurrency = (number) => number ? number.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '';

// Assuming this is in your util file

const gpIdMapping = new Map();

// Function to generate and store the random string
export const storeGpIdWithRandomString = (gpId) => {
  const randomString = generateRandomString(10); // Generate a 10-character random string
  gpIdMapping.set(randomString, gpId);
  return randomString;
};

// Function to get the gpId back using the random string
export const getGpIdFromRandomString = (randomString) => {
  return gpIdMapping.get(randomString);
};

// Helper function to generate a random string
const generateRandomString = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

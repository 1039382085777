import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
} from '@mui/material';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.default,
  width: '100%',
}));

const Header = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h6.fontSize,
  fontWeight: theme.typography.h6.fontWeight,
  padding: theme.spacing(2),
}));

const FieldLabel = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.body1.fontWeight,
  padding: theme.spacing(1),
}));

function DependentDropdownForm() {
  const [formData, setFormData] = useState({
    formLabel: '',
    values: '',
    fieldName: '',
    description: '',
    helpText: '',
    isRequired: false,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission here
    // You can access form data using formData state
    console.log('Form Submitted:', formData);
    // Clear form after submission (optional)
    setFormData({
      formLabel: '',
      values: '',
      fieldName: '',
      description: '',
      helpText: '',
      isRequired: false,
    });
  };

  return (
    <Root>
      <Grid container spacing={2} xs={6}>
        <Grid item xs={12}>
          <TextField
            label="Label"
            id="label"
            name="formLabel"
            value={formData.formLabel}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Enter values, with each value separated by a new line"
            multiline
            fullWidth
            minRows={4}
            maxRows={4}
            name="values"
            value={formData.values}
            id="values"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Field Name"
            id="fieldname"
            name="fieldName"
            value={formData.fieldName}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Description"
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Help Text"
            id="helptext"
            name="helpText"
            value={formData.helpText}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={formData.isRequired} onChange={handleChange} name="isRequired" />}
            label="Required"
          />
        </Grid>
        <Grid item xs={12}>
          <Button id="save" variant="contained" type="submit" onClick={handleSubmit}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Root>
  );
}

export default DependentDropdownForm;

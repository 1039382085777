import React, {useState} from  'react';
import { Box, Alert, List, ListItem, ListItemText, ListItemIcon, ListItemButton, Stack, Button, Checkbox, FormControl, Typography, MenuItem, FormControlLabel, FormGroup, Modal, OutlinedInput, Select, colors} from '@mui/material';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '4px'
  };
  const primaryButtonStyle = {
    height: '42px',
    minWidth: '90px',
    backgroundColor: '#0173BC',
    color: '#FFFFFF',
    lineHeight: 'normal',
    '&:hover': {
        backgroundColor: '#0C87D7',
        color: '#FFFFFF',
    }
}

const ChangeOwnerModal = ({openChangeOwner, handleOwnerClose}) => {
    const [changeOwner, setChangeOwner] = useState('John');

    const handleChange = (event) => {
        setChangeOwner(event.target.value);
    };
    return(
        <>
            <Modal
                open={openChangeOwner}
                onClose={handleOwnerClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modalmodaltitle" variant="h6" sx={{textAlign: 'center', margin: ' 0 0 20px 0'}} component="h2">
                        Change Lead Owner
                    </Typography>
                    <FormControl fullWidth>
                        <Box sx={{
                                padding: '0 0 10px 0',
                            }}>
                            <label htmlFor="name">Owner Name</label>
                        </Box>
                        <OutlinedInput placeholder="Title" value="Bertha Boxer" id="name" type="text" readOnly />                            
                    </FormControl>
                    <FormControl fullWidth sx={{margin: '15px 0 0 0'}}>
                        <Box sx={{
                            padding: '0 0 10px 0',
                        }}>
                            <label>Select User</label>
                        </Box>                            
                        <Select
                            onChange={handleChange}
                            labelId="demo-simple-select-label"
                            id="demosimpleselect"
                            value={changeOwner}
                        >
                            <MenuItem defaultValue value="John">John</MenuItem>
                            <MenuItem value="Bertha">Bertha</MenuItem>
                        </Select>
                    </FormControl>

                    <FormGroup sx={{margin: '10px 0 10px 0'}}>
                        <FormControlLabel control={<Checkbox />} label="Send notification email" />
                    </FormGroup>
                    <Alert  icon={<InfoOutlinedIcon fontSize="inherit" sx={{ color: '#D2C43B'}}  />} variant="outlined" sx={{color: '#242929', alignItems: 'center', borderColor: '#E7E1B3', margin: '0 0 10px 0'}}>                        
                        <strong>Test user</strong> will also become the owner of these below records related to <strong>Bertha Boxer</strong> that are owned by you.
                    </Alert>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton sx={{padding:'0 0 5px 0',}}>
                                <ListItemIcon sx={{ minWidth: 'auto', padding: '0 5px 0 0'}}>
                                    <DescriptionOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Notes" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton sx={{padding:'0 0 5px 0',}}>
                                <ListItemIcon sx={{ minWidth: 'auto', padding: '0 5px 0 0',}}>
                                    <AttachFileOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Attachments" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Stack spacing={2} direction="row" sx={{justifyContent:'end', marginTop: '15px'}}>
                        <Button onClick={handleOwnerClose} sx={{textTransform: 'capitalize',}} variant="outlined">Cancel</Button>
                        <Button sx={{...primaryButtonStyle, textTransform: 'capitalize'}} variant="contained">Submit</Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    )
}

export default ChangeOwnerModal;
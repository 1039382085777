import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Grid, Button } from '@mui/material';
import { Edit, Email, Phone } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import {
  getAllRoles as getAllRoleAction,
  openCreateRoleModal as openCreateRoleModalAction,
  openUpdateRoleModal as openUpdateRoleModalAction
} from '../../redux/actions/roleActions';
import { getUser as getUserAction } from '../../redux/actions/userActions';
import CustomDataGrid from '../../shared/CustomDataGrid';

const useStyles = makeStyles({
  header: {
    margin: ".5em 0 !important",
  },
});

const Roles = () => {
  const classes = useStyles();
  const roles = useSelector((state) => state?.roles?.allRoles);
  const error = useSelector((state) => state?.roles?.error);
  const loading = useSelector((state) => state.loading.ALL_ROLES);
  const user = useSelector((state) => state?.user?.user)
  const dispatch = useDispatch();

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: true,
      id:'name',
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 150,
      editable: true,
      id:'description',
    },
    {
      field: 'permissions',
      headerName: 'Permissions',
      id:'permissions',
      width: 260,
      editable: true,
      renderCell: (params) => (
        <span>{params.row.permissions.join(', ')}</span>
      ),
    },
    {
      field: 'updatedBy',
      headerName: 'Updated By',
      width: 100,
      editable: true,
      id:'updatedby',
    },
    {
      field: 'updatedTimeStamp',
      headerName: 'Updated Date',
      id:'updatedtimestamp',
      sortable: true,
      width: 160,
      valueGetter: (params) => {
        return new Date(params.row.updatedTimeStamp).toLocaleDateString('en-US');
      },
    },
    {
      field: 'insertedBy',
      headerName: 'Inserted By',
      id:'insertedby',
      sortable: true,
      width: 100,
    },
    {
      field: 'insertedTimeStamp',
      headerName: 'Inserted Date',
      id:'inserteddate',
      sortable: true,
      width: 160,
      valueGetter: (params) => {
        return new Date(params.row.insertedTimeStamp).toLocaleDateString('en-US');
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <div>
          <Edit
            style={{ cursor: 'pointer' }}
            onClick={() => updateRole(params.row.id)}
            id={`edit-action-${params.row.id}`}
          />
        </div>
      ),
    },
  ];

  const [rolesData, setMyRolesData] = React.useState(roles || []);

  React.useEffect(() => {
    if (!user) {
      dispatch(getUserAction());
    } else {
        console.log(user, 'user')
      dispatch(getAllRoleAction(user?.customer?.id));
    }
  }, [])

  React.useEffect(() => {
    setMyRolesData(roles || []);
  }, [roles, dispatch, user]);

  const openCreateRole = () => {
    dispatch(openCreateRoleModalAction());
  }

  const updateRole = (id) => {
    dispatch(openUpdateRoleModalAction(id));
  }

  return (
    <Box sx={{ minHeight: 400, width: '100%' }}>
      <Grid item xs={12} md={12} sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography id="Roles" variant="h5" className={classes.header}>Roles</Typography>
        <Button id="createrolesbutton" variant="contained" color="primary" sx={{ marginBottom: "1rem", padding: ".5rem 1rem" }} onClick={openCreateRole}>Create Role</Button>
      </Grid>
      <CustomDataGrid
        gridData={rolesData}
        columns={columns}
        loading={loading}
        error={error}
      />
    </Box>
  );
}

export default Roles;

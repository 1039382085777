import React from 'react';
import { Box } from '@mui/material';
import UploadForm from './UploadForm';
import UploadList from './UploadList';

const Attachments = () => {
    return (
        <Box
            id="attachmentscontainer"
            name="attachmentsContainer"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
            }}
        >
            <UploadForm 
                id="uploadformcomponent" 
                name="uploadformcomponent" 
            />
            <UploadList 
                id="uploadlistcomponent" 
                name="uploadListComponent" 
            />
        </Box>
    );
};

export default Attachments;

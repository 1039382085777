import { STOP_LOADING,START_LOADING } from './actionTypes';
import { getAllLocationGroups, saveLocationGroups, updateLocationGroups,deletelocationGroupsById } from '../../services/locationGroupServices';
import { openSessionTimeoutModal } from './sessionTimeOutActions';


  export  const saveLocationGroupsAction = (formData) => async (dispatch, getState) => {

      try {
        dispatch({ type: START_LOADING, service: 'SAVE_LOCATION_GROUPS' });

        const token = getState().auth.token || sessionStorage.getItem("token");
        const response = await saveLocationGroups(formData, token);
        dispatch({ type: STOP_LOADING, service: 'SAVE_LOCATION_GROUPS' });
        // if (response) {
        //   callbackfn(response);
        // }
        dispatch({ type: 'SAVE_LOCATION_GROUPS_SUCCESS', payload: response }); // Dispatch the success action with response data
      } catch (error) {
        if (error?.includes("403")) {
          dispatch(openSessionTimeoutModal());
        } else {
          dispatch({ type: STOP_LOADING, service: 'SAVE_LOCATION_GROUPS' });
          dispatch({ type: 'SAVE_LOCATION_GROUPS_FAILURE', payload: error || 'Save location service failed. Please try again.' });
        }
      }
    };

    export const updateLocationGroupsAction = (formData,callbackfn) => async (dispatch, getState) => {
      // console.log(formData, 'formData action updated location')

      try {
        dispatch({ type: START_LOADING, service: 'UPDATE_LOCATION_GROUPS' });
        const token = getState().auth.token || sessionStorage.getItem("token");;
        const response = await updateLocationGroups(formData, token);
        dispatch({ type: STOP_LOADING, service: 'UPDATE_LOCATION_GROUPS' });
        if (response) {
          callbackfn(response);
        }
        dispatch({ type: 'UPDATE_LOCATION_GROUPS_SUCCESS', payload: response });
      } catch (error) {
        dispatch({ type: 'GET_LOCATION_GROUPS_FAILURE', payload: error || 'All location service failed. Please try again.' });
      }
    };

    export const getAllLocationGroupsAction= (page = 1, size = 5) => async (dispatch, getState) => {
      try {
        dispatch({ type: START_LOADING, service: 'ALL_LOCATION_GROUPS' });
        const token = getState().auth.token || sessionStorage.getItem("token");
        const response = await getAllLocationGroups(token, page, size);
        dispatch({ type: STOP_LOADING, service: 'ALL_LOCATION_GROUPS' });
        dispatch({ type: 'ALL_LOCATION_GROUPS', payload: response });
      } catch (error) {
        dispatch({ type: 'GET_LOCATION_GROUPS_FAILURE', payload: error || 'All location service failed. Please try again.' });
      }
    };

    export const deleteGrouplocationAction = (id) => async (dispatch, getState) => {
      try {
        dispatch({ type: 'START_DELETE_LOADING' }); // Start loading for delete action
        const token = getState().auth.token || sessionStorage.getItem("token");
        await deletelocationGroupsById(token, id); // API call to delete the group
        dispatch({ type: 'DELETE_GROUP_LOCATIONS', payload: id });

        // Fetch updated list after deletion
        dispatch(getAllLocationGroupsAction()); // Fetch the latest list after deletion

        dispatch({ type: 'STOP_DELETE_LOADING' }); // Stop loading after fetch is done
      } catch (error) {
        dispatch({ type: 'GET_LOCATION_GROUPS_FAILURE', payload: error || 'Failed to delete group. Please try again.' });
        dispatch({ type: 'STOP_DELETE_LOADING' }); // Stop loading on error as well
      }
    };


import React, { useState } from 'react';
import FormLabelText from '../../../common/FormLabelText';
import { FormControl, Button , Link, FormLabel, Checkbox, Grid, OutlinedInput, Select, MenuItem, Box, FormControlLabel, Radio  } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const AdditionalInformation = () => {
    const [customer, setCustomer] = useState('Personal Lines');
    const [country, setCountry] = useState('');
    const [status, setStatus] = useState('New');

    const handleChange = (event) => {
        setCustomer(event.target.value);
    };

    const handleChangeCountry = (event) => {
        setCountry(event.target.value);
    }

    return(
        <>
            <Box sx={{ border: '#ccc solid 1px', borderRadius: '4px', padding: '20px', margin: '20px 0 0 0', position: 'relative' }}>
                <FormLabelText labelText="Additional Information" />
                <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sx={{margin: '15px 0 0 0'}}>                        
                        <FormControlLabel control={<Checkbox defaultChecked />} label="Do Not Email" />
                        <FormControlLabel control={<Checkbox defaultChecked />} label="Do Not Text" />
                        <FormControlLabel control={<Checkbox defaultChecked />} label="Do Not Call" />
                        <FormControlLabel control={<Checkbox />} label="Do Not Mail" />
                        <FormControlLabel control={<Checkbox />} label="Do Not Market" />
                        <FormControlLabel control={<Checkbox />} label="Do Not Capture Email" />
                        <FormControlLabel control={<Checkbox />} label="Unbearable mail" />
                        <FormControlLabel control={<Checkbox />} label="Bad Cell" />
                        <FormControlLabel control={<Checkbox />} label="Bad" />
                        <FormControlLabel control={<Checkbox />} label="Bad" />
                        <FormControlLabel control={<Checkbox />} label="Bad" />
                        <FormControlLabel control={<Checkbox />} label="Unbearable Email 1" />
                        <FormControlLabel control={<Checkbox />} label="Unbearable Email 2" />                        
                    </Grid>
                </Grid>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={3} sx={{margin: '15px 0 0 0'}}>
                        <FormControl fullWidth>
                            <Box sx={{
                                padding: '0 0 10px 0',
                            }}>
                                <label id="Type">Type</label>
                            </Box>                     
                            <Grid container spacing={2} sx={{margin:'0'}}>
                                <Grid xs={11} md={11}>
                                    <Select
                                        onChange={handleChange}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={status}
                                        sx={{ width: '100%'}}
                                    >
                                        <MenuItem id="selectstatus" value="">Select Status</MenuItem>
                                        <MenuItem id="active" value="Active">Active</MenuItem>
                                        <MenuItem id="new" value="New">New</MenuItem>
                                        <MenuItem id="inprogress" value="In progress">In progress</MenuItem>
                                        <MenuItem id="rejected" value="Rejected">Rejected</MenuItem>
                                        <MenuItem id="completed" value="Completed">Completed</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid xs={1} md={1} sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Link><EditIcon /></Link>
                                </Grid>    
                            </Grid> 
                        </FormControl>
                    </Grid>
                    <Grid item xs={3} sx={{margin: '15px 0 0 0'}}>
                        <FormControl fullWidth>
                            <Box sx={{
                                padding: '0 0 10px 0',
                            }}>
                                <label>Sub Status</label>
                            </Box> 
                            <Grid container spacing={2} sx={{margin:'0'}}>
                                <Grid xs={11} md={11}>
                                    <Select
                                        onChange={handleChange}
                                        labelId="demo-simple-select-label"
                                        id="demosimpleselect"
                                        value={status}
                                        sx={{ width: '100%'}}
                                    >
                                       <MenuItem id="select Status" value="">Select Status</MenuItem>
                                        <MenuItem id="active" value="Active">Active</MenuItem>
                                        <MenuItem id="new" value="New">New</MenuItem>
                                        <MenuItem id="inprogress" value="In progress">In progress</MenuItem>
                                        <MenuItem id="rejected" value="Rejected">Rejected</MenuItem>
                                        <MenuItem id="completed" value="Completed">Completed</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid xs={1} md={1} sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Link><EditIcon /></Link>
                                </Grid>
                            </Grid>                           
                        </FormControl>
                    </Grid>
                    <Grid item xs={3} sx={{margin: '15px 0 0 0'}}>
                        <FormControl fullWidth>
                            <Box sx={{
                                padding: '0 0 10px 0',
                            }}>
                                <label id= "customeragentofrecord" >Customer Agent of Record *</label>
                            </Box>
                            <Grid container spacing={2} sx={{margin:'0'}}>
                                <Grid xs={11} md={11}>
                                    <Select
                                        onChange={handleChange}
                                        labelId="demo-simple-select-label"
                                        id="demosimpleselect"
                                        value={status}
                                        sx={{ width: '100%'}}
                                    >
                                        <MenuItem id="selectstatus" value="">Select Status</MenuItem>
                                        <MenuItem id="active" value="Active">Active</MenuItem>
                                        <MenuItem id="new" value="New">New</MenuItem>
                                        <MenuItem id="inprogress" value="In progress">In progress</MenuItem>
                                        <MenuItem id="rejected" value="Rejected">Rejected</MenuItem>
                                        <MenuItem id="completed" value="Completed">Completed</MenuItem>
                                    </Select>
                                </Grid>    
                                <Grid xs={1} md={1} sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Link><EditIcon /></Link>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3} sx={{margin: '15px 0 0 0'}}>
                        <FormControl fullWidth>
                            <Box sx={{
                                padding: '0 0 10px 0',
                            }}>
                                <label id="Customer CSR"  >Customer CSR</label>
                            </Box>                            
                            <Grid container spacing={2} sx={{margin:'0'}}>
                                <Grid xs={11} md={11}>
                                    <Select
                                        onChange={handleChange}
                                        labelId="demo-simple-select-label"
                                        id="demosimpleselect"
                                        value={status}
                                        sx={{ width: '100%'}}
                                    >
                                        <MenuItem id="selectstatus" value="">Select Status</MenuItem>
                                        <MenuItem id="active" value="Active">Active</MenuItem>
                                        <MenuItem id="new" value="New">New</MenuItem>
                                        <MenuItem id="inprogress" value="In progress">In progress</MenuItem>
                                        <MenuItem id="rejected" value="Rejected">Rejected</MenuItem>
                                        <MenuItem id="completed" value="Completed">Completed</MenuItem>
                                    </Select>
                                </Grid>    
                                <Grid xs={1} md={1} sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Link><EditIcon /></Link>
                                </Grid>
                            </Grid>                            
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={3} sx={{margin: '15px 0 0 0'}}>
                        <FormControl fullWidth>
                            <Box sx={{
                                padding: '0 0 10px 0',
                            }}>
                                <label id="language" >Language</label>
                            </Box> 
                            <Grid container spacing={2} sx={{margin:'0'}}>
                                <Grid xs={11} md={11}>
                                    <Select
                                        onChange={handleChange}
                                        labelId="demo-simple-select-label"
                                        id="demosimpleselect"
                                        value={status}
                                        sx={{width: '100%'}}
                                    >
                                       <MenuItem id="selectstatus" value="">Select Status</MenuItem>
                                        <MenuItem id="active" value="Active">Active</MenuItem>
                                        <MenuItem id="new" value="New">New</MenuItem>
                                        <MenuItem id="inprogress" value="In progress">In progress</MenuItem>
                                        <MenuItem id="rejected" value="Rejected">Rejected</MenuItem>
                                        <MenuItem id="completed" value="Completed">Completed</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid xs={1} md={1} sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Link><EditIcon /></Link>
                                </Grid>    
                            </Grid>
                        </FormControl>
                    </Grid>

                    <Grid item xs={3} sx={{margin: '15px 0 0 0'}}>
                        <FormControl fullWidth>
                            <Box sx={{
                                padding: '0 0 10px 0',
                            }}>
                                <label id="source"  >Source *</label>
                            </Box>                            
                            <Grid container spacing={2} sx={{margin:'0'}}>
                                <Grid xs={11} md={11}>
                                    <Select
                                        onChange={handleChange}
                                        labelId="demo-simple-select-label"
                                        id="demosimpleselect"
                                        value={status}
                                        sx={{width: '100%'}}
                                    >
                                       <MenuItem id="selectstatus" value="">Select Status</MenuItem>
                                        <MenuItem id="active" value="Active">Active</MenuItem>
                                        <MenuItem id="new" value="New">New</MenuItem>
                                        <MenuItem id="inprogress" value="In progress">In progress</MenuItem>
                                        <MenuItem id="rejected" value="Rejected">Rejected</MenuItem>
                                        <MenuItem id="completed" value="Completed">Completed</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid xs={1} md={1} sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Link><EditIcon /></Link>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3} sx={{margin: '15px 0 0 0'}}>
                        <FormControl fullWidth>
                            <Box sx={{
                                padding: '0 0 10px 0',
                            }}>
                                <label id="subsource">Sub Source</label>
                            </Box>
                            <Grid container spacing={2} sx={{margin:'0'}}>
                                <Grid xs={11} md={11}>
                                    <Select
                                        onChange={handleChange}
                                        labelId="demo-simple-select-label"
                                        id="demosimpleselect"
                                        value={status}
                                        sx={{width: '100%'}}
                                    >
                                        <MenuItem id="selecttatus" value="">Select Status</MenuItem>
                                        <MenuItem id="active" value="Active">Active</MenuItem>
                                        <MenuItem id="new" value="New">New</MenuItem>
                                        <MenuItem id="inprogress" value="In progress">In progress</MenuItem>
                                        <MenuItem id="rejected" value="Rejected">Rejected</MenuItem>
                                        <MenuItem id="completed" value="Completed">Completed</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid xs={1} md={1} sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Link><EditIcon /></Link>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3} sx={{margin: '15px 0 0 0'}}>                       
                    </Grid>
                </Grid>
                
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={4} sx={{margin: '15px 0 0 0'}}>
                        <FormControlLabel control={<Checkbox defaultChecked />} label="Do Not Email" />
                        <FormControlLabel control={<Checkbox defaultChecked />} label="Do Not Test" />
                    </Grid>
                </Grid>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={3} sx={{margin: '15px 0 0 0'}}>
                        <FormControl fullWidth>
                            <Box sx={{
                                    padding: '0 0 10px 0',
                                }}>
                                <label htmlFor="name">Username *</label>
                            </Box>
                            <OutlinedInput placeholder="Enter the value" id="name" type="text" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3} sx={{margin: '15px 0 0 0'}}>
                        <FormControl fullWidth>
                            <Box sx={{
                                    padding: '0 0 10px 0',
                                }}>
                                <label htmlFor="name">Password *</label>
                            </Box>
                            <OutlinedInput placeholder="Enter the value" id="password" type="password" />
                        </FormControl>
                    </Grid>                    
                </Grid>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={4} sx={{margin: '15px 0 0 0'}}>
                        <Button variant="outlined" disabled>Generate Credentials</Button>
                    </Grid>
                </Grid>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={4} sx={{margin: '15px 0 0 0'}}>
                        <FormControlLabel control={<Checkbox defaultChecked />} label="Send login info by Email" />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default AdditionalInformation;
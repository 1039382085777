import React from 'react';
import { TextField, Typography, Grid, Button, Box } from '@mui/material';
import { saveAlertAction } from '../../../redux/actions/noteActions';
import { useDispatch } from 'react-redux';

const AddAlert = () => {
  const [alert, setAlert] = React.useState('');
  const dispatch = useDispatch();

  const handleNoteChange = (event) => {
    setAlert(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = {
      alert,
    };

    dispatch(saveAlertAction(formData, (response) => {
      console.log('Alert saved successfully:', response);
      // Add any additional actions after the note is saved successfully
    }));
  };

  return (
    <>
      <Box component="form" noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Typography id="alertlabel">Alert</Typography>
            <TextField
              id="alert-textfield"
              value={alert}
              onChange={handleNoteChange}
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              margin="normal"
              required
            />
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button 
            id="submitbutton" 
            variant="contained" 
            color="primary" 
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default AddAlert;

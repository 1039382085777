// import React, { useState,useEffect } from 'react';
// import { Container, Collapse,Grid, Box, TextField, Accordion, AccordionSummary, AccordionDetails, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button, IconButton } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ArrowRightIcon from '@mui/icons-material/ArrowRight';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import Sms from './Sms';
// import Phone from './Phone';
// import { getAllEmailAction,searchEmailAction } from '../../../redux/actions/communicationAction';
// import { useDispatch,useSelector } from 'react-redux';

// const Communication = () => {
//   const [expandedRows, setExpandedRows] = useState([]);
//   const emails = useSelector((state) => state.communication.allEmail);
//   const searchEmails = useSelector((state) => state.communication.searchEmail);
//   const dispatch = useDispatch();
//   console.log("emails",emails);

//   const [search, setSearch] = useState("");
//   const [emailData, setEmailData] = useState([]);
//   const [hasSearched, setHasSearched] = useState(false);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(6);

//   useEffect(() => {
//     dispatch(getAllEmailAction(page, rowsPerPage));
//   }, [dispatch, page, rowsPerPage]);

//   useEffect(() => {
//     if (hasSearched) {
//       setEmailData(searchEmails);
//     } else {
//       setEmailData(emails);
//     }
//   }, [emails, searchEmails, hasSearched]);

//   const handleSearch = () => {
//     dispatch(searchEmailAction({ search }, () => {
//       setHasSearched(true);
//     }));
//   };

//   const resetSearch = () => {
//     setHasSearched(false);
//     setSearch("");
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };
//   const handleChangeRowsPerPage = (event) => {

//     setRowsPerPage(parseInt(event.target.value, 5));
//     setPage(0);
//   };

//   const handleExpandClick = (index) => {
//     setExpandedRows(prevExpandedRows =>
//       prevExpandedRows.includes(index)
//         ? prevExpandedRows.filter(row => row !== index)
//         : [...prevExpandedRows, index]
//     );
//   };

//   const poppinsFont = {
//     fontFamily: '"Poppins", sans-serif',
//   };

//   return (
//     <Box>
//      <Grid container spacing={2} sx={{ mb: 3 }}>
//         <Grid item xs={12} md={3}>
//           <TextField
//             label="From"
//             type="date"
//             InputLabelProps={{ shrink: true }}
//             fullWidth
//             sx={poppinsFont}
//           />
//         </Grid>
//         <Grid item xs={12} md={3}>
//           <TextField
//             label="To"
//             type="date"
//             InputLabelProps={{ shrink: true }}
//             fullWidth
//             sx={poppinsFont}
//           />
//         </Grid>
//         <Grid item xs={12} md={3}>
//           <TextField
//             label="Subject"
//             fullWidth
//             sx={poppinsFont}
//           />
//         </Grid>
//         <Grid item xs={12} md={3}>
//           <TextField
//             label="Select Policy"
//             select
//             SelectProps={{ native: true }}
//             fullWidth
//             sx={poppinsFont}
//           >
//             <option value="All">All</option>
//           </TextField>
//         </Grid>
//         <Grid item xs={12} display="flex" justifyContent="flex-end">
//           <Button variant="contained" color="primary" sx={{ ...poppinsFont, mr: 2 }}>Search</Button>
//         </Grid>
//       </Grid>




//       <Accordion sx={{poppinsFont, marginTop: '1rem'}}>
//         <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//           <Typography sx={poppinsFont}>Email</Typography>
//         </AccordionSummary>
//         <AccordionDetails>
//           <TableContainer component={Paper}>
//             <Table sx={poppinsFont}>
//               <TableHead>
//                 <TableRow>
//                   <TableCell sx={poppinsFont}>Policy Number</TableCell>
//                   <TableCell sx={poppinsFont}>Type</TableCell>
//                   <TableCell sx={poppinsFont}>Date</TableCell>
//                   <TableCell sx={poppinsFont}>Subject</TableCell>
//                   <TableCell sx={poppinsFont}>Delivered</TableCell>
//                   <TableCell sx={poppinsFont}>Response</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {emails.map((row, index) => (
//                   <React.Fragment key={index}>
//                     <TableRow>
//                       <TableCell sx={poppinsFont}>{row.policyNumber}</TableCell>
//                       <TableCell sx={poppinsFont}>{row.type}</TableCell>
//                       <TableCell sx={poppinsFont}>{row.date}</TableCell>
//                       <TableCell sx={poppinsFont}>
//                         <IconButton onClick={() => handleExpandClick(index)}>
//                           {expandedRows.includes(index) ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
//                         </IconButton>
//                         {row.subject || "No Subject"}
//                       </TableCell>
//                       <TableCell sx={poppinsFont}>{row.delivered ? "Yes" : "No"}</TableCell>
//                       <TableCell sx={poppinsFont}>{row.response}</TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell colSpan={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
//                         <Collapse in={expandedRows.includes(index)} timeout="auto" unmountOnExit>
//                           <Box margin={1} sx={poppinsFont}>
//                             <div>
//                               Detailed information about the subject: {row.subject}
//                             </div>
//                           </Box>
//                         </Collapse>
//                       </TableCell>
//                     </TableRow>
//                   </React.Fragment>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>
//         </AccordionDetails>
//       </Accordion>

//       <Accordion sx={poppinsFont}>
//         <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//           <Typography sx={poppinsFont}>SMS</Typography>
//         </AccordionSummary>
//         <AccordionDetails>
//           <Sms />
//         </AccordionDetails>
//       </Accordion>

//       <Accordion sx={poppinsFont}>
//         <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//           <Typography sx={poppinsFont}>Phone</Typography>
//         </AccordionSummary>
//         <AccordionDetails>
//           <Phone />
//         </AccordionDetails>
//       </Accordion>
//     </Box>
//   );
// };

// export default Communication;


// import React, { useState, useEffect } from 'react';
// import { Grid, Box, TextField,Collapse, Accordion, AccordionSummary, AccordionDetails, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button, IconButton, TablePagination } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ArrowRightIcon from '@mui/icons-material/ArrowRight';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import Sms from './Sms';
// import Phone from './Phone';
// import { getAllEmailAction, searchEmailAction } from '../../../redux/actions/communicationAction';
// import { useDispatch, useSelector } from 'react-redux';

// const Communication = () => {
//   const [expandedRows, setExpandedRows] = useState([]);
//   const emails = useSelector((state) => state.communication.allEmail);
//   const searchEmails = useSelector((state) => state.communication.searchEmail);
//   const dispatch = useDispatch();
//   console.log("searchEmails",searchEmails);
//   console.log("Emails",emails);

//   const [search, setSearch] = useState("");
//   const [emailData, setEmailData] = useState([]);
//   const [hasSearched, setHasSearched] = useState(false);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(6);

//   useEffect(() => {
//     dispatch(getAllEmailAction(page, rowsPerPage));
//   }, [dispatch, page, rowsPerPage]);

//   useEffect(() => {
//     if (hasSearched) {
//       setEmailData(searchEmails);
//     } else {
//       setEmailData(emails);
//     }
//   }, [emails, searchEmails, hasSearched]);

//   const handleSearch = () => {
//     dispatch(searchEmailAction({ search }, () => {
//       setHasSearched(true);
//     }));
//   };

//   const resetSearch = () => {
//     setHasSearched(false);
//     setSearch("");
//     setPage(0);
//     dispatch(getAllEmailAction(0, rowsPerPage));
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const handleExpandClick = (index) => {
//     setExpandedRows(prevExpandedRows =>
//       prevExpandedRows.includes(index)
//         ? prevExpandedRows.filter(row => row !== index)
//         : [...prevExpandedRows, index]
//     );
//   };

//   const poppinsFont = {
//     fontFamily: '"Poppins", sans-serif',
//   };

//   return (
//     <Box>
//       <Grid container spacing={2} sx={{ mb: 3 }}>
//         <Grid item xs={12} md={3}>
//           <TextField
//             label="From"
//             type="date"
//             InputLabelProps={{ shrink: true }}
//             fullWidth
//             sx={poppinsFont}
//           />
//         </Grid>
//         <Grid item xs={12} md={3}>
//           <TextField
//             label="To"
//             type="date"
//             InputLabelProps={{ shrink: true }}
//             fullWidth
//             sx={poppinsFont}
//           />
//         </Grid>
//         <Grid item xs={12} md={3}>
//           <TextField
//             label="Subject"
//             fullWidth
//             value={search}
//             onChange={(e) => setSearch(e.target.value)}
//             sx={poppinsFont}
//           />
//         </Grid>
//         <Grid item xs={12} md={3}>
//           <TextField
//             label="Select Policy"
//             select
//             SelectProps={{ native: true }}
//             fullWidth
//             sx={poppinsFont}
//           >
//             <option value="All">All</option>
//           </TextField>
//         </Grid>
//         <Grid item xs={12} display="flex" justifyContent="flex-end">
//           <Button variant="contained" color="primary" onClick={handleSearch} sx={{ ...poppinsFont, mr: 2 }}>Search</Button>
//           <Button variant="outlined" onClick={resetSearch} sx={poppinsFont}>Reset</Button>
//         </Grid>
//       </Grid>

//       <Accordion sx={{ poppinsFont, marginTop: '1rem' }}>
//         <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//           <Typography sx={poppinsFont}>Email</Typography>
//         </AccordionSummary>
//         <AccordionDetails>
//           <TableContainer component={Paper}>
//             <Table sx={poppinsFont}>
//               <TableHead>
//                 <TableRow>
//                   <TableCell sx={poppinsFont}>Policy Number</TableCell>
//                   <TableCell sx={poppinsFont}>Type</TableCell>
//                   <TableCell sx={poppinsFont}>Date</TableCell>
//                   <TableCell sx={poppinsFont}>Subject</TableCell>
//                   <TableCell sx={poppinsFont}>Delivered</TableCell>
//                   <TableCell sx={poppinsFont}>Response</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {emailData.map((row, index) => (
//                   <React.Fragment key={index}>
//                     <TableRow>
//                       <TableCell sx={poppinsFont}>{row.policyNumber}</TableCell>
//                       <TableCell sx={poppinsFont}>{row.type}</TableCell>
//                       <TableCell sx={poppinsFont}>{row.date}</TableCell>
//                       <TableCell sx={poppinsFont}>
//                         <IconButton onClick={() => handleExpandClick(index)}>
//                           {expandedRows.includes(index) ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
//                         </IconButton>
//                         {row.subject || "No Subject"}
//                       </TableCell>
//                       <TableCell sx={poppinsFont}>{row.delivered ? "Yes" : "No"}</TableCell>
//                       <TableCell sx={poppinsFont}>{row.response}</TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell colSpan={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
//                         <Collapse in={expandedRows.includes(index)} timeout="auto" unmountOnExit>
//                           <Box margin={1} sx={poppinsFont}>
//                             <div>
//                               Detailed information about the subject: {row.subject}
//                             </div>
//                           </Box>
//                         </Collapse>
//                       </TableCell>
//                     </TableRow>
//                   </React.Fragment>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>

//           <TablePagination
//             component="div"
//             count={emailData.length}
//             page={page}
//             onPageChange={handleChangePage}
//             rowsPerPage={rowsPerPage}
//             onRowsPerPageChange={handleChangeRowsPerPage}
//           />
//         </AccordionDetails>
//       </Accordion>

//       <Accordion sx={poppinsFont}>
//         <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//           <Typography sx={poppinsFont}>SMS</Typography>
//         </AccordionSummary>
//         <AccordionDetails>
//           <Sms />
//         </AccordionDetails>
//       </Accordion>

//       <Accordion sx={poppinsFont}>
//         <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//           <Typography sx={poppinsFont}>Phone</Typography>
//         </AccordionSummary>
//         <AccordionDetails>
//           <Phone />
//         </AccordionDetails>
//       </Accordion>
//     </Box>
//   );
// };

// export default Communication;


import React, { useState, useEffect } from 'react';
import { Container, Collapse, Grid, Box, TextField, Accordion, AccordionSummary, AccordionDetails, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, IconButton, TablePagination,Select, MenuItem, Button, InputLabel, FormControl } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Sms from './Sms';
import Phone from './Phone';
import { getAllEmailAction, searchAction,getAllSmsAction,searchSmsAction,getAllPhoneAction,searchPhoneAction } from '../../../redux/actions/communicationAction';
import { useDispatch, useSelector } from 'react-redux';

const Communication = () => {
  const [expandedRows, setExpandedRows] = useState([]);
  const emails = useSelector((state) => state.communication.allEmail);
  const searchdatas = useSelector((state) => state.communication.allSearch);
  const sms = useSelector((state) => state.communication.allSms);
  const phones = useSelector((state) => state.communication.allPhones);
  const dispatch = useDispatch();

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [subject, setSubject] = useState("");
  const [hasSearched, setHasSearched] = useState(false);
  const [communicationType, setcommunicationType] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [emailData, setEmailData] = useState([]);
  const [smsData, setSmsData] = useState([]);
  const [phoneData, setPhoneData] = useState([]);

  console.log("search data general",searchdatas);

  useEffect(() => {
    dispatch(getAllEmailAction(page, rowsPerPage));
    dispatch(getAllSmsAction(page, rowsPerPage));
    dispatch(getAllPhoneAction(page, rowsPerPage));
  }, [dispatch, page, rowsPerPage]);

  // useEffect(() => {
  //   dispatch(getAllEmailAction(page, rowsPerPage));
  // }, [dispatch, page, rowsPerPage]);

  // useEffect(() => {
  //   dispatch(getAllSmsAction(page, rowsPerPage));
  // }, [dispatch, page, rowsPerPage]);

  // useEffect(() => {
  //   dispatch(getAllPhoneAction(page, rowsPerPage));
  // }, [dispatch, page, rowsPerPage]);

  // useEffect(() => {
  //   if (hasSearched) {
  //     setEmailData(searchdatas);
  //   } else {
  //     setEmailData(emails);
  //   }
  // }, [emails, searchEmails, hasSearched]);

  useEffect(() => {
    if (hasSearched) {
      // setEmailData(searchdatas.filter(item => item.type === 'email'));
      // setSmsData(searchdatas.filter(item => item.type === 'sms'));
      // setPhoneData(searchdatas.filter(item => item.type === 'phone'));
      if (communicationType === 'email') {
        setEmailData(searchdatas);
      } else if (communicationType === 'sms') {
        console.log("search data in sms",searchdatas);
        setSmsData(searchdatas);

      } else if (communicationType === 'phone') {
        setPhoneData(searchdatas);

      } else {
        // If no specific communication type is selected, you might want to show all results.
        setEmailData(searchdatas.filter(item => item.type === 'email'));
        setSmsData(searchdatas.filter(item => item.type === 'sms'));
        setPhoneData(searchdatas.filter(item => item.type === 'phone'));
      }
    } else {
      setEmailData(emails);
      setSmsData(sms);
      setPhoneData(phones);
    }
  }, [emails, sms, phones, searchdatas, hasSearched]);

  // const handleSearch = () => {
  //   const searchParams = {
  //     fromDate,
  //     toDate,
  //     subject,
  //     searchType,
  //   };

  //   dispatch(searchAction(searchParams, () => {
  //     setHasSearched(true);
  //   }));
  // };

  const handleSearch = () => {
    const searchParams = {
      fromDate,
      toDate,
      subject,
      communicationType,
    };

    switch (communicationType) {
      case 'email':
        dispatch(searchAction(searchParams, () => setHasSearched(true)));
        break;
      case 'sms':
        dispatch(searchAction(searchParams, () => setHasSearched(true)));
        break;
      case 'phone':
        dispatch(searchAction(searchParams, () => setHasSearched(true)));
        break;
      default:
        console.error('Invalid search type');
    }
  };


  // const resetSearch = () => {
  //   setHasSearched(false);
  //   setFromDate("");
  //   setToDate("");
  //   setSubject("");
  //   setSearchType("");
  // };

  const resetSearch = () => {
    setHasSearched(false);
    setFromDate("");
    setToDate("");
    setSubject("");
    setcommunicationType("");
    dispatch(getAllEmailAction(page, rowsPerPage));
    dispatch(getAllSmsAction(page, rowsPerPage));
    dispatch(getAllPhoneAction(page, rowsPerPage));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleExpandClick = (index) => {
    setExpandedRows(prevExpandedRows =>
      prevExpandedRows.includes(index)
        ? prevExpandedRows.filter(row => row !== index)
        : [...prevExpandedRows, index]
    );
  };

  const poppinsFont = {
    fontFamily: '"Poppins", sans-serif',
  };

  return (
    <Box>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} md={3}>
          <label>From</label>
          <TextField
            type="date"
            InputLabelProps={{ shrink: true }}
            fullWidth
            sx={poppinsFont}
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <label>To</label>
          <TextField
            type="date"
            InputLabelProps={{ shrink: true }}
            fullWidth
            sx={poppinsFont}
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <label>Subject</label>
          <TextField
            fullWidth
            sx={poppinsFont}
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth sx={poppinsFont}>
            <label>Select to search</label>
            <Select
              value={communicationType}
              label="Select to search"
              onChange={(e) => setcommunicationType(e.target.value)}
            >
              <MenuItem value="email">email</MenuItem>
              <MenuItem value="phone">phone</MenuItem>
              <MenuItem value="sms">sms</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <Button id="search" variant="contained" color="primary" sx={{ ...poppinsFont, mr: 2 }} onClick={handleSearch}>
            Search
          </Button>
          <Button id="reset" variant="outlined" color="secondary" sx={poppinsFont} onClick={resetSearch}>
            Reset
          </Button>
        </Grid>
      </Grid>

      <Accordion sx={{ poppinsFont, marginTop: '1rem' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography id="email" sx={poppinsFont}>Email</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table sx={poppinsFont}>
              <TableHead>
                <TableRow>
                <TableCell id="policynumbercell" sx={poppinsFont}>Policy Number</TableCell>
<TableCell id="typecell" sx={poppinsFont}>Type</TableCell>
<TableCell id="datecell" sx={poppinsFont}>Date</TableCell>
<TableCell id="subjectcell" sx={poppinsFont}>Subject</TableCell>
<TableCell id="deliveredcell" sx={poppinsFont}>Delivered</TableCell>
<TableCell id="responsecell" sx={poppinsFont}>Response</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {emailData.map((row, index) => (
                  <React.Fragment key={index}> 
                    <TableRow>
                      <TableCell sx={poppinsFont}>{row.policyNumber}</TableCell>
                      <TableCell sx={poppinsFont}>{row.type}</TableCell>
                      <TableCell sx={poppinsFont}>{row.date}</TableCell>
                      <TableCell sx={poppinsFont}>
                        <IconButton onClick={() => handleExpandClick(index)}>
                          {expandedRows.includes(index) ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                        </IconButton>
                        {row.subject || "No Subject"}
                      </TableCell>
                      <TableCell sx={poppinsFont}>{row.delivered ? "Yes" : "No"}</TableCell>
                      <TableCell sx={poppinsFont}>{row.response}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
                        <Collapse in={expandedRows.includes(index)} timeout="auto" unmountOnExit>
                          <Box margin={1} sx={poppinsFont}>
                            <div>
                              Detailed information about the subject: {row.subject || "No Subject"}
                            </div>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={100}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion sx={poppinsFont}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography id="SMS" sx={poppinsFont}>SMS</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Sms
            sms={smsData}
            poppinsFont={poppinsFont}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPage={rowsPerPage}
            page={page}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion sx={poppinsFont}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography id="phone" sx={poppinsFont}>Phone</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Phone
            phones={phoneData}
            poppinsFont={poppinsFont}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPage={rowsPerPage}
            page={page}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default Communication;



























// const Communication = () => {
//   const [expandedRows, setExpandedRows] = useState([]);
//   const emails = useSelector((state) => state.communication.allEmail);
//   const searchEmails = useSelector((state) => state.communication.searchEmail);
//   const sms=useSelector((state) => state.communication.allSms);
//   const phones=useSelector((state) => state.communication.allPhones);
//   const dispatch = useDispatch();
//   console.log("phone",phones);

//   const [fromDate, setFromDate] = useState("");
//   const [toDate, setToDate] = useState("");
//   const [subject, setSubject] = useState("");
//   const [hasSearched, setHasSearched] = useState(false);
//   const [emailData, setEmailData] = useState([]);
//   const [searchType,setSearchType]=useState();
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(10);

//   useEffect(() => {
//     dispatch(getAllEmailAction(page, rowsPerPage));
//   }, [dispatch, page, rowsPerPage]);

//   useEffect(() => {
//     dispatch(getAllSmsAction(page, rowsPerPage));
//   }, [dispatch, page, rowsPerPage]);

//   useEffect(() => {
//     dispatch(getAllPhoneAction(page, rowsPerPage));
//   }, [dispatch, page, rowsPerPage]);


//   useEffect(() => {
//     if (hasSearched) {
//       setEmailData(searchEmails);
//     } else {
//       setEmailData(emails);
//     }
//   }, [emails, searchEmails, hasSearched]);

//   const handleSearch = () => {
//     const searchParams = {
//       fromDate,
//       toDate,
//       subject,
//     };

//     dispatch(searchEmailAction(searchParams, () => {
//       setHasSearched(true);
//     }));
//   };

//   const resetSearch = () => {
//     setHasSearched(false);
//     setFromDate("");
//     setToDate("");
//     setSubject("");
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const handleExpandClick = (index) => {
//     setExpandedRows(prevExpandedRows =>
//       prevExpandedRows.includes(index)
//         ? prevExpandedRows.filter(row => row !== index)
//         : [...prevExpandedRows, index]
//     );
//   };

//   const poppinsFont = {
//     fontFamily: '"Poppins", sans-serif',
//   };

//   return (
//     <Box>
//       <Grid container spacing={2} sx={{ mb: 3 }}>
//         <Grid item xs={12} md={3}>
//           <TextField
//             label="From"
//             type="date"
//             InputLabelProps={{ shrink: true }}
//             fullWidth
//             sx={poppinsFont}
//             value={fromDate}
//             onChange={(e) => setFromDate(e.target.value)}
//           />
//         </Grid>
//         <Grid item xs={12} md={3}>
//           <TextField
//             label="To"
//             type="date"
//             InputLabelProps={{ shrink: true }}
//             fullWidth
//             sx={poppinsFont}
//             value={toDate}
//             onChange={(e) => setToDate(e.target.value)}
//           />
//         </Grid>
//         <Grid item xs={12} md={3}>
//           <TextField
//             label="Subject"
//             fullWidth
//             sx={poppinsFont}
//             value={subject}
//             onChange={(e) => setSubject(e.target.value)}
//           />
//         </Grid>
//         <Grid item xs={12} md={3}>
//                     <FormControl fullWidth sx={poppinsFont}>
//                         <InputLabel id="search-type-label">Select to search</InputLabel>
//                         <Select
//                             labelId="search-type-label"
//                             value={searchType}
//                             label="Select to search"
//                             onChange={(e) => setSearchType(e.target.value)}
//                         >
//                             <MenuItem value="email">Email</MenuItem>
//                             <MenuItem value="phone">Phone</MenuItem>
//                             <MenuItem value="sms">SMS</MenuItem>
//                         </Select>
//                     </FormControl>
//                 </Grid>
//         <Grid item xs={12} display="flex" justifyContent="flex-end">
//           <Button variant="contained" color="primary" sx={{ ...poppinsFont, mr: 2 }} onClick={handleSearch}>
//             Search
//           </Button>
//           <Button variant="outlined" color="secondary" sx={poppinsFont} onClick={resetSearch}>
//             Reset
//           </Button>
//         </Grid>
//       </Grid>

//       <Accordion sx={{ poppinsFont, marginTop: '1rem' }}>
//         <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//           <Typography sx={poppinsFont}>Email</Typography>
//         </AccordionSummary>
//         <AccordionDetails>
//           <TableContainer component={Paper}>
//             <Table sx={poppinsFont}>
//               <TableHead>
//                 <TableRow>
//                   <TableCell sx={poppinsFont}>Policy Number</TableCell>
//                   <TableCell sx={poppinsFont}>Type</TableCell>
//                   <TableCell sx={poppinsFont}>Date</TableCell>
//                   <TableCell sx={poppinsFont}>Subject</TableCell>
//                   <TableCell sx={poppinsFont}>Delivered</TableCell>
//                   <TableCell sx={poppinsFont}>Response</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {emailData.map((row, index) => (
//                   <React.Fragment key={index}>
//                     <TableRow>
//                       <TableCell sx={poppinsFont}>{row.policyNumber}</TableCell>
//                       <TableCell sx={poppinsFont}>{row.type}</TableCell>
//                       <TableCell sx={poppinsFont}>{row.date}</TableCell>
//                       <TableCell sx={poppinsFont}>
//                         <IconButton onClick={() => handleExpandClick(index)}>
//                           {expandedRows.includes(index) ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
//                         </IconButton>
//                         {row.subject || "No Subject"}
//                       </TableCell>
//                       <TableCell sx={poppinsFont}>{row.delivered ? "Yes" : "No"}</TableCell>
//                       <TableCell sx={poppinsFont}>{row.response}</TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell colSpan={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
//                         <Collapse in={expandedRows.includes(index)} timeout="auto" unmountOnExit>
//                           <Box margin={1} sx={poppinsFont}>
//                             <div>
//                               Detailed information about the subject: {row.subject || "No Subject"}
//                             </div>
//                           </Box>
//                         </Collapse>
//                       </TableCell>
//                     </TableRow>
//                   </React.Fragment>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>

//           <TablePagination
//             component="div"
//             count={100}
//             page={page}
//             onPageChange={handleChangePage}
//             rowsPerPage={rowsPerPage}
//             onRowsPerPageChange={handleChangeRowsPerPage}
//           />
//         </AccordionDetails>
//       </Accordion>

//       <Accordion sx={poppinsFont}>
//         <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//           <Typography sx={poppinsFont}>SMS</Typography>
//         </AccordionSummary>
//         <AccordionDetails>
//           <Sms sms={sms} poppinsFont={poppinsFont}
//           handleChangePage={handleChangePage}
//            handleChangeRowsPerPage={handleChangeRowsPerPage}
//            rowsPerPage={rowsPerPage}
//            page={page}
//            />
//         </AccordionDetails>
//       </Accordion>

//       <Accordion sx={poppinsFont}>
//         <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//           <Typography sx={poppinsFont}>Phone</Typography>
//         </AccordionSummary>
//         <AccordionDetails>
//           <Phone
//           phones={phones}
//           poppinsFont={poppinsFont}
//           handleChangePage={handleChangePage}
//            handleChangeRowsPerPage={handleChangeRowsPerPage}
//            rowsPerPage={rowsPerPage}
//            page={page}
//           />
//         </AccordionDetails>
//       </Accordion>
//     </Box>
//   );
// };

// export default Communication;
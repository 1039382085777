
// import React, { useState, useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { styled } from '@mui/material/styles';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell, { tableCellClasses } from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import Button from '@mui/material/Button';
// import NoteAddIcon from '@mui/icons-material/NoteAdd';
// import Modal from '@mui/material/Modal';
// import Box from '@mui/material/Box';
// import { getAllUploadAttachmentsAction, previewFileAction } from '../../redux/actions/uploadAttachmentActions';
// import { TablePagination } from '@mui/material';

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: theme.palette.common.white,
//     color: theme.palette.common.black,
//     fontWeight: 'bold',
//     padding: '10px 10px',
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//     border: 'none',
//     padding: '10px 10px',
//   },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   '&:nth-of-type(odd)': {
//     backgroundColor: '#F8F8F8',
//   },
//   '&:nth-of-type(even)': {
//     backgroundColor: theme.palette.common.white,
//   },
//   '& td, & th, & tr': {
//     border: 'none',
//     padding: '10px 10px',
//   },
// }));

// const StyledTableContainer = styled(TableContainer)({
//   boxShadow: 'none',
//   marginRight: '16px',
//   maxWidth: '95vw',
//   border: '2px solid grey',
//   '& tr': {
//     border: 'none',
//   },
// });

// const StyledOutlinedButton = styled(Button)(({ theme }) => ({
//   borderColor: '#0173BC',
//   color: '#0173BC',
//   '&:hover': {
//     borderColor: '#0173BC',
//     backgroundColor: 'rgba(1, 115, 188, 0.04)',
//   },
// }));

// const modalStyle = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: '80vw',
//   bgcolor: 'background.paper',
//   boxShadow: 24,
//   p: 4,
// };

// const UploadList = () => {
//   const dispatch = useDispatch();
//   const user = useSelector((state) => state?.user?.user?.id);
//   const uploads = useSelector(state => state.uploadAttachment.allAttachments);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);

//   useEffect(() => {
//     dispatch(getAllUploadAttachmentsAction(user));
//   }, [dispatch, user]);

//   const handleDownload = async (userId, filename, fileExtension) => {
//     try {
//         const result = await dispatch(previewFileAction(userId, filename));

//         if (result && result.blob && result.blobUrl) {
//             const { blob, blobUrl } = result;

//             // Define a MIME type map based on file extensions
//             const mimeTypeMap = {
//                 'pdf': 'application/pdf',
//                 'png': 'image/png',
//                 'jpg': 'image/jpeg',
//                 'jpeg': 'image/jpeg',
//                 'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
//                 // Add more mappings as needed
//             };

//             // Use the fileExtension parameter to determine the MIME type
//             const mimeType = mimeTypeMap[fileExtension] || 'application/octet-stream';

//             // Create a new Blob with the correct MIME type if necessary
//             const updatedBlob = mimeType === 'application/octet-stream' ? blob : new Blob([blob], { type: mimeType });

//             // Create a download link using the Blob URL
//             const a = document.createElement('a');
//             a.href = window.URL.createObjectURL(updatedBlob); // Create new Blob URL with correct MIME type
//             a.download = filename; // Keep original filename
//             document.body.appendChild(a);
//             a.click();
//             document.body.removeChild(a);
//             setTimeout(() => window.URL.revokeObjectURL(a.href), 1000); // Optional cleanup
//         } else {
//             console.error('Blob or Blob URL not found');
//         }
//     } catch (error) {
//         console.error('Error downloading file:', error);
//     }
// };

// const paginatedRows = uploads.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

// const handleChangePage = (event, newPage) => {
//   setPage(newPage);
// };

// const handleChangeRowsPerPage = (event) => {
//   setRowsPerPage(parseInt(event.target.value, 10));
//   setPage(0);
// };

//   return (
//     <>
//       <StyledTableContainer component={Paper}>
//         <Table sx={{ border: '0', borderColor: 'transparent', minWidth: 650 }} aria-label="simple table">
//           <TableHead>
//             <TableRow>
//               <StyledTableCell>Policy Number</StyledTableCell>
//               <StyledTableCell>Type</StyledTableCell>
//               <StyledTableCell>Date Uploaded</StyledTableCell>
//               <StyledTableCell>Document Name</StyledTableCell>
//               <StyledTableCell>Download</StyledTableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {paginatedRows.map((upload, index) => (
//               <StyledTableRow key={index}>
//                 <StyledTableCell>{upload.policy}</StyledTableCell>
//                 <StyledTableCell>{upload.type}</StyledTableCell>
//                 <StyledTableCell>{upload.date}</StyledTableCell>
//                 <StyledTableCell>{upload.name}</StyledTableCell>

//                 <StyledTableCell>
//                   <Button
//                     startIcon={<NoteAddIcon />}
//                     onClick={() => handleDownload(user, upload.name, upload.fileType)}
//                   >
//                     Download
//                   </Button>
//                 </StyledTableCell>
//               </StyledTableRow>
//             ))}
//           </TableBody>
//         </Table>
//         <TablePagination
//           component="div"
//           count={uploads.length}
//           page={page}
//           onPageChange={handleChangePage}
//           rowsPerPage={rowsPerPage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//           rowsPerPageOptions={[5, 10, 15]}
//         />
//       </StyledTableContainer>
//     </>
//   );
// };

// export default UploadList;

// import React, { useState, useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { styled } from '@mui/material/styles';
// import {
//   Table,
//   TableBody,
//   TableCell,
//   tableCellClasses, // Add this import
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Button,
//   TablePagination,
// } from '@mui/material';
// import NoteAddIcon from '@mui/icons-material/NoteAdd';
// import { getAllUploadAttachmentsAction, previewFileAction } from '../../redux/actions/uploadAttachmentActions';

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.common.white,
//     fontWeight: 'bold',
//     padding: '12px',
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//     padding: '10px',
//     borderBottom: `1px solid ${theme.palette.divider}`,
//   },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   '&:nth-of-type(odd)': {
//     backgroundColor: theme.palette.action.hover,
//   },
// }));

// const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
//   margin: '16px auto',
//   boxShadow: theme.shadows[3],
//   borderRadius: theme.shape.borderRadius,
//   maxWidth: '90vw',
//   border: `2px solid ${theme.palette.divider}`,
// }));

// const StyledButton = styled(Button)(({ theme }) => ({
//   borderColor: theme.palette.primary.main,
//   color: theme.palette.primary.main,
//   textTransform: 'none',
//   '&:hover': {
//     borderColor: theme.palette.primary.dark,
//     backgroundColor: theme.palette.action.hover,
//   },
// }));

// const UploadList = () => {
//   const dispatch = useDispatch();
//   const userId = useSelector(state => state.user?.user?.id);
//   const uploads = useSelector(state => state.uploadAttachment.allAttachments);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);

//   useEffect(() => {
//     dispatch(getAllUploadAttachmentsAction(userId));
//   }, [dispatch, userId]);

//   const handleDownload = async (userId, filename, fileExtension) => {
//     try {
//       const result = await dispatch(previewFileAction(userId, filename));
//       if (result?.blobUrl) {
//         const mimeTypeMap = {
//           pdf: 'application/pdf',
//           png: 'image/png',
//           jpg: 'image/jpeg',
//           jpeg: 'image/jpeg',
//           docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
//         };
//         const mimeType = mimeTypeMap[fileExtension] || 'application/octet-stream';
//         const blob = new Blob([result.blob], { type: mimeType });
//         const link = document.createElement('a');
//         link.href = URL.createObjectURL(blob);
//         link.download = filename;
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//         setTimeout(() => URL.revokeObjectURL(link.href), 1000);
//       }
//     } catch (error) {
//       console.error('Download error:', error);
//     }
//   };

//   const paginatedRows = uploads.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

//   const handleChangePage = (event, newPage) => setPage(newPage);
//   const handleChangeRowsPerPage = event => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   return (
//     <StyledTableContainer component={Paper}>
//       <Table sx={{ minWidth: 650 }} aria-label="upload list table">
//         <TableHead>
//           <TableRow>
//             <StyledTableCell>Policy Number</StyledTableCell>
//             <StyledTableCell>Type</StyledTableCell>
//             <StyledTableCell>Date Uploaded</StyledTableCell>
//             <StyledTableCell>Document Name</StyledTableCell>
//             <StyledTableCell>Download</StyledTableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {paginatedRows.map((upload, index) => (
//             <StyledTableRow key={index}>
//               <StyledTableCell>{upload.policy}</StyledTableCell>
//               <StyledTableCell>{upload.type}</StyledTableCell>
//               <StyledTableCell>{upload.date}</StyledTableCell>
//               <StyledTableCell>{upload.name}</StyledTableCell>
//               <StyledTableCell>
//                 <StyledButton
//                   variant="outlined"
//                   startIcon={<NoteAddIcon />}
//                   onClick={() => handleDownload(userId, upload.name, upload.fileType)}
//                 >
//                   Download
//                 </StyledButton>
//               </StyledTableCell>
//             </StyledTableRow>
//           ))}
//         </TableBody>
//       </Table>
//       <TablePagination
//         component="div"
//         count={uploads.length}
//         page={page}
//         onPageChange={handleChangePage}
//         rowsPerPage={rowsPerPage}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//         rowsPerPageOptions={[5, 10, 15]}
//       />
//     </StyledTableContainer>
//   );
// };

// export default UploadList;


import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
  Paper,
  Button,
  TablePagination,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PreviewIcon from '@mui/icons-material/Preview';
import { getAllUploadAttachmentsAction, previewFileAction } from '../../redux/actions/uploadAttachmentActions';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 'bold',
    padding: '12px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '10px',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  margin: '16px auto',
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius,
  maxWidth: '90vw',
  border: `2px solid ${theme.palette.divider}`,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.primary.main,
  color: theme.palette.primary.main,
  textTransform: 'none',
  '&:hover': {
    borderColor: theme.palette.primary.dark,
    backgroundColor: theme.palette.action.hover,
  },
}));

const UploadList = () => {
  const dispatch = useDispatch();
  const userId = useSelector(state => state.user?.user?.id);
  const uploads = useSelector(state => state.uploadAttachment.allAttachments);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [previewData, setPreviewData] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);

  useEffect(() => {
    dispatch(getAllUploadAttachmentsAction(userId));
  }, [dispatch, userId]);

  const handleDownload = async (userId, filename, fileExtension) => {
    try {
      const result = await dispatch(previewFileAction(userId, filename));
      if (result?.blob) {
        const mimeTypeMap = {
          pdf: 'application/pdf',
          png: 'image/png',
          jpg: 'image/jpeg',
          jpeg: 'image/jpeg',
          docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        };
        const mimeType = mimeTypeMap[fileExtension] || 'application/octet-stream';
        const blob = new Blob([result.blob], { type: mimeType });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(() => URL.revokeObjectURL(link.href), 1000);
      }
    } catch (error) {
      console.error('Download error:', error);
    }
  };

  const handlePreview = async (userId, filename, fileExtension) => {
    try {
      const result = await dispatch(previewFileAction(userId, filename));
      if (result?.blob) {
        const mimeTypeMap = {
          pdf: 'application/pdf',
          png: 'image/png',
          jpg: 'image/jpeg',
          jpeg: 'image/jpeg',
          docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        };
        const mimeType = mimeTypeMap[fileExtension] || 'application/octet-stream';
        const blob = new Blob([result.blob], { type: mimeType });
        const blobUrl = URL.createObjectURL(blob);
        setPreviewData({ url: blobUrl, type: mimeType });
        setOpenPreview(true);
      }
    } catch (error) {
      console.error('Preview error:', error);
    }
  };

  const handleClosePreview = () => {
    if (previewData?.url) {
      URL.revokeObjectURL(previewData.url);
    }
    setOpenPreview(false);
    setPreviewData(null);
  };

  const paginatedRows = uploads.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <StyledTableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="upload list table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Policy Number</StyledTableCell>
            <StyledTableCell>Type</StyledTableCell>
            <StyledTableCell>Date Uploaded</StyledTableCell>
            <StyledTableCell>Document Name</StyledTableCell>
            <StyledTableCell>Download</StyledTableCell>
            <StyledTableCell>Preview</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedRows.map((upload, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>{upload.policy}</StyledTableCell>
              <StyledTableCell>{upload.type}</StyledTableCell>
              <StyledTableCell>{upload.date}</StyledTableCell>
              <StyledTableCell>{upload.name}</StyledTableCell>
              <StyledTableCell>
                <StyledButton
                  id={`downloadbutton${index}`} 
                  name="downloadbutton" 
                  variant="outlined"
                  startIcon={<NoteAddIcon />}
                  onClick={() => handleDownload(userId, upload.name, upload.fileType)}
                >
                  Download
                </StyledButton>
              </StyledTableCell>
              <StyledTableCell>
                <IconButton
                  id={`previewbutton${index}`} 
                  name="previewbutton" 
                  onClick={() => handlePreview(userId, upload.name, upload.fileType)}
                  color="primary"
                >
                  <PreviewIcon />
                </IconButton>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={uploads.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 15]}
      />

      <Dialog open={openPreview} onClose={handleClosePreview} maxWidth="md" fullWidth>
        <DialogTitle>File Preview</DialogTitle>
        <DialogContent>
          {previewData && previewData.type.startsWith('image/') && (
            <img
              id="previewimage"
              src={previewData.url}
              alt="File preview"
              style={{ width: '100%', height: 'auto' }}
            />
          )}
          {previewData && previewData.type === 'application/pdf' && (
            <iframe
              id="previewpdf" 
              src={previewData.url}
              title="PDF preview"
              style={{ width: '100%', height: '500px' }}
            />
          )}
          {/* Handle other file types accordingly */}
        </DialogContent>
      </Dialog>
    </StyledTableContainer>
  );
};

export default UploadList;


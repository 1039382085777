
import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TablePagination,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddInsuranceCarrier from "./AddInsuranceCarrier";
import { getCarrierAction, deleteCarrierByIdAction, deleteCarrierListingByIdAction } from "../../../redux/actions/agencyActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: '#1565c0', // Set header background color to #1565c0
  color: theme.palette.common.white,
  fontFamily: '"Poppins", sans-serif',
}));
 
 
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover, // Odd rows have a subtle background color
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected, // Row background changes on hover
  },
  '& td, & th': {
    padding: theme.spacing(1.5),
    fontFamily: '"Poppins", sans-serif', // Poppins font applied to all cells
  },
}));

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 20,
    padding: 16,
    borderRadius: 8,
  },
  table: {
    minWidth: 650,
  },
  tableCell: {
    fontWeight: "bold",
    backgroundColor: "#b0b0b0",
    color: "white",
    textAlign: "center",
  },
  tableRow: {
    '&:hover': {
      backgroundColor: "#f1f1f1",
    },
  },
  iconButton: {
    marginRight: 8,
    '&:hover': {
      backgroundColor: "#e0e0e0",
    },
  },
  addButton: {
    backgroundColor: "#1976d2",
    color: "white",
    borderRadius: 8,
    textTransform: "none",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: "#1565c0",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  tableContainer: {
    borderRadius: 8,
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
  },
  dialogContent: {
    backgroundColor: "#f8f8f8",
  },
  deleteButton: {
    backgroundColor: "#d32f2f",
    color: "white",
    "&:hover": {
      backgroundColor: "#c62828",
    },
  },
  cancelButton: {
    backgroundColor: "#757575",
    color: "white",
    "&:hover": {
      backgroundColor: "#616161",
    },
  },
}));

const ManageCarrierListing = ({ toggleForm }) => {
  const classes = useStyles();
  const [editingCarrier, setEditingCarrier] = useState(null);
  const [formVisible, setFormVisible] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deletingCarrierId, setDeletingCarrierId] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const carrierDetails = useSelector((state) => state.agency.allCarrier);

  const carriers = carrierDetails || [];

  useEffect(() => {
    dispatch(getCarrierAction(page, rowsPerPage));
  }, [dispatch, page, rowsPerPage]);

  const handleEdit = (carrier) => {
    setSelectedUser(carrier);
    navigate('/Addinsurancecarrier', {
      state: {
        selectedUser: carrier,
      },
    });
  };

  const handleCloseForm = () => {
    setFormVisible(false);
    setEditingCarrier(null);
  };

  const handleDeleteOpen = (id) => {
    setDeletingCarrierId(id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {    
    setOpenDelete(false);
  };

  const handleDeleteConfirm = async () => {
    try {
      await dispatch(deleteCarrierListingByIdAction(deletingCarrierId));      
      dispatch(getCarrierAction());            
      setOpenDelete(false);
    } catch (error) {
      console.error("Error deleting carrier:", error);
    }
  };

  const addNewInsuranceCarrier = () => {
    navigate('/Addinsurancecarrier')
  }

  const handleFormClose = () => {
    setSelectedUser(null);
    setIsEditing(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRows = Array.isArray(carriers)
  ? carriers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  : [];

  return (
      <>
          <Box className={classes.header}>
            <Typography variant="h5" sx={{ fontFamily: '"Poppins", sans-serif', fontWeight: '500' }} gutterBottom>
              Manage Carrier Listing
            </Typography>
            <Button variant="contained" sx={{ background: '#1565c0', fontFamily: '"Poppins", sans-serif' }} className={classes.addButton} onClick={addNewInsuranceCarrier}>
              Add New
            </Button>
          </Box>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <StyledTableCell className={classes.StyledTableCell}>Company Name</StyledTableCell>
                  <StyledTableCell className={classes.StyledTableCell}>Program Name</StyledTableCell>
                  <StyledTableCell className={classes.StyledTableCell}>Producer Code</StyledTableCell>
                  <StyledTableCell className={classes.StyledTableCell}>Last Activity</StyledTableCell>
                  <StyledTableCell align="right" className={classes.StyledTableCell}>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedRows.map((row) => (
                  <StyledTableRow key={row.id} className={classes.tableRow}>
                    <TableCell>{row.agencyName}</TableCell>
                    <TableCell>{row.carrierProgramDescription}</TableCell>
                    <TableCell>{row.producerCode}</TableCell>
                    <TableCell>{new Date(row.updatedTimeStamp).toLocaleDateString("en-CA")}</TableCell>
                    <TableCell align="right">
                      <IconButton aria-label="edit" className={classes.iconButton} onClick={() => handleEdit(row)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton aria-label="delete" className={classes.iconButton} onClick={() => handleDeleteOpen(row.agencyId)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Dialog open={openDelete} onClose={handleCloseDelete}>
            <DialogTitle>Delete Carrier</DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <Typography>Are you sure you want to delete this carrier?</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDelete} className={classes.cancelButton}>
                Cancel
              </Button>
              <Button onClick={handleDeleteConfirm} className={classes.deleteButton}>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        
      <TablePagination
        component="div"
        count={carriers.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={carriers.length === 0 ? [] : [5, 10, 15]}
      />
      </>
  );
};

export default ManageCarrierListing;


const initialState = {
    dropDowns: {},
    error: null,
  };
  
  const dropDownReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_DYNAMIC_DROPDOWNS_SUCCESS':
            return { dropDowns: action.payload, error: null };
        case 'SAVE_MASTER_DYNAMIC_DROPDOWNS_SUCCESS':
        case 'SAVE_DEPENENDENT_DYNAMIC_DROPDOWNS_SUCCESS':
            return { ...state, error: null };
        case 'GET_DYNAMIC_DROPDOWNS_FAILURE':
        case 'SAVE_MASTER_DYNAMIC_DROPDOWNS_FAILUTE':
        case 'SAVE_DEPENENDENT_DYNAMIC_DROPDOWNS_FAILUTE':
            return { ...state, error: action.payload };
        default:
            return state;
    }
  };
  
  export default dropDownReducer;
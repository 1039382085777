import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Button,
  TextField,
  Box,
  Alert,
  Snackbar,
  Typography,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  alphanumericWithSpaceValidation,
  validateEmail,
  validateField,
  zipCodeValidation,
} from "../../common/helpers/validation";
import { CircleOutlined } from "@mui/icons-material";
import {
  AntiTheft,
  CommonYesNo,
  LeasedVehicle,
  Make,
  ModelYear,
  Rental,
  Usage,
  comp,
  primaryoperator,
  towing,
  vehBodyType,
} from "../../common/StaticFieldList";
import {
  createVehicleInfo,
  getAllVehicleInfo,
  getVehicleInfoByVIN,
  updateVehicleInfo,
  getVehicleInfoActionByYmsYears,
  getVehicleInfoActionByYmsModels,
  getVehicleInfoActionByYmsMakes,
  getVehicleInfoActionByYmsVins,
  getVehicleInfoActionByYmsStyles,
} from "../../redux/actions/vehicleInfoActions";
import { populateValue } from "../../common/helpers/utils";

const requiredFields = ["car_type", "model_year", "zipcode"];

const validationRules = [
  { fieldName: "vin", label: "VIN", type: "text", apiCall: true, id: "vin" },
  {
    fieldName: "modelYear",
    label: "Model Year",
    type: "select",
    option: ModelYear,
    id: "modelyear",
  },

  {
    fieldName: "manufacturer",
    label: "Make",
    type: "select",
    option: Make,
    id: "manufacturer",
  },
  { fieldName: "model", label: "Model", id: "model" },
  {
    fieldName: "vehBodyType",
    label: "Body Type",
    type: "select",
    option: vehBodyType,
    id: "vehbodytype",
  },
  {
    fieldName: "zipcode",
    label: "Garaging Zip code",
    type: "number",
    id: "zipcode",
  },
];

const validationRulesGeneral = [
  {
    fieldName: "comp",
    label: "Comp",
    type: "select",
    option: comp,
    id: "comp",
  },
  {
    fieldName: "coll",
    label: "Coll",
    type: "select",
    option: comp,
    id: "coll",
  },
  {
    fieldName: "roadside",
    label: "Roadside",
    type: "select",
    option: towing,
    id: "roadside",
  },
  {
    fieldName: "rental",
    label: "Rental",
    type: "select",
    option: Rental,
    id: "rental",
  },
];

// const validationRulesQuote = [
//   {
//     fieldName: "usage",
//     label: "Usage",
//     type: "select",
//     option: Usage,
//     id: "usage",
//   },
//   {
//     fieldName: "rideShare",
//     label: "Ride Share",
//     type: "select",
//     option: CommonYesNo,
//     id: "rideshare",
//   },
//   {
//     fieldName: "primaryOperator",
//     label: "Primary Operator",
//     type: "select",
//     option: primaryoperator,
//     id: "primaryoperator",
//   },
//   {
//     fieldName: "milesDrivenToWork",
//     label: "Miles Driven to Work",
//     type: "number",
//     id: "milesdriventowork",
//   },
//   {
//     fieldName: "annualMilesDriven",
//     label: "Annual Miles Driven",
//     type: "number",
//     id: "annualmilesdriven",
//   },
//   {
//     fieldName: "purchaseDate",
//     label: "Purchase Date",
//     type: "date",
//     id: "purchasedate",
//   },
//   {
//     fieldName: "salvaged",
//     label: "Salvaged/Rebuilt",
//     type: "select",
//     option: CommonYesNo,
//     id: "salvaged",
//   },
//   {
//     fieldName: "leasedVehicle",
//     label: "Ownership Type",
//     type: "select",
//     option: LeasedVehicle,
//     id: "leasedvehicle",
//   },
//   {
//     fieldName: "antiTheft",
//     label: "Anti Theft",
//     type: "select",
//     option: AntiTheft,
//     id: "antitheft",
//   },
//   { fieldName: "modified", label: "Modified", type: "text", id: "modified" },
// ];

// const validationRulesAdditionalAttributes = [
//   { fieldName: "symbol", label: "Symbol", type: "text", id: "symbol" },
//   {
//     fieldName: "airBags",
//     label: "Air Bags",
//     type: "select",
//     option: CommonYesNo,
//     id: "airbags",
//   },
//   {
//     fieldName: "antiLockBrakes",
//     label: "Anti-lock Brakes",
//     type: "text",
//     id: "antilockbrakes",
//   },
//   {
//     fieldName: "runningLights",
//     label: "Running Lights",
//     type: "select",
//     option: CommonYesNo,
//     id: "runninglights",
//   },
// ];

const validationFunctions = {
  firstName: alphanumericWithSpaceValidation,
  lastName: alphanumericWithSpaceValidation,
  email: validateEmail,
  zipcode: zipCodeValidation,
};

const useStyles = makeStyles((theme) => ({
  formContainer: {
    // marginBottom: theme.spacing(2),
  },
  select: {
    minWidth: 150,
  },

  centerContent: {
    display: "flex",
    alignItems: "center",
    // marginTop: '.5rem !important',
  },
  fullWidth: {
    width: "100%",
  },
}));

const VehicleSave = ({
  setShowHideVehicleForm,
  vehicleData,
  handleNext,
  handleBack,
  showVehicle,
  handleSubmitVehicleInfo,
  handleVehicleData,
  handleForward,
}) => {
  // const classes = useStyles();
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  let { quoteId } = useParams();
  const [selectedOption, setSelectedOption] = React.useState("vin");
  // const [selectedOption, setSelectedOption] = React.useState(
  //   vehicleData?.id ? "yms" : "vin"
  // );

  const years = useSelector((state) => state.vehicleInfo.years);
  const Make = useSelector((state) => state.vehicleInfo.makes);
  const models = useSelector((state) => state.vehicleInfo.models);
  const vehBodyType = useSelector((state) => state.vehicleInfo.styles);
  const vins = useSelector((state) => state.vehicleInfo.vins);
  const validationRulesyms = [
    {
      fieldName: "modelYear",
      label: "Model Year",
      type: "select",
      option: years,
      id: "modelyear",
    },

    {
      fieldName: "manufacturer",
      label: "Make",
      type: "select",
      option: Make,
      id: "manufacturer",
    },
    { fieldName: "model", label: "Model", type: "select", option: models, id: "model" },
    {
      fieldName: "vehBodyType",
      label: "Body Type",
      type: "select",
      option: vehBodyType,
      id: "vehbodytype",
    },
    { fieldName: "vin", label: "VIN",  type: "select", option: vins,apiCall: true, id: "vin" },
    {
      fieldName: "zipcode",
      label: "Garaging Zip code",
      type: "number",
      id: "zipcode",
    },
  ];


  // const handleOptionChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };
  // const [vehicleData, setVehicleData] = useState({})
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const loading = useSelector((state) => state.loading.CREATE_USER);
  const isModalOpen = useSelector(
    (state) => state?.user?.isCreateUserModalOpen
  );
  // const [isCreateModalOpen, setIsCreateModalOpen] = useState(isModalOpen);
  // const newFieldArg = [
  //   ...validationRules,
  //   ...validationRulesGeneral,
  //   ...validationRulesQuote,
  //   ...validationRulesAdditionalAttributes,
  // ];
  const newFieldArg = [
    ...validationRules,
    ...validationRulesGeneral,
    validationRulesyms,
    // ...validationRulesQuote,
    // ...validationRulesAdditionalAttributes,
  ];

  const [formState, setFormState] = useState({
    formData: newFieldArg.reduce(
      (fields, { fieldName }) => ({
        ...fields,
        [fieldName]: "",
      }),
      {}
    ),
    touchedFields: newFieldArg.reduce(
      (fields, { fieldName }) => ({
        ...fields,
        [fieldName]: false,
      }),
      {}
    ),
    fieldErrors: newFieldArg.reduce(
      (errors, { fieldName }) => ({
        ...errors,
        [fieldName]: "",
      }),
      {}
    ),
  });

  // const user = useSelector((state) => state.user);
  const selectedUserId = useSelector((state) => state.user.selectedUserId);
  const allUser = useSelector((state) => state?.user?.allUser);

  // const customerDataForUpdate =
  //   allUser &&
  //   allUser.length &&
  //   allUser.filter((user) => user.id === selectedUserId);
  // const customerForUpdate = customerDataForUpdate && customerDataForUpdate[0];

  const { formData, touchedFields, fieldErrors } = formState;
  // const [formDatas, setFormDatas] = useState({
  //   modelYear: '',
  //   manufacturer: '',
  //   model: '',
  //   vehBodyType: '',
  //   vin: ''
  // });

  const [formDatas, setFormDatas] = useState({
    modelYear: vehicleData?.modelYear || '',
    manufacturer: vehicleData?.manufacturer || '',
    model: vehicleData?.model || '',
    vehBodyType: vehicleData?.vehBodyType || '',
    vin: vehicleData?.vin || '',
    zipcode: vehicleData?.zipcode || ''
  });

  // console.log("makes", Make);
  // console.log("models", models);
  // console.log("vehBodyType", vehBodyType);
  // console.log("vins", vins);


  useEffect(() => {
    dispatch(getVehicleInfoActionByYmsYears());
  }, [dispatch]);

    // Fetch makes when year changes
    useEffect(() => {
      if (formDatas.modelYear) {
        dispatch(getVehicleInfoActionByYmsMakes(formDatas.modelYear));
        // Clear dependent fields
        setFormDatas(prev => ({
          ...prev,
          manufacturer: '',
          model: '',
          vehBodyType: '',
          vin: ''
        }));
      }
    }, [formDatas.modelYear, dispatch]);

    // Fetch models when manufacturer changes
  useEffect(() => {
    if (formDatas.modelYear && formDatas.manufacturer) {
      dispatch(getVehicleInfoActionByYmsModels(formDatas.modelYear, formDatas.manufacturer));
      // Clear dependent fields
      setFormDatas(prev => ({
        ...prev,
        model: '',
        vehBodyType: '',
        vin: ''
      }));
    }
  }, [formDatas.manufacturer, formDatas.modelYear, dispatch]);

   // Fetch body types when model changes
   useEffect(() => {
    if (formDatas.modelYear && formDatas.manufacturer && formDatas.model) {
      dispatch(getVehicleInfoActionByYmsStyles(
        formDatas.modelYear,
        formDatas.manufacturer,
        formDatas.model
      ));
      // Clear dependent field
      setFormDatas(prev => ({
        ...prev,
        vehBodyType: '',
        vin: ''
      }));
    }
  }, [formDatas.model, formDatas.manufacturer, formDatas.modelYear, dispatch]);

   // Fetch VINs when body type changes
   useEffect(() => {
    if (formDatas.modelYear && formDatas.manufacturer && formDatas.model && formDatas.vehBodyType) {
      dispatch(getVehicleInfoActionByYmsVins(
        formDatas.modelYear,
        formDatas.manufacturer,
        formDatas.model,
        formDatas.vehBodyType
      ));
    }
  }, [formDatas.vehBodyType, formDatas.model, formDatas.manufacturer, formDatas.modelYear, dispatch]);

  const [touchedFieldyms, setTouchedFieldyms] = useState({});



  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);


    // When switching to YMS, ensure the YMS form data is populated from the VIN form data
    if (event.target.value === 'vin' && formState.formData) {
      setFormDatas({
        modelYear: formState.formData.modelYear || '',
        manufacturer: formState.formData.manufacturer || '',
        model: formState.formData.model || '',
        vehBodyType: formState.formData.vehBodyType || '',
        vin: formState.formData.vin || '',
        zipcode: formState.formData.zipcode || ''
      });
    }
    // When switching to VIN, ensure the VIN form data is populated from the YMS form data
    else if (event.target.value === 'yms' && formDatas) {
      setFormState(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          modelYear: formDatas.modelYear,
          manufacturer: formDatas.manufacturer,
          model: formDatas.model,
          vehBodyType: formDatas.vehBodyType,
          vin: formDatas.vin,
          zipcode: formDatas.zipcode
        }
      }));
    }
  };


  const handleInputChangeyms = (e) => {
    const { name, value } = e.target;
    setFormDatas(prev => ({
      ...prev,
      [name]: value
    }));

    // Clear dependent fields based on which field changed
    if (name === 'modelYear') {
      setFormDatas(prev => ({
        ...prev,
        manufacturer: '',
        model: '',
        vehBodyType: '',
        vin: ''
      }));
    } else if (name === 'manufacturer') {
      setFormDatas(prev => ({
        ...prev,
        model: '',
        vehBodyType: '',
        vin: ''
      }));
    } else if (name === 'model') {
      setFormDatas(prev => ({
        ...prev,
        vehBodyType: '',
        vin: ''
      }));
    } else if (name === 'vehBodyType') {
      setFormDatas(prev => ({
        ...prev,
        vin: ''
      }));
    }
  };


  const handleBluryms = (fieldName) => {
    setTouchedFieldyms(prev => ({
      ...prev,
      [fieldName]: true
    }));

    // validateField(fieldName, formData[fieldName]);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  // const handleSelectChange = (value, name) => {
  //   setFormState((prevState) => ({
  //     ...prevState,
  //     formData: {
  //       ...prevState.formData,
  //       [name]: value,
  //     },
  //   }));
  // };

  const handleVinPopulate = async (value) => {
    if (!value) {
      // Clear all VIN-related fields if VIN is removed
      setFormState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          manufacturer: "",
          model: "",
          modelYear: "",

        },
      }));
      return;
    }
    // "5TDJZRFH4HS403872"
    const response = await dispatch(getVehicleInfoByVIN(value, ""));
    if (response && response.data) {
      setFormState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          manufacturer: response.data.make,
          model: response.data.model,
          modelYear: response.data.year,

        },
      }));
    } else {
      handleSnackbarOpen(
        `The requested VIN \"${value}"\ is too short. VINs should be at least 5 characters long, with most modern VINs being 11-17 characters long. The requested VIN is 2 characters`,
        "error"
      );
    }
  };

  const handleBlur = (field) => {
    if (field === "vin") {
      handleVinPopulate(formData[field]);
    }
    setFormState((prevFormState) => ({
      ...prevFormState,
      touchedFields: {
        ...prevFormState.touchedFields,
        [field]: true,
      },
    }));

    if (!requiredFields.includes(field)) {
      return;
    }

    const value = formData[field];
    const error = validateField(
      value,
      newFieldArg.find((rule) => rule.fieldName === field).label
    );

    // Additional validations
    const additionalError = validationFunctions[field]
      ? validationFunctions[field](value)
      : "";

    setFormState((prevFormState) => ({
      ...prevFormState,
      fieldErrors: {
        ...prevFormState.fieldErrors,
        [field]: error || additionalError,
      },
    }));
  };

  const handleSnackbarClose = () => {
    setSnackbar((prevSnackbar) => ({
      ...prevSnackbar,
      open: false,
    }));
  };

  const handleSnackbarOpen = (message, severity) => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };
  const handleFormSubmit = (event) => {
    event.preventDefault();

    handleSubmitVehicleInfo(formData); // Call the handleSubmit function with form data
    // handleVehicleData(vehicleData);
    console.log("Raushan")
    handleForward();
  };
  // console.log("formData", formData);
  // console.log("vehicleData", vehicleData);
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Determine which form data to use based on selected option
    const dataToSubmit = selectedOption === 'vin' ? formData : formDatas;

    // Validate required fields based on selected option
    const fieldsToValidate = selectedOption === 'vin' ? validationRules : validationRulesyms;

    const validationErrors = fieldsToValidate.reduce((errors, { fieldName, label }) => {
      if (requiredFields.includes(fieldName)) {
        const value = dataToSubmit[fieldName];
        const error = validateField(value, label);

        // Additional validations if they exist for the field
        const additionalError = validationFunctions[fieldName]
          ? validationFunctions[fieldName](value)
          : "";

        setFormState((prevFormState) => ({
          ...prevFormState,
          fieldErrors: {
            ...prevFormState.fieldErrors,
            [fieldName]: error || additionalError,
          },
        }));

        return {
          ...errors,
          [fieldName]: error || additionalError,
        };
      }
      return errors;
    }, {});

    const hasErrors = Object.values(validationErrors).some((error) => !!error);

    if (!hasErrors) {
      try {
        // Prepare the form data combining both sources and adding necessary IDs
        const combinedFormData = {
          // ...(selectedOption === 'vin' ? formData : {}),
          // ...(selectedOption === 'yms' ? formDatas : {}),
          ...(selectedOption === 'yms' ? { ...formDatas, comp: formData.comp, coll: formData.coll, roadside: formData.roadside, rental: formData.rental } : formData),

          gpId: quoteId
        };

        // If updating existing vehicle
        if (vehicleData.id) {
          combinedFormData.id = vehicleData.id;
          await dispatch(updateVehicleInfo(combinedFormData, getCreateUserSuccess));
        }
        // If creating new vehicle
        else {
          await dispatch(createVehicleInfo(combinedFormData, getCreateUserSuccess));
        }
      } catch (error) {
        handleSnackbarOpen(
          error.message || "Vehicle service failed. Please try again.",
          "error"
        );
      }
    } else {
      handleSnackbarOpen(
        "Please fill in all required fields correctly.",
        "error"
      );
    }
    handleNext();
  };
  const getCreateUserSuccess = async () => {
    // selectedUserId
    //   ? handleSnackbarOpen("User updated successfully.", "success")
    //   :
    handleSnackbarOpen("Vehicle created successfully.", "success");
    setShowHideVehicleForm(false);
    await dispatch(getAllVehicleInfo(quoteId));

    setFormState({
      formData: newFieldArg.reduce(
        (fields, { fieldName }) => ({
          ...fields,
          [fieldName]: "",
        }),
        {}
      ),
      touchedFields: newFieldArg.reduce(
        (fields, { fieldName }) => ({
          ...fields,
          [fieldName]: false,
        }),
        {}
      ),
      fieldErrors: newFieldArg.reduce(
        (errors, { fieldName }) => ({
          ...errors,
          [fieldName]: "",
        }),
        {}
      ),
    });
  };
// console.log("vheicledata",vehicleData)
  // useEffect(() => {
  //   setFormState((prevFormState) => ({
  //     ...prevFormState,
  //     formData: newFieldArg.reduce(
  //       (fields, { fieldName }) => ({
  //         ...fields,
  //         [fieldName]:
  //           (vehicleData &&
  //             populateValue(vehicleData[fieldName], fieldName, newFieldArg)) ||
  //           "",
  //         // organizationId: generalPartyInfo?.organization?.id
  //       }),
  //       {}
  //     ),
  //   }));
  // }, [vehicleData]);

  useEffect(() => {
    if (vehicleData) {
      // Update formState for VIN form
      setFormState((prevFormState) => ({
        ...prevFormState,
        formData: newFieldArg.reduce(
          (fields, { fieldName }) => ({
            ...fields,
            [fieldName]:
              (vehicleData &&
                populateValue(vehicleData[fieldName], fieldName, newFieldArg)) ||
              "",
          }),
          {}
        ),
      }));

      // Update formDatas for YMS form
      // setFormDatas({
      //   modelYear: vehicleData.modelYear || '',
      //   manufacturer: vehicleData.manufacturer || '',
      //   model: vehicleData.model || '',
      //   vehBodyType: vehicleData.vehBodyType || '',
      //   vin: vehicleData.vin || '',
      //   zipcode: vehicleData.zipcode || ''
      // });
      setFormDatas((prevFormDatas) => ({
        ...prevFormDatas,
        modelYear: vehicleData.modelYear || '',
        manufacturer: vehicleData.manufacturer || '',
        model: vehicleData.model || '',
        vehBodyType: vehicleData.vehBodyType || '',
        vin: vehicleData.vin || '',
        zipcode: vehicleData.zipcode || ''
      }));
    }
  }, [vehicleData]);
  return (
    <>
      <Box

      >
        {/* <FrameComponent2 /> */}
        <Box
          sx={{
            alignSelf: "stretch",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "36px",
            maxWidth: "100%",
          }}
        >
          {/* <FrameComponent1 /> */}

          <section
            sx={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              padding: "0px 23px 0px 20px",
              boxSizing: "border-box",
              maxWidth: "100%",
              textAlign: "left",
              fontSize: "16px",
              color: "#242929",
              fontFamily: '"Poppins", sans-serif',
            }}
          >
            <Box
              id="form"
              component="form"
              onSubmit={handleFormSubmit}
              sx={{
                width: "853px",
                borderRadius: "4px",
                backgroundColor: "#fff",
                border: "1px solid #e3e3e3",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                padding: "30px 0px 24px",
                gap: "23px",
                maxWidth: "100%",
              }}
            >
              <Box
                sx={{
                  alignSelf: "stretch",
                  height: "798px",
                  position: "relative",
                  borderRadius: "4px",
                  backgroundColor: "#fff",
                  border: "1px solid #e3e3e3",
                  boxSizing: "border-box",
                  display: "none",
                }}
              />

              <Typography
                id="vehicleinformation"
                variant="h1" // Adjust the variant as needed, e.g., h1, h2, h3, etc.
                component="div"
                sx={{
                  border: "none",
                  backgroundColor: "transparent",
                  alignSelf: "stretch",
                  height: "51px",
                  fontFamily: '"Poppins", sans-serif',
                  fontWeight: "600",
                  fontSize: "25px",
                  color: "#242929",
                  paddingLeft: "50px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Vehicle Information
              </Typography>
              <hr style={{ borderTop: "1px solid #ccc", width: "100%" }} />
              <Box
                sx={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                  padding: "0px 44px 0px 52px",
                  boxSizing: "border-box",
                  maxWidth: "100%",
                }}
              >
                <Box
                  sx={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "16px",
                    maxWidth: "100%",
                    fontFamily: '"Poppins", sans-serif',
                  }}
                >
                  <Box
                    sx={{
                      flex: "1",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "4px",
                      minWidth: "240px",
                      maxWidth: "100%",
                    }}
                  >
                    {/* Add radio button having name vin and yms */}

                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        name="search-option"
                        value={selectedOption}
                        onChange={handleOptionChange}
                        sx={{
                          marginBottom: "16px",
                        }}
                      >
                        <FormControlLabel
                          value="vin"
                          control={<Radio />}
                          label="VIN"
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontFamily: '"Poppins", sans-serif',
                              fontWeight: "500",
                            },
                          }}

                        />
                        <FormControlLabel
                          value="yms"
                          control={<Radio />}
                          label="YMS"

                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontFamily: '"Poppins", sans-serif',
                              fontWeight: "500",
                            },
                          }}

                        />
                      </RadioGroup>
                    </FormControl>
                    <Box
                      sx={{
                        alignSelf: "stretch",
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "5px 20px",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        padding: "0px 0px 8px",
                      }}
                    >
                      {selectedOption === "vin"
                        ? validationRules.map(
                            ({ fieldName, label, type, option }, index) => (
                              <Box
                                key={index}
                                sx={{
                                  width: "100%",
                                  margin: "0 0 15px 0",
                                  display: "flex",
                                  flexFlow: "column",
                                }}
                              >
                                <Typography
                                  key={fieldName} // Assign a unique key to each element
                                  variant="body1"
                                  sx={{
                                    position: "relative",
                                    lineHeight: "40px",
                                    fontWeight: "500",
                                    display: "inline-block",
                                    minWidth: "83px",
                                    zIndex: "1",
                                    fontFamily: '"Poppins", sans-serif',
                                  }}
                                >
                                  {label}
                                </Typography>
                                <TextField
                                  style={{
                                    border: "none",
                                    backgroundColor: "transparent",
                                    alignSelf: "stretch",
                                    height: "52px",
                                    fontFamily: '"Poppins", sans-serif',
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    color: "#242929",
                                    zIndex: "1",
                                  }}
                                  type={type}
                                  variant="outlined"
                                  select={type === "select"}
                                  required={requiredFields.includes(fieldName)}
                                  id={fieldName}
                                  // label={label}
                                  placeholder={label}
                                  name={fieldName}
                                  autoComplete={fieldName}
                                  value={formData[fieldName]}
                                  // onChange={(e) => handleInputChange(e)}
                                  onChange={(e) => {
                                    const val = e.target.value;
                                    if (
                                      type === "text" &&
                                      e.target.value.length > 17
                                    ) {
                                      return;
                                    }
                                    if (
                                      fieldName === "vin" &&
                                      !/^[A-Za-z0-9]*$/.test(e.target.value)
                                    ) {
                                      return;
                                    }
                                    if (fieldName === "zipcode") {
                                      // Allow only digits
                                      if (!/^\d*$/.test(val)) {
                                        return; // Prevent input if not a digit
                                      }

                                      // Restrict length to a maximum of 9 digits
                                      if (val.length > 9) {
                                        return;
                                      }
                                    }
                                    if (
                                      fieldName === "model" &&
                                      (!/^[A-Za-z0-9]*$/.test(val) ||
                                        val.length > 50)
                                    ) {
                                      return;
                                    }
                                    handleInputChange(e);
                                  }}
                                  onBlur={() => handleBlur(fieldName)}
                                  error={
                                    touchedFields[fieldName] &&
                                    !!fieldErrors[fieldName]
                                  }
                                  helperText={
                                    touchedFields[fieldName]
                                      ? fieldErrors[fieldName]
                                      : ""
                                  }
                                  sx={{
                                    "& fieldset": {
                                      borderColor: "#C1C1C1",
                                      fontFamily: '"Poppins", sans-serif',
                                    },
                                    "& .MuiInputBase-root": {
                                      height: "52px",
                                      fontFamily: '"Poppins", sans-serif',
                                      backgroundColor: "#fff",
                                      fontSize: "16px",
                                    },
                                    "& .MuiInputBase-input": {
                                      color: "#242929",
                                      fontFamily: '"Poppins", sans-serif',
                                    },
                                  }}
                                >
                                  {type === "select" &&
                                    option.map((option) => (
                                      <MenuItem
                                        key={option.value}
                                        placeholder={label}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                </TextField>
                              </Box>
                            )
                          )
                        : validationRulesyms.map(({ fieldName, label, type, option }, index) => (
                          <Box
                            key={index}
                            sx={{
                              width: "100%",
                              margin: "0 0 15px 0",
                              display: "flex",
                              flexFlow: "column",
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{
                                position: "relative",
                                lineHeight: "40px",
                                fontWeight: "500",
                                display: "inline-block",
                                minWidth: "83px",
                                zIndex: "1",
                                fontFamily: '"Poppins", sans-serif',
                              }}
                            >
                              {label}
                            </Typography>
                            <TextField
                              style={{
                                border: "none",
                                backgroundColor: "transparent",
                                alignSelf: "stretch",
                                height: "52px",
                                fontFamily: '"Poppins", sans-serif',
                                fontWeight: "500",
                                fontSize: "16px",
                                color: "#242929",
                                zIndex: "1",
                              }}
                              type={type}
                              variant="outlined"
                              select={type === "select"}
                              // required={requiredFields.includes(fieldName)}
                              id={fieldName}
                              placeholder={label}
                              name={fieldName}
                              autoComplete={fieldName}
                              value={formDatas[fieldName]}
                              onChange={handleInputChangeyms}
                              onBlur={() => handleBluryms(fieldName)}
                              error={touchedFields[fieldName] && !!fieldErrors[fieldName]}
                              helperText={touchedFields[fieldName] ? fieldErrors[fieldName] : ""}
                              disabled={
                                (fieldName === "manufacturer" && !formDatas.modelYear) ||
                                (fieldName === "model" && !formDatas.manufacturer) ||
                                (fieldName === "vehBodyType" && !formDatas.model) ||
                                (fieldName === "vin" && !formDatas.vehBodyType)
                              }
                              sx={{
                                "& fieldset": { borderColor: "#C1C1C1" },
                                "& .MuiInputBase-root": {
                                  height: "52px",
                                  backgroundColor: "#fff",
                                  fontSize: "16px",
                                },
                                "& .MuiInputBase-input": { color: "#242929" },
                              }}
                            >
                              {type === "select" &&
                                Array.isArray(option) &&
                                option.map((value) => (
                                  <MenuItem key={value} value={value}>
                                    {value}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </Box>
                        ))}
                    </Box>
                  </Box>

                </Box>
              </Box>

              {/* <Coverage /> */}
              <Typography
                id="coverages"
                variant="h1"
                component="div"
                sx={{
                  border: "none",
                  backgroundColor: "transparent",
                  alignSelf: "stretch",
                  height: "51px",
                  fontFamily: '"Poppins", sans-serif',
                  fontWeight: "600",
                  fontSize: "25px",
                  color: "#242929",
                  paddingLeft: "50px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Coverages
              </Typography>
              <hr style={{ borderTop: "1px solid #ccc", width: "100%" }} />
              <Box
                sx={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                  padding: "0px 44px 0px 52px",
                  boxSizing: "border-box",
                  maxWidth: "100%",
                }}
              >
                <Box
                  sx={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "16px",
                    maxWidth: "100%",
                    fontFamily: '"Poppins", sans-serif',
                  }}
                >
                  <Box
                    sx={{
                      flex: "1",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "4px",
                      minWidth: "240px",
                      maxWidth: "100%",
                      fontFamily: '"Poppins", sans-serif',
                    }}
                  >
                    <Box
                      sx={{
                        alignSelf: "stretch",
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "5px 20px",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        padding: "0px 0px 8px",
                      }}
                    >
                      {validationRulesGeneral.map(
                        ({ fieldName, label, type, option }, index) => (
                          <Box
                            key={index}
                            sx={{
                              width: "100%",
                              margin: "0 0 15px 0",
                              display: "flex",
                              flexFlow: "column",
                            }}
                          >
                            <Typography
                              key={fieldName} // Assign a unique key to each element
                              variant="body1"
                              sx={{
                                position: "relative",
                                lineHeight: "40px",
                                fontWeight: "500",
                                display: "inline-block",
                                minWidth: "83px",
                                zIndex: "1",
                                fontFamily: '"Poppins", sans-serif',
                              }}
                            >
                              {label}
                            </Typography>
                            <TextField
                              style={{
                                border: "none",
                                backgroundColor: "transparent",
                                alignSelf: "stretch",
                                height: "52px",
                                fontFamily: '"Poppins", sans-serif',
                                fontWeight: "500",
                                fontSize: "16px",
                                color: "#242929",
                                zIndex: "1",
                              }}
                              type={type}
                              variant="outlined"
                              select={type === "select"}
                              required={requiredFields.includes(fieldName)}
                              id={fieldName}
                              // label={label}
                              placeholder={label}
                              name={fieldName}
                              autoComplete={fieldName}
                              value={formData[fieldName]}
                              onChange={(e) => handleInputChange(e)}
                              // onBlur={() => handleBlur(fieldName)}
                              error={
                                touchedFields[fieldName] &&
                                !!fieldErrors[fieldName]
                              }
                              helperText={
                                touchedFields[fieldName]
                                  ? fieldErrors[fieldName]
                                  : ""
                              }
                              sx={{
                                "& fieldset": {
                                  borderColor: "#C1C1C1",
                                  fontFamily: '"Poppins", sans-serif',
                                },
                                "& .MuiInputBase-root": {
                                  height: "52px",
                                  backgroundColor: "#fff",
                                  fontSize: "16px",
                                  fontFamily: '"Poppins", sans-serif',
                                },
                                "& .MuiInputBase-input": {
                                  color: "#242929",
                                  fontFamily: '"Poppins", sans-serif',
                                },
                              }}
                            >
                              {type === "select" &&
                                option.map((option) => (
                                  <MenuItem
                                    sx={{ fontFamily: '"Poppins", sans-serif' }}
                                    key={option.value}
                                    placeholder={label}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </Box>
                        )
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>

              {showVehicle && (
                <>

                </>
              )}
              {!showVehicle ? (
                <>
                  <div
                    style={{
                      width: "222px",
                      height: "42px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "14px",
                      marginRight: "50px",
                    }}
                  >
                    <Button
                      id="backbutton"
                      style={{
                        flex: "none",
                        width: "auto",
                        cursor: "pointer",
                        zIndex: "1",
                      }}
                      startIcon={<ArrowBackIosIcon />}
                      disableElevation={true}
                      variant="outlined"
                      sx={{
                        textTransform: "none",
                        color: "#077BFD",
                        fontSize: "16px",
                        borderColor: "#077BFD",
                        fontFamily: '"Poppins", sans-serif',
                        borderRadius: "4px",
                        "&:hover": { borderColor: "#077BFD" },
                      }}
                      onClick={handleBack}
                    >
                      Back
                    </Button>

                    <Button
                      id="additionalinfo"
                      // type="submit"
                      style={{
                        flex: "none",
                        width: "auto",
                        fontFamily: '"Poppins", sans-serif',
                        cursor: "pointer",
                        zIndex: "1",
                      }}
                      endIcon={<ArrowForwardIosIcon />}
                      disableElevation={true}
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        color: "#fff",
                        fontSize: "16px",
                        background: "rgba(7, 123, 253, 0.6)",
                        fontFamily: '"Poppins", sans-serif',
                        borderRadius: "4px",
                        marginRight: "20px",
                        "&:hover": { background: "rgba(7, 123, 253, 1)" },
                      }}
                      // onClick={handleForward}
                      onClick={handleSubmit}
                    >
                      Additional Info
                    </Button>
                  </div>
                  <div
                    style={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      padding: "20px 20px 50px 23px",
                      fontFamily: '"Poppins", sans-serif',
                    }}
                  >
                    <Button
                      id="continue"
                      style={{
                        height: "42px",
                        width: "140px",
                        cursor: "pointer",
                        fontFamily: '"Poppins", sans-serif',
                      }}
                      disableElevation={true}
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        color: "#fff",
                        fontSize: "16px",
                        background: "#077BFD",
                        borderRadius: "4px",
                        fontFamily: '"Poppins", sans-serif',
                        "&:hover": { background: "#077BFD" },
                        width: 140,
                        height: 42,
                      }}
                      onClick={handleSubmit}
                    >
                      Continue
                    </Button>
                  </div>
                </>
              ) : (
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    id="cancel"
                    //   variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      marginRight: "1rem",
                      fontFamily: '"Poppins", sans-serif',
                    }}
                    onClick={() => setShowHideVehicleForm(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    id="submit"
                    type="submit"
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      marginRight: "1rem",
                      fontFamily: '"Poppins", sans-serif',
                    }}
                  >
                    {loading ? (
                      <CircleOutlined size="24" color="secondary" />
                    ) : (
                      "Next"
                    )}
                  </Button>
                </Box>
              )}
            </Box>

            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              open={snackbar.open}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
            >
              <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
                {snackbar.message}
              </Alert>
            </Snackbar>
          </section>
        </Box>
      </Box>
    </>
  );
};
export default VehicleSave;

import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  TextField,
  Box,
  Container,
  Snackbar,
  Alert,
  Grid,
  Typography,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  alphanumericWithSpaceValidation,
  validateEmail,
  validateField,
} from "../../common/helpers/validation";
import { CircleOutlined } from "@mui/icons-material";
import {
  AntiTheft,
  CityList,
  CommonNumbers,
  CommonYesNo,
  LeasedVehicle,
  Make,
  ModelYear,
  NewUsed,
  Rental,
  Usage,
  car_type,
  comp,
  primaryoperator,
  stateList,
  towing,
  vehBodyType,
} from "../../common/StaticFieldList";
import {
  createVehicleInfo,
  getAllVehicleInfo,
  getVehicleInfoByVIN,
  updateVehicleInfo,
} from "../../redux/actions/vehicleInfoActions";
import { populateValue } from "../../common/helpers/utils";

const requiredFields = ["car_type", "model_year", "zipcode"];

const validationRulesAdditionalAttributes = [
 
  { fieldName: "symbol", label: "Symbol", type: "text" , id: "symbol" },
  {
    fieldName: "airBags",
    label: "Air Bags",
    type: "select",
    option: CommonYesNo,
    id: "airBags",
  },
  { fieldName: "antiLockBrakes", label: "Anti-lock Brakes", type: "text" , id: "antiLockBrakes" },
  {
    fieldName: "runningLights",
    label: "Running Lights",
    type: "select",
    option: CommonYesNo,
    id: "runningLights",
  },
];

const validationFunctions = {
  firstName: alphanumericWithSpaceValidation,
  lastName: alphanumericWithSpaceValidation,
  email: validateEmail,
};

const useStyles = makeStyles((theme) => ({
  formContainer: {
    // marginBottom: theme.spacing(2),
  },
  select: {
    minWidth: 150,
  },
  centerContent: {
    display: "flex",
    alignItems: "center",
    // marginTop: '.5rem !important',
  },
  fullWidth: {
    width: "100%",
  },
}));

const VehicleAdditionalAttribute = ({
  setShowHideVehicleForm,
  vehicleData,
  handleNext,
  handleBack,
  vehicleInfoData,
  vehicleAttrData,
  handleBackward,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let { quoteId } = useParams();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const loading = useSelector((state) => state.loading.CREATE_USER);
  const isModalOpen = useSelector(
    (state) => state?.user?.isCreateUserModalOpen
  );
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(isModalOpen);
  const newFieldArg = [
    ...validationRulesAdditionalAttributes,
  ];

  const [formState, setFormState] = useState({
    formData: newFieldArg.reduce(
      (fields, { fieldName }) => ({
        ...fields,
        [fieldName]: "",
      }),
      {}
    ),
    touchedFields: newFieldArg.reduce(
      (fields, { fieldName }) => ({
        ...fields,
        [fieldName]: false,
      }),
      {}
    ),
    fieldErrors: newFieldArg.reduce(
      (errors, { fieldName }) => ({
        ...errors,
        [fieldName]: "",
      }),
      {}
    ),
  });

  const user = useSelector((state) => state.user);
  const selectedUserId = useSelector((state) => state.user.selectedUserId);
  const allUser = useSelector((state) => state?.user?.allUser);
  const customerDataForUpdate =
    allUser &&
    allUser.length &&
    allUser.filter((user) => user.id === selectedUserId);
  const customerForUpdate = customerDataForUpdate && customerDataForUpdate[0];

  const { formData, touchedFields, fieldErrors } = formState;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  const handleSelectChange = (value, name) => {
    setFormState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  const handleVinPopulate = async (value) => {
    // "5TDJZRFH4HS403872"
    const response = await dispatch(getVehicleInfoByVIN(value, ""));
    if (response && response.data) {
      setFormState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          manufacturer: response.data.make,
          model: response.data.model,
          modelYear: response.data.year,
          fuelType: response.data?.specs?.fuel_type_primary,
          fourWheelDrive:
            response.data?.specs?.number_of_wheels === "4" ? "Y" : "N",
          fourWheelSteering:
            response.data?.specs?.number_of_wheels === "4" ? "Y" : "N",
          airBags:
            response.data?.specs?.curtain_air_bag_locations === "All Rows"
              ? "Y"
              : "N",
          antiLockBrakes: response.data?.specs?.anti_lock_braking_system_abs,
          numberOfCylinders: response.data?.specs?.engine_number_of_cylinders,
        },
      }));
    } else {
      handleSnackbarOpen(
        `The requested VIN \"${value}"\ is too short. VINs should be at least 5 characters long, with most modern VINs being 11-17 characters long. The requested VIN is 2 characters`,
        "error"
      );
    }
  };

  const handleBlur = (field) => {
    if (field === "vin") {
      handleVinPopulate(formData[field]);
    }
    setFormState((prevFormState) => ({
      ...prevFormState,
      touchedFields: {
        ...prevFormState.touchedFields,
        [field]: true,
      },
    }));

    if (!requiredFields.includes(field)) {
      return;
    }

    const value = formData[field];
    const error = validateField(
      value,
      newFieldArg.find((rule) => rule.fieldName === field).label
    );

    // Additional validations
    const additionalError = validationFunctions[field]
      ? validationFunctions[field](value)
      : "";

    setFormState((prevFormState) => ({
      ...prevFormState,
      fieldErrors: {
        ...prevFormState.fieldErrors,
        [field]: error || additionalError,
      },
    }));
  };

  const handleSnackbarClose = () => {
    setSnackbar((prevSnackbar) => ({
      ...prevSnackbar,
      open: false,
    }));
  };

  const handleSnackbarOpen = (message, severity) => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };
  console.log("formData", formData);
  console.log("vehicleInfodata", vehicleInfoData);
  console.log("vehicleInfodata", vehicleAttrData);
  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationErrors = newFieldArg.reduce(
      (errors, { fieldName, label }) => {
        if (requiredFields.includes(fieldName)) {
          const value = formData[fieldName];
          const error = validateField(value, label);

          // Additional validations
          const additionalError = validationFunctions[fieldName]
            ? validationFunctions[fieldName](value)
            : "";

          setFormState((prevFormState) => ({
            ...prevFormState,
            fieldErrors: {
              ...prevFormState.fieldErrors,
              [fieldName]: error || additionalError,
            },
          }));

          return {
            ...errors,
            [fieldName]: error || additionalError,
          };
        }

        return errors;
      },
      {}
    );

    const hasErrors = Object.values(validationErrors).some((error) => !!error);

    if (!hasErrors) {
      try {
        if (vehicleData.id) {
          const newFormData = {
            ...formData,
            ...vehicleInfoData,
            ...vehicleAttrData,
            id: vehicleData.id,
            //cid
            gpId: quoteId,
          };
          await dispatch(updateVehicleInfo(newFormData, getCreateUserSuccess));
        } else {
          const newFormData = {
            ...vehicleInfoData,
            ...vehicleAttrData,
            ...formData,
            gpId: quoteId,
          };
          await dispatch(createVehicleInfo(newFormData, getCreateUserSuccess));
        }
      } catch (error) {
        handleSnackbarOpen(
          error.message || "User service failed. Please try again.",
          "error"
        );
      }
    } else {
      handleSnackbarOpen(
        "Validation errors detected. Please correct them.",
        "error"
      );
    }
    handleNext();
  };

  const dynamicTheme = useSelector((state) => state?.theme?.theme[0]);

  const getCreateUserSuccess = async () => {
    // selectedUserId
    //   ? handleSnackbarOpen("User updated successfully.", "success")
    //   :
    handleSnackbarOpen("Vehicle created successfully.", "success");
    setShowHideVehicleForm(false);
    await dispatch(getAllVehicleInfo(quoteId));

    setFormState({
      formData: newFieldArg.reduce(
        (fields, { fieldName }) => ({
          ...fields,
          [fieldName]: "",
        }),
        {}
      ),
      touchedFields: newFieldArg.reduce(
        (fields, { fieldName }) => ({
          ...fields,
          [fieldName]: false,
        }),
        {}
      ),
      fieldErrors: newFieldArg.reduce(
        (errors, { fieldName }) => ({
          ...errors,
          [fieldName]: "",
        }),
        {}
      ),
    });
  };
  useEffect(() => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      formData: newFieldArg.reduce(
        (fields, { fieldName }) => ({
          ...fields,
          [fieldName]:
            (vehicleData &&
              populateValue(vehicleData[fieldName], fieldName, newFieldArg)) ||
            "",
          // organizationId: generalPartyInfo?.organization?.id
        }),
        {}
      ),
    }));
  }, [vehicleData]);
  return (
    <>
      <Box
      // sx={{
      //     width: "100%",
      //     position: "relative",
      //     backgroundColor: "#f4f5f9",
      //     overflow: "hidden",
      //     display: "flex",
      //     flexDirection: "column",
      //     alignItems: "flex-start",
      //     justifyContent: "flex-start",
      //     padding: "0px 0px 156px",
      //     boxSizing: "border-box",
      //     lineHeight: "normal",
      //     letterSpacing: "normal",
      // }}
      >
        {/* <FrameComponent2 /> */}
        <Box
       
          sx={{
            alignSelf: "stretch",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "36px",
            maxWidth: "100%",
          }}
        >
          {/* <FrameComponent1 /> */}
          <section
          
            sx={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              padding: "0px 23px 0px 20px",
              boxSizing: "border-box",
              maxWidth: "100%",
              textAlign: "left",
              fontSize: "14px",
              color: dynamicTheme?.secondaryColor,
              fontFamily: '"Poppins", sans-serif',
            }}
          >
            <Box
            id ="form"
              component="form"
              onSubmit={handleSubmit}
              sx={{
                width: "853px",
                borderRadius: "4px",
                backgroundColor: "#fff",
                border: "1px solid #e3e3e3",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                padding: "30px 0px 24px",
                gap: "23px",
                maxWidth: "100%",
              }}
            >
              <Box
                sx={{
                  alignSelf: "stretch",
                  height: "798px",
                  position: "relative",
                  borderRadius: "4px",
                  backgroundColor: "#fff",
                  border: "1px solid #e3e3e3",
                  boxSizing: "border-box",
                  display: "none",
                }}
              />
              {/* <TextField
              style={{
                border: "none",
                backgroundColor: "transparent",
                alignSelf: "stretch",
                height: "51px",
                fontFamily: '"Poppins", sans-serif',
                fontWeight: "600",
                fontSize: "22px",
                color: "#242929",
                paddingLeft:"20px"
              }}
              placeholder="Vehicle Information"
              variant="standard"
              sx={{
                "& .MuiInputBase-root": { height: "51px", fontSize: "22px" },
                "& .MuiInputBase-input": { color: "#242929" },
              }}
            /> */}
              <Typography
               id="vehicleadditionalattributesheading"
                variant="h1"
                component="div"
                sx={{
                  border: "none",
                  backgroundColor: "transparent",
                  alignSelf: "stretch",
                  height: "51px",
                  fontFamily: '"Poppins", sans-serif',
                  fontWeight: "600",
                  fontSize: "25px",
                  color: dynamicTheme?.secondaryColor,
                  paddingLeft: "50px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Vehicle Additional Attributes
              </Typography>
              <hr style={{ borderTop: "1px solid #ccc", width: "100%" }} />
              <Box
                sx={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                  padding: "0px 44px 0px 52px",
                  boxSizing: "border-box",
                  maxWidth: "100%",
                  fontFamily: '"Poppins", sans-serif',
                }}
              >
                <Box
                  sx={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "16px",
                    maxWidth: "100%",
                    fontFamily: '"Poppins", sans-serif',
                  }}
                >
                  <Box
                    sx={{
                      flex: "1",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "4px",
                      minWidth: "240px",
                      maxWidth: "100%",
                      fontFamily: '"Poppins", sans-serif',
                    }}
                  >
                    <Box
                      sx={{
                        alignSelf: "stretch",
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: "5px 20px",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      padding: "0px 0px 8px",
                      }}
                    >
                      {validationRulesAdditionalAttributes.map(
                        ({ fieldName, label, type, option }, index) => (
                          <Box
                          key={index}
                          sx={{
                            width: "100%",
                            margin: "0 0 15px 0",
                            display: "flex",
                            flexFlow: "column",
                          }}
                        >
                            <Typography
                              key={fieldName} // Assign a unique key to each element
                              variant="body1"
                              sx={{
                                position: "relative",
                                lineHeight: "40px",
                                fontWeight: "500",
                                display: "inline-block",
                                fontFamily: '"Poppins", sans-serif',
                                minWidth: "83px",
                                zIndex: "1",
                              }}
                            >
                              {label}
                            </Typography>
                            <TextField
                              style={{
                                border: "none",
                                backgroundColor: "transparent",
                                alignSelf: "stretch",
                                height: "52px",
                                fontFamily: '"Poppins", sans-serif',
                                fontWeight: "500",
                                fontSize: "16px",
                                color: dynamicTheme?.secondaryColor,
                                zIndex: "1",
                              }}
                              type={type}
                              variant="outlined"
                              select={type === "select"}
                              required={requiredFields.includes(fieldName)}
                              id={fieldName}
                              // label={label}
                              placeholder={label}
                              name={fieldName}
                              autoComplete={fieldName}
                              value={formData[fieldName]}
                              // onChange={(e) => handleInputChange(e)}
                              onChange={(e) => {
                                if (
                                  type === "text" &&
                                  e.target.value.length > 10
                                ) {
                                  return;
                                }
                                if (
                                  (fieldName === "symbol" || fieldName==='antiLockBrakes') &&
                                  !/^[A-Za-z0-9]*$/.test(e.target.value)
                                ) {
                                  return;
                                }
                                handleInputChange(e);
                              }}

                              onBlur={() => handleBlur(fieldName)}
                              error={
                                touchedFields[fieldName] &&
                                !!fieldErrors[fieldName]
                              }
                              helperText={
                                touchedFields[fieldName]
                                  ? fieldErrors[fieldName]
                                  : ""
                              }
                              sx={{
                                "& fieldset": { borderColor: "#C1C1C1", fontFamily: '"Poppins", sans-serif', },
                                "& .MuiInputBase-root": {
                                  height: "52px",
                                  backgroundColor: "#fff",
                                  fontSize: "16px",
                                  fontFamily: '"Poppins", sans-serif',
                                },
                                "& .MuiInputBase-input": { color: dynamicTheme?.secondaryColor },
                              }}
                            >
                              {type === "select" &&
                                option.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    placeholder={label}
                                    value={option.value}
                                    sx={{fontFamily: '"Poppins", sans-serif',}}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </Box>
                        )
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* <Coverage /> */}

              <div
              id="buttoncontainer"
                style={{
                  width: "222px",
                  height: "42px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "14px",
                  fontFamily: '"Poppins", sans-serif',
                }}
              >
                <Button
                id="backbutton"
                  style={{
                    alignSelf: "stretch",
                    flex: "1",
                    cursor: "pointer",
                    fontFamily: '"Poppins", sans-serif',
                    zIndex: "1",
                  }}
                  startIcon={<ArrowBackIosIcon />}
                  disableElevation={true}
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    color: "#077BFD",
                    fontSize: "16px",
                    borderColor: "#077BFD",
                    borderRadius: "4px",
                    fontFamily: '"Poppins", sans-serif',
                    "&:hover": { borderColor: "#077BFD" },
                  }}
                  onClick={handleBackward}
                >
                  Back
                </Button>

                <Button
                 id="nextbutton"
                  type="submit"
                  style={{
                    alignSelf: "stretch",
                    flex: "1",
                    cursor: "pointer",
                    zIndex: "1",
                  }}
                  endIcon={<ArrowForwardIosIcon />}
                  disableElevation={true}
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    color: "#fff",
                    fontSize: "16px",
                    fontFamily: '"Poppins", sans-serif',
                    background: "rgba(7, 123, 253, 0.6)",
                    borderRadius: "4px",
                    marginRight: "20px",
                    "&:hover": { background: "rgba(7, 123, 253, 1)" },
                  }}
                  //  onClick={handleNext}
                >
                  Next
                </Button>
              </div>
            </Box>
            {/* <div
            id="continuebuttoncontainer"
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "center",
                padding: "20px 20px 50px 23px",
              }}
            >
              <Button
              id="continuebutton"
                style={{ height: "42px", width: "140px", cursor: "pointer" }}
                disableElevation={true}
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "#fff",
                  fontSize: "16px",
                  background: "#077BFD",
                  borderRadius: "4px",
                  fontFamily: '"Poppins", sans-serif',
                  "&:hover": { background: "#077BFD" },
                  width: 140,
                  height: 42,
                }}
                onClick={handleNext}
              >
                Continue
              </Button>
            </div> */}
          </section>
        </Box>
      </Box>
    </>
  );
};
export default VehicleAdditionalAttribute;

import React from 'react';
import {Box,Typography} from "@mui/material"
import imageloading from "./transparent-loading.gif";
const ImageLoader=({text1,text2})=> {
  return (
    <>
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top:0,
        left:0,

      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
        }}
      />
      <Box
        sx={{
          position: "relative",
          zIndex: 111,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        {/* <Box className="loader"></Box> */}
        <img src={imageloading} alt="loading" style={{ width: "150px" }} />
        <Typography
          variant="h5"
          sx={{
            color: "#FFFFFF",
            // color:"black",
            fontFamily: "Arial, Helvetica, sans-serif",
            textAlign: "center",
            lineHeight: "25px",
            fontSize: "18px",
          }}
        >
          {text1}
          <br />
          {text2}
          
        </Typography>
      </Box>
    </Box>
  
  </>
  )
}

export default ImageLoader;
import React, { useState } from 'react';
import { TextField, Button, Typography, Grid, Container, Box, CssBaseline, Snackbar, Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { validateEmail } from '../common/helpers/validation';
import { forgotPassword } from '../services/loginServices';

const useStyles = makeStyles({
    logo: {
        maxWidth: "120px",
        color: "#077BFD",
    },
    loginBox: {
        padding: "1.5em",
        boxShadow: "0em .35em .5em #ccc",
        border: "1px solid #ddd",
    },
    link: {
        fontSize: ".85em",
    }
});

const ForgotPasswordForm = () => {
    const classes = useStyles();
    const [formData, setFormData] = useState({
        email: '',
    });
    const [emailError, setEmailError] = useState('');

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
      };
    
    const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEmailError(validateEmail(formData.email));
        setFormData({ ...formData, [name]: value });
    };

    const [touchedFields, setTouchedFields] = useState({
        email: false,
      });

    const handleBlur = (field) => {
        setTouchedFields((prevTouched) => ({
          ...prevTouched,
          [field]: true,
        }));
    
        if (field === 'email') {
          setEmailError(validateEmail(formData.email));
        }
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Perform forgot password logic here
        if(!emailError) {
            try {
                await forgotPassword(formData);
                handleSnackbarOpen('Sent Email Successfully!', 'success');
              } catch (error) {
                handleSnackbarOpen(error, 'error');
              }
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box>
                    <h1 className={classes.logo}>BizAiTek</h1>
                </Box>
                <Box p={3} sx={{ mt: 1 }} className={classes.loginBox}>
                    <Typography id="forgotpassword" variant="h6" align="center" gutterBottom>
                        Forgot Password
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    name="email"
                                    label="Email"
                                    id="email"
                                    type="email"
                                    fullWidth
                                    autoComplete="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    onBlur={() => handleBlur('email')}
                                    error={touchedFields.email && !!emailError}
                                    helperText={touchedFields.email ? emailError : ''}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button id="resetpassword" type="submit" variant="contained" color="primary" fullWidth>
                                    Reset Password
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                    <Grid container>
                      <Grid item xs>
                          <Link className={classes.link} to="/signup" variant="body2">
                              {"Don't have an account? Sign Up"}
                          </Link>
                      </Grid>
                      <Grid item>
                          <Link className={classes.link} to="/login" variant="body2">
                              {"Redirect to Login"}
                          </Link>
                      </Grid>
                  </Grid>
                  <Snackbar
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                    }}
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                >
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
                </Box>
            </Box>
        </Container>
    );
};

export default ForgotPasswordForm;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  TextField,
  Box,
  Container,
  Snackbar,
  Alert,
  Grid,
  Typography,
  Modal,
  Paper,
  Select,
  MenuItem
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { alphanumericValidation, alphanumericWithSpaceValidation, validateEmail, validateField, validateMobile } from '../../common/helpers/validation';
import {
  closeCreateCustomerModal as closeCreateCustomerModalAction,
  createCustomer as createCustomerAction,
  getAllCustomer as getAllCustomerAction,
  updateCustomer as updateCustomerAction
} from '../../redux/actions/customerActions';
import {
  getAllOrganization as getAllOrganizationAction
} from '../../redux/actions/organizationActions';
import { CircleOutlined } from '@mui/icons-material';

const requiredFields = ['name', 'emailId', 'organizationId'];

const validationRules = [
  { fieldName: 'name', label: 'Name', type: 'text' },
  { fieldName: 'emailId', label: 'Email Id', type: 'text' },
  { fieldName: 'primaryContact', label: 'Primary Contact', type: 'text' },
  { fieldName: 'secondaryContact', label: 'Secondary Contact', type: 'text' },
  { fieldName: 'address', label: 'Customer Address', type: 'text' },
  { fieldName: 'country', label: 'Country', type: 'text' },
  { fieldName: 'organizationId', label: 'Select Organization', type: 'select' },
];

const validationFunctions = {
  name: alphanumericWithSpaceValidation,
  emailId: validateEmail,
  organizationId: validateField,
};

const useStyles = makeStyles((theme) => ({
  formContainer: {
    marginBottom: theme.spacing(2),
  },
  select: {
    minWidth: 150,
  },
  centerContent: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '.5rem !important',
  },
  fullWidth: {
    width: '100%',
  },
}));

const CreateCustomer = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const loading = useSelector((state) => state.loading.CREATE_ORGANIZATION);
  const isModalOpen = useSelector((state) => state?.customers?.isCreateCustomerModalOpen);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(isModalOpen);

  const [formState, setFormState] = useState({
    formData: validationRules.reduce((fields, { fieldName }) => ({
      ...fields,
      [fieldName]: '',
    }), {}),
    touchedFields: validationRules.reduce((fields, { fieldName }) => ({
      ...fields,
      [fieldName]: false,
    }), {}),
    fieldErrors: validationRules.reduce((errors, { fieldName }) => ({
      ...errors,
      [fieldName]: '',
    }), {}),
  });

  const user = useSelector((state) => state.user);
  const organizations = useSelector((state) => state?.organizations?.allOrganization);
  const selectedCustomerId = useSelector((state) => state.customers.selectedCustomerId);
  const allCustomer = useSelector((state) => state?.customers?.allCustomer);
  const customerDataForUpdate = allCustomer && allCustomer.length && allCustomer.filter((customer) => customer.id === selectedCustomerId);
  const customerForUpdate = customerDataForUpdate && customerDataForUpdate[0];

  React.useEffect(() => {
    if(!organizations) {
      dispatch(getAllOrganizationAction());
    }
  }, [organizations])

  const { formData, touchedFields, fieldErrors } = formState;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };
  
  const handleSelectChange = (event) => {
    setFormState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        organizationId: event.target.value,
      },
    }));
  };

  const handleBlur = (field) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      touchedFields: {
        ...prevFormState.touchedFields,
        [field]: true,
      },
    }));

    if (!requiredFields?.includes(field)) {
      return;
    }

    const value = formData[field];
    const error = validateField(value, validationRules.find(rule => rule.fieldName === field).label);

    // Additional validations
    const additionalError = validationFunctions[field] ? validationFunctions[field](value) : '';

    setFormState((prevFormState) => ({
      ...prevFormState,
      fieldErrors: {
        ...prevFormState.fieldErrors,
        [field]: error || additionalError,
      },
    }));
  };

  const handleSnackbarClose = () => {
    setSnackbar((prevSnackbar) => ({
      ...prevSnackbar,
      open: false,
    }));
  };

  const handleSnackbarOpen = (message, severity) => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationErrors = validationRules.reduce((errors, { fieldName, label }) => {
      if (requiredFields?.includes(fieldName)) {
        const value = formData[fieldName];
        const error = validateField(value, label);

        // Additional validations
        const additionalError = validationFunctions[fieldName] ? validationFunctions[fieldName](value) : '';

        setFormState((prevFormState) => ({
          ...prevFormState,
          fieldErrors: {
            ...prevFormState.fieldErrors,
            [fieldName]: error || additionalError,
          },
        }));

        return {
          ...errors,
          [fieldName]: error || additionalError,
        };
      }

      return errors;
    }, {});

    const hasErrors = Object.values(validationErrors).some((error) => !!error);

    if (!hasErrors) {
      try {
        if (selectedCustomerId) {
          const newFormData = {
            ...formData,
            id: selectedCustomerId,
            updatedBy: user.user.id,
            organization: {
              id: formData.organizationId
            },
          };
          await dispatch(updateCustomerAction(newFormData, getCreateCustomerSuccess));
        } else {
          const newFormData = {
            ...formData,
            insertedBy: user.user.id,
            updatedBy: user.user.id,
            id: 0,
            organization: {
              id: formData.organizationId
            }
          };
          await dispatch(createCustomerAction(newFormData, getCreateCustomerSuccess));
        }

      } catch (error) {
        handleSnackbarOpen(error || 'Customer service failed. Please try again.', 'error');
      }
    } else {
      handleSnackbarOpen('Validation errors detected. Please correct them.', 'error');
    }
  };

  const getCreateCustomerSuccess = async () => {
    selectedCustomerId ? handleSnackbarOpen('Customer updated successfully.', 'success') : handleSnackbarOpen('Customer created successfully.', 'success');
    await dispatch(getAllCustomerAction());

    setFormState({
      formData: validationRules.reduce((fields, { fieldName }) => ({
        ...fields,
        [fieldName]: '',
      }), {}),
      touchedFields: validationRules.reduce((fields, { fieldName }) => ({
        ...fields,
        [fieldName]: false,
      }), {}),
      fieldErrors: validationRules.reduce((errors, { fieldName }) => ({
        ...errors,
        [fieldName]: '',
      }), {}),
    });

    setTimeout(() => {
      handleSnackbarClose();
      if (location.pathname?.includes("customers")) {
        dispatch(closeCreateCustomerModalAction());
      } else {
        dispatch(closeCreateCustomerModalAction());
        navigate("/customers")
      }
    }, 3000)
  }

  useEffect(() => {
    setIsCreateModalOpen(isModalOpen);
    setFormState((prevFormState) => ({
      ...prevFormState,
      formData: validationRules.reduce((fields, { fieldName }) => ({
        ...fields,
        [fieldName]: selectedCustomerId ? (customerForUpdate && customerForUpdate[fieldName]) : '',
        organizationId: customerForUpdate?.organization?.id
      }), {}),
    }));
  }, [isModalOpen, dispatch]);

  const closeModal = () => {
    setFormState({
      formData: validationRules.reduce((fields, { fieldName }) => ({
        ...fields,
        [fieldName]: '',
      }), {}),
      touchedFields: validationRules.reduce((fields, { fieldName }) => ({
        ...fields,
        [fieldName]: false,
      }), {}),
      fieldErrors: validationRules.reduce((errors, { fieldName }) => ({
        ...errors,
        [fieldName]: '',
      }), {}),
    });

    dispatch(closeCreateCustomerModalAction());
  }

  return (
    <Modal open={isCreateModalOpen}>
      <Paper style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', maxWidth: '70vw', height: "80vh", overflowX: "auto" }}>
        <Container component="main" >
          <Grid item xs={12} md={12} sx={{ position: "sticky" }}>
            <Typography variant="h5">{selectedCustomerId ? 'Update Customer' : 'Create Customer'}</Typography>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <Grid container spacing={2}>
                {validationRules.map(({ fieldName, label, type }) => (
                  <Grid item xs={12} sm={6} key={fieldName}>
                    {type === 'select' ?
                      <>
                        <Select
                          id="organizationselectrequired"
                          value={formData[fieldName] || 0}
                          label={"Select Organization *"}
                          onChange={handleSelectChange}
                          className={`${classes.select} ${classes.fullWidth}`}
                        >
                          <MenuItem value={0}>
                            <em>Select Organization *</em>
                          </MenuItem>
                          {organizations.map((row)=><MenuItem value={row.id}>{row.name}</MenuItem>)}
                        </Select>
                      </>
                      :
                      <TextField
                        margin="normal"
                        required={requiredFields?.includes(fieldName)}
                        fullWidth
                        id={fieldName}
                        label={label}
                        type={type}
                        name={fieldName}
                        autoComplete={fieldName}
                        value={formData[fieldName]}
                        onChange={handleInputChange}
                        onBlur={() => handleBlur(fieldName)}
                        error={touchedFields[fieldName] && !!fieldErrors[fieldName]}
                        helperText={touchedFields[fieldName] ? fieldErrors[fieldName] : ''}
                      />
                    }
                  </Grid>
                ))}
              </Grid>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="submit"
                  id="submitbutton"
                  variant="contained"
                  sx={{ mt: 3, mb: 2, marginRight: "1rem" }}
                >
                  {loading ? <CircleOutlined size="24" color="secondary" /> : selectedCustomerId ? 'Update Customer' : 'Create Customer'}
                </Button>
                <Button
                  variant="contained"
                  id="closebutton"
                  sx={{ mt: 3, mb: 2 }}
                  color="secondary"
                  onClick={closeModal}
                >
                  Close
                </Button>
              </Box>
            </Box>
          </Box>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Container>
      </Paper>
    </Modal>
  );
};

export default CreateCustomer;

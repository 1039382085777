import apiService from "./apiServices";

export const saveCarrier = async(formData, token) => {
    try {

        // console.log(formData, 'formData, for task');
        const response = await apiService('/carrier/add-carrier', 'POST', formData, null, token);
        return response;
        } catch (error) {
          throw (error?.message || 'Saving carrierdetails is failed. Please try again.');
        }
  }

  export const updateInsuranceCarrier = async(formData, token) => {
    try {

        // console.log(formData, 'formData, for task');
        const response = await apiService('/carrier/update-carrier', 'PUT', formData, null, token);
        return response;
        } catch (error) {
          throw (error?.message || 'Saving carrierdetails is failed. Please try again.');
        }
  }

  export const saveCarrierGroup = async(formData, token) => {
    try {

        // console.log(formData, 'formData, for task');
        const response = await apiService('/company-group/', 'POST', formData, null, token);
        return response;
        } catch (error) {
          throw (error?.message || 'Saving carriergroupdetails is failed. Please try again.');
        }
  }

  export const searchAgency = async(formData, token) => {
    try {

        // console.log(formData, 'formData, for task');
        const response = await apiService('/agency/search', 'POST', formData, null, token);
        return response;
        } catch (error) {
          throw (error?.message || 'Seaching agency is failed. Please try again.');
        }
  }

  export const getCarrier = async (token, page, size) => {
    try {
      const headers = {
        'Access-Control-Request-Headers': '*'
      };
      // console.log("token",token);
      const response = await apiService(`/carrier/?page=${page}&size=${size}&sort=asc`, 'GET', null, headers, token);
      return response;
    } catch (error) {
      throw new Error(error.message || 'service is failed. Please try again.');
    }
  }

  export const getGroupCarrier = async (token, page, size) => {
    try {
      const headers = {
        'Access-Control-Request-Headers': '*'
      };
      const response = await apiService(`/company-group/?page=${page}&size=${size}&sort=desc`, 'GET', null, headers, token);
      return response;
    } catch (error) {
      throw new Error(error.message || 'service is failed. Please try again.');
    }
  }

  export const updateCarrier = async (formData, token) => {
    try {

      const response = await apiService('/company-group/', 'PUT', formData, null, token);
      return response;
      } catch (error) {
        throw (error?.message || 'Updating carrier is failed. Please try again.');
      }
  }


  export const deleteCarrierById = async (Id, token) => {
    try {
      // const headers = {
      //   'Access-Control-Request-Headers': '*'
      // };
      const response = await apiService(`/company-group/${Id}`, 'DELETE', null, null, token);
      return response;
    } catch (error) {
      throw new Error(error.message || 'service is failed. Please try again.');
    }
  }

  export const deleteCarrierListingById = async (Id, token) => {
    try {
      // const headers = {
      //   'Access-Control-Request-Headers': '*'
      // };
      const response = await apiService(`/carrier/delete-Carrier/${Id}`, 'DELETE', null, null, token);
      return response;
    } catch (error) {
      throw new Error(error.message || 'service is failed. Please try again.');
    }
  }

  //user profile

  // export const saveProfile = async(formData, token) => {
  //   try {

  //       // console.log(formData, 'formData, for task');
  //       const response = await apiService('/userprofile/save', 'POST', formData, null, token);
  //       return response;
  //       } catch (error) {
  //         throw (error?.message || 'Saving profile is failed. Please try again.');
  //       }
  // }

  export const saveProfile = async(formData, token) => {
    try {
      const response = await apiService('/userprofile/save', 'POST', formData, null, token);
      return response;
    } catch (error) {
      // Throw the complete error response instead of just the message
      if (error.response?.data) {
        throw error.response.data;  // This contains the error structure we want
      }
      // Fallback error structure if response.data is not available
      throw {
        errors: [{
          errorMessage: error?.message || 'Saving profile failed. Please try again.',
          target: null
        }]
      };
    }
  };


  export const activeProfileSearch = async(formData, token,page,size) => {
    try {

        // console.log(formData, 'formData, for task');
        const response = await apiService(`/userprofile/search?page=${page}&size=${size}&sort=desc`, 'POST', formData, null, token);
        return response;
        } catch (error) {
          throw (error?.message || 'Saving profile is failed. Please try again.');
        }
  }

  export const getProfile = async ( token, page, size) => {
    try {
      const headers = {
        'Access-Control-Request-Headers': '*'
      };
      // console.log("token",token);
      const response = await apiService(`/userprofile/all?page=${page}&size=${size}&sort=desc`, 'GET', null, headers, token);
      return response;
    } catch (error) {
      throw new Error(error.message || 'service is failed. Please try again.');
    }
  }
  export const getProfileUser = async (id, token) => {
    try {
      const headers = {
        'Access-Control-Request-Headers': '*'
      };
      // console.log("token",token);
      const response = await apiService('/userprofile/users', 'GET', null, headers, token);
      return response;
    } catch (error) {
      throw new Error(error.message || 'service is failed. Please try again.');
    }
  }

  export const updateProfile = async (formData, token) => {
    try {

      const response = await apiService('/userprofile/update', 'PUT', formData, null, token);
      return response;
      } catch (error) {
        throw (error?.message || 'Updating profile is failed. Please try again.');
      }
  }